import React from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  Button,
  createStyles,
  TextField,
} from '@material-ui/core';
import {
  lastDayOfMonth,
  startOfMonth,
} from 'date-fns';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {
  HeaderCurrencyDetails,
  HeaderLabel,
  HeaderTop,
  AccountStyled,
} from '../../../components/common.styled';
import COLORS from '../../../theme/colors';
import {
  LegacyJournalEntry,
  ProductCategory,
  Subledger,
} from '../../../interfaces/types';
import FactaDatePicker from '../../../components/DatePicker';
import InputBox from '../../../components/InputBox';
import isEmpty from '../../../util/isEmpty';
import SubledgerMonthIndicator from '../../../components/Subledger/SubledgerMonthIndicator';
import { fromUTC } from '../../../util/timezone';
import SubledgerAccountName from '../../../components/Subledger/SubledgerAccountName';
import {
  Breadcrumbs,
  BreadcrumbsItem,
} from '../../../components/BreadcrumbsStepper';

const useStyles = makeStyles(() => createStyles({
  datepicker: {
    background: COLORS.white,
    width: 120,
    maxWidth: 120,
    '& input': {
      background: COLORS.white,
    },
  },
  jeNumber: {
    '& input': {
      paddingTop: 7.5,
      paddingBottom: 7.5,
    },
  },
  jeDescription: {
    background: COLORS.white,
    width: 400,
    margin: 0,
    '& p': {
      position: 'absolute',
      top: -25,
      right: 0,
    },
    '& fieldset': {
      border: 0,
    },
    '& .MuiInputBase-root': {
      padding: '4px 0',
      border: `1px solid ${COLORS.border}`,
      '& .MuiInputBase-input': {
        padding: '4px 12px',
      },
      '&.Mui-focused': {
        border: `1px solid ${COLORS.violet}`,
      },
    },
  },
  navigation: {
    paddingLeft: 38,
    paddingTop: 30,
  },
}));

interface Props {
  subledger?: Subledger | null;
  scheduleDate: string;
  currentMonth: string;
  onCancel: () => void;
  journalEntry: LegacyJournalEntry;
  onPostJE: () => void;
  navigateToPrepareJE: () => void;
  rowsCount: number;
  totalPrepaidAccount: number;
}
const PostJEHeader = ({
  subledger,
  scheduleDate,
  onCancel,
  journalEntry,
  onPostJE,
  navigateToPrepareJE,
  rowsCount,
  totalPrepaidAccount,
  currentMonth,
}: Props) => {
  const classes = useStyles();
  const CHARACTER_LIMIT = 140;
  const isAccountClosed = totalPrepaidAccount === 0 || rowsCount === 0;
  const journalEntryDescription = journalEntry?.description;
  const defaultDescription = subledger?.productCategory === ProductCategory.DeferredRevenue
    ? `${currentMonth} revenue recognition` : `${currentMonth} prepaid expense amortization`;
  const description = journalEntryDescription?.length ? journalEntryDescription : defaultDescription;

  if (!subledger || isEmpty(subledger)) {
    return null;
  }

  return (
    <>
      <SubledgerMonthIndicator
        title="Previous Period:"
        scheduleDate={scheduleDate}
        color={COLORS.violet}
      />
      <Box className={classes.navigation}>
        <Breadcrumbs>
          <BreadcrumbsItem
            step={1}
            checked
            violet
          >
            Schedule
            {' '}
            {subledger?.productCategory === ProductCategory.DeferredRevenue ? 'Revenue' : 'Prepaid'}
          </BreadcrumbsItem>
          <BreadcrumbsItem
            step={2}
            checked
            violet
            action={navigateToPrepareJE}
          >
            Review schedule
          </BreadcrumbsItem>
          <BreadcrumbsItem
            step={3}
            active
            violet
          >
            Prepare JE
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Box>
      <HeaderTop
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
      >
        <AccountStyled
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          paddingLeft="38px"
          paddingRight="38px"
          height="70px"
        >
          <SubledgerAccountName account={subledger?.account} />
        </AccountStyled>
        <Box display="flex">
          <HeaderCurrencyDetails>
            <HeaderLabel>
              JE Number:
            </HeaderLabel>
            <InputBox
              readOnly
              fullWidth
              value={journalEntry?.jeNumber}
            />
          </HeaderCurrencyDetails>
          <HeaderCurrencyDetails>
            <HeaderLabel>
              Posting Date
            </HeaderLabel>
            <FactaDatePicker
              readOnly
              selectedDate={fromUTC(journalEntry?.txnDate as string)}
              onDateChange={() => {}}
              className={classes.datepicker}
              minDate={startOfMonth(new Date(scheduleDate))}
              maxDate={lastDayOfMonth(new Date(scheduleDate))}
            />
          </HeaderCurrencyDetails>
          <HeaderCurrencyDetails>
            <HeaderLabel>
              JE Description
            </HeaderLabel>
            <TextField
              disabled
              inputProps={{
                maxLength: CHARACTER_LIMIT,
              }}
              value={description}
              helperText={`${description?.length}/${CHARACTER_LIMIT}`}
              margin="normal"
              variant="outlined"
              className={classes.jeDescription}
            />
          </HeaderCurrencyDetails>
        </Box>
        <Box marginLeft="auto">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            height="100%"
            paddingLeft="19px"
            marginLeft="20px"
            marginTop="18px"
          >
            <Button
              variant="outlined"
              color="primary"
              startIcon={<ChevronLeftIcon />}
              onClick={navigateToPrepareJE}
            >
              REVIEW SCHEDULE
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onPostJE}
            >
              {!isAccountClosed ? 'POST JE' : 'CLOSE'}
            </Button>
            <Button
              style={{ color: COLORS.skyBlue }}
              onClick={onCancel}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </HeaderTop>
    </>
  );
};

export default React.memo(PostJEHeader);
