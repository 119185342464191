import React, {
  useEffect,
  useState,
} from 'react';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import {
  formatISO,
  isBefore,
  isEqual,
  isValid,
  lastDayOfMonth,
  parseISO,
} from 'date-fns';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { Alert } from '@material-ui/lab';
import { Box } from '@material-ui/core';
import { AppThunkDispatch } from '../../../store/store';
import {
  clearAllV2Subledgers,
  getV2SubledgerDetails,
} from '../../../store/slices/v2Subledgers';
import {
  getAccountBalance,
  getAllAccounts,
} from '../../../store/slices/v2Accounts';
import { getAllContacts } from '../../../store/slices/v2Contacts';
import { getAssetTypes } from '../../../store/slices/assetTypes';
import {
  subledgerTouchedSelector,
  v2SubledgerSelectorFilteredUnfinalized,
} from '../../../store/selectors/v2Subledgers';
import GLBalancesInfo from '../../../components/Subledger/FixedAssets/GLBalancesInfo';
import { PeriodSelector } from '../../../components/Subledger/FixedAssets/PeriodSelector';
import {
  Breadcrumbs,
  BreadcrumbsItem,
} from '../../../components/BreadcrumbsStepper';
import { BottomActions } from '../../../components/Subledger/FixedAssets/BottomActions';
import { calculateSubledgerDetails } from '../../../util/pages/FixedAssets/calculateSubledgerDetails';
import { Loader } from '../../../components/Loader';
import { accountGLBalanceSelector } from '../../../store/selectors/v2Accounts';
import { SchedulerContainer } from '../../../components/Subledger/FixedAssets/SchedulerContainer';
import { RouterPrompt } from '../../../components/RouterPrompt';
import { precisionRound } from '../../../util/math';
import { parseToIncludeCurrentDay } from '../../../util/timezone';
import { lastV2SyncSelector } from '../../../store/selectors/v2Sync';

export const FixedAssetsScheduler = () => {
  const history = useHistory();
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const { subledgerId, subledgerDate } = useParams<{ subledgerId: string; subledgerDate: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [allowNavigate, setAllowNavigate] = useState(false);

  const touched = useSelector(subledgerTouchedSelector);
  const lastFetch = useSelector(lastV2SyncSelector);
  const accountGLBalance = useSelector(accountGLBalanceSelector);
  const subledgerDetails = useSelector(v2SubledgerSelectorFilteredUnfinalized);

  const isDateValid = subledgerDate && isValid(parseISO(subledgerDate));
  const {
    assetsScheduledValue,
    allAssetsScheduled,
  } = calculateSubledgerDetails({ fixedAssets: subledgerDetails?.fixedAssets, asOfDate: subledgerDate });

  const isReviewDisabled = Math.abs(precisionRound(accountGLBalance - assetsScheduledValue)) !== 0
    || !subledgerDetails?.finalized
    || !allAssetsScheduled;

  const isPeriodLocked = !!subledgerDetails && isBefore(
    lastDayOfMonth(parseISO(subledgerDetails.currentPeriodEnd)),
    lastDayOfMonth(parseISO(subledgerDate)),
  );

  const isCurrentPeriod = isEqual(
    lastDayOfMonth(parseISO(subledgerDetails?.currentPeriodEnd!)),
    lastDayOfMonth(parseISO(subledgerDate)),
  );

  useEffect(() => {
    if (isDateValid) {
      setIsLoading(true);
      Promise.all([
        (!subledgerDate && [reduxDispatch(getV2SubledgerDetails({ subledgerId }))]),
        reduxDispatch(getAllAccounts({})),
        reduxDispatch(getAllContacts()),
        reduxDispatch(getAssetTypes()),
      ])
        .finally(() => setIsLoading(false));
    }

    return () => {
      reduxDispatch(clearAllV2Subledgers());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subledgerId, reduxDispatch, lastFetch]);

  useEffect(() => {
    if (subledgerDate && isDateValid) {
      setIsLoading(true);
      reduxDispatch(getV2SubledgerDetails({
        subledgerId,
        date: subledgerDate,
      }))
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isDateValid, subledgerId, reduxDispatch, subledgerDate, lastFetch]);

  useEffect(() => {
    if (subledgerDetails) {
      reduxDispatch(getAccountBalance({
        accountId: subledgerDetails.accountId,
        date: parseToIncludeCurrentDay(subledgerDate),
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subledgerDetails, reduxDispatch]);

  const handleEffectiveDateChange = (date: Date) => {
    const newSelectedDate = formatISO(date, { representation: 'date' });
    history.push(`/fixed-assets/scheduler/${subledgerId}/${newSelectedDate}`);
  };

  const handleGoForward = () => {
    setAllowNavigate(true);
    setTimeout(() => {
      history.push(`/fixed-assets/scheduler/${subledgerId}/${subledgerDate}/review`);
    }, 0);
  };

  if (!isDateValid) {
    return (
      <Box padding={4}>
        <Alert severity="error">No date or wrong date provided.</Alert>
      </Box>
    );
  }

  return (
    <>
      <Loader open={isLoading} />
      <RouterPrompt
        _key={subledgerDate}
        shouldPreventNavigation={touched.length > 0 && !isCurrentPeriod && !allowNavigate}
        onConfirm={() => true}
        onCancel={() => false}
        title="Unsaved changes"
        message={'Warning: Changes will not be reflected in your general ledger unless you post '
        + 'a new journal entry. Do you still want to navigate away?'}
      />
      <>
        <Box
          padding={4}
          paddingBottom={0}
          width="100%"
        >
          {isPeriodLocked && (
            <Alert
              elevation={0}
              severity="warning"
              variant="outlined"
              style={{ margin: '0 24px 24px 0' }}
            >
              <span>
                <strong>Warning: </strong>
                This period cannot be edited until it is unlocked in your general ledger.
              </span>
            </Alert>
          )}
          {!isCurrentPeriod && touched.length > 0 && (
            <Alert
              severity="warning"
              variant="outlined"
              style={{ marginBottom: '32px' }}
            >
              <span>
                <strong>Warning: </strong>
                Changes will not be reflected in your general ledger unless you post a new journal entry.
              </span>
            </Alert>
          )}
          <PeriodSelector
            selectedDate={parseISO(subledgerDate)}
            currentPeriodDate={parseISO(subledgerDetails?.currentPeriodEnd!)}
            handleChange={handleEffectiveDateChange}
            minDate={parseISO(subledgerDetails?.startDate!)}
            maxDate={parseISO(subledgerDetails?.currentPeriodEnd!)}
          />
          <Breadcrumbs style={{ marginTop: 32 }}>
            <BreadcrumbsItem
              step={1}
              active
            >
              Schedule Fixed Assets
            </BreadcrumbsItem>
            <BreadcrumbsItem
              step={2}
              action={isReviewDisabled ? undefined : handleGoForward}
            >
              Review Depreciation
            </BreadcrumbsItem>
            <BreadcrumbsItem step={3}>
              Prepare JE
            </BreadcrumbsItem>
          </Breadcrumbs>
          {!isLoading && subledgerDetails && (
            <GLBalancesInfo
              subledgerDetails={subledgerDetails}
              showScheduled
              selectedDate={subledgerDate}
            />
          )}
        </Box>
        {!isLoading && subledgerDetails && (
          <>
            <SchedulerContainer
              subledgerDetails={subledgerDetails}
              selectedDate={subledgerDate}
              isPeriodLocked={isPeriodLocked}
              isCurrentPeriod={isCurrentPeriod}
            />
            <BottomActions
              menuItems={[
                {
                  label: 'Review Schedule',
                  action: handleGoForward,
                  disabled: isReviewDisabled,
                },
              ]}
            />
          </>
        )}
      </>
    </>
  );
};
