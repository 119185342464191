import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import { styled } from '@material-ui/core/styles';

import COLORS from '../../theme/colors';

export const StyledFormControl = styled(FormControl)({
  margin: 4,
  width: '100%',
  minWidth: 350,
});

export const StyledFormHelperText = styled(FormHelperText)({
  color: COLORS.error,
  paddingLeft: 4,
  marginTop: 4,
});

export const StyledFormLabel = styled(Typography)({
  padding: 0,
  margin: '0 0 10px',
  color: COLORS.black,
  fontSize: 16,
  fontWeight: 600,
  lineHeight: '24px',
});

export const StyledInputLabel = styled(Typography)({
  padding: 0,
  paddingLeft: 4,
  paddingBottom: 4,
  marginTop: 8,
  marginBottom: 0,
  color: COLORS.lightGray,
  fontSize: 11,
  fontWeight: 'normal',
  fontStyle: 'normal',
  lineHeight: '16px',
  textTransform: 'uppercase',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
});

export const StyledInputLabelRequired = styled('span')({
  color: COLORS.error,
  margin: '0 4px',
});
