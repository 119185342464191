import React from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  Button,
  createStyles,
  Tooltip,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import roundTo from 'round-to';
import { eachMonthOfInterval } from 'date-fns';
import COLORS from '../../../theme/colors';
import currencyFormatter from '../../../util/currencyFormatter';
import {
  Account,
  AmortizationSource,
  ProductCategory,
  Subledger,
} from '../../../interfaces/types';
import isEmpty from '../../../util/isEmpty';
import CalendarNavigation from '../../../components/CalendarNavigation';
import getSubledgerStartEndDate from '../../../util/getSubledgerStartEndDate';
import SubledgerAccountName from '../../../components/Subledger/SubledgerAccountName';
import {
  Breadcrumbs,
  BreadcrumbsItem,
} from '../../../components/BreadcrumbsStepper';
import {
  HeaderTop,
  HeaderLabel,
  CurrencyLabel,
  HeaderCurrencyDetails,
} from '../../../components/common.styled';
import { DownloadExportIcon } from '../../../components/Icons';

const useStyles = makeStyles(() => createStyles({
  selectBox: {
    width: 400,
  },
  periodBox: {
    width: 100,
    marginRight: 20,
  },
  subledgerBalance: {
    color: COLORS.violet,
  },
  datepicker: {
    background: COLORS.white,
    borderRadius: 4,
    marginRight: 20,
    width: 120,
    maxWidth: 120,
    '& input': {
      background: COLORS.white,
    },
  },
  fullWidth: {
    width: '100%',
  },
  whiteFont: {
    color: COLORS.white,
  },
  page: {
    position: 'relative',
    width: '100%',
  },
  navigation: {
    paddingLeft: 38,
    paddingTop: 30,
  },
}));

interface Props {
  subledger?: Subledger | null;
  scheduleDate: string;
  accountBalance: Account;
  downloadCSV: any;
  navigateToPrepareJE: () => void;
  onMonthChange: (dateItem: Date) => void;
}

const ScheduleHeader = ({
  subledger,
  scheduleDate,
  accountBalance,
  downloadCSV,
  navigateToPrepareJE,
  onMonthChange,
}: Props) => {
  const classes = useStyles();

  if (!subledger || isEmpty(subledger)) {
    return null;
  }

  const difference = roundTo(Number(accountBalance?.glBalance), 2) - roundTo(Number(subledger?.openingBalance), 2);

  const isPrepareJEEnable = !subledger?.amortizationSources?.some((asset: AmortizationSource) => !asset?.status)
    && difference === 0;

  const {
    startDate,
    endDate,
  } = getSubledgerStartEndDate(subledger);

  const subledgerInterval = eachMonthOfInterval({
    start: startDate,
    end: endDate,
  });

  const onDateChange = (dateItem: Date) => {
    onMonthChange(dateItem);
  };

  const toolTipContent = 'Please ensure that all '
    + `${subledger?.productCategory === ProductCategory.PrepaidExpense ? 'prepaids' : 'revenues'} are scheduled.`;

  return (
    <Box className={classes.page}>
      <CalendarNavigation
        onDateChange={onDateChange}
        scheduleDate={scheduleDate}
        subledgerInterval={subledgerInterval}
        periodContext="Current Period:"
        color={COLORS.deepGray}
      />
      <Box className={classes.navigation}>
        <Breadcrumbs>
          <BreadcrumbsItem
            step={1}
            active
          >
            Schedule
            {' '}
            {subledger?.productCategory === ProductCategory.DeferredRevenue ? 'Revenue' : 'Prepaid'}
          </BreadcrumbsItem>
          <BreadcrumbsItem
            step={2}
            action={isPrepareJEEnable ? navigateToPrepareJE : undefined}
          >
            Review schedule
          </BreadcrumbsItem>
          <BreadcrumbsItem step={3}>
            Prepare JE
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Box>
      <HeaderTop
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          height="100%"
          padding="0 14px"
          marginTop="10px"
        >
          <SubledgerAccountName account={subledger?.account} />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            height="100%"
            marginTop="10px"
          />
        </Box>
        <Box display="flex">
          <HeaderCurrencyDetails>
            <HeaderLabel>
              Current GL Balance:
            </HeaderLabel>
            <CurrencyLabel>
              {currencyFormatter.format(accountBalance.glBalance)}
            </CurrencyLabel>
          </HeaderCurrencyDetails>
          <HeaderCurrencyDetails>
            <HeaderLabel>
              Current Facta Balance:
            </HeaderLabel>
            <CurrencyLabel className={classes.subledgerBalance}>
              {currencyFormatter.format(subledger.openingBalance)}
            </CurrencyLabel>
          </HeaderCurrencyDetails>
          <HeaderCurrencyDetails>
            <HeaderLabel>
              Difference
            </HeaderLabel>
            <CurrencyLabel>
              {currencyFormatter.format(difference)}
            </CurrencyLabel>
          </HeaderCurrencyDetails>
        </Box>

        {
          isPrepareJEEnable && (
            <Box marginLeft="auto">
              <Button
                variant="contained"
                color="primary"
                endIcon={<ChevronRightIcon />}
                onClick={navigateToPrepareJE}
              >
                REVIEW SCHEDULE
              </Button>
            </Box>
          )
        }
        {
          !isPrepareJEEnable && (
            <Tooltip
              title={toolTipContent}
              arrow
              placement="top"
            >
              <Box marginLeft="auto">
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<ChevronRightIcon />}
                  onClick={navigateToPrepareJE}
                  disabled={!isPrepareJEEnable}
                >
                  REVIEW SCHEDULE
                </Button>
              </Box>
            </Tooltip>
          )
        }
      </HeaderTop>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        position="absolute"
        top="30px"
        right="38px"
      >
        <Button
          onClick={downloadCSV}
          color="primary"
          endIcon={(<DownloadExportIcon />)}
        >
          Export
        </Button>
      </Box>
    </Box>
  );
};

export default ScheduleHeader;
