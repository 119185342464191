import React from 'react';
import { useSelector } from 'react-redux';
import {
  TableRow,
  TableCell,
} from '@material-ui/core';
import clsx from 'clsx';
import { getTotalBalanceAndAmortizationBalance } from './common';
import { AmortizationSource } from '../../interfaces/types';
import { useTableStyles } from './Subledger.styled';
import { accountInfoSelector } from '../../store/selectors/account';
import ExpenseAccountEditableCell from './ExpenseAccountEditableCell';
import ClassEditableCell from './TableCells/ClassEditableCell';
import AmortizationSourceCreationCell from './TableCells/AmortizationSourceCreationCell';
import AmortizationSourceIdCell from './TableCells/AmortizationSourceIdCell';
import DescriptionEditableForQuickbookSourceCell from './TableCells/DescriptionEditableForQuickbookSourceCell';
import AmortizationStartDateCell from './TableCells/AmortizationStartDateCell';
import AmortizationEndDateCell from './TableCells/AmortizationEndDateCell';
import AmoritizationScheduledTypeCell from './TableCells/AmoritizationScheduledTypeCell';
import StartingBalanceEditableCell from './TableCells/StartingBalanceEditableCell';
import SaveRefreshActionCell from './TableCells/SaveRefreshActionCell';
import CustomerCell from './TableCells/CustomerCell';
import ProductCell from './TableCells/ProductCell';
import { FactaCheckbox } from '../Checkbox';

interface Props {
  onSave: (asset: AmortizationSource) => () => Promise<void>;
  parentAsset: AmortizationSource;
  amortizationSource: AmortizationSource;
  hasChildren?: boolean;
  collapses: Array<string>;
  onDateChange: (propertyName: string, internalId: string, value: Date | null) => void;
  onAutoCompleteChange: (propertyName: string, internalId: string) => (value: string) => void;
  onInputBoxChange: (propertyName: string, internalId: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  onInputBoxFocus: (propertyName: string, internalId: string) => (event: React.FocusEvent<HTMLInputElement>) => void;
  addSplit: (internalId: string) => () => void;
  onRowSelect: (internalId: string) => () => void;
  removeAsset: (internalId: string) => () => void;
  onShowDetails: (internalId: string, sourceId: string) => void;
  isSaveEnabled: (asset: AmortizationSource) => any;
  childrenPrepaidAssets: Array<AmortizationSource>;
  selectedRow: string;
  lastChild?: boolean;
  subledgerId: string;
  showBulkEdit: boolean;
  onCheckboxChange?: (internalId: string) => void;
  isSelectedForBulkEdit?: boolean;
  isSelectable?: boolean;
}

const HistoricalRevSyncEditableRow = ({
  parentAsset,
  amortizationSource,
  hasChildren,
  collapses,
  onSave,
  onDateChange,
  onInputBoxChange,
  onInputBoxFocus,
  addSplit,
  isSaveEnabled,
  removeAsset,
  onShowDetails,
  childrenPrepaidAssets,
  selectedRow,
  onRowSelect,
  onAutoCompleteChange,
  subledgerId,
  lastChild = false,
  showBulkEdit,
  onCheckboxChange,
  isSelectedForBulkEdit,
  isSelectable,
}: Props) => {
  const account = useSelector(accountInfoSelector)!;
  const tableClasses = useTableStyles();
  const { totalBalance } = hasChildren ? getTotalBalanceAndAmortizationBalance(childrenPrepaidAssets) : {
    totalBalance: 0,
  };
  const isRowSelected = selectedRow === amortizationSource.parentId || selectedRow === amortizationSource.internalId;
  return (
    <TableRow
      selected={isSelectedForBulkEdit || isRowSelected}
      classes={{
        selected: tableClasses.selected,
      }}
      onClick={onRowSelect(amortizationSource.parentId ?? amortizationSource.internalId)}
    >
      {showBulkEdit && (
        <TableCell
          className={clsx({
            [tableClasses.child]: amortizationSource.parentId,
            [tableClasses.parent]: hasChildren,
          })}
          is-cell-sticky="true"
        >
          {(!amortizationSource?.parentId && onCheckboxChange && typeof isSelectedForBulkEdit !== 'undefined') ? (
            <FactaCheckbox
              checked={isSelectedForBulkEdit}
              disabled={!!hasChildren || isSelectable === false}
              onChange={() => onCheckboxChange(amortizationSource.internalId)}
            />
          ) : ''}
        </TableCell>
      )}
      <AmortizationSourceCreationCell
        amortizationSource={amortizationSource}
        hasChildren={hasChildren}
        lastChild={lastChild}
        showCursor
      />
      <AmortizationSourceIdCell
        amortizationSource={amortizationSource}
        hasChildren={hasChildren}
        lastChild={lastChild}
        showCursor
      />
      <DescriptionEditableForQuickbookSourceCell
        amortizationSource={amortizationSource}
        hasChildren={hasChildren}
        lastChild={lastChild}
        highlighted={!amortizationSource.status}
        isRowSelected={isRowSelected}
        showCursor
        onInputBoxChange={onInputBoxChange}
      />
      <CustomerCell
        amortizationSource={amortizationSource}
        hasChildren={hasChildren}
        lastChild={lastChild}
        highlighted={!amortizationSource.status}
        showCursor
      />
      <ProductCell
        amortizationSource={amortizationSource}
        hasChildren={hasChildren}
        lastChild={lastChild}
      />
      <ExpenseAccountEditableCell
        asset={amortizationSource}
        hasChildren={hasChildren}
        lastChild={lastChild}
        collapses={collapses}
        isRowSelected={isRowSelected}
        onSelectChange={onAutoCompleteChange}
      />
      {(account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) && (
        <ClassEditableCell
          asset={amortizationSource}
          hasChildren={hasChildren}
          lastChild={lastChild}
          isRowSelected={isRowSelected}
          onSelectChange={onAutoCompleteChange}
        />
      )}
      <AmortizationStartDateCell
        amortizationSource={amortizationSource}
        hasChildren={hasChildren}
        lastChild={lastChild}
        highlighted={!amortizationSource.status}
        showCursor
        isRowSelected={isRowSelected}
        onDateChange={onDateChange}
      />
      <AmortizationEndDateCell
        amortizationSource={amortizationSource}
        hasChildren={hasChildren}
        lastChild={lastChild}
        highlighted={!amortizationSource.status}
        showCursor
        isRowSelected={isRowSelected}
        onDateChange={onDateChange}
      />
      <AmoritizationScheduledTypeCell
        amortizationSource={amortizationSource}
        hasChildren={hasChildren}
        lastChild={lastChild}
        highlighted={!amortizationSource.status}
        showCursor
        isRowSelected={isRowSelected}
        onSelectChange={onAutoCompleteChange}
      />
      <StartingBalanceEditableCell
        amortizationSource={amortizationSource}
        hasChildren={hasChildren}
        lastChild={lastChild}
        highlighted={!amortizationSource.status}
        showCursor
        isRowSelected={isRowSelected}
        onInputBoxChange={onInputBoxChange}
        onInputBoxFocus={onInputBoxFocus}
        totalBalance={totalBalance}
        parentStartingBalance={parentAsset?.startingBalance}
      />
      <SaveRefreshActionCell
        lastChild={lastChild}
        hasChildren={hasChildren}
        amortizationSource={amortizationSource}
        addSplit={addSplit}
        removeAsset={removeAsset}
        isSaveEnabled={isSaveEnabled}
        onSave={onSave}
        saveTitle="Save"
        subledgerId={subledgerId}
        onShowDetails={onShowDetails}
        isRevSync
      />
    </TableRow>
  );
};

export default React.memo(HistoricalRevSyncEditableRow);
