import React, { useState } from 'react';
import find from 'lodash.find';
import { MenuItem } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { LoadingBox } from '../../../../components/LoadingBox';
import SelectBox from '../../../../components/Select';
import { CommonSubledger } from '../../../../hooks/useAllSubledgers';
import { V2Subledger } from '../../../../interfaces/subledgers';
import {
  Account,
  LegacySchedulingMethod,
} from '../../../../interfaces/types';
import {
  legacySchedulingMethodOptions,
  schedulingMethodOptions,
} from '../../../../util/helpers';
import { AppThunkDispatch } from '../../../../store/store';
import { putAccountBalances } from '../../../../store/slices/account';
import { SchedulingMethod } from '../../../../interfaces/common';
import { patchV2Subledger } from '../../../../store/slices/v2Subledgers';

interface Props {
  commonSubledger: CommonSubledger;
  v1Accounts?: Account[];
  v2Subledgers: V2Subledger[] | undefined;
  setSelectedId: (id: string) => void;
}

export const SchedulingMethodForm = ({
  commonSubledger,
  v1Accounts,
  v2Subledgers,
  setSelectedId,
}: Props) => {
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const [isUpdating, setIsUpdating] = useState(false);
  const v1Account = find(v1Accounts, { subledger: { id: commonSubledger.id } })!;
  const v2Subledger = find(v2Subledgers, { id: commonSubledger.id })!;

  const options = commonSubledger.apiVersion === 1
    ? legacySchedulingMethodOptions
    : schedulingMethodOptions;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setIsUpdating(true);
    if (commonSubledger.apiVersion === 1) {
      reduxDispatch(putAccountBalances({
        id: v1Account.id,
        enable: v1Account.enable,
        scheduleType: event.target.value as LegacySchedulingMethod,
      }))
        .finally(() => setIsUpdating(false));
    } else {
      reduxDispatch(patchV2Subledger({
        subledgerId: commonSubledger.id,
        params: {
          defaultSchedulingMethod: event.target.value as SchedulingMethod,
        },
      }))
        .finally(() => setIsUpdating(false));
    }
  };

  return (
    <>
      {isUpdating && (
        <LoadingBox transparent />
      )}
      <SelectBox
        value={commonSubledger.apiVersion === 1
          ? v1Account.scheduleType
          : v2Subledger.defaultSchedulingMethod}
        disableUnderline
        onChange={handleChange}
        onOpen={() => setSelectedId(commonSubledger.id)}
        onClose={() => setSelectedId('')}
      >
        {options.map((schMethod) => (
          <MenuItem
            key={schMethod.value}
            value={schMethod.value}
          >
            {schMethod.label}
          </MenuItem>
        ))}
      </SelectBox>
    </>
  );
};
