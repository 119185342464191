import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import Datepicker from 'react-datepicker';
import debounce from 'lodash.debounce';
import { PopperPlacementType } from '@material-ui/core';
import {
  isAfter,
  isSameDay,
  isValid,
  startOfDay,
} from 'date-fns';
import {
  FULL_DATE_FORMAT,
  MIN_SYSTEM_DATE,
} from '../../util/constants';
import { computeNewDate } from './DatePicker.util';
import StyledCalendarInput from '../Table/Filters/Datepicker/StyledCalendarInput';
import { useStyles } from './index.styled';

const DATEPICKER_CUSTOM_KEYS = ['t', '=', '+', '-', 'w', 'k', 'm', 'h', 'y', 'r'];

interface Props {
  selectedDate: Date | null;
  onDateChange: (date: Date | null) => void;
  minDate?: Date | null;
  maxDate?: Date | null;
  disabled?: boolean;
  error?: boolean | null;
  className?: string;
  readOnly?: boolean;
  placement?: PopperPlacementType;
  openToDate?: Date | undefined;
  isNullable?: boolean;
  onBlur?: any;
  disablePortal?: boolean;
}

const FactaDatePicker = ({
  selectedDate,
  onDateChange,
  minDate = new Date(MIN_SYSTEM_DATE),
  maxDate,
  disabled,
  readOnly,
  error,
  className,
  placement = 'bottom-start',
  openToDate,
  isNullable,
  onBlur,
  disablePortal = false,
}: Props) => {
  const classes = useStyles();
  const [applicationError, setApplicationError] = useState(Boolean(error));
  const [innerState, setInnerState] = useState(
    selectedDate === undefined
      ? startOfDay(new Date())
      : isValid(selectedDate)
        ? selectedDate
        : null,
  );
  const datepickerRef = useRef<Datepicker>(null);

  useEffect(() => {
    setApplicationError(Boolean(error));
  }, [error]);

  useEffect(() => {
    if (disabled) {
      setInnerState(null);
    }
  }, [disabled]);

  useEffect(() => {
    setInnerState(selectedDate === undefined
      ? startOfDay(new Date())
      : isValid(selectedDate)
        ? selectedDate
        : null);
  }, [selectedDate]);

  const debounceHandleChange = debounce(onDateChange, 200);

  const handleChange = (date: Date | null) => {
    setInnerState(date!);
    if (!date && isNullable) {
      setApplicationError(Boolean(error));
      debounceHandleChange(null);
      return;
    }
    if (isValid(date) && (isAfter(date!, minDate!) || isSameDay(date!, minDate!))) {
      setApplicationError(Boolean(error));
      debounceHandleChange(date);
    } else {
      setApplicationError(true);
    }
  };

  const onError = () => {
    setApplicationError(true);
  };

  const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { target } = event;
    const extensionStarts = target.value.lastIndexOf('.');
    target.focus();
    target.setSelectionRange(0, extensionStarts);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Tab') {
      datepickerRef.current?.setOpen(false);
      debounceHandleChange(innerState);
    }

    if (DATEPICKER_CUSTOM_KEYS.includes(event.key)) {
      event.preventDefault();
      const computedDate = computeNewDate(event, datepickerRef.current?.props.selected!);

      if (isValid(computedDate)) {
        onDateChange(computedDate);
        setInnerState(computedDate);
      }
    }
  };

  const handleChangeRaw = (textDate: string) => {
    const tempDate = new Date(textDate);
    if (isValid(tempDate)) {
      setInnerState(tempDate);
    }
  };

  const debouncedHandleChangeRaw = debounce(handleChangeRaw, 50);

  return (
    <Datepicker
      ref={datepickerRef}
      className={classes.datepicker}
      calendarClassName={classes.datepickerCalendar}
      selected={innerState}
      onChange={handleChange}
      onChangeRaw={(e) => debouncedHandleChangeRaw(e.target.value)}
      onSelect={handleChange}
      onKeyDown={handleKeyDown}
      showPopperArrow={false}
      popperProps={{
        disablePortal,
      }}
      customInput={(
        <StyledCalendarInput
          className={className}
          error={Boolean(error) || (!readOnly && applicationError)}
          onFocus={onFocus}
        />
      )}
      popperPlacement={placement}
      popperModifiers={{
        preventOverflow: {
          enabled: true,
          priority: ['right'],
        },
      }}
      minDate={minDate}
      maxDate={maxDate}
      dateFormat={FULL_DATE_FORMAT}
      onInputError={onError}
      disabled={disabled}
      readOnly={readOnly}
      placeholderText="mm/dd/yyyy"
      openToDate={selectedDate || openToDate}
      fixedHeight
      onBlur={onBlur}
      {...(!disablePortal && { portalId: 'datepicker-portal' })}
    />
  );
};
export default FactaDatePicker;
