import { styled } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';

import COLORS from '../../theme/colors';

const SelectBox = styled(Select)({
  background: COLORS.white,
  border: `1px solid ${COLORS.border}`,
  borderRadius: 4,
  fontWeight: 'normal',
  fontSize: 12,
  lineHeight: '14px',
  color: COLORS.deepGray,
  padding: '4px 12px',
  minWidth: 81,
  '& .MuiSelect-select:focus ': {
    background: COLORS.white,
  },
  height: 32,
  '&.Mui-focused': {
    border: `1px solid ${COLORS.violet}`,
  },
  '&.Mui-error': {
    color: COLORS.error,
    border: `1px solid ${COLORS.error}`,
  },
  '& .MuiSelect-icon': {
    right: '10px',
  },
});

export default SelectBox;
