import { TableCell } from '@material-ui/core';
import clsx from 'clsx';
import { format } from 'date-fns';
import React from 'react';
import { LegacySchedulingMethod } from '../../interfaces/types';
import { FULL_DAY_FORMAT } from '../../util/constants';
import { useTableStyles } from './Subledger.styled';

interface Props {
  amortizationDate: Date | null;
  hasChildren?: boolean;
  parentId: string | null;
  lastChild?: boolean;
  amortizationScheduleType: string;
  isCellSticky?: string;
}

const AmortizationReadOnlyTableCell = ({
  amortizationDate,
  hasChildren,
  lastChild,
  parentId,
  amortizationScheduleType,
  isCellSticky,
}: Props) => {
  const tableClasses = useTableStyles();
  return (
    <TableCell
      className={clsx(tableClasses.datePickerCell, {
        [tableClasses.child]: parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      is-cell-sticky={isCellSticky}
    >
      {
        amortizationDate && !hasChildren
          ? amortizationScheduleType !== LegacySchedulingMethod.MANUAL
            ? format(amortizationDate, FULL_DAY_FORMAT)
            : 'M' : ''
      }
    </TableCell>
  );
};

export default React.memo(AmortizationReadOnlyTableCell);
