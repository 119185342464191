import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import COLORS from '../../../theme/colors';

interface Props {
  description?: string | React.ReactNode;
}

const useStyles = makeStyles({
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: 20,
    '& > div': {
      color: COLORS.lightGray,
      fontSize: 20,
      fontWeight: 700,
      margin: '0 10px 37px',
    },
  },
});

export const EmptyState = ({ description }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.emptyState}>
      <img
        alt="no results"
        src="/llama-gray.svg"
      />
      <div>
        {description || 'No results'}
      </div>
    </Box>
  );
};
