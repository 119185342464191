import React, {
  useEffect,
  useState,
} from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  createStyles,
  makeStyles,
  TableBody,
  TableRow,
  Table,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { JELine } from '../../../../interfaces/journalEntries';
import { FixedAssetsColumn } from '../../../../interfaces/fixedAssets';
import { TagCategories } from '../../../../interfaces/tags';
import {
  ITableItemType,
  ITableType,
  SortOrder,
} from '../../../../interfaces/types';
import { StyledTableCell } from '../../../Table/StyledTableCell';
import COLORS from '../../../../theme/colors';
import TableComponent from '../../../Table';
import currencyFormatter from '../../../../util/currencyFormatter';
import { precisionRound } from '../../../../util/math';

const useStyles = makeStyles((theme) => createStyles({
  expandIcon: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginRight: '14px',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  subRow: {
    '& td:nth-child(-n+2)': {
      borderBottom: '0px',
    },
    '&:last-child': {
      borderBottom: '0px',
      '& > td': {
        borderBottom: '0px',
      },
    },
  },
  subTableBody: {
    '&:last-child': {
      borderBottom: '0px',
    },
  },
  headerRow: {
    fontWeight: 700,
  },
  summaryRow: {
    backgroundColor: `${COLORS.lightGrayBackground} !important`,
  },
  divider: {
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: '10%',
      top: '50%',
      width: '90%',
      height: '1px',
      backgroundColor: COLORS.lightGray2,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      left: '10%',
      top: '0',
      width: '1px',
      height: '100%',
      backgroundColor: COLORS.lightGray2,
    },
  },
  lastDivider: {
    '&:after': {
      height: '50%',
    },
  },
}));

interface Props {
  tableData: Array<JELine>;
}

export const PrepareJETable = ({
  tableData,
}: Props) => {
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const [expandedLines, setExpandedLines] = useState<Array<string>>([]);
  const classes = useStyles();

  const expandableJournalEntriesLines = tableData.filter((line) => line?.details?.length);
  const summaryCR = tableData
    .filter((asset) => asset.amount >= 0)
    .reduce((sum, asset) => precisionRound(sum + asset.amount), 0);
  const summaryDR = tableData
    .filter((asset) => asset.amount < 0)
    .reduce((sum, asset) => precisionRound(sum + asset.amount), 0);

  const renderExpandIcon = (isExpanded: boolean) => (
    <ExpandMoreIcon
      className={clsx(classes.expandIcon, {
        [classes.expandOpen]: isExpanded,
      })}
    />
  );

  const renderSummaryRow = () => (
    <TableRow className={clsx(classes.headerRow, classes.summaryRow)}>
      <StyledTableCell colSpan={7} />
      <StyledTableCell
        width="150"
        alignRight
      >
        {currencyFormatter.format(summaryCR)}
      </StyledTableCell>
      <StyledTableCell
        width="150"
        alignRight
      >
        {currencyFormatter.format(Math.abs(summaryDR))}
      </StyledTableCell>
    </TableRow>
  );

  const handleExpandAll = () => {
    setExpandedLines(isAllExpanded
      ? []
      : [...expandableJournalEntriesLines?.map((line) => line.id)]);
    setIsAllExpanded(!isAllExpanded);
  };

  const handleClickExpand = (id: string) => {
    setExpandedLines(expandedLines.includes(id)
      ? expandedLines.filter((line) => line !== id)
      : [...expandedLines, id]);
  };

  const checkIsExpanded = (id: string) => expandedLines.includes(id);

  useEffect(() => {
    const isSameLength = expandedLines?.length === expandableJournalEntriesLines?.length;
    if (isSameLength) {
      setIsAllExpanded(true);
    }

    if (isAllExpanded && !isSameLength) {
      setIsAllExpanded(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedLines.length]);

  const colProps = [
    {
      colName: 'expandColumn',
      customHeader: (
        <Box width={52}>
          <Button onClick={() => handleExpandAll()}>
            {renderExpandIcon(isAllExpanded)}
          </Button>
        </Box>
      ),
      headerStyles: { width: '52px' },
      varKey: 'expand',
      colType: ITableItemType.ACTION,
      sortable: false,
      child: (value: JELine) => (
        <StyledTableCell
          key="expand"
          colSpan={9}
          style={{ padding: '0px' }}
        >
          <Table style={{ marginBottom: '0px', tableLayout: 'fixed' }}>
            <TableBody className={classes.subTableBody}>
              <TableRow className={classes.headerRow}>
                <StyledTableCell width="52">
                  {value.details && (
                    <Button onClick={() => handleClickExpand(value.id)}>
                      {renderExpandIcon(checkIsExpanded(value.id))}
                    </Button>
                  )}
                </StyledTableCell>
                <StyledTableCell width="88">
                  {value.jeLineIndex}
                </StyledTableCell>
                <StyledTableCell minWidth="244">
                  {value.description || ''}
                </StyledTableCell>
                <StyledTableCell minWidth="152">
                  {value.contact?.name || ''}
                </StyledTableCell>
                <StyledTableCell minWidth="322">
                  {value.account.displayName || ''}
                </StyledTableCell>
                <StyledTableCell minWidth="133">
                  {value.tags.find((tag) => tag.category === TagCategories.CLASS)?.name || ''}
                </StyledTableCell>
                <StyledTableCell minWidth="166">
                  {value.tags.find((tag) => tag.category === TagCategories.LOCATION)?.name || ''}
                </StyledTableCell>
                <StyledTableCell
                  width="150"
                  alignRight
                >
                  {value.amount >= 0 && currencyFormatter.format(value.amount)}
                </StyledTableCell>
                <StyledTableCell
                  width="150"
                  alignRight
                >
                  {value.crAmount < 0 && currencyFormatter.format(Math.abs(value.crAmount))}
                </StyledTableCell>
              </TableRow>
              {checkIsExpanded(value.id) && value.details?.map((detail, detailsIndex) => (
                <TableRow
                  className={classes.subRow}
                  key={detail.sourceId}
                >
                  <StyledTableCell width="52">
                    {' '}
                  </StyledTableCell>
                  <StyledTableCell
                    className={clsx(classes.divider, {
                      [classes.lastDivider]: value.details.length === detailsIndex + 1,
                    })}
                    width="88"
                  >
                    {' '}
                  </StyledTableCell>
                  <StyledTableCell width="244">
                    {detail.description}
                  </StyledTableCell>
                  <StyledTableCell minWidth="152">
                    {detail.contact ? detail.contact.name : ''}
                  </StyledTableCell>
                  <StyledTableCell minWidth="322">
                    {value.account.displayName}
                  </StyledTableCell>
                  <StyledTableCell minWidth="133">
                    {value.classTag?.name || ''}
                  </StyledTableCell>
                  <StyledTableCell minWidth="166">
                    {value.locationTag?.name || ''}
                  </StyledTableCell>
                  <StyledTableCell
                    width="150"
                    alignRight
                  >
                    {detail.amount >= 0 && currencyFormatter.format(detail.amount)}
                  </StyledTableCell>
                  <StyledTableCell
                    width="150"
                    alignRight
                  >
                    {detail.amount < 0 && currencyFormatter.format(Math.abs(detail.amount))}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableCell>
      ),
    },
    {
      colName: FixedAssetsColumn.JE_LINE,
      varKey: 'jeLineIndex',
      colType: ITableItemType.NUMMARY,
      headerStyles: { width: '88px' },
      child: () => null,
    },
    {
      colName: FixedAssetsColumn.DESCRIPTION,
      varKey: 'description',
      colType: ITableItemType.TEXT,
      child: () => null,
    },
    {
      colName: FixedAssetsColumn.VENDOR,
      varKey: 'vendor',
      colType: ITableItemType.TEXT,
      headerStyles: { minWidth: '52px' },
      child: () => null,
      sortable: false,
    },
    {
      colName: FixedAssetsColumn.ACCOUNT,
      varKey: 'account',
      colType: ITableItemType.SORTKEY,
      child: () => null,
      sortKey: ['displayName'],
    },
    {
      colName: FixedAssetsColumn.CLASS,
      varKey: 'classTag',
      colType: ITableItemType.SORTKEY,
      sortKey: ['name'],
      child: () => null,
    },
    {
      colName: FixedAssetsColumn.LOCATION,
      varKey: 'locationTag',
      colType: ITableItemType.SORTKEY,
      sortKey: ['name'],
      child: () => null,
    },
    {
      colName: FixedAssetsColumn.DR,
      varKey: 'amount',
      colType: ITableItemType.NUMMARY,
      headerStyles: { width: '150px' },
      child: () => null,
    },
    {
      colName: FixedAssetsColumn.CR,
      varKey: 'crAmount',
      colType: ITableItemType.NUMMARY,
      headerStyles: { width: '150px' },
      child: () => null,
    },
  ];

  return (
    <TableComponent
      colProps={colProps}
      tableData={tableData}
      defaultSort={{
        key: 'jeLineIndex',
        order: SortOrder.DESC,
      }}
      contentType={ITableType.fixedAssetsAmortizationSources}
      topBarActions={[]}
      isFixed
      summaryRow={renderSummaryRow}
    />
  );
};
