/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SortUpIcon = (props: any) => (
  <SvgIcon
    fontSize="small"
    width="8"
    height="11"
    viewBox="0 0 8 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ fontSize: '12px' }}
    {...props}
  >
    <path
      d="M3.92857 0L7.33081 4.71429H0.526329L3.92857 0Z"
      fill="#CFD3DA"
    />
    <path
      d="M3.92861 11L0.526363 6.28572L7.33085 6.28572L3.92861 11Z"
      fill="#252729"
    />
  </SvgIcon>
);

export default SortUpIcon;
