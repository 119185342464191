import { SourceType } from '../interfaces/types';

const getQuickbookLink = (sourceId: string, sourceType: SourceType) => {
  switch (sourceType) {
    case SourceType.ReceivePayment:
    case SourceType.Payment:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/recvpayment?txnId=${sourceId}`;
    case SourceType.JournalEntry:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/journal?txnId=${sourceId}`;
    case SourceType.Bill:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/bill?txnId=${sourceId}`;
    case SourceType.BillPayment:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/billpayment?txnId=${sourceId}`;
    case SourceType.BillPaymentCheck:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/billpayment?txnId=${sourceId}`;
    case SourceType.BillPaymentCreditCard:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/billpaymentcc?txnId=${sourceId}`;
    case SourceType.VendorCredit:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/vendorcredit?txnId=${sourceId}`;
    case SourceType.Invoice:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/invoice?txnId=${sourceId}`;
    case SourceType.Cash:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/cashexpense?txnId=${sourceId}`;
    case SourceType.CashExpense:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/cashexpense?txnId=${sourceId}`;
    case SourceType.Check:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/check?txnId=${sourceId}`;
    case SourceType.Expense:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/expense?txnId=${sourceId}`;
    case SourceType.SalesReceipt:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/salesreceipt?txnId=${sourceId}`;
    case SourceType.Refund:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/refundreceipt?txnId=${sourceId}`;
    case SourceType.Deposit:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/deposit?txnId=${sourceId}`;
    case SourceType.CreditCardPayment:
      // TODO -- need to recheck
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/creditcardcredit?txnId=${sourceId}`;
    case SourceType.PurchaseOrder:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/purchaseorder?txnId=${sourceId}`;
    case SourceType.PayDownCreditCard:
      // TODO -- need to recheck
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/creditcardpayment?txnId=${sourceId}`;
    case SourceType.CreditCardCredit:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/creditcardcredit?txnId=${sourceId}`;
    case SourceType.CreditMemo:
      return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/creditmemo?txnId=${sourceId}`;
  }
  return '';
};

export default getQuickbookLink;
