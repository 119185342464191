import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  format,
  startOfMonth,
} from 'date-fns';
import { FULL_DATE_FORMAT } from './constants';
import { ProductCategory } from '../interfaces/types';

export const startEndDateSchema = (minDate: string, productCategory: ProductCategory) => yupResolver(
  yup.object().shape({
    ...(minDate && {
      amortizationStartDate: yup.date().nullable()
        .min(startOfMonth(new Date(minDate)),
          `${productCategory === ProductCategory.PrepaidExpense ? 'Amortization' : 'Recognition'}
            start date must be later or equal ${format(startOfMonth(new Date(minDate)), FULL_DATE_FORMAT)}`),
    }),
    amortizationEndDate: yup.date().nullable()
      .when('amortizationStartDate', {
        is: (val) => val,
        then: yup.date().min(yup.ref('amortizationStartDate'),
          `${productCategory === ProductCategory.PrepaidExpense ? 'Amortization' : 'Recognition'}
            end date must be after
          ${productCategory === ProductCategory.PrepaidExpense ? 'amortization' : 'recognition'}
            start date`),
      }),
  }),
);
