import React, {
  useMemo,
  useState,
} from 'react';
import * as yup from 'yup';
import {
  format,
  formatISO,
  startOfMonth,
} from 'date-fns';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import {
  DialogTitle,
  Typography,
  Box,
  DialogActions,
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useHistory } from 'react-router';
import find from 'lodash.find';
import { CalendarIcon } from '../../../../components/Icons';
import MonthPicker from '../../../../components/MonthPicker';
import { StyledCalendarInput } from '../../../../components/MonthPicker/index.styled';
import {
  DEFERRED_REVENUE_ACCOUNTS,
  FIXED_ASSETS,
  FIXED_ASSETS_ACCUMULATED_ACCOUNTS,
  MONTH_SHORT_FORMAT,
  PREPAID_EXPENSES,
} from '../../../../util/constants';
import {
  legacySchedulingMethodOptions,
  schedulingMethodOptions,
} from '../../../../util/helpers';
import { AppThunkDispatch } from '../../../../store/store';
import { postV2CreateSubledger } from '../../../../store/slices/v2Subledgers';
import { InputLabel } from '../../../../components/InputLabel';
import FactaAutocomplete from '../../../../components/Inputs/FactaAutocomplete';
import {
  ProductTypes,
  SchedulingMethod,
} from '../../../../interfaces/common';
import {
  accountsAccumulationListSelector,
  accountsBalanceListSelector,
} from '../../../../store/selectors/v2Accounts';
import { TooltipHeader } from '../../../../components/TooltipHeader';
import {
  postCreateSubledger,
  putSubledger,
} from '../../../../store/slices/subledger';
import { Account as V1Account } from '../../../../interfaces/types';
import { V2Subledger } from '../../../../interfaces/subledgers';
import { featureSwitch } from '../../../../util/featureSwitch';
import COLORS from '../../../../theme/colors';

const yupSchema = () => yupResolver(yup.object().shape({
  factaType: yup.object()
    .required(),
  glAccount: yup.object()
    .required(),
  accumulationAccount: yup.object({
    id: yup.string().required(),
    name: yup.string().required(),
  })
    .nullable()
    .when('factaType', {
      is: (factaType) => factaType?.value === 'fixed_assets',
      then: (schema: any) => schema.required(),
      otherwise: (schema: any) => schema.notRequired(),
    }),
  factaStartDate: yup.date().required(),
  defaultFrequency: yup.object({
    value: yup.string().required(),
    label: yup.string().required(),
  }).required(),
}));

const useStyles = makeStyles(() => createStyles({
  form: {
    minWidth: '344px',
    '& > *': {
      marginTop: '14px',
    },
  },
  inlineBoxes: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '24px',
  },
  buttonsWrapper: {
    marginTop: '40px',
  },
  placeholder: {
    color: COLORS.disabledInput,
  },
}));

interface Props {
  closeDialog: () => void;
  v1Accounts: V1Account[] | undefined;
  v2Subledgers: V2Subledger[] | undefined;
}

export const CreateSubledgerForm = ({
  closeDialog,
  v1Accounts,
  v2Subledgers,
}: Props) => {
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [isCreatingSubledger, setIsCreatingSubledger] = useState(false);
  const {
    handleSubmit,
    errors,
    control,
    formState,
    watch,
    setValue,
    trigger,
  } = useForm({
    mode: 'onChange',
    resolver: yupSchema(),
  });

  const selectedFactaType = watch('factaType');
  const allGlAccounts = useSelector(accountsBalanceListSelector);

  const v1SubledgerIds = useMemo(() => (v1Accounts?.filter((acc) => acc.subledger.id) || [])
    .map((acc) => acc.id), [v1Accounts]);
  const v2SubledgerIds = useMemo(() => v2Subledgers?.map((sub) => sub.accountId) || [], [v2Subledgers]);

  const glAccounts = useMemo(() => (selectedFactaType && allGlAccounts && allGlAccounts
    .filter((account) => account.active)
    .filter((account) => !v1SubledgerIds.includes(account.legacyId) && !v2SubledgerIds.includes(account.id))
    .filter((account) => {
      switch (selectedFactaType.value) {
        case 'prepaid':
          return account.detail === PREPAID_EXPENSES;
        case 'defrev':
          return DEFERRED_REVENUE_ACCOUNTS.includes(account.detail);
        case 'fixed_assets':
          return account.type === FIXED_ASSETS && !FIXED_ASSETS_ACCUMULATED_ACCOUNTS.includes(account.detail);
        default:
          return false;
      }
    })), [allGlAccounts, selectedFactaType, v1SubledgerIds, v2SubledgerIds]);

  const accumulationAccounts = useSelector(accountsAccumulationListSelector);

  const handleCreateSubledger = (data: any) => {
    setIsCreatingSubledger(true);

    if (selectedFactaType?.value === 'fixed_assets') {
      const subledgerData = {
        startDate: formatISO(startOfMonth(data.factaStartDate), { representation: 'date' }),
        defaultSchedulingMethod: data.defaultFrequency.value,
        accountId: data.glAccount.id,
        product: ProductTypes.FIXED_ASSETS,
        accumulationAccount: data.accumulationAccount.id,
      };
      reduxDispatch(postV2CreateSubledger({ subledgerData }))
        .unwrap()
        .then(({ id }) => {
          history.push(`/fixed-assets/create/${id}`);
          closeDialog();
        });
    } else {
      reduxDispatch(postCreateSubledger({
        accountId: data.glAccount.legacyId,
      }))
        .unwrap()
        .then((result) => {
          const amortizationScheduleType = find(legacySchedulingMethodOptions, {
            label: data.defaultFrequency.label,
          })?.value!;

          const updatedSubledger = {
            ...result,
            account: {
              ...result.account,
              scheduleType: amortizationScheduleType,
            },
            factaStartDate: formatISO(startOfMonth(data.factaStartDate)),
          };
          reduxDispatch(putSubledger({
            subledgerId: result.id,
            updatedSubledger,
          }))
            .unwrap()
            .then(() => {
              closeDialog();
              history.push(`/subledgers/scheduler/${result.id}`);
            });
        });
    }
  };

  const factaTypes = [
    {
      value: 'prepaid',
      label: 'PrepaidSync',
    },
    {
      value: 'defrev',
      label: 'RevSync',
    },
    ...(featureSwitch.fixedAssets ? [{
      value: 'fixed_assets',
      label: 'Fixed Assets',
    }] : []),
  ];

  return (
    <>
      <DialogTitle disableTypography>
        <TooltipHeader
          headerText="Create New Subledger"
          tooltipText="The Facta Start Date can be changed later by editing the subledger in the action menu."
          headerType="modal"
        />
      </DialogTitle>
      <form className={classes.form}>
        <Box>
          <InputLabel label="Facta Type" />
          <FactaAutocomplete
            control={control}
            name="factaType"
            options={factaTypes}
            disableInactiveOptions
            optionName="label"
            defaultValue={null}
            error={!!errors?.factaType}
            onChange={() => {
              setValue('glAccount', null);
              setValue('accumulationAccount', null);
              trigger('glAccount');
            }}
            placeholder="Select Facta Type"
          />
        </Box>
        <Box>
          <InputLabel label="GL Account Mapping" />
          <FactaAutocomplete
            control={control}
            name="glAccount"
            options={glAccounts}
            disableInactiveOptions
            optionName="displayName"
            defaultValue={null}
            error={!!errors?.glAccount}
            placeholder="Select Account"
          />
        </Box>
        <Box hidden={selectedFactaType?.value !== 'fixed_assets'}>
          <InputLabel label="Accumulated Depreciation Mapping" />
          <FactaAutocomplete
            control={control}
            name="accumulationAccount"
            options={accumulationAccounts}
            disableInactiveOptions
            optionName="displayName"
            defaultValue={null}
            error={!!errors?.accumulationAccount}
            placeholder="Select Account"
          />
        </Box>
        <Box className={classes.inlineBoxes}>
          <Box>
            <InputLabel
              label="Facta Start Date"
              tooltipText="Facta Start Date will always be the first day of the month."
              centered
            />
            <Controller
              name="factaStartDate"
              control={control}
              defaultValue={null}
              render={({ onChange, value }) => (
                <MonthPicker
                  selectedDate={null}
                  onDateChange={(date) => onChange(date)}
                  customInput={(
                    <StyledCalendarInput withError={!!errors?.factaStartDate}>
                      {value
                        ? <Typography>{format(value, MONTH_SHORT_FORMAT)}</Typography>
                        : <Typography className={classes.placeholder}>mm/dd/yyyy</Typography>}
                      <CalendarIcon />
                    </StyledCalendarInput>
                  )}
                />
              )}
            />
          </Box>
          <Box>
            <InputLabel
              label="Default Frequency"
              centered
              keepHeight
            />
            <FactaAutocomplete
              control={control}
              name="defaultFrequency"
              options={schedulingMethodOptions}
              optionName="label"
              defaultValue={find(schedulingMethodOptions, { value: SchedulingMethod.PERIODIC })}
              error={!!errors?.defaultFrequency}
              placeholder="Select"
            />
          </Box>
        </Box>
        <DialogActions className={classes.buttonsWrapper}>
          <Button
            variant="outlined"
            onClick={closeDialog}
          >
            Cancel
            {isCreatingSubledger && (
              <CircularProgress
                size={20}
                style={{ position: 'absolute' }}
              />
            )}
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={!formState.isValid || isCreatingSubledger}
            onClick={handleSubmit(handleCreateSubledger)}
          >
            Create Subledger
            {isCreatingSubledger && (
              <CircularProgress
                size={20}
                style={{ position: 'absolute' }}
              />
            )}
          </Button>
        </DialogActions>
      </form>
    </>
  );
};
