import React from 'react';
import CSVReader from 'react-csv-reader';
import {
  Box,
  Button,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import COLORS from '../../../../theme/colors';

const useStyles = makeStyles(() => createStyles({
  reactCSV: {
    pointerEvents: 'none',
  },
  label: {
    color: COLORS.lightGray,
    marginRight: '8px',
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 500,
  },
  input: {
    marginRight: '26px',
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 500,
  },
}));

interface Props {
  onFileUploaded: any;
  onError: (err: Error) => void;
}

const CSVImportInput = ({
  onFileUploaded,
  onError,
}: Props) => {
  const classes = useStyles();

  return (
    <>
      <CSVReader
        onFileLoaded={onFileUploaded}
        onError={onError}
        parserOptions={{ header: true, skipEmptyLines: true }}
        inputId="csv-importer"
        cssClass={classes.reactCSV}
        inputStyle={{ display: 'none', pointerEvents: 'none' }}
        label={(
          <Box
            display="flex"
            alignItems="center"
          >
            <Button
              onClick={() => document.getElementById('csv-importer')?.click()}
              style={{ pointerEvents: 'all' }}
              variant="outlined"
            >
              Browse Files
            </Button>
          </Box>
        )}
      />
    </>
  );
};

export default CSVImportInput;
