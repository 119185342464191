import React, {
  useEffect,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  compareAsc,
  eachMonthOfInterval,
  format,
  formatISO,
  isBefore,
  lastDayOfMonth,
  parseISO,
} from 'date-fns';
import clsx from 'clsx';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import find from 'lodash.find';
import { flatten } from 'ramda';
import { InputLabel } from '../../InputLabel';
import MonthPicker from '../../MonthPicker';
import { AppThunkDispatch } from '../../../store/store';
import {
  clearReports,
  getRollforwardFixedAssetsReport,
} from '../../../store/slices/reports';
import {
  isFetchingReport,
  rollforwardFixedAssetsReportSelector,
} from '../../../store/selectors/reports';
import COLORS from '../../../theme/colors';
import currencyFormatter from '../../../util/currencyFormatter';
import {
  Account,
  NotifierType,
} from '../../../interfaces/types';
import { saveFile } from '../../../util/helpers';
import { openSnackbar } from '../../Notifier';
import { StyledTableCell } from '../../Table/StyledTableCell';
import { EmptyState } from '../../Table/EmptyState';
import {
  CalendarIcon,
  CopyIcon,
  DownloadExportIcon,
  InfoIcon,
} from '../../Icons';
import {
  FULL_DATE_FORMAT,
  MONTH_SHORT_FORMAT,
} from '../../../util/constants';
import { isFetchingSubledger } from '../../../store/selectors/subledger';
import {
  ScrollableBoxContainer,
  ScrollableTableContainer,
} from '../../ScrollableTable';
import { Loader } from '../../Loader';
import { StyledCalendarInput } from '../../MonthPicker/index.styled';
import { useReportsStyles } from '../reports.styled';
import { accountsBalanceListSelector } from '../../../store/selectors/v2Accounts';
import { subledgersFinalizedListSelector } from '../../../store/selectors/v2Subledgers';
import { getAllAccounts } from '../../../store/slices/v2Accounts';
import { getV2Subledgers } from '../../../store/slices/v2Subledgers';
import { FactaMultipicker } from '../../Inputs/FactaMultipicker';
import { V2Subledger } from '../../../interfaces/subledgers';
import { BackButton } from '../../BackButton';

const yupSchema = () => yupResolver(yup.object().shape({
  subledgerAccounts: yup.array().of(yup.object({
    id: yup.string().required(),
    name: yup.string().required(),
  }).required()),
  asOfDate: yup.date().required(),
  endDate: yup.date().required()
    .min(yup.ref('asOfDate')),
}));

interface GenerateReportData {
  subledgerAccounts: Array<Account>;
  asOfDate: Date;
  endDate: Date;
}

export const RollforwardFixedAssets = () => {
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const classes = useReportsStyles();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [startEndDates, setStartEndDates] = useState<{ startDate?: Date; endDate?: Date; datesInterval: Array<Date> }>({
    datesInterval: [],
  });

  const accountBalances = useSelector(accountsBalanceListSelector);
  const subledgers = useSelector(subledgersFinalizedListSelector);

  const isFetching = useSelector(isFetchingReport);
  const isFetchingSubledgerDetails = useSelector(isFetchingSubledger);
  const report = useSelector(rollforwardFixedAssetsReportSelector);

  const eligibleAccounts = accountBalances
    .filter(({ id }) => find(subledgers, { accountId: id }));

  useEffect(() => {
    reduxDispatch(getAllAccounts({}));
    reduxDispatch(getV2Subledgers({}));

    return () => {
      reduxDispatch(clearReports());
    };
  }, [reduxDispatch]);

  const {
    control,
    getValues,
    trigger,
    formState,
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: yupSchema(),
    shouldUnregister: true,
  });

  const handleGenerateReport: SubmitHandler<GenerateReportData> = (data) => {
    const parsedData = {
      accountsIds: data.subledgerAccounts.map((sub) => sub.id),
      startDate: formatISO(lastDayOfMonth(data.asOfDate), { representation: 'date' }),
      endDate: formatISO(lastDayOfMonth(data.endDate), { representation: 'date' }),
    };
    reduxDispatch(getRollforwardFixedAssetsReport(parsedData));
    setIsFormDirty(false);
  };

  const handleCSVTSVData = (data: GenerateReportData, type: string) => {
    const parsedData = {
      accountsIds: data.subledgerAccounts.map((sub) => sub.id),
      startDate: formatISO(lastDayOfMonth(data.asOfDate), { representation: 'date' }),
      endDate: formatISO(lastDayOfMonth(data.endDate), { representation: 'date' }),
      accept: type,
    };
    reduxDispatch(getRollforwardFixedAssetsReport(parsedData))
      .unwrap()
      .then(({ result, filename }) => {
        if (type === 'text/csv') {
          saveFile(result as string, filename || 'report', type);
        }
        if (type === 'text/tsv') {
          navigator.clipboard.writeText(result as string);
          openSnackbar({ message: 'Copied to clipboard' }, NotifierType.Success);
        }
      });
  };

  const handleAccountsChange = () => {
    const accountsData = getValues('subledgerAccounts');
    const subledgersData: Array<V2Subledger> = accountsData && subledgers
      .filter((sub) => !!find(accountsData, { id: sub.accountId }));
    reduxDispatch(clearReports());

    if (subledgersData.length > 0) {
      const earliestStartDate = subledgersData
        .reduce((prev, curr) => (compareAsc(
          parseISO(prev.startDate),
          parseISO(curr.startDate),
        ) ? prev : curr)).startDate;

      const oldestCurrentPeriodEnd = subledgersData
        .reduce((prev, curr) => (compareAsc(
          parseISO(curr.currentPeriodEnd),
          parseISO(prev.currentPeriodEnd),
        ) ? prev : curr)).currentPeriodEnd;

      setStartEndDates({
        startDate: parseISO(earliestStartDate),
        endDate: parseISO(oldestCurrentPeriodEnd),
        datesInterval: eachMonthOfInterval({
          start: parseISO(earliestStartDate),
          end: parseISO(oldestCurrentPeriodEnd),
        }),
      });
      reset({
        subledgerAccounts: accountsData,
        asOfDate: parseISO(earliestStartDate),
        endDate: parseISO(oldestCurrentPeriodEnd),
        requiredEndDate: false,
      });
    } else {
      reset({ subledgerAccounts: null, asOfDate: null, endDate: null, requiredEndDate: false });
      setIsFormDirty(false);
    }
  };

  const scheduleHeaders = report?.rows?.length
    ? report.rows[0].rollforwardSchedule.map((rollforward, index) => {
      const isOdd = index % 2;

      return (
        [
          {
            label: 'Additions',
            className: clsx({
              [classes.leftSeparator]: index === 0,
              [classes.odd]: isOdd,
            }),
            id: uuidv4(),
          },
          {
            label: 'Depreciation',
            className: clsx({ [classes.odd]: isOdd }),
            id: uuidv4(),
          },
          {
            label: `Balance as of ${format(parseISO(rollforward.stepDate), FULL_DATE_FORMAT)}`,
            className: clsx({ [classes.odd]: isOdd }),
            minWidth: 100,
            id: uuidv4(),
          },
        ]
      );
    })
    : [];

  const reportTableHeaders: Array<{ label: string; className?: string; minWidth?: number; id?: string }> = [
    {
      label: 'JE Date',
    },
    {
      label: 'Transaction No.',
    },
    {
      label: 'Account No.',
      minWidth: 100,
    },
    {
      label: 'Account',
      minWidth: 185,
    },
    {
      label: 'Description',
      minWidth: 185,
    },
    {
      label: 'Vendor',
      minWidth: 160,
    },
    {
      label: 'Original Amount',
    },
    {
      label: `Beginnig balance as of ${
        report?.rows?.[0] && format(parseISO(report.rows[0]?.beginningDate), FULL_DATE_FORMAT)
      }`,
      minWidth: 125,
    },
    ...flatten(scheduleHeaders),
  ];

  const renderTableBody = () => (
    <>
      {report.rows.map((reportRow) => (
        <TableRow key={`${reportRow.id}`}>
          <StyledTableCell>{reportRow.jeDate && format(parseISO(reportRow.jeDate), FULL_DATE_FORMAT)}</StyledTableCell>
          <StyledTableCell>{reportRow.transactionId && reportRow.transactionId.split(':').shift()}</StyledTableCell>
          <StyledTableCell>{reportRow.accountNo}</StyledTableCell>
          <StyledTableCell>{reportRow.accountName}</StyledTableCell>
          <StyledTableCell>{reportRow.description}</StyledTableCell>
          <StyledTableCell>{reportRow.vendorName}</StyledTableCell>
          <StyledTableCell>{currencyFormatter.format(reportRow.originalAmount)}</StyledTableCell>
          <StyledTableCell>{currencyFormatter.format(reportRow.beginningDateBalance)}</StyledTableCell>
          {reportRow.rollforwardSchedule.map((rollforward, index) => {
            const isOdd = index % 2;
            return (
              <React.Fragment key={`${reportRow.id}_${rollforward.stepDate}`}>
                <StyledTableCell
                  className={clsx({
                    [classes.leftSeparator]: index === 0,
                    [classes.odd]: isOdd,
                  })}
                >
                  {currencyFormatter.format(rollforward.additions)}
                </StyledTableCell>
                <StyledTableCell className={clsx({ [classes.odd]: isOdd })}>
                  {currencyFormatter.format(rollforward.depreciations)}
                </StyledTableCell>
                <StyledTableCell className={clsx({ [classes.odd]: isOdd })}>
                  {currencyFormatter.format(rollforward.balance)}
                </StyledTableCell>
              </React.Fragment>
            );
          })}
        </TableRow>
      ))}
    </>
  );

  return (
    <>
      <Loader open={isFetching || isFetchingSubledgerDetails} />
      <Box>
        <BackButton
          label="Back to report list"
          path="/reports/standard-reports"
        />
      </Box>
      <>
        <Typography className={classes.formHeader}>
          Rollforward Report
        </Typography>
        <form className={classes.form}>
          <Box className={classes.formCell}>
            <InputLabel
              label="Account name:"
              isHorizontal
            />
            <FactaMultipicker
              label="Select accounts"
              options={eligibleAccounts || []}
              control={control}
              optionName="displayName"
              name="subledgerAccounts"
              defaultValue={[]}
              value={getValues('subledgerAccounts')}
              onChange={() => {
                setIsFormDirty(true);
                handleAccountsChange();
              }}
            />
          </Box>
          <Box className={classes.formCell}>
            <InputLabel
              label="As of date:"
              isHorizontal
              disabled={!getValues('subledgerAccounts')?.length}
              tooltipText="As of date will always be the last day of the month."
              centered
            />
            <Controller
              name="asOfDate"
              control={control}
              defaultValue={null}
              render={({ onChange, value }) => (
                <MonthPicker
                  selectedDate={value}
                  minDate={getValues('asOfDate') || new Date(startEndDates.startDate!) || undefined}
                  onDateChange={(date) => {
                    setIsFormDirty(true);
                    onChange(date);
                    if (getValues('endDate') && isBefore(
                      lastDayOfMonth(getValues('endDate')),
                      lastDayOfMonth(getValues('asOfDate')),
                    )) {
                      setValue('endDate', getValues('asOfDate'));
                    }
                    trigger();
                  }}
                  disabled={!getValues('subledgerAccounts')?.length}
                  customInput={(
                    <StyledCalendarInput>
                      <Typography>{value && format(value, MONTH_SHORT_FORMAT)}</Typography>
                      <CalendarIcon />
                    </StyledCalendarInput>
                  )}
                />
              )}
            />
          </Box>
          <Box className={classes.formCell}>
            <InputLabel
              label="End date:"
              isHorizontal
              disabled={!getValues('subledgerAccounts')?.length}
              tooltipText={`End date will always be the last day of the month 
                and designates the final month of the waterfall view.`}
              centered
            />
            <Controller
              name="endDate"
              control={control}
              defaultValue={null}
              render={({ onChange, value }) => (
                <MonthPicker
                  selectedDate={value}
                  minDate={getValues('asOfDate') || new Date(startEndDates.startDate!) || undefined}
                  onDateChange={(date) => {
                    setIsFormDirty(true);
                    onChange(date);
                    trigger();
                  }}
                  disabled={!getValues('subledgerAccounts')?.length}
                  customInput={(
                    <StyledCalendarInput>
                      <Typography>{value && format(value, MONTH_SHORT_FORMAT)}</Typography>
                      <CalendarIcon />
                    </StyledCalendarInput>
                  )}
                />
              )}
            />
          </Box>
          <Button
            style={{ marginTop: '24px' }}
            variant="contained"
            color="primary"
            disabled={!formState.isValid || !isFormDirty}
            onClick={handleSubmit(handleGenerateReport)}
          >
            Generate report
          </Button>
        </form>
      </>
      <Typography className={classes.formHeader}>Report</Typography>
      <Box className={classes.actionHeader}>
        <Box
          marginRight="auto"
          marginLeft={2}
          display="flex"
          alignItems="center"
          color={COLORS.skyBlue}
        >
          {!!report?.rows?.length && (
            <>
              <InfoIcon className={classes.infoIcon} />
              <span>
                This report contains
                <strong>{` ${report.rows.length || 0} row${report.rows.length > 1 ? 's' : ''}.`}</strong>
              </span>
            </>
          )}
        </Box>
        <Button
          className={classes.actionButtons}
          disabled={!report?.rows?.length}
          color="primary"
          onClick={() => handleCSVTSVData(
            getValues() as GenerateReportData,
            'text/csv',
          )}
          endIcon={(<DownloadExportIcon />)}
        >
          Export to CSV
        </Button>
        <Button
          className={classes.actionButtons}
          disabled={!report?.rows?.length}
          color="primary"
          onClick={() => handleCSVTSVData(
            getValues() as GenerateReportData,
            'text/tsv',
          )}
          endIcon={(<CopyIcon />)}
        >
          Copy to clipboard
        </Button>
      </Box>
      {report?.rows?.length > 0 && (
        <Box className={classes.reportContainer}>
          <ScrollableBoxContainer style={{ padding: 0, margin: 0 }}>
            <ScrollableTableContainer style={{ width: '100%' }}>
              <Table
                size="small"
                stickyHeader
                aria-label="Reports table"
              >
                <TableHead>
                  <TableRow>
                    {reportTableHeaders.map(({ label, className, minWidth, id }) => (
                      <StyledTableCell
                        key={id || label}
                        className={className}
                        minWidth={minWidth ? `${minWidth}` : undefined}
                      >
                        {label}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderTableBody()}
                </TableBody>
              </Table>
            </ScrollableTableContainer>
          </ScrollableBoxContainer>
        </Box>
      )}
      {(!report?.rows?.length && formState.isSubmitted && !isFetching) && (<EmptyState />)}
    </>
  );
};
