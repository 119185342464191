import React, { useEffect } from 'react';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import FactaAutocomplete from '../../Inputs/FactaAutocomplete';
import { FormInput } from '../../Inputs/FormInput';
import FactaDatePicker from '../../DatePicker';
import { accountInfoSelector } from '../../../store/selectors/account';
import { legacySchedulingMethodOptions } from '../../../util/helpers';
import { InputLabel } from '../../InputLabel';
import { InputError } from '../../InputError';
import { commonBulkEditFormStyles } from '../index.styled';
import {
  ProductCategory,
  LegacySchedulingMethod,
} from '../../../interfaces/types';
import { startEndDateSchema } from '../../../util/schemas';

interface Props {
  onSubmit: (data: any) => void;
  isOpen: boolean;
  isFullyEditable: boolean;
  scheduleDate: string;
}

export const PrepaidAssetsForm = ({
  onSubmit,
  isOpen,
  isFullyEditable,
  scheduleDate,
}: Props) => {
  const classes = commonBulkEditFormStyles();
  const {
    accountClasses,
    accountIncomes,
    vendors,
  } = useSelector(accountInfoSelector)!;

  const {
    control,
    errors,
    handleSubmit,
    watch,
    setValue,
    reset,
    trigger,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      description: '',
      vendor: null,
      destinationId: null,
      class: null,
      amortizationStartDate: null,
      amortizationEndDate: null,
      amortizationScheduleType: null as any,
    },
    resolver: startEndDateSchema(scheduleDate, ProductCategory.PrepaidExpense),
  });

  const isManualScheduleType = watch('amortizationScheduleType')?.value === LegacySchedulingMethod.MANUAL;

  useEffect(() => {
    if (isManualScheduleType) {
      setValue('amortizationEndDate', null);
      setValue('amortizationStartDate', null);
    }
  }, [isManualScheduleType, setValue]);

  useEffect(() => {
    if (isOpen) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Box className={classes.wrapper}>
      <form
        id="hook-form"
        onSubmit={handleSubmit(onSubmit)}
        className={classes.form}
      >
        {isFullyEditable && (
          <>
            <Box>
              <InputLabel label="Description" />
              <FormInput
                control={control}
                defaultValue=""
                name="description"
                error={!!errors.description}
              />
            </Box>
            <Box>
              <InputLabel label="Vendor" />
              <FactaAutocomplete
                options={vendors}
                disableInactiveOptions
                control={control}
                optionName="displayName"
                name="vendor"
                defaultValue={null}
              />
            </Box>
          </>
        )}
        <Box>
          <InputLabel label="Expense account" />
          <FactaAutocomplete
            options={accountIncomes}
            disableInactiveOptions
            control={control}
            optionName="displayName"
            name="destinationId"
            defaultValue={null}
          />
        </Box>
        <Box>
          <InputLabel label="Class" />
          <FactaAutocomplete
            options={accountClasses}
            disableInactiveOptions
            control={control}
            optionName="className"
            name="class"
            defaultValue={null}
          />
        </Box>
        {isOpen && (
          <>
            <Box>
              <InputLabel label="Amortization Start Date" />
              <Controller
                name="amortizationStartDate"
                control={control}
                defaultValue={null}
                render={({ onChange }) => (
                  <FactaDatePicker
                    selectedDate={null}
                    error={!!errors?.amortizationStartDate}
                    disabled={isManualScheduleType}
                    onDateChange={(date) => {
                      onChange(date);
                      trigger('amortizationEndDate');
                    }}
                    openToDate={scheduleDate ? new Date(scheduleDate) : undefined}
                  />
                )}
              />
              {errors?.amortizationStartDate?.message
                && <InputError errorMessage={errors?.amortizationStartDate?.message} />}
            </Box>
            <Box>
              <InputLabel label="Amortization End Date" />
              <Controller
                name="amortizationEndDate"
                control={control}
                defaultValue={null}
                render={({ onChange }) => (
                  <FactaDatePicker
                    selectedDate={null}
                    error={!!errors?.amortizationEndDate}
                    disabled={isManualScheduleType}
                    onDateChange={(date) => onChange(date)}
                    openToDate={scheduleDate ? new Date(scheduleDate) : undefined}
                  />
                )}
              />
              {errors?.amortizationEndDate?.message
                && <InputError errorMessage={errors?.amortizationEndDate?.message} />}
            </Box>
          </>
        )}
        <Box>
          <InputLabel label="Amortization Schedule Type" />
          <FactaAutocomplete
            options={legacySchedulingMethodOptions}
            control={control}
            optionName="label"
            name="amortizationScheduleType"
            defaultValue={null}
          />
        </Box>
      </form>
    </Box>
  );
};
