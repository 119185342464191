import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const settingsSelector = (state: RootState) => state.settings;

export const meSelector = createSelector(
  settingsSelector,
  (settings) => settings.me,
);

export const isFetchingMe = createSelector(
  settingsSelector,
  (settings) => settings.isFetchingMe,
);

export const isFetchingCompanyUsers = createSelector(
  settingsSelector,
  (settings) => settings.isFetchingCompanyUsers,
);

export const companyUsersSelector = createSelector(
  settingsSelector,
  (settings) => settings.companyUsers,
);

export const fetchingCompanyUsersError = createSelector(
  settingsSelector,
  (settings) => settings.error,
);
