import { ProductTypes } from './common';

export enum AccountClassification {
  ASSET = 'asset',
  EQUITY = 'equity',
  LIABILITY = 'liability',
  EXPENSE = 'expense',
  REVENUE = 'revenue',
}

export enum AccumulationAccountTypes {
  ACCUMULATED_AMORTIZATION = 'AccumulatedAmortization',
  ACCUMULATED_DEPLETION = 'AccumulatedDepletion',
  ACCUMULATED_DEPRECIATION = 'AccumulatedDepreciation',
}

export interface Account {
  id: string;
  legacyId: string;
  displayName: string;
  number: string;
  name: string;
  type: string;
  detail: string;
  classification: AccountClassification;
  active: boolean;
  balance: number;
  product: ProductTypes;
  visible: boolean;
}

export enum AccountsFilters {
  INCOME_STATEMENT = 'income_statement',
  BALANCE_SHEET = 'balance_sheet',
}
