import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import DialogBox from '../DialogBox';

interface Props {
  _key: string;
  shouldPreventNavigation: boolean;
  onConfirm: () => boolean;
  onCancel: () => boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  title: string;
  message: string;
}

export const RouterPrompt = ({
  _key,
  shouldPreventNavigation,
  onConfirm,
  onCancel,
  confirmLabel,
  cancelLabel,
  title,
  message,
}: Props) => {
  const history = useHistory();
  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');
  const unblockRef = useRef<any>(null);

  useEffect(() => {
    if (shouldPreventNavigation) {
      unblockRef.current = history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return 'true';
      });
    } else if (unblockRef.current) {
      unblockRef.current();
    }

    return () => {
      if (unblockRef.current) {
        unblockRef.current();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_key, shouldPreventNavigation]);

  const handleOK = useCallback(async () => {
    if (onConfirm) {
      const canRoute = await Promise.resolve(onConfirm());
      if (canRoute) {
        setShowPrompt(false);
        history.block(() => {});
        history.push(currentPath);
      }
    }
  }, [currentPath, history, onConfirm]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  return showPrompt ? (
    <DialogBox
      openDialog={showPrompt}
      closeDialog={handleCancel}
      dialogContext={message}
      dialogTitle={title}
      dismissContext={cancelLabel || 'Cancel'}
      actions={[
        {
          title: confirmLabel || 'Confirm',
          event: handleOK,
        },
      ]}
    />
  ) : null;
};
