import React, {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import {
  useHistory,
  useLocation,
} from 'react-router';
import {
  createStyles,
  Snackbar,
} from '@material-ui/core';
import jwtDecode from 'jwt-decode';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  FactaMessage,
  FactaLoginBackgroundLogo,
  FactaLoginMessage,
  AuthContainer,
  AuthDivider,
  FactaDisconnectMessage,
  FactaIntuitLoginImage,
  FactaSecondaryMessage,
} from './Auth.styled';
import {
  deleteToken,
  getErrorFromCookie,
  getToken,
  isExpired,
  parseJwt,
} from '../../util/auth';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../interfaces/types';
import Alert from '../../components/Alert';
import { STATUS_DISCONNECTED } from '../../util/constants';
import { AppThunkDispatch } from '../../store/store';
import {
  clearAccountInfo,
  getAccountSync,
} from '../../store/slices/account';
import {
  accountInfoSelector,
  isFetchingAccountInfo,
  fetchingAccountError,
} from '../../store/selectors/account';
import { postCompaniesDisconnected } from '../../store/slices/company';
import { Loader } from '../../components/Loader';

const useStyles = makeStyles(() => createStyles({
  mb16: {
    marginBottom: 16,
  },
  secondaryMessage: {
    maxWidth: 355,
  },
}));

const Login = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const history = useHistory();
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const accountInfo = useSelector(accountInfoSelector);
  const error = useSelector(fetchingAccountError);
  const isFetching = useSelector(isFetchingAccountInfo);

  const classes = useStyles();
  const success = useQuery()
    ?.get('success');
  const status = useQuery()
    ?.get('status');
  const token = useQuery()
    ?.get('token');
  const [isTokenExpired, setIsTokenExpired] = useState<boolean>(false);
  const [company, setCompany] = useState<string | null>(null);

  useEffect(() => {
    const checkAccountInfo = async () => {
      if (accountInfo && !getToken()) {
        reduxDispatch(clearAccountInfo());
      }

      if (token) {
        const jwtToken = jwtDecode(token);
        if (jwtToken) {
          // check token expiry
          if (!isExpired(jwtToken)) {
            // @ts-ignore
            setCompany(jwtToken?.company);
          } else {
            setIsTokenExpired(true);
          }
        }
      }

      if (success) {
        if (success === 'true') {
          // check if the token has company id or not
          const parseToken = parseJwt(getToken() as string);
          if (parseToken?.company) {
            await reduxDispatch(getAccountSync())
              .then(() => {
                history.push('/');
              });
          } else {
            history.push('/settings/connect');
          }
        } else {
          const requestError = getErrorFromCookie();
          if (requestError) {
            openSnackbar({ message: requestError }, NotifierType.Error);
          }
        }
      } else {
        deleteToken();
      }
    };
    checkAccountInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status === STATUS_DISCONNECTED) {
      reduxDispatch(postCompaniesDisconnected());
    }
  }, [status, reduxDispatch]);

  const onLogin = () => {
    if (token) {
      window.location.href = `${process.env.REACT_APP_API_URL}/auth/login?id=${token}`;
    } else {
      window.location.href = `${process.env.REACT_APP_API_URL}/v2/auth/qbo/login`;
    }
  };

  return (
    <>
      <Snackbar
        open={Boolean(error)}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert severity="error">
          {error?.message}
        </Alert>
      </Snackbar>
      <Loader open={isFetching} />
      <AuthContainer
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        m={0}
        p={0}
        pl="10%"
        mt="-10%"
        width="100%"
      >
        <FactaLoginBackgroundLogo src="/facta_login_background_logo.svg" />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="row"
        >
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="center"
            flexDirection="column"
            alignSelf="flex-start"
          >
            <img
              alt="Facta"
              src="/full_logo.png"
              height={85}
              width={250}
            />
            <AuthDivider />
            <FactaLoginMessage>
              The automated solution for business expenses
            </FactaLoginMessage>
          </Box>
          {
              !isTokenExpired ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  ml={32}
                >
                  <FactaMessage>
                    {`Connect to ${company ?? ''} Facta`}
                  </FactaMessage>
                  <ButtonBase
                    onClick={onLogin}
                  >
                    <FactaIntuitLoginImage
                      alt="Sign in with Intuit"
                      src="/sign-in-intuit.svg"
                    />
                  </ButtonBase>
                </Box>
              )
                : (
                  <Box
                    display="flex"
                    alignItems="left"
                    justifyContent="center"
                    flexDirection="column"
                    ml={32}
                  >
                    <FactaMessage className={classes.mb16}>
                      Your invite has expired
                    </FactaMessage>
                    <FactaSecondaryMessage className={classes.secondaryMessage}>
                      Please contact your admin for a new invitation. We look forward to seeing you!
                    </FactaSecondaryMessage>
                  </Box>
                )
            }
          {status === STATUS_DISCONNECTED && (
            <Box
              marginLeft={30}
              width={450}
              display="flex"
              flexDirection="column"
            >
              <Alert severity="error">
                <FactaDisconnectMessage>
                  Facta has been disconnected from QuickBooks.
                </FactaDisconnectMessage>
                <FactaDisconnectMessage>
                  Click the Connect button to re-establish connection.
                </FactaDisconnectMessage>
              </Alert>
            </Box>
          )}
        </Box>
      </AuthContainer>
    </>
  );
};

export default React.memo(Login);
