import React from 'react';
import {
  Button,
  makeStyles,
} from '@material-ui/core';
import COLORS from '../../../../theme/colors';

interface ClearAllButtonProps {
  onClick: () => void;
  children?: React.ReactNode;
  disabled?: boolean;
}

const useStyles = makeStyles({
  clearButton: {
    display: 'flex',
    margin: 'auto',
    fontSize: '12px',
    lineHeight: '16px',
    width: 'auto',
    whiteSpace: 'nowrap',
    padding: '4px 8px',
    color: COLORS.skyBlue,
    borderColor: COLORS.skyBlue,
  },
});

export const ClearAllButton = ({
  onClick,
  children,
  disabled,
}: ClearAllButtonProps) => {
  const styles = useStyles();

  return (
    <Button
      type="button"
      onClick={onClick}
      fullWidth
      variant="outlined"
      className={styles.clearButton}
      disabled={disabled}
    >
      {children || 'Clear all'}
    </Button>
  );
};
