import {
  TableCell,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  getAccountNameFromList,
  getClassName,
  getParent,
  getPrepaidScheduleAmountForMonth,
  getCustomerName,
  getProductName,
} from './common';
import currencyFormatter from '../../util/currencyFormatter';
import { useTableStyles } from './Subledger.styled';
import { accountInfoSelector } from '../../store/selectors/account';
import { AmortizationSource } from '../../interfaces/types';

interface Props {
  index: number;
  amortizationSource: AmortizationSource;
  amortizationSources: Array<AmortizationSource>;
  scheduleDate: string;
}

const PostJERevSyncRow = ({ index, amortizationSource, amortizationSources, scheduleDate }: Props) => {
  const tableClasses = useTableStyles();
  const account = useSelector(accountInfoSelector)!;
  const { customers, products, accountIncomes, accountClasses, classTrackingPerTxnLine, classTrackingPerTxn } = account;
  const prepaidAssetAmount = getPrepaidScheduleAmountForMonth(amortizationSource, scheduleDate);
  const parent = getParent(amortizationSource?.parentId, amortizationSources);
  return (
    <TableRow
      hover
    >
      <TableCell className={tableClasses.assetCell}>
        {index + 1}
      </TableCell>
      <TableCell className={tableClasses.assetCell}>
        {amortizationSource.parentId ? parent?.sourceId : amortizationSource.sourceId}
      </TableCell>
      <TableCell className={tableClasses.descriptionCell}>
        {amortizationSource.description ? amortizationSource?.description : parent?.description}
      </TableCell>
      <TableCell className={tableClasses.customerCell}>
        {getCustomerName(amortizationSource.parentId
          ? parent?.customerId! : amortizationSource.customerId, customers)}
      </TableCell>
      <TableCell className={tableClasses.customerCell}>
        {getProductName(amortizationSource.parentId
          ? parent?.productId! : amortizationSource.productId, products)}
      </TableCell>
      <TableCell className={tableClasses.expenseAccountCell}>
        {
          getAccountNameFromList(amortizationSource?.amortizationSchedule?.destinationId!, accountIncomes)
        }
      </TableCell>
      {
        (classTrackingPerTxnLine || classTrackingPerTxn) && (
          <TableCell className={tableClasses.classesCell}>
            {
              getClassName(amortizationSource?.amortizationSchedule?.classId!, accountClasses)
            }
          </TableCell>
        )
      }
      <TableCell className={tableClasses.prepaidAssetAmount}>
        {
            amortizationSource?.amortizationSchedule?.amortizationScheduleDetails?.length
            && prepaidAssetAmount && prepaidAssetAmount < 0
              ? currencyFormatter.format(Math.abs(Number(prepaidAssetAmount!))) : '-'
        }
      </TableCell>
      <TableCell className={tableClasses.prepaidAssetAmount}>
        {
          amortizationSource?.amortizationSchedule?.amortizationScheduleDetails?.length
          && prepaidAssetAmount && prepaidAssetAmount > 0
            ? currencyFormatter.format(Math.abs(Number(prepaidAssetAmount!))) : '-'
        }
      </TableCell>
    </TableRow>
  );
};

export default React.memo(PostJERevSyncRow);
