import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const jeSelector = (state: RootState) => state.je;

export const subledgerWithJeSelector = createSelector(
  jeSelector,
  (je) => je.subledger,
);

export const isFetchingSubledgerWithJe = createSelector(
  jeSelector,
  (je) => je.isFetching,
);

export const fetchingSubledgerWithJeError = createSelector(
  jeSelector,
  (je) => je.error,
);

export const journalEntryFilterDatesSelector = createSelector(
  jeSelector,
  (je) => je.filterDates,
);

export const isFetchingJournalEntryCSV = createSelector(
  jeSelector,
  (je) => je.isFetchingCSV,
);

export const journalEntrySelector = createSelector(
  jeSelector,
  (je) => je.journalEntry,
);
