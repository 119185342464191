import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const reportsState = (state: RootState) => state.reports;

export const isFetchingReport = createSelector(
  reportsState,
  (reports) => reports.isFetching,
);

export const waterfallLegacyReportSelector = createSelector(
  reportsState,
  (reports) => reports.waterfallLegacyReport,
);

export const rollforwardLegacyReportSelector = createSelector(
  reportsState,
  (reports) => reports.rollforwardLegacyReport,
);

export const waterfallFixedAssetsReportSelector = createSelector(
  reportsState,
  (reports) => reports.waterfallFixedAssetsReport,
);

export const rollforwardFixedAssetsReportSelector = createSelector(
  reportsState,
  (reports) => reports.rollforwardFixedAssetsReport,
);
