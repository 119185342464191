export enum ContactTypes {
  VENDOR = 'vendor',
  CUSTOMER = 'customer',
}

export interface Contact {
  id: string;
  externalId: string;
  name: string;
  active: boolean;
  contactType: ContactTypes;
}
