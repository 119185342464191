import {
  makeStyles,
  createStyles,
} from '@material-ui/core';
import COLORS from '../../../../theme/colors';

export const commonFiltersStyles = makeStyles(() => createStyles({
  filterButton: {
    justifyContent: 'space-between',
    fontSize: '12px',
    lineHeight: 1,
    fontWeight: 500,
    textAlign: 'left',
    margin: 0,
    color: COLORS.grayNeutral,
    '& .MuiButton-endIcon': {
      marginLeft: 'min(10px, 50px)',
    },
  },
  selected: {
    borderColor: COLORS.skyBlue,
    color: COLORS.black,
  },
  filterIndicator: {
    margin: '0 4px',
    '& span': {
      fontWeight: 700,
      color: COLORS.skyBlue,
    },
  },
  filterDot: {
    margin: '0 4px 0 8px',
    width: '8px',
    '&:after': {
      content: '""',
      display: 'block',
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      backgroundColor: COLORS.skyBlue,
    },
  },
  paper: {
    margin: '8px 0',
    padding: '8px',
  },
  searchField: {
    margin: '0',
    boxShadow: 'none',
    '& .MuiInputBase-input': {
      paddingTop: '8px',
      paddingBottom: '8px',
    },
  },
  buttonBox: {
    marginTop: '32px',
    display: 'flex',
    '& > *': {
      width: '50%',
      margin: 0,
    },
    '& > :first-of-type': {
      marginRight: '8px',
    },
  },
  applyButton: {
    color: COLORS.white,
  },
  radioButton: {
    padding: '6px 10px !important',
    '& + span': {
      fontSize: '12px',
      lineHeight: '15px',
      color: COLORS.lightGray,
    },
  },
  inputsBox: {
    marginBottom: '32px',
    display: 'flex',
    '& > *': {
      width: '50%',
    },
    '& > :first-of-type': {
      marginRight: '8px',
    },
    '& span': {
      fontSize: '10px',
      padding: '4px 16px 8px',
    },
  },
}));
