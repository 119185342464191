import React from 'react';
import {
  endOfMonth,
  isWithinInterval,
  parse,
  startOfMonth,
} from 'date-fns';
import TableBody from '@material-ui/core/TableBody';
import {
  AmortizationSource,
  BulkEditorType,
} from '../../interfaces/types';
import { getChildren } from './common';
import {
  ASSET_SCHEDULED_STATUS,
  ASSET_TEMP_SCHEDULED_STATUS,
  DAY_SHORT_FORMAT,
  FACTA_SOURCE,
} from '../../util/constants';
import PrepaidSchedulerRevSyncCurrentMonthAssetRow from './PrepaidSchedulerRevSyncCurrentMonthAssetRow';
import PrepaidSchedulerRevSyncTableHeader from './PrepaidSchedulerRevSyncTableHeader';
import UnScheduledRevSyncRow from './UnScheduledRevSyncRow';
import AddSplitButtonRow from './AddSplitButtonRow';

interface Props {
  scheduleDate: string;
  selectedRow: string;
  subledgerId: string;
  amortizationSources: AmortizationSource[];
  onSave: (asset: AmortizationSource) => () => Promise<void>;
  onRowSelect: (internalId: string) => () => void;
  isSaveEnabled: (asset: AmortizationSource) => boolean;
  onSelectAutoCompleteChange: (propertyName: string, internalId: string) => (value: string) => void;
  onDateChange: (propertyName: string, internalId: string, value: Date | null) => void;
  onAutoCompleteChange: (propertyName: string, internalId: string) => (value: string) => void;
  onInputBoxChange: (propertyName: string, internalId: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  onInputBoxFocus: (propertyName: string, internalId: string) => (event: React.FocusEvent<HTMLInputElement>) => void;
  addSplit: (internalId: string) => () => void;
  addSplitAsset: (internalId: string) => () => void;
  removeAsset: (internalId: string) => () => void;
  onShowDetails: (internalId: string, sourceId: string) => void;
  onCheckboxChange: (internalId: string) => void;
  showBulkEdit: boolean;
  checkIsElementSelected: (internalId: string) => boolean;
  onSelectAll: (assetsType: BulkEditorType) => void;
  isAllSelected: (assetsType: BulkEditorType) => boolean;
  bulkEditorType: BulkEditorType;
  page: number;
  pageSize: number;
  tab: BulkEditorType;
}

const PrepaidSchedulerRevSyncTransactions = ({
  scheduleDate,
  selectedRow,
  subledgerId,
  amortizationSources,
  onSave,
  onRowSelect,
  isSaveEnabled,
  onSelectAutoCompleteChange,
  onDateChange,
  onAutoCompleteChange,
  onInputBoxChange,
  onInputBoxFocus,
  removeAsset,
  onShowDetails,
  addSplitAsset,
  addSplit,
  onCheckboxChange,
  showBulkEdit,
  checkIsElementSelected,
  onSelectAll,
  isAllSelected,
  bulkEditorType,
  page,
  pageSize,
  tab,
}: Props) => {
  const renderRow = (asset: AmortizationSource, type?: BulkEditorType) => {
    const children = getChildren(asset.internalId, amortizationSources);
    const isSelectableForBulkEdit = !bulkEditorType || type === bulkEditorType;
    const parent = (
      <PrepaidSchedulerRevSyncCurrentMonthAssetRow
        asset={asset}
        amortizationSources={amortizationSources}
        hasChildren={!!children?.length}
        selectedRow={selectedRow}
        onRowSelect={onRowSelect}
        isSaveEnabled={isSaveEnabled}
        onSave={onSave}
        onShowDetails={onShowDetails}
        onSelectAutoCompleteChange={onSelectAutoCompleteChange}
        onCheckboxChange={onCheckboxChange}
        showBulkEdit={showBulkEdit}
        isSelectedForBulkEdit={checkIsElementSelected(asset.internalId)}
        isSelectable={isSelectableForBulkEdit}
      />
    );
    if (!children?.length) {
      return parent;
    }
    return (
      <>
        {parent}
        {children?.map((child: AmortizationSource, childIndex: number) => (
          <PrepaidSchedulerRevSyncCurrentMonthAssetRow
            key={child.internalId}
            asset={child}
            amortizationSources={amortizationSources}
            hasChildren={false}
            lastChild={children?.length - 1 === childIndex}
            selectedRow={selectedRow}
            onRowSelect={onRowSelect}
            isSaveEnabled={isSaveEnabled}
            onSave={onSave}
            onShowDetails={onShowDetails}
            onSelectAutoCompleteChange={onSelectAutoCompleteChange}
            onCheckboxChange={onCheckboxChange}
            showBulkEdit={showBulkEdit}
            isSelectedForBulkEdit={checkIsElementSelected(child.internalId)}
          />
        ))}
      </>
    );
  };

  const renderUnScheduledRow = (amortizationSource: AmortizationSource, type?: BulkEditorType) => {
    const children = getChildren(amortizationSource.internalId, amortizationSources);
    const saveTitle = [ASSET_SCHEDULED_STATUS, ASSET_TEMP_SCHEDULED_STATUS].includes(amortizationSource.status)
      ? 'Save' : 'Schedule';
    const isSelectableForBulkEdit = !bulkEditorType || type === bulkEditorType;
    const parent = (
      <UnScheduledRevSyncRow
        selectedRow={selectedRow}
        childrenPrepaidAssets={children}
        addSplit={addSplit}
        amortizationSource={amortizationSource}
        hasChildren={!!children?.length}
        isSaveEnabled={isSaveEnabled}
        onDateChange={onDateChange}
        onInputBoxChange={onInputBoxChange}
        onInputBoxFocus={onInputBoxFocus}
        onSave={onSave}
        onAutoCompleteChange={onAutoCompleteChange}
        parentAsset={amortizationSource}
        removeAsset={removeAsset}
        onRowSelect={onRowSelect}
        key={amortizationSource.internalId}
        saveTitle={saveTitle}
        subledgerId={subledgerId}
        onShowDetails={onShowDetails}
        onCheckboxChange={onCheckboxChange}
        showBulkEdit={showBulkEdit}
        isSelectedForBulkEdit={checkIsElementSelected(amortizationSource.internalId)}
        isSelectable={isSelectableForBulkEdit}
      />
    );
    if (!children?.length) {
      return parent;
    }
    return (
      <>
        {parent}
        {children?.map((child: AmortizationSource, index: number) => (
          <UnScheduledRevSyncRow
            onRowSelect={onRowSelect}
            selectedRow={selectedRow}
            childrenPrepaidAssets={children}
            addSplit={addSplit}
            amortizationSource={child}
            hasChildren={false}
            isSaveEnabled={isSaveEnabled}
            onDateChange={onDateChange}
            onInputBoxChange={onInputBoxChange}
            onInputBoxFocus={onInputBoxFocus}
            onSave={onSave}
            onAutoCompleteChange={onAutoCompleteChange}
            parentAsset={amortizationSource}
            removeAsset={removeAsset}
            key={child.internalId}
            lastChild={index === children?.length - 1 && selectedRow !== amortizationSource?.internalId}
            saveTitle={saveTitle}
            subledgerId={subledgerId}
            onShowDetails={onShowDetails}
            onCheckboxChange={onCheckboxChange}
            showBulkEdit={showBulkEdit}
            isSelectedForBulkEdit={checkIsElementSelected(child.internalId)}
          />
        ))}
        {
          selectedRow === amortizationSource?.internalId && (
            <AddSplitButtonRow
              addSplitAsset={addSplitAsset(amortizationSource.internalId)}
            />
          )
        }
      </>
    );
  };

  const renderAsset = () => (
    <>
      {tab === BulkEditorType.UNSCHEDULED && amortizationSources
        ?.filter((amortizationSource) => !amortizationSource.parentId)
        ?.splice(page * pageSize, pageSize)
        ?.map((amortizationSource) => renderUnScheduledRow(amortizationSource, BulkEditorType.UNSCHEDULED))}

      {tab === BulkEditorType.SCHEDULED && amortizationSources
        ?.filter((amortizationSource) => !amortizationSource.parentId)
        ?.splice(page * pageSize, pageSize)
        ?.map((asset: AmortizationSource) => {
          if (asset.origin !== FACTA_SOURCE
            && isWithinInterval(asset.sourceCreationDate!, {
              start: startOfMonth(parse(scheduleDate, DAY_SHORT_FORMAT, new Date())),
              end: endOfMonth(parse(scheduleDate, DAY_SHORT_FORMAT, new Date())),
            })
          ) {
            return renderUnScheduledRow(asset, BulkEditorType.SCHEDULED);
          }
          return renderRow(asset, BulkEditorType.SCHEDULED);
        })}
    </>
  );

  return (
    <>
      <PrepaidSchedulerRevSyncTableHeader
        onSelectAll={() => onSelectAll(tab)}
        isAllSelected={isAllSelected(tab)}
        showBulkEdit={showBulkEdit}
        amortizationSourcesLength={amortizationSources.length}
      />
      <TableBody>
        {renderAsset()}
      </TableBody>
    </>
  );
};

export default PrepaidSchedulerRevSyncTransactions;
