import React, { useEffect } from 'react';
import {
  useForm,
  Controller,
} from 'react-hook-form';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import FactaAutocomplete from '../../Inputs/FactaAutocomplete';
import FactaDatePicker from '../../DatePicker';
import { FormInput } from '../../Inputs/FormInput';
import { InputError } from '../../InputError';
import { accountInfoSelector } from '../../../store/selectors/account';
import { legacySchedulingMethodOptions } from '../../../util/helpers';
import { InputLabel } from '../../InputLabel';
import { commonBulkEditFormStyles } from '../index.styled';
import {
  ProductCategory,
  LegacySchedulingMethod,
} from '../../../interfaces/types';
import { startEndDateSchema } from '../../../util/schemas';

interface Props {
  onSubmit: (data: any) => void;
  isOpen: boolean;
  isFullyEditable: boolean;
  scheduleDate: string;
}

export const DeferredRevenueForm = ({
  onSubmit,
  isOpen,
  isFullyEditable,
  scheduleDate,
}: Props) => {
  const classes = commonBulkEditFormStyles();
  const {
    accountClasses,
    accountIncomes,
    customers,
    products,
  } = useSelector(accountInfoSelector)!;

  const {
    control,
    errors,
    handleSubmit,
    setValue,
    watch,
    reset,
    trigger,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      description: '',
      customerId: null,
      productId: null,
      destinationId: null,
      class: null,
      amortizationStartDate: null,
      amortizationEndDate: null,
      amortizationScheduleType: null as any,
    },
    resolver: startEndDateSchema(scheduleDate, ProductCategory.DeferredRevenue),
  });

  const isManualScheduleType = watch('amortizationScheduleType')?.value === LegacySchedulingMethod.MANUAL;

  useEffect(() => {
    if (isManualScheduleType) {
      setValue('amortizationEndDate', null);
      setValue('amortizationStartDate', null);
    }
  }, [isManualScheduleType, setValue]);

  useEffect(() => {
    if (isOpen) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Box className={classes.wrapper}>
      <form
        id="hook-form"
        onSubmit={handleSubmit(onSubmit)}
        className={classes.form}
      >
        {isFullyEditable && (
          <>
            <Box>
              <InputLabel label="Description" />
              <FormInput
                control={control}
                defaultValue=""
                name="description"
                error={!!errors.description}
              />
            </Box>
            <Box>
              <InputLabel label="Customer" />
              <FactaAutocomplete
                options={customers}
                disableInactiveOptions
                control={control}
                optionName="displayName"
                name="customerId"
                defaultValue={null}
              />
            </Box>
            <Box>
              <InputLabel label="Product/Service " />
              <FactaAutocomplete
                options={products}
                disableInactiveOptions
                control={control}
                optionName="name"
                name="productId"
                defaultValue={null}
              />
            </Box>
          </>
        )}
        <Box>
          <InputLabel label="Revenue account" />
          <FactaAutocomplete
            options={accountIncomes}
            disableInactiveOptions
            control={control}
            optionName="displayName"
            name="destinationId"
            defaultValue={null}
          />
        </Box>
        <Box>
          <InputLabel label="Class" />
          <FactaAutocomplete
            options={accountClasses}
            disableInactiveOptions
            control={control}
            optionName="className"
            name="class"
            defaultValue={null}
          />
        </Box>
        {isOpen && (
          <>
            <Box>
              <InputLabel label="Recognition Start Date" />
              <Controller
                name="amortizationStartDate"
                control={control}
                defaultValue={null}
                render={({ onChange, value }) => (
                  <FactaDatePicker
                    selectedDate={value}
                    error={!!errors?.amortizationStartDate}
                    disabled={isManualScheduleType}
                    onDateChange={(date) => {
                      onChange(date);
                      trigger('amortizationEndDate');
                    }}
                    openToDate={scheduleDate ? new Date(scheduleDate) : undefined}
                    isNullable
                  />
                )}
              />
              {errors?.amortizationStartDate?.message
                && <InputError errorMessage={errors?.amortizationStartDate?.message} />}
            </Box>
            <Box>
              <InputLabel label="Recognition End Date" />
              <Controller
                name="amortizationEndDate"
                control={control}
                defaultValue={null}
                render={({ onChange, value }) => (
                  <FactaDatePicker
                    selectedDate={value}
                    error={!!errors?.amortizationEndDate}
                    disabled={isManualScheduleType}
                    onDateChange={(date) => onChange(date)}
                    openToDate={scheduleDate ? new Date(scheduleDate) : undefined}
                    isNullable
                  />
                )}
              />
              {errors?.amortizationEndDate?.message
                && <InputError errorMessage={errors?.amortizationEndDate?.message} />}
            </Box>
          </>
        )}
        <Box>
          <InputLabel label="Recognition Schedule Type" />
          <FactaAutocomplete
            options={legacySchedulingMethodOptions}
            control={control}
            optionName="label"
            name="amortizationScheduleType"
            defaultValue={null}
          />
        </Box>
      </form>
    </Box>
  );
};
