import React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import Layout from '../../components/Layout';
import { isClassTrackingEnabledSelector } from '../../store/selectors/account';
import { featureSwitch } from '../../util/featureSwitch';
import { NavigationTabs } from '../../components/NavigationTabs';
import { SubledgersSheet } from './SubledgersSheet';
import { IncomeStatement } from './IncomeStatement';
import { Tags } from './Tags';
import { AssetTypes } from './AssetTypes';

const Account = () => {
  const { url } = useRouteMatch();
  const isClassTrackingEnabled = useSelector(isClassTrackingEnabledSelector)!;

  const chartOfAccountsRoutes = [
    {
      label: 'Subledgers',
      path: `${url}/subledgers`,
      component: <SubledgersSheet />,
      exact: true,
    },
    {
      label: 'Income Statement',
      path: `${url}/income-statement`,
      component: <IncomeStatement />,
      exact: true,
    },
    ...(isClassTrackingEnabled ? [{
      label: 'Classes',
      path: `${url}/classes`,
      component: <Tags tagType="classes" />,
      exact: true,
    }] : []),
    ...(featureSwitch.fixedAssets ? [{
      label: 'Locations',
      path: `${url}/locations`,
      component: <Tags tagType="locations" />,
      exact: true,
    },
    {
      label: 'Asset Types',
      path: `${url}/asset-types`,
      component: <AssetTypes />,
      exact: true,
    }] : []),
  ];

  return (
    <Layout title="Chart of Accounts">
      <NavigationTabs
        routes={chartOfAccountsRoutes}
        baseRedirect={{ fromUrl: url, pathUrl: `${url}/subledgers` }}
        noPadding
      />
    </Layout>
  );
};

export default Account;
