import clsx from 'clsx';
import {
  Link,
  TableCell,
} from '@material-ui/core';
import React from 'react';
import { useTableStyles } from '../Subledger.styled';
import { AmortizationSource } from '../../../interfaces/types';
import getQuickbookLink from '../../../util/getQuickbookLink';

interface Props {
  amortizationSource: AmortizationSource;
  hasChildren?: boolean;
  lastChild?: boolean;
  highlighted?: boolean;
  showCursor?: boolean;
}

const AmortizationSourceIdCell = ({
  amortizationSource,
  hasChildren,
  lastChild,
  highlighted,
  showCursor,
}: Props) => {
  const tableClasses = useTableStyles();
  const sourceId = amortizationSource.parentId ? '' : amortizationSource.sourceId;
  const quickbookLink = getQuickbookLink(sourceId, amortizationSource.sourceType);
  return (
    <TableCell
      className={clsx(tableClasses.assetCell, {
        [tableClasses.child]: amortizationSource.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.bold]: highlighted,
        [tableClasses.lastChild]: lastChild,
        [tableClasses.cursorPointer]: showCursor,
      })}
    >
      {(!amortizationSource?.sourceType || !quickbookLink) && sourceId}
      {quickbookLink && (
        <Link
          href={quickbookLink}
          target="_blank"
        >
          {sourceId}
        </Link>
      )}
    </TableCell>
  );
};

export default React.memo(AmortizationSourceIdCell);
