import React from 'react';
import {
  Box,
  Button,
} from '@material-ui/core';
import styled from 'styled-components';
import {
  FixedAssetForm,
  SaveMethodProps,
} from '../../../../../interfaces/fixedAssets';
import ContextMenu from '../../../../ContextMenu';
import { StyledTableCell } from '../../../../Table/StyledTableCell';
import { SchedulingMethod } from '../../../../../interfaces/common';
import { featureSwitch } from '../../../../../util/featureSwitch';
import { MoreHorizIcon } from '../../../../Icons';

interface Props {
  handleSubmit: Function;
  onSave: (props: SaveMethodProps) => void;
  onSchedule: (assetId: string, periodDepreciationAmount?: number) => void;
  onEditFixedAsset: (assetId: string) => void;
  onWriteOffFixedAsset: (assetId: string) => void;
  asset: FixedAssetForm;
  selectedRow?: string;
  isPeriodLocked: boolean;
  isAssetEditable?: boolean;
  isCurrentPeriod?: boolean;
}

const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
});

export const SaveAndScheduleCell = ({
  handleSubmit,
  onSave,
  onSchedule,
  onEditFixedAsset,
  onWriteOffFixedAsset,
  asset,
  selectedRow,
  isPeriodLocked,
  isAssetEditable,
  isCurrentPeriod,
}: Props) => {
  const menuItems = [
    {
      title: isAssetEditable ? 'Override Asset Type' : 'Update Schedule',
      disabled: isPeriodLocked,
      action: () => onEditFixedAsset(asset.id),
    },
    {
      title: 'Write-Off',
      hidden: !asset.finalized || isPeriodLocked || !isCurrentPeriod || !featureSwitch.disposals,
      action: () => onWriteOffFixedAsset(asset.id),
    },
  ];

  const handleOnClick = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const isManualScheduleType = asset.schedulingMethod?.value === SchedulingMethod.MANUAL;
    if (selectedRow === asset.id) {
      handleSubmit((data: FixedAssetForm) => onSave({
        assetId: asset.id,
        data,
        shouldSchedule: true,
        dontUpdateState: false,
      }))();
    } else {
      ev.stopPropagation();
      onSchedule(asset.id, isManualScheduleType ? asset.periodDepreciationAmount : undefined);
    }
  };

  return (
    <StyledTableCell>
      <Container>
        {!asset.finalized && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleOnClick}
            disabled={!asset.isValid}
          >
            Schedule
          </Button>
        )}
        <ContextMenu menuItems={menuItems}>
          <MoreHorizIcon style={{ fontSize: 13 }} />
        </ContextMenu>
      </Container>
    </StyledTableCell>
  );
};
