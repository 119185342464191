import {
  makeStyles,
  styled,
} from '@material-ui/core/styles';
import {
  Typography,
  createStyles,
  Theme,
} from '@material-ui/core';
import COLORS from '../../../theme/colors';

const cardStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'relative',
    margin: '30px 20px 20px',
    textAlign: 'left',
    cursor: 'pointer',
  },
  disabledBox: {
    pointerEvents: 'none',
  },
  card: {
    minWidth: 360,
    boxShadow: '0px 2px 12px rgba(24, 43, 88, 0.13)',
    '&::before': {
      position: 'absolute',
      content: "''",
      left: 0,
      top: 0,
      height: '100%',
      width: 4,
      backgroundColor: COLORS.violet,
      borderRadius: '4px 0 0 4px',
      opacity: 0,
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.short,
      }),
    },
  },
  cardError: {
    '&::before': {
      backgroundColor: COLORS.error,
    },
  },
  title: {
    fontSize: 14,
    color: COLORS.deepGray,
    fontWeight: 600,
    paddingTop: 20,
  },
  accountStatus: {
    height: 22,
    width: 22,
    background: COLORS.successGreen,
    borderRadius: 12,
    margin: 0,
    color: `${COLORS.white} !important`,
    position: 'relative',
    fontSize: 11,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pendingAssets: {
    background: `${COLORS.violet} !important`,
  },
  cardType: {
    display: 'flex',
    justifyContent: 'space-between',
    '& h4': {
      margin: '5px 0 0',
      fontSize: 10,
      fontWeight: 'normal',
      color: COLORS.lightGray,
      textTransform: 'uppercase',
    },
  },
  factaBalance: {
    color: `${COLORS.violet} !important`,
  },
  factaBalanceError: {
    color: `${COLORS.error} !important`,
  },
  schedule: {
    color: COLORS.deepGray,
  },
  amortization: {
    fontSize: 13,
    color: COLORS.lightGray,
  },
  pendingTasks: {
    background: `${COLORS.violet} !important`,
    border: `1px solid ${COLORS.violet} !important`,
    '&:hover': {
      background: ` ${COLORS.hoverBlue} !important`,
      border: `1px solid ${COLORS.hoverBlue} !important`,
    },
  },
  completedTasks: {
    background: COLORS.successGreen,
    border: `1px solid ${COLORS.successGreen}`,
  },
  checkComplete: {
    fontSize: 20,
    color: COLORS.white,
  },
  checkCompleted: {
    fontSize: 14,
    marginLeft: 9,
    color: COLORS.successGreen,
  },
  closedTag: {
    display: 'flex',
    fontSize: 10,
    fontWeight: 500,
    color: COLORS.successGreen,
    '& span': {
      marginRight: 14,
      marginTop: 5,
    },
  },
  active: {
    '&::before': {
      opacity: 1,
    },
  },
  disabled: {
    opacity: 0.5,
  },
  navigatePrepaid: {
    textDecoration: 'underline',
    color: COLORS.skyBlue,
    '& p': {
      color: COLORS.skyBlue,
    },
  },
  postDisabled: {
    background: `${COLORS.lightGray2} !important`,
    color: `${COLORS.white} !important`,
  },
  padding15: {
    paddingLeft: 15,
    paddingRight: 15,
  },
}));

export const AccountLabel = styled(Typography)({
  margin: '5px 0 0',
  fontSize: 10,
  fontWeight: 'normal',
  color: COLORS.lightGray,
  textTransform: 'uppercase',
});

export default cardStyles;
