import React from 'react';
import Scheduler from './Scheduler';
import Layout from '../../../components/Layout';

interface Props {
  scheduleDate: string;
}

const ScheduleContainer = ({
  scheduleDate,
}: Props) => (

  <Layout title="Schedule">
    <Scheduler
      scheduleDate={scheduleDate}
    />
  </Layout>
);

export default ScheduleContainer;
