import React, {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import fileDownload from 'js-file-download';
import Papa from 'papaparse';
import startCase from 'lodash.startcase';
import TableComponent, {
  IColProps,
} from '../../../components/Table';
import { StyledTableCell } from '../../../components/Table/StyledTableCell';
import {
  ITableItemType,
  ITableType,
  SortOrder,
} from '../../../interfaces/types';
import { accountsIncomeListSelector } from '../../../store/selectors/v2Accounts';
import { DEFAULT_PAGE_SIZE } from '../../../util/constants';
import { DownloadExportIcon } from '../../../components/Icons';
import { LineClamp } from '../../../components/Table/LineClamp';
import { Account } from '../../../interfaces/accounts';
import {
  getAccountIncomes,
  putAccountIncomes,
} from '../../../store/slices/account';
import { AppThunkDispatch } from '../../../store/store';
import { Loader } from '../../../components/Loader';
import {
  getAllAccounts,
  patchAccount,
} from '../../../store/slices/v2Accounts';
import HideVisibilityIcon from '../../../components/Icons/HideVisibilityIcon';
import VisibilityIcon from '../../../components/Icons/VisibilityIcon';
import { LoadingBox } from '../../../components/LoadingBox';
import { lastV2SyncSelector } from '../../../store/selectors/v2Sync';

const useStyles = makeStyles(() => createStyles({
  noOffset: {
    minHeight: 'auto',
    margin: 0,
    padding: 0,
  },
}));

export const IncomeStatement = () => {
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const accounts = useSelector(accountsIncomeListSelector);
  const lastFetch = useSelector(lastV2SyncSelector);
  const classes = useStyles();

  const onDownload = (sortedTableValues: Account[]) => {
    if (sortedTableValues?.length) {
      const accts = sortedTableValues?.map((acc) => ({
        ...acc,
        active: acc.active ? 'Active' : 'Inactive',
      }));
      const csvData = Papa.unparse({
        data: accts,
        fields: [
          'number',
          'name',
          'type',
          'detail',
          'active',
        ],
      }, {
        header: false,
      });
      const columns = [
        'Number',
        'Account Name',
        'Type',
        'Detail',
        'Active/Inactive',
      ];
      const downloadData = `${columns.join(',')}\n${csvData}`;
      fileDownload(downloadData, 'Income Statement.csv');
    }
  };

  const handleToggleVisibility = (account: Account) => {
    setIsUpdating(account.id);
    Promise.all([
      reduxDispatch(putAccountIncomes({
        id: account?.legacyId!,
        enable: !account?.visible,
      })),
      reduxDispatch(patchAccount({
        accountId: account.id,
        account: {
          visible: !account.visible,
        },
      })),
    ])
      .finally(() => setIsUpdating(''));
  };

  const defaultSort = {
    key: 'name',
    order: SortOrder.DESC,
  };

  const colProps: Array<IColProps> = [
    {
      colName: 'Number',
      varKey: 'number',
      colType: ITableItemType.TEXT,
      child: (value: Account) => (
        <StyledTableCell
          key="number"
          minWidth="100"
        >
          {value.number}
        </StyledTableCell>
      ),
    },
    {
      colName: 'Account Name',
      varKey: 'name',
      colType: ITableItemType.TEXT,
      child: (value: Account) => (
        <StyledTableCell
          key="name"
          minWidth="100"
        >
          <LineClamp text={value.name} />
        </StyledTableCell>
      ),
    },
    {
      colName: 'Type',
      varKey: 'type',
      colType: ITableItemType.TEXT,
      child: (value: Account) => (
        <StyledTableCell
          key="type"
          minWidth="100"
        >
          <LineClamp text={value.type} />
        </StyledTableCell>
      ),
    },
    {
      colName: 'Detail',
      varKey: 'detail',
      colType: ITableItemType.TEXT,
      child: (value: Account) => (
        <StyledTableCell
          key="detail"
          minWidth="100"
        >
          <LineClamp text={startCase(value.detail)} />
        </StyledTableCell>
      ),
    },
    {
      colName: 'Visibility',
      varKey: 'visibility',
      sortable: true,
      colType: ITableItemType.BOOLEAN,
      child: (value: Account) => (
        <StyledTableCell
          width={80}
          align="center"
        >
          {isUpdating === value.id && (
            <LoadingBox transparent />
          )}
          <Tooltip
            arrow
            title={value.visible ? 'Hide' : 'Show'}
          >
            <IconButton
              color={value.visible ? 'primary' : 'default'}
              className={classes.noOffset}
              disabled={!value.active}
              onClick={() => handleToggleVisibility(value)}
            >
              {value.visible ? <VisibilityIcon /> : <HideVisibilityIcon />}
            </IconButton>
          </Tooltip>
        </StyledTableCell>
      ),
    },
  ];

  const topBarActions = [
    {
      label: 'Export',
      icon: <DownloadExportIcon />,
      isData: true,
      action: onDownload,
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      reduxDispatch(getAllAccounts({})),
    ])
      .finally(() => {
        setIsLoading(false);
        reduxDispatch(getAccountIncomes());
      });
  }, [reduxDispatch, lastFetch]);

  return (
    <Box
      padding={2}
      width="100%"
      display="flex"
      flexDirection="column"
      flexGrow={1}
    >
      <Loader open={isLoading} />
      {!isLoading && (
        <TableComponent
          colProps={colProps}
          tableData={accounts}
          defaultSort={defaultSort}
          contentType={ITableType.accounts}
          topBarActions={topBarActions}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
        />
      )}
    </Box>
  );
};
