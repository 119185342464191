import {
  isValid,
  isEqual,
  parse,
  add,
  startOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
} from 'date-fns';
import {
  DAY_SHORT_FORMAT,
  CSV_IMPORT_DAY_FORMAT,
  DAY_MONTH_FORMAT,
  DAY_FORMAT,
} from '../../util/constants';

const getDatePickerDate = (keyboardInputValue: string) => {
  const keyboardInputs = keyboardInputValue.split('/');
  if (keyboardInputs?.length === 3) {
    const date = parse(keyboardInputValue, DAY_SHORT_FORMAT, new Date());
    if (isValid(date)) {
      return date;
    }
    return parse(keyboardInputValue, CSV_IMPORT_DAY_FORMAT, new Date());
  }
  if (keyboardInputs?.length === 2) {
    return parse(keyboardInputValue, DAY_MONTH_FORMAT, new Date());
  }
  if (keyboardInputs?.length === 1) {
    return parse(keyboardInputValue, DAY_FORMAT, new Date());
  }
  return null;
};

export const computeNewDate = (event: React.KeyboardEvent<HTMLDivElement>, date: Date) => {
  const currentDatePickerDate = startOfDay(date);

  switch (event.key) {
    case 't':
      return startOfDay(new Date());
    case '+':
    case '=':
      return add(currentDatePickerDate, { days: 1 });
    case '-':
      return add(currentDatePickerDate, { days: -1 });
    case 'w': {
      const currentStartOfWeek = startOfWeek(currentDatePickerDate);
      return isEqual(currentDatePickerDate, currentStartOfWeek)
        ? add(currentStartOfWeek, { weeks: -1 })
        : currentStartOfWeek;
    }
    case 'k': {
      const currentEndOfWeek = startOfDay(endOfWeek(currentDatePickerDate));
      return isEqual(currentDatePickerDate, currentEndOfWeek)
        ? add(currentEndOfWeek, { weeks: 1 })
        : currentEndOfWeek;
    }
    case 'm': {
      const currentStartOfMonth = startOfMonth(currentDatePickerDate);
      return isEqual(currentDatePickerDate, currentStartOfMonth)
        ? add(currentStartOfMonth, { months: -1 })
        : currentStartOfMonth;
    }
    case 'h': {
      const currentEndOfMonth = startOfDay(endOfMonth(currentDatePickerDate));
      return isEqual(currentDatePickerDate, currentEndOfMonth)
        ? endOfMonth(add(currentEndOfMonth, { months: 1 }))
        : currentEndOfMonth;
    }
    case 'y': {
      const currentStartOfYear = startOfYear(currentDatePickerDate);
      return isEqual(currentDatePickerDate, currentStartOfYear)
        ? add(currentStartOfYear, { years: -1 })
        : currentStartOfYear;
    }
    case 'r': {
      const currentEndOfYear = startOfDay(endOfYear(currentDatePickerDate));
      return isEqual(currentDatePickerDate, currentEndOfYear)
        ? add(currentEndOfYear, { years: 1 })
        : currentEndOfYear;
    }
    default:
      return currentDatePickerDate;
  }
};

export default getDatePickerDate;
