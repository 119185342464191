import React, {
  useEffect,
  useRef,
} from 'react';
import clsx from 'clsx';
import { Controller } from 'react-hook-form';
import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Paper,
  PaperProps,
} from '@material-ui/core';
import {
  AutocompleteBox,
  AutocompleteListBox,
  AutoCompleteTextField,
} from './index.styled';
import AutocompletePopper from './PopperComponent';
import COLORS from '../../../theme/colors';

interface Props {
  error?: boolean;
  className?: string;
  options: Array<any>;
  optionName: string;
  valueName?: string;
  disabled?: boolean;
  isVirtualizedList?: boolean;
  control: any;
  name: string;
  defaultValue: any;
  defaultInputValue?: string;
  onChange?: any;
  onBlur?: any;
  disableInactiveOptions?: boolean;
  disableHiddenOptions?: boolean;
  disableClearable?: boolean;
  additionalActions?: Array<{
    label: string;
    action: () => void;
  }>;
  placeholder?: string;
}

const useStyles = makeStyles(() => createStyles({
  error: {
    border: `1px solid ${COLORS.error}`,
  },
}));

const FactaAutocomplete = ({
  className,
  error,
  options,
  optionName,
  isVirtualizedList,
  control,
  name,
  defaultValue,
  defaultInputValue,
  onChange,
  disabled,
  onBlur,
  disableInactiveOptions,
  disableHiddenOptions,
  disableClearable,
  additionalActions,
  placeholder,
}: Props) => {
  const [inputValue, setInputValue] = React.useState('');
  const classes = useStyles();
  const highlighted = useRef<any>();

  const AutocompletePaper = ({ children, ...rest }: PaperProps) => (
    <Paper {...rest}>
      {children}
      {additionalActions && (
        <Box padding={1}>
          {additionalActions.map(({ label, action }) => (
            <Button
              type="button"
              variant="contained"
              color="primary"
              key={label}
              onMouseDown={(event) => event.preventDefault()}
              onClick={action}
              size="small"
            >
              {label}
            </Button>
          ))}
        </Box>
      )}
    </Paper>
  );

  const onInputChange = (_: any, value: string) => {
    setInputValue(value);
    highlighted.current = '';
  };

  useEffect(() => {
    if (defaultInputValue) {
      setInputValue(defaultInputValue);
    }
  }, [defaultInputValue]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={(props) => (
        <AutocompleteBox
          {...props}
          onKeyDown={(ev) => {
            if (ev.key === 'Tab' && highlighted.current) {
              props.onChange(highlighted.current);
            }
          }}
          onHighlightChange={(_, option, reason) => {
            if (reason === 'keyboard') highlighted.current = option;
          }}
          disableClearable={disableClearable}
          autoHighlight={inputValue.length > 0}
          className={clsx(className, { [classes.error]: Boolean(error) })}
          options={options || []}
          {...(isVirtualizedList
            ? { ListboxComponent: AutocompleteListBox as React.ComponentType<React.HTMLAttributes<HTMLElement>> }
            : { PopperComponent: AutocompletePopper })}
          PaperComponent={AutocompletePaper}
          getOptionLabel={(option: any) => option?.[optionName]}
          getOptionSelected={(option: any, value: any) => option?.[optionName] === value?.[optionName]}
          getOptionDisabled={(option: any) => (
            (!!disableInactiveOptions && option.active === false)
            || (!!disableHiddenOptions && option.visible === false)
          )}
          onChange={(_, data) => {
            props.onChange(data);
            onChange && onChange();
          }}
          openOnFocus
          {...(defaultInputValue && { inputValue })}
          defaultValue={defaultValue}
          onInputChange={onInputChange}
          disabled={disabled}
          onBlur={() => {
            props.onBlur();
            onBlur && onBlur();
          }}
          renderInput={(params) => (
            <AutoCompleteTextField
              {...params}
              hiddenLabel
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
              placeholder={placeholder}
            />
          )}
        />
      )}
    />
  );
};

export default React.memo(FactaAutocomplete);
