import React from 'react';
import clsx from 'clsx';
import { TableCell } from '@material-ui/core';
import FactaAutocomplete from '../../FactaAutocomplete';
import { AmortizationSource } from '../../../interfaces/types';
import {
  capitalizeFirstLetter,
  legacySchedulingMethodOptions,
} from '../../../util/helpers';
import { useTableStyles } from '../Subledger.styled';

interface Props {
  amortizationSource: AmortizationSource;
  hasChildren?: boolean;
  lastChild?: boolean;
  highlighted?: boolean;
  showCursor?: boolean;
  isRowSelected?: boolean;
  onSelectChange?: (propertyName: string, internalId: string) => (value: string) => void;
  isCellSticky?: string;
  error?: boolean;
}

const AmortizationScheduledTypeCell = ({
  amortizationSource,
  hasChildren,
  lastChild,
  highlighted,
  showCursor,
  isRowSelected,
  onSelectChange,
  isCellSticky,
  error,
}: Props) => {
  const tableClasses = useTableStyles();

  return (
    <TableCell
      className={clsx(tableClasses.scheduleTypeCell, {
        [tableClasses.child]: amortizationSource.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.bold]: highlighted,
        [tableClasses.lastChild]: lastChild,
        [tableClasses.cursorPointer]: showCursor,
      })}
      is-cell-sticky={isCellSticky}
    >
      {isRowSelected && !hasChildren && onSelectChange && (
        <FactaAutocomplete
          error={error}
          className={clsx(tableClasses.classSelectBox)}
          defaultValue={amortizationSource.amortizationSchedule.amortizationScheduleType}
          onChange={onSelectChange('amortizationScheduleType', amortizationSource.internalId)}
          options={legacySchedulingMethodOptions}
          valueName="value"
          optionName="label"
          selectedItem={{
            value: amortizationSource.amortizationSchedule.amortizationScheduleType,
            label: capitalizeFirstLetter(amortizationSource.amortizationSchedule.amortizationScheduleType),
          }}
        />
      )}
      {!isRowSelected && !hasChildren
        && amortizationSource?.amortizationSchedule.amortizationScheduleType?.toLocaleLowerCase()}
    </TableCell>
  );
};

export default React.memo(AmortizationScheduledTypeCell);
