import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { StyledCalendarIcon } from './index.styled';
import COLORS from '../../theme/colors';

const useStyles = makeStyles(() => createStyles({
  root: {
    fontWeight: 'normal',
    fontSize: 12,
    margin: 0,
    marginLeft: 8,
    padding: 0,
    paddingLeft: 8,
    paddingRight: 8,
    color: COLORS.deepGray,
    minWidth: 81,
    height: 32,
    border: 'none',
    backgroundColor: COLORS.white,
    boxShadow: COLORS.selectBoxShadow,
    cursor: 'pointer',
    '&.Mui-error': {
      border: `1px solid ${COLORS.violet}`,
    },
    '&.Mui-focused': {
      boxShadow: COLORS.focusBoxShadow,
    },
  },
  display: {
    fontSize: '0.8125rem',
    color: 'rgba(0, 0, 0, 0.87);',
  },
}));

interface Props {
  onClick?: () => void;
  value?: Date;
}

const MonthDisplayBox = ({
  onClick,
  value,
}: Props) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.root}
      onClick={onClick}
      display="flex"
      alignItems="center"
      width={163}
    >
      <Box
        display="flex"
        flexGrow={1}
      >
        <Typography className={classes.display}>
          {value}
        </Typography>
      </Box>
      <IconButton
        size="small"
      >
        <StyledCalendarIcon />
      </IconButton>
    </Box>
  );
};

export default React.memo(MonthDisplayBox);
