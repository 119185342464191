import React from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  Button,
  createStyles,
  Typography,
} from '@material-ui/core';
import roundTo from 'round-to';
import {
  SchedulerBox,
  SchedulerBoxItem,
} from './SchedulerHeader.styled';
import COLORS from '../../../theme/colors';
import {
  Account,
  Subledger,
} from '../../../interfaces/types';
import currencyFormatter from '../../../util/currencyFormatter';
import {
  HeaderTop,
  AccountName,
  HeaderCurrencyDetails,
  CurrencyLabel,
  SchedulerLabel,
} from '../../../components/common.styled';
import isEmpty from '../../../util/isEmpty';
import SubledgerAccountName from '../../../components/Subledger/SubledgerAccountName';
import { getHeaderScheduleLabel } from '../../../components/Subledger/common';
import FactaDatePicker from '../../../components/DatePicker';

const useStyles = makeStyles(() => createStyles({
  subledgerBalance: {
    color: COLORS.violet,
  },
  glBalance: {
    maxWidth: 100,
    width: 100,
  },
  factaStartingBalance: {
    maxWidth: 120,
    width: 150,
  },
  datepicker: {
    marginRight: 20,
  },
  pageMode: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: COLORS.violet,
    padding: 2,
    paddingLeft: 20,
    paddingRight: 20,
    color: COLORS.white,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
  font13: {
    fontSize: 13,
  },
}));

interface Props {
  subledger?: Subledger | null;
  accountBalance: Account;
  onFinalizedSubledger: () => void;
  showDirtyCheckDialog: () => void;
  isFinalizeSubledgerEnabled?: boolean;
}

const Header = ({
  subledger,
  accountBalance,
  onFinalizedSubledger,
  showDirtyCheckDialog,
  isFinalizeSubledgerEnabled,
}: Props) => {
  const classes = useStyles();
  if (!subledger || isEmpty(subledger)) {
    return null;
  }

  const difference = roundTo(Number(accountBalance?.glBalance), 2) - roundTo(Number(subledger?.openingBalance), 2);

  return (
    <>
      <HeaderTop>
        <Box className={classes.pageMode}>
          <Typography className={classes.font13}>Edit Mode</Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          padding="0 14px"
          alignItems="flex-start"
        >
          <SubledgerAccountName account={subledger?.account} />
          <SchedulerBox>
            <SchedulerBoxItem>
              <SchedulerLabel>
                Facta Start Date
              </SchedulerLabel>
              <FactaDatePicker
                selectedDate={subledger.factaStartDate}
                onDateChange={() => {}}
                readOnly
                className={classes.datepicker}
              />
            </SchedulerBoxItem>
            <SchedulerBoxItem>
              <SchedulerLabel>
                { getHeaderScheduleLabel(subledger?.productCategory) }
              </SchedulerLabel>
              <AccountName>
                {subledger?.account?.scheduleType}
              </AccountName>
            </SchedulerBoxItem>
          </SchedulerBox>
        </Box>
        <Box display="flex">
          <HeaderCurrencyDetails>
            <SchedulerLabel className={classes.glBalance}>
              GL Balance on start date:
            </SchedulerLabel>
            <CurrencyLabel>
              {currencyFormatter.format(accountBalance?.glBalance)}
            </CurrencyLabel>
          </HeaderCurrencyDetails>
          <HeaderCurrencyDetails>
            <SchedulerLabel className={classes.factaStartingBalance}>
              Facta Opening Subledger balance:
            </SchedulerLabel>
            <CurrencyLabel className={classes.subledgerBalance}>
              {currencyFormatter.format(subledger?.openingBalance)}
            </CurrencyLabel>
          </HeaderCurrencyDetails>
          <HeaderCurrencyDetails>
            <SchedulerLabel className={classes.glBalance}>
              Difference
            </SchedulerLabel>
            <CurrencyLabel>
              {currencyFormatter.format(difference)}
            </CurrencyLabel>
          </HeaderCurrencyDetails>
        </Box>
        <Box marginLeft="auto">
          <Button
            onClick={onFinalizedSubledger}
            variant="contained"
            color="primary"
            disabled={!isFinalizeSubledgerEnabled}
          >
            Finalize Subledger
          </Button>
          <Button
            onClick={showDirtyCheckDialog}
            color="primary"
            style={{ color: COLORS.skyBlue }}
          >
            Cancel
          </Button>
        </Box>
      </HeaderTop>
    </>
  );
};

export default Header;
