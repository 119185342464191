/* eslint-disable react/jsx-props-no-spreading,max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const EmailIcon = (props: any) => (
  <SvgIcon
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.99984 0.666664C1.2665 0.666664 0.666504 1.26666 0.666504 2V10C0.666504 10.7333 1.2665 11.3333 1.99984 11.3333H8.99984C8.8185 10.9094 8.70598 10.4593 8.6665 10H1.99984V3.33333L7.33317 6.66666L12.6665 3.33333V5.33333C12.7776 5.32906 12.8888 5.32906 12.9998 5.33333C13.3367 5.33433 13.6723 5.3746 13.9998 5.45333V2C13.9998 1.26666 13.3998 0.666664 12.6665 0.666664H1.99984ZM1.99984 2H12.6665L7.33317 5.33333L1.99984 2ZM12.6665 6L11.1665 7.5L12.6665 9V8C13.1085 8 13.5325 8.17559 13.845 8.48815C14.1576 8.80071 14.3332 9.22464 14.3332 9.66666C14.3332 9.93333 14.2732 10.1867 14.1598 10.4133L14.8865 11.14C15.1665 10.72 15.3332 10.2133 15.3332 9.66666C15.3332 8.19333 14.1398 7 12.6665 7V6ZM10.4465 8.19333C10.1665 8.61333 9.99984 9.12 9.99984 9.66666C9.99984 11.14 11.1932 12.3333 12.6665 12.3333V13.3333L14.1665 11.8333L12.6665 10.3333V11.3333C12.2245 11.3333 11.8006 11.1577 11.488 10.8452C11.1754 10.5326 10.9998 10.1087 10.9998 9.66666C10.9998 9.4 11.0598 9.14666 11.1732 8.92L10.4465 8.19333Z"
      fill="#11ADF8"
    />
  </SvgIcon>
);

export default EmailIcon;
