import React from 'react';
import { useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import { makeStyles } from '@material-ui/core/styles';
import NoRecords from './NoRecords';
import {
  AmortizationSource,
  Subledger,
} from '../../interfaces/types';
import getScheduleDate from '../../util/getScheduleDate';
import UnScheduleAssets from '../Subledger/UnScheduledAssets';
import { useTableStyles } from '../Subledger/Subledger.styled';
import { accountInfoSelector } from '../../store/selectors/account';

const useStyles = makeStyles({
  tableStyle: {
    position: 'relative',
    width: '100%',
  },
});

interface Props {
  subledger: Subledger;
  dispatch: any;
  selectedRow: string;
}

const SubledgerDetailsRevSync = ({
  subledger,
  selectedRow,
  dispatch,
}: Props) => {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const account = useSelector(accountInfoSelector)!;
  const scheduleDate = getScheduleDate(subledger);

  const unScheduleAssets = subledger?.amortizationSources?.filter((asset: AmortizationSource) => !asset.status);
  return (
    <Box width="100%">
      <Table
        size="small"
        stickyHeader
        className={classes.tableStyle}
      >
        <TableHead>
          <TableRow hover={false}>
            <TableCell className={tableClasses.assetCell}>
              JE Date
            </TableCell>
            <TableCell className={tableClasses.assetCell}>
              GL JE ID
            </TableCell>
            <TableCell className={tableClasses.descriptionCell}>
              Description
            </TableCell>
            <TableCell className={tableClasses.customerCell}>
              Customer
            </TableCell>
            <TableCell className={tableClasses.customerCell}>
              Product/Service
            </TableCell>
            <TableCell className={tableClasses.expenseAccountCell}>
              Revenue Account
            </TableCell>
            {
              (account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) && (
                <TableCell className={tableClasses.classesCell}>
                  Class
                </TableCell>
              )
            }
            <TableCell className={tableClasses.datePickerCell}>
              Recognition Start Date
            </TableCell>
            <TableCell className={tableClasses.datePickerCell}>
              Recognition End Date
            </TableCell>
            <TableCell className={tableClasses.scheduleTypeCell}>
              Recog. Schedule
            </TableCell>
            <TableCell className={tableClasses.currencyCell}>
              Amount
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {
            unScheduleAssets?.length === 0 && <NoRecords />
          }
          <UnScheduleAssets
            selectedRow={selectedRow}
            subledger={subledger}
            dispatch={dispatch}
            scheduleDate={scheduleDate!}
            unScheduledAssets={unScheduleAssets}
          />
        </TableBody>
      </Table>
    </Box>
  );
};

export default SubledgerDetailsRevSync;
