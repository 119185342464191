import React, { useEffect } from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import { useParams } from 'react-router';
import ErrorPage from '../../components/ErrorPage';
import { ErrorPageType } from '../../interfaces/types';
import JournalEntryViewTable from '../../components/Subledger/JournalEntryViewTable';
import JournalEntryViewHeader from './JournalEntryViewHeader';
import { ScrollableBoxContainer } from '../../components/ScrollableTable';
import { getAccountInfo } from '../../store/slices/account';
import { AppThunkDispatch } from '../../store/store';

import { getJournalEntryById } from '../../store/slices/je';
import {
  fetchingSubledgerWithJeError,
  isFetchingSubledgerWithJe,
  journalEntrySelector,
} from '../../store/selectors/je';
import { Loader } from '../../components/Loader';

interface Params {
  id: string;
}

const JournalEntryView = () => {
  const { id: jeID } = useParams<Params>();
  const reduxDispatch: AppThunkDispatch = useDispatch();

  const error = useSelector(fetchingSubledgerWithJeError);
  const loading = useSelector(isFetchingSubledgerWithJe);
  const journalEntry = useSelector(journalEntrySelector);

  useEffect(() => {
    reduxDispatch(getAccountInfo());
    reduxDispatch(getJournalEntryById(({ jeID })));
  }, [jeID, reduxDispatch]);

  if (error) {
    return (
      <ErrorPage variant={ErrorPageType.Error} />
    );
  }

  if (loading) {
    return <Loader open />;
  }

  if (!loading && !journalEntry?.journalEntryLineItems) {
    return (
      <ErrorPage variant={ErrorPageType.Error} />
    );
  }

  const getDRCRAmount = () => {
    const { DRAmount, CRAmount } = journalEntry?.journalEntryLineItems?.reduce((acc: any, item: any) => {
      if (item?.postingType === 'Debit') {
        acc.DRAmount += Number(item?.amount);
      } else {
        acc.CRAmount += Number(item?.amount);
      }
      return acc;
    }, { DRAmount: 0, CRAmount: 0 });
    return { DRAmount, CRAmount };
  };

  const { DRAmount, CRAmount } = getDRCRAmount();

  return (
    <>
      <JournalEntryViewHeader journalEntry={journalEntry} />
      <ScrollableBoxContainer>
        <JournalEntryViewTable
          DRAmount={DRAmount}
          CRAmount={CRAmount}
          data={journalEntry}
        />
      </ScrollableBoxContainer>
    </>
  );
};

export default JournalEntryView;
