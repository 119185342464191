import {
  makeStyles,
  createStyles,
} from '@material-ui/core';
import COLORS from '../../../../theme/colors';

export const useStyles = makeStyles(() => createStyles({
  listBox: {
    minWidth: '260px',
    maxWidth: '300px',
    margin: '2px 0 0',
    padding: 0,
    listStyle: 'none',
    backgroundColor: COLORS.white,
    overflow: 'auto',
    maxHeight: '260px',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',

    '& li': {
      display: 'flex',
      padding: '10px 8px',
      borderRadius: '4px',
      marginTop: '8px',

      '& span': {
        flexGrow: 1,
      },

      '& svg': {
        color: 'transparent',
      },
    },

    '& li[aria-selected="true"]': {
      backgroundColor: COLORS.skyBlueShaded,
      fontWeight: '600',
      color: COLORS.skyBlue,

      '& svg': {
        color: COLORS.skyBlue,
      },

      '&.selected': {
        order: -1,
      },
    },

    '& li[data-focus="true"]': {
      backgroundColor: COLORS.lightGray3,
      cursor: 'pointer',

      '& svg': {
        color: COLORS.lightGray2,
      },
    },
  },
}));
