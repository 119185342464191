import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import CheckIcon from '@material-ui/icons/Check';
import Box from '@material-ui/core/Box';
import CardActions from '@material-ui/core/CardActions';
import cardStyles, { AccountLabel } from './cardStyles.styled';
import AccountName from './AccountName';
import GLBalance from './GLBalance';
import FactaBalance from './FactaBalance';
import TaskReadOnlyDetail from './TaskReadOnlyDetail';
import {
  OutOfPeriodData,
  ProductCategory,
} from '../../../interfaces/types';
import { getCategoryLabels } from '../../Subledger/common';
import { OutOfPeriodWarning } from './OutOfPeriodWarning';

interface Props {
  active: boolean;
  accountName: string;
  loading: boolean;
  glBalance: number;
  closingBalance: number;
  amortizationPostedAmount: number;
  category: ProductCategory;
  onCurrencyNavigation: () => void;
  outOfPeriodData: OutOfPeriodData;
}

const ClosedMonthCard = ({
  accountName,
  loading,
  glBalance,
  closingBalance,
  amortizationPostedAmount,
  active,
  category,
  onCurrencyNavigation,
  outOfPeriodData,
}: Props) => {
  const classes = cardStyles();
  const cardLabels = getCategoryLabels(category);
  const { outOfPeriod } = outOfPeriodData;

  return (
    <Card
      className={clsx(classes.card, {
        [classes.active]: active,
        [classes.cardError]: outOfPeriod,
      })}
    >
      {outOfPeriod && <OutOfPeriodWarning outOfPeriodData={outOfPeriodData} />}
      <CardContent>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <AccountLabel>
            Account
          </AccountLabel>
          <section className={classes.closedTag}>
            <span> CLOSED </span>
            <Box className={clsx(classes.accountStatus, classes.completedTasks)}>
              <CheckIcon className={classes.checkComplete} />
            </Box>
          </section>
        </Box>
        <AccountName accountName={accountName} />
        <GLBalance
          glBalance={glBalance}
          loading={loading}
        />
        <FactaBalance
          balance={closingBalance}
          error={outOfPeriod}
        />
      </CardContent>
      <CardActions className={classes.padding15}>
        <TaskReadOnlyDetail
          name={cardLabels.scheduled}
          completed
          amount={0}
          onCurrencyNavigation={onCurrencyNavigation}
          enableCurrencyNavigation
        />
        <TaskReadOnlyDetail
          name={cardLabels.unposted}
          completed
          amount={0}
        />
        <TaskReadOnlyDetail
          name={cardLabels.posted}
          completed
          amount={amortizationPostedAmount}
        />
      </CardActions>
    </Card>
  );
};

export default React.memo(ClosedMonthCard);
