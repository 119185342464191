import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { useForm } from 'react-hook-form';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  Button,
  CircularProgress,
  createStyles,
} from '@material-ui/core';
import { FormInputBox } from '../../components/FormControl';
import { SuccessTypography } from './Settings.styled';

interface Props {
  onClose: () => void;
  onAddUser: (email: string) => Promise<boolean>;
}
const useStyles = makeStyles(() => createStyles({
  successSpan: {
    fontWeight: 700,
  },
}));

const AddUser = ({ onClose, onAddUser }: Props) => {
  const { register, handleSubmit, errors } = useForm();
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const classes = useStyles();

  const addAnotherUser = () => {
    setEmail('');
    setSuccess(false);
  };

  const onUserAdd = async (data: any) => {
    setEmail(data.email);
    setIsSaving(true);
    const result = await onAddUser(data.email);
    setIsSaving(false);
    if (result) {
      setSuccess(true);
    }
  };

  return (
    <>
      {!success && (
        <form
          autoComplete="off"
          onSubmit={handleSubmit(onUserAdd)}
          noValidate
        >
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
          >
            <FormInputBox
              title="User Email"
              name="email"
              type="email"
              require
              defaultValue={email}
              hasError={Boolean(errors?.email)}
              helperText={errors?.email?.message!}
              inputRef={register({
                required: { value: true, message: 'Email is required' },
                pattern: {
                  // eslint-disable-next-line max-len
                  value: /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/,
                  message: 'Please enter correct email address.',
                },
              })}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSaving}
            >
              Add User
              {isSaving && (
                <CircularProgress
                  size={20}
                  style={{ position: 'absolute' }}
                />
              )}
            </Button>
          </Box>
        </form>
      )}
      {success && (
        <>
          <SuccessTypography>
            <span className={classes.successSpan}>Success!</span>
            {` An email invitation has been sent to ${email}`}
          </SuccessTypography>
          <Box
            display="flex"
            flexDirection="row"
            mt={2}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => addAnotherUser()}
            >
              Add Another User
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export default React.memo(AddUser);
