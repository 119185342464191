import { SUBLEDGER_STORAGE_KEY } from './constants';
import {
  AmortizationSource,
  Subledger,
} from '../interfaces/types';

interface State {
  subledger: Subledger;
  historicalUpdatedAssets: Array<AmortizationSource>;
}

export const setSubledger = (state: State) => {
  localStorage.setItem(SUBLEDGER_STORAGE_KEY, JSON.stringify(state));
};

export const getSubledger = () => (localStorage.getItem(SUBLEDGER_STORAGE_KEY)
// @ts-ignore
  ? JSON.parse(localStorage.getItem(SUBLEDGER_STORAGE_KEY)) as State
  : { subledger: {}, historicalUpdatedAssets: null });

export const deleteSubledger = () => localStorage.removeItem(SUBLEDGER_STORAGE_KEY);

export const removeHistoricalUpdatedAssetsFromLocalStorage = () => {
  const subledgerFromLocalStorage = getSubledger();
  const updatedSubledger = {
    ...subledgerFromLocalStorage,
    historicalUpdatedAssets: [],
  } as State;
  setSubledger(updatedSubledger);
};
