/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { request } from '../../util/request';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../interfaces/types';
import { handleAuthError } from '../../util/auth';

const PREFIX = 'v2Sync';
const POST_V2_SYNC = `${PREFIX}/postSync`;
const POST_V2_RESET = `${PREFIX}/postReset`;
const POST_V2_LOGOUT = `${PREFIX}/logout`;

interface State {
  isSyncing: boolean;
  isResetting: boolean;
  isLoggingOut: boolean;
  lastV2Sync: string;
}

const initialState: State = {
  isSyncing: false,
  isResetting: false,
  isLoggingOut: false,
  lastV2Sync: '',
};

export const postV2Reset = createAsyncThunk(
  POST_V2_RESET,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request().post('/v2/reset');

      return data;
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';

      if (handledError === 'invalidated authorization token') {
        handleAuthError();
      }

      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const postV2Logout = createAsyncThunk(
  POST_V2_LOGOUT,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request().post('/v2/auth/qbo/logout');

      return data;
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';

      if (handledError === 'invalidated authorization token') {
        handleAuthError();
      }

      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const postV2Sync = createAsyncThunk(
  POST_V2_SYNC,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request().post('/v2/sync');

      return data;
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';

      if (handledError === 'invalidated authorization token') {
        handleAuthError();
      }

      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const v2SyncSlice = createSlice({
  initialState,
  name: PREFIX,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postV2Sync.pending, (state) => {
      state.isSyncing = true;
    });
    builder.addCase(postV2Sync.fulfilled, (state) => {
      state.isSyncing = false;
      state.lastV2Sync = new Date().toISOString();
    });
    builder.addCase(postV2Sync.rejected, (state) => {
      state.isSyncing = false;
    });

    builder.addCase(postV2Reset.pending, (state) => {
      state.isSyncing = true;
    });
    builder.addCase(postV2Reset.fulfilled, (state) => {
      state.isSyncing = false;
    });
    builder.addCase(postV2Reset.rejected, (state) => {
      state.isSyncing = false;
    });

    builder.addCase(postV2Logout.pending, (state) => {
      state.isLoggingOut = true;
    });
    builder.addCase(postV2Logout.fulfilled, (state) => {
      state.isLoggingOut = false;
    });
    builder.addCase(postV2Logout.rejected, (state) => {
      state.isLoggingOut = false;
    });
  },
});

export const v2SyncReducer = v2SyncSlice.reducer;
