import React, {
  useEffect,
  useReducer,
} from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import { useParams } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import { getTotalAndRowsCount } from '../../../components/Subledger/common';
import PostJEHeader from './PostJEHeader';
import reducer from '../../../components/Subledger/reducer';
import { INITIALED } from '../../../util/constants';
import PostJEPrepaidExpenseTable from '../../../components/Subledger/PostJEPrepaidExpenseTable';
import { ProductCategory } from '../../../interfaces/types';
import PostJERevSyncTable from '../../../components/Subledger/PostJERevSyncTable';
import {
  ScrollableBoxContainer,
  ScrollableTableContainer,
} from '../../../components/ScrollableTable';
import { AppThunkDispatch } from '../../../store/store';
import { getSubledgerDetailsByScheduleDate } from '../../../store/slices/subledger';
import { isFetchingSubledger } from '../../../store/selectors/subledger';
import { Loader } from '../../../components/Loader';
import { accountLastFetchSelector } from '../../../store/selectors/account';

interface Props {
  scheduleDate: string;
}

interface Params {
  id: string;
}

const PostJE = ({ scheduleDate }: Props) => {
  const { id: subledgerId } = useParams<Params>();
  const reduxDispatch: AppThunkDispatch = useDispatch();

  const subledgerLoading = useSelector(isFetchingSubledger);
  const lastFetch = useSelector(accountLastFetchSelector);

  // @ts-ignore
  const [state, dispatch] = useReducer(reducer, { subledger: {} });

  const currentMonth = new Date(scheduleDate).toLocaleString('en-us', { month: 'long' });

  useEffect(() => {
    reduxDispatch(getSubledgerDetailsByScheduleDate({
      subledgerId,
      scheduleDate,
    }))
      .unwrap()
      .then((result) => {
        dispatch({
          type: INITIALED,
          payload: {
            subledger: result,
          },
        });
      });
  }, [reduxDispatch, subledgerId, scheduleDate, lastFetch]);

  const { subledger } = state;

  const {
    totalPrepaidAccount,
    rowsCount,
    total,
  } = (
    getTotalAndRowsCount(subledger?.amortizationSources, scheduleDate) ?? {
      totalPrepaidAccount: 0,
      rowsCount: 0,
      total: 0,
    }
  );

  return (
    <>
      <Loader open={subledgerLoading} />
      <PostJEHeader
        subledger={subledger}
        scheduleDate={scheduleDate}
        currentMonth={currentMonth}
        totalPrepaidAccount={totalPrepaidAccount}
        rowsCount={rowsCount}
      />
      <ScrollableBoxContainer>
        <ScrollableTableContainer>
          <Table
            size="small"
            id="journalEntryTable"
            stickyHeader
            aria-label="Post JE"
          >
            {subledger?.productCategory === ProductCategory.PrepaidExpense && (
              <PostJEPrepaidExpenseTable
                scheduleDate={scheduleDate}
                amortizationSources={subledger?.amortizationSources}
                account={subledger?.account}
                currentMonth={currentMonth}
                rowsCount={rowsCount}
                total={total}
                totalPrepaidAccount={totalPrepaidAccount}
              />
            )}
            {subledger?.productCategory === ProductCategory.DeferredRevenue && (
              <PostJERevSyncTable
                scheduleDate={scheduleDate}
                amortizationSources={subledger?.amortizationSources}
                account={subledger?.account}
                currentMonth={currentMonth}
                rowsCount={rowsCount}
                total={total}
                totalPrepaidAccount={totalPrepaidAccount}
              />
            )}
          </Table>
        </ScrollableTableContainer>
      </ScrollableBoxContainer>
    </>
  );
};

export default PostJE;
