import { createSelector } from '@reduxjs/toolkit';
import {
  AccountClassification,
  AccumulationAccountTypes,
} from '../../interfaces/accounts';
import { RootState } from '../store';

const BALANCE_SHEET_CLASSIFICATIONS = [
  AccountClassification.ASSET,
  AccountClassification.EQUITY,
  AccountClassification.LIABILITY,
];

const INCOME_STATEMENT_CLASSIFICATIONS = [
  AccountClassification.EXPENSE,
];

const ACCUMULATED_ACCOUNT_CLASSIFICATIONS = [
  AccumulationAccountTypes.ACCUMULATED_AMORTIZATION,
  AccumulationAccountTypes.ACCUMULATED_DEPLETION,
  AccumulationAccountTypes.ACCUMULATED_DEPRECIATION,
];

const accountSelector = (state: RootState) => state.v2Accounts;

export const accountsListSelector = createSelector(
  accountSelector,
  (accounts) => accounts.accounts,
);

export const accountsBalanceListSelector = createSelector(
  accountSelector,
  (accounts) => accounts.accounts
    .filter((account) => BALANCE_SHEET_CLASSIFICATIONS.includes(account.classification)),
);

export const accountsIncomeListSelector = createSelector(
  accountSelector,
  (accounts) => accounts.accounts
    .filter((account) => INCOME_STATEMENT_CLASSIFICATIONS.includes(account.classification)),
);

export const isFetchingAccounts = createSelector(
  accountSelector,
  (accounts) => accounts.isFetchingAccounts,
);

export const accountsAccumulationListSelector = createSelector(
  accountSelector,
  (accounts) => accounts.accounts
    .filter((account) => ACCUMULATED_ACCOUNT_CLASSIFICATIONS.includes(account.detail as AccumulationAccountTypes)),
);

export const accountGLBalanceSelector = createSelector(
  accountSelector,
  (accounts) => accounts.accountBalance,
);
