import React from 'react';
import clsx from 'clsx';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import {
  capitalize,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import currencyFormatter from '../../../../util/currencyFormatter';
import { accountInfoSelector } from '../../../../store/selectors/account';
import {
  getVendorName,
  getCustomerName,
} from '../../../Subledger/common';
import { FULL_DATE_FORMAT } from '../../../../util/constants';
import {
  AmortizationSource,
  ProductCategory,
  LegacySchedulingMethod,
} from '../../../../interfaces/types';
import COLORS from '../../../../theme/colors';

const useStyles = makeStyles(() => ({
  table: {
    marginBottom: 0,
  },
  removeBorderBottom: {
    borderBottom: 'none !important',
    '& td': {
      borderBottom: 'none',
    },
  },
  tableRow: {
    background: `${COLORS.lightGray3} !important`,
    '&:hover': {
      background: `${COLORS.lightGray3} !important`,
    },
  },
}));

interface Props {
  productCategory: ProductCategory;
  selectedSubledger: AmortizationSource;
  selectedSubledgersParent?: AmortizationSource;
  sourceId?: string | null;
}

const SingleRowTable = ({
  productCategory,
  selectedSubledger,
  selectedSubledgersParent,
  sourceId,
}: Props) => {
  const classes = useStyles();
  const account = useSelector(accountInfoSelector)!;
  const { vendors } = account;
  const { customers } = account;

  const {
    description,
    sourceCreationDate,
    vendorId,
    customerId,
    amortizationSchedule: {
      amortizationScheduleType,
      amortizationStartDate,
      amortizationEndDate,
    },
    startingBalance,
  } = selectedSubledger;

  const vendor = selectedSubledgersParent?.vendorId || vendorId;
  const customer = selectedSubledgersParent?.customerId || customerId;
  const isCategoryPrepaid = productCategory === ProductCategory.PrepaidExpense;

  const tableHeaderNames = isCategoryPrepaid
    ? [
      'Description',
      'ID',
      'GL Date',
      'Vendor',
      'Schedule',
      'Amortization Start',
      'Amortization End',
      'Original Balance',
    ]
    : [
      'Description',
      'ID',
      'GL Date',
      'Customer',
      'Schedule',
      'Recognition Start Date',
      'Recognition End Date',
      'Amount',
    ];

  return (
    <Table
      className={classes.table}
      size="small"
    >
      <TableHead>
        <TableRow className={classes.tableRow}>
          {tableHeaderNames.map((name) => (
            <TableCell key={name}>{name}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow className={clsx(classes.removeBorderBottom, classes.tableRow)}>
          <TableCell>{description}</TableCell>
          <TableCell>{sourceId}</TableCell>
          <TableCell>{format(sourceCreationDate as Date, FULL_DATE_FORMAT)}</TableCell>
          <TableCell>
            {isCategoryPrepaid ? getVendorName(vendor, vendors) : getCustomerName(customer, customers)}
          </TableCell>
          <TableCell>{capitalize(amortizationScheduleType.toLocaleLowerCase())}</TableCell>
          <TableCell>
            {
              amortizationScheduleType === LegacySchedulingMethod.MANUAL
                ? 'Manual'
                : format(amortizationStartDate as Date, FULL_DATE_FORMAT)
            }
          </TableCell>
          <TableCell>
            {
              amortizationScheduleType === LegacySchedulingMethod.MANUAL
                ? 'Manual'
                : format(amortizationEndDate as Date, FULL_DATE_FORMAT)
            }
          </TableCell>
          <TableCell>{currencyFormatter.format(startingBalance)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default React.memo(SingleRowTable);
