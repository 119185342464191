import {
  useEffect,
  useState,
} from 'react';
import {
  Filter,
  FilterValue,
} from '../interfaces/types';

export const useFilters = (initialFilters: Array<Filter>) => {
  const [filters, setFilters] = useState<Array<Filter>>(initialFilters);

  const setFilter = (
    filterName: string,
    filterValue: FilterValue,
  ) => {
    setFilters((previousFilters) => previousFilters.map((filter) => (filter.name === filterName
      ? { ...filter, value: filterValue }
      : filter)));
  };

  const clearAllFilters = () => {
    setFilters(initialFilters);
  };

  useEffect(() => setFilters(initialFilters), [initialFilters]);

  return {
    filters,
    setFilter,
    clearAllFilters,
  };
};
