import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import cardStyles from './cardStyles.styled';
import AccountName from './AccountName';
import GLBalance from './GLBalance';
import FactaBalance from './FactaBalance';
import TaskReadOnlyDetail from './TaskReadOnlyDetail';
import {
  OutOfPeriodData,
  ProductCategory,
} from '../../../interfaces/types';
import { getCategoryLabels } from '../../Subledger/common';
import { OutOfPeriodWarning } from './OutOfPeriodWarning';

interface Props {
  accountName: string;
  category: ProductCategory;
  outOfPeriodData: OutOfPeriodData;
}

const SubledgerDisableCard = ({
  accountName,
  category,
  outOfPeriodData,
}: Props) => {
  const classes = cardStyles();
  const cardLabels = getCategoryLabels(category);
  const { outOfPeriod } = outOfPeriodData;

  return (
    <Card
      className={clsx(classes.card, classes.disabled)}
    >
      {outOfPeriod && <OutOfPeriodWarning outOfPeriodData={outOfPeriodData} />}
      <CardContent>
        <section className={classes.cardType}>
          <h4>Account</h4>
        </section>
        <section className={classes.closedTag} />
        <AccountName accountName={accountName} />
        <GLBalance glBalance={0} />
        <FactaBalance balance={0} />
      </CardContent>
      <CardActions className={classes.padding15}>
        <TaskReadOnlyDetail
          name={cardLabels.scheduled}
          amount={0}
        />
        <TaskReadOnlyDetail
          name={cardLabels.unposted}
          amount={0}
        />
        <TaskReadOnlyDetail
          name={cardLabels.posted}
          amount={0}
        />
      </CardActions>
    </Card>
  );
};

export default React.memo(SubledgerDisableCard);
