import React from 'react';
import {
  format,
  parseISO,
} from 'date-fns';
import Flyover from '../../Flyover';
import TopBarSubledgerInfo from './TopBarSubledgerInfo';
import {
  AmortizationSource,
  ProductCategory,
  LegacySchedulingMethod,
} from '../../../interfaces/types';
import { FULL_DAY_FORMAT } from '../../../util/constants';
import ImportCSVContent from './ImportCSVContent';
import { SchedulingMethod } from '../../../interfaces/common';
import { FixedAssetsImport } from './FixedAssetsImport';

interface Props {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  factaStartDate: string;
  accountName: string;
  scheduleType: LegacySchedulingMethod | SchedulingMethod;
  isClassTrackingEnabled?: boolean;
  onImportCSV?: (data: Array<AmortizationSource>) => void;
  productCategory: ProductCategory;
  subledgerId?: string;
}

const CSVImportWizard = ({
  isOpen,
  setOpen,
  isClassTrackingEnabled,
  onImportCSV,
  productCategory,
  factaStartDate,
  accountName,
  scheduleType,
  subledgerId,
}: Props) => {
  const handleClose = () => setOpen(() => false);

  return (
    <Flyover
      open={isOpen}
      title="IMPORT CSV"
      titleAdditionalMarkup={(
        <TopBarSubledgerInfo
          accountName={accountName}
          factaStartDate={format(parseISO(factaStartDate), FULL_DAY_FORMAT)}
          amortizationScheduleType={scheduleType!}
        />
      )}
      variant="cover"
      onClose={handleClose}
    >
      {productCategory === ProductCategory.FixedAsset
        ? (
          <FixedAssetsImport
            subledgerId={subledgerId!}
            factaStartDate={factaStartDate}
            onClose={handleClose}
          />
        )
        : (
          <ImportCSVContent
            productCategory={productCategory}
            factaStartDate={factaStartDate}
            isClassTrackingEnabled={isClassTrackingEnabled!}
            onClose={handleClose}
            onImportCSV={onImportCSV!}
          />
        )}
    </Flyover>
  );
};

export default CSVImportWizard;
