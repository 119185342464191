import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import COLORS from '../../theme/colors';
import {
  ASSET_CELL,
  CLASSES_CELL,
  CUSTOMER_CELL,
  DATEPICKER_CELL,
  DESCRIPTION_CELL,
  EXPENSE_ACCOUNT_CELL,
  CURRENCY_CELL,
  SCHEDULE_TYPE_CELL,
  ACTION_CELL,
  AMORTIZAION_ROW_CELL,
  ACTION_CELL_WITH_SAVE,
  PREPAID_AMOUNT,
  ASSET_AMOUNT,
  VIRTUALISED_CELL,
  PRODUCT_CELL,
  STICKY_COLUMN_HEADER_Z_INDEX,
} from '../../util/constants';

export const useTableStyles = makeStyles((theme: Theme) => createStyles({
  assetCell: {
    minWidth: ASSET_CELL,
    textAlign: 'left',
  },
  descriptionCell: {
    minWidth: DESCRIPTION_CELL,
    textAlign: 'left',
    overflow: 'hidden',
  },
  customerCell: {
    minWidth: CUSTOMER_CELL,
    textAlign: 'left',
  },
  autocompleteVirtualizedCell: {
    minWidth: VIRTUALISED_CELL,
    textAlign: 'left',
  },
  productCell: {
    minWidth: PRODUCT_CELL,
    textAlign: 'left',
  },
  expenseAccountCell: {
    minWidth: EXPENSE_ACCOUNT_CELL,
  },
  classesCell: {
    minWidth: CLASSES_CELL,
    textAlign: 'left',
    overflow: 'hidden',
  },
  datePickerCell: {
    minWidth: DATEPICKER_CELL,
    textAlign: 'left',
  },
  scheduleTypeCell: {
    minWidth: SCHEDULE_TYPE_CELL,
    textTransform: 'capitalize',
    textAlign: 'left',
  },
  currencyCell: {
    minWidth: CURRENCY_CELL,
    textAlign: 'right',
  },
  actionCell: {
    minWidth: ACTION_CELL,
  },
  actionCellWithSave: {
    minWidth: ACTION_CELL_WITH_SAVE,
  },
  assetAmountCell: {
    minWidth: ASSET_CELL,
    textAlign: 'right',
  },
  actionCellButtonPadding: {
    padding: 4,
    margin: 4,
  },
  parent: {
    borderBottom: 'none !important',
    paddingBottom: '0 !important',
    paddingTop: '8px !important',
  },
  child: {
    borderBottom: 'none !important',
    paddingTop: '4px !important',
    paddingBottom: '4px !important',
  },
  lastChild: {
    borderBottom: `1px dashed ${COLORS.lightGray2} !important`,
    paddingBottom: '8px !important',
  },
  inputBox: {
    backgroundColor: COLORS.white,
    borderRadius: 4,
    border: 'none',
    height: 32,
    '&.Mui-error': {
      border: `1px solid ${COLORS.error}`,
    },
  },
  fontSize12: {
    fontSize: 12,
  },
  amortizationRowCell: {
    padding: 0,
    margin: 0,
    minWidth: AMORTIZAION_ROW_CELL,
    textAlign: 'right',
    paddingRight: '16px !important',
    paddingBottom: '2px !important',
  },
  amortizationSplitCell: {
    borderBottom: 'none',
  },
  amortizationRowCellHeader: {
    fontSize: 13,
    fontWeight: 'bold',
    color: COLORS.medGray,
    borderBottom: `1px solid ${COLORS.lightGray2}`,
    width: '100%',
    padding: 8,
    textAlign: 'right',
  },
  cellBackground: {
    backgroundColor: COLORS.lightGray3,
  },
  expenseAccountSelectBox: {
    width: 225,
  },
  customerSelectBox: {
    width: 170,
  },
  virtualizedSelectBox: {
    width: 290,
  },
  classSelectBox: {
    width: 117,
  },
  scheduleTypeSelectBox: {
    width: 71,
  },
  selected: {
    '&.Mui-selected': {
      background: `${COLORS.selectedAssetRow} !important`,
    },
  },
  noBorder: {
    borderBottom: 'none !important',
  },
  iconDisabled: {
    '&.Mui-disabled': {
      opacity: 0.3,
    },
  },
  disabledRow: {
    opacity: 0.5,
  },
  fontSize13: {
    fontSize: 13,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  amortizationRowCellHeaderCurrency: {
    fontSize: 11,
    fontWeight: 500,
    color: COLORS.lightGray,
    width: '100%',
    textAlign: 'right',
    padding: 8,
    paddingBottom: 2,
  },
  prepaidAssetAmount: {
    width: PREPAID_AMOUNT,
    maxWidth: PREPAID_AMOUNT,
    textAlign: 'right',
  },
  selectedCellBackground: {
    backgroundColor: COLORS.selectedCellBackground,
    borderLeft: `1px solid ${COLORS.skyBlue} !important`,
    borderRight: `1px solid ${COLORS.skyBlue} !important`,
  },
  selectedCellBackgroundHeaderCell: {
    borderLeft: `1px solid ${COLORS.skyBlue} !important`,
    borderTop: `1px solid ${COLORS.skyBlue} !important`,
    background: COLORS.white,
  },
  amortizationManualCell: {
    paddingRight: '4px !important',
  },
  amortizationCell: {
    padding: '8px 4px',
  },
  arrowIcon: {
    fontSize: 12,
    textTransform: 'none',
    height: 14,
    marginTop: 8,
    marginBottom: 8,
  },
  bold: {
    fontWeight: 'bold',
  },
  assetAmount: {
    width: ASSET_AMOUNT,
    textAlign: 'right',
  },
  saveButton: {
    minWidth: 70,
  },
  scheduledMinWidth: {
    minWidth: 85,
  },
  hover: {
    backgroundColor: `${COLORS.lighterBlue} !important`,
    transition: theme.transitions.create('background-color', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.shorter,
    }),
  },
  descriptionCellBox: {
    width: '100%',
  },
  tableHeader: {
    position: 'sticky',
    zIndex: STICKY_COLUMN_HEADER_Z_INDEX,
  },
  lastCell: {
    paddingRight: '40px !important',
  },
}));
