import clsx from 'clsx';
import { TableCell } from '@material-ui/core';
import React from 'react';
import { useTableStyles } from '../Subledger.styled';
import { AmortizationSource } from '../../../interfaces/types';
import InputBox from '../../InputBox';

interface Props {
  amortizationSource: AmortizationSource;
  hasChildren?: boolean;
  lastChild?: boolean;
  onInputBoxChange: (propertyName: string, internalId: string) =>
  (event: React.ChangeEvent<HTMLInputElement>) => void;
  onInputBoxFocus: (propertyName: string, internalId: string) =>
  (event: React.FocusEvent<HTMLInputElement>) => void;
  hasError?: boolean;
  isCellSticky?: string;
}

const CurrencyEditableCell = ({
  amortizationSource,
  hasChildren,
  lastChild,
  onInputBoxChange,
  onInputBoxFocus,
  hasError,
  isCellSticky,
}: Props) => {
  const tableClasses = useTableStyles();
  return (
    <TableCell
      className={clsx(tableClasses.currencyCell, {
        [tableClasses.child]: amortizationSource.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      is-cell-sticky={isCellSticky}
    >
      <InputBox
        fullWidth
        type="number"
        readOnly={hasChildren}
        inputProps={{ style: { textAlign: 'right' } }}
        value={amortizationSource.startingBalance}
        className={tableClasses.inputBox}
        error={hasError}
        onChange={onInputBoxChange('startingBalance', amortizationSource.internalId)}
        onFocus={onInputBoxFocus('startingBalance', amortizationSource.internalId)}
        onBlur={onInputBoxFocus('startingBalance', amortizationSource.internalId)}
      />
    </TableCell>
  );
};

export default React.memo(CurrencyEditableCell);
