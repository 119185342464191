/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  MenuItem,
} from '@material-ui/core';
import { Pagination as MuiPagination } from '@material-ui/lab';
import SelectBox from '../Select';
import { FormInput } from '../Inputs/FormInput';
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGINATION_OPTIONS,
} from '../../util/constants';
import COLORS from '../../theme/colors';

interface Props {
  page: number;
  setPage: Function;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  length: number;
  paginationOptions?: Array<number>;
  scrollRef?: React.MutableRefObject<HTMLTableElement | null>;
  hidePaginationOptions?: boolean;
  removeBottomMargin?: boolean;
}

export const Pagination = ({
  page,
  setPage,
  pageSize,
  setPageSize,
  length,
  paginationOptions,
  scrollRef,
  hidePaginationOptions,
  removeBottomMargin,
}: Props) => {
  const {
    control,
    handleSubmit,
    reset,
    trigger,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      page,
    },
  });

  const options = paginationOptions || DEFAULT_PAGINATION_OPTIONS;
  const numberOfPages = Math.ceil(length / pageSize);

  const availableOptions = options.indexOf(DEFAULT_PAGE_SIZE) === -1
    ? [...options, DEFAULT_PAGE_SIZE].sort((a, b) => a - b)
    : options;

  const handlePageSizeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPageSize(event.target.value as number);
    setPage(1);
  };

  const handlePageChange = (pageNumber: number) => {
    setPage(pageNumber);
    if (scrollRef?.current) {
      scrollRef.current.scrollLeft = 0;
      scrollRef.current.scrollTop = 0;
    }
  };

  const handleGoToPage = ({ pageNumber }: { pageNumber: string }) => {
    const newPage = isNaN(Number(pageNumber))
      ? 1
      : Math.max(Math.min(Number(pageNumber), numberOfPages), 1);

    handlePageChange(newPage);
    reset({ page: newPage });
  };

  useEffect(() => {
    reset({ page });
    trigger();
  }, [page, trigger, reset]);

  useEffect(() => {
    if (page > 1 && numberOfPages < page) {
      setPage(numberOfPages);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfPages]);

  return (
    <form
      id="pagination-hook-form"
      onSubmit={handleSubmit(handleGoToPage)}
      style={{ width: '100%' }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        padding="16px 80px 16px 16px"
        borderTop={`1px solid ${COLORS.disabled}`}
        paddingBottom={removeBottomMargin && '0'}
      >
        <Box
          visibility={hidePaginationOptions && 'hidden'}
          display="flex"
          flexWrap="no-wrap"
          alignItems="center"
        >
          Show
          <Box margin="0 8px">
            <SelectBox
              value={pageSize}
              disableUnderline
              onChange={handlePageSizeChange}
            >
              {availableOptions.map((option) => (
                <MenuItem
                  key={`pagination-${option}`}
                  value={option}
                >
                  {option}
                </MenuItem>
              ))}
            </SelectBox>
          </Box>
          {`of ${length}.`}
        </Box>
        <Box
          display="flex"
          alignItems="center"
        >
          <Box>
            <MuiPagination
              boundaryCount={2}
              count={numberOfPages}
              page={page}
              showFirstButton
              showLastButton
              size="small"
              onChange={(ev, newPage) => handlePageChange(newPage)}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flexWrap="no-wrap"
          alignItems="center"
        >
          <Box margin={1}>
            Go to page:
          </Box>
          <Box width="50px">
            <FormInput
              control={control}
              defaultValue={`${page}`}
              name="pageNumber"
              error={false}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            type="submit"
          >
            Go
          </Button>
        </Box>
      </Box>
    </form>
  );
};
