import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import Table from '@material-ui/core/Table';
import cloneDeep from 'lodash.clonedeep';
import TableBody from '@material-ui/core/TableBody';
import { Box } from '@material-ui/core';
import {
  Account,
  AmortizationScheduleDetails,
  AmortizationSource,
  ProductCategory,
  LegacySchedulingMethod,
} from '../../../interfaces/types';
import {
  FINALIZED_SUBLEDGER_STATUS,
  INITIALED_FOR_PREPARE_JE,
} from '../../../util/constants';
import { getPrepareJEStartEndDate } from '../../../components/Subledger/common';
import ScheduleHeader from './ScheduleHeader';
import reducer from '../../../components/Subledger/reducer';
import isEmpty from '../../../util/isEmpty';
import { toUTC } from '../../../util/timezone';
import {
  isPostJEEnabled,
  prepareJEInputChange,
} from '../../../components/Subledger/prepreJECommon';
import PrepareJEPrepaidExpenseTableHeader from '../../../components/Subledger/PrepareJEPrepaidExpenseTableHeader';
import PrepareJERevSyncTableHeader from '../../../components/Subledger/PrepareJERevSyncTableHeader';
import PrepareJEPrepareExpenseTableRow from '../../../components/Subledger/PrepareJEPrepareExpenseTableRow';
import PrepareJERevSyncTableRow from '../../../components/Subledger/PrepareJERevSyncTableRow';
import useStickyColumns from '../../../hooks/useStickyColumns';
import useCollapsedView from '../../../hooks/useCollapsedView';
import {
  ScrollableBoxContainer,
  ScrollableTableContainer,
} from '../../../components/ScrollableTable';
import { AppThunkDispatch } from '../../../store/store';
import {
  getSubledgerDetailsByScheduleDate,
  patchManualAmortizationSources,
} from '../../../store/slices/subledger';
import {
  fetchingSubledgerError,
  isFetchingSubledger,
  isUpdatingSubledger,
} from '../../../store/selectors/subledger';
import { Loader } from '../../../components/Loader';
import { accountLastFetchSelector } from '../../../store/selectors/account';

interface Props {
  scheduleDate: string;
  accountBalance: Account;
}

interface Params {
  id: string;
}

const PrepareJE = ({
  scheduleDate,
  accountBalance,
}: Props) => {
  const { id: subledgerId } = useParams<Params>();
  const history = useHistory();
  const reduxDispatch: AppThunkDispatch = useDispatch();

  const subledgerLoading = useSelector(isFetchingSubledger);
  const subledgerError = useSelector(fetchingSubledgerError);
  const isUpdating = useSelector(isUpdatingSubledger);
  const lastFetch = useSelector(accountLastFetchSelector);

  const tableRef: React.MutableRefObject<HTMLTableElement | null> = useRef(null);
  const [collapses, setCollapses] = useState<Array<string>>([]);
  const [tableRefState, setTableRefState] = useState(false);

  // @ts-ignore
  const [state, dispatch] = useReducer(reducer, { subledger: {} });
  const { subledger } = state;

  const [
    isCollapsedView,
    isCollapsedViewHovered,
    ToggleExpandedView,
  ] = useCollapsedView(false, 'expanded view');

  useStickyColumns(tableRef, [tableRefState, isCollapsedView, subledger]);

  const getData = useCallback(async () => {
    if (!subledgerLoading && !subledgerError) {
      reduxDispatch(getSubledgerDetailsByScheduleDate({
        subledgerId,
        scheduleDate,
        requiredAmortizationToDate: true,
      }))
        .unwrap()
        .then((result) => {
          if (result.status !== FINALIZED_SUBLEDGER_STATUS) {
            history.push(`/subledgers/scheduler/${subledgerId}`);
          }
          dispatch({
            type: INITIALED_FOR_PREPARE_JE,
            payload: {
              subledger: result,
              scheduleDate,
            },
          });
        });
    }
  }, [
    history,
    reduxDispatch,
    scheduleDate,
    subledgerError,
    subledgerId,
    subledgerLoading,
  ]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastFetch]);

  if (subledgerLoading || isEmpty(subledger)) {
    return <Loader open />;
  }

  const {
    startDate,
    endDate,
  } = getPrepareJEStartEndDate(subledger, subledger?.amortizationSources, scheduleDate);

  const toggleSplit = (internalId: string) => () => {
    const exists = collapses.includes(internalId);
    let updateCollapse: Array<string> = [];
    if (exists) {
      updateCollapse = collapses?.filter((item: string) => item !== internalId);
    } else {
      updateCollapse.push(internalId);
    }
    setCollapses(updateCollapse);
  };

  const postJEEnabled = isPostJEEnabled(subledger?.amortizationSources, scheduleDate);

  const onPostJE = async () => {
    const manualPrepaidAssets = cloneDeep(subledger?.amortizationSources)
      ?.filter((asset: AmortizationSource) => asset
        .amortizationSchedule.amortizationScheduleType === LegacySchedulingMethod.MANUAL);
    if (manualPrepaidAssets?.length > 0) {
      for (const prepaidAsset of manualPrepaidAssets) {
        if (prepaidAsset?.amortizationSchedule?.amortizationScheduleDetails?.length) {
          const amortizationScheduleDetails = prepaidAsset?.amortizationSchedule
            ?.amortizationScheduleDetails
            ?.filter((item: AmortizationScheduleDetails) => !Number.isNaN(Number(item.amount)));
          for (const amortizationSchedule of amortizationScheduleDetails) {
            amortizationSchedule.scheduleDate = toUTC(amortizationSchedule.scheduleDate);
            amortizationSchedule.amount = Number(amortizationSchedule.amount);
          }
          prepaidAsset.amortizationSchedule.amortizationScheduleDetails = amortizationScheduleDetails;
        }
      }
      reduxDispatch(patchManualAmortizationSources({
        subledgerId,
        amortizationSources: manualPrepaidAssets,
        productCategory: subledger.productCategory,
      }))
        .then(() => {
          history.push(`/subledgers/schedule/${subledgerId}/journal-entry/?scheduleDate=${scheduleDate}`);
        });
    } else {
      history.push(`/subledgers/schedule/${subledgerId}/journal-entry/?scheduleDate=${scheduleDate}`);
    }
  };

  const onInputBoxChange = (
    lastDay: string,
    asset: AmortizationSource,
    schedule?: AmortizationScheduleDetails,
  ) => (event: React.ChangeEvent<HTMLInputElement>) => {
    prepareJEInputChange({
      number: event.target.value,
      amortizationSource: asset,
      schedule,
      lastDay,
      amortizationSources: subledger?.amortizationSources,
      dispatch,
    });
  };

  const renderRow = (amortizationSource: AmortizationSource) => {
    if (subledger?.productCategory === ProductCategory.PrepaidExpense) {
      return (
        <PrepareJEPrepareExpenseTableRow
          startDate={startDate}
          endDate={endDate}
          scheduleDate={scheduleDate}
          amortizationSource={amortizationSource}
          subledger={subledger}
          onInputBoxChange={onInputBoxChange}
          collapses={collapses}
          toggleSplit={toggleSplit}
          isCollapsedView={isCollapsedView}
        />
      );
    }
    if (subledger?.productCategory === ProductCategory.DeferredRevenue) {
      return (
        <PrepareJERevSyncTableRow
          startDate={startDate}
          endDate={endDate}
          scheduleDate={scheduleDate}
          amortizationSource={amortizationSource}
          subledger={subledger}
          onInputBoxChange={onInputBoxChange}
          collapses={collapses}
          toggleSplit={toggleSplit}
          isCollapsedView={isCollapsedView}
        />
      );
    }
    return null;
  };

  return (
    <>
      <Loader open={subledgerLoading || isUpdating} />
      <ScheduleHeader
        subledger={subledger}
        scheduleDate={scheduleDate}
        postJEEnabled={postJEEnabled}
        onPostJE={onPostJE}
        accountBalance={accountBalance}
      />
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="flex-end"
        padding="0 16px"
      >
        <ToggleExpandedView />
      </Box>
      <ScrollableBoxContainer>
        <ScrollableTableContainer>
          <Table
            ref={(el) => {
              setTableRefState(true);
              tableRef.current = el;
            }}
            size="small"
            stickyHeader
            aria-label="Prepare JE Table"
          >
            {subledger.productCategory === ProductCategory.PrepaidExpense && (
              <PrepareJEPrepaidExpenseTableHeader
                startDate={startDate}
                endDate={endDate!}
                scheduleDate={scheduleDate}
                amortizationSources={subledger?.amortizationSources}
                isCollapsedView={isCollapsedView}
                isCollapsedViewHovered={isCollapsedViewHovered}
              />
            )}
            {subledger.productCategory === ProductCategory.DeferredRevenue && (
              <PrepareJERevSyncTableHeader
                startDate={startDate}
                endDate={endDate!}
                scheduleDate={scheduleDate}
                amortizationSources={subledger?.amortizationSources}
                isCollapsedView={isCollapsedView}
                isCollapsedViewHovered={isCollapsedViewHovered}
              />
            )}
            <TableBody>
              {
                subledger?.amortizationSources
                  ?.filter((asset: AmortizationSource) => !asset.parentId)
                  .map(renderRow)
              }
            </TableBody>
          </Table>
        </ScrollableTableContainer>
      </ScrollableBoxContainer>
    </>
  );
};

export default PrepareJE;
