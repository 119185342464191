import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import React from 'react';
import cardStyles, { AccountLabel } from './cardStyles.styled';

interface Props {
  unScheduleAssetsCount: number;
}

const AccountUnScheduledCountInfo = ({ unScheduleAssetsCount }: Props) => {
  const classes = cardStyles();
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
    >
      <AccountLabel>Account</AccountLabel>
      {
        unScheduleAssetsCount > 0 && (
          <section>
            <p className={clsx(classes.accountStatus, classes.pendingAssets)}>
              {unScheduleAssetsCount}
            </p>
          </section>
        )
      }
    </Box>
  );
};

export default React.memo(AccountUnScheduledCountInfo);
