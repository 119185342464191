/* eslint-disable @typescript-eslint/no-throw-literal,no-throw-literal */
import React, {
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import cloneDeep from 'lodash.clonedeep';
import {
  format,
  startOfMonth,
  subDays,
} from 'date-fns';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import roundTo from 'round-to';
import {
  Account,
  AmortizationSource,
  ProductCategory,
  Subledger,
} from '../../../interfaces/types';
import {
  BULK_EDIT,
  BULK_REMOVE_ASSETS,
  DAY_SHORT_FORMAT,
  FINALIZED_SUBLEDGER_STATUS,
  IMPORT_CSV,
  INITIALED,
  SUBLEDGER_AMORTIZATION_SCHEDULE_CHANGE,
  SUBLEDGER_START_DATE_CHANGE,
} from '../../../util/constants';
import Header from './Header';
import {
  isCreateSubledgerFormValid,
  updateAssetsForSaving,
} from '../../../components/Subledger/common';
import DialogBox from '../../../components/DialogBox';
import reducer from '../../../components/Subledger/reducer';
import { toUTC } from '../../../util/timezone';
import ErrorPage from '../../../components/ErrorPage';
import CreateSubledgerTable from '../../../components/Subledger/CreateSubledgerTable';
import isEmpty from '../../../util/isEmpty';
import { getAccountInfo } from '../../../store/slices/account';
import { AppThunkDispatch } from '../../../store/store';
import {
  accountLastFetchSelector,
  isFetchingAccountInfo,
} from '../../../store/selectors/account';
import {
  getSubledgerDetails,
  clearSubledger,
  getBalanceData,
  putSubledger,
} from '../../../store/slices/subledger';
import {
  isFetchingSubledger,
  fetchingSubledgerError,
  fetchingBalanceDataError,
  isFetchingBalanceData,
  isUpdatingSubledger,
} from '../../../store/selectors/subledger';
import { Loader } from '../../../components/Loader';

interface Params {
  id: string;
}

const CreateSubledger = () => {
  const { id: subledgerId } = useParams<Params>();
  const history = useHistory();
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const [isInitialized, setIsInitialized] = useState(false);

  const lastFetch = useSelector(accountLastFetchSelector);
  const isFetching = useSelector(isFetchingAccountInfo);
  const subledgerError = useSelector(fetchingSubledgerError);
  const accountBalanceError = useSelector(fetchingBalanceDataError);
  const subledgerLoading = useSelector(isFetchingSubledger);
  const subledgerBalanceLoading = useSelector(isFetchingBalanceData);
  const isUpdating = useSelector(isUpdatingSubledger);

  const [openSubledgerDialog, setOpenSubledgerDialog] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [formSubmit, setFormSubmit] = useState<boolean>();
  const [accountBalance, setAccountBalance] = useState<Account | null>();
  const [isBulkEditApplied, setIsBulkEditApplied] = useState<boolean>(false);

  // @ts-ignore
  const [state, dispatch] = useReducer(reducer, {
    selectedRow: '',
    subledger: {},
    hoverRow: '',
    isSplitAssetsSumCorrect: true,
  });
  const {
    subledger,
    selectedRow,
    hoverRow,
    isSplitAssetsSumCorrect,
  } = state;

  // Will get subleger balance by last day of the month of previous month
  const lastDayOfSelectedDate = state?.subledger?.factaStartDate
    ? format(subDays(startOfMonth(state.subledger.factaStartDate), 1), DAY_SHORT_FORMAT)
    : '';

  const getData = useCallback(async () => {
    if (!subledgerLoading && !subledgerError) {
      reduxDispatch(getSubledgerDetails({ subledgerId }))
        .unwrap()
        .then((result) => {
          if (result.status === FINALIZED_SUBLEDGER_STATUS) {
            history.push(result?.productCategory === ProductCategory.DeferredRevenue ? '/revsync' : '/');
          }
          dispatch({
            type: INITIALED,
            payload: {
              subledger: result,
            },
          });
        });
    }
  }, [history, reduxDispatch, subledgerError, subledgerId, subledgerLoading]);

  const getAccountBalance = useCallback(() => {
    reduxDispatch(getBalanceData({
      subledgerId,
      lastDayOfSelectedDate,
    }))
      .unwrap()
      .then((result) => {
        setAccountBalance(result);
      });
  }, [reduxDispatch, subledgerId, lastDayOfSelectedDate]);

  useEffect(() => {
    if (lastDayOfSelectedDate) {
      getAccountBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastDayOfSelectedDate]);

  useEffect(() => {
    reduxDispatch(getAccountInfo())
      .unwrap()
      .then(() => getData())
      .finally(() => setIsInitialized(true));

    return () => {
      reduxDispatch(clearSubledger());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxDispatch]);

  useEffect(() => {
    if (isInitialized) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastFetch]);

  useEffect(() => {
    if (isBulkEditApplied) {
      onFinalizedSubledger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBulkEditApplied]);

  if (isFetching || !accountBalance || isEmpty(subledger) || !subledger) {
    return <Loader open />;
  }

  if (subledgerError || accountBalanceError) {
    return <ErrorPage />;
  }

  const navigateToDashboard = (productCategory: ProductCategory) => {
    if (productCategory === ProductCategory.DeferredRevenue) {
      history.push('/revsync');
    } else {
      history.push('/');
    }
  };

  const callSaveAPI = async (existingSubledger: Subledger, redirect?: boolean) => {
    const updatedSubledger = cloneDeep(existingSubledger) as Subledger;
    updatedSubledger.amortizationSources
      ?.filter((amortizationSource: AmortizationSource) => !amortizationSource.parentId)
      .forEach((prepaidAsset: AmortizationSource, index: number) => {
        if (updatedSubledger?.productCategory === ProductCategory.PrepaidExpense) {
          // eslint-disable-next-line no-param-reassign
          prepaidAsset.sourceId = `Open ${index + 1}`;
        } else if (updatedSubledger?.productCategory === ProductCategory.DeferredRevenue) {
          // eslint-disable-next-line no-param-reassign
          prepaidAsset.sourceId = `FINV${index + 1}`;
        }
      });
    updateAssetsForSaving(updatedSubledger?.amortizationSources);
    updatedSubledger.openingBalance = Number(updatedSubledger?.openingBalance);
    // @ts-ignore
    updatedSubledger.factaStartDate = toUTC(updatedSubledger.factaStartDate);
    reduxDispatch(putSubledger({
      subledgerId,
      updatedSubledger,
    }))
      .unwrap()
      .then((result) => {
        if (redirect && result.status === FINALIZED_SUBLEDGER_STATUS) {
          reduxDispatch(getAccountInfo())
            .then(() => {
              navigateToDashboard(updatedSubledger?.productCategory);
            });
        }
        dispatch({
          type: INITIALED,
          payload: {
            subledger: result,
          },
        });
      });
  };

  const onSave = async () => {
    await callSaveAPI({
      ...subledger,
      factaStartDate: startOfMonth(subledger?.factaStartDate),
    });
  };

  const onSaveAndExit = async () => {
    await callSaveAPI({
      ...subledger,
      factaStartDate: startOfMonth(subledger?.factaStartDate),
    }, true);
  };

  const onFinalizedSubledger = async () => {
    setFormSubmit(true);
    if (isCreateSubledgerFormValid(subledger, dispatch)) {
      if (accountBalance && roundTo(
        Number(accountBalance?.glBalance!), 2,
      ) !== roundTo(Number(subledger?.openingBalance), 2)) {
        setOpenSubledgerDialog(true);
        return;
      }
      await callSaveAPI({
        ...subledger,
        factaStartDate: startOfMonth(subledger?.factaStartDate),
        status: FINALIZED_SUBLEDGER_STATUS,
      }, true);
    }
  };

  const onCSVImport = (data: Array<AmortizationSource>) => {
    dispatch({
      type: IMPORT_CSV,
      payload: {
        assets: data,
      },
    });
  };

  const onBulkEditApply = (internalIds: Array<string>, changedProperties: any) => {
    dispatch({
      type: BULK_EDIT,
      payload: {
        internalIds,
        changedProperties,
      },
    });
  };

  const onBulkRemoveAssets = (internalIds: Array<string>) => {
    dispatch({
      type: BULK_REMOVE_ASSETS,
      payload: {
        internalIds,
      },
    });
  };

  const onBulkEditSchedule = (internalIds: Array<string>, changedProperties: any) => {
    onBulkEditApply(internalIds, changedProperties);
    setIsBulkEditApplied(true);
  };

  const navigateBack = () => {
    history.push(subledger?.productCategory === ProductCategory.DeferredRevenue ? '/revsync' : '/');
  };

  const closeDialog = () => {
    setOpenSubledgerDialog(false);
  };

  const onSubledgerAmortizationScheduleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch({
      type: SUBLEDGER_AMORTIZATION_SCHEDULE_CHANGE,
      payload: { value: event.target.value },
    });
  };

  const onFactaStartDateChange = (date: Date | null) => {
    // @ts-ignore
    dispatch({
      type: SUBLEDGER_START_DATE_CHANGE,
      payload: { value: date },
    });
  };

  const openSaveAndExitMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Loader open={subledgerLoading || subledgerBalanceLoading || isUpdating} />
      <DialogBox
        openDialog={openSubledgerDialog}
        closeDialog={closeDialog}
        dialogContext="The difference between your Facta subledger and your General Ledger balance must be $0.00 to
            finalize subledger."
        dialogTitle="Hint"
        dismissContext="Dismiss"
      />
      <Header
        onImportCSV={onCSVImport}
        subledger={subledger}
        onFactaStartDateChange={onFactaStartDateChange}
        onAmortizationScheduleTypeChange={onSubledgerAmortizationScheduleChange}
        accountBalance={accountBalance}
        onSave={onSave}
        openSaveAndExitMenu={openSaveAndExitMenu}
        onSaveAndExit={onSaveAndExit}
        navigateBack={navigateBack}
        onFinalizedSubledger={onFinalizedSubledger}
        handleClose={handleClose}
        anchorEl={anchorEl}
        isFinalizeSubledgerEnabled={isSplitAssetsSumCorrect}
      />
      <CreateSubledgerTable
        subledger={subledger}
        selectedRow={selectedRow}
        hoverRow={hoverRow}
        dispatch={dispatch}
        formSubmit={formSubmit}
        isSplitAssetsSumCorrect={isSplitAssetsSumCorrect}
        onBulkEditApply={onBulkEditApply}
        onBulkEditSchedule={onBulkEditSchedule}
        onBulkRemoveAssets={onBulkRemoveAssets}
      />
    </>
  );
};

export default React.memo(CreateSubledger);
