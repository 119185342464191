import React, {
  useEffect,
  useState,
} from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import {
  Box,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import {
  useHistory,
  useParams,
} from 'react-router';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  lastDayOfMonth,
  parseISO,
} from 'date-fns';
import {
  Breadcrumbs,
  BreadcrumbsItem,
} from '../../../components/BreadcrumbsStepper';
import { FormInput } from '../../../components/Inputs/FormInput';
import COLORS from '../../../theme/colors';
import { AccountDetails } from '../../../components/AccountDetails';
import { v2SubledgerSelectorFilteredUnfinalized } from '../../../store/selectors/v2Subledgers';
import { getAllAccounts } from '../../../store/slices/v2Accounts';
import {
  getV2SubledgerDetails,
  clearAllV2Subledgers,
} from '../../../store/slices/v2Subledgers';
import { AppThunkDispatch } from '../../../store/store';
import { Loader } from '../../../components/Loader';
import { BottomActions } from '../../../components/Subledger/FixedAssets/BottomActions';
import { InputLabel } from '../../../components/InputLabel';
import FactaDatePicker from '../../../components/DatePicker';
import {
  clearV2CandidateJournals,
  getV2CandidateJEs,
  postV2JournalEntry,
} from '../../../store/slices/v2JournalEntries';
import {
  isPostingV2JournalEntry,
  v2CandidateJEsSelector,
} from '../../../store/selectors/v2JournalEntries';
import { PrepareJETable } from '../../../components/Subledger/FixedAssets/PrepareJETable';
import { InformationBanner } from '../../../components/InformationBanner';
import { NotifierType } from '../../../interfaces/types';
import { calculateSubledgerDetails } from '../../../util/pages/FixedAssets/calculateSubledgerDetails';
import AssetsSettled from '../../../components/Dashboard/AssetsSettled';
import { lastV2SyncSelector } from '../../../store/selectors/v2Sync';

interface PostJEForm {
  jeNumber: string;
  postingDate: Date;
  jeDescription: string;
}

const yupSchema = () => yupResolver(yup.object().shape({
  jeNumber: yup.string()
    .required('This field is required')
    .min(2),
  postingDate: yup.date().required(),
  jeDescription: yup.string()
    .required()
    .min(2)
    .max(140),
}));

const useStyles = makeStyles(() => createStyles({
  formContent: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '32px',
  },
  wrapper: {
    padding: '0 32px',
    borderLeft: `1px solid ${COLORS.disabled}`,
  },
}));

export const FixedAssetsPrepareJE = () => {
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const classes = useStyles();
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const { subledgerId, subledgerDate } = useParams<{ subledgerId: string; subledgerDate: string }>();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const lastFetch = useSelector(lastV2SyncSelector);
  const subledgerDetails = useSelector(v2SubledgerSelectorFilteredUnfinalized);
  const candidateJEs = useSelector(v2CandidateJEsSelector);
  const isPosting = useSelector(isPostingV2JournalEntry);

  const { allAssetsScheduled } = calculateSubledgerDetails({ fixedAssets: subledgerDetails?.fixedAssets });

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      reduxDispatch(getV2CandidateJEs({ subledgerId, date: subledgerDate })),
      reduxDispatch(getV2SubledgerDetails({ subledgerId, date: subledgerDate })),
      reduxDispatch(getAllAccounts({})),
    ])
      .finally(() => setIsLoading(false));

    return () => {
      reduxDispatch(clearV2CandidateJournals());
      reduxDispatch(clearAllV2Subledgers());
    };
  }, [subledgerId, subledgerDate, reduxDispatch, lastFetch]);

  const {
    control,
    errors,
    handleSubmit,
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: yupSchema(),
    shouldUnregister: true,
  });

  const handlePostJe = (data: PostJEForm) => {
    const parsedData = {
      description: data.jeDescription,
      number: data.jeNumber,
      contentHash: candidateJEs.contentHash,
    };

    reduxDispatch(postV2JournalEntry({ subledgerId, date: subledgerDate, ...parsedData }))
      .unwrap()
      .then(() => {
        setIsSuccessModalOpen(true);
      });
  };

  useEffect(() => {
    const updatedAssetType: PostJEForm = ({
      postingDate: parseISO(candidateJEs.postingDate),
      jeDescription: candidateJEs.description,
      jeNumber: candidateJEs.number,
    });

    reset(updatedAssetType);
  }, [candidateJEs, reset]);

  const handleSuccessModalClose = () => {
    setIsSuccessModalOpen(false);
    history.push('/fixed-assets');
  };

  const handleGoToScheduler = () => {
    history.push(`/fixed-assets/scheduler/${subledgerId}/${subledgerDate}`);
  };

  const handleGoBack = () => {
    history.push(`/fixed-assets/scheduler/${subledgerId}/${subledgerDate}/review`);
  };

  return (
    <>
      <Loader open={isLoading} />
      {subledgerDetails && (
        <InformationBanner
      // TODO when ednpoint ready asses if banner could be based on response from BE
          isOpenProp={!allAssetsScheduled}
          informationType={NotifierType.Warning}
          icon={false}
        >
          <span>
            <strong>Warning: </strong>
            There was a change in your general ledger resulting in new unscheduled transactions.
          </span>
        </InformationBanner>
      )}
      <Box padding={4}>
        <Breadcrumbs style={{ marginTop: 0 }}>
          <BreadcrumbsItem
            step={1}
            checked
            action={handleGoToScheduler}
          >
            Schedule Fixed Assets
          </BreadcrumbsItem>
          <BreadcrumbsItem
            step={2}
            checked
            action={handleGoBack}
          >
            Review Depreciation
          </BreadcrumbsItem>
          <BreadcrumbsItem
            step={3}
            active
          >
            Prepare JE
          </BreadcrumbsItem>
        </Breadcrumbs>
        <form className={classes.formContent}>
          {!isLoading && subledgerDetails && (
            <AccountDetails
              accumulationAccountId={subledgerDetails.accumulationAccount}
              subledgerAccountId={subledgerDetails.accountId}
            />
          )}
          <Box className={classes.wrapper}>
            <InputLabel
              label="JE Number"
              tooltipText="This number is synced to your general ledger to identify your journal entry."
              centered
            />
            <FormInput
              control={control}
              name="jeNumber"
              defaultValue=""
              error={!!errors.jeNumber}
            />
          </Box>
          <Box className={classes.wrapper}>
            <InputLabel
              label="Posting Date"
              tooltipText="Posting date will always be the last day of the posting period."
              centered
            />
            <Controller
              name="postingDate"
              control={control}
              defaultValue={lastDayOfMonth(parseISO(subledgerDate))}
              render={({ onChange, value: localValue }) => (
                <FactaDatePicker
                  disabled
                  selectedDate={localValue}
                  onDateChange={(date) => onChange(date)}
                  error={!!errors.postingDate}
                />
              )}
            />
          </Box>
          <Box
            className={classes.wrapper}
            minWidth={320}
          >
            <InputLabel
              label="JE Description"
              centered
              keepHeight
            />
            <FormInput
              control={control}
              name="jeDescription"
              defaultValue=""
              error={!!errors.jeDescription}
            />
          </Box>
        </form>
      </Box>
      {!isLoading && subledgerDetails && candidateJEs && (
        <Box
          padding={2}
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          flexGrow={1}
        >
          <PrepareJETable tableData={candidateJEs.lines || []} />
          <BottomActions
            menuItems={[
              {
                label: 'Review Depreciation',
                variant: 'outlined',
                action: handleGoBack,
              },
              {
                label: 'Post JE',
                action: handleSubmit((data: PostJEForm) => handlePostJe(data)),
                disabled: isPosting || !allAssetsScheduled,
              },
            ]}
          />
        </Box>
      )}
      <Box position="relative">
        <AssetsSettled
          open={isSuccessModalOpen}
          onClose={handleSuccessModalClose}
        />
      </Box>
    </>
  );
};
