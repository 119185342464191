import {
  PRODUCTION,
  UAT,
  DEMO,
  DEXTER,
  VEGETA,
} from './constants';
import {
  sessionEnabledFeatures,
  enableFeature,
  disableFeature,
} from './helpers';

/**
 * Feature switches
 *
 * Manually: turned on/off by providing true/false
 * Environment level: by providing specific condidtion, ie.
 *   switchName: process.env.REACT_APP_ENV !== PRODUCTION,
 * Programatically: turned on/off by adding specific values,
 *  to session storage 'feature' key, ie: sessionStorage.setItem('feature', 'fixedAssets');
 */

// @ts-ignore
window.enableFixedAssetsLocal = () => enableFeature('fixedAssets');
// @ts-ignore
window.removeFixedAssetsLocal = () => disableFeature('fixedAssets');
// @ts-ignore
window.enableMarkerIOLocal = () => enableFeature('markerIO');
// @ts-ignore
window.removeMarkerIOLocal = () => disableFeature('markerIO');

export const getFeatureSwitches = () => ({
  /**
   * Asset Types tab on Chart of Accounts page
   */
  assetTypes: true,

  /**
   * Filtering feature on PrepaidSync dashboard page
   */
  prepaidSyncFiltering: ![PRODUCTION, UAT].includes(process.env.REACT_APP_ENV!),

  /**
   * Bulk edit feature on Create Subledger/Edit Historical RevSync/Prepaid Assets pages
   */
  bulkEdit: true,

  /**
   * Fixed Assets
   */
  fixedAssets: true,

  /**
   * FullStory script available on PROD/DEMO and UAT only
   */
  fullStory: [PRODUCTION, UAT, DEMO].includes(process.env.REACT_APP_ENV!),

  /**
   * Disposals script disabled on PROD
   */
  disposals: true,

  /**
   * Reports page and reports feature
   */
  reports: true,

  /**
   * Reports pages for Fixed Assets
   */
  reportsFixedAssets: true,
  /**
   * Marker.io script available on PROD/DEXTER and UAT only
   */
  markerIO: [
    UAT,
    DEXTER,
    DEMO,
    PRODUCTION,
    VEGETA,
  ].includes(process.env.REACT_APP_ENV!) || sessionEnabledFeatures()?.includes('markerIO'),

  /**
   * Prompt information disabled on DEMO, UAT
   */
  support: ![DEMO, UAT].includes(process.env.REACT_APP_ENV!),

});

export const featureSwitch = getFeatureSwitches();
