import {
  Button,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import COLORS from '../../theme/colors';
import { useTableStyles } from './Subledger.styled';

interface Props {
  addSplitAsset: () => void;
}

const AddSplitButtonRow = ({ addSplitAsset }: Props) => {
  const tableClasses = useTableStyles();
  return (
    <TableRow
      selected
      classes={{
        selected: tableClasses.selected,
      }}
    >
      <TableCell is-cell-sticky="true" />
      <TableCell is-cell-sticky="true" />
      <TableCell is-cell-sticky="true">
        <Button
          color="primary"
          className={tableClasses.arrowIcon}
          onClick={addSplitAsset}
          startIcon={(
            <AddIcon
              className={tableClasses.fontSize12}
              color="primary"
            />
          )}
        >
          <Typography style={{ color: COLORS.skyBlue }}>Add</Typography>
        </Button>
      </TableCell>
      <TableCell colSpan={100} />
    </TableRow>
  );
};

export default React.memo(AddSplitButtonRow);
