import { UsefulLifeUnits } from '../../../interfaces/fixedAssetTypes';

export const DEFAULT_ASSET_TYPE = {
  number: '',
  visible: true,
  name: '',
  description: '',
  depreciationAccount: undefined,
  gainOrLossAccount: undefined,
  usefulLifeValue: 0,
  usefulLifeUnits: { type: UsefulLifeUnits.MONTHS },
  usefulLifeValueMonths: 0,
  class: undefined,
  location: undefined,
  tags: [],
};

export const usefulLifeUnitsOptions = [
  { type: UsefulLifeUnits.MONTHS },
  { type: UsefulLifeUnits.YEARS },
];
