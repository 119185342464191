/* eslint-disable */
import TableBody from '@material-ui/core/TableBody';
import React from 'react';
import PostJETableFooter from './PostJETableFooter';
import { Account, AmortizationSource, ProductCategory } from '../../interfaces/types';
import PostJERevSyncTableHeader from './PostJERevSyncTableHeader';
import PostJERevSyncAccountRow from './PostJERevSyncAccountRow';
import PostJERevSyncRows from './PostJERevSyncRows';
import NoRecords from "../Dashboard/NoRecords";

interface Props {
  amortizationSources: Array<AmortizationSource>;
  scheduleDate: string;
  rowsCount: number;
  account: Account;
  currentMonth: string;
  totalPrepaidAccount: number;
  total: number;
}

const PostJERevSyncTable = ({
  amortizationSources,
  scheduleDate,
  rowsCount,
  account,
  currentMonth,
  totalPrepaidAccount,
  total,
}: Props) => {
  const isAccountClosed = totalPrepaidAccount === 0 || rowsCount === 0;
  return (
    <>
      <PostJERevSyncTableHeader />
      {
        isAccountClosed ? <NoRecords closedAccount={isAccountClosed} />
          : (
          <>
              <TableBody>
              <PostJERevSyncRows
                  amortizationSources={amortizationSources}
                  scheduleDate={scheduleDate}
                />
              <PostJERevSyncAccountRow
                  rowsCount={rowsCount}
                  account={account}
                  currentMonth={currentMonth}
                  totalPrepaidAccount={totalPrepaidAccount}
                />
            </TableBody>
              <PostJETableFooter
              total={total}
              productCategory={ProductCategory.DeferredRevenue}
            />
            </>
          )
      }
    </>
  );
};

export default React.memo(PostJERevSyncTable);
