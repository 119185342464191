import startCase from 'lodash.startcase';
import {
  uniqBy,
  prop,
} from 'ramda';

export const getUniqueOptions = (list: any[], key: string) => uniqBy(prop(key), list).map((listItem) => ({
  value: listItem[key],
  label: startCase(listItem[key]),
}));
