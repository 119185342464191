import React from 'react';
import {
  makeStyles,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { TagType } from '../../interfaces/types';

const useStyles = makeStyles(() => ({
  general: {
    padding: '5px 16px',
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 500,
    borderRadius: '4px',
  },
  new: {
    color: '#448C3C',
    backgroundColor: '#F1FDF0',
    border: '1px solid #C6F6C1',
  },
  comingSoon: {
    color: '#11ADF8',
    backgroundColor: '#F3FBFF',
    border: '1px solid #A0DEFC',
  },
}));

interface Props {
  tagType: TagType;
}

const Tag = ({
  tagType,
}: Props) => {
  const classes = useStyles();

  return (
    <Typography
      className={clsx(classes.general, {
        [classes.new]: tagType === TagType.New,
        [classes.comingSoon]: tagType === TagType.ComingSoon,
      })}
    >
      {`${tagType}!`}
    </Typography>
  );
};

export default Tag;
