import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import COLORS from '../../theme/colors';
import { ErrorPageType } from '../../interfaces/types';
import {
  StyledButton,
  WelcomeMessage,
} from '../common.styled';

interface Props {
  variant?: string;
}

const ErrorPage = ({ variant = ErrorPageType.Error }: Props) => (
  <Box
    bgcolor={COLORS.lighterBlue}
    height={172}
    display="flex"
    flexDirection="row"
    width="95%"
    alignSelf="center"
    borderRadius="4px"
    marginTop={6}
    p={0}
  >
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
    >
      <img
        alt="half-llama"
        src="/half-llama.svg"
      />
    </Box>
    <Box
      display="flex"
      maxWidth="650px"
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-start"
      marginLeft="-50px"
    >
      <WelcomeMessage>{variant === ErrorPageType.NotFound ? 'ERROR 404' : 'Something Went Wrong'}</WelcomeMessage>
      <Typography>
        We’re not sure what went wrong, you can go back, or visit the homepage.
      </Typography>
    </Box>
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      marginLeft="-175px"
      marginTop="75px"
    >
      <img
        alt="get started arrow"
        src="/get-started-arrow.svg"
      />
    </Box>
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      ml="4px"
    >
      <StyledButton
        variant="contained"
        color="primary"
        href="/"
      >
        Homepage
      </StyledButton>
    </Box>
  </Box>
);

export default ErrorPage;
