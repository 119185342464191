import styled from 'styled-components';
import { Box } from '@material-ui/core';
import COLORS from '../../../../theme/colors';

export const ClosedTag = styled(Box)({
  display: 'flex',
  fontSize: 10,
  fontWeight: 500,
  color: COLORS.successGreen,
  '& span:first-of-type': {
    marginRight: 8,
    lineHeight: '24px',
  },
  '& span:last-of-type': {
    color: COLORS.white,
    width: 24,
    height: 24,
    background: COLORS.successGreen,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      width: 16,
      height: 16,
    },
  },
});
