import { makeStyles } from '@material-ui/core';
import COLORS from '../../../theme/colors';

export const useStyles = makeStyles(() => ({
  button: {
    justifyContent: 'space-between',
    fontSize: '12px',
    lineHeight: 1,
    fontWeight: 500,
    textAlign: 'left',
    margin: 0,
    borderRadius: '4px',
    padding: '6px 12px',
    height: '32px',
    borderColor: COLORS.border,
    '& .MuiButton-endIcon': {
      marginLeft: 'auto',
      '& svg': {
        fontSize: '21px',
        color: 'rgba(0, 0, 0, 0.54)',
      },
    },
  },
  buttonSelectAll: {
    width: '100%',
    fontSize: '12px',
    fontWeight: 500,
    textAlign: 'left',
    padding: '12px 6px',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
  listBox: {
    minWidth: '260px',
    maxWidth: '300px',
    margin: '2px 0 0',
    padding: 0,
    listStyle: 'none',
    backgroundColor: COLORS.white,
    overflow: 'auto',
    maxHeight: '260px',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',

    '& li': {
      display: 'flex',
      padding: '10px 8px',
      borderRadius: '4px',
      marginTop: '8px',

      '& span': {
        flexGrow: 1,
      },

      '& svg': {
        color: 'transparent',
      },
    },

    '& li[aria-selected="true"]': {
      backgroundColor: COLORS.skyBlueShaded,
      fontWeight: '700',
      color: COLORS.skyBlue,

      '& svg': {
        color: COLORS.skyBlue,
      },

      '&.selected': {
        order: -1,
      },
    },

    '& li[data-focus="true"]': {
      backgroundColor: COLORS.lightGray3,
      cursor: 'pointer',

      '& svg': {
        color: COLORS.lightGray2,
      },
    },
  },
  selectedIndicator: {
    margin: '0 4px',
    minWidth: 15,
    '& span': {
      fontWeight: 700,
      color: COLORS.skyBlue,
    },
  },
  selectBox: {
    padding: '8px 0',
    borderBottom: `solid 1px ${COLORS.border}`,
  },
  paper: {
    margin: '8px 0',
    padding: '8px',
  },
  searchField: {
    margin: '0',
    boxShadow: 'none',
    '& .MuiInputBase-input': {
      paddingTop: '8px',
      paddingBottom: '8px',
    },
  },
  buttonBox: {
    marginTop: '32px',
    display: 'flex',
    '& > *': {
      width: '50%',
      margin: 0,
    },
    '& > :first-of-type': {
      marginRight: '8px',
    },
  },
  applyButton: {
    color: COLORS.white,
  },
}));
