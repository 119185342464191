import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import debounce from 'lodash.debounce';
import { STICKY_COLUMN_Z_INDEX } from '../util/constants';

/**
 * Custom hook to apply sticky columns within provided table
 *
 * The hook is looking for cells with [is-cell-sticky="true"] attribute within provided table
 * component and applies needed inline style parameters.
 *
 * @param tableRef ref object of table component
 * @param deps dependency array on which update should occur
 * @param reservedWidth minimum width reserved for horizontal scroll, default: 400
 * @returns setShouldUpdate state setter
 */
const useStickyColumns = (
  tableRef: React.MutableRefObject<HTMLTableElement | null>,
  deps: any[],
  reservedWidth = 400,
): React.Dispatch<React.SetStateAction<boolean>> => {
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const initialize = useCallback(() => {
    const tableElement = tableRef.current;
    const tableHeader = tableElement?.querySelector('thead tr');

    if (!tableElement || !tableHeader) return;

    const maxStickyWidth = (tableElement.parentNode as Element).clientWidth - reservedWidth;
    const columnsWidths = Array.from(tableHeader.children).map((el) => el.clientWidth);

    tableElement.querySelectorAll('[is-cell-sticky="true"]').forEach((el) => {
      if (el && el.parentNode) {
        const index = Array.from(el.parentNode.children).indexOf(el);
        const left = columnsWidths.slice(0, index).reduce((x, y) => x + y, 0);
        const shouldBeSticky = left + columnsWidths[index] < maxStickyWidth;

        if (shouldBeSticky) {
          el.setAttribute(
            'style',
            `position: sticky; z-index: ${STICKY_COLUMN_Z_INDEX}; left: ${left}px; background-color: inherit;`,
          );
        } else if (el.tagName !== 'TH') {
          el.setAttribute('style', 'position: relative; z-index: unset;');
        } else {
          el.setAttribute('style', 'z-index: 199;');
        }
      }
    });

    setShouldUpdate(false);
  }, [reservedWidth, tableRef]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedInitialize = useCallback(debounce(initialize, 200), []);

  useEffect(() => {
    window.addEventListener('resize', debouncedInitialize);

    return () => {
      window.removeEventListener('resize', debouncedInitialize);
    };
  }, [debouncedInitialize]);

  useEffect(() => {
    initialize();
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (shouldUpdate) {
      initialize();
    }
  }, [initialize, shouldUpdate]);

  return setShouldUpdate;
};

export default useStickyColumns;
