import React, { useState } from 'react';
import clsx from 'clsx';
import {
  useHistory,
  useRouteMatch,
} from 'react-router';
import {
  Box,
  Button,
  makeStyles,
  Menu,
  MenuItem,
  styled,
} from '@material-ui/core';
import COLORS from '../../../theme/colors';

const StyledNavigationButtonBox = styled(Box)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: 7,
  borderRadius: 4,
});

const StyledNavigationButton = styled(Button)({
  color: COLORS.white,
  marginTop: 20,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  textTransform: 'none',
  fontSize: 11,
  fontWeight: 500,
  textAlign: 'left',
  paddingLeft: 7,
  paddingRight: 7,
  height: 32,
  '&.Mui-disabled': {
    opacity: 0.6,
    color: COLORS.white,
  },
  minWidth: 'auto',
});

const useStyles = makeStyles({
  active: {
    backgroundColor: COLORS.lightActiveColor,
  },
  menuText: {
    marginLeft: 4,
    opacity: 0,
    transition: 'opacity 0.3s ease',
  },
  menuTextVisible: {
    opacity: 1,
  },
  menuIcon: {
    fontSize: 24,
  },
  styledNavigationCollapsed: {
    width: '40px',
  },
});

interface SideBarItem {
  label: string;
  path: string;
  exact: boolean;
  disabled: boolean;
  Icon: (props: { className: string }) => JSX.Element;
  subItems?: Array<NavigationSubItem>;
  action?: Function;
  customStyle?: React.CSSProperties;
}

interface NavigationSubItem {
  label: string;
  path: string;
  action?: Function;
}

interface Props {
  sidebarItem: SideBarItem;
  navBarOpen: boolean;
  navBarTextVisible: boolean;
}

export const NavigationItem = ({
  sidebarItem,
  navBarOpen,
  navBarTextVisible,
}: Props) => {
  const history = useHistory();
  const classes = useStyles();
  const routeMatch = useRouteMatch({
    path: sidebarItem.path,
    exact: sidebarItem.exact,
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClickItem = (ev: React.MouseEvent<HTMLButtonElement>) => {
    if (sidebarItem.subItems) {
      if (!anchorEl) setAnchorEl(ev.currentTarget);
    } else if (sidebarItem.action) {
      sidebarItem.action();
    } else {
      history.push(sidebarItem.path);
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickSubItem = (ev: React.MouseEvent<HTMLElement>, subItem: NavigationSubItem) => {
    setAnchorEl(null);
    if (subItem.action) {
      subItem.action();
    } else {
      history.push(subItem.path);
    }
  };

  return (
    <StyledNavigationButton
      key={sidebarItem.label}
      onClick={handleClickItem}
      disabled={sidebarItem.disabled}
      fullWidth
      style={sidebarItem.customStyle}
    >
      <StyledNavigationButtonBox
        whiteSpace="nowrap"
        className={clsx({
          [classes.active]: routeMatch,
        })}
      >
        <sidebarItem.Icon className={classes.menuIcon} />
        <span
          className={clsx(classes.menuText, {
            [classes.menuTextVisible]: navBarTextVisible || navBarOpen,
          })}
        >
          {sidebarItem.label}
        </span>
      </StyledNavigationButtonBox>
      {sidebarItem.subItems && (
        <Menu
          id={`menu_${sidebarItem.label}`}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {sidebarItem.subItems.map((subItem) => (
            <MenuItem
              key={subItem.label}
              onClick={(ev) => handleClickSubItem(ev, subItem)}
            >
              {subItem.label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </StyledNavigationButton>
  );
};
