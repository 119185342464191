import { V2Subledger } from '../interfaces/subledgers';
import {
  Account,
  Subledger,
} from '../interfaces/types';

export const addDisplayName = (
  array: Array<Account>,
) => array.map((item: Account) => ({ displayName: item.accNum ? `${item.accNum} ${item.name}` : item.name, ...item }));

export const sortAmortizationLogs = (subledger: Subledger) => {
  const amortizationLogsToBeSorted = { ...subledger.subledgerAmortizationLogs };

  if (amortizationLogsToBeSorted.length > 0) {
    amortizationLogsToBeSorted.sort((firstLog, secondLog) => (
      // @ts-ignore
      firstLog?.scheduleDate?.getTime() > secondLog?.scheduleDate?.getTime() ? 1 : -1));

    return {
      ...subledger,
      subledgerAmortizationLogs: amortizationLogsToBeSorted,
    };
  }
  return subledger;
};

export const filterUnfinalizedFactaAssets = (subledger: V2Subledger): V2Subledger => {
  const filteredAssets = subledger.fixedAssets?.filter((asset) => asset.finalized || asset.transaction);

  return { ...subledger, fixedAssets: filteredAssets };
};

export const filterSubledgersNestedAssets = (subledgers: Array<V2Subledger>): Array<V2Subledger> => subledgers
  .map((subledger) => filterUnfinalizedFactaAssets(subledger));
