import React, { forwardRef } from 'react';
import { ReactDatePickerProps } from 'react-datepicker';
import InputAdornment from '@material-ui/core/InputAdornment';
import { CalendarIcon } from '../../../Icons';
import { StyledInput } from '../../../Inputs/index.styled';
import COLORS from '../../../../theme/colors';

type Props = Pick<ReactDatePickerProps, 'value' | 'className'> | {
  error?: boolean;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
};

const StyledCalendarInput = (props: Props) => (
  <StyledInput
    {...props}
    style={{ width: 'auto' }}
    endAdornment={(
      <InputAdornment position="end">
        <CalendarIcon fill={COLORS.lightGray} />
      </InputAdornment>
    )}
  />
);

export default forwardRef(StyledCalendarInput);
