import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import {
  Button,
  createStyles,
  Link,
} from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import { StyledFormLabel } from '../../components/FormControl/FormControl.styled';
import { PRODUCTION } from '../../util/constants';
import DialogBox from '../../components/DialogBox';
import { AppThunkDispatch } from '../../store/store';
import { deleteAccountData } from '../../store/slices/account';
import {
  postV2Reset,
  postV2Sync,
} from '../../store/slices/v2Sync';

const useStyles = makeStyles(() => createStyles({
  helperLinks: {
    paddingLeft: 4,
    marginTop: 15,
  },
  warning: {
    marginTop: 10,
  },
}));

const About = () => {
  const [deleteAllModal, setDeleteAllModal] = useState(false);
  const classes = useStyles();
  const reduxDispatch: AppThunkDispatch = useDispatch();

  const triggerDeleteAll = () => {
    setDeleteAllModal(true);
  };

  const onDelete = () => {
    Promise.all([
      reduxDispatch(deleteAccountData()),
      reduxDispatch(postV2Reset()),
    ])
      .finally(() => {
        setDeleteAllModal(false);
        reduxDispatch(postV2Sync());
      });
  };

  return (
    <Box
      width="100%"
      display="flex"
      alignItems="flex-start"
    >
      <DialogBox
        openDialog={deleteAllModal}
        closeDialog={() => setDeleteAllModal(false)}
        dialogTitle="Warning!"
        dialogContext={(
          <Box mt={3}>
            You are about to remove all your data from Facta application. Do you wish to proceed?
          </Box>
        )}
        dismissContext="Cancel"
        actions={[
          {
            title: 'Remove',
            event: () => onDelete(),
          },
        ]}
      />
      <Box flexGrow={1}>
        <StyledFormLabel>Product Info</StyledFormLabel>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
        >
          <Link
            className={classes.helperLinks}
            href="https://help.facta.io"
            target="_blank"
          >
            FAQ
          </Link>
          <Link
            className={classes.helperLinks}
            href="https://facta.io/privacy-policy"
            target="_blank"
          >
            Policies & Procedures
          </Link>
          <Link
            className={classes.helperLinks}
            href="https://facta.io/terms-of-service"
            target="_blank"
          >
            Terms of Use
          </Link>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
      >
        <StyledFormLabel>Settings</StyledFormLabel>
        <Box>
          {process.env.REACT_APP_ENV !== PRODUCTION && (
            <Button
              variant="contained"
              color="secondary"
              className={classes.warning}
              onClick={triggerDeleteAll}
            >
              Delete All
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(About);
