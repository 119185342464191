import React from 'react';
import { useSelector } from 'react-redux';
import TableBody from '@material-ui/core/TableBody';
import {
  AmortizationSource,
  Subledger,
} from '../../interfaces/types';
import {
  getChildren,
  getStartEndDate,
} from './common';
import AddSplitButtonRow from './AddSplitButtonRow';
import { accountInfoSelector } from '../../store/selectors/account';
import CreateSubledgerRevSyncTableHeader from './CreateSubledgerRevSyncTableHeader';
import CreateSubledgerRevSyncSelectedRow from './CreateSubledgerRevSyncSelectedRow';
import CreateSubledgerRevSyncReadOnlyRow from './CreateSubledgerRevSyncReadOnlyRow';

interface Props {
  subledger: Subledger;
  selectedRow: string;
  hoverRow: string;
  formSubmit?: boolean;
  addSplit: (internalId: string) => () => void;
  addSplitAsset: (internalId: string) => () => void;
  removeAsset: (internalId: string) => () => void;
  onInputBoxChange: (propertyName: string, internalId: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  onInputBoxFocus: (propertyName: string, internalId: string) => (event: React.FocusEvent<HTMLInputElement>) => void;
  onSelectChange: (propertyName: string, internalId: string) => (value: string) => void;
  onAutoCompleteChange: (propertyName: string, internalId: string) => (value: string) => void;
  onDateChange: (propertyName: string, internalId: string, value: Date | null) => void;
  onShowDetails: (internalId: string, sourceId: string) => void;
  isCollapsedView: boolean;
  isCollapsedViewHovered: boolean;
  isSplitAssetsSumCorrect: boolean;
  onCheckboxChange: (internalId: string) => void;
  checkIsElementSelected: (internalId: string) => boolean;
  onSelectAll: () => void;
  isAllSelected: boolean;
  onRowClick: (asset: AmortizationSource) => void;
  showBulkEdit: boolean;
  page: number;
  pageSize: number;
}

const CreateSubledgerRevSyncTranscations = ({
  subledger,
  selectedRow,
  hoverRow,
  formSubmit,
  addSplit,
  addSplitAsset,
  removeAsset,
  onInputBoxChange,
  onInputBoxFocus,
  onSelectChange,
  onAutoCompleteChange,
  onDateChange,
  onShowDetails,
  isCollapsedView,
  isCollapsedViewHovered,
  isSplitAssetsSumCorrect,
  onCheckboxChange,
  onSelectAll,
  isAllSelected,
  checkIsElementSelected,
  onRowClick,
  showBulkEdit,
  page,
  pageSize,
}: Props) => {
  const account = useSelector(accountInfoSelector)!;
  const { accountClasses } = account;
  const { startDate, endDate } = getStartEndDate(subledger);

  const renderSelectedRow = (amortizationSource: AmortizationSource, index: number) => {
    const children = getChildren(amortizationSource.internalId, subledger?.amortizationSources);
    const parent = (
      <CreateSubledgerRevSyncSelectedRow
        key={amortizationSource.internalId}
        selectedRow={selectedRow}
        amortizationSource={amortizationSource}
        hoverRow={hoverRow}
        onRowSelected={() => onRowClick(amortizationSource)}
        hasChildren={!!children?.length}
        index={index}
        formSubmit={formSubmit}
        onInputBoxChange={onInputBoxChange}
        onInputBoxFocus={onInputBoxFocus}
        onCustomerChange={onAutoCompleteChange('customerId', amortizationSource.internalId)}
        onProductChange={onAutoCompleteChange('productId', amortizationSource.internalId)}
        onExpenseAccountChange={onAutoCompleteChange('destinationId', amortizationSource.internalId)}
        account={account}
        onClassChange={onAutoCompleteChange('classId', amortizationSource.internalId)}
        accountClasses={accountClasses}
        onDateChange={onDateChange}
        onSelectChange={onSelectChange}
        onRemoveAsset={removeAsset(amortizationSource.internalId)}
        onAddAsset={addSplit(amortizationSource.internalId)}
        startDate={startDate}
        endDate={endDate}
        amortizationSources={subledger?.amortizationSources}
        factaStartDate={subledger?.factaStartDate}
        isCollapsedView={isCollapsedView}
        isSplitAssetsSumCorrect={isSplitAssetsSumCorrect}
        isSelectedForBulkEdit={checkIsElementSelected(amortizationSource.internalId)}
        onCheckboxChange={onCheckboxChange}
        onShowDetails={(sourceId: string) => onShowDetails(amortizationSource.internalId, sourceId)}
        showBulkEdit={showBulkEdit}
      />
    );
    if (!children?.length) {
      return parent;
    }
    return (
      <>
        {parent}
        {
            children?.map((child: AmortizationSource, childIndex: number) => (
              <CreateSubledgerRevSyncSelectedRow
                key={child.internalId}
                selectedRow={selectedRow}
                amortizationSource={child}
                hoverRow={hoverRow}
                onRowSelected={() => onRowClick(child)}
                hasChildren={false}
                index={childIndex}
                formSubmit={formSubmit}
                onInputBoxChange={onInputBoxChange}
                onInputBoxFocus={onInputBoxFocus}
                onCustomerChange={onAutoCompleteChange('customerId', amortizationSource.internalId)}
                onProductChange={onAutoCompleteChange('productId', amortizationSource.internalId)}
                onExpenseAccountChange={onAutoCompleteChange('destinationId', child.internalId)}
                account={account}
                onClassChange={onAutoCompleteChange('classId', child.internalId)}
                accountClasses={accountClasses}
                onDateChange={onDateChange}
                onSelectChange={onSelectChange}
                onRemoveAsset={removeAsset(child.internalId)}
                onAddAsset={addSplit(amortizationSource.internalId)}
                startDate={startDate}
                endDate={endDate}
                amortizationSources={subledger?.amortizationSources}
                lastChild={children?.length - 1 === childIndex}
                factaStartDate={subledger?.factaStartDate}
                isCollapsedView={isCollapsedView}
                isSplitAssetsSumCorrect={isSplitAssetsSumCorrect}
                isSelectedForBulkEdit={checkIsElementSelected(amortizationSource.internalId)}
                onCheckboxChange={onCheckboxChange}
                onShowDetails={(sourceId: string) => onShowDetails(child.internalId, sourceId)}
                showBulkEdit={showBulkEdit}
              />
            ))
        }
        <AddSplitButtonRow
          addSplitAsset={addSplitAsset(amortizationSource?.internalId)}
        />
      </>
    );
  };

  const renderRow = (amortizationSource: AmortizationSource, index: number) => {
    const children = getChildren(amortizationSource.internalId, subledger?.amortizationSources);
    const parent = (
      <CreateSubledgerRevSyncReadOnlyRow
        selectedRow={selectedRow}
        amortizationSource={amortizationSource}
        hoverRow={hoverRow}
        onClick={() => onRowClick(amortizationSource)}
        hasChildren={!!children?.length}
        lastChild={false}
        index={index}
        account={account}
        startDate={startDate}
        endDate={endDate}
        amortizationSources={subledger?.amortizationSources}
        isCollapsedView={isCollapsedView}
        isSelectedForBulkEdit={checkIsElementSelected(amortizationSource.internalId)}
        onCheckboxChange={onCheckboxChange}
        showBulkEdit={showBulkEdit}
      />
    );
    if (!children?.length) {
      return parent;
    }
    return (
      <>
        {parent}
        {
            children
              ?.map((child: AmortizationSource, childIndex: number) => (
                <CreateSubledgerRevSyncReadOnlyRow
                  key={child.internalId}
                  selectedRow={selectedRow}
                  amortizationSource={child}
                  hoverRow={hoverRow}
                  onClick={() => onRowClick(child)}
                  hasChildren={false}
                  lastChild={children?.length - 1 === childIndex}
                  index={index}
                  account={account}
                  startDate={startDate}
                  endDate={endDate}
                  amortizationSources={subledger?.amortizationSources}
                  isCollapsedView={isCollapsedView}
                  isSelectedForBulkEdit={checkIsElementSelected(amortizationSource.internalId)}
                  onCheckboxChange={onCheckboxChange}
                  showBulkEdit={showBulkEdit}
                />
              ))
        }
      </>
    );
  };

  const renderAsset = () => subledger?.amortizationSources
    ?.filter((amortizationSource: AmortizationSource) => !amortizationSource.parentId)
    .splice(page * pageSize, pageSize)
    .map((amortizationSource: AmortizationSource, index: number) => {
      if (amortizationSource.internalId === selectedRow) {
        return renderSelectedRow(amortizationSource, page * pageSize + index);
      }
      return renderRow(amortizationSource, page * pageSize + index);
    });

  return (
    <>
      <CreateSubledgerRevSyncTableHeader
        subledger={subledger}
        isCollapsedView={isCollapsedView}
        isCollapsedViewHovered={isCollapsedViewHovered}
        onSelectAll={onSelectAll}
        isAllSelected={isAllSelected}
        showBulkEdit={showBulkEdit}
      />
      <TableBody>
        {renderAsset()}
      </TableBody>
    </>
  );
};

export default React.memo(CreateSubledgerRevSyncTranscations);
