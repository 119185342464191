/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import {
  createStyles,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  dropDown: {
    fontSize: 10,
  },
}));

const DropDownIcon = (props: any) => {
  const classes = useStyles();
  return (
    <SvgIcon
      className={classes.dropDown}
      viewBox="0 0 10 6"
      fill="none"
      fontSize="small"
      height={2}
      width={2}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.14933 6L0.689946 -8.15666e-07L9.60872 -5.85622e-08L5.14933 6Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default DropDownIcon;
