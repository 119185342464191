import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import cardStyles from './cardStyles.styled';
import AccountName from './AccountName';
import GLBalance from './GLBalance';
import FactaBalance from './FactaBalance';
import TaskReadOnlyDetail from './TaskReadOnlyDetail';
import TaskActionDetail from './TaskActionDetail';
import AccountUnScheduledCountInfo from './AccountUnScheduledCountInfo';
import {
  OutOfPeriodData,
  ProductCategory,
} from '../../../interfaces/types';
import { getCategoryLabels } from '../../Subledger/common';
import { OutOfPeriodWarning } from './OutOfPeriodWarning';

interface Props {
  active: boolean;
  accountName: string;
  loading: boolean;
  glBalance: number;
  openingBalance: number;
  action: () => void;
  onCurrencyNavigation: () => void;
  amortizationUnPostedAmount: number;
  unScheduleAssetsCount: number;
  category: ProductCategory;
  outOfPeriodData: OutOfPeriodData;
}

const CurrentMonthScheduledCard = ({
  active,
  accountName,
  loading,
  glBalance,
  openingBalance,
  action,
  onCurrencyNavigation,
  amortizationUnPostedAmount,
  unScheduleAssetsCount,
  category,
  outOfPeriodData,
}: Props) => {
  const classes = cardStyles();
  const cardLabels = getCategoryLabels(category);
  const { outOfPeriod } = outOfPeriodData;

  return (
    <Card
      className={clsx(classes.card, {
        [classes.active]: active,
        [classes.cardError]: outOfPeriod,
      })}
    >
      {outOfPeriod && <OutOfPeriodWarning outOfPeriodData={outOfPeriodData} />}
      <CardContent>
        <AccountUnScheduledCountInfo
          unScheduleAssetsCount={unScheduleAssetsCount}
        />
        <AccountName accountName={accountName} />
        <GLBalance
          glBalance={glBalance}
          loading={loading}
        />
        <FactaBalance
          balance={openingBalance}
          error={outOfPeriod}
        />
      </CardContent>
      <CardActions className={classes.padding15}>
        <TaskReadOnlyDetail
          name={cardLabels.scheduled}
          completed
          amount={0}
          onCurrencyNavigation={onCurrencyNavigation}
          enableCurrencyNavigation
        />
        <TaskActionDetail
          name={cardLabels.postButton}
          amount={amortizationUnPostedAmount}
          action={action}
        />
        <TaskReadOnlyDetail
          name={cardLabels.posted}
          amount={0}
        />
      </CardActions>
    </Card>
  );
};

export default React.memo(CurrentMonthScheduledCard);
