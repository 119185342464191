import {
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import COLORS from '../../theme/colors';
import { fontFamily } from '../../theme/theme';

export const useStyles = makeStyles(() => createStyles({
  root: {
    fontWeight: 'normal',
    fontSize: 12,
    margin: 0,
    padding: 0,
    paddingLeft: 8,
    paddingRight: 8,
    color: COLORS.deepGray,
    minWidth: 81,
    height: 32,
    border: 'none',
    backgroundColor: COLORS.lightGray3,
    boxShadow: COLORS.selectBoxShadow,
    '&.Mui-error': {
      border: `1px solid ${COLORS.violet}`,
    },
    '&.Mui-focused': {
      boxShadow: COLORS.focusBoxShadow,
    },
  },
  readOnly: {
    '&.Mui-focused': {
      boxShadow: COLORS.selectBoxShadow,
    },
  },
  datepicker: {
    '& .MuiButton-endIcon': {
      marginLeft: 'auto',
    },
  },
  datepickerCalendar: {
    background: COLORS.white,
    border: 'none',
    boxShadow: COLORS.inputBoxShadow,
    fontFamily,
    fontSize: '12px',
    padding: '16px',

    '& .react-datepicker__header': {
      backgroundColor: COLORS.white,
      borderBottom: 'none',
      paddingTop: 0,
    },

    '& .react-datepicker__current-month': {
      fontSize: '12px',
      textAlign: 'left',
      fontWeight: 'normal',
      padding: '0px 8px 16px',
    },

    '& .react-datepicker__navigation': {
      top: '12px',
      width: '24px',
      height: '24px',
      border: 'none',

      '&--previous': {
        right: '64px',
        left: 'auto',
        background: 'url(/chevron-left.svg) no-repeat !important',
      },
      '&--next': {
        right: '20px',
        background: 'url(/chevron-right.svg) no-repeat !important',
      },
    },

    '& .react-datepicker__header__dropdown': {
      display: 'flex',
    },

    '& .react-datepicker__day-name': {
      fontWeight: 700,
      margin: '5px',
    },

    '& .react-datepicker__day': {
      color: COLORS.lightGray,
      borderRadius: '50%',
      margin: '5px',

      '&--in-range, &--in-selecting-range': {
        color: COLORS.white,
        backgroundColor: COLORS.lightBlue,
      },

      '&--selected, &--keyboard-selected, &--range-end, &--selecting-range-end': {
        color: COLORS.white,
        backgroundColor: COLORS.skyBlue,
      },

      '&--disabled': {
        color: COLORS.lightGray2,
      },

      '&--keyboard-selected': {
        color: COLORS.lightGray,
        backgroundColor: COLORS.white,
      },

      '&:hover': {
        color: COLORS.white,
        backgroundColor: COLORS.lightGray2,
      },
    },

    '& .react-datepicker__month-year-dropdown': {
      backgroundColor: COLORS.white,
      overflowX: 'auto',
      height: '250px',
    },

    '& .react-datepicker__month-year-option': {
      lineHeight: '24px',
      textAlign: 'left',
      padding: '0 8px',

      '&--selected': {
        display: 'none',
      },
    },

    '& .--selected_month-year': {
      fontWeight: 700,
      color: COLORS.skyBlue,
    },
  },
}));
