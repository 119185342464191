/* eslint-disable no-param-reassign */
import axios from 'axios';
import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import {
  Account,
  AccountClass,
  AccountInfo,
  NotifierType,
  LegacySchedulingMethod,
} from '../../interfaces/types';
import {
  addDisplayName,
} from '../../util/middleware';
import { openSnackbar } from '../../components/Notifier';
import { updateFullStoryGlobals } from '../../util/fullstory';

const PREFIX = 'account';
const GET_ACCOUNT_SYNC = `${PREFIX}/getSync`;
const GET_ACCOUNT_INFO = `${PREFIX}/getInfo`;
const DELETE_ACCOUNT_DATA = `${PREFIX}/deleteAccountData`;
const POST_ACCOUNT_COMPANY_SWITCH = `${PREFIX}/postCompanySwitch`;
const GET_ACCOUNT_JE_NUMBER = `${PREFIX}/accountJENumber`;

const GET_ACCOUNT_BALANCES = `${PREFIX}/getBalances`;
const PUT_ACCOUNT_BALANCES = `${PREFIX}/putBalances`;
const PUT_ACCOUNT_BALANCES_VISITED = `${PREFIX}/putBalancesVisited`;

const GET_ACCOUNT_INCOMES = `${PREFIX}/getIncomes`;
const PUT_ACCOUNT_INCOMES = `${PREFIX}/putIncomes`;
const PUT_ACCOUNT_INCOMES_VISITED = `${PREFIX}/putIncomesVisited`;

const GET_ACCOUNT_CLASSES = `${PREFIX}/getClasses`;
const PUT_ACCOUNT_CLASSES = `${PREFIX}/putClasses`;
const PUT_ACCOUNT_CLASSES_VISITED = `${PREFIX}/putClassesVisited`;

interface State {
  isFetching: boolean;
  accountInfo?: AccountInfo;
  error?: any;
  isCompanyAvailable: boolean;
  lastFetch: string;
}

const initialState: State = {
  isFetching: false,
  isCompanyAvailable: false,
  lastFetch: '',
};

export const getAccountSync = createAsyncThunk<AccountInfo>(
  GET_ACCOUNT_SYNC,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<AccountInfo>('/account/sync');
      updateFullStoryGlobals(data);

      return data;
    } catch (error: any) {
      const handledError = error.message;
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const getAccountInfo = createAsyncThunk<AccountInfo>(
  GET_ACCOUNT_INFO,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<AccountInfo>('/account/info');

      return data;
    } catch (error: any) {
      const handledError = error.message;
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const postAccountCompanySwitch = createAsyncThunk<{ token: string }, {
  companyId: unknown;
}>(
  POST_ACCOUNT_COMPANY_SWITCH,
  async ({ companyId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/account/company/${companyId}/switch`);

      return data;
    } catch (error: any) {
      const handledError = error.message;
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const deleteAccountData = createAsyncThunk<null>(
  DELETE_ACCOUNT_DATA,
  async (_, { rejectWithValue, dispatch }) => {
    try {
      await axios.delete('/account');
      await dispatch(getAccountSync());

      return null;
    } catch (error: any) {
      const handledError = error.message;
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const getAccountJeNumber = createAsyncThunk<string>(
  GET_ACCOUNT_JE_NUMBER,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<string>('/account/je-number');
      return data;
    } catch (error: any) {
      const handledError = error.message;
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const getAccountBalances = createAsyncThunk<Array<Account>>(
  GET_ACCOUNT_BALANCES,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<Array<Account>>('/account/balances');

      return data;
    } catch (error: any) {
      const handledError = error.message;
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const putAccountBalances = createAsyncThunk<Account, {
  id: string;
  enable: boolean;
  scheduleType: LegacySchedulingMethod;
}>(
  PUT_ACCOUNT_BALANCES,
  async ({ id, ...params }, { rejectWithValue }) => {
    try {
      const { data } = await axios.put<Account>(`/account/balances/${id}`, params);

      return data;
    } catch (error: any) {
      const handledError = error.message;
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const getAccountIncomes = createAsyncThunk<Array<Account>>(
  GET_ACCOUNT_INCOMES,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<Array<Account>>('/account/incomes');

      return data;
    } catch (error: any) {
      const handledError = error.message;
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const putAccountIncomes = createAsyncThunk<Account, {
  id: string;
  enable: boolean;
}>(
  PUT_ACCOUNT_INCOMES,
  async ({ id, ...params }, { rejectWithValue }) => {
    try {
      const { data } = await axios.put<Account>(`/account/incomes/${id}`, params);

      return data;
    } catch (error: any) {
      const handledError = error.message;
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const getAccountClasses = createAsyncThunk<Array<AccountClass>>(
  GET_ACCOUNT_CLASSES,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<Array<AccountClass>>('/account/classes');

      return data;
    } catch (error: any) {
      const handledError = error.message;
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);
export const putAccountClasses = createAsyncThunk<AccountClass, {
  id: string;
  enable: boolean;
}>(
  PUT_ACCOUNT_CLASSES,
  async ({ id, ...params }, { rejectWithValue }) => {
    try {
      const { data } = await axios.put<AccountClass>(`/account/classes/${id}`, params);

      return data;
    } catch (error: any) {
      const handledError = error.message;
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const putAccountBalancesVisited = createAsyncThunk(
  PUT_ACCOUNT_BALANCES_VISITED,
  async () => {
    await axios.put('/account/actions/balances/visited');
  },
);

export const putAccountIncomesVisited = createAsyncThunk(
  PUT_ACCOUNT_INCOMES_VISITED,
  async () => {
    await axios.put('/account/actions/incomes/visited');
  },
);

export const putAccountClassesVisited = createAsyncThunk(
  PUT_ACCOUNT_CLASSES_VISITED,
  async () => {
    await axios.put('/account/actions/classes/visited');
  },
);

export const accountSlice = createSlice({
  initialState,
  name: PREFIX,
  reducers: {
    clearAccountInfo: (state) => {
      state.accountInfo = undefined;
      state.isCompanyAvailable = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteAccountData.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(deleteAccountData.fulfilled, (state) => {
      state.isFetching = false;
    });
    builder.addCase(deleteAccountData.rejected, (state) => {
      state.isFetching = false;
    });

    builder.addCase(getAccountSync.pending, (state) => {
      state.isFetching = true;
      state.error = undefined;
      state.isCompanyAvailable = true;
    });
    builder.addCase(getAccountSync.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.accountInfo = {
        ...payload,
        accountBalances: addDisplayName(payload.accountBalances || []),
        accountIncomes: addDisplayName(payload.accountIncomes || []),
        accountClasses: payload.accountClasses || [],
        vendors: payload.vendors || [],
        customers: payload.customers || [],
        products: payload.products || [],
      };
      state.lastFetch = payload.lastSync ? payload.lastSync : '';
    });
    builder.addCase(getAccountSync.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    });

    builder.addCase(getAccountInfo.pending, (state) => {
      state.isFetching = true;
      state.error = undefined;
      state.isCompanyAvailable = true;
    });
    builder.addCase(getAccountInfo.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.accountInfo = {
        ...payload,
        accountBalances: addDisplayName(payload.accountBalances || []),
        accountIncomes: addDisplayName(payload.accountIncomes || []),
        accountClasses: payload.accountClasses || [],
        vendors: payload.vendors || [],
        customers: payload.customers || [],
        products: payload.products || [],
      };
      state.lastFetch = new Date().toISOString();
    });
    builder.addCase(getAccountInfo.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    });

    builder.addCase(postAccountCompanySwitch.pending, (state) => {
      state.isFetching = true;
      state.error = undefined;
    });
    builder.addCase(postAccountCompanySwitch.fulfilled, (state) => {
      state.isFetching = false;
    });
    builder.addCase(postAccountCompanySwitch.rejected, (state) => {
      state.isFetching = false;
    });

    builder.addCase(getAccountBalances.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getAccountBalances.fulfilled, (state, { payload }) => {
      state.accountInfo = {
        ...state.accountInfo!,
        accountBalances: addDisplayName(payload || []),
      };
      state.isFetching = false;
    });
    builder.addCase(getAccountBalances.rejected, (state) => {
      state.isFetching = false;
    });

    builder.addCase(putAccountBalances.fulfilled, (state, { payload }) => {
      state.accountInfo = {
        ...state.accountInfo!,
        accountBalances: state.accountInfo!.accountBalances.map((balance) => {
          if (balance.id === payload.id) {
            return {
              ...balance,
              enable: payload.enable,
              scheduleType: payload.scheduleType,
            };
          }
          return { ...balance };
        }),
      };
    });

    builder.addCase(getAccountIncomes.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getAccountIncomes.fulfilled, (state, { payload }) => {
      state.accountInfo = {
        ...state.accountInfo!,
        accountIncomes: addDisplayName(payload || []),
      };
      state.isFetching = false;
    });
    builder.addCase(getAccountIncomes.rejected, (state) => {
      state.isFetching = false;
    });

    builder.addCase(putAccountIncomes.fulfilled, (state, { payload }) => {
      state.accountInfo = {
        ...state.accountInfo!,
        accountIncomes: state.accountInfo!.accountIncomes.map((income) => {
          if (income.id === payload.id) {
            return {
              ...income,
              enable: payload.enable,
            };
          }
          return { ...income };
        }),
      };
    });

    builder.addCase(getAccountClasses.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getAccountClasses.fulfilled, (state, { payload }) => {
      state.accountInfo = {
        ...state.accountInfo!,
        accountClasses: payload || [],
      };
      state.isFetching = false;
    });
    builder.addCase(getAccountClasses.rejected, (state) => {
      state.isFetching = false;
    });

    builder.addCase(putAccountClasses.fulfilled, (state, { payload }) => {
      state.accountInfo = {
        ...state.accountInfo!,
        accountClasses: state.accountInfo!.accountClasses.map((accountClass) => {
          if (accountClass.id === payload.id) {
            return {
              ...accountClass,
              enable: payload.enable,
            };
          }
          return { ...accountClass };
        }),
      };
    });
  },
});

export const {
  clearAccountInfo,
} = accountSlice.actions;

export const accountReducer = accountSlice.reducer;
