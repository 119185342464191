/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { request } from '../../util/request';
import { openSnackbar } from '../../components/Notifier';
import mapErrorMessage from '../../util/errorMessage';
import { NotifierType } from '../../interfaces/types';
import { Item } from '../../interfaces/items';

const PREFIX = 'items';
const GET_ALL_ITEMS = `${PREFIX}/getAllItems`;

interface State {
  isFetchingItems: boolean;
  items: Array<Item>;
}

const initialState: State = {
  isFetchingItems: false,
  items: [],
};

export const getAllItems = createAsyncThunk<Array<Item>>(
  GET_ALL_ITEMS,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request().get<Array<Item>>('/v2/items');

      return data;
    } catch (error) {
      const handledError = mapErrorMessage(error.response?.data || error.message);
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const itemsSlice = createSlice({
  initialState,
  name: PREFIX,
  reducers: {
    clearItems: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllItems.pending, (state) => {
      state.isFetchingItems = true;
    });
    builder.addCase(getAllItems.fulfilled, (state, { payload }) => {
      state.isFetchingItems = false;
      state.items = [...payload.items];
    });
    builder.addCase(getAllItems.rejected, (state) => {
      state.isFetchingItems = false;
    });
  },
});

export const {
  clearItems,
} = itemsSlice.actions;

export const itemsReducer = itemsSlice.reducer;
