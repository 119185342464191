import React, { useState } from 'react';
import TableBody from '@material-ui/core/TableBody';
import {
  Button,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  endOfMonth,
  isWithinInterval,
  parse,
  startOfMonth,
} from 'date-fns';
import AddIcon from '@material-ui/icons/Add';
import {
  AmortizationSource,
  BulkEditorType,
} from '../../interfaces/types';
import { getChildren } from './common';
import COLORS from '../../theme/colors';
import { DAY_SHORT_FORMAT } from '../../util/constants';
import PrepaidSchedulerTableHeader from './PrepaidSchedulerPrepaidExpenseTableHeader';
import AddSplitButtonRow from './AddSplitButtonRow';
import UnScheduledRow from './UnScheduledPrepaidExpenseRow';
import HistoricalEditableRow from './HistoricalEditableRow';
import PrepaidAssetRow from './PrepaidAssetRow';
import { useTableStyles } from './Subledger.styled';

interface Props {
  scheduleDate: string;
  selectedRow: string;
  subledgerId: string;
  amortizationSources: AmortizationSource[];
  onSave: (asset: AmortizationSource) => () => Promise<void>;
  onRowSelect: (internalId: string) => () => void;
  isSaveEnabled: (asset: AmortizationSource) => boolean;
  onDateChange: (propertyName: string, internalId: string, value: Date | null) => void;
  onAutoCompleteChange: (propertyName: string, internalId: string) => (value: string) => void;
  onInputBoxChange: (propertyName: string, internalId: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  onInputBoxFocus: (propertyName: string, internalId: string) => (event: React.FocusEvent<HTMLInputElement>) => void;
  addSplit: (internalId: string) => () => void;
  addSplitAsset: (internalId: string) => () => void;
  removeAsset: (internalId: string) => () => void;
  onShowDetails: (internalId: string, sourceId: string) => void;
  onCheckboxChange: (internalId: string) => void;
  showBulkEdit: boolean;
  checkIsElementSelected: (internalId: string) => boolean;
  onSelectAll: (assetsType: BulkEditorType) => void;
  isAllSelected: (assetsType: BulkEditorType) => boolean;
  bulkEditorType: BulkEditorType;
  page: number;
  pageSize: number;
  tab: BulkEditorType;
}

const HistoricalSchedulerPrepaidExpenses = ({
  scheduleDate,
  selectedRow,
  subledgerId,
  amortizationSources,
  onSave,
  onRowSelect,
  isSaveEnabled,
  onDateChange,
  onAutoCompleteChange,
  onInputBoxChange,
  onInputBoxFocus,
  removeAsset,
  addSplitAsset,
  addSplit,
  onShowDetails,
  onCheckboxChange,
  showBulkEdit,
  checkIsElementSelected,
  onSelectAll,
  isAllSelected,
  bulkEditorType,
  page,
  pageSize,
  tab,
}: Props) => {
  const tableClasses = useTableStyles();
  const [collapses, setCollapses] = useState<Array<string>>([]);

  const toggleSplit = (internalId: string) => () => {
    const exists = collapses.includes(internalId);
    let updateCollapse: Array<string> = [];
    if (exists) {
      updateCollapse = collapses?.filter((item: string) => item !== internalId);
    } else {
      updateCollapse.push(internalId);
    }
    setCollapses(updateCollapse);
  };

  const renderReadOnlyRow = (amortizationSource: AmortizationSource) => {
    const children = getChildren(amortizationSource.internalId, amortizationSources);
    const parent = (
      <PrepaidAssetRow
        amortizationSource={amortizationSource}
        amortizationSources={amortizationSources}
        onChildExpand={toggleSplit}
        collapses={collapses}
        hasChildren={!!children?.length}
        disabled
        showBulkEdit={showBulkEdit}
        onShowDetails={onShowDetails}
      />
    );
    if (!children?.length || collapses.includes(amortizationSource.internalId)) {
      return parent;
    }
    return (
      <>
        {parent}
        {children?.map((child: AmortizationSource, childIndex: number) => (
          <PrepaidAssetRow
            disabled
            key={child.internalId}
            amortizationSource={child}
            amortizationSources={amortizationSources}
            onChildExpand={toggleSplit}
            collapses={collapses}
            hasChildren={false}
            lastChild={children?.length - 1 === childIndex}
            showBulkEdit={showBulkEdit}
            onShowDetails={onShowDetails}
          />
        ))}
      </>
    );
  };

  const renderEditableRow = (amortizationSource: AmortizationSource, type?: BulkEditorType) => {
    const children = getChildren(amortizationSource.internalId, amortizationSources);
    const isSelectableForBulkEdit = !bulkEditorType || type === bulkEditorType;
    const parent = (
      <HistoricalEditableRow
        selectedRow={selectedRow}
        childrenPrepaidAssets={children}
        addSplit={addSplit}
        amortizationSource={amortizationSource}
        collapses={collapses}
        hasChildren={!!children?.length}
        isSaveEnabled={isSaveEnabled}
        onDateChange={onDateChange}
        onInputBoxChange={onInputBoxChange}
        onInputBoxFocus={onInputBoxFocus}
        onSave={onSave}
        onAutoCompleteChange={onAutoCompleteChange}
        parentAsset={amortizationSource}
        removeAsset={removeAsset}
        onRowSelect={onRowSelect}
        key={amortizationSource.internalId}
        subledgerId={subledgerId}
        onShowDetails={onShowDetails}
        onCheckboxChange={onCheckboxChange}
        showBulkEdit={showBulkEdit}
        isSelectedForBulkEdit={checkIsElementSelected(amortizationSource.internalId)}
        isSelectable={isSelectableForBulkEdit}
      />
    );
    if (!children?.length || collapses.includes(amortizationSource.internalId)) {
      return parent;
    }
    return (
      <>
        {parent}
        {children?.map((child: AmortizationSource, index: number) => (
          <HistoricalEditableRow
            onRowSelect={onRowSelect}
            selectedRow={selectedRow}
            childrenPrepaidAssets={children}
            addSplit={addSplit}
            amortizationSource={child}
            collapses={collapses}
            hasChildren={false}
            isSaveEnabled={isSaveEnabled}
            onDateChange={onDateChange}
            onInputBoxChange={onInputBoxChange}
            onInputBoxFocus={onInputBoxFocus}
            onSave={onSave}
            onAutoCompleteChange={onAutoCompleteChange}
            parentAsset={amortizationSource}
            removeAsset={removeAsset}
            key={child.internalId}
            lastChild={index === children?.length - 1 && selectedRow !== amortizationSource?.internalId}
            subledgerId={subledgerId}
            onShowDetails={onShowDetails}
            showBulkEdit={showBulkEdit}
          />
        ))}
        {
          selectedRow === amortizationSource?.internalId && (
            <AddSplitButtonRow
              addSplitAsset={addSplitAsset(amortizationSource.internalId)}
            />
          )
        }
      </>
    );
  };

  const renderUnScheduledRow = (amortizationSource: AmortizationSource, type?: BulkEditorType) => {
    const children = getChildren(amortizationSource.internalId, amortizationSources);
    const isSelectableForBulkEdit = !bulkEditorType || type === bulkEditorType;
    const parent = (
      <UnScheduledRow
        selectedRow={selectedRow}
        childrenPrepaidAssets={children}
        addSplit={addSplit}
        amortizationSource={amortizationSource}
        hasChildren={!!children?.length}
        isSaveEnabled={isSaveEnabled}
        onDateChange={onDateChange}
        onInputBoxChange={onInputBoxChange}
        onInputBoxFocus={onInputBoxFocus}
        onSave={onSave}
        onAutoCompleteChange={onAutoCompleteChange}
        parentAsset={amortizationSource}
        removeAsset={removeAsset}
        onRowSelect={onRowSelect}
        key={amortizationSource.internalId}
        saveTitle="Schedule"
        subledgerId={subledgerId}
        onShowDetails={onShowDetails}
        onCheckboxChange={onCheckboxChange}
        showBulkEdit={showBulkEdit}
        isSelectedForBulkEdit={checkIsElementSelected(amortizationSource.internalId)}
        isSelectable={isSelectableForBulkEdit}
      />
    );
    if (!children?.length) {
      return parent;
    }
    return (
      <>
        {parent}
        {children?.map((child: AmortizationSource, index: number) => (
          <UnScheduledRow
            onRowSelect={onRowSelect}
            selectedRow={selectedRow}
            childrenPrepaidAssets={children}
            addSplit={addSplit}
            amortizationSource={child}
            hasChildren={false}
            isSaveEnabled={isSaveEnabled}
            onDateChange={onDateChange}
            onInputBoxChange={onInputBoxChange}
            onInputBoxFocus={onInputBoxFocus}
            onSave={onSave}
            onAutoCompleteChange={onAutoCompleteChange}
            parentAsset={amortizationSource}
            removeAsset={removeAsset}
            key={child.internalId}
            lastChild={index === children?.length - 1 && selectedRow !== amortizationSource?.internalId}
            saveTitle="schedule"
            subledgerId={subledgerId}
            onShowDetails={onShowDetails}
            onCheckboxChange={onCheckboxChange}
            showBulkEdit={showBulkEdit}
            isSelectedForBulkEdit={checkIsElementSelected(child.internalId)}
          />
        ))}
        {
          selectedRow === amortizationSource?.internalId && (
            <TableRow
              selected
              classes={{
                selected: tableClasses.selected,
              }}
            >
              <TableCell />
              <TableCell colSpan={100}>
                <Button
                  color="primary"
                  className={tableClasses.arrowIcon}
                  onClick={addSplitAsset(amortizationSource.internalId)}
                  startIcon={(
                    <AddIcon
                      fontSize="small"
                      className={tableClasses.fontSize12}
                      color="primary"
                    />
                  )}
                >
                  <Typography style={{ color: COLORS.skyBlue }}>Add</Typography>
                </Button>
              </TableCell>
            </TableRow>
          )
        }
      </>
    );
  };

  const renderAsset = () => (
    <>
      {tab === BulkEditorType.UNSCHEDULED && amortizationSources
        ?.filter((amortizationSource) => !amortizationSource.parentId)
        ?.slice(page * pageSize, page * pageSize + pageSize)
        ?.map((amortizationSource) => renderUnScheduledRow(amortizationSource, BulkEditorType.UNSCHEDULED))}

      {tab === BulkEditorType.SCHEDULED && amortizationSources
        ?.filter((amortizationSource) => !amortizationSource.parentId)
        ?.slice(page * pageSize, page * pageSize + pageSize)
        ?.map((amortizationSource: AmortizationSource) => {
          if (isWithinInterval(amortizationSource.sourceCreationDate!, {
            start: startOfMonth(parse(scheduleDate, DAY_SHORT_FORMAT, new Date())),
            end: endOfMonth(parse(scheduleDate, DAY_SHORT_FORMAT, new Date())),
          })) {
            return renderEditableRow(amortizationSource, BulkEditorType.SCHEDULED);
          }
          return renderReadOnlyRow(amortizationSource);
        })}
    </>
  );

  return (
    <>
      <PrepaidSchedulerTableHeader
        onSelectAll={() => onSelectAll(tab)}
        isAllSelected={isAllSelected(tab)}
        showBulkEdit={showBulkEdit}
        amortizationSourcesLength={amortizationSources.length}
      />
      <TableBody>
        {renderAsset()}
      </TableBody>
    </>
  );
};

export default HistoricalSchedulerPrepaidExpenses;
