import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  createStyles,
  TextField,
} from '@material-ui/core';
import {
  lastDayOfMonth,
  startOfMonth,
} from 'date-fns';
import COLORS from '../../theme/colors';
import FactaDatePicker from '../../components/DatePicker';
import { LegacyJournalEntry } from '../../interfaces/types';
import SubledgerAccountName from '../../components/Subledger/SubledgerAccountName';
import { fromUTC } from '../../util/timezone';
import {
  HeaderTop,
  HeaderLabel,
} from '../../components/common.styled';
import { BackButton } from '../../components/BackButton';

const useStyles = makeStyles(() => createStyles({
  datepicker: {
    background: COLORS.white,
    width: 140,
    maxWidth: 150,
    '& input': {
      background: COLORS.white,
      maxWidth: '90px',
    },
  },
  fullWidth: {
    width: '100%',
  },
  jeDescription: {
    background: COLORS.white,
    width: 400,
    margin: 0,
    '& p': {
      position: 'absolute',
      top: -25,
      right: 0,
    },
    '& fieldset': {
      border: 0,
    },
    '& .MuiInputBase-root': {
      padding: '4px 0',
      border: `1px solid ${COLORS.border}`,
      '& .MuiInputBase-input': {
        padding: '4px 12px',
      },
      '&.Mui-focused': {
        border: `1px solid ${COLORS.violet}`,
      },
    },
  },
  margin0: {
    margin: 0,
  },
  fontBold: {
    fontWeight: 'bold',
  },
  navigateBack: {
    textTransform: 'none',
    paddingBottom: 0,
    marginBottom: 0,
    paddingTop: 0,
    marginTop: 0,
    fontWeight: 'normal',
  },
}));
interface Props {
  journalEntry?: LegacyJournalEntry | null;
}
const JournalEntryViewHeader = ({ journalEntry }: Props) => {
  const classes = useStyles();
  const CHARACTER_LIMIT = 140;
  const [values, setValues] = useState(journalEntry?.description!);
  const [selectedDate, handleDateChange] = useState(fromUTC(journalEntry?.txnDate as string));

  const changeDate = (date: Date | ((prevState: Date) => Date) | null) => {
    // @ts-ignore
    handleDateChange(date);
  };

  return (
    <>
      <Box padding="32px 32px 10px">
        <BackButton label="Back to All Journal Entries" />
      </Box>
      <HeaderTop>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          padding="0 14px"
          height="70px"
          paddingTop="0px"
        >
          <SubledgerAccountName account={journalEntry?.subledger?.account!} />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            marginTop="10px"
            height="70px"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          marginLeft="14px"
          marginTop="10px"
          borderLeft="1px solid #CFD3DA"
          paddingLeft="19px"
          paddingRight="20px"
          width="auto"
          height="70px"
        >
          <HeaderLabel>
            JE Number:
          </HeaderLabel>
          <p>
            {journalEntry?.jeNumber}
          </p>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          marginTop="10px"
          borderLeft="1px solid #CFD3DA"
          paddingLeft="19px"
          marginLeft="14px"
          paddingRight="20px"
          width="auto"
          height="70px"
        >
          <HeaderLabel>
            Posting Date
          </HeaderLabel>
          <FactaDatePicker
            selectedDate={selectedDate}
            onDateChange={(date) => changeDate(date)}
            className={classes.datepicker}
            minDate={startOfMonth(new Date())}
            maxDate={lastDayOfMonth(new Date())}
            readOnly
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          marginLeft="14px"
          marginTop="10px"
          borderLeft="1px solid #CFD3DA"
          paddingLeft="19px"
          width="400px"
          height="70px"
        >
          <HeaderLabel>
            JE Description
          </HeaderLabel>
          <TextField
            inputProps={{
              maxLength: CHARACTER_LIMIT,
              readOnly: Boolean(true),
              disabled: Boolean(true),
            }}
            value={values}
            helperText={`${values.length}/${CHARACTER_LIMIT}`}
            margin="normal"
            variant="outlined"
            className={classes.jeDescription}
            onChange={(e) => setValues(e.target.value)}
          />
        </Box>
      </HeaderTop>
    </>
  );
};

export default JournalEntryViewHeader;
