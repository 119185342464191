/* eslint-disable react/jsx-props-no-spreading,max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ImportCSVIcon = (props: any) => (
  <SvgIcon
    fontSize="small"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.57143 0H5.5V0.785714H1.57143C1.36304 0.785714 1.16319 0.868495 1.01584 1.01584C0.868495 1.16319 0.785714 1.36304 0.785714 1.57143V9.42857C0.785714 9.63696 0.868495 9.8368 1.01584 9.98415C1.16319 10.1315 1.36304 10.2143 1.57143 10.2143H7.85714C8.06553 10.2143 8.26538 10.1315 8.41273 9.98415C8.56008 9.8368 8.64286 9.63696 8.64286 9.42857V3.92857H9.42857V9.42857C9.42857 9.84534 9.26301 10.245 8.96831 10.5397C8.67361 10.8344 8.27391 11 7.85714 11H1.57143C1.15466 11 0.754961 10.8344 0.460261 10.5397C0.165561 10.245 0 9.84534 0 9.42857V1.57143C0 1.15466 0.165561 0.754961 0.460261 0.460261C0.754961 0.165561 1.15466 0 1.57143 0V0Z"
      fill={props?.fill ?? '#252729'}
    />
    <path
      d="M5.5 2.75V0L9.42857 3.92857H6.67857C6.36599 3.92857 6.06622 3.8044 5.8452 3.58338C5.62417 3.36235 5.5 3.06258 5.5 2.75Z"
      fill={props?.fill ?? '#252729'}
    />
  </SvgIcon>
);

export default ImportCSVIcon;
