import React from 'react';
import {
  Controller,
  Control,
} from 'react-hook-form';
import { StyledInput } from '../index.styled';

interface Props {
  control: Control;
  defaultValue: string;
  name: string;
  error: boolean;
  disabled?: boolean;
  onBlur?: any;
  onChange?: any;
  multiline?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
}

export const FormInput = ({
  control,
  defaultValue,
  name,
  error,
  disabled,
  onBlur,
  onChange,
  multiline,
  autoFocus,
  placeholder,
}: Props) => (
  <Controller
    render={(props) => (
      <StyledInput
        {...props}
        onBlur={() => {
          props.onBlur();
          onBlur && onBlur();
        }}
        onChange={(e) => {
          props.onChange(e);
          onChange && onChange(e);
        }}
        disabled={disabled}
        multiline={multiline}
        rows={multiline ? 4 : undefined}
        error={error}
        autoFocus={autoFocus}
        placeholder={placeholder}
      />
    )}
    name={name}
    control={control}
    defaultValue={defaultValue}
  />
);
