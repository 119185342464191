import React, {
  useEffect,
  useState,
} from 'react';
import { Route } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  format,
  lastDayOfMonth,
} from 'date-fns';
import Scheduler from './Scheduler';
import { DAY_SHORT_FORMAT } from '../../util/constants';
import PrepareJE from './PrepareJE';
import PostJE from './PostJE';
import CreateSubledger from './CreateSubledger';
import { getAccountInfo } from '../../store/slices/account';
import { AppThunkDispatch } from '../../store/store';
import { Loader } from '../../components/Loader';

const HistoricalEditing = () => {
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const scheduleDate = useQuery().get('scheduleDate') ?? format(lastDayOfMonth(new Date()), DAY_SHORT_FORMAT);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    reduxDispatch(getAccountInfo())
      .finally(() => setIsInitialized(true));
  }, [reduxDispatch]);

  if (!isInitialized) {
    return <Loader open />;
  }

  return (
    <>
      <Route
        path="/historical/subledgers/scheduler/:id"
        exact
      >
        <CreateSubledger />
      </Route>
      <Route
        path="/historical/subledgers/schedule/:id"
        exact
      >
        <Scheduler
          scheduleDate={scheduleDate}
        />
      </Route>
      <Route
        path="/historical/subledgers/schedule/:id/prepare-je"
        exact
      >
        <PrepareJE
          scheduleDate={scheduleDate}
        />
      </Route>
      <Route
        path="/historical/subledgers/schedule/:id/post-je"
        exact
      >
        <PostJE
          scheduleDate={scheduleDate}
        />
      </Route>
    </>
  );
};

export default HistoricalEditing;
