export enum NotifierType {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Info = 'info',
}

export enum LegacySchedulingMethod {
  MONTHLY = 'MONTHLY',
  DAILY = 'DAILY',
  MANUAL = 'MANUAL',
}

export enum ReportType {
  Detail = 'DETAIL',
  Summary = 'SUMMARY',
}

export enum SourceType {
  ReceivePayment = 'Receive Payment',
  JournalEntry = 'Journal Entry',
  Bill = 'Bill',
  BillPayment = 'Bill Payment',
  BillPaymentCheck = 'Bill Payment (Check)',
  BillPaymentCreditCard = 'Bill Payment (Credit Card)',
  VendorCredit = 'Vendor Credit',
  Invoice = 'Invoice',
  CashExpense = 'Cash Expense',
  Cash = 'Cash',
  Check = 'Check',
  Expense = 'Expense',
  SalesReceipt = 'Sales Receipt',
  Refund = 'Refund',
  Deposit = 'Deposit',
  CreditCardPayment = 'Credit Card Payment',
  PurchaseOrder = 'Purchase Order',
  PayDownCreditCard = 'Pay down credit card',
  CreditCardCredit = 'Credit Card Credit',
  Payment = 'Payment',
  CreditMemo = 'Credit Memo',
}

export interface LegacyJournalEntry {
  id: string;
  quickbookId: string;
  status: string;
  subledgerId: string;
  txnDate: Date | string;
  updatedAt: Date | string;
  description: string;
  amount: number;
  jeNumber: string;
  userId: string;
  journalEntryLineItems?: Array<JournalEntryLineItem>;
  subledger?: Subledger;
  user: User;
  hasMismatch: boolean;
}

export interface OptionType {
  value: string;
  label: string;
}

export interface Customer {
  id: string;
  displayName: string;
}

export interface Product {
  id: string;
  name: string;
}

export interface AccountInfo {
  user: User;
  companyId: string;
  companyName: string;
  lastSync: string;
  bookCloseDate: string;
  classTrackingPerTxnLine: boolean;
  classTrackingPerTxn: boolean;
  accountIncomes: Array<Account>;
  accountClasses: Array<AccountClass>;
  accountBalances: Array<Account>;
  customers: Array<Customer>;
  products: Array<Product>;
  vendors: Array<Vendor>;
  companies: Array<Company>;
}

export interface SubledgerAmortizationLog {
  date: Date | null;
  scheduleDate: Date | null;
  openingBalance: number;
  closingBalance: number;
  postingDate?: string;
  jeNumber: string;
}

export enum ProductCategory {
  PrepaidExpense = 'PREPAID_EXPENSE',
  DeferredRevenue = 'DEFERRED_REVENUE',
  FixedAsset = 'FIXED_ASSET',
}

export enum AccountClassification {
  Asset = 'Asset',
  Equity = 'Equity',
  Liability = 'Liability',
  Expense = 'Expense',
  Revenue = 'Revenue',
}

export interface Subledger {
  id: string;
  glBalance: number;
  openingBalance: number;
  factaStartDate: string | Date | null;
  status?: string;
  accountId: string;
  account: Account;
  amortizationSources: Array<AmortizationSource>;
  subledgerAmortizationLogs: Array<SubledgerAmortizationLog>;
  factaDate: FactaDate;
  journalEntries: Array<LegacyJournalEntry>;
  productCategory: ProductCategory;
}

export interface JournalEntryLineItem {
  id: string;
  journalEntryId: string;
  accountId: string;
  postingType: string;
  amount: number;
  description: string;
  quickbookId: string;
  vendorId?: string;
  classId?: string;
  sourceId?: string;
  sourceType?: string;
  customerId?: string;
  productId?: string;
}

export interface Account {
  id: string;
  name: string;
  accountType: string;
  accountDetail: string;
  active: boolean;
  displayName?: string;
  glBalance: number;
  companyId: string;
  visited: string;
  enable: boolean;
  accNum?: string;
  scheduleType: LegacySchedulingMethod;
  subledger: Subledger;
  classification: AccountClassification;
}

export interface Vendor {
  id: string;
  displayName: string;
}

export interface AccountClass {
  id: string;
  quickbookId: string;
  className: string;
  active: boolean;
  enable: boolean;
  new: boolean;
  visited?: any;
}

export interface CompanyUser {
  id: string;
  user: User;
  status: string;
  createdAt: string;
  updatedAt: string;
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  officePhone: string;
  mobilePhone: string;
}

export interface AmortizationScheduleDetails {
  id?: string;
  amortizationScheduleId: string;
  scheduleDate: Date;
  status?: string;
  amount: number | string;
  journalEntry?: {
    id?: string;
    jeNumber?: string;
  };
  error?: boolean;
}

export interface FactaDate {
  startDate: Date | null;
  endDate: Date | null;
}

export interface AmortizationSchedule {
  destinationId?: string;
  classId?: string;
  amortizationStartDate: Date | null;
  amortizationEndDate: Date | null;
  amortizationScheduleType: string;
  amortizationScheduleDetails?: Array<AmortizationScheduleDetails> | null;
}

export interface AmortizationSource {
  id: string;
  internalId: string;
  sourceId: string;
  sourceType: SourceType;
  description: string;
  vendorId: string;
  customerId: string;
  productId: string;
  startingBalance: number;
  amortizationToDateBalance: number;
  parentId: string;
  status: string;
  sourceCreationDate: Date | null;
  parent: AmortizationSource;
  children?: Array<AmortizationSource>;
  amortizationSchedule: AmortizationSchedule;
  origin?: string;
}

export enum ErrorPageType {
  NotFound = '404',
  Error = 'error',
}

export enum SubledgerColumns {
  DESCRIPTION = 'DESCRIPTION',
  VENDOR = 'VENDOR',
  CUSTOMER = 'CUSTOMER',
  PRODUCT = 'PRODUCT/SERVICE',
  EXPENSES_ACCOUNT = 'EXPENSE ACCOUNT',
  REVENUE_ACCOUNT = 'REVENUE ACCOUNT',
  CLASS = 'CLASS',
  FACTA_STARTING_BALANCE = 'FACTA STARTING BALANCE',
  AMORTIZATION_START_DATE = 'AMORTIZATION START DATE',
  RECOGNITION_START_DATE = 'RECOGNITION START DATE',
  RECOGNITION_END_DATE = 'RECOGNITION END DATE',
  AMORTIZATION_END_DATE = 'AMORTIZATION END DATE',
  AMORT_SCHEDULE = 'AMORT SCHEDULE',
  RECOG_SCHEDULE = 'RECOG SCHEDULE',
  JE_DATE = 'JE DATE',
  GL_JE_ID = 'GL JE ID',
  AMOUNT = 'AMOUNT',
  EXPENSE_TO_DATE = 'EXPENSE TO DATE',
  REMAINING_BALANCE = 'REMAINING BALANCE',
}

export type CSVImportObject = {
  [key in SubledgerColumns]: string;
};

export interface JournalEntryFilterDates {
  startDate: Date;
  endDate: Date;
}

export interface Company {
  id: string;
  companyName: string;
  legalName: string;
}

export interface QuickBookError {
  message: string;
  detail: string;
  code: string;
}

export enum ITableItemType {
  TEXT = 'text',
  DATE = 'date',
  NUMMARY = 'nummary',
  ACTION = 'action',
  BOOLEAN = 'boolean',
  GL_BALANCE = 'GLBalance',
  USEREMAIL = 'userMail',
  USERNAME = 'userName',
  SORTKEY = 'sortkey',
  SORTKEY_NUMMARY = 'sortKeyNummary',
}

export enum ITableType {
  accounts = 'accounts',
  settings = 'settings',
  assetTypes = 'assetTypes',
  fixedAssetsAmortizationSources = 'fixedAssetsAmortizationSources', // TODO Temporary name
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export interface CategoryLabel {
  readonly scheduled: string;
  readonly scheduleButton: string;
  readonly unposted: string;
  readonly postButton: string;
  readonly posted: string;
  readonly accountLabelText: string;
}

export enum FilterMatchingType {
  STRING_INCLUDES = 'StringIncludes',
  ARRAY_INCLUDES_PROPERTY = 'ArrayIncludesProperty',
  NUMBER_COMPARE = 'NumberCompare',
  DATE_RANGE = 'DateRange',
  LIFE_COMPARE = 'LifeCompare',
  STRING_INCLUDES_PROPERTY = 'StringIncludesProperty',
}

export type FilterValue = string | Array<any> | AmountFilterValue | DateRangeFilterValue | undefined;

export interface Filter {
  name: string;
  sourcePath?: Array<string>;
  value?: FilterValue;
  matching: FilterMatchingType;
  propertyPath?: Array<string>;
}

export interface AmountFilterValue {
  compareMethod?: CompareMethod;
  value1?: number;
  value2?: number;
  multiplicator?: number;
}

export interface DateRangeFilterValue {
  startDate?: Date;
  endDate?: Date;
}

export enum CompareMethod {
  GT = 'gt',
  GTE = 'gte',
  LT = 'lt',
  LTE = 'lte',
  EQ = 'eq',
  NEQ = 'neq',
  IN = 'in',
  NIN = 'nin',
}
export interface DepreciationScheduleV2 {
  index: number;
  date: string;
  amount: number;
}

export interface OutOfPeriodData {
  outOfPeriod: boolean;
  outOfPeriodDate: Date | null;
  outOfPeriodMessage: string | null;
  outOfPeriodTransactions: number;
  outOfPeriodNavLink?: string;
}

export enum BulkEditorType {
  UNSCHEDULED = 'unscheduled',
  SCHEDULED = 'scheduled',
  NONE = '',
}

export enum TagType {
  New = 'New',
  ComingSoon = 'Coming soon',
}
