import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const noticesState = (state: RootState) => state.notices;

export const allNoticesSelector = createSelector(
  noticesState,
  (notices) => notices.messages,
);

export const lastMessageSelector = createSelector(
  noticesState,
  (notices) => notices.lastMessage,
);

export const lastDismissedErrorSelector = createSelector(
  noticesState,
  (notices) => notices.lastDismissedError,
);
