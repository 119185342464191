import React from 'react';
import { useSelector } from 'react-redux';
import {
  Link,
  TableCell,
  TableRow,
} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';

import clsx from 'clsx';
import {
  getAccountNameFromList,
  getClassName,
  getCustomerName,
  getProductName,
} from './common';
import { accountInfoSelector } from '../../store/selectors/account';
import currencyFormatter from '../../util/currencyFormatter';
import { useTableStyles } from './Subledger.styled';
import getQuickbookLink from '../../util/getQuickbookLink';
import PostJERevSyncTableHeader from './PostJERevSyncTableHeader';
import JournalEntryViewTableFooter from './JournalEntryViewTableFooter';
import { ProductCategory } from '../../interfaces/types';

interface Props {
  DRAmount: number;
  CRAmount: number;
  data: any;
}

const JournalEntryViewPrepaidRevenueTable = ({ data, DRAmount, CRAmount }: Props) => {
  const account = useSelector(accountInfoSelector)!;
  const { products, accountIncomes, accountClasses, customers } = account;
  const tableClasses = useTableStyles();

  const renderRow = (journalEntryLineItem: any, index: number) => {
    const quickbookLink = getQuickbookLink(journalEntryLineItem?.sourceId, journalEntryLineItem?.sourceType);
    return (
      <TableRow hover>
        <TableCell className={tableClasses.assetCell}>
          { index + 1 }
        </TableCell>
        <TableCell className={tableClasses.assetCell}>
          { (!journalEntryLineItem?.sourceType || !quickbookLink) && journalEntryLineItem?.sourceId }
          { quickbookLink && (
            <Link
              href={quickbookLink}
              target="_blank"
            >
              {journalEntryLineItem?.sourceId}
            </Link>
          ) }
        </TableCell>
        <TableCell className={tableClasses.descriptionCell}>
          { journalEntryLineItem?.description }
        </TableCell>
        <TableCell className={tableClasses.customerCell}>
          { getCustomerName(journalEntryLineItem?.customerId, customers) }
        </TableCell>
        <TableCell className={tableClasses.customerCell}>
          { getProductName(journalEntryLineItem?.productId, products) }
        </TableCell>
        <TableCell className={tableClasses.expenseAccountCell}>
          { getAccountNameFromList(journalEntryLineItem?.accountId, accountIncomes) }
        </TableCell>
        {
          (account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) && (
            <TableCell className={tableClasses.classesCell}>
              { getClassName(journalEntryLineItem?.classId, accountClasses) }
            </TableCell>
          )
        }
        <TableCell className={tableClasses.prepaidAssetAmount}>
          {
            journalEntryLineItem?.postingType === 'Debit'
              ? currencyFormatter.format(Math.abs(Number(journalEntryLineItem?.amount)))
              : '-'
          }
        </TableCell>
        <TableCell className={clsx(tableClasses.prepaidAssetAmount, tableClasses.lastCell)}>
          {
            journalEntryLineItem?.postingType !== 'Debit'
              ? currencyFormatter.format(Math.abs(Number(journalEntryLineItem?.amount)))
              : '-'
          }
        </TableCell>
      </TableRow>
    );
  };

  const renderAsset = () => (
    <>
      {
          data?.journalEntryLineItems
            .map((JournalEntryLineItem: any, index: number) => renderRow(JournalEntryLineItem, index))
      }
    </>
  );

  return (
    <>
      <PostJERevSyncTableHeader />
      <TableBody>
        { renderAsset() }
      </TableBody>
      <JournalEntryViewTableFooter
        DRAmount={DRAmount}
        CRAmount={CRAmount}
        productCategory={ProductCategory.DeferredRevenue}
      />
    </>
  );
};

export default JournalEntryViewPrepaidRevenueTable;
