import {
  Box,
  Link,
} from '@material-ui/core';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import Alert from '@material-ui/lab/Alert/Alert';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Subledger } from '../../../interfaces/types';
import COLORS from '../../../theme/colors';
import { useRefreshDataProvider } from '../../../context/dataRefreshContext';
import { deleteJournalEntry } from '../../../store/slices/dashboard';
import { AppThunkDispatch } from '../../../store/store';
import { isDeletingJournalEntry } from '../../../store/selectors/dashboard';
import { Loader } from '../../Loader';

interface Props {
  subledgers: Array<Subledger>;
}

const useStyles = makeStyles({
  root: {
    padding: '16px',
    lineHeight: '32px',
    '& span': {
      marginRight: 8,
      color: COLORS.deepGray,
    },
    '& input': {
      paddingLeft: 12,
      background: COLORS.white,
      color: COLORS.medGray,
    },
  },
  alert: {
    width: '100%',
    backgroundColor: COLORS.violet15,
    color: COLORS.medGray,
  },
  message: {
    width: '100%',
  },
  diagnosisButton: {
    paddingLeft: '4px',
    marginTop: '15px',
    color: COLORS.violet,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
});

const JEMismatchAlert = ({ subledgers }: Props) => {
  const classes = useStyles();
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const loading = useSelector(isDeletingJournalEntry);
  const { setRefreshDate } = useRefreshDataProvider();

  if (!subledgers?.length) {
    return null;
  }

  const getMismatchJE = () => {
    for (const subledger of subledgers) {
      if (subledger?.journalEntries?.length) {
        for (const journalEntry of subledger?.journalEntries) {
          if (journalEntry?.hasMismatch) {
            return { journalEntry, subledger };
          }
        }
      }
    }
    return { journalEntry: null, subledger: null };
  };

  const { journalEntry, subledger } = getMismatchJE();

  const resetAmortization = async () => {
    if (!subledger || !journalEntry) {
      return;
    }
    await reduxDispatch(deleteJournalEntry({
      subledger,
      journalEntryId: journalEntry.id,
    }))
      .then(() => {
        setRefreshDate(new Date());
      });
  };

  return (
    <>
      <Loader open={loading} />
      {journalEntry && (
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          flexWrap="wrap"
          className={classes.root}
        >
          <Alert
            severity="error"
            icon={false}
            className={classes.alert}
            classes={{
              message: classes.message,
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              flexGrow={1}
            >
              <Box>
                <strong>
                  Attention:
                </strong>
                {` QuickBooks JE ${journalEntry.jeNumber} no longer matches the Facta record. `}
                <Link
                  className={classes.diagnosisButton}
                  onClick={resetAmortization}
                >
                  Reset Now
                </Link>
              </Box>
              <Box>
                <Link
                  className={classes.diagnosisButton}
                  target="_blank"
                  href="https://help.facta.io"
                >
                  Why I am seeing this?
                </Link>
              </Box>
            </Box>
          </Alert>
        </Box>
      )}
    </>
  );
};

export default React.memo(JEMismatchAlert);
