import { CompareMethod } from '../interfaces/types';

export const compareMethods = [
  {
    name: 'Greater than',
    value: CompareMethod.GT,
  },
  {
    name: 'Greater than or equals',
    value: CompareMethod.GTE,
  },
  {
    name: 'Less than',
    value: CompareMethod.LT,
  },
  {
    name: 'Less than or equals',
    value: CompareMethod.LTE,
  },
  {
    name: 'Is equal to',
    value: CompareMethod.EQ,
  },
  {
    name: 'Is not equal to',
    value: CompareMethod.NEQ,
  },
  {
    name: 'Is between',
    value: CompareMethod.IN,
  },
  {
    name: 'Is not between',
    value: CompareMethod.NIN,
  },
];
