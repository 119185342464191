import React from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  Button,
  createStyles,
  Tooltip,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import COLORS from '../../../theme/colors';
import {
  ProductCategory,
  Subledger,
} from '../../../interfaces/types';
import isEmpty from '../../../util/isEmpty';
import SubledgerMonthIndicator from '../../../components/Subledger/SubledgerMonthIndicator';
import { HeaderTop } from '../../../components/common.styled';
import SubledgerAccountName from '../../../components/Subledger/SubledgerAccountName';
import {
  Breadcrumbs,
  BreadcrumbsItem,
} from '../../../components/BreadcrumbsStepper';

const useStyles = makeStyles(() => createStyles({
  navigation: {
    paddingLeft: 38,
    paddingTop: 30,
  },
}));

interface Props {
  subledger?: Subledger | null;
  scheduleDate: string;
  postJEEnabled: boolean;
  onPostJE: () => void;
  navigateToSchedulePrepaid: () => void;
  onCancel: () => void;
}

const ScheduleHeader = ({
  subledger,
  scheduleDate,
  postJEEnabled,
  onPostJE,
  onCancel,
  navigateToSchedulePrepaid,
}: Props) => {
  const classes = useStyles();

  if (!subledger || isEmpty(subledger)) {
    return null;
  }

  return (
    <>
      <SubledgerMonthIndicator
        title="Previous Period:"
        scheduleDate={scheduleDate}
        color={COLORS.violet}
      />
      <Box className={classes.navigation}>
        <Breadcrumbs>
          <BreadcrumbsItem
            step={1}
            checked
            violet
            action={navigateToSchedulePrepaid}
          >
            Schedule
            {' '}
            {subledger?.productCategory === ProductCategory.DeferredRevenue ? 'Revenue' : 'Prepaid'}
          </BreadcrumbsItem>
          <BreadcrumbsItem
            step={2}
            active
            violet
          >
            Review schedule
          </BreadcrumbsItem>
          <BreadcrumbsItem
            step={3}
            violet
            action={postJEEnabled ? onPostJE : undefined}
          >
            Prepare JE
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Box>
      <HeaderTop
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          padding="0 14px"
          height="70px"
        >
          <SubledgerAccountName account={subledger?.account} />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            marginTop="10px"
            height="70px"
          />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          marginLeft="auto"
        >
          <Button
            variant="outlined"
            color="primary"
            startIcon={<ChevronLeftIcon />}
            onClick={navigateToSchedulePrepaid}
          >
            {subledger?.productCategory === ProductCategory.DeferredRevenue ? 'SCHEDULE REVENUE' : 'SCHEDULE PREPAID' }
          </Button>
          {!postJEEnabled && (
            <Tooltip
              title="Please ensure all Manual Prepaids have a $0.00 or greater value."
              arrow
              placement="top"
            >
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<ChevronRightIcon />}
                  onClick={onPostJE}
                  disabled
                >
                  PREPARE JE
                </Button>
              </Box>
            </Tooltip>
          )}
          {postJEEnabled && (
            <Box>
              <Button
                variant="contained"
                color="primary"
                endIcon={<ChevronRightIcon />}
                onClick={onPostJE}
                disabled={!postJEEnabled}
              >
                PREPARE JE
              </Button>
            </Box>
          )}
          <Button
            style={{ color: COLORS.skyBlue }}
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Box>
      </HeaderTop>
    </>
  );
};

export default ScheduleHeader;
