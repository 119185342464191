import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const companySelector = (state: RootState) => state.company;

export const isDisconnectingSelector = createSelector(
  companySelector,
  (company) => company.isDisconnecting,
);

export const failedDisconnectionSelector = createSelector(
  companySelector,
  (company) => company.failedDisconnection,
);
