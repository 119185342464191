import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { TableCell } from '@material-ui/core';
import {
  getAccountClassById,
  getClassName,
} from '../common';
import { useTableStyles } from '../Subledger.styled';
import { AmortizationSource } from '../../../interfaces/types';
import { accountInfoSelector } from '../../../store/selectors/account';
import FactaAutocomplete from '../../FactaAutocomplete';

interface Props {
  hasChildren?: boolean;
  lastChild: boolean;
  asset: AmortizationSource;
  isRowSelected: boolean;
  onSelectChange: (propertyName: string, internalId: string) => (value: string) => void;
  isBoldEnable?: boolean;
}

const ClassEditableCell = ({
  hasChildren,
  lastChild,
  asset,
  isRowSelected,
  onSelectChange,
  isBoldEnable = false,
}: Props) => {
  const tableClasses = useTableStyles();
  const { accountClasses } = useSelector(accountInfoSelector)!;

  return (
    <TableCell
      className={clsx(tableClasses.cursorPointer, tableClasses.classesCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.bold]: isBoldEnable && !asset.status,
        [tableClasses.lastChild]: lastChild,
      })}
    >
      {hasChildren && 'Multiple'}
      {isRowSelected && !hasChildren && (
        <FactaAutocomplete
          className={clsx(tableClasses.classSelectBox)}
          defaultValue={asset?.amortizationSchedule?.classId!}
          onChange={onSelectChange('classId', asset.internalId)}
          options={accountClasses}
          optionName="className"
          selectedItem={
            getAccountClassById(asset?.amortizationSchedule?.classId!, accountClasses) || null
          }
        />
      )}
      {!isRowSelected && !hasChildren && getClassName(asset?.amortizationSchedule?.classId!, accountClasses)}
    </TableCell>
  );
};

export default React.memo(ClassEditableCell);
