import React, {
  useMemo,
  useState,
} from 'react';
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import {
  Box,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  format,
  formatISO,
  isBefore,
  isValid,
  startOfMonth,
  parseISO,
} from 'date-fns';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import find from 'lodash.find';
import ErrorIcon from '@material-ui/icons/Error';
import { yupResolver } from '@hookform/resolvers/yup';
import fileDownload from 'js-file-download';
import Papa from 'papaparse';
import { openSnackbar } from '../../../Notifier';
import {
  ITableItemType,
  ITableType,
  NotifierType,
  SortOrder,
} from '../../../../interfaces/types';
import getCSVImportDate from '../../../../util/csv';
import { vendorsListSelector } from '../../../../store/selectors/v2contacts';
import { assetTypesSelector } from '../../../../store/selectors/assetTypes';
import {
  ImportTab,
  SchedulingMethod,
} from '../../../../interfaces/common';
import { StyledTableCell } from '../../../Table/StyledTableCell';
import { FormInput } from '../../../Inputs/FormInput';
import {
  capitalizeFirstLetter,
  downloadTemplateFile,
  generateFixedAssetColumnNames,
  schedulingMethodOptions,
} from '../../../../util/helpers';
import {
  BasicFixedAssetForm,
  FixedAsset,
  FixedAssetsColumn,
  FixedAssetsImportObject,
} from '../../../../interfaces/fixedAssets';
import FactaAutocomplete from '../../../Inputs/FactaAutocomplete';
import { NumberInput } from '../../../Inputs/NumberInput';
import FactaDatePicker from '../../../DatePicker';
import {
  FULL_DATE_FORMAT,
  FULL_DAY_FORMAT,
} from '../../../../util/constants';
import TableComponent from '../../../Table';
import COLORS from '../../../../theme/colors';
import { AppThunkDispatch } from '../../../../store/store';
import { bulkCreateFixedAssets } from '../../../../store/slices/v2Subledgers';
import currencyFormatter from '../../../../util/currencyFormatter';
import { DeleteIcon } from '../../../Icons';
import { importWizardStyles } from '../index.styled';
import { UploadActions } from '../UploadActions';
import { IdleTableCell } from '../../../Table/IdleTableCell';
import { precisionRound } from '../../../../util/math';

interface Props {
  factaStartDate: string;
  subledgerId: string;
  onClose: (e?: React.MouseEvent) => void;
}

export const FixedAssetsImport = ({
  factaStartDate,
  subledgerId,
  onClose,
}: Props) => {
  const [importedData, setImportedData] = useState<Array<any>>([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState<string>('');
  const [tab, setTab] = useState(ImportTab.ALL);
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const classes = importWizardStyles();

  const vendors = useSelector(vendorsListSelector);
  const fixedAssetTypes = useSelector(assetTypesSelector);

  const columnNames = generateFixedAssetColumnNames();
  const isImportedDataValid = !importedData.find((item) => Object.keys(item.errors).length);

  const handleTemplateDownload = () => {
    downloadTemplateFile(columnNames, 'Facta_asset_template.csv');
  };

  const yupSchema = () => yupResolver(yup.object().shape({
    number: yup.string(),
    description: yup.string(),
    fixedAssetType: yup.object({
      name: yup.string().required(),
    }).required(),
    originalValue: yup.number().required(),
    priorDepreciation: yup.number()
      .max(yup.ref('originalValue')),
    vendorContact: yup.object({
      id: yup.string().required(),
    }).nullable(),
    schedulingMethod: yup.object({
      value: yup.string().required(),
    }).required(),
    inServiceDate: yup.date().nullable()
      .default(null)
      .notRequired()
      .min(parseISO(factaStartDate!))
      .typeError(),
    fullyDepreciatedDate: yup.date().nullable()
      .notRequired()
      .when('inServiceDate', {
        is: (val) => val,
        then: yup.date().min(yup.ref('inServiceDate'))
          .min(parseISO(factaStartDate!)),
      }),
  }));

  const validateAsset = (asset: any) => {
    const errors = {};
    const isInvalidStartDate = asset.inServiceDate
      && isBefore(asset.inServiceDate, startOfMonth(parseISO(factaStartDate)));
    const isInvalidEndDate = asset.fullyDepreciatedDate
      && (
        isBefore(asset.fullyDepreciatedDate, asset.inServiceDate)
        || isBefore(asset.inServiceDate, parseISO(factaStartDate))
      );

    if (!asset.originalValue) {
      Object.assign(errors, { originalValue: true });
    }

    if (asset.priorDepreciation > asset.originalValue) {
      Object.assign(errors, { priorDepreciation: true });
    }

    if (asset.schedulingMethod?.importValue || !asset.schedulingMethod) {
      Object.assign(errors, { schedulingMethod: true });
    }

    if (isInvalidStartDate) {
      Object.assign(errors, { inServiceDate: true });
    }

    if (asset.fullyDepreciatedDate && isInvalidEndDate) {
      Object.assign(errors, { fullyDepreciatedDate: true });
    }

    if (asset.vendorContact?.value) {
      Object.assign(errors, { vendorContact: true });
    }

    if (!asset.fixedAssetType || asset.fixedAssetType?.value) {
      Object.assign(errors, { fixedAssetType: true });
    }

    return { ...asset, errors };
  };

  const {
    control,
    errors,
    getValues,
    trigger,
    reset,
    setValue,
  } = useForm({
    mode: 'onChange',
    resolver: yupSchema(),
    shouldUnregister: false,
  });

  const handleRowSelect = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, id: string) => {
    event.stopPropagation();
    if (selectedAsset === id) {
      return;
    }

    if (selectedAsset && selectedAsset !== id) {
      const updatedData = importedData.map((asset) => {
        if (asset.id === selectedAsset) {
          return { id: selectedAsset, ...(validateAsset(getValues())) };
        }

        return asset;
      });
      setImportedData(updatedData);
    }

    setSelectedAsset(id);
    reset(importedData.find((asset) => asset.id === id));
    setTimeout(() => trigger(), 0);
  };

  const onFileUploaded = (data: Array<FixedAssetsImportObject>, fileInfo: any) => {
    if (!data.length) {
      openSnackbar({ message: 'There is no data in this CSV file, please select different one' }, NotifierType.Error);
      return;
    }

    if (Object.entries(data[0]).length !== columnNames.length) {
      openSnackbar(
        { message: 'Missing columns. Please be sure to use the provided CSV import template' },
        NotifierType.Error,
      );
      return;
    }

    if (JSON.stringify(Object.keys(data[0])) !== JSON.stringify(columnNames)) {
      openSnackbar(
        { message: 'Missing or incorrect column names. Please be sure to use the provided CSV import template' },
        NotifierType.Error,
      );
      return;
    }

    const depreciationSources = data?.map((item) => {
      const asset: any = {
        number: item[FixedAssetsColumn.NUMBER] ?? '',
        id: uuidv4(),
        fixedAssetType: null,
        description: item[FixedAssetsColumn.DESCRIPTION] ?? '',
        vendorContact: null,
        originalValue: 0,
        priorDepreciation: 0,
        schedulingMethod: null,
        inServiceDate: null,
        fullyDepreciatedDate: null,
        errors: {},
      };
      if (item[FixedAssetsColumn.ORIGINAL_AMOUNT]) {
        asset.originalValue = Number(item[FixedAssetsColumn.ORIGINAL_AMOUNT]
          .replace(/[a-z\s,$]/ig, ''));
      }
      if (item[FixedAssetsColumn.ACCUMULATED_DEPRECIATION]) {
        asset.priorDepreciation = Number(item[FixedAssetsColumn.ACCUMULATED_DEPRECIATION]
          .replace(/[a-z\s,$]/ig, ''));
      }
      if (item[FixedAssetsColumn.FACTA_DEPRECIATION_START_DATE]) {
        const inServiceDate = item[FixedAssetsColumn.FACTA_DEPRECIATION_START_DATE];
        asset.inServiceDate = getCSVImportDate(inServiceDate);
        if (!isValid(asset?.inServiceDate)) {
          asset.inServiceDate = null;
        }
      }
      if (item[FixedAssetsColumn.FACTA_DEPRECIATION_END_DATE]) {
        const fullyDepreciatedDate = item[FixedAssetsColumn.FACTA_DEPRECIATION_END_DATE];
        asset.fullyDepreciatedDate = getCSVImportDate(fullyDepreciatedDate);
        if (!isValid(asset?.fullyDepreciatedDate)) {
          asset.fullyDepreciatedDate = null;
        }
      }
      if (item[FixedAssetsColumn.SCHEDULE]) {
        const importedScheduleType = capitalizeFirstLetter(item[FixedAssetsColumn.SCHEDULE]);
        asset.schedulingMethod = find(
          schedulingMethodOptions,
          { label: importedScheduleType as SchedulingMethod },
        )
          || {
            importValue: importedScheduleType,
          };
        if (asset.schedulingMethod?.value === SchedulingMethod.MANUAL) {
          asset.fullyDepreciatedDate = null;
          asset.inServiceDate = null;
        }
      }
      if (item[FixedAssetsColumn.VENDOR]) {
        asset.vendorContact = find(vendors, { name: item[FixedAssetsColumn.VENDOR] }) || {
          value: item[FixedAssetsColumn.VENDOR],
        };
      }
      if (item[FixedAssetsColumn.ASSET_TYPE]) {
        asset.fixedAssetType = find(fixedAssetTypes, { name: item[FixedAssetsColumn.ASSET_TYPE] }) || {
          value: item[FixedAssetsColumn.ASSET_TYPE],
        };
      }
      return validateAsset(asset);
    });

    setUploadedFile(fileInfo);
    setImportedData(depreciationSources);

    if (depreciationSources.filter((asset) => Object.keys(asset.errors).length).length) {
      setTab(ImportTab.ERRORS);
    }
  };

  const onSubmit = () => {
    const fixedAssets: Array<Partial<FixedAsset>> = importedData.map((data: BasicFixedAssetForm) => ({
      number: data.number,
      description: data.description,
      vendorContactId: data.vendorContact?.id,
      fixedAssetTypeId: data.fixedAssetType?.id,
      originalValue: data.originalValue,
      priorDepreciation: data.priorDepreciation,
      inServiceDate: formatISO(data.inServiceDate
        ? data.inServiceDate
        : startOfMonth(parseISO(factaStartDate)), { representation: 'date' }),
      fullyDepreciatedDate: data.inServiceDate && precisionRound(data.originalValue - data.priorDepreciation) === 0
        ? formatISO(data.inServiceDate
          ? data.inServiceDate
          : startOfMonth(parseISO(factaStartDate)), { representation: 'date' })
        : data.fullyDepreciatedDate && formatISO(data.fullyDepreciatedDate, { representation: 'date' }),
      schedulingMethod: data.schedulingMethod?.value,
      depreciationAccountId: data.fixedAssetType?.depreciationAccountId,
      gainOrLossAccountId: data.fixedAssetType?.gainOrLossAccountId,
      tags: data.fixedAssetType?.tags,
    }));

    reduxDispatch(bulkCreateFixedAssets({ fixedAssets, subledgerId }))
      .unwrap()
      .then(() => onClose());
  };

  const fixedAssetsColumnInfo = [
    {
      columnName: FixedAssetsColumn.NUMBER,
      fieldName: 'number',
      messages: [],
    },
    {
      columnName: FixedAssetsColumn.DESCRIPTION,
      fieldName: 'description',
      messages: [],
    },
    {
      columnName: FixedAssetsColumn.VENDOR,
      fieldName: 'vendorContact',
      messages: ['Vendor does not exist. Please select a valid vendor'],
    },
    {
      columnName: FixedAssetsColumn.ASSET_TYPE,
      fieldName: 'fixedAssetType',
      messages: ['Invalid asset type name', 'This field is required'],
    },
    {
      columnName: FixedAssetsColumn.ORIGINAL_AMOUNT,
      fieldName: 'originalValue',
      messages: ['Please provide a valid number without formatting'],
    },
    {
      columnName: FixedAssetsColumn.ACCUMULATED_DEPRECIATION,
      fieldName: 'priorDepreciation',
      messages: [
        'Please provide a valid number without formatting',
        'Accumulated Depreciation should be less than or equal to Original Amount',
      ],
    },
    {
      columnName: FixedAssetsColumn.FACTA_DEPRECIATION_START_DATE,
      fieldName: 'inServiceDate',
      messages: [
        `Depreciation start date should be on or after ${format(parseISO(factaStartDate), FULL_DAY_FORMAT)}`,
        'Format: MM/DD/YYYY',
      ],
    },
    {
      columnName: FixedAssetsColumn.FACTA_DEPRECIATION_END_DATE,
      fieldName: 'fullyDepreciatedDate',
      messages: [
        'Depreciation end date should be on or after Depreciation Start Date',
        'Format: MM/DD/YYYY',
      ],
    },
    {
      columnName: FixedAssetsColumn.SCHEDULE,
      fieldName: 'schedulingMethod',
      messages: ['Schedule type does not exist. Please select a valid schedule type', 'This field is required'],
    },
  ];

  const checkColumnErrors = (colName: FixedAssetsColumn) => {
    const searchedItem = fixedAssetsColumnInfo.find((item) => item.columnName === colName);

    if (searchedItem) {
      return importedData.filter((asset: any) => Object
        .prototype.hasOwnProperty.call(asset.errors, searchedItem.fieldName)).length > 0;
    }

    return false;
  };

  const getColumnMessages = (colName: FixedAssetsColumn) => fixedAssetsColumnInfo
    .find((item) => item.columnName === colName)?.messages;

  const handleDeleteRow = (internalId: string, event: React.MouseEvent) => {
    event.stopPropagation();
    const updatedImportedData = importedData.filter((asset) => asset.id !== internalId);
    setImportedData(updatedImportedData);
  };

  const onScheduleTypeChange = () => {
    if (getValues('schedulingMethod')?.value === SchedulingMethod.MANUAL) {
      setValue('inServiceDate', null);
      setValue('fullyDepreciatedDate', null);
    }
    trigger();
  };

  const handleClickAway = () => {
    if (selectedAsset && !Object.keys(errors).length) {
      const updatedData = importedData.map((asset) => {
        if (asset.id === selectedAsset) {
          return { id: selectedAsset, ...getValues(), errors: {} };
        }

        return asset;
      });
      setImportedData(updatedData);
      setSelectedAsset('');
    }
  };

  const handleDownloadFixedData = () => {
    const fixedData = importedData.map((asset) => ({
      number: asset.number,
      description: asset.description,
      vendorContact: asset.vendorContact?.name,
      fixedAssetType: asset.fixedAssetType.name,
      originalValue: asset.originalValue,
      priorDepreciation: asset.priorDepreciation,
      inServiceDate: asset.inServiceDate && format(asset.inServiceDate, FULL_DATE_FORMAT),
      fullyDepreciatedDate: asset
        .fullyDepreciatedDate && format(asset.fullyDepreciatedDate, FULL_DATE_FORMAT),
      schedulingMethod: asset.schedulingMethod.label,
    }));

    const csvData = Papa.unparse({
      data: fixedData,
      fields: Object.keys(fixedData[0]),
    }, {
      header: false,
    });
    const downloadData = `${[...columnNames].join(',')}\r\n${csvData}`;
    fileDownload(downloadData, 'updatedData.csv');
  };

  const renderCustomHeader = (colName: FixedAssetsColumn) => (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexGrow={1}
    >
      <p>{colName}</p>
      {checkColumnErrors(colName) && (
        <Tooltip
          title={(
            <p className={classes.tooltipInfo}>
              {getColumnMessages(colName)?.map((message) => (
                <span key={message}>{message}</span>
              ))}
            </p>
          )}
          arrow
          placement="top"
        >
          <ErrorIcon
            style={{ color: COLORS.error, fontSize: '20px' }}
          />
        </Tooltip>
      )}
    </Box>
  );

  const colProps = [
    {
      colName: FixedAssetsColumn.NUMBER,
      customHeader: renderCustomHeader(FixedAssetsColumn.NUMBER),
      varKey: 'number',
      colType: ITableItemType.TEXT,
      child: (value: any) => (
        <StyledTableCell
          key="number"
          minWidth="160"
        >
          {selectedAsset === value.id
            ? (
              <FormInput
                control={control}
                defaultValue={value.number}
                name="number"
                error={!!errors.number}
                placeholder="Number"
              />
            )
            : (
              <IdleTableCell
                value={value.number}
                placeholderText="FA#"
                withError={!!value.errors.number}
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: FixedAssetsColumn.DESCRIPTION,
      customHeader: (renderCustomHeader(FixedAssetsColumn.DESCRIPTION)),
      varKey: 'description',
      colType: ITableItemType.TEXT,
      child: (value: any) => (
        <StyledTableCell
          key="description"
          minWidth="220"
        >
          {selectedAsset === value.id
            ? (
              <FormInput
                control={control}
                defaultValue={value.description}
                name="description"
                error={!!errors.description}
                placeholder="Asset Description"
              />
            )
            : (
              <IdleTableCell
                value={value.description}
                placeholderText="Description"
                withError={!!value.errors.description}
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: FixedAssetsColumn.VENDOR,
      customHeader: (renderCustomHeader(FixedAssetsColumn.VENDOR)),
      varKey: 'vendorContact',
      colType: ITableItemType.SORTKEY,
      sortKey: ['name'],
      child: (value: any) => (
        <StyledTableCell
          key="vendorContact"
          minWidth="200"
        >
          {selectedAsset === value.id
            ? (
              <FactaAutocomplete
                control={control}
                name="vendorContact"
                options={vendors}
                disableInactiveOptions
                optionName="name"
                defaultValue={value?.vendorContact}
                {...(value?.vendorContact?.value && { defaultInputValue: value.vendorContact.value })}
                error={!!errors?.vendorContact}
                placeholder="Asset Vendor"
              />
            )
            : (
              <IdleTableCell
                value={value?.vendorContact?.name || value?.vendorContact?.value || ''}
                placeholderText="Select Vendor"
                withError={!!value.errors.vendorContact}
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: FixedAssetsColumn.ASSET_TYPE,
      customHeader: (renderCustomHeader(FixedAssetsColumn.ASSET_TYPE)),
      varKey: 'fixedAssetType',
      colType: ITableItemType.SORTKEY,
      sortKey: ['name'],
      child: (value: any) => (
        <StyledTableCell
          key="fixedAssetType"
          minWidth="200"
        >
          {selectedAsset === value.id
            ? (
              <FactaAutocomplete
                control={control}
                options={fixedAssetTypes || []}
                disableInactiveOptions
                optionName="name"
                name="fixedAssetType"
                defaultValue={value.fixedAssetType}
                {...(value?.fixedAssetType?.value
                  && { defaultInputValue: value.fixedAssetType.value })
                }
                error={errors?.fixedAssetType}
                placeholder="Asset Type"
              />
            )
            : (
              <IdleTableCell
                value={value.fixedAssetType?.name || value?.fixedAssetType?.value || ''}
                placeholderText="Asset Type"
                withError={!!value.errors.fixedAssetType}
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: FixedAssetsColumn.ORIGINAL_AMOUNT,
      customHeader: (renderCustomHeader(FixedAssetsColumn.ORIGINAL_AMOUNT)),
      varKey: 'originalValue',
      colType: ITableItemType.NUMMARY,
      child: (value: any) => (
        <StyledTableCell
          key="originalValue"
          alignRight
          minWidth="180"
        >
          {selectedAsset === value.id
            ? (
              <NumberInput
                name="originalValue"
                defaultValue={value?.originalValue}
                control={control}
                error={!!errors?.originalValue}
              />
            )
            : (
              <IdleTableCell
                value={currencyFormatter.format(value.originalValue)}
                placeholderText="Original Value"
                withError={!!value.errors.originalValue}
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: FixedAssetsColumn.ACCUMULATED_DEPRECIATION,
      customHeader: (renderCustomHeader(FixedAssetsColumn.ACCUMULATED_DEPRECIATION)),
      varKey: 'priorDepreciation',
      colType: ITableItemType.NUMMARY,
      child: (value: any) => (
        <StyledTableCell
          key="priorDepreciation"
          alignRight
          minWidth="180"
        >
          {selectedAsset === value.id
            ? (
              <NumberInput
                name="priorDepreciation"
                defaultValue={value?.priorDepreciation}
                control={control}
                error={!!errors?.priorDepreciation}
              />
            )
            : (
              <IdleTableCell
                value={currencyFormatter.format(value.priorDepreciation)}
                placeholderText="Prior Depreciation"
                withError={!!value.errors.priorDepreciation}
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: FixedAssetsColumn.FACTA_DEPRECIATION_START_DATE,
      customHeader: (renderCustomHeader(FixedAssetsColumn.FACTA_DEPRECIATION_START_DATE)),
      varKey: 'inServiceDate',
      colType: ITableItemType.DATE,
      child: (value: any) => (
        <StyledTableCell
          key="inServiceDate"
          minWidth="200"
        >
          {selectedAsset === value.id
            ? (
              <Controller
                name="inServiceDate"
                control={control}
                defaultValue={value?.inServiceDate}
                render={({ onChange, value: localValue }) => (
                  <FactaDatePicker
                    selectedDate={localValue}
                    error={!!errors?.inServiceDate}
                    disabled={getValues('schedulingMethod')?.value === SchedulingMethod.MANUAL}
                    onDateChange={(date) => {
                      onChange(date);
                      trigger('fullyDepreciatedDate');
                    }}
                    disablePortal
                    isNullable
                  />
                )}
              />
            )
            : (
              <IdleTableCell
                value={(value.inServiceDate && format(value.inServiceDate, FULL_DATE_FORMAT)) || ''}
                placeholderText="mm/dd/yyyy"
                withError={!!value.errors.inServiceDate}
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: FixedAssetsColumn.FACTA_DEPRECIATION_END_DATE,
      customHeader: (renderCustomHeader(FixedAssetsColumn.FACTA_DEPRECIATION_END_DATE)),
      varKey: 'fullyDepreciatedDate',
      colType: ITableItemType.DATE,
      child: (value: any) => (
        <StyledTableCell
          key="fullyDepreciatedDate"
          minWidth="200"
        >
          {selectedAsset === value.id
            ? (
              <Controller
                name="fullyDepreciatedDate"
                control={control}
                defaultValue={value?.fullyDepreciatedDate}
                render={({ onChange, value: localValue }) => (
                  <FactaDatePicker
                    selectedDate={localValue}
                    error={!!errors?.fullyDepreciatedDate}
                    disabled={getValues('schedulingMethod')?.value === SchedulingMethod.MANUAL}
                    onDateChange={(date) => {
                      onChange(date);
                      trigger('inServiceDate');
                    }}
                    disablePortal
                    isNullable
                  />
                )}
              />
            )
            : (
              <IdleTableCell
                value={(value.fullyDepreciatedDate && format(value.fullyDepreciatedDate, FULL_DATE_FORMAT)) || ''}
                placeholderText="mm/dd/yyyy"
                withError={!!value.errors.fullyDepreciatedDate}
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: FixedAssetsColumn.SCHEDULE,
      customHeader: (renderCustomHeader(FixedAssetsColumn.SCHEDULE)),
      varKey: 'schedulingMethod',
      colType: ITableItemType.SORTKEY,
      sortKey: ['label'],
      child: (value: any) => (
        <StyledTableCell
          key="schedulingMethod"
          minWidth="150"
        >
          {selectedAsset === value.id
            ? (
              <FactaAutocomplete
                control={control}
                options={schedulingMethodOptions}
                optionName="label"
                name="schedulingMethod"
                defaultValue={value.schedulingMethod}
                error={errors?.schedulingMethod}
                onChange={onScheduleTypeChange}
                placeholder="Select Frequency"
              />
            )
            : (
              <IdleTableCell
                value={value.schedulingMethod?.label || value.schedulingMethod?.importValue || ''}
                placeholderText="Select Frequency"
                withError={!!value.errors.schedulingMethod}
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: '',
      varKey: 'delete',
      colType: ITableItemType.ACTION,
      child: (value: any) => (
        <StyledTableCell key="delete">
          <IconButton
            onClick={(e) => handleDeleteRow(value.id, e)}
          >
            <DeleteIcon />
          </IconButton>
        </StyledTableCell>
      ),
    },
  ];

  const defaultSort = {
    key: 'id',
    order: SortOrder.DESC,
  };

  const filteredData = useMemo(
    () => (tab === ImportTab.ERRORS && !isImportedDataValid
      ? importedData.filter((asset) => Object.keys(asset.errors).length)
      : importedData),
    [importedData, isImportedDataValid, tab],
  );

  const handleChange = (event: React.ChangeEvent<{}>, newValue: ImportTab) => {
    setTab(newValue);
  };

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      flexGrow={1}
      overflow="auto"
    >
      <UploadActions
        handleTemplateDownload={handleTemplateDownload}
        onFileUploaded={onFileUploaded}
        onSubmit={onSubmit}
        handleDownloadFixedData={handleDownloadFixedData}
        isImportedDataValid={isImportedDataValid}
        uploadedFile={uploadedFile}
      />
      <form
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        {importedData.length > 0 && (
          <>
            <Tabs
              value={tab}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              scrollButtons="off"
            >
              <Tab
                label={`All (${importedData.length})`}
                value={ImportTab.ALL}
                disableRipple
              />
              <Tab
                label={(
                  <Box display="flex">
                    <Typography className={classes.errorTab}>
                      With errors (
                      {importedData.filter((asset) => Object.keys(asset.errors).length)?.length || 0}
                      )
                    </Typography>
                    {!isImportedDataValid && <ErrorIcon style={{ color: COLORS.error, fontSize: '20px' }} />}
                  </Box>
                )}
                value={ImportTab.ERRORS}
                disableRipple
                disabled={isImportedDataValid}
              />
            </Tabs>
            <TableComponent
              colProps={colProps}
              tableData={filteredData}
              defaultSort={defaultSort}
              contentType={ITableType.fixedAssetsAmortizationSources}
              onRowClick={handleRowSelect}
              onClickOutsideSelectedRow={handleClickAway}
              selectedId={selectedAsset}
              topBarActions={[]}
            />
          </>
        )}
      </form>
    </Box>
  );
};
