/* eslint-disable */
import TableBody from '@material-ui/core/TableBody';
import React from 'react';
import PostJEPrepaidExpenseTableHeader from './PostJEPrepaidExpenseTableHeader';
import PostJEPrepaidExpenseRows from './PostJEPrepaidExpenseRows';
import PostJEPrepaidAccountRow from './PostJEPrepaidAccountRow';
import PostJETableFooter from './PostJETableFooter';
import { Account, AmortizationSource, ProductCategory } from '../../interfaces/types';
import NoRecords from "../Dashboard/NoRecords";

interface Props {
  amortizationSources: Array<AmortizationSource>;
  scheduleDate: string;
  rowsCount: number;
  account: Account;
  currentMonth: string;
  totalPrepaidAccount: number;
  total: number;
}

const PostJEPrepaidExpenseTable = ({
  amortizationSources,
  scheduleDate,
  rowsCount,
  account,
  currentMonth,
  totalPrepaidAccount,
  total,
}: Props) => {
  const isAccountClosed = totalPrepaidAccount === 0 || rowsCount === 0;
  return (
    <>
      <PostJEPrepaidExpenseTableHeader />
      {
        isAccountClosed ? <NoRecords closedAccount={isAccountClosed}/>
      : (
          <>
          <TableBody>
              <PostJEPrepaidExpenseRows
              amortizationSources={amortizationSources}
              scheduleDate={scheduleDate}
            />
              <PostJEPrepaidAccountRow
              rowsCount={rowsCount}
              account={account}
              currentMonth={currentMonth}
              totalPrepaidAccount={totalPrepaidAccount}
            />
            </TableBody>
          <PostJETableFooter
              total={total}
              productCategory={ProductCategory.PrepaidExpense}
            />
        </>
        )}
    </>
  );
};

export default React.memo(PostJEPrepaidExpenseTable);
