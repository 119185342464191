import Box from '@material-ui/core/Box';
import React from 'react';
import clsx from 'clsx';
import {
  CardLabel,
  CurrencyLabel,
} from '../Dashboard.styled';
import currencyFormatter from '../../../util/currencyFormatter';
import cardStyles from './cardStyles.styled';

interface Props {
  balance: number;
  error?: boolean;
}

const FactaBalance = ({
  balance,
  error,
}: Props) => {
  const classes = cardStyles();
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      fontSize="14px"
      paddingTop="8px"
      className={clsx(classes.factaBalance, {
        [classes.factaBalanceError]: error,
      })}
    >
      <CardLabel
        className={clsx(classes.factaBalance, {
          [classes.factaBalanceError]: error,
        })}
      >
        Facta
      </CardLabel>
      <CurrencyLabel
        className={clsx(classes.factaBalance, {
          [classes.factaBalanceError]: error,
        })}
      >
        {currencyFormatter.format(balance)}
      </CurrencyLabel>
    </Box>
  );
};

export default React.memo(FactaBalance);
