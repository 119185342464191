import React from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  createStyles,
  makeStyles,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
  getAccountNameFromList,
  getChildren,
  getClassName,
  getTotalBalanceAndAmortizationBalance,
} from './common';
import { AmortizationSource } from '../../interfaces/types';
import { useTableStyles } from './Subledger.styled';
import { accountInfoSelector } from '../../store/selectors/account';
import AmortizationSourceCreationCell from './TableCells/AmortizationSourceCreationCell';
import AmortizationSourceIdCell from './TableCells/AmortizationSourceIdCell';
import DescriptionReadOnlyCell from './TableCells/DescriptionReadOnlyCell';
import VendorCell from './TableCells/VendorCell';
import AmortizationStartDateCell from './TableCells/AmortizationStartDateCell';
import AmortizationEndDateCell from './TableCells/AmortizationEndDateCell';
import AmortizationScheduledTypeCell from './TableCells/AmoritizationScheduledTypeCell';
import TotalBalanceCell from './TableCells/TotalBalanceCell';
import ContextMenu from '../ContextMenu';
import { MoreHorizIcon } from '../Icons';

interface Props {
  amortizationSource: AmortizationSource;
  hasChildren?: boolean;
  lastChild?: boolean;
  amortizationSources: Array<AmortizationSource>;
  onChildExpand: (internalId: string) => () => void;
  collapses: Array<string>;
  disabled?: boolean;
  showBulkEdit?: boolean;
  onShowDetails: (internalId: string, sourceId: string) => void;
}

const useStyles = makeStyles(() => createStyles({
  cursorPointer: {
    cursor: 'pointer',
  },
}));

const PrepaidAssetRow = ({
  amortizationSource,
  amortizationSources,
  hasChildren,
  lastChild,
  onChildExpand,
  collapses,
  disabled,
  showBulkEdit,
  onShowDetails,
}: Props) => {
  const account = useSelector(accountInfoSelector)!;
  const { accountIncomes, accountClasses } = account;
  const tableClasses = useTableStyles();
  const sourceId = amortizationSource.parentId ? '' : amortizationSource.sourceId;
  const classes = useStyles();
  const children = getChildren(amortizationSource.internalId, amortizationSources);
  const { totalBalance } = hasChildren ? getTotalBalanceAndAmortizationBalance(children) : {
    totalBalance: 0,
  };
  return (
    <TableRow
      hover
      classes={{
        selected: tableClasses.selected,
      }}
      className={clsx({
        [tableClasses.disabledRow]: disabled,
      })}
    >
      {showBulkEdit && <TableCell />}
      <AmortizationSourceCreationCell
        amortizationSource={amortizationSource}
        hasChildren={hasChildren}
        lastChild={lastChild}
      />
      <AmortizationSourceIdCell
        amortizationSource={amortizationSource}
        hasChildren={hasChildren}
        lastChild={lastChild}
      />
      <DescriptionReadOnlyCell
        amortizationSource={amortizationSource}
        hasChildren={hasChildren}
        lastChild={lastChild}
      />
      <VendorCell
        amortizationSource={amortizationSource}
        hasChildren={hasChildren}
        lastChild={lastChild}
      />
      <TableCell
        className={clsx(classes.cursorPointer, tableClasses.expenseAccountCell, {
          [tableClasses.child]: amortizationSource.parentId,
          [tableClasses.parent]: hasChildren,
          [tableClasses.lastChild]: lastChild,
        })}
        onClick={onChildExpand(amortizationSource.parentId ?? amortizationSource.internalId)}
      >
        {hasChildren && (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Typography className={tableClasses.fontSize13}>Multiple</Typography>
            {
              collapses.includes(amortizationSource.internalId) && (
                <ArrowRightIcon />
              )
            }
            {
              !collapses.includes(amortizationSource.internalId) && (
                <ArrowDropDownIcon />
              )
            }
          </Box>
        )}
        {!hasChildren
          && getAccountNameFromList(amortizationSource?.amortizationSchedule?.destinationId!, accountIncomes)}
      </TableCell>
      {(account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) && (
        <TableCell
          className={clsx(tableClasses.classesCell, {
            [tableClasses.child]: amortizationSource.parentId,
            [tableClasses.parent]: hasChildren,
            [tableClasses.lastChild]: lastChild,
          })}
        >
          {hasChildren && 'Multiple'}
          {!hasChildren && getClassName(amortizationSource?.amortizationSchedule?.classId!, accountClasses)}
        </TableCell>
      )}
      <AmortizationStartDateCell
        amortizationSource={amortizationSource}
        hasChildren={hasChildren}
        lastChild={lastChild}
      />
      <AmortizationEndDateCell
        amortizationSource={amortizationSource}
        hasChildren={hasChildren}
        lastChild={lastChild}
      />
      <AmortizationScheduledTypeCell
        amortizationSource={amortizationSource}
        hasChildren={hasChildren}
        lastChild={lastChild}
      />
      <TotalBalanceCell
        amortizationSource={amortizationSource}
        hasChildren={hasChildren}
        lastChild={lastChild}
        totalBalance={totalBalance}
      />
      <TableCell
        className={clsx(tableClasses.actionCell, {
          [tableClasses.child]: amortizationSource.parentId,
          [tableClasses.parent]: hasChildren,
          [tableClasses.lastChild]: lastChild,
        })}
      >
        <ContextMenu
          menuItems={[
            {
              title: 'View amortization schedule',
              disabled: hasChildren || amortizationSource.startingBalance <= 0,
              hidden: !onShowDetails,
              action: () => onShowDetails(amortizationSource.internalId, sourceId),
            },
          ]}
        >
          <MoreHorizIcon className={tableClasses.fontSize13} />
        </ContextMenu>
      </TableCell>
    </TableRow>
  );
};

export default React.memo(PrepaidAssetRow);
