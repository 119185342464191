import {
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { accountInfoSelector } from '../../store/selectors/account';
import { useTableStyles } from './Subledger.styled';
import { AmortizationSource } from '../../interfaces/types';
import PrepareJETableHeaderAmortizationSchedules from './PrepareJETableHeaderAmortizationSchedules';

interface Props {
  startDate: Date;
  endDate: Date;
  scheduleDate: string;
  amortizationSources: Array<AmortizationSource>;
  isCollapsedView?: boolean;
  isCollapsedViewHovered?: boolean;
}

const PrepareJERevSyncTableHeader = ({
  startDate,
  endDate,
  scheduleDate,
  amortizationSources,
  isCollapsedView,
  isCollapsedViewHovered,
}: Props) => {
  const tableClasses = useTableStyles();
  const account = useSelector(accountInfoSelector)!;

  return (
    <TableHead className={tableClasses.tableHeader}>
      <TableRow
        hover={false}
      >
        <TableCell
          className={tableClasses.assetCell}
          is-cell-sticky="true"
        >
          JE Date
        </TableCell>
        <TableCell
          className={tableClasses.descriptionCell}
          is-cell-sticky="true"
        >
          Description
        </TableCell>
        {!isCollapsedView && (
          <>
            <TableCell
              className={clsx(tableClasses.customerCell, {
                [tableClasses.hover]: isCollapsedViewHovered,
              })}
              is-cell-sticky="true"
            >
              Customer
            </TableCell>
            <TableCell
              className={clsx(tableClasses.customerCell, {
                [tableClasses.hover]: isCollapsedViewHovered,
              })}
              is-cell-sticky="true"
            >
              Product/Service
            </TableCell>
            <TableCell
              className={clsx(tableClasses.expenseAccountCell, {
                [tableClasses.hover]: isCollapsedViewHovered,
              })}
              is-cell-sticky="true"
            >
              Revenue Account
            </TableCell>
            {
              (account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) && (
                <TableCell
                  className={clsx(tableClasses.classesCell, {
                    [tableClasses.hover]: isCollapsedViewHovered,
                  })}
                  is-cell-sticky="true"
                >
                  Class
                </TableCell>
              )
            }
          </>
        )}
        <TableCell
          className={clsx(tableClasses.datePickerCell)}
          is-cell-sticky="true"
        >
          Recognition Start Date
        </TableCell>
        <TableCell
          className={clsx(tableClasses.datePickerCell)}
          is-cell-sticky="true"
        >
          Recognition End Date
        </TableCell>
        <TableCell
          className={clsx(tableClasses.currencyCell)}
          is-cell-sticky="true"
        >
          Remaining Balance
        </TableCell>
        <PrepareJETableHeaderAmortizationSchedules
          scheduleDate={scheduleDate}
          amortizationSources={amortizationSources}
          endDate={endDate}
          startDate={startDate}
        />
      </TableRow>
    </TableHead>
  );
};

export default React.memo(PrepareJERevSyncTableHeader);
