import {
  makeStyles,
  createStyles,
} from '@material-ui/core';

export const commonBulkEditFormStyles = makeStyles(() => createStyles({
  wrapper: {
    display: 'flex',
    maxWidth: '1005px',
    width: '100%',
    overflow: 'auto',
    height: '100%',
    padding: '0 2px',
  },
  form: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 176px)',
    gridGap: '24px',
    width: '100%',
  },
}));
