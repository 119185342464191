import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import Box from '@material-ui/core/Box';
import Login from './Auth/Login';
import PrivateRoute from './PrivateRoute';
import {
  deleteToken,
  getToken,
  parseJwt,
  updateTokenFromCookie,
} from '../util/auth';
import ErrorPage from '../components/ErrorPage';
import ProtectedRoutes from './ProtectedRoutes';
import { AppThunkDispatch } from '../store/store';
import {
  accountInfoSelector,
  fetchingAccountError,
} from '../store/selectors/account';
import { getAccountSync } from '../store/slices/account';
import { setAxiosRequestDefaults } from '../util/request';
import { postV2Sync } from '../store/slices/v2Sync';
import { Loader } from '../components/Loader';
import { ErrorNotices } from '../components/ErrorNotices';
import DialogBox from '../components/DialogBox';
import { featureSwitch } from '../util/featureSwitch';

const AppRouter = () => {
  const [loadingAccountInfo, setLoadingAccountInfo] = useState(true);
  const [isSupportPromptVisible, setIsSupportPromptVisible] = useState(localStorage
    .getItem('supportPrompt') !== 'false');
  const token = updateTokenFromCookie() || getToken();
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const accountInfo = useSelector(accountInfoSelector);
  const error = useSelector(fetchingAccountError);

  setAxiosRequestDefaults(token);

  useEffect(() => {
    if (error) {
      deleteToken();
      window.location.href = '/login';
    }
  }, [error]);

  const checkAccountInfo = useCallback(async () => {
    if (!accountInfo && token) {
      const parseToken = parseJwt(token as string);
      if (parseToken?.company) {
        await reduxDispatch(getAccountSync())
          .finally(() => reduxDispatch(postV2Sync()));
      } else if (window.location.pathname !== '/settings/connect') {
        window.location.href = '/settings/connect';
      }
    }
    setLoadingAccountInfo(false);
  }, [accountInfo, reduxDispatch, token]);

  useEffect(() => {
    checkAccountInfo();
  }, [checkAccountInfo]);

  if (loadingAccountInfo) {
    return <Loader open />;
  }

  // TODO need to handle error pages
  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        width="100%"
        alignContent="center"
      >
        <Box width="70%">
          <ErrorPage />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      width="100%"
      height="100%"
    >
      <ErrorNotices />
      {featureSwitch.support && (
        <DialogBox
          openDialog={isSupportPromptVisible}
          closeDialog={() => {
            setIsSupportPromptVisible(false);
            localStorage.setItem('supportPrompt', 'false');
          }}
          dialogTitle="Attention"
          dialogContext="This app will no longer be supported.
          Please save any important data and migrate to an alternative solution as soon as possible.
          Please note that we will not be responding to any requests or messages related to the app.
          Thank you for your understanding."
          dismissContext="Dismiss"
        />
      )}
      <Router getUserConfirmation={() => {}}>
        <Switch>
          <Route
            path="/login"
            component={Login}
            exact
          />
          <PrivateRoute path="/">
            <ProtectedRoutes />
          </PrivateRoute>
        </Switch>
      </Router>
    </Box>
  );
};
export default AppRouter;
