import {
  format,
  parse,
} from 'date-fns';
import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { createStyles } from '@material-ui/core';
import {
  DAY_SHORT_FORMAT,
  MONTH_SHORT_FORMAT,
} from '../../util/constants';
import COLORS from '../../theme/colors';

interface Props {
  scheduleDate: string;
  title: string;
  color?: string;
}

const useStyles = makeStyles(() => createStyles({
  currentPeriod: {
    background: COLORS.violet,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    borderTopLeftRadius: 0,
    color: COLORS.white,
    padding: '3px 10px',
    position: 'absolute',
    left: '50%',
    '& p': {
      fontSize: 13,
      margin: 2,
      '& span': {
        fontWeight: 'bold',
        marginLeft: 5,
      },
    },
  },
}));

const SubledgerMonthIndicator = ({ title, scheduleDate, color = COLORS.deepGray }: Props) => {
  const classes = useStyles();
  return (
    <div
      className={classes.currentPeriod}
      style={{ background: color }}
    >
      <p>
        {title}
        <span>{format(parse(scheduleDate, DAY_SHORT_FORMAT, new Date()), MONTH_SHORT_FORMAT)}</span>
      </p>
    </div>
  );
};

export default React.memo(SubledgerMonthIndicator);
