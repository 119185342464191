import React from 'react';
import PostJE from './PostJE';
import Layout from '../../../components/Layout';

interface Props {
  scheduleDate: string;
}

const PostJEContainer = ({ scheduleDate }: Props) => (
  <Layout title="Journal Entry">
    <PostJE
      scheduleDate={scheduleDate}
    />
  </Layout>
);

export default PostJEContainer;
