import { styled } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

import COLORS from '../../../theme/colors';
import ListBox from './ListBox';

export const AutocompleteBox = styled(Autocomplete)({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'normal',
  fontSize: 12,
  lineHeight: 14,
  border: `1px solid ${COLORS.border}`,
  color: COLORS.deepGray,
  minWidth: 81,
  height: 32,
  margin: 0,
  padding: '6px 12px',
  borderRadius: 4,
  backgroundColor: COLORS.white,
  '&.Mui-error': {
    color: COLORS.error,
    border: `1px solid ${COLORS.error} !important`,
  },
  '&.Mui-focused': {
    border: `1px solid ${COLORS.violet}`,
  },
  '& input': {
    padding: '0 !important',
  },
});

export const AutoCompleteTextField = styled(TextField)({
  margin: 0,
  padding: 0,
});

export const AutocompleteListBox = styled(ListBox)({
  overflowY: 'hidden',
});
