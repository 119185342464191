import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import Layout from '../../components/Layout';
import { StandardReports } from './StandardReports';
import { NavigationTabs } from '../../components/NavigationTabs';
import { ReportPage } from './ReportPage';

export const Reports = () => {
  const { url } = useRouteMatch();

  const reportsRoutes = [
    {
      label: 'Standard Reports',
      path: `${url}/standard-reports`,
      component: <StandardReports />,
      isDisabled: false,
      exact: true,
    },
    {
      label: '',
      path: `${url}/standard-reports/:section?`,
      component: <ReportPage />,
      isDisabled: false,
      exact: false,
    },
  ];

  return (
    <Layout title="Reports">
      <NavigationTabs
        routes={reportsRoutes}
        baseRedirect={{ fromUrl: url, pathUrl: `${url}/standard-reports` }}
      />
    </Layout>
  );
};
