import Box from '@material-ui/core/Box';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  HeaderLabel,
  AccountName,
} from '../common.styled';
import { getAccountNameFromList } from './common';
import { Account } from '../../interfaces/types';
import { accountInfoSelector } from '../../store/selectors/account';

interface Props {
  account: Account;
}

const SubledgerAccountName = ({ account }: Props) => {
  const { accountBalances } = useSelector(accountInfoSelector)!;
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      width="100%"
      fontSize="14px"
    >
      <HeaderLabel>
        ACCOUNT
      </HeaderLabel>
      <AccountName>
        {getAccountNameFromList(account?.id, accountBalances)}
      </AccountName>
    </Box>
  );
};

export default React.memo(SubledgerAccountName);
