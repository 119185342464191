/* eslint-disable react/jsx-props-no-spreading */
import React, { cloneElement } from 'react';
import {
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { List } from 'react-virtualized';
import {
  CUSTOMER_CELL,
  VIRTUALIZED_AUTO_COMPLETE_ITEM_SIZE,
  VIRTUALIZED_AUTO_COMPLETE_VIEW_PORT_ITEM_COUNT,
} from '../../../../util/constants';

const useStyles = makeStyles(() => createStyles({
  list: {
    maxHeight: '40vh',
    height: 'auto !important',
  },
}));

const Listbox = React.forwardRef((
  props: any,
  ref: any,
) => {
  const {
    children,
    role,
    ...other
  } = props;
  const classes = useStyles();
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = VIRTUALIZED_AUTO_COMPLETE_ITEM_SIZE;

  const rowRenderer = (rowProps: any) => cloneElement(children[rowProps.index], {
    style: rowProps.style,
  });
  return (
    <div ref={ref}>
      <div {...other}>
        <List
          autoWidth
          autoContainerWidth
          className={classes.list}
          height={450}
          width={CUSTOMER_CELL}
          rowHeight={itemSize}
          overscanCount={VIRTUALIZED_AUTO_COMPLETE_VIEW_PORT_ITEM_COUNT}
          rowCount={itemCount}
          rowRenderer={rowRenderer}
          role={role}
        />
      </div>
    </div>
  );
});

export default React.memo(Listbox);
