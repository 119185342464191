import React from 'react';
import Scheduler from './Scheduler';
import Layout from '../../../components/Layout';
import { Account } from '../../../interfaces/types';

interface Props {
  scheduleDate: string;
  accountBalance: Account;
}

const ScheduleContainer = ({
  scheduleDate,
  accountBalance,
}: Props) => (

  <Layout title="Schedule">
    {accountBalance && (
      <Scheduler
        scheduleDate={scheduleDate}
        accountBalance={accountBalance}
      />
    )}
  </Layout>
);

export default ScheduleContainer;
