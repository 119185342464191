import React from 'react';
import {
  addMonths,
  differenceInCalendarMonths,
  format,
  lastDayOfMonth,
  startOfMonth,
} from 'date-fns';
import {
  Box,
  TableCell,
} from '@material-ui/core';
import clsx from 'clsx';
import { Subledger } from '../../interfaces/types';
import {
  getAssetSumForMonth,
  getStartEndDate,
} from './common';
import {
  DAY_SHORT_FORMAT,
  FULL_DATE_FORMAT,
} from '../../util/constants';
import COLORS from '../../theme/colors';
import currencyFormatter from '../../util/currencyFormatter';
import { useTableStyles } from './Subledger.styled';

interface Props {
  subledger: Subledger;
}

const CreateSubledgerAmortizationTableHeader = ({ subledger }: Props) => {
  const tableClasses = useTableStyles();
  const { startDate, endDate } = getStartEndDate(subledger);
  if (startDate && endDate) {
    const diffInMonths = differenceInCalendarMonths(lastDayOfMonth(endDate!), startOfMonth(startDate!)) + 1;
    if (diffInMonths > 0) {
      return (
        <>
          {
            Array.from(Array(diffInMonths).keys()).map((index: number) => {
              const dayOfMonth = lastDayOfMonth(addMonths(startDate!, index));
              const currentDate = format(dayOfMonth, DAY_SHORT_FORMAT);
              return (
                <TableCell
                  key={`month${index}`}
                  className={clsx(tableClasses.amortizationRowCell, {
                    [tableClasses.cellBackground]: index % 2 === 0,
                  })}
                  style={{ borderLeft: index === 0 ? `1px solid ${COLORS.lightGray2}` : 'none' }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="stretch"
                    width="100"
                    padding={0}
                    margin={0}
                  >
                    <Box
                      className={tableClasses.amortizationRowCellHeader}
                    >
                      {currencyFormatter.format(getAssetSumForMonth(currentDate, subledger?.amortizationSources))}
                    </Box>
                    <Box className={tableClasses.amortizationRowCellHeaderCurrency}>
                      {format(dayOfMonth, FULL_DATE_FORMAT)}
                    </Box>
                  </Box>
                </TableCell>
              );
            })
          }
        </>
      );
    }
  }
  return null;
};

export default React.memo(CreateSubledgerAmortizationTableHeader);
