import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const v2JournalEntriesState = (state: RootState) => state.v2JournalEntries;

export const isFetchingV2JournalEntries = createSelector(
  v2JournalEntriesState,
  (v2JournalEntries) => v2JournalEntries.isFetching,
);

export const isPostingV2JournalEntry = createSelector(
  v2JournalEntriesState,
  (v2JournalEntries) => v2JournalEntries.isPostingJE,
);

export const v2CandidateJEsSelector = createSelector(
  v2JournalEntriesState,
  (v2JournalEntries) => v2JournalEntries.canidateJournals,
);

export const v2JournalEntriesSelector = createSelector(
  v2JournalEntriesState,
  (v2JournalEntries) => v2JournalEntries.journals,
);
