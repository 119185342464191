import React, {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Popper,
  Paper,
  Box,
  ClickAwayListener,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { useAutocomplete } from '@material-ui/lab';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CheckIcon from '@material-ui/icons/Check';
import { POPPER_ELEMENTS_Z_INDEX } from '../../../util/constants';
import { StyledInput } from '../index.styled';
import { useStyles } from './index.styled';

interface Props {
  label: string;
  value?: Array<any>;
  options: Array<any>;
  optionName: string;
  onChange: (filter?: Array<string>) => void;
  name: string;
  control: any;
  defaultValue: any;
}

export const FactaMultipicker = ({
  label,
  value: selectedValues,
  options,
  optionName,
  onChange,
  name,
  control,
  defaultValue,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [innerValue, setInnerValue] = useState<Array<any> | undefined>(selectedValues || []);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [autocompleteOpen, setAutocompleteOpen] = React.useState(false);
  const classes = useStyles();

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
    setAnchorEl: setAutocompleteAnchorEl,
  } = useAutocomplete({
    id: 'facta-multipicker',
    value: innerValue,
    multiple: true,
    open: autocompleteOpen,
    options,
    disableCloseOnSelect: true,
    getOptionLabel: (option: any) => option[optionName],
    onChange: (_, value) => setInnerValue(value),
  });

  const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
    setOpen(!open);
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const applyValue = () => {
    onChange(innerValue?.length ? innerValue : []);
    setOpen(false);
    setAnchorEl(null);
  };

  const clearValue = () => {
    onChange([]);
    setInnerValue([]);
    setOpen(false);
    setAnchorEl(null);
  };

  const handleCancel = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.key === 'Escape') {
      handleCancel();
    }
  };

  useEffect(() => {
    setInnerValue(selectedValues || []);
  }, [selectedValues]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={(props) => (
        <>
          <Button
            type="button"
            onClick={handleOpen}
            variant="outlined"
            endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            className={classes.button}
          >
            {label}
            <span className={classes.selectedIndicator}>
              {selectedValues && selectedValues?.length > 0 && (
                <>
                  (
                  <span>{selectedValues.length}</span>
                  )
                </>
              )}
            </span>
          </Button>
          <Popper
            open={open}
            anchorEl={anchorEl}
            placement="bottom-start"
            style={{
              zIndex: POPPER_ELEMENTS_Z_INDEX,
              minWidth: '200px',
            }}
            transition
          >
            <ClickAwayListener onClickAway={handleCancel}>
              <Paper
                className={classes.paper}
                elevation={3}
              >
                <form
                  id="facta-multipicker-form"
                  noValidate
                  autoComplete="off"
                >
                  <div {...getRootProps()}>
                    <div
                      ref={setAutocompleteAnchorEl}
                      className={focused ? 'focused' : ''}
                    >
                      <StyledInput
                        {...getInputProps()}
                        className={classes.searchField}
                        fullWidth
                        autoFocus
                        onFocus={() => setAutocompleteOpen(true)}
                        onKeyDown={onKeyDown}
                        placeholder="Search"
                      />
                      <Box className={classes.selectBox}>
                        <Button
                          onClick={() => setInnerValue(options.length !== innerValue?.length ? options : [])}
                          size="large"
                          className={classes.buttonSelectAll}
                        >
                          {options.length !== innerValue?.length
                            ? 'Select all'
                            : 'Deselect all'}
                        </Button>
                      </Box>
                    </div>
                    <ul
                      {...getListboxProps()}
                      className={classes.listBox}
                    >
                      {groupedOptions.map((option, index) => (
                        <li
                          className={selectedValues?.includes(option) ? 'selected' : ''}
                          key={option[optionName]}
                          {...getOptionProps({ option, index })}
                        >
                          <span>{option[optionName]}</span>
                          <CheckIcon fontSize="small" />
                        </li>
                      ))}
                    </ul>
                  </div>
                  <Box className={classes.buttonBox}>
                    <Button
                      type="button"
                      variant="outlined"
                      onClick={() => {
                        props.onChange([]);
                        clearValue();
                      }}
                      size="small"
                    >
                      Clear
                    </Button>
                    <Button
                      type="button"
                      color="primary"
                      variant="contained"
                      size="small"
                      onClick={() => {
                        props.onChange(innerValue?.length ? innerValue : []);
                        applyValue();
                      }}
                      fullWidth
                      className={classes.applyButton}
                    >
                      Apply
                    </Button>
                  </Box>
                </form>
              </Paper>
            </ClickAwayListener>
          </Popper>
        </>
      )}
    />
  );
};
