/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { request } from '../../util/request';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../interfaces/types';
import {
  CandidateJE,
  JournalEntry,
} from '../../interfaces/journalEntries';
import { TagCategories } from '../../interfaces/tags';
import mapErrorMessage from '../../util/errorMessage';
import { handleAuthError } from '../../util/auth';

const PREFIX = 'v2JournalEntries';
const GET_V2_CANDIDATE_JOURNAL_ENTRIES = `${PREFIX}/getCandidateJournalEntries`;
const POST_V2_JOURNAL_ENTRY = `${PREFIX}/postJournalEntry`;
const GET_V2_JOURNAL_ENTRIES = `${PREFIX}/getHournalEntries`;

interface State {
  isFetching: boolean;
  isPostingJE: boolean;
  canidateJournals: CandidateJE;
  journals: Array<JournalEntry>;
}

const initialState: State = {
  isFetching: false,
  isPostingJE: false,
  canidateJournals: {} as CandidateJE,
  journals: [] as Array<JournalEntry>,
};

export const getV2CandidateJEs = createAsyncThunk<CandidateJE, {
  subledgerId: string;
  date: string;
}>(
  GET_V2_CANDIDATE_JOURNAL_ENTRIES,
  async ({ subledgerId, date }, { rejectWithValue }) => {
    try {
      const { data } = await request().get(`/v2/subledgers/${subledgerId}/candidate_journals/${date}`);

      return data;
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';

      if (handledError === 'invalidated authorization token') {
        handleAuthError();
      }

      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const postV2JournalEntry = createAsyncThunk<null, {
  subledgerId: string;
  date: string;
  number: string;
  description: string;
  contentHash: string;
}>(
  POST_V2_JOURNAL_ENTRY,
  async ({ subledgerId, date, ...rest }, { rejectWithValue }) => {
    try {
      const { data } = await request()
        .post(`/v2/subledgers/${subledgerId}/journals/${date}`, { ...rest });

      return data;
    } catch (error: any) {
      const handledError = error?.response?.data?.raw ? mapErrorMessage(error?.response?.data?.raw) : 'Unknown error';
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const getV2JournalEntries = createAsyncThunk<Array<JournalEntry>, {
  subledgerId: string;
}>(
  GET_V2_JOURNAL_ENTRIES,
  async ({ subledgerId }, { rejectWithValue }) => {
    try {
      const { data }: { data: { journals: Array<JournalEntry> } } = await request()
        .get(`/v2/subledgers/${subledgerId}/journals`);

      return data.journals;
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const v2JournalEntriesSlice = createSlice({
  initialState,
  name: PREFIX,
  reducers: {
    clearV2CandidateJournals: () => initialState,
    clearV2Journals: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getV2CandidateJEs.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getV2CandidateJEs.fulfilled, (state, { payload }) => {
      state.canidateJournals = {
        ...payload,
        lines: payload.lines.map((line, index) => ({
          ...line,
          jeLineIndex: index + 1,
          id: line.id ? line.id : uuidv4(),
          classTag: line.tags.find((tag) => tag.category === TagCategories.CLASS) || {},
          locationTag: line.tags.find((tag) => tag.category === TagCategories.LOCATION) || {},
          crAmount: line.amount < 0 ? line.amount : 0,
        })),
      };
      state.isFetching = false;
    });
    builder.addCase(getV2CandidateJEs.rejected, (state) => {
      state.isFetching = false;
    });

    builder.addCase(postV2JournalEntry.pending, (state) => {
      state.isPostingJE = true;
    });
    builder.addCase(postV2JournalEntry.fulfilled, (state) => {
      state.isPostingJE = false;
    });
    builder.addCase(postV2JournalEntry.rejected, (state) => {
      state.isPostingJE = false;
    });

    builder.addCase(getV2JournalEntries.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getV2JournalEntries.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.journals = payload.map((journal) => ({
        ...journal,
        lines: journal.lines.map((line) => ({
          ...line,
          id: line.id ? line.id : uuidv4(),
          classTag: line.tags.find((tag) => tag.category === TagCategories.CLASS) || {},
          locationTag: line.tags.find((tag) => tag.category === TagCategories.LOCATION) || {},
          crAmount: line.amount < 0 ? line.amount : 0,
        })),
      }));
    });
    builder.addCase(getV2JournalEntries.rejected, (state) => {
      state.isFetching = false;
    });
  },
});

export const {
  clearV2CandidateJournals,
  clearV2Journals,
} = v2JournalEntriesSlice.actions;

export const v2JournalEntriesReducer = v2JournalEntriesSlice.reducer;
