import { styled } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import COLORS from '../theme/colors';

export const WelcomeMessage = styled(Typography)({
  fontWeight: 'bold',
  marginBottom: 8,
});

export const StyledButton = styled(Button)({
  height: '50px',
  fontSize: '12px',
});

export const HeaderTop = styled(Box)({
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'stretch',
  padding: '25px 24px 10px',
  background: COLORS.white,
  width: '100%',
});

export const HeaderCurrencyDetails = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  borderLeft: `1px solid ${COLORS.lightGray2}`,
  paddingLeft: 17,
  paddingRight: 20,
  paddingBottom: 10,
  marginTop: 1,
  minWidth: 150,
  minHeight: 70,
  '&:first-of-type': {
    marginLeft: '14px',
  },
});

export const HeaderLabel = styled(Typography)({
  fontSize: 13,
  marginRight: 9,
  color: COLORS.medGray,
});

export const AccountName = styled('p')({
  fontWeight: 'bold',
});

export const CurrencyLabel = styled(Typography)({
  fontSize: 18,
  fontWeight: 500,
  color: COLORS.medGray,
});

export const SchedulerLabel = styled(Typography)({
  fontSize: 13,
  marginRight: 9,
  color: COLORS.medGray,
});

export const AccountStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0 14px',
  marginTop: 10,
});
