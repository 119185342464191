import clsx from 'clsx';
import { format } from 'date-fns';
import { TableCell } from '@material-ui/core';
import React from 'react';
import { FULL_DAY_FORMAT } from '../../../util/constants';
import { useTableStyles } from '../Subledger.styled';
import {
  AmortizationSource,
  LegacySchedulingMethod,
} from '../../../interfaces/types';
import FactaDatePicker from '../../DatePicker';

interface Props {
  amortizationSource: AmortizationSource;
  hasChildren?: boolean;
  lastChild?: boolean;
  highlighted?: boolean;
  showCursor?: boolean;
  isRowSelected?: boolean;
  hasError?: boolean;
  onDateChange?: (propertyName: string, internalId: string, value: Date | null) => void;
  minDate?: Date | null;
  isCellSticky?: string;
}

const AmortizationStartDateCell = ({
  amortizationSource,
  hasChildren,
  lastChild,
  highlighted,
  showCursor,
  isRowSelected,
  onDateChange,
  hasError,
  minDate,
  isCellSticky,
}: Props) => {
  const tableClasses = useTableStyles();
  return (
    <TableCell
      className={clsx(tableClasses.datePickerCell, {
        [tableClasses.child]: amortizationSource.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.bold]: highlighted,
        [tableClasses.lastChild]: lastChild,
        [tableClasses.cursorPointer]: showCursor,
      })}
      is-cell-sticky={isCellSticky}
    >
      {
        hasChildren && 'Multiple'
      }
      {
        isRowSelected && !hasChildren && onDateChange && (
          <FactaDatePicker
            disabled={amortizationSource
              .amortizationSchedule.amortizationScheduleType === LegacySchedulingMethod.MANUAL}
            selectedDate={amortizationSource.amortizationSchedule.amortizationStartDate}
            onDateChange={(newDate) => onDateChange('amortizationStartDate', amortizationSource.internalId, newDate)}
            error={hasError}
            minDate={minDate}
          />
        )
      }
      {amortizationSource.amortizationSchedule.amortizationScheduleType === LegacySchedulingMethod.MANUAL
        && !isRowSelected
        && !hasChildren && 'M'}
      {
        amortizationSource.amortizationSchedule.amortizationScheduleType !== LegacySchedulingMethod.MANUAL
        && !isRowSelected && !hasChildren && amortizationSource.amortizationSchedule.amortizationStartDate
        && format(amortizationSource.amortizationSchedule.amortizationStartDate, FULL_DAY_FORMAT)
      }
    </TableCell>
  );
};

export default React.memo(AmortizationStartDateCell);
