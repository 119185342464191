import { createMuiTheme } from '@material-ui/core/styles';

import COLORS from './colors';
import { POPPER_ELEMENTS_Z_INDEX } from '../util/constants';

export const fontFamily = '"Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",'
  + ' "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';

export const typography = {
  fontFamily,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  fontSize: 12,
  color: COLORS.skyBlue,
};

const overrides = {
  MuiTableBody: {
    root: {
      '&:last-child': {
        borderBottom: `2px solid ${COLORS.lightGray2}`,
      },
    },
  },
  MuiTableCell: {
    root: {
      fontSize: 12,
      color: COLORS.deepGray,
      borderBottom: `1px dashed ${COLORS.lightGray2}`,
      lineHeight: '16px',
      height: '46px',
    },
    head: {
      fontSize: 12,
      color: COLORS.grayNeutral,
      borderBottom: `1px solid ${COLORS.disabled}`,
      lineHeight: '13px',
      padding: '8px',
      verticalAlign: 'middle',
    },
    sizeSmall: {
      padding: 8,
    },
  },
  MuiTableRow: {
    root: {
      minHeight: 38,
      background: `${COLORS.white} !important`,
    },
    hover: {
      '&:hover': {
        background: `${COLORS.lightGrayBackground} !important`,
      },
    },
    head: {
      background: `${COLORS.white} !important`,
      '&:hover': {
        background: `${COLORS.white} !important`,
      },
    },
  },
  MuiTooltip: {
    tooltip: {
      fontSize: 12,
      color: COLORS.white,
      backgroundColor: COLORS.black,
    },
  },
  MuiDialog: {
    paper: {
      padding: '24px',
      color: COLORS.black,
    },
  },
  MuiDialogTitle: {
    root: {
      padding: 0,
      fontSize: 14,
      fontWeight: 'bold' as 'bold',
      lineHeight: '22px',
    },
  },
  MuiDialogContent: {
    root: {
      marginTop: '24px',
      padding: 0,
    },
  },
  MuiDialogContentText: {
    root: {
      maxWidth: 430,
      marginBottom: '24px',
      fontSize: 14,
      color: COLORS.black,
    },
  },
  MuiDialogActions: {
    root: {
      padding: 0,
      justifyContent: 'flex-end',
    },
  },
  MuiAlert: {
    root: {
      '&$error': {
        backgroundColor: COLORS.error,
      },
    },
    filledError: {
      backgroundColor: COLORS.error,
    },
    outlinedWarning: {
      backgroundColor: COLORS.warningBackground,
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .MuiAlert-message': {
        color: COLORS.black,
        whiteSpace: 'normal',
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
      },
      '& .MuiAlert-icon': {
        marginLeft: '12px',
        marginRight: 0,
        '& svg': {
          fontSize: 24,
        },
      },
    },
    outlinedError: {
      backgroundColor: COLORS.errorShadow,
      borderColor: COLORS.error,
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .MuiAlert-message': {
        color: COLORS.error,
        whiteSpace: 'normal',
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
      },
      '& .MuiAlert-icon': {
        color: COLORS.error,
        marginLeft: '12px',
        marginRight: 0,
        '& svg': {
          fontSize: 24,
        },
      },
    },
  },
  MuiBreadcrumbs: {
    li: {
      position: 'relative' as 'relative',
    },
  },
  MuiCardContent: {
    root: {
      paddingLeft: 20,
      paddingTop: 12,
      paddingBottom: 6,
      paddingRight: 20,
    },
  },
  MuiCardActions: {
    root: {
      flexDirection: 'column' as 'column',
      display: 'flex',
      width: '100%',
      alignItems: 'flex-start',
      paddingLeft: 20,
      paddingTop: 0,
      paddingBottom: 12,
      paddingRight: 20,
    },
  },
  MuiTypography: {
    h6: {
      fontWeight: 'normal' as 'normal',
    },
    body2: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '22px',
    },
  },
  MuiAutocomplete: {
    input: {
      '&.Mui-focused': {
        border: `1px solid ${COLORS.violet}`,
      },
      '&.Mui-error': {
        color: COLORS.error,
        border: `1px solid ${COLORS.error} !important`,
      },
    },
    option: {
      overflowWrap: 'anywhere',
    },
    popper: {
      top: '2px !important',
      left: '-9px !important',
      zIndex: POPPER_ELEMENTS_Z_INDEX,
    },
  },
  MuiInputAdornment: {
    positionEnd: {
      marginLeft: 0,
    },
  },
  MuiInputBase: {
    root: {
      fontSize: 12,
    },
  },
  MuiButton: {
    root: {
      fontSize: '10px',
      lineHeight: '15px',
      fontWeight: 700,
      letterSpacing: 0.25,
      borderRadius: '6px',
      minHeight: '28px',
      'text-transform': 'none',
    },
    iconSizeMedium: {
      '& > *:first-child': {
        fontSize: '16px',
      },
    },
    sizeLarge: {
      fontSize: 12,
      padding: '6px 10px',
    },
    contained: {
      margin: '0 6px',
      boxShadow: '0 0 0 3px transparent',
    },
    outlined: {
      margin: '0 6px',
      boxShadow: '0 0 0 3px transparent',
    },
    containedPrimary: {
      color: COLORS.white,
      '&:hover, &:focus': {
        boxShadow: `0 0 0 3px ${COLORS.skyBlueShadow}`,
        backgroundColor: COLORS.skyBlue,
      },
      '&.Mui-disabled': {
        color: '#B9B9B9',
        backgroundColor: COLORS.border,
      },
    },
    containedSecondary: {
      color: COLORS.white,
      '&:hover, &:focus': {
        boxShadow: `0 0 0 3px ${COLORS.violetShadow}`,
        backgroundColor: COLORS.violet,
      },
      '&.Mui-disabled': {
        color: '#B9B9B9',
        backgroundColor: COLORS.border,
      },
    },
    outlinedPrimary: {
      '&:hover, &:focus': {
        boxShadow: `0 0 0 3px ${COLORS.skyBlueShadow}`,
      },
    },
    sizeSmall: {
      minHeight: '24px',
      fontSize: '10px',
    },
  },
  MuiButtonGroup: {
    contained: {
      boxShadow: 'none',
    },
    groupedContained: {
      marginRight: '1px',
    },
  },
  MuiMenu: {
    list: {
      padding: '8px 8px 0',
      minWidth: '120px',
    },
  },
  MuiMenuItem: {
    root: {
      fontSize: '12px',
      padding: 8,
      minHeight: '40px !important',
      marginBottom: 8,
      borderRadius: '4px',
      '&.Mui-selected': {
        backgroundColor: COLORS.skyBlueShaded,
        color: COLORS.skyBlue,
        fontWeight: 700,
      },
    },
  },
  MuiTab: {
    root: {
      height: 48,
      padding: '16px',
      minWidth: 'auto !important',
      'text-transform': 'none',
    },
    textColorPrimary: {
      color: COLORS.medGray,
      '&.Mui-selected': {
        color: COLORS.deepGray,
      },
    },
  },
  MuiCheckbox: {
    root: {
      padding: 0,
      width: '18px',
      height: '18px',
      color: COLORS.checkboxUnchecked,
    },
    colorSecondary: {
      '&$checked': {
        color: COLORS.skyBlue,
      },
      '&disabled': {
        color: COLORS.disabled,
      },
    },
  },
  MuiPagination: {
    ul: {
      '& li:first-child, li:nth-child(2), li:nth-last-child(2), li:last-child': {
        '& button': {
          boxShadow: '0 0 0 3px transparent',
          borderRadius: 4,
          backgroundColor: COLORS.skyBlue,
          color: COLORS.white,
          transition: 'box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          '&.Mui-disabled': {
            backgroundColor: COLORS.border,
            color: COLORS.black,
          },
          '&:hover, &:focus': {
            boxShadow: `0 0 0 3px ${COLORS.skyBlueShadow}`,
            backgroundColor: COLORS.skyBlue,
          },
        },
      },
    },
  },
  MuiPaginationItem: {
    sizeSmall: {
      margin: '0 8px',
    },
    page: {
      '&.Mui-selected': {
        color: COLORS.skyBlue,
        backgroundColor: COLORS.skyBlueShadow,
      },
    },
  },
  MuiCssBaseline: {
    '@global': {
      body: {
        lineHeight: 1,
      },
      '.react-datepicker-popper': {
        zIndex: POPPER_ELEMENTS_Z_INDEX,
      },
    },
  },
};

const theme = createMuiTheme({
  overrides,
  typography,
  palette: {
    background: {
      default: COLORS.background,
    },
    primary: {
      main: COLORS.skyBlue,
      light: COLORS.skyBlue,
    },
    secondary: {
      main: COLORS.violet,
      light: COLORS.violet15,
      dark: COLORS.brightViolet,
    },
  },
});

export default theme;
