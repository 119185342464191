import {
  addMonths,
  differenceInCalendarDays,
  differenceInCalendarMonths,
  endOfMonth,
  format,
  getDaysInMonth,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  isSameMonth,
  isWithinInterval,
  lastDayOfMonth,
  max,
  parse,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import roundTo from 'round-to';
import Papa from 'papaparse';
import fileDownload from 'js-file-download';
import { v4 as uuidv4 } from 'uuid';
import cloneDeep from 'lodash.clonedeep';
import {
  Account,
  AccountClass,
  AccountClassification,
  AccountInfo,
  AmortizationSchedule,
  AmortizationScheduleDetails,
  AmortizationSource,
  CategoryLabel,
  Customer,
  Product,
  ProductCategory,
  LegacySchedulingMethod,
  Subledger,
  SubledgerAmortizationLog,
  Vendor,
} from '../../interfaces/types';
import {
  ASSET_SCHEDULED_STATUS,
  ASSET_TEMP_SCHEDULED_STATUS,
  CALCULATE_ASSET,
  DAY_SHORT_FORMAT,
  DEFERRED_REVENUE_ACCOUNTS,
  FACTA_SOURCE,
  FIXED_ASSETS,
  FULL_DAY_FORMAT,
  MONTH_SHOW_FORMAT,
  SELECT_ROW,
} from '../../util/constants';
import {
  fromUTC,
  toUTC,
} from '../../util/timezone';
import currencyFormatter from '../../util/currencyFormatter';
import getQuickbookLink from '../../util/getQuickbookLink';
import getSubledgerStartEndDate from '../../util/getSubledgerStartEndDate';
import { setSubledger } from '../../util/subledger';

export const getPrepaidScheduleAmountForMonth = (asset: AmortizationSource, date: string) => {
  let amount = 0;
  asset?.amortizationSchedule?.amortizationScheduleDetails
    ?.forEach((assetSchedule: AmortizationScheduleDetails) => {
      if (format(assetSchedule.scheduleDate, DAY_SHORT_FORMAT) === date
        && !Number.isNaN(Number(assetSchedule.amount))) {
        amount = Number(assetSchedule.amount);
      }
    });
  return amount;
};

export const getTotalBalanceAndAmortizationBalance = (childAssets: Array<AmortizationSource>) => {
  let totalBalance = 0;
  let amortizationToDateBalance = 0;
  childAssets?.forEach((asset: AmortizationSource) => {
    totalBalance += Number(asset.startingBalance);
    amortizationToDateBalance += Number(asset.amortizationToDateBalance ?? 0);
  });
  return {
    totalBalance,
    amortizationToDateBalance,
  };
};

export const getAccountNameFromList = (destinationId: string, accounts: Array<Account>) => {
  const expenseAccount = accounts?.find(({ id }: Account) => id === destinationId);
  if (expenseAccount) {
    if (expenseAccount?.accNum) {
      return `${expenseAccount?.accNum} ${expenseAccount?.name}`;
    }
    return expenseAccount?.name;
  }
  return '';
};

export const getAccountName = (account: Account) => {
  if (account?.accNum) {
    return `${account?.accNum} ${account?.name}`;
  }
  return account?.name;
};

export const getSourceCreationDate = (prepaidAsset: AmortizationSource, assets: Array<AmortizationSource>) => {
  if (prepaidAsset?.parentId) {
    const parent = getParent(prepaidAsset.parentId, assets);
    if (parent) {
      return parent?.sourceCreationDate;
    }
  }
  return prepaidAsset?.sourceCreationDate;
};

export const getVendorName = (vendorId: string, vendors: Array<Vendor>) => vendors
  ?.find(({ id }: Vendor) => id === vendorId)?.displayName;

export const getCustomerName = (customerId: string, customers: Array<Customer>) => customers
  ?.find(({ id }: Customer) => id === customerId)?.displayName;

export const getProductName = (productId: string, products: Array<Product>) => products
  ?.find(({ id }: Product) => id === productId)?.name;

export const getAccountByName = (accountName: string, accounts: Array<Account>) => (accounts?.find((
  {
    accNum,
    name,
  }: Account,
) => accountName === `${accNum} ${name}` || accountName === name)
);

export const getAccountById = (accountId: string, accounts: Array<Account>) => {
  if (accountId) {
    return accounts
      ?.find(({ id }: Account) => id === accountId);
  }

  return null;
};

export const getVendorById = (vendorId: string, vendors: Array<Vendor>) => vendors
  ?.find(({ id }: Vendor) => id === vendorId);

export const getCustomerById = (customerId: string, customers: Array<Customer>) => customers
  ?.find(({ id }: Customer) => id === customerId);

export const getProductById = (productId: string, products: Array<Product>) => products
  ?.find(({ id }: Product) => id === productId);

export const getClassName = (classId: string, accountClasses: Array<AccountClass>) => accountClasses
  ?.find(({ id }: AccountClass) => id === classId)?.className;

export const getAccountClassById = (classId: string, accountClasses: Array<AccountClass>) => {
  if (classId) {
    return accountClasses
      ?.find(({ id }: AccountClass) => id === classId);
  }

  return null;
};

export const getChildren = (id: string, assets: Array<AmortizationSource>) => assets
  ?.filter(({ parentId }: AmortizationSource) => parentId === id);

export const getAssetSumForMonth = (date: string, assets: Array<AmortizationSource>) => {
  let sum = 0;
  assets?.forEach((asset: AmortizationSource) => {
    asset?.amortizationSchedule?.amortizationScheduleDetails
      ?.forEach((assetSchedule: AmortizationScheduleDetails) => {
        if (format(assetSchedule.scheduleDate, DAY_SHORT_FORMAT) === date) {
          if (!Number.isNaN(Number(assetSchedule.amount))) {
            sum += Number(assetSchedule.amount);
          }
        }
      });
  });
  return sum;
};

export const getAssetSumForMonthWithStatus = (
  date: string, assets: Array<AmortizationSource>, status?: Array<string>,
) => {
  let sum = 0;
  assets?.forEach((asset: AmortizationSource) => {
    if ((!status && !asset.status) || (status && status.includes(asset?.status))) {
      asset?.amortizationSchedule
        ?.amortizationScheduleDetails
        ?.forEach((assetSchedule: AmortizationScheduleDetails) => {
          if (format(assetSchedule.scheduleDate, DAY_SHORT_FORMAT) === date) {
            if (!Number.isNaN(Number(assetSchedule.amount))) {
              sum += Number(assetSchedule.amount);
            }
          }
        });
    }
  });
  return sum;
};

export const getFactaBalance = (assets: Array<AmortizationSource>) => {
  let total = 0;
  assets?.forEach((asset: AmortizationSource) => {
    if (!asset?.parentId) {
      total += roundTo(Number(asset.startingBalance), 2);
    }
  });
  return roundTo(total, 2);
};

export const getFactaSourceBalance = (assets: Array<AmortizationSource>) => {
  let total = 0;
  assets?.forEach((asset: AmortizationSource) => {
    if (!asset?.parentId && asset?.origin === FACTA_SOURCE) {
      total += roundTo(Number(asset.startingBalance), 2);
    }
  });
  return roundTo(total, 2);
};

export const getPositiveNegativeAssetForMonth = (date: string, assets: Array<AmortizationSource>) => {
  let positiveTotal = 0;
  let negativeTotal = 0;
  assets?.forEach((asset: AmortizationSource) => {
    asset?.amortizationSchedule?.amortizationScheduleDetails
      ?.forEach((assetSchedule: AmortizationScheduleDetails) => {
        if (![ASSET_SCHEDULED_STATUS, ASSET_TEMP_SCHEDULED_STATUS].includes(asset?.status)) {
          return;
        }
        if (format(assetSchedule.scheduleDate, DAY_SHORT_FORMAT) === date
          && !Number.isNaN(Number(assetSchedule.amount))) {
          if (Number(assetSchedule.amount) > 0) {
            positiveTotal += Number(assetSchedule.amount);
          } else if (Number(assetSchedule.amount) < 0) {
            negativeTotal += Number(assetSchedule.amount);
          }
        }
      });
  });
  return {
    positiveTotal: Math.abs(positiveTotal),
    negativeTotal: Math.abs(negativeTotal),
  };
};

export const getParent = (id: string, assets: Array<AmortizationSource>) => assets
  ?.find((asset: AmortizationSource) => asset.id === id);

export const getMonthlyCalculationFactor = (asset: AmortizationSource) => {
  const startMonthFraction = ((differenceInCalendarDays(lastDayOfMonth(
    asset?.amortizationSchedule?.amortizationStartDate!,
  ),
  asset?.amortizationSchedule?.amortizationStartDate!)) + 1) / getDaysInMonth(
    asset?.amortizationSchedule?.amortizationStartDate!,
  );
  const endDateMonthFraction = ((differenceInCalendarDays(asset.amortizationSchedule?.amortizationEndDate!,
    startOfMonth(
      asset?.amortizationSchedule?.amortizationEndDate!,
    )) + 1) / getDaysInMonth(
    asset?.amortizationSchedule?.amortizationEndDate!,
  ));
  const monthsDifference = differenceInCalendarMonths(
    lastDayOfMonth(asset?.amortizationSchedule?.amortizationEndDate!),
    startOfMonth(asset?.amortizationSchedule?.amortizationStartDate!),
  ) - 1;
  return startMonthFraction + endDateMonthFraction + monthsDifference;
};

export const getStartEndDate = (subledger?: Subledger, amortizationSources?: Array<AmortizationSource>) => {
  let endDate: Date | null = null;
  const calAssets = amortizationSources ?? subledger?.amortizationSources;
  calAssets?.forEach((asset: AmortizationSource) => {
    if (asset?.amortizationSchedule.amortizationEndDate) {
      if (!endDate) {
        endDate = asset?.amortizationSchedule.amortizationEndDate;
      } else if (isBefore(endDate, asset?.amortizationSchedule?.amortizationEndDate)) {
        endDate = asset?.amortizationSchedule?.amortizationEndDate;
      }
    }
  });
  return {
    startDate: lastDayOfMonth(subledger?.factaStartDate!),
    endDate,
  };
};

export const getPrepareJEStartEndDate = (
  subledger: Subledger, amortizationSources: Array<AmortizationSource>, scheduleDate: string,
) => {
  const currentMonth = parse(scheduleDate!, DAY_SHORT_FORMAT, new Date());
  const {
    startDate,
    endDate,
  } = getStartEndDate(subledger, amortizationSources);
  const advanceMonth = addMonths(lastDayOfMonth(currentMonth), 11);
  const prepareJEEndDate = max([endDate!, advanceMonth]);
  return {
    startDate,
    endDate: prepareJEEndDate,
  };
};

export const geSubledgerStartEndDateForCalculations = (
  subledger?: Subledger,
  amortizationSources?: Array<AmortizationSource>,
) => {
  let endDate: Date | null = null;
  let startDate: Date | null = null;
  const calAssets = amortizationSources ?? subledger?.amortizationSources;
  calAssets
    ?.forEach((asset: AmortizationSource) => {
      if (asset?.amortizationSchedule.amortizationEndDate && asset?.amortizationSchedule?.amortizationStartDate) {
        if (!endDate) {
          endDate = asset?.amortizationSchedule.amortizationEndDate;
        } else if (isBefore(endDate, asset?.amortizationSchedule?.amortizationEndDate)) {
          endDate = asset?.amortizationSchedule?.amortizationEndDate;
        }
        if (!startDate) {
          startDate = asset?.amortizationSchedule.amortizationStartDate;
        } else if (isAfter(startDate, asset?.amortizationSchedule?.amortizationStartDate)) {
          startDate = asset?.amortizationSchedule?.amortizationStartDate;
        }
      }
    });
  return {
    startDate,
    endDate,
  };
};

export const calculatePrepaidAssetAmortizationSchedules = (
  startDate: Date,
  asset: AmortizationSource,
  calculationsMonths: number,
  diffInMonths: number,
  totalAmount: number,
  calculationsDays: number,
) => (index: number) => {
  const currentMonth = startOfMonth(addMonths(startDate!, index));
  if ((isBefore(
    startOfMonth(
      asset?.amortizationSchedule?.amortizationStartDate!,
    ),
    asset?.amortizationSchedule?.amortizationEndDate!,
  )
    && isWithinInterval(currentMonth, {
      start: startOfMonth(asset?.amortizationSchedule?.amortizationStartDate!),
      end: asset?.amortizationSchedule?.amortizationEndDate!,
    })) || isEqual(currentMonth, startOfMonth(asset?.amortizationSchedule?.amortizationStartDate!))) {
    const lastDay = lastDayOfMonth(currentMonth);
    if (isSameMonth(
      asset?.amortizationSchedule?.amortizationStartDate!, asset?.amortizationSchedule?.amortizationEndDate!,
    )) {
      return {
        amount: Number(asset?.startingBalance),
        scheduleDate: lastDay,
        amortizationSourceId: asset.id,
      };
    }
    let amount: number | null = 0;
    // calculations
    if (asset?.amortizationSchedule?.amortizationScheduleType === LegacySchedulingMethod.MONTHLY) {
      const perMonthAmount = asset.startingBalance / calculationsMonths;
      if (isSameMonth(asset?.amortizationSchedule?.amortizationStartDate!, currentMonth)) {
        const startMonthFraction = (
          (differenceInCalendarDays(lastDayOfMonth(asset?.amortizationSchedule?.amortizationStartDate!),
            asset?.amortizationSchedule?.amortizationStartDate!)) + 1) / getDaysInMonth(
          asset?.amortizationSchedule?.amortizationStartDate!,
        );
        amount = perMonthAmount * startMonthFraction;
      } else if (isSameMonth(asset?.amortizationSchedule?.amortizationEndDate!, currentMonth)) {
        amount = asset.startingBalance - totalAmount;
      } else {
        amount = perMonthAmount;
      }
    } else if (asset?.amortizationSchedule?.amortizationScheduleType === LegacySchedulingMethod.DAILY) {
      const perDayAmount = asset.startingBalance / calculationsDays;
      if (isSameMonth(asset?.amortizationSchedule?.amortizationStartDate!, currentMonth)) {
        amount = (differenceInCalendarDays(lastDayOfMonth(currentMonth!),
          asset?.amortizationSchedule?.amortizationStartDate!) + 1) * perDayAmount;
      } else if (isSameMonth(asset?.amortizationSchedule?.amortizationEndDate!, currentMonth)) {
        amount = asset.startingBalance - totalAmount;
      } else {
        amount = getDaysInMonth(currentMonth) * perDayAmount;
      }
    }
    // eslint-disable-next-line no-param-reassign
    totalAmount += roundTo(amount, 2);
    return {
      amount: roundTo(amount, 2),
      scheduleDate: lastDay,
      amortizationSourceId: asset.id,
    };
  }
  return null;
};

export const calculateScheduleAsset = (
  subledger: Subledger,
  asset: AmortizationSource,
  amortizationSources?: Array<AmortizationSource>,
): AmortizationSource => {
  if (asset.amortizationSchedule.amortizationScheduleType === LegacySchedulingMethod.MANUAL) {
    // eslint-disable-next-line no-param-reassign
    asset.amortizationSchedule.amortizationScheduleDetails = null;
    return asset;
  }
  const {
    startDate,
    endDate,
  } = geSubledgerStartEndDateForCalculations(subledger, amortizationSources);
  if (!(asset?.amortizationSchedule?.amortizationStartDate
    && asset?.amortizationSchedule?.amortizationEndDate
    && startDate
    && endDate)) {
    return asset;
  }
  const diffInMonths = differenceInCalendarMonths(lastDayOfMonth(endDate!), startOfMonth(startDate!)) + 1;
  const calculationsMonths = getMonthlyCalculationFactor(asset);
  const calculationsDays = differenceInCalendarDays(
    asset?.amortizationSchedule?.amortizationEndDate!, asset?.amortizationSchedule?.amortizationStartDate!,
  ) + 1;
  if (diffInMonths > 0) {
    const totalAmount = 0;
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    asset.amortizationSchedule.amortizationScheduleDetails = Array.from(Array(diffInMonths)
      .keys())
      .map(calculatePrepaidAssetAmortizationSchedules(
        startDate!,
        asset,
        calculationsMonths,
        diffInMonths,
        totalAmount,
        calculationsDays,
      ))
      .filter((item) => !!item);
  }
  return asset;
};

export const getAssetSum = (subledger: Subledger, amortizationSources?: Array<AmortizationSource>) => {
  const assets = amortizationSources ?? subledger?.amortizationSources;
  let sum = 0;
  assets?.forEach((asset: AmortizationSource) => {
    if (!asset?.parentId) {
      sum += Number(asset?.startingBalance);
    }
  });
  return Number(sum.toFixed(2));
};

export const getAssetSumWithStatus = (subledger: Subledger, amortizationSources?: Array<AmortizationSource>) => {
  let sum = 0;
  (amortizationSources ?? subledger?.amortizationSources)?.forEach((asset: AmortizationSource) => {
    if (asset?.parentId || ![ASSET_SCHEDULED_STATUS, ASSET_TEMP_SCHEDULED_STATUS].includes(asset?.status)) {
      return;
    }
    sum += Number(asset?.startingBalance) - Number(asset?.amortizationToDateBalance);
  });
  return Number(sum)
    .toFixed(2);
};

export const getStartingBalanceOfParent = (subledger: Subledger, parentAssetId: string, skipChildId?: string) => {
  const children = getChildren(parentAssetId, subledger?.amortizationSources);
  return children.reduce((total: number, currentAsset: AmortizationSource) => {
    if (currentAsset.startingBalance && skipChildId !== currentAsset.internalId) {
      // eslint-disable-next-line no-param-reassign
      total += Number(currentAsset.startingBalance);
    }
    return total;
  }, 0);
};

export const catchUpAmountForPrepaidAsset = (date: Date, newAssets: Array<AmortizationSource>, index: number) => {
  const scheduledDate = format(date, DAY_SHORT_FORMAT);
  if (isBefore(newAssets[index]?.amortizationSchedule?.amortizationStartDate!, date)) {
    // get the amortization assets
    const total = newAssets[index]?.amortizationSchedule?.amortizationScheduleDetails
      ?.reduce((acc: number, schedule: AmortizationScheduleDetails) => {
        if (isBefore(schedule.scheduleDate, date)) {
          // @ts-ignore
          // eslint-disable-next-line no-param-reassign
          acc += schedule.amount;
        }
        return acc;
      }, 0);
    const amortizationScheduleDetails = newAssets[index]?.amortizationSchedule?.amortizationScheduleDetails
      ?.filter((schedule: AmortizationScheduleDetails) => !isBefore(schedule.scheduleDate, date)) ?? [];
    amortizationScheduleDetails?.forEach((schedule: AmortizationScheduleDetails) => {
      if (format(schedule.scheduleDate, DAY_SHORT_FORMAT) === scheduledDate) {
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        schedule.amount += roundTo(total, 2);
      }
    });
    // eslint-disable-next-line no-param-reassign
    newAssets[index].amortizationSchedule.amortizationScheduleDetails = amortizationScheduleDetails;
  }
  return newAssets[index];
};

export const defaultAsset = (
  prepaidAsset: AmortizationSource,
  scheduleType: string,
  factaStartDate: Date | null,
  scheduleDate?: Date | null,
): AmortizationSource => {
  const today = startOfDay(new Date());
  let amortizationEndDate = scheduleDate ?? today;
  if (prepaidAsset?.amortizationSchedule?.amortizationStartDate
    // @ts-ignore
    && isBefore(amortizationEndDate, fromUTC(prepaidAsset?.amortizationSchedule?.amortizationStartDate))) {
    // @ts-ignore
    amortizationEndDate = fromUTC(prepaidAsset?.amortizationSchedule?.amortizationStartDate);
  }
  if (prepaidAsset?.amortizationSchedule?.amortizationEndDate) {
    // @ts-ignore
    amortizationEndDate = fromUTC(prepaidAsset?.amortizationSchedule?.amortizationEndDate);
  }
  let amortizationScheduleDetails: Array<AmortizationScheduleDetails> = [];
  if (prepaidAsset?.amortizationSchedule?.amortizationScheduleDetails?.length) {
    amortizationScheduleDetails = prepaidAsset.amortizationSchedule.amortizationScheduleDetails
      ?.map((schedule: AmortizationScheduleDetails) => ({
        ...schedule,
        // @ts-ignore
        scheduleDate: fromUTC(schedule.scheduleDate),
      }));
  }
  // @ts-ignore
  return ({
    ...prepaidAsset,
    internalId: prepaidAsset.id,
    amortizationSchedule: {
      ...prepaidAsset.amortizationSchedule,
      amortizationStartDate: prepaidAsset?.amortizationSchedule?.amortizationStartDate
        // @ts-ignore
        ? fromUTC(prepaidAsset?.amortizationSchedule?.amortizationStartDate)
        : factaStartDate ?? today,
      amortizationEndDate,
      amortizationScheduleType: prepaidAsset?.amortizationSchedule?.amortizationScheduleType
        ? prepaidAsset?.amortizationSchedule?.amortizationScheduleType : scheduleType,
      amortizationScheduleDetails,
    },
    // @ts-ignore
    sourceCreationDate: prepaidAsset?.sourceCreationDate ? fromUTC(prepaidAsset?.sourceCreationDate) : today,
  });
};

export const defaultAssetFromLocalStorage = (prepaidAsset: AmortizationSource): AmortizationSource => {
  let amortizationScheduleDetails: Array<AmortizationScheduleDetails> = [];
  if (prepaidAsset?.amortizationSchedule?.amortizationScheduleDetails?.length) {
    amortizationScheduleDetails = prepaidAsset.amortizationSchedule.amortizationScheduleDetails
      ?.map((schedule: AmortizationScheduleDetails) => ({
        ...schedule,
        scheduleDate: new Date(schedule.scheduleDate!),
      }));
  }
  return ({
    ...prepaidAsset,
    internalId: prepaidAsset.id ?? prepaidAsset.internalId ?? uuidv4(),
    amortizationSchedule: {
      ...prepaidAsset.amortizationSchedule,
      amortizationStartDate: new Date(prepaidAsset?.amortizationSchedule?.amortizationStartDate!),
      amortizationEndDate: new Date(prepaidAsset?.amortizationSchedule?.amortizationEndDate!),
      amortizationScheduleDetails,
    },
    sourceCreationDate: new Date(prepaidAsset?.sourceCreationDate!),
  });
};

interface InitSubledgerProps {
  subledger: Subledger;
  scheduleDate?: string;
}

export const initializedSubledger = ({
  subledger,
  scheduleDate,
}: InitSubledgerProps): Subledger => {
  const date = scheduleDate ? parse(scheduleDate, DAY_SHORT_FORMAT, new Date()) : null;
  // @ts-ignore
  const factaStartDate = startOfMonth(fromUTC(subledger?.factaStartDate!));
  const serviceAssets = subledger
    ?.amortizationSources
    ?.map((prepaidAsset: any) => defaultAsset(
      prepaidAsset,
      subledger?.account?.scheduleType,
      date,
    )) ?? [];
  // @ts-ignore
  let subledgerAmortizationLogs: Array<SubledgerAmortizationLog> = [];
  if (subledger?.subledgerAmortizationLogs?.length) {
    subledgerAmortizationLogs = subledger?.subledgerAmortizationLogs
      ?.map((log: SubledgerAmortizationLog) => ({
        ...log,
        // @ts-ignore
        scheduleDate: fromUTC(log.scheduleDate),
      }));
  }
  return {
    ...subledger,
    amortizationSources: serviceAssets,
    factaStartDate,
    subledgerAmortizationLogs,
  };
};

export const initializedSubledgerFromLocalStorage = ({ subledger }: InitSubledgerProps): Subledger => {
  // @ts-ignore
  const factaStartDate = startOfMonth(new Date(subledger?.factaStartDate!));
  const serviceAssets = subledger
    ?.amortizationSources
    ?.map((prepaidAsset: any) => defaultAssetFromLocalStorage(
      prepaidAsset,
    )) ?? [];
  let subledgerAmortizationLogs: Array<SubledgerAmortizationLog> = [];
  if (subledger?.subledgerAmortizationLogs?.length) {
    subledgerAmortizationLogs = subledger?.subledgerAmortizationLogs
      ?.map((log: SubledgerAmortizationLog) => ({
        ...log,
        // @ts-ignore
        scheduleDate: new Date(log.scheduleDate),
      }));
  }
  return {
    ...subledger,
    amortizationSources: serviceAssets,
    factaStartDate,
    subledgerAmortizationLogs,
  };
};

export const calculateAssetForUnScheduledAssets = (
  newAssets: Array<AmortizationSource>, index: number, subledger: Subledger,
) => {
  if (newAssets[index]?.amortizationSchedule?.amortizationScheduleType !== LegacySchedulingMethod.MANUAL) {
    const sourceCreationDate = getSourceCreationDate(newAssets[index], newAssets);
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    newAssets[index] = calculateScheduleAsset(subledger, newAssets[index]);
    // eslint-disable-next-line no-param-reassign
    newAssets[index] = catchUpAmountForPrepaidAsset(lastDayOfMonth(sourceCreationDate!), newAssets, index);
  } else {
    // eslint-disable-next-line no-param-reassign
    newAssets[index].amortizationSchedule.amortizationEndDate = null;
    // eslint-disable-next-line no-param-reassign
    newAssets[index].amortizationSchedule.amortizationStartDate = null;
    // eslint-disable-next-line no-param-reassign
    newAssets[index].amortizationSchedule.amortizationScheduleDetails = null;
  }
};

export interface ExportToCSVProps {
  subledger: Subledger;
  vendors: Array<Vendor>;
  accountClasses: Array<AccountClass>;
  accountIncomes: Array<Account>;
  account: AccountInfo;
  scheduleDate?: string;
  customers: Array<Customer>;
  products: Array<Product>;
}

export const schedulerExportToCSV = ({
  subledger,
  vendors,
  accountClasses,
  accountIncomes,
  account,
  scheduleDate,
  customers,
  products,
}: ExportToCSVProps) => {
  const filteredSchedulerAssets = subledger?.amortizationSources
    ?.filter((asset: AmortizationSource) => !asset.parentId
      && [ASSET_SCHEDULED_STATUS, ASSET_TEMP_SCHEDULED_STATUS]?.includes(asset.status));

  const SchedulerAssets: Array<any> = [];
  // filter the prepaid assets for which the assests status is scheduled.
  if (subledger?.productCategory === ProductCategory.PrepaidExpense) {
    filteredSchedulerAssets?.forEach((asset: AmortizationSource) => {
      const children = getChildren(asset.internalId, subledger?.amortizationSources);
      const hasChildren = children?.length > 0;
      const { amortizationToDateBalance } = hasChildren ? getTotalBalanceAndAmortizationBalance(children) : {
        amortizationToDateBalance: 0,
      };
      const vendor = getVendorName(asset.vendorId, vendors);
      const parentAccountClass = getClassName(asset?.amortizationSchedule?.classId!, accountClasses);
      const parentRemainingBalance = currencyFormatter.format(asset?.startingBalance
       - (asset?.amortizationToDateBalance ? asset?.amortizationToDateBalance : 0));
      const expenseToDate = currencyFormatter.format((asset?.amortizationToDateBalance
        ? asset?.amortizationToDateBalance : 0));
      const parentAmortizationStartDate = asset?.amortizationSchedule?.amortizationStartDate
      && asset?.amortizationSchedule?.amortizationScheduleType !== LegacySchedulingMethod.MANUAL
        ? format(asset?.amortizationSchedule?.amortizationStartDate, FULL_DAY_FORMAT) : 'M';
      const parentAmortizationEndDate = asset?.amortizationSchedule?.amortizationEndDate
      && asset?.amortizationSchedule?.amortizationScheduleType !== LegacySchedulingMethod.MANUAL
        ? format(asset?.amortizationSchedule?.amortizationEndDate, FULL_DAY_FORMAT) : 'M';
      const classAvailable = account?.classTrackingPerTxnLine || account?.classTrackingPerTxn;
      const reportingMonth = format(parse(scheduleDate!, DAY_SHORT_FORMAT, new Date()), MONTH_SHOW_FORMAT);
      const parentDescription = asset?.description;

      if (!children?.length) {
        SchedulerAssets.push({
          ...asset,
          reportingMonth,
          parentDescription,
          expenseAccount: getAccountNameFromList(asset?.amortizationSchedule?.destinationId!, accountIncomes),
          accountClass: classAvailable ? parentAccountClass : '',
          vendor,
          remainingBalance: parentRemainingBalance,
          expenseToDate,
          amortizationToDateBalance: currencyFormatter
            .format((asset?.amortizationToDateBalance ? asset?.amortizationToDateBalance : 0)),
          amount: currencyFormatter.format(asset?.startingBalance!),
          amortizationStartDate: parentAmortizationStartDate,
          amortizationEndDate: parentAmortizationEndDate,
          url: getQuickbookLink(asset.sourceId, asset.sourceType),
          sourceCreationDate: asset?.sourceCreationDate && format(asset?.sourceCreationDate, FULL_DAY_FORMAT),
          amortizationScheduleType: asset?.amortizationSchedule?.amortizationScheduleType,
        });
      } else {
        children?.forEach((child) => {
          SchedulerAssets.push({
            ...asset,
            reportingMonth,
            parentDescription,
            description: child?.description,
            expenseAccount: getAccountNameFromList(child?.amortizationSchedule?.destinationId!, accountIncomes),
            accountClass: classAvailable ? getClassName(child?.amortizationSchedule?.classId!, accountClasses) : '',
            vendor,
            remainingBalance: currencyFormatter.format(child.startingBalance
              - (child?.amortizationToDateBalance ? child?.amortizationToDateBalance : 0)),
            expenseToDate: currencyFormatter.format((child?.amortizationToDateBalance
              ? child?.amortizationToDateBalance : 0)),
            amortizationToDateBalance,
            amount: currencyFormatter.format(child.startingBalance),
            amortizationStartDate: child?.amortizationSchedule?.amortizationStartDate
            && child?.amortizationSchedule?.amortizationScheduleType !== LegacySchedulingMethod.MANUAL
              ? format(child?.amortizationSchedule?.amortizationStartDate, FULL_DAY_FORMAT) : 'M',
            amortizationEndDate: child?.amortizationSchedule?.amortizationEndDate
            && child?.amortizationSchedule?.amortizationScheduleType !== LegacySchedulingMethod.MANUAL
              ? format(child?.amortizationSchedule?.amortizationEndDate, FULL_DAY_FORMAT) : 'M',
            amortizationScheduleType: child?.amortizationSchedule?.amortizationScheduleType,
            url: getQuickbookLink(asset.sourceId, asset.sourceType),
            sourceCreationDate: asset?.sourceCreationDate && format(asset?.sourceCreationDate, FULL_DAY_FORMAT),
          });
        });
      }
    });
    // preparing filtered data to be unparsed and fed to csv
    const csvData = Papa.unparse({
      data: SchedulerAssets,
      fields: ['reportingMonth',
        'sourceCreationDate',
        'sourceId',
        'url',
        'parentDescription',
        'description',
        'vendor',
        'expenseAccount',
        (account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) ? 'accountClass' : '',
        'amortizationStartDate',
        'amortizationEndDate',
        'amortizationScheduleType',
        'amount',
        'expenseToDate',
        'remainingBalance'].filter((data) => !!data),
    }, {
      header: false,
      skipEmptyLines: true,
    });

    // chunk to unify the column and render the fed to csv data
    const downloadData = `${['Report Period',
      'JE Date',
      'GL JE ID',
      'Url',
      'Parent Description',
      'Description',
      'Vendor',
      'Expense Account',
      (account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) ? 'Class' : null,
      'Amortization Start Date',
      'Amortization End Date',
      'Amort. Schedule',
      'Amount',
      'Expense To Date',
      'Remaining Balance'].filter((data) => !!data)
      .join(',')}\n${csvData}`;

    fileDownload(downloadData, 'Schedule Prepaid.csv');
  }
  if (subledger?.productCategory === ProductCategory.DeferredRevenue) {
    filteredSchedulerAssets?.forEach((asset: AmortizationSource) => {
      const children = getChildren(asset.internalId, subledger?.amortizationSources);
      const hasChildren = children?.length > 0;
      const recognizedToDate = currencyFormatter.format((asset?.amortizationToDateBalance
        ? asset?.amortizationToDateBalance : 0));
      const parentRemainingBalance = currencyFormatter.format(asset?.startingBalance
        - (asset?.amortizationToDateBalance ? asset?.amortizationToDateBalance : 0));
      const { amortizationToDateBalance } = hasChildren ? getTotalBalanceAndAmortizationBalance(children) : {
        amortizationToDateBalance: 0,
      };
      const customer = getCustomerName(asset.customerId, customers);
      const product = getProductName(asset.productId, products);
      const parentAccountClass = getClassName(asset?.amortizationSchedule?.classId!, accountClasses);
      const parentAmortizationStartDate = asset?.amortizationSchedule?.amortizationStartDate
      && asset?.amortizationSchedule?.amortizationScheduleType !== LegacySchedulingMethod.MANUAL
        ? format(asset?.amortizationSchedule?.amortizationStartDate, FULL_DAY_FORMAT) : 'M';
      const parentAmortizationEndDate = asset?.amortizationSchedule?.amortizationEndDate
      && asset?.amortizationSchedule?.amortizationScheduleType !== LegacySchedulingMethod.MANUAL
        ? format(asset?.amortizationSchedule?.amortizationEndDate, FULL_DAY_FORMAT) : 'M';
      const classAvailable = account?.classTrackingPerTxnLine || account?.classTrackingPerTxn;
      const reportingMonth = format(parse(scheduleDate!, DAY_SHORT_FORMAT, new Date()), MONTH_SHOW_FORMAT);
      const parentDescription = asset?.description;
      const revenueAccount = getAccountNameFromList(asset?.amortizationSchedule?.destinationId!, accountIncomes);

      if (!children?.length) {
        SchedulerAssets.push({
          ...asset,
          reportingMonth,
          parentDescription,
          revenueAccount,
          accountClass: classAvailable ? parentAccountClass : '',
          remainingBalance: parentRemainingBalance,
          recognizedToDate,
          customer,
          product,
          amortizationToDateBalance: currencyFormatter
            .format((asset?.amortizationToDateBalance ? asset?.amortizationToDateBalance : 0)),
          amount: currencyFormatter.format(asset?.startingBalance!),
          amortizationStartDate: parentAmortizationStartDate,
          amortizationEndDate: parentAmortizationEndDate,
          url: getQuickbookLink(asset.sourceId, asset.sourceType),
          sourceCreationDate: asset?.sourceCreationDate && format(asset?.sourceCreationDate, FULL_DAY_FORMAT),
          amortizationScheduleType: asset?.amortizationSchedule?.amortizationScheduleType,
        });
      } else {
        children?.forEach((child) => {
          SchedulerAssets.push({
            ...asset,
            reportingMonth,
            parentDescription,
            description: child?.description,
            revenueAccount: getAccountNameFromList(child?.amortizationSchedule?.destinationId!, accountIncomes),
            accountClass: classAvailable
              ? getClassName(child?.amortizationSchedule?.classId!, accountClasses) : '',
            customer,
            product,
            amortizationToDateBalance,
            remainingBalance: currencyFormatter.format(child.startingBalance - (child?.amortizationToDateBalance
              ? child?.amortizationToDateBalance : 0)),
            recognizedToDate: currencyFormatter.format((child?.amortizationToDateBalance
              ? child?.amortizationToDateBalance : 0)),
            amount: currencyFormatter.format(child.startingBalance),
            amortizationStartDate: child?.amortizationSchedule?.amortizationStartDate
            && child?.amortizationSchedule?.amortizationScheduleType !== LegacySchedulingMethod.MANUAL
              ? format(child?.amortizationSchedule?.amortizationStartDate, FULL_DAY_FORMAT) : 'M',
            amortizationEndDate: child?.amortizationSchedule?.amortizationEndDate
            && child?.amortizationSchedule?.amortizationScheduleType !== LegacySchedulingMethod.MANUAL
              ? format(child?.amortizationSchedule?.amortizationEndDate, FULL_DAY_FORMAT) : 'M',
            amortizationScheduleType: child?.amortizationSchedule?.amortizationScheduleType,
            url: getQuickbookLink(asset.sourceId, asset.sourceType),
            sourceCreationDate: asset?.sourceCreationDate && format(asset?.sourceCreationDate, FULL_DAY_FORMAT),
          });
        });
      }
    });
    // preparing filtered data to be unparsed and fed to csv
    const csvData = Papa.unparse({
      data: SchedulerAssets,
      fields: ['reportingMonth',
        'sourceCreationDate',
        'sourceId',
        'url',
        'parentDescription',
        'description',
        'customer',
        'product',
        'revenueAccount',
        (account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) ? 'accountClass' : '',
        'amortizationStartDate',
        'amortizationEndDate',
        'amortizationScheduleType',
        'amount',
        'recognizedToDate',
        'remainingBalance'].filter((data) => !!data),
    }, {
      header: false,
      skipEmptyLines: true,
    });

    // chunk to unify the column and render the fed to csv data
    const downloadData = `${['Report Period',
      'JE Date',
      'Trans. #',
      'Url',
      'Parent Description',
      'Description',
      'Customer',
      'Product/Service',
      'Revenue Account',
      (account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) ? 'Class' : null,
      'Recognition Start Date',
      'Recognition End Date',
      'Recog. Schedule',
      'Amount',
      'Recognized to Date',
      'Remaining Balance'].filter((data) => !!data)
      .join(',')}\n${csvData}`;
    fileDownload(downloadData, 'Deferred Revenue.csv');
  }
};

export const calculateAsset = (subledger: Subledger, internalId: string) => {
  const newAssets = [...subledger.amortizationSources];
  const index = subledger.amortizationSources
    ?.findIndex((asset: AmortizationSource) => asset.internalId === internalId);
  const children = getChildren(newAssets[index].internalId, newAssets);
  if (children?.length) {
    // eslint-disable-next-line no-param-reassign
    newAssets[index].amortizationSchedule.amortizationEndDate = null;
    // eslint-disable-next-line no-param-reassign
    newAssets[index].amortizationSchedule.amortizationStartDate = null;
    // eslint-disable-next-line no-param-reassign
    newAssets[index].amortizationSchedule.amortizationScheduleDetails = null;
    children?.forEach((child) => {
      const childIndex = subledger.amortizationSources
        ?.findIndex((asset: AmortizationSource) => asset.internalId === child.internalId);
      calculateAssetForUnScheduledAssets(newAssets, childIndex, subledger);
    });
  } else {
    calculateAssetForUnScheduledAssets(newAssets, index, subledger);
  }
  return newAssets;
};

export const isPrepaidScheduleSame = (
  oldPrepaidSchedule: AmortizationSchedule, newPrepaidSchedule: AmortizationSchedule,
) => {
  if (oldPrepaidSchedule?.amortizationScheduleType === LegacySchedulingMethod.MANUAL
    && newPrepaidSchedule?.amortizationScheduleType === LegacySchedulingMethod.MANUAL) {
    return oldPrepaidSchedule?.classId === newPrepaidSchedule?.classId
      && oldPrepaidSchedule.destinationId === newPrepaidSchedule.destinationId;
  }
  return (
    oldPrepaidSchedule?.classId === newPrepaidSchedule?.classId
    && oldPrepaidSchedule.destinationId === newPrepaidSchedule.destinationId
    && isSameDay((oldPrepaidSchedule.amortizationStartDate
      ?? new Date()), (newPrepaidSchedule.amortizationStartDate ?? new Date()))
    && isSameDay((oldPrepaidSchedule.amortizationEndDate
      ?? new Date()), (newPrepaidSchedule.amortizationEndDate ?? new Date()))
    && oldPrepaidSchedule.amortizationScheduleType === newPrepaidSchedule.amortizationScheduleType
  );
};

export const initialPrepaidAssetForPrepareJE = (
  subledger: Subledger, scheduleDate: string,
) => subledger?.amortizationSources
  ?.map((amortizationSource: any) => {
    let amortizationScheduleDetails = amortizationSource
      ?.amortizationSchedule?.amortizationScheduleDetails
      ?.map((item: AmortizationScheduleDetails) => ({
        ...item,
        amount: item?.amount ?? 'M',
      }));

    if (amortizationSource.amortizationSchedule?.amortizationScheduleType === LegacySchedulingMethod.MANUAL) {
      const date = parse(scheduleDate, DAY_SHORT_FORMAT, new Date());
      const amortizationScheduleDate = startOfDay(date);
      const amortizationScheduleAmount = 0;
      if (!amortizationScheduleDetails) {
        amortizationScheduleDetails = [{
          scheduleDate: amortizationScheduleDate,
          amount: amortizationScheduleAmount,
        }];
      } else if (!amortizationScheduleDetails
        ?.some((scheduled: AmortizationScheduleDetails) => format(
          scheduled.scheduleDate, DAY_SHORT_FORMAT,
        ) === scheduleDate)
        && isAfter(endOfMonth(amortizationScheduleDate), amortizationSource.sourceCreationDate)) {
        amortizationScheduleDetails = [...amortizationScheduleDetails, {
          scheduleDate: amortizationScheduleDate,
          amount: amortizationScheduleAmount,
        }];
      }
    }
    return ({
      ...amortizationSource,
      amortizationSchedule: {
        ...amortizationSource.amortizationSchedule,
        amortizationScheduleDetails,
      },
    });
  });

export const getTotalAndRowsCount = (
  amortizationSources: Array<AmortizationSource>, scheduleDate: string,
): any => {
  const filterAssets = amortizationSources?.filter((asset: AmortizationSource) => {
    const children = getChildren(asset?.id, amortizationSources!);
    return children?.length === 0;
  });
  const {
    positiveTotal,
    negativeTotal,
  } = getPositiveNegativeAssetForMonth(scheduleDate, filterAssets!);
  const total = Math.max(positiveTotal, negativeTotal);
  const totalPrepaidAccount = positiveTotal - negativeTotal;
  return {
    totalPrepaidAccount,
    rowsCount: filterAssets?.length,
    total,
  };
};

export const updateAssetsForSaving = (amortizationSources: Array<AmortizationSource>) => {
  amortizationSources?.forEach((prepaidAsset: AmortizationSource) => {
    // eslint-disable-next-line no-param-reassign
    prepaidAsset.startingBalance = Number(prepaidAsset.startingBalance);
    if (prepaidAsset?.amortizationSchedule?.amortizationScheduleDetails?.length) {
      const amortizationScheduleDetails = prepaidAsset?.amortizationSchedule
        ?.amortizationScheduleDetails
        ?.filter((item: AmortizationScheduleDetails) => !Number.isNaN(Number(item.amount)));
      for (const amortizationSchedule of amortizationScheduleDetails) {
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        amortizationSchedule.prepaidAsset = null;
        // @ts-ignore
        amortizationSchedule.scheduleDate = toUTC(amortizationSchedule.scheduleDate);
        amortizationSchedule.amount = Number(amortizationSchedule.amount);
      }
      // eslint-disable-next-line no-param-reassign
      prepaidAsset.amortizationSchedule.amortizationScheduleDetails = amortizationScheduleDetails;
    }
    if (prepaidAsset?.amortizationSchedule?.amortizationStartDate) {
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      prepaidAsset.amortizationSchedule.amortizationStartDate = toUTC(
        prepaidAsset?.amortizationSchedule?.amortizationStartDate,
      );
    }
    if (prepaidAsset?.amortizationSchedule?.amortizationEndDate) {
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      prepaidAsset.amortizationSchedule.amortizationEndDate = toUTC(
        prepaidAsset?.amortizationSchedule?.amortizationEndDate,
      );
    }
    if (prepaidAsset?.sourceCreationDate) {
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      prepaidAsset.sourceCreationDate = toUTC(prepaidAsset?.sourceCreationDate);
    }
  });
};

export const monthChange = (previousDate: Date, subledger: Subledger) => {
  const { endDate } = getSubledgerStartEndDate(subledger);
  const datesEquality = isEqual(
    endDate,
    previousDate,
  );
  return {
    equalDates: datesEquality,
    scheduleDateToNavigate: lastDayOfMonth(previousDate),
  };
};

export const getPrepaidAssetsForSave = async (existingAsset: AmortizationSource, dispatch: any,
  scheduleDate: string, subledger: Subledger) => {
  const asset = cloneDeep(existingAsset) as AmortizationSource;
  await dispatch({
    type: CALCULATE_ASSET,
    payload: {
      internalId: asset.internalId,
      scheduleDate,
    },
  });
  const subledgerAssets = cloneDeep(subledger?.amortizationSources);
  const amortizationSource = subledgerAssets?.find((a: AmortizationSource) => a.internalId === asset.internalId);
  if (!amortizationSource) {
    return { prepaidAsset: null };
  }
  const children = getChildren(amortizationSource?.internalId!, subledgerAssets);
  const amortizationSources = [amortizationSource, ...children];
  updateAssetsForSaving(amortizationSources);
  return {
    amortizationSource,
    amortizationSources,
  };
};

const isAmoritzationSourceValid = (prepaidAsset: AmortizationSource, subledger: Subledger) => {
  const isValid = !prepaidAsset.description
    || !prepaidAsset.amortizationSchedule?.destinationId
    || !prepaidAsset.amortizationSchedule?.amortizationScheduleType
    || (prepaidAsset.amortizationSchedule?.amortizationScheduleType !== LegacySchedulingMethod.MANUAL && (
      !prepaidAsset.amortizationSchedule?.amortizationEndDate
      || !prepaidAsset.amortizationSchedule?.amortizationStartDate
      || isBefore(prepaidAsset.amortizationSchedule?.amortizationEndDate,
        prepaidAsset?.amortizationSchedule?.amortizationStartDate)
      || isBefore(prepaidAsset.amortizationSchedule?.amortizationStartDate, subledger?.factaStartDate!)
    ));
  return isValid;
};

export const isCreateSubledgerFormValid = (subledger: Subledger, dispatch: any) => {
  let formInvalid = true;
  subledger?.amortizationSources?.filter((prepaidAsst: AmortizationSource) => !prepaidAsst.parentId)
    .forEach((prepaidAsset: AmortizationSource) => {
      const children = getChildren(prepaidAsset.internalId, subledger?.amortizationSources);
      if (children?.length > 0) {
        if (!prepaidAsset.description) {
          if (formInvalid) {
            formInvalid = false;
            dispatch({
              type: SELECT_ROW,
              payload: { selectedRow: prepaidAsset.internalId },
            });
          }
        }
        children?.forEach((asset: AmortizationSource) => {
          if (!asset.amortizationSchedule?.destinationId
            || !asset.amortizationSchedule?.amortizationScheduleType
            || (asset.amortizationSchedule?.amortizationScheduleType !== LegacySchedulingMethod.MANUAL && (
              !asset.amortizationSchedule?.amortizationEndDate
              || !asset?.amortizationSchedule?.amortizationStartDate
              || isBefore(
                asset?.amortizationSchedule?.amortizationEndDate, asset?.amortizationSchedule?.amortizationStartDate,
              )
              || isBefore(
                asset.amortizationSchedule?.amortizationStartDate, subledger?.factaStartDate!,
              )
            ))
          ) {
            if (formInvalid) {
              formInvalid = false;
              dispatch({
                type: SELECT_ROW,
                payload: { selectedRow: prepaidAsset.internalId },
              });
            }
          }
        });
      } else if (isAmoritzationSourceValid(prepaidAsset, subledger)) {
        if (formInvalid) {
          formInvalid = false;
          dispatch({
            type: SELECT_ROW,
            payload: { selectedRow: prepaidAsset.internalId },
          });
        }
      }
    });
  return formInvalid;
};

export const updateHistoricalSubledgerData = (
  historicalUpdatedAssets: Array<AmortizationSource>,
  subledger: Subledger,
  serviceData?: Subledger,
) => {
  if (historicalUpdatedAssets?.length) {
    const amortizationSources = subledger
      ?.amortizationSources.map((asset: AmortizationSource) => {
        const historicalAsset = historicalUpdatedAssets
          ?.find((ha: AmortizationSource) => ha.internalId === asset.internalId);
        if (historicalAsset) {
          return cloneDeep(historicalAsset);
        }
        return asset;
      });
    const newSubledger = {
      ...subledger,
      amortizationSources,
    };
    setSubledger({
      subledger: newSubledger,
      historicalUpdatedAssets,
    });
  } else {
    const existingSubledger = serviceData ? initializedSubledger(
      { subledger: serviceData },
    ) : subledger;
    setSubledger({
      subledger: existingSubledger,
      historicalUpdatedAssets,
    });
  }
};

export const checkSchedulerAmortizationSourceDirtyCheck = (
  asset: AmortizationSource,
  subledger: Subledger,
  initSubledger?: Subledger | undefined,
) => {
  const children = getChildren(asset.internalId, subledger?.amortizationSources);
  if (children?.length > 0) {
    for (const child of children) {
      if (!(child?.amortizationSchedule.destinationId)) {
        return false;
      }
      if (child?.amortizationSchedule?.amortizationScheduleType !== LegacySchedulingMethod.MANUAL) {
        if (isBefore(
          child?.amortizationSchedule?.amortizationEndDate!,
          startOfMonth(asset?.sourceCreationDate!),
        )) {
          return false;
        }
        if (isBefore(
          child?.amortizationSchedule?.amortizationEndDate!,
          startOfMonth(child?.amortizationSchedule.amortizationStartDate!),
        )) {
          return false;
        }
      }
    }
    const { totalBalance } = getTotalBalanceAndAmortizationBalance(children);
    const isBalanceSame = roundTo(Number(totalBalance), 2) === roundTo(Number(asset?.startingBalance), 2);
    if (!isBalanceSame) {
      return false;
    }
  }
  if (children?.length === 0) {
    if (asset?.amortizationSchedule?.amortizationScheduleType !== LegacySchedulingMethod.MANUAL) {
      if (isBefore(
        asset?.amortizationSchedule?.amortizationEndDate!,
        startOfMonth(asset?.sourceCreationDate!),
      )) {
        return false;
      }
      if (isBefore(
        asset?.amortizationSchedule?.amortizationEndDate!,
        startOfMonth(asset?.amortizationSchedule?.amortizationStartDate!),
      )) {
        return false;
      }
    }
    if (!asset?.amortizationSchedule?.destinationId) {
      return false;
    }
  }
  if (initSubledger && !!asset?.status) {
    const existingAsset = initSubledger.amortizationSources
      ?.find((amortizationSource: AmortizationSource) => amortizationSource.id === asset.internalId);
    if (!existingAsset) {
      return true;
    }
    if (!isPrepaidScheduleSame(existingAsset?.amortizationSchedule, asset?.amortizationSchedule)) {
      return true;
    }
    if (existingAsset?.description !== asset?.description || existingAsset?.vendorId !== asset?.vendorId) {
      return true;
    }
    const existingChildren = getChildren(asset.internalId, initSubledger?.amortizationSources);
    if (children?.length !== existingChildren?.length) {
      return true;
    }
    for (const existingChild of existingChildren) {
      const child = subledger?.amortizationSources
        ?.find((prepaidAsset: AmortizationSource) => prepaidAsset.internalId === existingChild.internalId);
      // @ts-ignore
      if (!isPrepaidScheduleSame(existingChild?.amortizationSchedule, child?.amortizationSchedule)) {
        return true;
      }
      if (existingChild?.description !== child?.description || existingChild?.vendorId !== child?.vendorId) {
        return true;
      }
    }
    return false;
  }
  return true;
};

export const getGLBalance = (account: Account) => {
  if ([AccountClassification.Equity, AccountClassification.Liability, AccountClassification.Revenue]
    .includes(account.classification) && account.glBalance !== 0) {
    return account.glBalance * -1;
  }
  return account.glBalance;
};

export const getAccountLabel = (category: ProductCategory) => {
  if (category === ProductCategory.DeferredRevenue) {
    return 'Revenue Account';
  }
  if (category === ProductCategory.PrepaidExpense) {
    return 'Expense Account';
  }
  return '';
};

const revsyncCategoryLabels: CategoryLabel = {
  scheduled: 'Revenue Scheduled',
  scheduleButton: 'Schedule Revenue',
  unposted: 'Unposted Revenue',
  postButton: 'Post Recognition',
  posted: 'Revenue Posted',
  accountLabelText: 'deferred revenue account',
};

const prepaidCategoryLabel: CategoryLabel = {
  scheduled: 'Prepaids Scheduled',
  scheduleButton: 'Schedule Prepaids',
  unposted: 'Amortization Unposted',
  postButton: 'Post Amortization',
  posted: 'Amortization Posted',
  accountLabelText: 'prepaid',
};

const fixedAssetCategoryLabel: CategoryLabel = {
  // TODO will add this list in dashboard story
  scheduled: '',
  scheduleButton: '',
  unposted: '',
  postButton: '',
  posted: '',
  accountLabelText: 'fixed asset',
};

export const getCategoryLabels = (category: ProductCategory) => {
  switch (category) {
    case ProductCategory.DeferredRevenue:
      return revsyncCategoryLabels;
    case ProductCategory.FixedAsset:
      return fixedAssetCategoryLabel;
    default:
      return prepaidCategoryLabel;
  }
};

export const getAccountLabels = (account?: Account | null) => {
  if (account?.accountDetail
    && DEFERRED_REVENUE_ACCOUNTS.includes(account.accountDetail)) {
    return revsyncCategoryLabels;
  } if (account?.accountType === FIXED_ASSETS) {
    return fixedAssetCategoryLabel;
  }
  return prepaidCategoryLabel;
};

export const getHeaderScheduleLabel = (category: ProductCategory) => {
  if (category === ProductCategory.DeferredRevenue) {
    return 'Recognition Schedule';
  }
  if (category === ProductCategory.PrepaidExpense) {
    return 'Amortization Schedule';
  }
  return '';
};

export const getRemainingBalanceForSplitAssets = (
  assets: AmortizationSource[],
  internalId: string,
  updatedAssets?: AmortizationSource[],
) => {
  const currentAsset = assets
    .find((asset: AmortizationSource) => asset.internalId === internalId);
  const currentAssetValue = currentAsset?.startingBalance;

  const parentAsset = assets
    .find((asset: AmortizationSource) => asset.internalId === currentAsset?.parentId);

  if (!parentAsset) {
    return {
      currentAssetValue,
      remainingBalance: 0,
      isParentAsset: true,
    };
  }

  const parentAssetValue = parentAsset?.startingBalance!;

  const splittedAssets = (updatedAssets ? [...updatedAssets] : [...assets])
    .filter((asset: AmortizationSource) => asset.parentId === currentAsset?.parentId);

  const currentEnteredBalanceSum = splittedAssets
    .reduce((acc, cur) => acc + Number(cur?.startingBalance), 0);

  const remainingBalance = roundTo(parentAssetValue - currentEnteredBalanceSum, 2);

  return {
    currentAssetValue,
    remainingBalance: splittedAssets.length === 0 ? 0 : remainingBalance,
  };
};
