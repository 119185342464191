import React from 'react';
import { Box } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SingleRowTable from './SingleRowTable/index';
import AmortizationTable from './AmortizationTable';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '../../Accordion';
import COLORS from '../../../theme/colors';
import {
  ProductCategory,
  Subledger,
} from '../../../interfaces/types';
import DetailsSection from './DetailsSection';
import RevSyncRecognitionTable from './RevSyncRecognitionTable';
import { calculateScheduleAsset } from '../../Subledger/common';

interface Props {
  subledger: Subledger;
  selectedAmortizationSourceId: string | null;
  sourceId?: string;
}

const VerticalScheduleContent = ({
  subledger,
  selectedAmortizationSourceId,
  sourceId,
}: Props) => {
  const [isDetailsExpanded, setIsDetailsExpanded] = React.useState(true);
  const { productCategory } = subledger;

  const selectedSubledger = subledger.amortizationSources
    .find((amortizationSource) => amortizationSource.internalId === selectedAmortizationSourceId);

  if (!selectedSubledger) {
    return (
      <Box>No subledger found</Box>
    );
  }

  calculateScheduleAsset(subledger, selectedSubledger);

  const { amortizationScheduleDetails } = selectedSubledger.amortizationSchedule;

  const selectedSubledgersParent = selectedSubledger?.parentId
    ? subledger.amortizationSources
      .find((amortizationSource) => amortizationSource.internalId === selectedSubledger?.parentId)
    : undefined;

  return (
    <>
      <Accordion
        expanded={isDetailsExpanded}
        onChange={() => setIsDetailsExpanded((prevState) => !prevState)}
      >
        {amortizationScheduleDetails && (
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            VIEW DETAILS
            <ArrowDropDownIcon />
          </AccordionSummary>
        )}
        <AccordionDetails>
          <Box display="flex">
            <DetailsSection
              subledger={subledger}
              selectedSubledger={selectedSubledger}
            />
          </Box>
          <Box
            bgcolor={COLORS.lightGray3}
            borderRadius="4px"
            padding="0 15px"
            marginTop="15px"
          >
            <SingleRowTable
              productCategory={productCategory}
              selectedSubledger={selectedSubledger}
              selectedSubledgersParent={selectedSubledgersParent}
              sourceId={sourceId}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
      {amortizationScheduleDetails && (
        <Box
          overflow="auto"
          height="100%"
        >
          {productCategory === ProductCategory.PrepaidExpense
            ? (
              <AmortizationTable selectedSubledger={selectedSubledger} />
            )
            : (
              <RevSyncRecognitionTable selectedSubledger={selectedSubledger} />
            )}
        </Box>
      )}
    </>
  );
};

export default React.memo(VerticalScheduleContent);
