import React from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  createStyles,
  makeStyles,
  TableCell,
  TableRow,
} from '@material-ui/core';
import clsx from 'clsx';
import {
  getChildren,
  getTotalBalanceAndAmortizationBalance,
} from './common';
import { AmortizationSource } from '../../interfaces/types';
import { useTableStyles } from './Subledger.styled';
import { accountInfoSelector } from '../../store/selectors/account';
import ClassEditableCell from './TableCells/ClassEditableCell';
import AmortizationSourceIdCell from './TableCells/AmortizationSourceIdCell';
import AmortizationSourceCreationCell from './TableCells/AmortizationSourceCreationCell';
import DescriptionReadOnlyCell from './TableCells/DescriptionReadOnlyCell';
import AmortizationStartDateCell from './TableCells/AmortizationStartDateCell';
import AmortizationEndDateCell from './TableCells/AmortizationEndDateCell';
import AmortizationScheduledTypeCell from './TableCells/AmoritizationScheduledTypeCell';
import TotalBalanceCell from './TableCells/TotalBalanceCell';
import ExpenseAccountEditableCell from './ExpenseAccountEditableCell';
import CustomerCell from './TableCells/CustomerCell';
import ProductCell from './TableCells/ProductCell';
import { FactaCheckbox } from '../Checkbox';
import ContextMenu from '../ContextMenu';
import { MoreHorizIcon } from '../Icons';

interface Props {
  asset: AmortizationSource;
  hasChildren?: boolean;
  lastChild?: boolean;
  amortizationSources: Array<AmortizationSource>;
  disabled?: boolean;
  selectedRow: string;
  onRowSelect: (internalId: string) => () => void;
  onSave: (asset: AmortizationSource) => () => Promise<void>;
  onShowDetails: (internalId: string, sourceId: string) => void;
  onSelectAutoCompleteChange: (propertyName: string, internalId: string) => (value: string) => void;
  isSaveEnabled: (asset: AmortizationSource) => boolean;
  showBulkEdit: boolean;
  onCheckboxChange?: (internalId: string) => void;
  isSelectedForBulkEdit?: boolean;
  isSelectable?: boolean;
}

const useStyles = makeStyles(() => createStyles({
  saveButton: {
    minWidth: 70,
  },
  marginSplitIcon: {
    marginLeft: 37,
  },
}));

const PrepaidSchedulerRevSyncCurrentMonthAssetRow = ({
  asset,
  amortizationSources,
  hasChildren,
  lastChild,
  disabled,
  selectedRow,
  onRowSelect,
  onSave,
  onShowDetails,
  onSelectAutoCompleteChange,
  isSaveEnabled,
  showBulkEdit,
  onCheckboxChange,
  isSelectedForBulkEdit,
  isSelectable,
}: Props) => {
  const classes = useStyles();
  const account = useSelector(accountInfoSelector)!;
  const tableClasses = useTableStyles();
  const sourceId = asset.parentId ? '' : asset.sourceId;
  const children = getChildren(asset.internalId, amortizationSources);
  const {
    totalBalance,
  } = hasChildren ? getTotalBalanceAndAmortizationBalance(children) : {
    totalBalance: 0,
  };
  const isRowSelected = selectedRow === asset.parentId || selectedRow === asset.internalId;
  return (
    <TableRow
      hover
      classes={{
        selected: tableClasses.selected,
      }}
      className={clsx({
        [tableClasses.disabledRow]: disabled,
      })}
      onClick={onRowSelect(asset.parentId ?? asset.internalId)}
    >
      {showBulkEdit && (
        <TableCell
          className={clsx({
            [tableClasses.child]: asset.parentId,
            [tableClasses.parent]: hasChildren,
          })}
          is-cell-sticky="true"
        >
          {(!asset?.parentId && onCheckboxChange && typeof isSelectedForBulkEdit !== 'undefined') ? (
            <FactaCheckbox
              checked={isSelectedForBulkEdit}
              disabled={!!hasChildren || isSelectable === false}
              onChange={() => onCheckboxChange(asset.internalId)}
            />
          ) : ''}
        </TableCell>
      )}
      <AmortizationSourceCreationCell
        amortizationSource={asset}
        hasChildren={hasChildren}
        lastChild={lastChild}
      />
      <AmortizationSourceIdCell
        amortizationSource={asset}
        hasChildren={hasChildren}
        lastChild={lastChild}
      />
      <DescriptionReadOnlyCell
        amortizationSource={asset}
        hasChildren={hasChildren}
        lastChild={lastChild}
      />
      <CustomerCell
        amortizationSource={asset}
        hasChildren={hasChildren}
        lastChild={lastChild}
      />
      <ProductCell
        amortizationSource={asset}
        hasChildren={hasChildren}
        lastChild={lastChild}
      />
      <ExpenseAccountEditableCell
        asset={asset}
        hasChildren={hasChildren}
        lastChild={lastChild!}
        isRowSelected={isRowSelected}
        onSelectChange={onSelectAutoCompleteChange}
      />
      {

        (account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) && (
          <ClassEditableCell
            asset={asset}
            hasChildren={hasChildren}
            lastChild={lastChild!}
            isRowSelected={isRowSelected}
            onSelectChange={onSelectAutoCompleteChange}
          />
        )
      }
      <AmortizationStartDateCell
        amortizationSource={asset}
        hasChildren={hasChildren}
        lastChild={lastChild}
      />
      <AmortizationEndDateCell
        amortizationSource={asset}
        hasChildren={hasChildren}
        lastChild={lastChild}
      />
      <AmortizationScheduledTypeCell
        amortizationSource={asset}
        hasChildren={hasChildren}
        lastChild={lastChild}
      />
      <TotalBalanceCell
        amortizationSource={asset}
        hasChildren={hasChildren}
        lastChild={lastChild}
        totalBalance={totalBalance}
      />
      <TableCell
        className={clsx(tableClasses.actionCellWithSave, {
          [tableClasses.child]: asset.parentId,
          [tableClasses.parent]: hasChildren,
          [tableClasses.lastChild]: lastChild,
        })}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <ContextMenu
            menuItems={[
              {
                title: 'View revenue recognition schedule',
                disabled: hasChildren || asset.startingBalance <= 0,
                hidden: !onShowDetails,
                action: () => onShowDetails(asset.internalId, sourceId),
              },
            ]}
          >
            <MoreHorizIcon className={tableClasses.fontSize13} />
          </ContextMenu>
          {!asset.parentId && (
            <Button
              className={clsx(classes.saveButton, {
                [classes.marginSplitIcon]: !hasChildren,
              })}
              variant="contained"
              color="primary"
              size="small"
              onClick={onSave(asset)}
              disabled={!isSaveEnabled(asset)}
            >
              Save
            </Button>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default React.memo(PrepaidSchedulerRevSyncCurrentMonthAssetRow);
