import clsx from 'clsx';
import { TableCell } from '@material-ui/core';
import React from 'react';
import { useTableStyles } from '../Subledger.styled';
import { AmortizationSource } from '../../../interfaces/types';
import currencyFormatter from '../../../util/currencyFormatter';

interface Props {
  amortizationSource: AmortizationSource;
  hasChildren?: boolean;
  lastChild?: boolean;
  highlighted?: boolean;
  showCursor?: boolean;
  totalBalance: number;
}

const TotalBalanceCell = ({
  amortizationSource,
  hasChildren,
  lastChild,
  highlighted,
  showCursor,
  totalBalance,
}: Props) => {
  const tableClasses = useTableStyles();
  return (
    <TableCell
      className={clsx(tableClasses.currencyCell, {
        [tableClasses.child]: amortizationSource.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.bold]: highlighted,
        [tableClasses.lastChild]: lastChild,
        [tableClasses.cursorPointer]: showCursor,
      })}
    >
      {hasChildren && currencyFormatter.format(totalBalance)}
      {!hasChildren && currencyFormatter.format(amortizationSource?.startingBalance!)}
    </TableCell>
  );
};

export default React.memo(TotalBalanceCell);
