import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Box,
  Button,
} from '@material-ui/core';
import startCase from 'lodash.startcase';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import fileDownload from 'js-file-download';
import Papa from 'papaparse';
import TableComponent, { IColProps } from '../../../components/Table';
import { StyledTableCell } from '../../../components/Table/StyledTableCell';
import {
  FilterMatchingType,
  ITableItemType,
  ITableType,
  SortOrder,
} from '../../../interfaces/types';
import { accountBalancesSelector } from '../../../store/selectors/account';
import { accountsBalanceListSelector } from '../../../store/selectors/v2Accounts';
import { subledgersListSelector } from '../../../store/selectors/v2Subledgers';
import { lastV2SyncSelector } from '../../../store/selectors/v2Sync';
import {
  CommonSubledger,
  useAllSubledgers,
} from '../../../hooks/useAllSubledgers';
import currencyFormatter from '../../../util/currencyFormatter';
import { DEFAULT_PAGE_SIZE } from '../../../util/constants';
import { DownloadExportIcon } from '../../../components/Icons';
import {
  getFilteredData,
  getFilterValueByFilterName,
} from '../../../util/filtering';
import { useFilters } from '../../../hooks/useFilters';
import { MultipickerFilter } from '../../../components/Table/Filters/Multipicker';
import { LineClamp } from '../../../components/Table/LineClamp';
import { SubledgersSheetActions } from './SubledgersSheetActions';
import DialogBox from '../../../components/DialogBox';
import { CreateSubledgerForm } from './CreateSubledgerForm';
import { SchedulingMethodForm } from './SchedulingMethodForm';
import VisibilityIcon from '../../../components/Icons/VisibilityIcon';
import HideVisibilityIcon from '../../../components/Icons/HideVisibilityIcon';
import { AppThunkDispatch } from '../../../store/store';
import { getAllAccounts } from '../../../store/slices/v2Accounts';
import { getV2Subledgers } from '../../../store/slices/v2Subledgers';
import { getAccountBalances } from '../../../store/slices/account';
import { Loader } from '../../../components/Loader';
import { getUniqueOptions } from '../../../util/getUniqueOptions';

export const SubledgersSheet = () => {
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [selectedId, setSelectedId] = useState('');
  const [isCreateSubledgerOpen, setIsCreateSubledgerOpen] = useState(false);
  const [showHiddenSubledgers, setShowHiddenSubledgers] = useState(false);
  const v1Accounts = useSelector(accountBalancesSelector);
  const v2Accounts = useSelector(accountsBalanceListSelector);
  const v2Subledgers = useSelector(subledgersListSelector);
  const lastFetch = useSelector(lastV2SyncSelector);

  const subledgers = useAllSubledgers({
    v1Accounts,
    v2Accounts,
    v2Subledgers,
  })
    .filter((subledger) => (showHiddenSubledgers ? true : (subledger.visible && subledger.active)));

  const initialFilters = useMemo(() => ([
    {
      name: 'factaType',
      matching: FilterMatchingType.ARRAY_INCLUDES_PROPERTY,
      propertyPath: ['value'],
    },
    {
      name: 'glType',
      matching: FilterMatchingType.ARRAY_INCLUDES_PROPERTY,
      propertyPath: ['value'],
    },
    {
      name: 'glDetailType',
      matching: FilterMatchingType.ARRAY_INCLUDES_PROPERTY,
      propertyPath: ['value'],
    },
  ]), []);

  const {
    filters,
    setFilter,
    clearAllFilters,
  } = useFilters(initialFilters);

  const {
    filteredData: filteredSubledgers,
    numberOfFiltersApplied,
  } = useMemo(
    () => getFilteredData(subledgers, filters),
    [filters, subledgers],
  );

  const factaTypeOptions = useMemo(() => getUniqueOptions(subledgers, 'factaType'), [subledgers]);
  const glTypeOptions = useMemo(() => getUniqueOptions(subledgers, 'glType'), [subledgers]);
  const glDetailTypeOptions = useMemo(() => getUniqueOptions(subledgers, 'glDetailType'), [subledgers]);

  const onDownload = (sortedTableValues: CommonSubledger[]) => {
    if (sortedTableValues?.length) {
      const acctBalances = sortedTableValues?.map((subledger) => ({
        ...subledger,
        glDetailType: startCase(subledger.glDetailType),
        glBalance: currencyFormatter.format(subledger.glBalance),
        active: subledger.active ? 'Active' : 'Inactive',
      }));
      const csvData = Papa.unparse({
        data: acctBalances,
        fields: [
          'number',
          'accountName',
          'factaType',
          'glType',
          'glDetailType',
          'active',
          'defaultFrequency',
          'glBalance',
        ],
      }, {
        header: false,
      });
      const columns = [
        'Number',
        'Account Name',
        'Facta Type',
        'GL Type',
        'GL Detail Type',
        'Active/Inactive',
        'Default Frequency',
        'GL Balance',
      ];
      const downloadData = `${columns.join(',')}\n${csvData}`;
      fileDownload(downloadData, 'Balance Sheet.csv');
    }
  };

  const handleCreateSubledger = () => {
    setIsCreateSubledgerOpen(true);
  };

  const toggleHiddenSubledgers = () => {
    setShowHiddenSubledgers(!showHiddenSubledgers);
  };

  const defaultSort = {
    key: 'accountName',
    order: SortOrder.DESC,
  };

  const colProps: Array<IColProps> = [
    {
      colName: 'Number',
      varKey: 'number',
      colType: ITableItemType.TEXT,
      child: (value: CommonSubledger) => (
        <StyledTableCell
          key="number"
          minWidth="100"
        >
          {value.number}
        </StyledTableCell>
      ),
    },
    {
      colName: 'Account Name',
      varKey: 'accountName',
      colType: ITableItemType.TEXT,
      child: (value: CommonSubledger) => (
        <StyledTableCell
          key="accountName"
          minWidth="100"
        >
          <LineClamp text={value.accountName} />
        </StyledTableCell>
      ),
    },
    {
      colName: 'Facta Type',
      varKey: 'factaType',
      customHeader: (
        <MultipickerFilter
          _key="factaType"
          label="Facta Type"
          options={factaTypeOptions}
          value={getFilterValueByFilterName(filters, 'factaType')}
          onChange={(filter) => setFilter('factaType', filter)}
        />
      ),
      colType: ITableItemType.TEXT,
      child: (value: CommonSubledger) => (
        <StyledTableCell
          key="factaType"
          minWidth="100"
        >
          <LineClamp text={value.factaType} />
        </StyledTableCell>
      ),
    },
    {
      colName: 'GL Type',
      varKey: 'glType',
      customHeader: (
        <MultipickerFilter
          _key="glType"
          label="GL Type"
          options={glTypeOptions}
          value={getFilterValueByFilterName(filters, 'glType')}
          onChange={(filter) => setFilter('glType', filter)}
        />
      ),
      colType: ITableItemType.TEXT,
      child: (value: CommonSubledger) => (
        <StyledTableCell
          key="glType"
          minWidth="100"
        >
          <LineClamp text={value.glType} />
        </StyledTableCell>
      ),
    },
    {
      colName: 'GL Detail Type',
      varKey: 'glDetailType',
      customHeader: (
        <MultipickerFilter
          _key="glDetailType"
          label="GL Detail Type"
          options={glDetailTypeOptions}
          value={getFilterValueByFilterName(filters, 'glDetailType')}
          onChange={(filter) => setFilter('glDetailType', filter)}
        />
      ),
      colType: ITableItemType.TEXT,
      child: (value: CommonSubledger) => (
        <StyledTableCell
          key="glDetailType"
          minWidth="100"
        >
          <LineClamp text={startCase(value.glDetailType)} />
        </StyledTableCell>
      ),
    },
    {
      colName: 'Default Frequency',
      varKey: 'defaultFrequency',
      colType: ITableItemType.TEXT,
      child: (value: CommonSubledger) => (
        <StyledTableCell
          style={{ position: 'relative' }}
          key="defaultFrequency"
          minWidth="100"
        >
          <SchedulingMethodForm
            commonSubledger={value}
            v1Accounts={v1Accounts}
            v2Subledgers={v2Subledgers}
            setSelectedId={setSelectedId}
          />
        </StyledTableCell>
      ),
    },
    {
      colName: 'GL Balance',
      varKey: 'glBalance',
      colType: ITableItemType.TEXT,
      alignRight: true,
      child: (value: CommonSubledger) => (
        <StyledTableCell
          key="glBalance"
          width={140}
          alignRight
        >
          {currencyFormatter.format(value.glBalance || 0)}
        </StyledTableCell>
      ),
    },
    {
      colName: 'Actions',
      varKey: 'actions',
      colType: ITableItemType.ACTION,
      alignRight: true,
      child: (value: CommonSubledger) => (
        <StyledTableCell
          key="actions"
          width={80}
          alignRight
        >
          <SubledgersSheetActions
            commonSubledger={value}
            setSelectedId={setSelectedId}
            v1Accounts={v1Accounts}
          />
        </StyledTableCell>
      ),
    },
  ];

  const topBarActions = [
    {
      label: 'Create New Subledger',
      icon: <AddCircleRoundedIcon
        color="primary"
        fontSize="small"
      />,
      iconPosition: 'left',
      isData: true,
      action: handleCreateSubledger,
    },
    {
      label: 'Hidden subledgers',
      icon: showHiddenSubledgers ? <VisibilityIcon /> : <HideVisibilityIcon />,
      isInversed: !showHiddenSubledgers,
      isData: false,
      action: toggleHiddenSubledgers,
      pushRight: true,
    },
    {
      label: 'Export',
      icon: <DownloadExportIcon />,
      isData: true,
      action: onDownload,
    },
  ];

  const emptyStateDescription = numberOfFiltersApplied
    ? (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        No results for selected filters
        <Button
          color="primary"
          variant="contained"
          onClick={clearAllFilters}
          style={{ marginTop: 10 }}
        >
          Clear all filters
        </Button>
      </Box>
    )
    : (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        Nothing to see here...
        <Button
          color="primary"
          variant="contained"
          onClick={handleCreateSubledger}
          style={{ marginTop: 10 }}
        >
          Create New Subledger
        </Button>
      </Box>
    );

  useEffect(() => {
    setPage(1);
  }, [filters]);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      reduxDispatch(getAccountBalances()),
      reduxDispatch(getAllAccounts({})),
      reduxDispatch(getV2Subledgers({})),
    ])
      .finally(() => setIsLoading(false));
  }, [reduxDispatch, lastFetch]);

  return (
    <Box
      padding={2}
      width="100%"
      display="flex"
      flexDirection="column"
      flexGrow={1}
    >
      <Loader open={isLoading} />
      <DialogBox
        openDialog={isCreateSubledgerOpen}
        closeDialog={() => setIsCreateSubledgerOpen(false)}
        dialogContext=""
        dialogTitle="Create New Subledger"
        dismissContext="Cancel"
      >
        <CreateSubledgerForm
          closeDialog={() => setIsCreateSubledgerOpen(false)}
          v1Accounts={v1Accounts}
          v2Subledgers={v2Subledgers}
        />
      </DialogBox>
      {!isLoading && (
        <TableComponent
          colProps={colProps}
          tableData={filteredSubledgers}
          defaultSort={defaultSort}
          contentType={ITableType.accounts}
          topBarActions={topBarActions}
          selectedId={selectedId}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          emptyStateDescription={emptyStateDescription}
        />
      )}
    </Box>
  );
};
