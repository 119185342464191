import {
  format,
  parse,
} from 'date-fns';
import {
  DAY_SHORT_FORMAT,
  DIGIT_LOWER_LIMIT,
  DIGIT_UPPER_LIMIT,
  PREPARE_JE_UPDATE_ASSETS,
} from '../../util/constants';
import {
  AmortizationSource,
  AmortizationScheduleDetails,
  LegacySchedulingMethod,
} from '../../interfaces/types';

interface InputBoxProps {
  number: string;
  amortizationSource: AmortizationSource;
  schedule: AmortizationScheduleDetails | undefined;
  lastDay: string;
  amortizationSources: Array<AmortizationSource>;
  dispatch: any;
}

export const prepareJEInputChange = ({
  number, amortizationSource, schedule, lastDay, dispatch, amortizationSources,
}: InputBoxProps) => {
  if (Number(number) >= DIGIT_UPPER_LIMIT || Number(number) <= DIGIT_LOWER_LIMIT) {
    return;
  }
  const amortizationScheduleDetails = amortizationSource?.amortizationSchedule?.amortizationScheduleDetails ?? [];
  const amount = !Number.isNaN(Number(number)) || number.trim() === '-' ? number?.trim() : 'M';
  if (schedule) {
    for (const prepaidAssetsAmortizationSchedule of amortizationScheduleDetails) {
      if (format(prepaidAssetsAmortizationSchedule?.scheduleDate, DAY_SHORT_FORMAT) === lastDay) {
        prepaidAssetsAmortizationSchedule.amount = amount;
      }
    }
  } else {
    const amortizationSchedule: AmortizationScheduleDetails = {
      id: '',
      scheduleDate: parse(lastDay, DAY_SHORT_FORMAT, new Date()), // TODO need to check the value
      amount,
      amortizationScheduleId: amortizationSource.id,
      status: '',
    };
    amortizationScheduleDetails?.push(amortizationSchedule);
  }
  const updatedAssets = amortizationSources?.map((item: AmortizationSource) => {
    if (item?.internalId === amortizationSource?.internalId) {
      return {
        ...item,
        amortizationSchedule: {
          ...item.amortizationSchedule,
          amortizationScheduleDetails,
        },
      };
    }
    return item;
  });
  dispatch({ type: PREPARE_JE_UPDATE_ASSETS, payload: { assets: updatedAssets } });
};

export const isPostJEEnabled = (amortizationSources: Array<AmortizationSource>, scheduleDate: string) => {
  for (const asset of amortizationSources) {
    if (asset.amortizationSchedule?.amortizationScheduleType === LegacySchedulingMethod.MANUAL) {
      if (!asset?.amortizationSchedule?.amortizationScheduleDetails) {
        return false;
      }
      for (const prepaidAssetsAmortizationSchedule of asset?.amortizationSchedule?.amortizationScheduleDetails) {
        if (format(prepaidAssetsAmortizationSchedule?.scheduleDate, DAY_SHORT_FORMAT) === scheduleDate) {
          if (Number.isNaN(Number(prepaidAssetsAmortizationSchedule.amount))
            || prepaidAssetsAmortizationSchedule.amount === '') {
            return false;
          }
        }
      }
    }
  }
  return true;
};
