import React from 'react';
import {
  Backdrop,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import COLORS from '../../theme/colors';

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 1302,
    color: COLORS.black,
  },
}));

interface Props {
  open: boolean;
}

export const Loader = ({ open }: Props) => {
  const classes = useStyles();

  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        open={open}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </div>
  );
};
