import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const v2SyncSelector = (state: RootState) => state.v2Sync;

export const isSyncingSelector = createSelector(
  v2SyncSelector,
  (v2Sync) => v2Sync.isSyncing,
);

export const lastV2SyncSelector = createSelector(
  v2SyncSelector,
  (v2Sync) => v2Sync.lastV2Sync,
);

export const isResettingSelector = createSelector(
  v2SyncSelector,
  (v2Sync) => v2Sync.isResetting,
);
