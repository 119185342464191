import React, { useState } from 'react';
import {
  Box,
  Collapse,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import COLORS from '../../../theme/colors';
import Tag from '../../Tag';
import { TagType } from '../../../interfaces/types';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: '863px',
    padding: '24px',
    borderRadius: '4px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08)',
    '& + &': {
      marginTop: '24px',
    },
  },
  contentWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${COLORS.disabled}`,
    color: 'inherit',
    textDecoration: 'none',
    padding: '24px',
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '11px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    cursor: 'pointer',
    marginRight: 'auto',
  },
  expandIcon: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginRight: '14px',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  titleLink: {
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 500,
    color: COLORS.black,
    '&:hover': {
      color: COLORS.skyBlue,
    },
  },
  subtitle: {
    paddingTop: '8px',
    fontSize: '10px',
    lineHeight: '16px',
    fontWeight: 500,
    color: COLORS.buttonBlack,
  },
  disabled: {
    color: '#B9B9B9',
    pointerEvents: 'none',
  },
}));

interface Report {
  title: string;
  subtitle: string;
  url: string;
  isDisabled?: boolean;
}

interface Props {
  reportsDetails: Report[];
  reportName: string;
  tagType?: TagType;
}

export const ReportsCard = ({
  reportName,
  reportsDetails,
  tagType,
}: Props) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  return (
    <Box className={classes.container}>
      <Box
        display="flex"
        alignItems="flex-start"
      >
        <Typography
          className={classes.header}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <ExpandMoreIcon
            className={clsx(classes.expandIcon, {
              [classes.expandOpen]: !isExpanded,
            })}
          />
          {reportName}
        </Typography>
        {tagType && (
          <Tag tagType={tagType} />
        )}
      </Box>
      <Collapse in={isExpanded}>
        {reportsDetails.map(({
          title,
          subtitle,
          isDisabled,
          url,
        }) => (
          <Box
            key={title}
            className={clsx(classes.contentWrapper, {
              [classes.disabled]: isDisabled,
            })}
          >
            <Typography className={classes.textWrapper}>
              <Link
                to={url}
                className={clsx(classes.titleLink, {
                  [classes.disabled]: isDisabled,
                })}
              >
                {title}
              </Link>
              {subtitle && (
                <span className={classes.subtitle}>
                  {subtitle}
                </span>
              )}
            </Typography>
          </Box>
        ))}
      </Collapse>
    </Box>
  );
};
