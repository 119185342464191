import React, {
  useRef,
  useState,
} from 'react';
import Table from '@material-ui/core/Table';
import {
  Box,
  createStyles,
  makeStyles,
  Tab,
  Tabs,
} from '@material-ui/core';
import {
  ASSET_SCHEDULED_STATUS,
  ASSET_TEMP_SCHEDULED_STATUS,
  DEFAULT_PAGE_SIZE,
  FREEZE_PANEL_Z_INDEX,
} from '../../util/constants';
import {
  AmortizationSource,
  BulkEditorType,
  ProductCategory,
  Subledger,
} from '../../interfaces/types';
import HistoricalSchedulerPrepaidExpenses from './HistoricalSchedulerPrepaidExpenses';
import HistoricalSchedulerRevSyncTransactions from './HistoricalSchedulerRevSyncTransactions';
import {
  ScrollableBoxContainer,
  ScrollableTableContainer,
} from '../ScrollableTable';
import VerticalSchedule from '../Flyovers/VerticalSchedule';
import { BulkEdit } from '../BulkEdit';
import { featureSwitch } from '../../util/featureSwitch';
import { Pagination } from '../Pagination';
import COLORS from '../../theme/colors';

interface Props {
  subledger: Subledger;
  selectedRow: string;
  onSave: (asset: AmortizationSource) => () => Promise<void>;
  onRowSelect: (internalId: string) => () => void;
  scheduleDate: string;
  isSaveEnabled: (asset: AmortizationSource) => boolean;
  onDateChange: (propertyName: string, internalId: string, value: Date | null) => void;
  onAutoCompleteChange: (propertyName: string, internalId: string) => (value: string) => void;
  onInputBoxChange: (propertyName: string, internalId: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  onInputBoxFocus: (propertyName: string, internalId: string) => (event: React.FocusEvent<HTMLInputElement>) => void;
  addSplit: (internalId: string) => () => void;
  addSplitAsset: (internalId: string) => () => void;
  removeAsset: (internalId: string) => () => void;
  onBulkEditApply: (internalIds: string[], changedProperties: any) => void;
  onEditClose: () => void;
  isElementSelected: (internalId: string) => boolean;
  onSelectAll: (assetsType: BulkEditorType) => void;
  isAllSelected: (assetsType: BulkEditorType) => boolean;
  onCheckboxChange: (internalId: string) => void;
  selectedSources: string[];
  onBulkEditSchedule: (internalIds: string[], changedProperties: any) => void;
  bulkEditorType: BulkEditorType;
}

const useStyles = makeStyles(() => createStyles({
  table: {
    minWidth: 1560,
    marginBottom: 0,
    zIndex: FREEZE_PANEL_Z_INDEX,
  },
  tabs: {
    borderBottom: `1px solid ${COLORS.disabled}`,
    padding: '0 32px',
  },
}));

const HistoricalSchedulerTable = ({
  subledger,
  selectedRow,
  onSave,
  onRowSelect,
  scheduleDate,
  isSaveEnabled,
  onDateChange,
  onAutoCompleteChange,
  onInputBoxChange,
  onInputBoxFocus,
  removeAsset,
  addSplitAsset,
  addSplit,
  onBulkEditApply,
  onEditClose,
  isElementSelected,
  onSelectAll,
  isAllSelected,
  onCheckboxChange,
  selectedSources,
  onBulkEditSchedule,
  bulkEditorType,
}: Props) => {
  const classes = useStyles();
  const scrollRef = useRef(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [tab, setTab] = React.useState(BulkEditorType.UNSCHEDULED);

  const [flyover, setFlyover] = useState<{
    isOpen: boolean;
    internalId: null | string;
    sourceId?: string;
  }>({
    isOpen: false,
    internalId: null,
    sourceId: undefined,
  });

  const showBulkEdit = featureSwitch.bulkEdit;

  const onShowDetails = (internalId: string, sourceId?: string) => {
    setFlyover({ isOpen: true, internalId, sourceId });
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: BulkEditorType) => {
    setTab(newValue);
    setPage(1);
  };

  const unscheduledAmortizationSources = subledger?.amortizationSources
    ?.filter((amortizationSource: AmortizationSource) => !amortizationSource.status);

  const scheduledAmortizationSources = subledger?.amortizationSources
    ?.filter((amortizationSource: AmortizationSource) => [ASSET_SCHEDULED_STATUS, ASSET_TEMP_SCHEDULED_STATUS]
      .includes(amortizationSource.status));

  const amortizationSources = tab === BulkEditorType.UNSCHEDULED
    ? unscheduledAmortizationSources
    : scheduledAmortizationSources;

  const unscheduledAmortizationSourcesLength = unscheduledAmortizationSources
    ?.filter((amortizationSource) => !amortizationSource.parentId).length || 0;

  const scheduledAmortizationSourcesLength = scheduledAmortizationSources
    ?.filter((amortizationSource) => !amortizationSource.parentId).length || 0;

  React.useEffect(() => {
    setTab(!unscheduledAmortizationSourcesLength && !!scheduledAmortizationSourcesLength
      ? BulkEditorType.SCHEDULED
      : BulkEditorType.UNSCHEDULED);
  }, [unscheduledAmortizationSourcesLength, scheduledAmortizationSourcesLength]);

  return (
    <>
      <Box
        width="100%"
        padding="0 16px"
      >
        <Tabs
          value={tab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="off"
          className={classes.tabs}
        >
          <Tab
            label={`Unscheduled (${unscheduledAmortizationSourcesLength})`}
            value={BulkEditorType.UNSCHEDULED}
            disableRipple
          />
          <Tab
            label={`Scheduled (${scheduledAmortizationSourcesLength})`}
            value={BulkEditorType.SCHEDULED}
            disableRipple
          />
        </Tabs>
      </Box>
      <ScrollableBoxContainer>
        <ScrollableTableContainer
          scrollRef={scrollRef}
          removeBottomPadding
        >
          <Table
            size="small"
            stickyHeader
            aria-label="Scheduler Table"
            className={classes.table}
          >
            {subledger?.productCategory === ProductCategory.PrepaidExpense && (
              <HistoricalSchedulerPrepaidExpenses
                selectedRow={selectedRow}
                subledgerId={subledger?.id}
                amortizationSources={amortizationSources}
                onSave={onSave}
                onRowSelect={onRowSelect}
                scheduleDate={scheduleDate}
                isSaveEnabled={isSaveEnabled}
                onInputBoxChange={onInputBoxChange}
                onInputBoxFocus={onInputBoxFocus}
                removeAsset={removeAsset}
                onDateChange={onDateChange}
                onAutoCompleteChange={onAutoCompleteChange}
                addSplitAsset={addSplitAsset}
                addSplit={addSplit}
                onShowDetails={onShowDetails}
                onCheckboxChange={onCheckboxChange}
                showBulkEdit={showBulkEdit}
                checkIsElementSelected={isElementSelected}
                onSelectAll={onSelectAll}
                isAllSelected={isAllSelected}
                bulkEditorType={bulkEditorType}
                page={page - 1}
                pageSize={pageSize}
                tab={tab}
              />
            )}
            {subledger?.productCategory === ProductCategory.DeferredRevenue && (
              <HistoricalSchedulerRevSyncTransactions
                selectedRow={selectedRow}
                subledgerId={subledger?.id}
                amortizationSources={amortizationSources}
                onSave={onSave}
                onRowSelect={onRowSelect}
                scheduleDate={scheduleDate}
                isSaveEnabled={isSaveEnabled}
                onInputBoxChange={onInputBoxChange}
                onInputBoxFocus={onInputBoxFocus}
                removeAsset={removeAsset}
                onDateChange={onDateChange}
                onAutoCompleteChange={onAutoCompleteChange}
                addSplitAsset={addSplitAsset}
                addSplit={addSplit}
                onShowDetails={onShowDetails}
                onCheckboxChange={onCheckboxChange}
                showBulkEdit={showBulkEdit}
                checkIsElementSelected={isElementSelected}
                onSelectAll={onSelectAll}
                isAllSelected={isAllSelected}
                bulkEditorType={bulkEditorType}
                page={page - 1}
                pageSize={pageSize}
                tab={tab}
              />
            )}
          </Table>
        </ScrollableTableContainer>
      </ScrollableBoxContainer>
      {showBulkEdit && (
        <BulkEdit
          productCategory={subledger?.productCategory}
          onClose={onEditClose}
          onBulkEditApply={onBulkEditApply}
          onBulkEditSchedule={onBulkEditSchedule}
          selectedSources={selectedSources}
          isOpen={selectedSources.length > 1}
          isFullyEditable={false}
          bulkEditorType={bulkEditorType}
          isAllSelected={isAllSelected(tab)}
        />
      )}
      <Pagination
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        length={amortizationSources?.filter((source) => !source.parentId).length || 0}
        scrollRef={scrollRef}
        hidePaginationOptions
      />
      <VerticalSchedule
        isOpen={flyover.isOpen}
        setOpen={setFlyover}
        internalId={flyover.internalId}
        sourceId={flyover.sourceId}
        subledger={subledger}
      />
    </>
  );
};

export default HistoricalSchedulerTable;
