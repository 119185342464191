import React from 'react';
import styled from 'styled-components';
import COLORS from '../../../theme/colors';

const StyledErrorAnnotate = styled.div`
  color: ${COLORS.error};
  font-size: 10px;
  padding: 8px 0;
  line-height: 16px;
  max-width: 260px;
`;

interface Props {
  error: {
    message: string;
  };
}

export const ErrorAnnotate = ({ error }: Props) => {
  if (!error) return null;

  return (
    <StyledErrorAnnotate>
      {error.message}
    </StyledErrorAnnotate>
  );
};
