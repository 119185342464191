import React from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
  isBefore,
  isSameDay,
} from 'date-fns';
import {
  AccountInfo,
  AmortizationSource,
  LegacySchedulingMethod,
} from '../../interfaces/types';
import { useTableStyles } from './Subledger.styled';
import CreateSubledgerAmortizationSchedules from './CreateSubledgerAmortizationSchedules';
import AmortizationScheduledTypeCell from './TableCells/AmoritizationScheduledTypeCell';
import AmortizationStartDateCell from './TableCells/AmortizationStartDateCell';
import AmortizationEndDateCell from './TableCells/AmortizationEndDateCell';
import DescriptionEditableCell from './TableCells/DescriptionEditableCell';
import CurrencyEditableCell from './TableCells/CurrencyEditableCell';
import ContextMenu from '../ContextMenu';
import FactaAutocomplete from '../FactaAutocomplete';
import {
  getAccountById,
  getAccountClassById,
  getParent,
  getVendorById,
} from './common';
import { FactaCheckbox } from '../Checkbox';
import { MoreHorizIcon } from '../Icons';

const useStyles = makeStyles(() => createStyles({
  table: {
    minWidth: 1560,
  },
  fontSize13: {
    fontSize: 13,
  },
  exportIcon: {
    width: 13,
    height: 13,
    marginLeft: 8,
  },
  arrowIcon: {
    fontSize: 12,
    textTransform: 'none',
    height: 14,
    marginTop: 8,
    marginBottom: 8,
  },
  bold: {
    fontWeight: 'bold',
  },
}));

interface Props {
  selectedRow: string;
  amortizationSource: AmortizationSource;
  hoverRow: string;
  onRowSelected: () => void;
  hasChildren?: boolean;
  index: number;
  formSubmit?: boolean;
  onVendorChange: (value: string) => void;
  account: AccountInfo;
  onExpenseAccountChange: (value: string) => void;
  onClassChange: (value: string) => void;
  onDateChange: (propertyName: string, internalId: string, value: Date | null) => void;
  onRemoveAsset: () => void;
  onAddAsset: () => void;
  startDate: Date;
  endDate: Date | null;
  amortizationSources: Array<AmortizationSource>;
  lastChild?: boolean;
  onSelectChange: (propertyName: string, internalId: string) => (value: string) => void;
  onInputBoxChange: (propertyName: string, internalId: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  onInputBoxFocus: (propertyName: string, internalId: string) => (event: React.FocusEvent<HTMLInputElement>) => void;
  factaStartDate: Date | null;
  isCollapsedView: boolean;
  isSplitAssetsSumCorrect: boolean;
  onShowDetails: (sourceId: string) => void;
  onCheckboxChange: (internalId: string) => void;
  isSelectedForBulkEdit: boolean;
  showBulkEdit: boolean;
}

const CreateSubledgerPrepaidExpenseSelectedRow = ({
  onRemoveAsset,
  isSelectedForBulkEdit,
  onRowSelected,
  index,
  hoverRow,
  account,
  hasChildren,
  amortizationSource,
  selectedRow,
  onDateChange,
  onInputBoxChange,
  onInputBoxFocus,
  formSubmit,
  onVendorChange,
  onExpenseAccountChange,
  onClassChange,
  onAddAsset,
  startDate,
  endDate,
  amortizationSources,
  lastChild,
  onSelectChange,
  factaStartDate,
  isCollapsedView,
  isSplitAssetsSumCorrect,
  onShowDetails,
  onCheckboxChange,
  showBulkEdit,
}: Props) => {
  const tableClasses = useTableStyles();
  const classes = useStyles();
  const parentSourceId = getParent(amortizationSource.parentId, amortizationSources)?.sourceId;
  const sourceId = amortizationSource.parentId ? '' : `Open ${index + 1}`;
  const { vendors, accountIncomes, accountClasses } = account;

  return (
    <TableRow
      hover={false}
      selected={
        isSelectedForBulkEdit
        || selectedRow === amortizationSource.internalId
        || (hoverRow === amortizationSource.internalId
          || amortizationSource.parentId === selectedRow)
      }
      onClick={onRowSelected}
      classes={{
        selected: tableClasses.selected,
      }}
    >
      {showBulkEdit && (
        <TableCell
          className={clsx({
            [tableClasses.child]: amortizationSource.parentId,
            [tableClasses.parent]: hasChildren,
          })}
          is-cell-sticky="true"
        >
          {!amortizationSource?.parentId ? (
            <FactaCheckbox
              checked={isSelectedForBulkEdit}
              disabled={!!hasChildren}
              onChange={() => onCheckboxChange(amortizationSource.internalId)}
            />
          ) : ''}
        </TableCell>
      )}
      <TableCell
        className={clsx(tableClasses.assetCell, {
          [tableClasses.child]: amortizationSource.parentId,
          [tableClasses.parent]: hasChildren,
        })}
        is-cell-sticky="true"
      >
        {amortizationSource.parentId ? '' : `Open ${index + 1}`}
      </TableCell>
      <DescriptionEditableCell
        formSubmit={formSubmit}
        amortizationSource={amortizationSource}
        hasChildren={hasChildren}
        onInputBoxChange={onInputBoxChange}
        isCellSticky="true"
      />
      {!isCollapsedView && (
        <>
          <TableCell
            className={clsx(tableClasses.autocompleteVirtualizedCell, {
              [tableClasses.child]: amortizationSource.parentId,
              [tableClasses.parent]: hasChildren,
            })}
            is-cell-sticky="true"
          >
            {!amortizationSource.parentId && (
              <FactaAutocomplete
                className={clsx(tableClasses.virtualizedSelectBox)}
                defaultValue={amortizationSource.vendorId}
                onChange={onVendorChange}
                options={vendors}
                optionName="displayName"
                selectedItem={getVendorById(amortizationSource.vendorId, vendors) || null}
                isVirtualizedList
              />
            )}
          </TableCell>
          <TableCell
            className={clsx(tableClasses.expenseAccountCell, {
              [tableClasses.child]: amortizationSource.parentId,
              [tableClasses.parent]: hasChildren,
            })}
            is-cell-sticky="true"
          >
            {!hasChildren && (
              <FactaAutocomplete
                error={!amortizationSource.amortizationSchedule?.destinationId && formSubmit}
                className={clsx(tableClasses.expenseAccountSelectBox)}
                defaultValue={amortizationSource?.amortizationSchedule?.destinationId!}
                onChange={onExpenseAccountChange}
                options={accountIncomes}
                optionName="displayName"
                selectedItem={
                  getAccountById(amortizationSource?.amortizationSchedule?.destinationId!, accountIncomes) || null
                }
              />
            )}
            {hasChildren
              && (
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Typography className={classes.fontSize13}>Multiple</Typography>
                  <ArrowDropDownIcon />
                </Box>
              )}
          </TableCell>
          {(account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) && (
            <TableCell
              className={clsx(tableClasses.classesCell, {
                [tableClasses.child]: amortizationSource.parentId,
                [tableClasses.parent]: hasChildren,
              })}
              is-cell-sticky="true"
            >
              {!hasChildren && (
                <FactaAutocomplete
                  className={clsx(tableClasses.classSelectBox)}
                  defaultValue={amortizationSource?.amortizationSchedule?.classId!}
                  onChange={onClassChange}
                  options={accountClasses}
                  optionName="className"
                  selectedItem={
                    getAccountClassById(amortizationSource?.amortizationSchedule?.classId!, accountClasses) || null
                  }
                />
              )}
            </TableCell>
          )}
        </>
      )}
      <AmortizationStartDateCell
        amortizationSource={amortizationSource}
        hasChildren={hasChildren}
        isRowSelected
        onDateChange={onDateChange}
        minDate={factaStartDate}
        hasError={
          Boolean(amortizationSource.amortizationSchedule?.amortizationScheduleType !== LegacySchedulingMethod.MANUAL
          && factaStartDate
          && amortizationSource.amortizationSchedule?.amortizationStartDate
          && isBefore(amortizationSource.amortizationSchedule?.amortizationStartDate!, factaStartDate)
          && !isSameDay(amortizationSource.amortizationSchedule?.amortizationStartDate!, factaStartDate))
        }
        isCellSticky="true"
      />
      <AmortizationEndDateCell
        amortizationSource={amortizationSource}
        hasChildren={hasChildren}
        isRowSelected
        onDateChange={onDateChange}
        isCellSticky="true"
      />
      {!isCollapsedView && (
        <AmortizationScheduledTypeCell
          error={!amortizationSource.amortizationSchedule.amortizationScheduleType && formSubmit}
          amortizationSource={amortizationSource}
          hasChildren={hasChildren}
          isRowSelected
          onSelectChange={onSelectChange}
          isCellSticky="true"
        />
      )}
      <CurrencyEditableCell
        amortizationSource={amortizationSource}
        hasChildren={hasChildren}
        onInputBoxChange={onInputBoxChange}
        onInputBoxFocus={onInputBoxFocus}
        hasError={!isSplitAssetsSumCorrect}
        isCellSticky="true"
      />
      <TableCell
        className={clsx({
          [tableClasses.child]: amortizationSource.parentId,
          [tableClasses.parent]: hasChildren,
        })}
        is-cell-sticky="true"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <ContextMenu
            menuItems={[
              {
                title: 'View amortization schedule',
                disabled: hasChildren || amortizationSource.startingBalance <= 0,
                action: () => onShowDetails(parentSourceId || sourceId),
              },
              {
                title: 'Split asset',
                action: onAddAsset,
                hidden: !!amortizationSource.parentId,
                disabled: hasChildren || amortizationSource.startingBalance <= 0,
              },
              {
                title: 'Remove asset',
                action: onRemoveAsset,
              },
            ]}
          >
            <MoreHorizIcon className={tableClasses.fontSize13} />
          </ContextMenu>
        </Box>
      </TableCell>
      <CreateSubledgerAmortizationSchedules
        startDate={startDate}
        endDate={endDate}
        asset={amortizationSource}
        amortizationSources={amortizationSources}
        lastChild={lastChild}
      />
    </TableRow>
  );
};

export default React.memo(CreateSubledgerPrepaidExpenseSelectedRow);
