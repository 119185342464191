import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  DEFERRED_REVENUE_ACCOUNTS,
  PREPAID_EXPENSES,
} from '../../util/constants';

const accountSelector = (state: RootState) => state.account;

export const accountLastFetchSelector = createSelector(
  accountSelector,
  (account) => account.lastFetch,
);

export const accountInfoSelector = createSelector(
  accountSelector,
  (account) => account.accountInfo,
);

export const isFetchingAccountInfo = createSelector(
  accountSelector,
  (account) => account.isFetching,
);

export const fetchingAccountError = createSelector(
  accountSelector,
  (account) => account.error,
);

export const accountBalancesSelector = createSelector(
  accountSelector,
  (account) => account.accountInfo?.accountBalances,
);

export const accountIncomesSelector = createSelector(
  accountSelector,
  (account) => account.accountInfo?.accountIncomes,
);

export const accountClassesSelector = createSelector(
  accountSelector,
  (account) => account.accountInfo?.accountClasses,
);

export const isClassTrackingEnabledSelector = createSelector(
  accountSelector,
  (account) => account.accountInfo?.classTrackingPerTxnLine || account.accountInfo?.classTrackingPerTxn,
);

export const isCompanyAvailableSelector = createSelector(
  accountSelector,
  (account) => account.isCompanyAvailable,
);

export const prepaidAccountFinalizedSubledgerListSelector = createSelector(
  accountSelector,
  (account) => account.accountInfo?.accountBalances?.filter((accountInfo) => (
    accountInfo?.accountDetail === PREPAID_EXPENSES
    && accountInfo.subledger.id && accountInfo.subledger.status === 'FINALIZED'
    && accountInfo.subledger.subledgerAmortizationLogs?.length
  )),
);

export const defRevenueFinalizedAccountSubledgerListSelector = createSelector(
  accountSelector,
  (account) => account.accountInfo?.accountBalances?.filter((accountInfo) => (
    DEFERRED_REVENUE_ACCOUNTS.includes(accountInfo.accountDetail)
    && accountInfo.subledger.id && accountInfo.subledger.status === 'FINALIZED'
    && accountInfo.subledger.subledgerAmortizationLogs?.length
  )),
);
