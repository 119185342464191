import CheckIcon from '@material-ui/icons/Check';
import Box from '@material-ui/core/Box';
import React from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';

import clsx from 'clsx';
import currencyFormatter from '../../../util/currencyFormatter';
import {
  CompletedTasksLabel,
  CurrencyLabel,
} from '../Dashboard.styled';
import cardStyles from './cardStyles.styled';

interface Props {
  name: string;
  amount: number;
  completed?: boolean;
  onCurrencyNavigation?: () => void;
  enableCurrencyNavigation?: boolean;
}

const TaskReadOnlyDetail = ({
  name,
  amount,
  completed = false,
  onCurrencyNavigation,
  enableCurrencyNavigation = false,
}: Props) => {
  const classes = cardStyles();
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      width="100%"
      padding="5px"
      margin="0px !important"
    >
      <CompletedTasksLabel>
        { name }
        { completed && <CheckIcon className={classes.checkCompleted} /> }
      </CompletedTasksLabel>
      <ButtonBase
        disabled={!enableCurrencyNavigation}
        onClick={onCurrencyNavigation}
        className={clsx({
          [classes.navigatePrepaid]: enableCurrencyNavigation,
        })}
      >
        <CurrencyLabel className={classes.schedule}>
          {currencyFormatter.format(amount)}
        </CurrencyLabel>
      </ButtonBase>
    </Box>
  );
};

export default React.memo(TaskReadOnlyDetail);
