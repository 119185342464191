import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import {
  Box,
  createStyles,
  makeStyles,
  TableContainer,
} from '@material-ui/core';

const MINIMUM_SCROLLABLE_HEIGHT = 200;

const useStyles = makeStyles(() => createStyles({
  scrollableBoxContainer: {
    margin: '0 0 16px',
    padding: '0 16px',
    position: 'relative',
    flexGrow: 1,
    width: '100%',
  },
  tableContainer: {
    height: '100%',
    '& table': {
      marginBottom: '50px',
    },
  },
  removeBottomPadding: {
    '& table': {
      marginBottom: 0,
    },
  },
  scrollableTableContainer: {
    top: 0,
    width: 'calc(100% - 32px)',
    bottom: 0,
    position: 'absolute',
    scrollBehavior: 'smooth',
    overflow: 'auto',
  },
}));

interface ScrollableTableContainerProps {
  scrollRef?: React.MutableRefObject<HTMLDivElement | null>;
  style?: React.CSSProperties;
  children: React.ReactNode;
  removeBottomPadding?: boolean;
}

interface ScrollableBoxContainerProps {
  style?: React.CSSProperties;
  children: React.ReactNode;
}

export const ScrollableBoxContainer = ({ style, children }: ScrollableBoxContainerProps) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.scrollableBoxContainer}
      style={style}
    >
      {children}
    </Box>
  );
};

export const ScrollableTableContainer = ({
  scrollRef,
  style,
  children,
  removeBottomPadding,
}: ScrollableTableContainerProps) => {
  const classes = useStyles();
  const [isScrollable, setIsScrollable] = useState(true);
  const containerBoxRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

  useEffect(() => {
    const containerHeight = scrollRef?.current?.clientHeight || containerBoxRef?.current?.clientHeight || 0;
    setIsScrollable(containerHeight > MINIMUM_SCROLLABLE_HEIGHT);
  }, [scrollRef, containerBoxRef]);

  return (
    <TableContainer
      ref={scrollRef || containerBoxRef}
      className={clsx(classes.tableContainer, {
        [classes.scrollableTableContainer]: isScrollable,
        [classes.removeBottomPadding]: removeBottomPadding,
      })}
      style={style}
    >
      {children}
    </TableContainer>
  );
};
