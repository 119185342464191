import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useHistory } from 'react-router';
import find from 'lodash.find';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  addDays,
  addMonths,
  addYears,
  format,
  formatISO,
  parseISO,
  startOfMonth,
} from 'date-fns';
import {
  Box,
  Button,
} from '@material-ui/core';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import TableComponent from '../../../Table';
import {
  FilterMatchingType,
  ITableItemType,
  ITableType,
  ProductCategory,
  SortOrder,
} from '../../../../interfaces/types';
import COLORS from '../../../../theme/colors';
import { useFilters } from '../../../../hooks/useFilters';
import {
  getFilteredData,
  getFilterValueByFilterName,
} from '../../../../util/filtering';
import { TextSearchFilter } from '../../../Table/Filters/TextSearch';
import { AmountFilter } from '../../../Table/Filters/Amount';
import { MultipickerFilter } from '../../../Table/Filters/Multipicker';
import { DatepickerFilter } from '../../../Table/Filters/Datepicker';
import {
  AUTOSAVE_DELAY,
  DEFAULT_PAGE_SIZE,
  FULL_DATE_FORMAT,
  HIGHLIGHT_RESET,
} from '../../../../util/constants';
import { AppThunkDispatch } from '../../../../store/store';
import currencyFormatter from '../../../../util/currencyFormatter';
import { StyledTableCell } from '../../../Table/StyledTableCell';
import {
  bulkUpdateFixedAssets,
  createFixedAsset,
  removeFixedAssets,
  updateFixedAsset,
} from '../../../../store/slices/v2Subledgers';
import {
  FixedAsset,
  FixedAssetForm,
  SaveMethodProps,
} from '../../../../interfaces/fixedAssets';
import {
  fixedAssetsListWithoutQBOSelector,
  isFetchingFixedAssets,
  isUpdatingSilentlyFixedAssets,
  updatedAtFixedAssets,
} from '../../../../store/selectors/v2Subledgers';
import { vendorsListSelector } from '../../../../store/selectors/v2contacts';
import { assetTypesSelector } from '../../../../store/selectors/assetTypes';
import FactaAutocomplete from '../../../Inputs/FactaAutocomplete';
import { NumberInput } from '../../../Inputs/NumberInput';
import FactaDatePicker from '../../../DatePicker';
import { FormInput } from '../../../Inputs/FormInput';
import { SaveCell } from './SaveCell';
import { SchedulingMethod } from '../../../../interfaces/common';
import { useDebouncedEffect } from '../../../../hooks/useDebouncedEffect';
import { Autosaved } from '../../../Autosaved';
import {
  AssetType,
  AssetTypeForm,
  UsefulLifeUnits,
} from '../../../../interfaces/fixedAssetTypes';
import { schedulingMethodOptions } from '../../../../util/helpers';
import CSVImportWizard from '../../../Flyovers/CSVImportWizard';
import { V2Subledger } from '../../../../interfaces/subledgers';
import {
  accountsListSelector,
} from '../../../../store/selectors/v2Accounts';
import {
  DeleteIcon,
  ImportCSVIcon,
} from '../../../Icons';
import { Loader } from '../../../Loader';
import { RemainingLifeFilter } from '../../../Table/Filters/RemainingLife';
import { calculateRemainingLife } from '../../../../util/pages/FixedAssets/calculateRemainingLife';
import { BulkEdit } from '../../../BulkEdit';
import { FactaCheckbox } from '../../../Checkbox';
import DialogBox from '../../../DialogBox';
import { Contact } from '../../../../interfaces/contacts';
import { LoadingBox } from '../../../LoadingBox';
import AddEditAssetType from '../../../Flyovers/AddEditAssetType';
import { DEFAULT_ASSET_TYPE } from '../../../../util/pages/FixedAssets/assetTypes';
import { precisionRound } from '../../../../util/math';
import { IdleTableCell } from '../../../Table/IdleTableCell';

interface Props {
  subledgerId: string;
  subledgerDetails: V2Subledger;
  disabled?: boolean;
  preselectedAssetId?: string;
  setIsEditingTable: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FixedAssetsCreateEditTable = ({
  subledgerId,
  subledgerDetails,
  disabled,
  preselectedAssetId,
  setIsEditingTable,
}: Props) => {
  const history = useHistory();
  const [selectedRow, setSelectedRow] = useState<string | undefined>(undefined);
  const [updatingId, setUpdatingId] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const addedRowRef = useRef<HTMLTableRowElement>(null);
  const preselectedRowRef = useRef<HTMLTableRowElement>(null);
  const [flyover, setFlyover] = useState(false);
  const [assetTypeFlyover, setAssetTypeFlyover] = useState<{
    isOpen: boolean;
    assetType: AssetTypeForm;
  }>({
    isOpen: false,
    assetType: DEFAULT_ASSET_TYPE,
  });
  const [selectedSources, setSelectedSources] = useState<string[]>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false);
  const [isNoAssetTypesWarningOpen, setIsNoAssetTypesWarningOpen] = useState(false);
  const [highlightedId, setHighlightedId] = useState(preselectedAssetId);
  const [selectedByTabbing, setSelectedByTabbing] = useState(false);

  const {
    startDate,
    accountId,
    defaultSchedulingMethod,
  } = subledgerDetails;
  const [autosaveData, setAutosaveData] = useState<{
    assetId?: string;
    data?: FixedAssetForm;
  }>({ assetId: undefined, data: undefined });

  const accounts = useSelector(accountsListSelector);
  const vendors = useSelector(vendorsListSelector);
  const fixedAssetTypes = useSelector(assetTypesSelector);
  const allFixedAssets = useSelector(fixedAssetsListWithoutQBOSelector)
    .map((asset) => ({
      ...asset,
      remainingLife: calculateRemainingLife(asset.inServiceDate, asset.fullyDepreciatedDate),
    }));
  const isFetching = useSelector(isFetchingFixedAssets);
  const isUpdatingSilently = useSelector(isUpdatingSilentlyFixedAssets);
  const updatedAt = useSelector(updatedAtFixedAssets);

  const yupSchema = () => yupResolver(yup.object().shape({
    number: yup.string(),
    description: yup.string(),
    vendorContact: yup.object({}).notRequired(),
    fixedAssetType: yup.object({
      name: yup.string().required(),
    }).required(),
    originalValue: yup.number().required(),
    priorDepreciation: yup.number(),
    schedulingMethod: yup.object({
      value: yup.string().required(),
    }).required(),
    inServiceDate: yup.date().nullable()
      .when('schedulingMethod', {
        is: (method) => method?.value === SchedulingMethod.MANUAL,
        then: yup.date().notRequired(),
        otherwise: yup.date().required()
          .min(parseISO(startDate!),
            `Amortization start date must be later or equal ${format(parseISO(startDate!), FULL_DATE_FORMAT)}`),
      }),
    fullyDepreciatedDate: yup.date().nullable()
      .notRequired()
      .when('inServiceDate', {
        is: (val) => val,
        then: yup.date().min(yup.ref('inServiceDate'),
          'Amortization end date must be later or equal start date'),
      }),
  }));

  const {
    control,
    errors,
    setValue,
    getValues,
    trigger,
    formState,
    reset,
  } = useForm({
    mode: 'onBlur',
    resolver: yupSchema(),
    shouldUnregister: true,
  });

  const initialFilters = useMemo(() => ([
    {
      name: 'number',
      matching: FilterMatchingType.STRING_INCLUDES,
    },
    {
      name: 'description',
      matching: FilterMatchingType.STRING_INCLUDES,
    },
    {
      name: 'vendorContactId',
      matching: FilterMatchingType.ARRAY_INCLUDES_PROPERTY,
      propertyPath: ['id'],
    },
    {
      name: 'fixedAssetTypeId',
      matching: FilterMatchingType.ARRAY_INCLUDES_PROPERTY,
      propertyPath: ['id'],
    },
    {
      name: 'originalValue',
      matching: FilterMatchingType.NUMBER_COMPARE,
    },
    {
      name: 'priorDepreciation',
      matching: FilterMatchingType.NUMBER_COMPARE,
    },
    {
      name: 'nbv',
      matching: FilterMatchingType.NUMBER_COMPARE,
    },
    {
      name: 'inServiceDate',
      matching: FilterMatchingType.DATE_RANGE,
    },
    {
      name: 'fullyDepreciatedDate',
      matching: FilterMatchingType.DATE_RANGE,
    },
    {
      name: 'schedulingMethod',
      matching: FilterMatchingType.ARRAY_INCLUDES_PROPERTY,
      propertyPath: ['value'],
    },
    {
      name: 'remainingLife',
      matching: FilterMatchingType.LIFE_COMPARE,
    },
  ]), []);

  const {
    filters,
    setFilter,
    clearAllFilters,
  } = useFilters(initialFilters);

  const {
    filteredData,
    numberOfFiltersApplied,
  } = useMemo(
    () => getFilteredData(allFixedAssets, filters),
    [filters, allFixedAssets],
  );

  const filteredFixedAssets = filteredData as unknown as FixedAsset[];

  const calculateRemainingLifeFromFixedAssetType = (inServiceDate: Date, fixedAssetTypeId: string) => {
    const assetType = find(fixedAssetTypes, { id: fixedAssetTypeId });
    const {
      usefulLifeUnits,
      usefulLifeValue,
    } = assetType!;

    const endDate = usefulLifeUnits === UsefulLifeUnits.YEARS
      ? addDays(addYears(inServiceDate, usefulLifeValue), -1)
      : addDays(addMonths(inServiceDate, usefulLifeValue), -1);

    const formattedEndDate = formatISO(endDate, { representation: 'date' });

    return {
      remainingLife: calculateRemainingLife(formatISO(inServiceDate, { representation: 'date' }), formattedEndDate),
      endDate,
    };
  };

  const fixedAssets: Array<Partial<FixedAssetForm>> = filteredFixedAssets.map((asset) => ({
    id: asset.id,
    number: asset.number || '',
    description: asset.description || '',
    vendorContact: find(vendors, { id: asset.vendorContactId }) as Contact || null,
    fixedAssetType: find(fixedAssetTypes, { id: asset.fixedAssetTypeId }) as AssetType || null,
    finalized: false,
    originalValue: asset.originalValue,
    priorDepreciation: asset.priorDepreciation,
    inServiceDate: asset.inServiceDate ? parseISO(asset.inServiceDate) : undefined,
    fullyDepreciatedDate: asset.fullyDepreciatedDate ? parseISO(asset.fullyDepreciatedDate) : undefined,
    schedulingMethod: find(schedulingMethodOptions, { value: asset.schedulingMethod }),
    depreciationAccountId: asset.depreciationAccountId,
    gainOrLossAccountId: asset.gainOrLossAccountId,
    tags: asset.tags,
    remainingLife: calculateRemainingLife(asset.inServiceDate, asset.fullyDepreciatedDate),
    nbv: precisionRound(asset.originalValue - asset.priorDepreciation),
  }));

  useEffect(() => {
    setPage(1);
  }, [filters]);

  const handleCheckboxChange = (id: string) => {
    selectedSources.includes(id)
      ? setSelectedSources(selectedSources.filter((source) => source !== id))
      : setSelectedSources([...selectedSources, id]);
  };

  const handleSelectAll = () => {
    isAllSelected
      ? setSelectedSources([])
      : setSelectedSources(allFixedAssets
        .map((asset) => asset.id));

    setIsAllSelected(!isAllSelected);
  };

  const handleBulkEditClose = () => {
    setSelectedSources([]);
    setIsAllSelected(false);
  };

  const handleBulkEditApply = (ids: Array<string>, changedProperties: FixedAsset) => {
    const payload: Array<Partial<FixedAsset>> = ids.map((id) => ({
      id,
      ...Object.fromEntries(Object.entries(changedProperties).map(([key, value]: any) => {
        switch (key) {
          case 'vendorContact':
            return ['vendorContactId', value.id];
          case 'fixedAssetType':
            return ['fixedAssetTypeId', value.id];
          case 'schedulingMethod':
            return ['schedulingMethod', value.value];
          case 'inServiceDate':
          case 'fullyDepreciatedDate':
            return [key, formatISO(value, { representation: 'date' })];
          default:
            return [key, value];
        }
      })),
    }));

    reduxDispatch(bulkUpdateFixedAssets({
      fixedAssets: payload,
      effective: formatISO(startOfMonth(parseISO(startDate)), { representation: 'date' }),
    }));
  };

  const handleDepreciationEndDateChange = () => {
    setValue(
      'remainingLife',
      calculateRemainingLife(
        formatISO(getValues().inServiceDate, { representation: 'date' }),
        formatISO(getValues().fullyDepreciatedDate, { representation: 'date' }),
      ).label,
    );
  };

  const isUnselectedAssetFullyDepreciated = (value: FixedAssetForm): boolean => (
    value.nbv === 0 && value.priorDepreciation !== 0
  );

  const isUnselectedAssetManuallyDepreciated = (value: FixedAssetForm): boolean => (
    value.schedulingMethod?.value === SchedulingMethod.MANUAL
  );

  const isAssetFullyDepreciated = (value: FixedAssetForm): boolean => (
    getValues().nbv
      ? (getValues().nbv === 0 && getValues().priorDepreciation !== 0)
      : isUnselectedAssetFullyDepreciated(value));

  const isAssetManuallyDepreciated = (value: FixedAssetForm): boolean => (
    getValues().schedulingMethod?.value
      ? getValues().schedulingMethod?.value === SchedulingMethod.MANUAL
      : isUnselectedAssetManuallyDepreciated(value)
  );

  const isUnselectedAssetDatesDisabled = (value: FixedAssetForm): boolean => (
    isUnselectedAssetFullyDepreciated(value) || isUnselectedAssetManuallyDepreciated(value)
  );

  const isAssetDatesDisabled = (value: FixedAssetForm): boolean => (
    isAssetFullyDepreciated(value) || isAssetManuallyDepreciated(value)
  );

  const colProps = [
    {
      colName: 'Bulk Edit',
      varKey: 'bulkEdit',
      colType: ITableItemType.BOOLEAN,
      sortable: false,
      sticky: true,
      customHeader: (
        <FactaCheckbox
          checked={isAllSelected}
          onChange={handleSelectAll}
          disabled={false}
        />
      ),
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="bulk"
          is-cell-sticky="true"
        >
          <FactaCheckbox
            checked={selectedSources.includes(value.id)}
            onChange={() => handleCheckboxChange(value.id)}
            disabled={false}
            onKeyDown={(ev) => {
              if (ev.key === 'Tab' && !ev.shiftKey && selectedRow !== value.id) {
                ev.preventDefault();
                setSelectedByTabbing(true);
                setSelectedRow(value.id);
              }
            }}
          />
        </StyledTableCell>
      ),
    },
    {
      colName: 'FA#',
      customHeader: (
        <TextSearchFilter
          _key={subledgerId}
          label="FA#"
          value={getFilterValueByFilterName(filters, 'number')}
          onChange={(filter) => setFilter('number', filter)}
          onInput={(filter) => setFilter('number', filter)}
        />
      ),
      varKey: 'number',
      colType: ITableItemType.TEXT,
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="number"
          minWidth="120"
        >
          {updatingId === value.id && (
            <LoadingBox
              transparent
              size={20}
            />
          )}
          {selectedRow === value.id
            ? (
              <FormInput
                control={control}
                name="number"
                defaultValue={value.number}
                error={!!errors?.number}
                onBlur={() => handleOnBlur(value.id)}
                autoFocus={selectedRow === value.id && selectedByTabbing}
                placeholder="FA#"
              />
            )
            : (
              <IdleTableCell
                placeholderText="#FA"
                value={value.number}
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: 'Description',
      customHeader: (
        <TextSearchFilter
          _key={subledgerId}
          label="Description"
          value={getFilterValueByFilterName(filters, 'description')}
          onChange={(filter) => setFilter('description', filter)}
          onInput={(filter) => setFilter('description', filter)}
        />
      ),
      varKey: 'description',
      colType: ITableItemType.TEXT,
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="description"
          minWidth="200"
        >
          {updatingId === value.id && (
            <LoadingBox
              transparent
              size={20}
            />
          )}
          {selectedRow === value.id
            ? (
              <FormInput
                control={control}
                name="description"
                defaultValue={value.description}
                error={!!errors?.description}
                onBlur={() => handleOnBlur(value.id)}
                placeholder="Description"
              />
            )
            : (
              <IdleTableCell
                placeholderText="Description"
                value={value.description}
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: 'Vendor',
      customHeader: (
        <MultipickerFilter
          _key={subledgerId}
          label="Vendor"
          value={getFilterValueByFilterName(filters, 'vendorContactId')}
          options={vendors || []}
          onChange={(filter) => setFilter('vendorContactId', filter)}
        />
      ),
      varKey: 'vendorContact',
      colType: ITableItemType.SORTKEY,
      sortKey: ['name'],
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="vendorContact"
          minWidth="200"
        >
          {updatingId === value.id && (
            <LoadingBox
              transparent
              size={20}
            />
          )}
          {selectedRow === value.id
            ? (
              <FactaAutocomplete
                control={control}
                options={vendors}
                disableInactiveOptions
                optionName="name"
                name="vendorContact"
                defaultValue={value.vendorContact}
                error={errors?.vendorContact}
                onBlur={() => handleOnBlur(value.id)}
                placeholder="Select Vendor"
              />
            )
            : (
              <IdleTableCell
                placeholderText="Select Vendor"
                value={value.vendorContact?.name || ''}
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: 'Asset Type',
      customHeader: (
        <MultipickerFilter
          _key={subledgerId}
          label="Asset Type"
          value={getFilterValueByFilterName(filters, 'fixedAssetTypeId')}
          options={fixedAssetTypes || []}
          onChange={(filter) => setFilter('fixedAssetTypeId', filter)}
        />
      ),
      varKey: 'fixedAssetType',
      colType: ITableItemType.SORTKEY,
      sortKey: ['name'],
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="fixedAssetType"
          minWidth="200"
        >
          {updatingId === value.id && (
            <LoadingBox
              transparent
              size={20}
            />
          )}
          {selectedRow === value.id
            ? (
              <FactaAutocomplete
                control={control}
                options={fixedAssetTypes || []}
                disableInactiveOptions
                optionName="name"
                name="fixedAssetType"
                defaultValue={value.fixedAssetType}
                error={errors?.fixedAssetType}
                onBlur={() => {
                  handleOnBlur(value.id);
                  handleScheduleChange();
                }}
                additionalActions={[
                  {
                    label: 'Add New Asset Type',
                    action: () => setAssetTypeFlyover({ isOpen: true, assetType: DEFAULT_ASSET_TYPE }),
                  },
                ]}
                placeholder="Select Asset Type"
              />
            )
            : (
              <IdleTableCell
                value={value.fixedAssetType?.name || ''}
                placeholderText="Asset Type"
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: 'Original Amount',
      customHeader: (
        <AmountFilter
          _key={subledgerId}
          label="Original Amount"
          value={getFilterValueByFilterName(filters, 'originalValue')}
          onChange={(filter) => setFilter('originalValue', filter)}
        />
      ),
      varKey: 'originalValue',
      colType: ITableItemType.NUMMARY,
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="originalValue"
          alignRight
        >
          {updatingId === value.id && (
            <LoadingBox
              transparent
              size={20}
            />
          )}
          {selectedRow === value.id
            ? (
              <NumberInput
                control={control}
                name="originalValue"
                defaultValue={value.originalValue}
                error={errors?.originalValue}
                onUpdate={() => {
                  setValue('nbv', getValues().originalValue - getValues().priorDepreciation);
                  handleOnBlur(value.id);
                }}
              />
            )
            : (
              <IdleTableCell
                value={currencyFormatter.format(value.originalValue)}
                placeholderText="Original Value"
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: 'Accumulated Depreciation',
      customHeader: (
        <AmountFilter
          _key={subledgerId}
          label="Accumulated Depreciation"
          value={getFilterValueByFilterName(filters, 'priorDepreciation')}
          onChange={(filter) => setFilter('priorDepreciation', filter)}
        />
      ),
      varKey: 'priorDepreciation',
      colType: ITableItemType.NUMMARY,
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="priorDepreciation"
          alignRight
        >
          {updatingId === value.id && (
            <LoadingBox
              transparent
              size={20}
            />
          )}
          {selectedRow === value.id
            ? (
              <NumberInput
                control={control}
                name="priorDepreciation"
                defaultValue={value.priorDepreciation}
                error={errors?.priorDepreciation}
                onUpdate={() => {
                  setValue('nbv', getValues().originalValue - getValues().priorDepreciation);
                  handleOnBlur(value.id);
                }}
              />
            )
            : (
              <IdleTableCell
                value={currencyFormatter.format(value.priorDepreciation)}
                placeholderText="Prior Depreciation"
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: 'Net Book Value',
      customHeader: (
        <AmountFilter
          _key={subledgerId}
          label="Net&nbsp;Book Value"
          value={getFilterValueByFilterName(filters, 'nbv')}
          onChange={(filter) => setFilter('nbv', filter)}
        />
      ),
      varKey: 'nbv',
      colType: ITableItemType.NUMMARY,
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="nbv"
          alignRight
        >
          {updatingId === value.id && (
            <LoadingBox
              transparent
              size={20}
            />
          )}
          {selectedRow === value.id
            ? (
              <NumberInput
                control={control}
                name="nbv"
                defaultValue={value.nbv}
                error={errors?.nbv}
                disabled
              />
            )
            : currencyFormatter.format(value.nbv)}
        </StyledTableCell>
      ),
    },
    {
      colName: 'Depreciation Start Date',
      customHeader: (
        <DatepickerFilter
          _key={subledgerId}
          label="Depreciation Start Date"
          value={getFilterValueByFilterName(filters, 'inServiceDate')}
          onChange={(filter) => setFilter('inServiceDate', filter)}
        />
      ),
      varKey: 'inServiceDate',
      colType: ITableItemType.DATE,
      child: (value: FixedAssetForm) => (
        <StyledTableCell key="inServiceDate">
          {updatingId === value.id && (
            <LoadingBox
              transparent
              size={20}
            />
          )}
          {selectedRow === value.id
            ? (
              <>
                <Box {...(isAssetDatesDisabled(value) ? { display: 'none' } : {})}>
                  <Controller
                    name="inServiceDate"
                    control={control}
                    defaultValue={value.inServiceDate ? value.inServiceDate : null}
                    render={({ onChange, onBlur, value: localValue }) => (
                      <FactaDatePicker
                        selectedDate={localValue}
                        error={!!errors?.inServiceDate}
                        onDateChange={(date) => {
                          onChange(date);
                          handleScheduleChange();
                        }}
                        openToDate={value.inServiceDate ? value.inServiceDate : undefined}
                        isNullable
                        onBlur={() => {
                          onBlur();
                          handleOnBlur(value.id);
                        }}
                        minDate={parseISO(startDate!)}
                      />
                    )}
                  />
                </Box>
                {isAssetDatesDisabled(value) && 'N/A'}
              </>
            )
            : (
              <>
                <IdleTableCell
                  value={isUnselectedAssetDatesDisabled(value)
                    ? 'N/A'
                    : (value.inServiceDate && format(value.inServiceDate, FULL_DATE_FORMAT)) || ''}
                  placeholderText="mm/dd/yyyy"
                />
              </>
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: 'Depreciation End Date',
      customHeader: (
        <DatepickerFilter
          _key={subledgerId}
          label="Depreciation End Date"
          value={getFilterValueByFilterName(filters, 'fullyDepreciatedDate')}
          onChange={(filter) => setFilter('fullyDepreciatedDate', filter)}
        />
      ),
      varKey: 'fullyDepreciatedDate',
      colType: ITableItemType.DATE,
      child: (value: FixedAssetForm) => (
        <StyledTableCell key="fullyDepreciatedDate">
          {updatingId === value.id && (
            <LoadingBox
              transparent
              size={20}
            />
          )}
          {selectedRow === value.id
            ? (
              <>
                <Box {...(isAssetDatesDisabled(value) ? { display: 'none' } : {})}>
                  <Controller
                    name="fullyDepreciatedDate"
                    control={control}
                    defaultValue={value.fullyDepreciatedDate ? new Date(value.fullyDepreciatedDate) : null}
                    render={({ onChange, onBlur, value: localValue }) => (
                      <FactaDatePicker
                        selectedDate={localValue}
                        error={!!errors?.fullyDepreciatedDate}
                        onDateChange={(date) => {
                          onChange(date);
                          handleDepreciationEndDateChange();
                        }}
                        openToDate={value.fullyDepreciatedDate ? new Date(value.fullyDepreciatedDate) : undefined}
                        isNullable
                        onBlur={() => {
                          onBlur();
                          handleOnBlur(value.id);
                        }}
                        minDate={getValues().inServiceDate}
                      />
                    )}
                  />
                </Box>
                {isAssetDatesDisabled(value) && 'N/A'}
              </>
            )
            : (
              <IdleTableCell
                value={isUnselectedAssetDatesDisabled(value)
                  ? 'N/A'
                  : (value.fullyDepreciatedDate && format(value.fullyDepreciatedDate, FULL_DATE_FORMAT)) || ''}
                placeholderText="mm/dd/yyyy"
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: 'Remaining Life',
      varKey: 'remainingLife',
      customHeader: (
        <RemainingLifeFilter
          _key={subledgerId}
          label="Remaining Life"
          value={getFilterValueByFilterName(filters, 'remainingLife')}
          onChange={(filter) => setFilter('remainingLife', filter)}
        />
      ),
      colType: ITableItemType.SORTKEY_NUMMARY,
      sortKey: ['difference'],
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="remainingLife"
          minWidth="200"
        >
          {updatingId === value.id && (
            <LoadingBox
              transparent
              size={20}
            />
          )}
          {selectedRow === value.id
            ? (
              <>
                <Box {...(isAssetFullyDepreciated(value) ? { display: 'none' } : {})}>
                  <FormInput
                    control={control}
                    name="remainingLife"
                    defaultValue={value.remainingLife.label}
                    error={!!errors?.remainingLife}
                    disabled
                  />
                </Box>
                {isAssetFullyDepreciated(value) && '0 days'}
              </>
            )
            : (
              <IdleTableCell
                value={isUnselectedAssetFullyDepreciated(value) ? '0 days' : value.remainingLife.label}
                placeholderText="0 days"
              />
            ) }
        </StyledTableCell>
      ),
    },
    {
      colName: 'Frequency',
      varKey: 'schedulingMethod',
      customHeader: (
        <MultipickerFilter
          _key={subledgerId}
          label="Freqeuncy"
          value={getFilterValueByFilterName(filters, 'schedulingMethod')}
          options={schedulingMethodOptions || []}
          onChange={(filter) => setFilter('schedulingMethod', filter)}
        />
      ),
      colType: ITableItemType.SORTKEY,
      sortKey: ['label'],
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="schedulingMethod"
          minWidth="150"
        >
          {selectedRow === value.id
            ? (
              <FactaAutocomplete
                control={control}
                options={schedulingMethodOptions}
                optionName="label"
                name="schedulingMethod"
                defaultValue={value.schedulingMethod}
                error={errors?.schedulingMethod}
                onChange={() => {
                  setValue('inServiceDate', null);
                  setValue('fullyDepreciatedDate', null);
                  trigger();
                  handleOnBlur(value.id);
                }}
                disableClearable
                placeholder="Select Frequency"
              />
            )
            : (
              <IdleTableCell
                value={value.schedulingMethod?.label || ''}
                placeholderText="Select Frequency"
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: '',
      varKey: 'save',
      colType: ITableItemType.ACTION,
      child: (value: FixedAsset) => (
        <SaveCell
          key="save"
          onDelete={handleDelete}
          value={value}
        />
      ),
    },
  ];

  const defaultSort = {
    key: 'number',
    order: SortOrder.DESC,
  };

  const handleAddAsset = () => {
    reduxDispatch(createFixedAsset({
      subledgerId,
    }))
      .unwrap()
      .then((result) => {
        setSelectedRow(result.id);
        const lastPage = Math.ceil((filteredFixedAssets.length + 1) / pageSize);
        setPage(lastPage);
        if (addedRowRef.current) {
          addedRowRef.current.scrollIntoView(false);
        }
      });
  };

  useEffect(() => {
    if (preselectedRowRef?.current && highlightedId) {
      const foundIndex = allFixedAssets.findIndex((asset) => asset.id === preselectedAssetId);
      const foundOnPage = Math.floor(foundIndex / pageSize) + 1;
      setPage(foundOnPage);
      setTimeout(() => setHighlightedId(''), HIGHLIGHT_RESET);
      setTimeout(() => {
        preselectedRowRef.current?.scrollIntoView({ block: 'center' });
      }, 100);
    }
  }, [allFixedAssets, pageSize, preselectedAssetId, highlightedId]);

  const handleRowSelect = (ev: React.MouseEvent<HTMLTableRowElement, MouseEvent>, id: string) => {
    if (selectedSources.length < 2) {
      if (id !== selectedRow) {
        handleInstantSave(selectedRow);
        setSelectedRow(id);
      } else {
        setSelectedRow(id);
      }
    }
  };

  const handleScheduleChange = () => {
    const {
      inServiceDate,
      fixedAssetType,
    } = getValues();

    if (inServiceDate && fixedAssetType) {
      const {
        remainingLife,
        endDate,
      } = calculateRemainingLifeFromFixedAssetType(inServiceDate, fixedAssetType.id);
      setValue('remainingLife', remainingLife.label);
      setValue('fullyDepreciatedDate', endDate);
      trigger();
    }
  };
  const handleOnBlur = (assetId: string) => {
    setAutosaveData({ assetId, data: getValues() as FixedAssetForm });
  };

  const handleInstantSave = (assetId?: string) => {
    const { isDirty } = formState;
    setAutosaveData({ assetId: undefined, data: undefined });
    if (assetId && isDirty) {
      setUpdatingId(assetId);
      handleSave({
        assetId,
        data: getValues() as FixedAssetForm,
        updateSilently: true,
        dontUpdateState: false,
      });
    }
  };

  const handleClickAway = () => {
    if (selectedRow && !assetTypeFlyover.isOpen) {
      handleInstantSave(selectedRow);
      setSelectedRow(undefined);
    }
  };

  useDebouncedEffect(() => {
    if (autosaveData.assetId && autosaveData.data) {
      handleSave({
        assetId: autosaveData.assetId,
        data: autosaveData.data,
        updateSilently: true,
        dontUpdateState: true,
      });
    }
  }, [autosaveData], AUTOSAVE_DELAY);

  const handleSave = ({
    assetId,
    data,
    updateSilently,
    dontUpdateState,
  }: SaveMethodProps) => {
    const parsedData = {
      assetId,
      fixedAsset: {
        number: data.number,
        description: data.description,
        vendorContactId: data.vendorContact?.id || null,
        fixedAssetTypeId: data.fixedAssetType?.id || null,
        originalValue: data.originalValue,
        priorDepreciation: data.priorDepreciation,
        inServiceDate: data.inServiceDate && formatISO(data.inServiceDate, { representation: 'date' }),
        fullyDepreciatedDate: data.nbv === 0 && data.originalValue !== 0
          ? (data.inServiceDate && formatISO(data.inServiceDate, { representation: 'date' }))
          : (data.fullyDepreciatedDate && formatISO(data.fullyDepreciatedDate, { representation: 'date' })),
        schedulingMethod: data.schedulingMethod?.value,
        depreciationAccountId: data.fixedAssetType?.depreciationAccountId || null,
        gainOrLossAccountId: data.fixedAssetType?.gainOrLossAccountId || null,
        tags: data.fixedAssetType?.tags || [],
      },
      updateSilently,
      dontUpdateState,
      effective: formatISO(startOfMonth(parseISO(startDate)), { representation: 'date' }),
    };

    reduxDispatch(updateFixedAsset(parsedData))
      .then(() => {
        if (!updateSilently) setSelectedRow(undefined);
      })
      .finally(() => {
        setUpdatingId('');
      });
  };

  const handleDelete = (assetId: string) => {
    setSelectedRow(undefined);
    reduxDispatch(removeFixedAssets({
      fixedAssetIds: [assetId],
    }));
  };

  const handleClickDeleteSelectedAssets = () => {
    setIsDeleteWarningOpen(true);
  };

  const handleDeleteSelectedAssets = () => {
    setIsDeleteWarningOpen(false);
    setSelectedRow(undefined);
    reduxDispatch(removeFixedAssets({
      fixedAssetIds: selectedSources,
    }))
      .finally(() => {
        setSelectedSources([]);
      });
  };

  const handleSetPage = (p: number) => {
    setPage(p);
    setSelectedRow(undefined);
  };

  const handleClickImportCSV = () => {
    if (fixedAssetTypes.length === 0) {
      setIsNoAssetTypesWarningOpen(true);
    } else {
      setFlyover(true);
    }
  };

  const topBarActions = [
    {
      label: 'Add Asset',
      icon: <AddCircleRoundedIcon
        color="primary"
        fontSize="small"
      />,
      iconPosition: 'left',
      isData: false,
      action: handleAddAsset,
    },
    ...(selectedSources.length ? [{
      label: `Delete selected (${selectedSources.length})`,
      icon: <DeleteIcon
        color="primary"
        fontSize="small"
      />,
      iconPosition: 'left',
      isData: false,
      action: handleClickDeleteSelectedAssets,
    }] : []),
    {
      label: 'Import CSV',
      icon: <ImportCSVIcon
        color="primary"
        fill={COLORS.skyBlue}
        fontSize="small"
      />,
      isData: true,
      action: handleClickImportCSV,
      pushRight: true,
    },
  ];

  useEffect(() => {
    setIsEditingTable(formState.isDirty);
  }, [formState.isDirty, setIsEditingTable]);

  useEffect(() => {
    if (selectedSources.length > 1 && selectedRow) {
      setSelectedRow('');
    }

    selectedSources.length === allFixedAssets.length && allFixedAssets.length > 0
      ? setIsAllSelected(true)
      : setIsAllSelected(false);
  }, [selectedSources, selectedRow, allFixedAssets.length]);

  useEffect(() => {
    reset();
    setSelectedByTabbing(false);
  }, [selectedRow, reset]);

  const emptyStateDescription = numberOfFiltersApplied
    ? (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        No results for selected filters
        <Button
          color="primary"
          variant="contained"
          onClick={clearAllFilters}
          style={{ marginTop: 10 }}
        >
          Clear all filters
        </Button>
      </Box>
    )
    : 'No results';

  return (
    <>
      <Box
        padding={2}
        width="100%"
        display="flex"
        flexDirection="column"
        flexGrow={1}
      >
        <Loader open={isFetching} />
        <Autosaved
          isSaving={isUpdatingSilently}
          lastSaved={updatedAt}
        />
        <DialogBox
          openDialog={isDeleteWarningOpen}
          closeDialog={() => setIsDeleteWarningOpen(false)}
          dialogContext={`Are you sure you want to delete selected ${selectedSources.length > 1
            ? `${selectedSources.length} assets`
            : 'asset'}?`}
          dialogTitle="Alert"
          dismissContext="Cancel"
          actions={[{
            title: 'Delete',
            event: handleDeleteSelectedAssets,
          }]}
        />
        <DialogBox
          openDialog={isNoAssetTypesWarningOpen}
          closeDialog={() => setIsNoAssetTypesWarningOpen(false)}
          dialogContext="Please create an asset type before importing."
          dialogTitle="Alert"
          dismissContext="Cancel"
          actions={[{
            title: 'Create Asset Type',
            event: () => history.push('/account/asset-types'),
          }]}
        />
        {fixedAssetTypes && (
          <TableComponent
            colProps={colProps}
            tableData={fixedAssets}
            defaultSort={defaultSort}
            contentType={ITableType.fixedAssetsAmortizationSources}
            topBarActions={topBarActions}
            emptyStateDescription={emptyStateDescription}
            onRowClick={handleRowSelect}
            onClickOutsideSelectedRow={handleClickAway}
            selectedId={selectedRow}
            selectedIds={selectedSources}
            addedRowRef={addedRowRef}
            preselectedAssetId={highlightedId}
            preselectedRowRef={preselectedRowRef}
            disabled={disabled}
            highlightedId={highlightedId}
            page={page}
            setPage={handleSetPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
          >
            <BulkEdit
              productCategory={ProductCategory.FixedAsset}
              onClose={handleBulkEditClose}
              onBulkEditApply={handleBulkEditApply}
              selectedSources={selectedSources}
              isOpen={selectedSources.length > 1}
              isFullyEditable
              isAllSelected={isAllSelected}
            />
          </TableComponent>
        )}
      </Box>
      <CSVImportWizard
        isOpen={flyover}
        setOpen={setFlyover}
        accountName={find(accounts, { id: accountId })?.name || ''}
        factaStartDate={startDate!}
        productCategory={ProductCategory.FixedAsset}
        scheduleType={defaultSchedulingMethod!}
        subledgerId={subledgerId}
      />
      <AddEditAssetType
        assetType={assetTypeFlyover.assetType}
        isOpen={assetTypeFlyover.isOpen}
        customSaveLabel="Save & Apply"
        onClose={(assetType) => {
          setAssetTypeFlyover({ isOpen: false, assetType: assetTypeFlyover.assetType });
          if (assetType?.id) setValue('fixedAssetType', assetType);
        }}
      />
    </>
  );
};
