export enum TagCategories {
  CLASS = 'class',
  LOCATION = 'location',
}

export interface TagDetails {
  id: string;
  externalId: string;
  parentExternalId: string;
  category: TagCategories;
  name: string;
  active: boolean;
  visible: boolean;
}
