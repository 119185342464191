import React from 'react';
import Box from '@material-ui/core/Box';
import {
  endOfMonth,
  format,
  isAfter,
  isBefore,
  isWithinInterval,
  lastDayOfMonth,
  startOfMonth,
} from 'date-fns';
import { useHistory } from 'react-router';
import CircularProgress from '@material-ui/core/CircularProgress';
import roundTo from 'round-to';
import clsx from 'clsx';
import COLORS from '../../theme/colors';
import {
  Account,
  AmortizationSource,
  ProductCategory,
  Subledger,
} from '../../interfaces/types';
import {
  getAccountName,
  getAssetSumForMonthWithStatus,
  getFactaSourceBalance,
} from '../Subledger/common';
import getSubledgerStartEndDate from '../../util/getSubledgerStartEndDate';
import {
  ASSET_SCHEDULED_STATUS,
  ASSET_TEMP_SCHEDULED_STATUS,
  DAY_SHORT_FORMAT,
} from '../../util/constants';
import ClosedMonthCard from './Card/ClosedMonthCard';
import OutOfPeriodMonthUnScheduledCard from './Card/OutOfPeriodMonthUnScheduledCard';
import OutOfPeriodMonthScheduledCard from './Card/OutOfPeriodMonthScheduledCard';
import CurrentMonthScheduledCard from './Card/CurrentMonthScheduledCard';
import CurrentMonthUnScheduledCard from './Card/CurrentMonthUnScheduledCard';
import SubledgerDisableCard from './Card/SubledgerDisableCard';
import OutOfPeriodCurrentMonthCard from './Card/OutOfPeriodCurrentMonthCard';
import cardStyles from './Card/cardStyles.styled';
import { getOutOfPeriodInfoForSubledger } from '../../util/getOutOfPeriodInfoForSubledger';

interface Props {
  subledger: Subledger;
  account: Account;
  selectedDate: Date | null;
  active: boolean;
  loading: boolean;
  subledgerAccountStartingBalance: number;
  category: ProductCategory;
  accountBalances: Array<Account>;
}

const getSubledgerInfo = (subledger: Subledger, account: Account, selectedDate: Date | null) => {
  const { startDate, endDate } = getSubledgerStartEndDate(subledger);

  let subledgerEnable = true;
  let glBalance = account?.glBalance ?? subledger?.account?.glBalance;
  let postAmortizationAmount: number;
  let unPostAmortizationAmount: number;
  let isSubledgerAmortized = false;
  let closingBalance = 0;
  if (selectedDate && startDate && endDate && isAfter(endDate, startDate)
    && isWithinInterval(
      selectedDate, { start: startDate, end: endDate },
    )
  ) {
    const scheduleDate = format(lastDayOfMonth(selectedDate!), DAY_SHORT_FORMAT);
    postAmortizationAmount = getAssetSumForMonthWithStatus(scheduleDate, subledger.amortizationSources,
      [ASSET_SCHEDULED_STATUS]);
    unPostAmortizationAmount = getAssetSumForMonthWithStatus(scheduleDate, subledger.amortizationSources,
      [ASSET_TEMP_SCHEDULED_STATUS]);
    const subledgerLog = subledger?.subledgerAmortizationLogs
      ?.find((log) => format(log.scheduleDate!, DAY_SHORT_FORMAT) === scheduleDate);
    if (subledgerLog) {
      closingBalance = subledgerLog.closingBalance;
      glBalance = account?.glBalance;
      isSubledgerAmortized = true;
    }
  } else {
    subledgerEnable = false;
    glBalance = 0;
    postAmortizationAmount = 0;
    unPostAmortizationAmount = 0;
  }

  return {
    subledgerEnable,
    glBalance,
    postAmortizationAmount,
    isSubledgerAmortized,
    closingBalance,
    unPostAmortizationAmount,
  };
};

const SubledgerCard = ({
  subledger,
  selectedDate,
  active,
  account,
  loading,
  subledgerAccountStartingBalance,
  category,
  accountBalances,
}: Props) => {
  const classes = cardStyles();
  const history = useHistory();
  const unScheduleAssetsForScheduledPeriod = subledger
    ?.amortizationSources
    ?.filter((prepaidAsset) => (!prepaidAsset.status
      || prepaidAsset.status === ASSET_TEMP_SCHEDULED_STATUS)
      && isWithinInterval(prepaidAsset?.sourceCreationDate!, {
        start: startOfMonth(selectedDate!),
        end: endOfMonth(selectedDate!),
      })
    && !prepaidAsset.parentId);

  const {
    glBalance,
    subledgerEnable,
    isSubledgerAmortized,
    postAmortizationAmount,
    closingBalance,
    unPostAmortizationAmount,
  } = getSubledgerInfo(subledger, account, selectedDate);

  const outOfPeriodData = getOutOfPeriodInfoForSubledger(subledger, accountBalances);
  const date = format(lastDayOfMonth(selectedDate!), DAY_SHORT_FORMAT);

  const navigateToPostJE = () => {
    history.push(`/subledgers/schedule/${subledger.id}/journal-entry/?scheduleDate=${date}`);
  };

  const navigateToScheduler = () => {
    history.push(`/subledgers/schedule/${subledger.id}/?scheduleDate=${date}`);
  };

  const navigateToHistoricalScheduler = () => {
    history.push(`historical/subledgers/schedule/${subledger.id}/?scheduleDate=${date}`);
  };

  const navigateToHistoricalPostJE = () => {
    history.push(`historical/subledgers/schedule/${subledger.id}/post-je?scheduleDate=${date}&fromDashboard=true`);
  };

  const renderCard = () => {
    const accountName = getAccountName(subledger?.account);
    if (!subledgerEnable) {
      return (
        <SubledgerDisableCard
          accountName={accountName}
          category={category}
          outOfPeriodData={outOfPeriodData}
        />
      );
    }
    const previousMonthAssets = subledger
      ?.amortizationSources
      ?.filter((prepaidAsset) => (!prepaidAsset.status
        || prepaidAsset.status === ASSET_TEMP_SCHEDULED_STATUS)
        && isBefore(prepaidAsset?.sourceCreationDate!, startOfMonth(selectedDate!)));

    const unScheduledAssetsWithNoStatus = unScheduleAssetsForScheduledPeriod
      ?.filter((asset) => !asset?.status);

    const tempScheduledAssets = unScheduleAssetsForScheduledPeriod
      ?.filter((asset) => asset.status === ASSET_TEMP_SCHEDULED_STATUS)?.length;

    if (isSubledgerAmortized) {
      if (previousMonthAssets?.length > 0
          || subledgerAccountStartingBalance !== getFactaSourceBalance(subledger?.amortizationSources)) {
        return (
          <OutOfPeriodCurrentMonthCard
            active={active}
            accountName={accountName}
            loading={loading}
            glBalance={glBalance}
            closingBalance={closingBalance}
            amortizationPostedAmount={postAmortizationAmount}
            unScheduleAssetsCount={unScheduledAssetsWithNoStatus?.length > 0
              ? unScheduledAssetsWithNoStatus?.length : tempScheduledAssets > 0 ? 1 : 0}
            category={category}
            outOfPeriodData={outOfPeriodData}
          />
        );
      }
      if (unScheduleAssetsForScheduledPeriod?.length === 0 && glBalance === closingBalance) {
        return (
          <ClosedMonthCard
            active={active}
            accountName={accountName}
            loading={loading}
            glBalance={glBalance}
            closingBalance={closingBalance}
            amortizationPostedAmount={postAmortizationAmount}
            category={category}
            onCurrencyNavigation={navigateToHistoricalScheduler}
            outOfPeriodData={outOfPeriodData}
          />
        );
      }
      if (unScheduledAssetsWithNoStatus?.length > 0) {
        const amount = unScheduledAssetsWithNoStatus.map((item: AmortizationSource) => item.startingBalance)
          .reduce((prev, curr) => prev + curr, 0);
        return (
          <OutOfPeriodMonthUnScheduledCard
            active={active}
            accountName={accountName}
            loading={loading}
            amount={amount}
            closingBalance={closingBalance}
            amortizationPostedAmount={postAmortizationAmount}
            amortizationUnPostedAmount={unPostAmortizationAmount}
            action={navigateToHistoricalScheduler}
            onCurrencyNavigation={navigateToHistoricalScheduler}
            unScheduleAssetsCount={unScheduledAssetsWithNoStatus?.length}
            glBalance={glBalance}
            category={category}
            outOfPeriodData={outOfPeriodData}
          />
        );
      }
      return (
        <OutOfPeriodMonthScheduledCard
          active={active}
          accountName={accountName}
          loading={loading}
          glBalance={glBalance}
          closingBalance={closingBalance}
          amortizationPostedAmount={postAmortizationAmount}
          amortizationUnPostedAmount={unPostAmortizationAmount + postAmortizationAmount}
          action={navigateToHistoricalPostJE}
          onCurrencyNavigation={navigateToHistoricalScheduler}
          unScheduleAssetsCount={tempScheduledAssets > 0 ? 1 : 0}
          category={category}
          outOfPeriodData={outOfPeriodData}
        />
      );
    }
    if (previousMonthAssets?.length > 0
        || subledgerAccountStartingBalance !== getFactaSourceBalance(subledger?.amortizationSources)) {
      return (
        <OutOfPeriodCurrentMonthCard
          active={active}
          accountName={accountName}
          loading={loading}
          glBalance={glBalance}
          closingBalance={subledger?.openingBalance}
          amortizationPostedAmount={0}
          unScheduleAssetsCount={unScheduledAssetsWithNoStatus?.length > 0
            ? unScheduledAssetsWithNoStatus?.length : tempScheduledAssets > 0 ? 1 : 0}
          category={category}
          outOfPeriodData={outOfPeriodData}
        />
      );
    }
    if (unScheduleAssetsForScheduledPeriod?.some((asset) => !asset?.status)) {
      return (
        <CurrentMonthUnScheduledCard
          active={active}
          accountName={accountName}
          loading={loading}
          glBalance={glBalance}
          amount={roundTo(glBalance - subledger?.openingBalance, 2)}
          openingBalance={subledger?.openingBalance}
          amortizationPostedAmount={0}
          amortizationUnPostedAmount={unPostAmortizationAmount + postAmortizationAmount}
          action={navigateToScheduler}
          onCurrencyNavigation={navigateToScheduler}
          unScheduleAssetsCount={unScheduledAssetsWithNoStatus?.length}
          category={category}
          outOfPeriodData={outOfPeriodData}
        />
      );
    }
    return (
      <CurrentMonthScheduledCard
        active={active}
        accountName={accountName}
        loading={loading}
        glBalance={glBalance}
        openingBalance={subledger?.openingBalance}
        amortizationUnPostedAmount={unPostAmortizationAmount + postAmortizationAmount}
        action={navigateToPostJE}
        onCurrencyNavigation={navigateToScheduler}
        unScheduleAssetsCount={tempScheduledAssets > 0 ? 1 : 0}
        category={category}
        outOfPeriodData={outOfPeriodData}
      />
    );
  };

  return (
    <Box className={clsx(classes.root, { [classes.disabledBox]: !subledgerEnable })}>
      {
        loading && (
          <Box
            position="absolute"
            height="100%"
            width="100%"
            bgcolor={COLORS.lightGray5}
            display="flex"
            justifyContent="center"
            alignItems="center"
            zIndex={1}
          >
            <CircularProgress />
          </Box>
        )
      }
      { renderCard() }
    </Box>
  );
};

export default SubledgerCard;
