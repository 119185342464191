import React, {
  useEffect,
  useRef,
} from 'react';
import {
  createStyles,
  Tabs,
  TabsActions,
} from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import {
  Switch,
} from 'react-router';
import {
  Redirect,
  Route,
  Link,
  useLocation,
} from 'react-router-dom';
import clsx from 'clsx';
import makeStyles from '@material-ui/styles/makeStyles';
import Box from '@material-ui/core/Box';
import COLORS from '../../theme/colors';

const useStyles = makeStyles(() => createStyles({
  boxBorder: {
    borderTop: `1px solid ${COLORS.lightGray2}`,
    backgroundColor: COLORS.lightGray3,
    paddingLeft: 36,
    transition: 'none !important',
  },
  routeContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
    padding: '32px',
    overflow: 'auto',
  },
  noPadding: {
    padding: 0,
  },
}));

interface IRoute {
  label: string;
  path: string;
  component: React.ReactNode;
  isDisabled?: boolean;
  exact: boolean;
}

interface Props {
  routes: Array<IRoute>;
  baseRedirect: {
    fromUrl: string;
    pathUrl: string;
  };
  noPadding?: boolean;
}

export const NavigationTabs = ({
  routes,
  baseRedirect,
  noPadding,
}: Props) => {
  const classes = useStyles();
  const tabsActions = useRef<TabsActions>(null);
  const { pathname } = useLocation();
  const landingPath = routes.find(({ path, label }) => label && pathname.includes(path));
  const currentTabValue = landingPath
    ? landingPath.path
    : baseRedirect.pathUrl;
  const [value, setValue] = React.useState(currentTabValue);

  useEffect(() => {
    if (value !== pathname) {
      setValue(baseRedirect.pathUrl);
    }

    setTimeout(() => {
      if (tabsActions?.current) {
        tabsActions.current?.updateIndicator();
      }
    }, 0);
  }, [tabsActions, pathname, value, baseRedirect.pathUrl]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="flex-start"
        flexDirection="column"
        width="100%"
        className={classes.boxBorder}
      >
        <Tabs
          action={tabsActions}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="off"
        >
          {routes.filter((route) => route.label)?.map(({
            label,
            isDisabled,
            path,
          }) => (
            <Tab
              key={label}
              label={label}
              disableRipple
              disabled={isDisabled}
              to={path}
              value={path}
              component={Link}
            />
          ))}
        </Tabs>
      </Box>
      <Box
        className={clsx(classes.routeContainer, {
          [classes.noPadding]: noPadding,
        })}
      >
        <Switch>
          {routes.map(({
            path,
            component,
            label,
            exact,
          }) => (
            <Route
              key={label}
              path={path}
              exact={exact}
            >
              {component}
            </Route>
          ))}
          <Redirect
            from={baseRedirect.fromUrl}
            to={baseRedirect.pathUrl}
          />
        </Switch>
      </Box>
    </>
  );
};
