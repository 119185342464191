import React, {
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import Table from '@material-ui/core/Table';
import {
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import cloneDeep from 'lodash.clonedeep';
import {
  format,
  parseISO,
} from 'date-fns';
import { Box } from '@material-ui/core';
import {
  AmortizationSource,
  NotifierType,
  ProductCategory,
  LegacySchedulingMethod,
  QuickBookError,
  Subledger,
  JournalEntry,
} from '../../../interfaces/types';
import {
  getChildren,
  getTotalAndRowsCount,
  updateAssetsForSaving,
  updateHistoricalSubledgerData,
} from '../../../components/Subledger/common';
import PostJEHeader from './PostJEHeader';
import reducer from '../../../components/Subledger/reducer';
import isEmpty from '../../../util/isEmpty';
import {
  deleteSubledger,
  getSubledger,
  removeHistoricalUpdatedAssetsFromLocalStorage,
} from '../../../util/subledger';
import DialogBox from '../../../components/DialogBox';
import ErrorPage from '../../../components/ErrorPage';
import { openSnackbar } from '../../../components/Notifier';
import {
  ASSET_SCHEDULED_STATUS,
  ASSET_TEMP_SCHEDULED_STATUS,
  INITIALED,
  INITIALED_WITH_LOCAL_STORAGE,
  QUICKBOOK_ERRORS,
} from '../../../util/constants';
import PostJEPrepaidExpenseTable from '../../../components/Subledger/PostJEPrepaidExpenseTable';
import PostJERevSyncTable from '../../../components/Subledger/PostJERevSyncTable';
import mapErrorMessage from '../../../util/errorMessage';
import {
  ScrollableBoxContainer,
  ScrollableTableContainer,
} from '../../../components/ScrollableTable';
import { AppThunkDispatch } from '../../../store/store';
import { accountInfoSelector } from '../../../store/selectors/account';
import {
  getJournalEntryData,
  getSubledgerDetailsByScheduleDate,
  patchAmortizationSources,
} from '../../../store/slices/subledger';
import {
  fetchingJournalEntryError,
  fetchingSubledgerError,
  isFetchingJournalEntry,
  isFetchingSubledger,
  isUpdatingSubledger,
} from '../../../store/selectors/subledger';
import { Loader } from '../../../components/Loader';
import AssetsSettled from '../../../components/Dashboard/AssetsSettled';

interface Props {
  scheduleDate: string;
}

interface Params {
  id: string;
}

const PostJE = ({ scheduleDate }: Props) => {
  const { id: subledgerId } = useParams<Params>();
  const currentMonth = new Date(scheduleDate).toLocaleString('en-us', { month: 'long' });
  const history = useHistory();
  const location = useLocation();
  const reduxDispatch: AppThunkDispatch = useDispatch();

  const account = useSelector(accountInfoSelector);
  const subledgerLoading = useSelector(isFetchingSubledger);
  const subledgerError = useSelector(fetchingSubledgerError);
  const jeLoading = useSelector(isFetchingJournalEntry);
  const jeError = useSelector(fetchingJournalEntryError);
  const isUpdating = useSelector(isUpdatingSubledger);

  const useQuery = () => new URLSearchParams(location.search);
  const fromDashboard = Boolean(useQuery()
    .get('fromDashboard'));
  const [openDialog, setOpenDialog] = useState(false);
  const [serviceData, setServiceData] = useState<Subledger>();
  const [journalEntryData, setJournalEntryData] = useState<JournalEntry>();
  const [openSubledgerDialog, setOpenSubledgerDialog] = useState<boolean>(false);
  const [openPostJEAlert, setPostJEAlert] = useState<boolean>(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  // @ts-ignore
  const [state, dispatch] = useReducer(reducer, { subledger: {} });

  const getData = useCallback(async () => {
    if (!subledgerLoading && !subledgerError) {
      reduxDispatch(getSubledgerDetailsByScheduleDate({
        subledgerId,
        scheduleDate,
      }))
        .unwrap()
        .then((result) => {
          setServiceData(result);
          dispatch({
            type: INITIALED,
            payload: {
              subledger: result,
            },
          });
        });
    }
  }, [
    reduxDispatch,
    scheduleDate,
    subledgerError,
    subledgerId,
    subledgerLoading,
  ]);

  const getDataFromLocalStorage = useCallback(() => {
    const {
      subledger: localSubledger,
      historicalUpdatedAssets: localHistoricalPrepaidAssets,
    } = getSubledger();
    dispatch({
      type: INITIALED_WITH_LOCAL_STORAGE,
      payload: {
        subledger: localSubledger,
        historicalUpdatedAssets: localHistoricalPrepaidAssets,
        scheduleDate,
      },
    });
  }, [scheduleDate]);

  useEffect(() => {
    if (fromDashboard) {
      getData();
    } else {
      getDataFromLocalStorage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reduxDispatch(getJournalEntryData({
      subledgerId,
      scheduleDate,
    }))
      .unwrap()
      .then((result) => setJournalEntryData(result));
  }, [reduxDispatch, scheduleDate, subledgerId]);

  const {
    subledger,
    historicalUpdatedAssets,
  } = state;

  if (jeError || subledgerError) {
    return <ErrorPage />;
  }

  if (isEmpty(subledger) || jeLoading || subledgerLoading) {
    return <Loader open />;
  }

  const onCancel = () => {
    if (historicalUpdatedAssets?.length > 0) {
      setOpenSubledgerDialog(true);
    } else {
      navigateBack();
    }
  };

  const onPostJE = async () => {
    setPostJEAlert(false);
    // get list of manual assets
    const subledgerPrepaidAssets = subledger?.amortizationSources;
    const manualAssets = subledgerPrepaidAssets
      ?.filter(
        (amortizationSource: AmortizationSource) => (
          amortizationSource?.amortizationSchedule?.amortizationScheduleType === LegacySchedulingMethod.MANUAL
        )
          || amortizationSource?.status === ASSET_TEMP_SCHEDULED_STATUS,
      );

    let assetsForUpdate = cloneDeep(historicalUpdatedAssets ?? []);

    manualAssets?.forEach((manualAsset: AmortizationSource) => {
      if (!assetsForUpdate?.some(
        (amortizationSource: AmortizationSource) => amortizationSource.internalId === manualAsset.internalId,
      )) {
        if (manualAsset?.parentId) {
          const parentAsset = subledgerPrepaidAssets
            ?.find(
              (amortizationSource: AmortizationSource) => amortizationSource.internalId === manualAsset?.parentId,
            );
          assetsForUpdate = assetsForUpdate
            ?.filter(
              (amortizationSource: AmortizationSource) => !(amortizationSource.internalId === parentAsset.internalId
                || amortizationSource.parentId === parentAsset.internalId),
            );
          // update historical assets
          assetsForUpdate.push(cloneDeep(parentAsset));
          const children = getChildren(parentAsset.internalId, state?.subledger?.amortizationSources);
          children?.forEach((child) => assetsForUpdate.push(cloneDeep(child)));
        } else {
          // update historical assets
          assetsForUpdate.push(cloneDeep(manualAsset));
        }
      }
    });
    const amortizationSources = cloneDeep(assetsForUpdate);
    updateAssetsForSaving(amortizationSources);

    reduxDispatch(patchAmortizationSources({
      subledgerId,
      amortizationSources,
      productCategory: subledger.productCategory,
    }))
      .unwrap()
      .then(() => {
        removeHistoricalUpdatedAssetsFromLocalStorage();
        setIsSuccessModalOpen(true);
      })
      .catch((error) => {
        let snackbarMessages: QuickBookError[] = [];
        error.response?.data?.errors && error.response.data.errors.forEach((err: QuickBookError) => {
          if (QUICKBOOK_ERRORS.BOOK_CLOSE_DATES?.includes(err.code)) {
            setOpenDialog(true);
          } else {
            snackbarMessages = [err, ...snackbarMessages];
          }
        });
        if (snackbarMessages.length > 0) {
          const message = snackbarMessages.map((err) => mapErrorMessage(err, subledger.productCategory)).join(', ');
          openSnackbar({ message }, NotifierType.Error);
        }
      });
  };

  const closeDialog = () => {
    setOpenSubledgerDialog(false);
    setPostJEAlert(false);
    setOpenDialog(false);
  };

  const navigateBack = () => {
    deleteSubledger();
    setOpenSubledgerDialog(false);
    history.push(`/journal-entries/?account_id=${subledger?.account?.id}`);
  };

  const setAlertDialog = () => {
    setPostJEAlert(true);
  };

  const navigateToPrepareJE = () => {
    updateHistoricalSubledgerData(historicalUpdatedAssets, subledger, serviceData);
    history.push(`/historical/subledgers/schedule/${subledger.id}/prepare-je/?scheduleDate=${scheduleDate}`);
  };

  const filterPrepaidAssets = subledger?.amortizationSources?.map((amortizationSource: AmortizationSource) => ({
    ...amortizationSource,
    status: ASSET_SCHEDULED_STATUS,
  }));

  const {
    totalPrepaidAccount,
    rowsCount,
    total,
  } = (
    getTotalAndRowsCount(filterPrepaidAssets, scheduleDate) ?? {
      totalPrepaidAccount: 0,
      rowsCount: 0,
      total: 0,
    }
  );

  const dialogTitle = account?.bookCloseDate
    ? `QuickBooks is locked as of ${format(parseISO(account?.bookCloseDate), 'LLLL dd, yyyy')}.`
    + ' \n You cannot edit a locked period. \n Please unlock QuickBooks in order to post changes.' : '';

  const handleSuccessModalClose = () => {
    setIsSuccessModalOpen(false);
    history.push(subledger?.productCategory === ProductCategory.DeferredRevenue ? '/revsync' : '/');
  };

  return (
    <>
      {
        account?.bookCloseDate && (
          <DialogBox
            openDialog={openDialog}
            closeDialog={closeDialog}
            dialogContext={dialogTitle}
            dialogTitle="Alert"
            dismissContext="Dismiss"
          />
        )
      }
      <Loader open={isUpdating} />
      <DialogBox
        openDialog={openSubledgerDialog}
        closeDialog={closeDialog}
        dialogContext={'Are you sure you want to navigate away from this page?'
        + ' \n If you press "Yes" now, ALL your changes will be lost!'}
        dialogTitle="Alert"
        dismissContext="Cancel"
        actions={[{
          title: 'YES',
          event: navigateBack,
        }]}
      />

      <DialogBox
        openDialog={openPostJEAlert}
        closeDialog={closeDialog}
        dialogContext={'You are about to edit a historical period. \n Do you want to proceed?'}
        dialogTitle="ALERT"
        dismissContext="Cancel"
        actions={[{
          title: 'YES',
          event: onPostJE,
        }]}
      />

      <PostJEHeader
        subledger={subledger}
        scheduleDate={scheduleDate}
        currentMonth={currentMonth}
        onCancel={onCancel}
        journalEntry={journalEntryData!}
        onPostJE={setAlertDialog}
        navigateToPrepareJE={navigateToPrepareJE}
        rowsCount={rowsCount}
        totalPrepaidAccount={totalPrepaidAccount}
      />
      <ScrollableBoxContainer>
        <ScrollableTableContainer>
          <Table
            size="small"
            id="journalEntryTable"
            stickyHeader
            aria-label="Post JE Edit"
          >
            {subledger?.productCategory === ProductCategory.PrepaidExpense && (
              <PostJEPrepaidExpenseTable
                scheduleDate={scheduleDate}
                amortizationSources={subledger?.amortizationSources}
                account={subledger?.account}
                currentMonth={currentMonth}
                rowsCount={rowsCount}
                total={total}
                totalPrepaidAccount={totalPrepaidAccount}
              />
            )}
            {subledger?.productCategory === ProductCategory.DeferredRevenue && (
              <PostJERevSyncTable
                scheduleDate={scheduleDate}
                amortizationSources={subledger?.amortizationSources}
                account={subledger?.account}
                currentMonth={currentMonth}
                rowsCount={rowsCount}
                total={total}
                totalPrepaidAccount={totalPrepaidAccount}
              />
            )}
          </Table>
        </ScrollableTableContainer>
      </ScrollableBoxContainer>
      <Box position="relative">
        <AssetsSettled
          open={isSuccessModalOpen}
          onClose={handleSuccessModalClose}
        />
      </Box>
    </>
  );
};

export default PostJE;
