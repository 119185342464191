import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const assetTypesState = (state: RootState) => state.assetTypes;

export const assetTypesSelector = createSelector(
  assetTypesState,
  (assetTypes) => assetTypes.assetTypes,
);

export const isFetchingAssetTypes = createSelector(
  assetTypesState,
  (assetTypes) => assetTypes.isFetching,
);

export const isSavingAssetTypes = createSelector(
  assetTypesState,
  (assetTypes) => assetTypes.isSaving,
);

export const fetchingAssetTypesError = createSelector(
  assetTypesState,
  (assetTypes) => assetTypes.error,
);
