import {
  Box,
  TableCell,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import {
  addMonths,
  differenceInCalendarMonths,
  format,
  lastDayOfMonth,
  startOfMonth,
} from 'date-fns';
import { getAssetSumForMonth } from './common';
import COLORS from '../../theme/colors';
import currencyFormatter from '../../util/currencyFormatter';
import { useTableStyles } from './Subledger.styled';
import { AmortizationSource } from '../../interfaces/types';
import {
  DAY_SHORT_FORMAT,
  FULL_DATE_FORMAT,
} from '../../util/constants';

interface Props {
  startDate: Date;
  endDate: Date;
  scheduleDate: string;
  amortizationSources: Array<AmortizationSource>;
}

const PrepareJETableHeaderAmortizationSchedules = ({
  startDate,
  endDate,
  scheduleDate,
  amortizationSources,
}: Props) => {
  const tableClasses = useTableStyles();
  if (startDate && endDate) {
    const diffInMonths = differenceInCalendarMonths(lastDayOfMonth(endDate!), startOfMonth(startDate!)) + 1;
    if (diffInMonths > 0) {
      return (
        <>
          {
            Array.from(Array(diffInMonths).keys()).map((index: number) => {
              const dayOfMonth = lastDayOfMonth(addMonths(startDate!, index));
              const currentDate = format(dayOfMonth, DAY_SHORT_FORMAT);
              return (
                <TableCell
                  key={`month${index}`}
                  className={clsx(tableClasses.amortizationRowCell, {
                    [tableClasses.cellBackground]: index % 2 === 0,
                    [tableClasses.selectedCellBackground]: currentDate === scheduleDate,
                    [tableClasses.selectedCellBackgroundHeaderCell]: currentDate === scheduleDate,
                  })}
                  style={{ borderLeft: currentDate === scheduleDate ? `1px solid ${COLORS.lightGray2}` : 'none' }}
                  align="right"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="stretch"
                    width="100"
                    padding={0}
                    margin={0}
                  >
                    <Box
                      className={tableClasses.amortizationRowCellHeader}
                    >
                      {currencyFormatter.format(getAssetSumForMonth(currentDate, amortizationSources))}
                    </Box>
                    <Box
                      className={tableClasses.amortizationRowCellHeaderCurrency}
                    >
                      {format(dayOfMonth, FULL_DATE_FORMAT)}
                    </Box>
                  </Box>
                </TableCell>
              );
            })
          }
        </>
      );
    }
  }
  return null;
};

export default React.memo(PrepareJETableHeaderAmortizationSchedules);
