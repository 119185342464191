import {
  Box,
  Button,
} from '@material-ui/core';
import clsx from 'clsx';
import {
  format,
  parseISO,
} from 'date-fns';
import React from 'react';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { ClassNameMap } from '@material-ui/styles';
import { StyledTableCell } from '../../../components/Table/StyledTableCell';
import {
  Filter,
  FilterValue,
  ITableItemType,
  SortOrder,
} from '../../../interfaces/types';
import { FULL_DAY_FORMAT } from '../../../util/constants';
import currencyFormatter from '../../../util/currencyFormatter';
import { JournalEntry } from '../../../interfaces/journalEntries';
import { DatepickerFilter } from '../../../components/Table/Filters/Datepicker';
import { getFilterValueByFilterName } from '../../../util/filtering';
import { TextSearchFilter } from '../../../components/Table/Filters/TextSearch';
import { AmountFilter } from '../../../components/Table/Filters/Amount';

export const v2DefaultSort = {
  key: 'postingDate',
  order: SortOrder.DESC,
};

interface GetV2ColProps {
  classes: ClassNameMap<any>;
  onView: (id: string) => void;
  onEdit: (id: string, date: string) => void;
  accountName: string;
  filters: Array<Filter>;
  setFilter: (filterName: string, filterValue: FilterValue) => void;
  clearFilters: () => void;
  filtersApplied: boolean;
}

export const getV2ColProps = ({
  classes,
  onView,
  onEdit,
  accountName,
  filters,
  setFilter,
  clearFilters,
  filtersApplied,
}: GetV2ColProps) => [
  {
    colName: 'FACTA JE #',
    varKey: 'number',
    customHeader: (
      <TextSearchFilter
        _key={accountName}
        label="FACTA JE #"
        value={getFilterValueByFilterName(filters, 'number')}
        onChange={(filter) => setFilter('number', filter)}
        onInput={(filter) => setFilter('number', filter)}
      />
    ),
    colType: ITableItemType.TEXT,
    child: (value: JournalEntry) => (
      <StyledTableCell key="nubmer">
        {value.number}
      </StyledTableCell>
    ),
  },
  {
    colName: 'GL JE #',
    varKey: 'externalId',
    customHeader: (
      <TextSearchFilter
        _key={accountName}
        label="GL JE #"
        value={getFilterValueByFilterName(filters, 'externalId')}
        onChange={(filter) => setFilter('externalId', filter)}
        onInput={(filter) => setFilter('externalId', filter)}
      />
    ),
    colType: ITableItemType.TEXT,
    child: (value: JournalEntry) => (
      <StyledTableCell key="eternalId">
        {value.externalId}
      </StyledTableCell>
    ),
  },
  {
    colName: 'GL Account',
    varKey: '',
    colType: ITableItemType.TEXT,
    child: () => (
      <StyledTableCell key="GLAccount">
        {accountName}
      </StyledTableCell>
    ),
  },
  {
    colName: 'JE Date',
    varKey: 'postingDate',
    colType: ITableItemType.DATE,
    customHeader: (
      <DatepickerFilter
        _key={accountName}
        label="JE Date"
        value={getFilterValueByFilterName(filters, 'postingDate')}
        onChange={(filter) => setFilter('postingDate', filter)}
      />
    ),
    child: (value: JournalEntry) => (
      <StyledTableCell key="postingDate">
        {format(parseISO(value.postingDate), FULL_DAY_FORMAT)}
      </StyledTableCell>
    ),
  },
  {
    colName: 'JE Description',
    varKey: 'description',
    customHeader: (
      <TextSearchFilter
        _key={accountName}
        label="JE Description"
        value={getFilterValueByFilterName(filters, 'description')}
        onChange={(filter) => setFilter('description', filter)}
        onInput={(filter) => setFilter('description', filter)}
      />
    ),
    colType: ITableItemType.TEXT,
    child: (value: JournalEntry) => (
      <StyledTableCell key="description">
        {value.description}
      </StyledTableCell>
    ),
  },
  {
    colName: 'User',
    varKey: 'user',
    colType: ITableItemType.USERNAME,
    child: (value: JournalEntry) => (
      <StyledTableCell key="user">
        {value.creator}
      </StyledTableCell>
    ),
  },
  {
    colName: 'Updated at',
    varKey: 'lastUpdated',
    colType: ITableItemType.DATE,
    child: (value: JournalEntry) => (
      <StyledTableCell key="lastUpdated">
        {value.lastUpdated && format(parseISO(value.lastUpdated), FULL_DAY_FORMAT)}
      </StyledTableCell>
    ),
  },
  {
    colName: 'Amount',
    varKey: 'amount',
    customHeader: (
      <AmountFilter
        _key={accountName}
        label="Amount"
        value={getFilterValueByFilterName(filters, 'amount')}
        onChange={(filter) => setFilter('amount', filter)}
      />
    ),
    colType: ITableItemType.NUMMARY,
    child: (value: JournalEntry) => (
      <StyledTableCell key="amount">
        {currencyFormatter.format(value.lines.reduce((acc, line) => (line.amount < 0 ? acc + line.amount : acc), 0))}
      </StyledTableCell>
    ),
  },
  {
    colName: 'Status',
    varKey: 'status',
    colType: ITableItemType.TEXT,
    child: () => (
      <StyledTableCell key="status">
        Posted
      </StyledTableCell>
    ),
  },
  {
    colName: 'edit',
    varKey: 'edit',
    customHeader: (
      <Button
        color="primary"
        variant="outlined"
        onClick={clearFilters}
        fullWidth
        disabled={!filtersApplied}
      >
        Clear filters
      </Button>
    ),
    sortable: false,
    colType: ITableItemType.TEXT,
    child: (value: JournalEntry) => (
      <StyledTableCell
        className={clsx(classes.colorBlue, classes.width5)}
        key="id"
      >
        <Box display="flex">
          <Button
            onClick={() => onView(value.id)}
            color="primary"
          >
            View
          </Button>
          <Button
            color="primary"
            onClick={() => onEdit(value.id, value.postingDate)}
          >
            Edit
            <EditOutlinedIcon className={clsx(classes.colorBlue, classes.editIcon)} />
          </Button>
        </Box>
      </StyledTableCell>
    ),
  },
];
