import React from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  getAccountNameFromList,
  getClassName,
} from '../../../Subledger/common';
import {
  AmortizationSource,
  ProductCategory,
  LegacySchedulingMethod,
  Subledger,
} from '../../../../interfaces/types';
import { AMORTIZATION_POSTED } from '../../../../util/constants';
import { accountInfoSelector } from '../../../../store/selectors/account';
import COLORS from '../../../../theme/colors';
import currencyFormatter from '../../../../util/currencyFormatter';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '11px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  subTitle: {
    fontSize: '11px',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    color: COLORS.lightGray,
    marginTop: '12px',
  },
  boxTitle: {
    fontSize: '13px',
    fontWeight: 'normal',
    lineHeight: '15px',
    marginTop: '20px',
    marginBottom: '10px',
    textTransform: 'uppercase',
    color: COLORS.lightGray,
    '&:first-of-type': {
      marginTop: 0,
    },
  },
}));

interface Props {
  subledger: Subledger;
  selectedSubledger: AmortizationSource;
}

const PrepaidExpensesDetailsSection = ({ subledger, selectedSubledger }: Props) => {
  const classes = useStyles();
  const account = useSelector(accountInfoSelector)!;
  const {
    accountBalances,
    accountClasses,
    accountIncomes,
    classTrackingPerTxn,
    classTrackingPerTxnLine,
  } = account;
  const {
    classId,
    destinationId,
    amortizationScheduleDetails,
    amortizationScheduleType,
  } = selectedSubledger.amortizationSchedule;

  const isManualScheduleType = amortizationScheduleType === LegacySchedulingMethod.MANUAL;
  const remainingDuration = amortizationScheduleDetails
    ?.filter((schedule) => schedule.status !== AMORTIZATION_POSTED).length;
  const isCategoryPrepaid = subledger.productCategory === ProductCategory.PrepaidExpense;

  const getDurationType = () => {
    switch (amortizationScheduleType) {
      case LegacySchedulingMethod.MONTHLY:
        return remainingDuration && remainingDuration === 1 ? 'month' : 'months';
      case LegacySchedulingMethod.DAILY:
        return remainingDuration && remainingDuration === 1 ? 'day' : 'days';
      default:
        return '';
    }
  };

  const periodicAmortization = amortizationScheduleDetails?.[0]?.amount;
  const prepaidAssets = getAccountNameFromList(subledger.account.id, accountBalances);
  const amortizedExpense = destinationId ? getAccountNameFromList(destinationId, accountIncomes) : '';
  const className = classId ? getClassName(classId, accountClasses) : null;
  const cumulativeAmortized = amortizationScheduleDetails
    ?.filter((schedule) => schedule.status === AMORTIZATION_POSTED)
    .reduce((a, b) => Number(a) + Number(b.amount), 0) as number || 0;

  const prepaidRemaining = selectedSubledger.startingBalance - cumulativeAmortized;
  const isDisposed = prepaidRemaining === 0;

  return (
    <>
      <Box
        padding="0 30px 15px"
        borderRight={`1px solid ${COLORS.lightGray2}`}
      >
        <Typography className={classes.title}>
          {isCategoryPrepaid ? 'Prepaid Expense Details' : 'Revenue Recognition Details'}
        </Typography>
        <Typography className={classes.subTitle}>Remaining Duration</Typography>
        {isManualScheduleType ? <>&mdash;</> : `${remainingDuration} ${getDurationType()}`}
        <Typography className={classes.subTitle}>
          {isCategoryPrepaid ? 'Periodic Amortization' : 'Periodic Recognition'}
        </Typography>
        {isManualScheduleType ? <>&mdash;</> : currencyFormatter.format(Number(periodicAmortization))}
        {(classTrackingPerTxnLine || classTrackingPerTxn) && (
          <>
            <Typography className={classes.subTitle}>Class</Typography>
            {className}
          </>
        )}
      </Box>
      <Box padding="0 30px 15px">
        <Typography className={classes.title}>Accounts</Typography>
        <Typography className={classes.subTitle}>
          {isCategoryPrepaid ? 'Prepaid Assets' : 'Deferred Revenue Liability'}
        </Typography>
        {prepaidAssets}
        <Typography className={classes.subTitle}>
          {isCategoryPrepaid ? 'Amortized Expense' : 'Revenue'}
        </Typography>
        {amortizedExpense}
      </Box>
      <Box
        fontSize="18px"
        bgcolor={COLORS.lightGray3}
        padding="20px 30px"
        width="285px"
        marginLeft="auto"
        borderRadius="4px"
        border={isDisposed ? `2px solid ${COLORS.violet}` : 'none'}
      >
        <Typography className={classes.boxTitle}>
          {isCategoryPrepaid ? 'Prepaid Remaining' : 'Unrecognized Revenue'}
        </Typography>
        {currencyFormatter.format(prepaidRemaining)}
        <Typography className={classes.boxTitle}>
          {isCategoryPrepaid ? 'Cumulative Amortized' : 'Total Recognized Value'}
        </Typography>
        {currencyFormatter.format(cumulativeAmortized)}
      </Box>
    </>
  );
};

export default React.memo(PrepaidExpensesDetailsSection);
