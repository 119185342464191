import React from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import roundTo from 'round-to';
import {
  AmortizationSource,
  Subledger,
  ProductCategory,
} from '../../interfaces/types';
import {
  getChildren,
  getPrepaidAssetsForSave,
  checkSchedulerAmortizationSourceDirtyCheck,
} from './common';
import UnScheduledRow from './UnScheduledPrepaidExpenseRow';
import {
  ADD_SPLIT,
  ADD_SPLIT_ASSET,
  INPUT_FOCUS,
  SELECT_ROW,
  UNSCHEDULED_DATE_CHANGE,
  UNSCHEDULED_INPUT_CHANGE,
  UNSCHEDULED_REMOVE_ASSET,
  UNSCHEDULED_SELECT_CHANGE,
  UPDATE_ASSET,
} from '../../util/constants';
import UnScheduledRevSyncRow from './UnScheduledRevSyncRow';
import AddSplitButtonRow from './AddSplitButtonRow';
import { isUpdatingSubledger } from '../../store/selectors/subledger';
import { AppThunkDispatch } from '../../store/store';
import { patchUnscheduledAmortizationSources } from '../../store/slices/subledger';
import { Loader } from '../Loader';

interface Props {
  subledger: Subledger;
  initSubledger?: Subledger;
  scheduleDate: string;
  selectedRow: string;
  unScheduledAssets: Array<AmortizationSource>;
  dispatch: any;
}

const UnScheduleAssets = ({
  subledger,
  initSubledger,
  scheduleDate,
  dispatch,
  unScheduledAssets,
  selectedRow,
}: Props) => {
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const loading = useSelector(isUpdatingSubledger);

  const onSave = (existingAsset: AmortizationSource) => async () => {
    const {
      amortizationSource,
      amortizationSources,
    } = await getPrepaidAssetsForSave(existingAsset, dispatch, scheduleDate, subledger);
    if (!amortizationSource) {
      return;
    }
    reduxDispatch(patchUnscheduledAmortizationSources({
      subledgerId: subledger.id,
      amortizationSources,
      productCategory: subledger.productCategory,
    }))
      .unwrap()
      .then((result) => {
        dispatch({
          type: UPDATE_ASSET,
          payload: {
            assets: result?.amortizationSources,
            // @ts-ignore - balance doesn't exist on Subledger interface
            balance: roundTo(result?.balance, 2),
            internalId: amortizationSource.internalId,
          },
        });
      });
  };

  const addSplit = (internalId: string) => () => {
    dispatch({
      type: ADD_SPLIT,
      payload: { internalId },
    });
  };

  const addSplitAsset = (internalId: string) => () => {
    dispatch({
      type: ADD_SPLIT_ASSET,
      payload: { internalId },
    });
  };

  const removeAsset = (internalId: string) => () => {
    dispatch({
      type: UNSCHEDULED_REMOVE_ASSET,
      payload: { internalId },
    });
  };

  const onDateChange = (propertyName: string, internalId: string, value: Date | null) => {
    dispatch({
      type: UNSCHEDULED_DATE_CHANGE,
      payload: {
        internalId,
        propertyName,
        value,
        scheduleDate,
      },
    });
  };

  const onAutoCompleteChange = (
    propertyName: string, internalId: string,
  ) => (value: string) => {
    dispatch({
      type: UNSCHEDULED_SELECT_CHANGE,
      payload: {
        internalId,
        propertyName,
        value,
        scheduleDate,
      },
    });
  };

  const onInputBoxChange = (
    propertyName: string, internalId: string,
  ) => (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: UNSCHEDULED_INPUT_CHANGE,
      payload: {
        internalId,
        propertyName,
        value: event.target.value,
        scheduleDate,
      },
    });
  };

  const onInputBoxFocus = (
    propertyName: string,
    internalId: string,
  ) => (event: React.FocusEvent<HTMLInputElement>) => {
    dispatch({
      type: INPUT_FOCUS,
      payload: {
        propertyName,
        internalId,
        target: event.target,
        type: event.type,
      },
    });
  };

  const onRowSelect = (internalId: string) => () => {
    dispatch({
      type: SELECT_ROW,
      payload: { selectedRow: internalId },
    });
  };

  const isSaveEnabled = (
    asset: AmortizationSource,
  ) => checkSchedulerAmortizationSourceDirtyCheck(asset, subledger, initSubledger);

  const renderUnScheduledRow = (asset: AmortizationSource) => {
    const children = getChildren(asset.internalId, subledger?.amortizationSources);
    const parent = (
      <UnScheduledRow
        selectedRow={selectedRow}
        childrenPrepaidAssets={children}
        addSplit={addSplit}
        amortizationSource={asset}
        hasChildren={!!children?.length}
        isSaveEnabled={isSaveEnabled}
        onDateChange={onDateChange}
        onInputBoxChange={onInputBoxChange}
        onInputBoxFocus={onInputBoxFocus}
        onSave={onSave}
        onAutoCompleteChange={onAutoCompleteChange}
        parentAsset={asset}
        removeAsset={removeAsset}
        onRowSelect={onRowSelect}
        key={asset.internalId}
        subledgerId={subledger?.id}
      />
    );
    if (!children?.length) {
      return parent;
    }
    return (
      <>
        {parent}
        {children?.map((child: AmortizationSource, index: number) => (
          <UnScheduledRow
            onRowSelect={onRowSelect}
            selectedRow={selectedRow}
            childrenPrepaidAssets={children}
            addSplit={addSplit}
            amortizationSource={child}
            hasChildren={false}
            isSaveEnabled={isSaveEnabled}
            onDateChange={onDateChange}
            onInputBoxChange={onInputBoxChange}
            onInputBoxFocus={onInputBoxFocus}
            onSave={onSave}
            onAutoCompleteChange={onAutoCompleteChange}
            parentAsset={asset}
            removeAsset={removeAsset}
            key={child.internalId}
            lastChild={index === children?.length - 1 && selectedRow !== asset?.internalId}
            subledgerId={subledger?.id}
          />
        ))}
        {
          selectedRow === asset?.internalId && (
            <AddSplitButtonRow
              addSplitAsset={addSplitAsset(asset.internalId)}
            />
          )
        }
      </>
    );
  };
  const renderRevSyncUnScheduledRow = (asset: AmortizationSource) => {
    const children = getChildren(asset.internalId, subledger?.amortizationSources);
    const parent = (
      <UnScheduledRevSyncRow
        selectedRow={selectedRow}
        childrenPrepaidAssets={children}
        addSplit={addSplit}
        amortizationSource={asset}
        hasChildren={!!children?.length}
        isSaveEnabled={isSaveEnabled}
        onDateChange={onDateChange}
        onInputBoxChange={onInputBoxChange}
        onInputBoxFocus={onInputBoxFocus}
        onSave={onSave}
        onAutoCompleteChange={onAutoCompleteChange}
        removeAsset={removeAsset}
        onRowSelect={onRowSelect}
        key={asset.internalId}
        parentAsset={asset}
        subledgerId={subledger?.id}
      />
    );
    if (!children?.length) {
      return parent;
    }
    return (
      <>
        {parent}
        {children?.map((child: AmortizationSource, index: number) => (
          <UnScheduledRevSyncRow
            onRowSelect={onRowSelect}
            selectedRow={selectedRow}
            childrenPrepaidAssets={children}
            addSplit={addSplit}
            amortizationSource={child}
            hasChildren={false}
            isSaveEnabled={isSaveEnabled}
            onDateChange={onDateChange}
            onInputBoxChange={onInputBoxChange}
            onInputBoxFocus={onInputBoxFocus}
            onSave={onSave}
            onAutoCompleteChange={onAutoCompleteChange}
            removeAsset={removeAsset}
            key={child.internalId}
            lastChild={index === children?.length - 1 && selectedRow !== asset?.internalId}
            parentAsset={asset}
            subledgerId={subledger?.id}
          />
        ))}
        {
          selectedRow === asset?.internalId && (
            <AddSplitButtonRow
              addSplitAsset={addSplitAsset(asset.internalId)}
            />
          )
        }
      </>
    );
  };
  return (
    <>
      <Loader open={loading} />
      {
        subledger?.productCategory === ProductCategory.PrepaidExpense
        && unScheduledAssets?.filter((asset) => !asset?.parentId)
          .map(renderUnScheduledRow)
      }
      {
        subledger?.productCategory === ProductCategory.DeferredRevenue
        && unScheduledAssets?.filter((asset) => !asset?.parentId)
          .map(renderRevSyncUnScheduledRow)
      }
    </>
  );
};

export default React.memo(UnScheduleAssets);
