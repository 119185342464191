/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { request } from '../../util/request';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../interfaces/types';
import { TagDetails } from '../../interfaces/tags';
import { handleAuthError } from '../../util/auth';

const PREFIX = 'v2Tags';
const GET_ALL_TAGS = `${PREFIX}/getAllTags`;
const PATCH_TAG = `${PREFIX}/patchTag`;

interface State {
  isFetchingTags: boolean;
  isUpdatingTags: boolean;
  tags: Array<TagDetails>;
}

const initialState: State = {
  isFetchingTags: false,
  isUpdatingTags: false,
  tags: [],
};

export const getAllTags = createAsyncThunk<{ tags: Array<TagDetails> }>(
  GET_ALL_TAGS,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request().get<{ tags: Array<TagDetails> }>('/v2/tags');

      return data;
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';

      if (handledError === 'invalidated authorization token') {
        handleAuthError();
      }

      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const patchTag = createAsyncThunk<TagDetails, {
  tagId: string;
  visible: boolean;
}>(
  PATCH_TAG,
  async ({ tagId, visible }, { rejectWithValue }) => {
    try {
      const { data } = await request().patch<TagDetails>(`/v2/tags/${tagId}`, { visible });

      return data;
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';

      if (handledError === 'invalidated authorization token') {
        handleAuthError();
      }

      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const tagsSlice = createSlice({
  initialState,
  name: PREFIX,
  reducers: {
    clearTags: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllTags.pending, (state) => {
      state.isFetchingTags = true;
    });
    builder.addCase(getAllTags.fulfilled, (state, { payload }) => {
      state.isFetchingTags = false;
      state.tags = [...payload.tags];
    });
    builder.addCase(getAllTags.rejected, (state) => {
      state.isFetchingTags = false;
    });

    builder.addCase(patchTag.pending, (state) => {
      state.isUpdatingTags = true;
    });
    builder.addCase(patchTag.fulfilled, (state, { payload }) => {
      state.isUpdatingTags = false;
      state.tags = state.tags.map((tag) => (tag.id === payload.id ? payload : tag));
    });
    builder.addCase(patchTag.rejected, (state) => {
      state.isUpdatingTags = false;
    });
  },
});

export const {
  clearTags,
} = tagsSlice.actions;

export const tagsReducer = tagsSlice.reducer;
