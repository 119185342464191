import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  addMonths,
  endOfMonth,
  format,
  isEqual,
  lastDayOfMonth,
  startOfMonth,
  subMonths,
} from 'date-fns';
import clsx from 'clsx';
import {
  Box,
  Button,
  ButtonBase,
  createStyles,
  Typography,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MonthPicker from '../../../MonthPicker';
import { MONTH_SHORT_FORMAT } from '../../../../util/constants';
import {
  ArrowLeft,
  ArrowRight,
} from '../../../Icons';
import COLORS from '../../../../theme/colors';

interface Props {
  selectedDate: Date;
  currentPeriodDate: Date;
  handleChange: (date: Date) => void;
  isDisabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

const useStyles = makeStyles(() => createStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    padding: '8px 8px 8px 16px',
    borderRadius: '6px',
    backgroundColor: COLORS.white,
  },
  navigationArrows: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 28,
    height: 28,
    minWidth: 0,
    borderRadius: '4px',
    margin: 0,
    '& + &': {
      marginLeft: '8px',
    },
  },
  currentPeriodArrows: {
    backgroundColor: COLORS.grayNeutral,
    '&:hover, &:focus': {
      boxShadow: `0 0 0 3px ${COLORS.lightGrayBackground}`,
      backgroundColor: COLORS.grayNeutral,
    },
  },
  periodText: {
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 500,
  },
  dateInfo: {
    fontSize: 14,
    lineHeight: '22px',
    marginLeft: '16px',
    marginRight: '12px',
    fontWeight: 700,
  },
  monthPicker: {
    marginRight: '28px',
    minWidth: '120px',
  },
  displayFlex: {
    display: 'flex',
  },
  dropDownIcon: {
    marginTop: -1,
    marginLeft: '15px',
  },
}));

export const PeriodSelector = ({
  selectedDate,
  currentPeriodDate,
  handleChange,
  isDisabled,
  minDate,
  maxDate,
}: Props) => {
  const classes = useStyles();
  const isCurrentPeriod = isEqual(lastDayOfMonth(currentPeriodDate), lastDayOfMonth(selectedDate));

  return (
    <Box
      className={classes.container}
      border={`1px solid ${isCurrentPeriod ? COLORS.disabled : COLORS.violet}`}
      color={isCurrentPeriod ? COLORS.black : COLORS.violet}
    >
      {!isCurrentPeriod && currentPeriodDate && (
        <Button
          style={{ marginRight: '40px' }}
          variant="outlined"
          color="secondary"
          onClick={() => handleChange(lastDayOfMonth(currentPeriodDate))}
        >
          Current period
        </Button>
      )}
      <Typography className={classes.periodText}>{isCurrentPeriod ? 'Current Period:' : 'Previous period:'}</Typography>
      {isDisabled
        ? (
          <Typography className={classes.dateInfo}>{format(selectedDate, MONTH_SHORT_FORMAT)}</Typography>
        )
        : (
          <MonthPicker
            selectedDate={selectedDate}
            onDateChange={(date) => handleChange(lastDayOfMonth(date!))}
            minDate={minDate ? startOfMonth(minDate) : undefined}
            maxDate={maxDate ? endOfMonth(maxDate) : undefined}
            customInput={(
              <ButtonBase className={classes.monthPicker}>
                <Typography className={classes.dateInfo}>{format(selectedDate, MONTH_SHORT_FORMAT)}</Typography>
                <ArrowDropDownIcon className={classes.dropDownIcon} />
              </ButtonBase>
            )}
          />
        )}
      {!isDisabled && (
        <>
          <Button
            variant="contained"
            color="secondary"
            className={clsx(classes.navigationArrows, {
              [classes.currentPeriodArrows]: isCurrentPeriod,
            })}
            onClick={() => handleChange(lastDayOfMonth(subMonths(selectedDate, 1)))}
            disabled={minDate && isEqual(startOfMonth(selectedDate), startOfMonth(minDate))}
          >
            <ArrowLeft />
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={clsx(classes.navigationArrows, {
              [classes.currentPeriodArrows]: isCurrentPeriod,
            })}
            onClick={() => handleChange(lastDayOfMonth(addMonths(selectedDate, 1)))}
            disabled={maxDate && isEqual(startOfMonth(selectedDate), startOfMonth(maxDate))}
          >
            <ArrowRight />
          </Button>
        </>
      )}
    </Box>
  );
};
