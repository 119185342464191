import React from 'react';
import {
  createStyles,
  makeStyles,
  Tooltip,
  Typography,
  IconButton,
} from '@material-ui/core';
import clsx from 'clsx';
import COLORS from '../../theme/colors';
import { InfoIcon } from '../Icons';

const useStyles = makeStyles(() => createStyles({
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14px',
    color: COLORS.grayNeutral,
    height: 20,
  },
  headerTypeModal: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '22px',
    height: 'auto',
  },
  headerCentered: {
    alignItems: 'center',
  },
  withSpaceBottom: {
    marginBottom: '8px',
  },
  headerText: {
    marginRight: '10px',
  },
  noOffset: {
    minHeight: 'auto',
    margin: 0,
    padding: 0,
  },
}));

interface Props {
  headerText: string;
  tooltipText?: string;
  centered?: boolean;
  withSpaceBottom?: boolean;
  headerType?: 'modal';
}

export const TooltipHeader = ({
  headerText,
  tooltipText,
  centered,
  withSpaceBottom,
  headerType,
}: Props) => {
  const classes = useStyles();

  return (
    <Typography
      className={clsx(classes.header, {
        [classes.headerTypeModal]: headerType === 'modal',
        [classes.headerCentered]: centered || tooltipText,
        [classes.withSpaceBottom]: withSpaceBottom,
      })}
    >
      <span className={classes.headerText}>{headerText}</span>
      {tooltipText && (
        <Tooltip
          title={tooltipText}
          arrow
          placement="top"
        >
          <IconButton className={classes.noOffset}>
            <InfoIcon style={{ color: COLORS.skyBlue }} />
          </IconButton>
        </Tooltip>
      )}
    </Typography>
  );
};
