import React, {
  useEffect,
  useState,
} from 'react';
import clsx from 'clsx';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useForm } from 'react-hook-form';
import Box from '@material-ui/core/Box';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CachedIcon from '@material-ui/icons/Cached';
import LockIcon from '@material-ui/icons/Lock';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  createStyles,
  Theme,
  Button,
  Tooltip,
} from '@material-ui/core';
import {
  format,
  formatRelative,
  parseISO,
} from 'date-fns';
import useInterval from '@use-it/interval';
import { useHistory } from 'react-router';

import COLORS from '../../theme/colors';
import {
  PageTitle,
  TopBarContainer,
  TopBarLabel,
  TopBarBoldLabel,
  StyledResyncButton,
  StyledAppBar,
  TopBarItem,
} from './TopBar.styled';
import { FULL_DAY_FORMAT } from '../../util/constants';
import { Company } from '../../interfaces/types';
import { setToken } from '../../util/auth';
import { AppThunkDispatch } from '../../store/store';
import {
  getAccountSync,
  postAccountCompanySwitch,
} from '../../store/slices/account';
import { postV2Sync } from '../../store/slices/v2Sync';
import { accountInfoSelector } from '../../store/selectors/account';
import { Loader } from '../Loader';
import { clearLastDismissedError } from '../../store/slices/notices';
import { lastMessageSelector } from '../../store/selectors/notices';
import FactaAutocomplete from '../Inputs/FactaAutocomplete';

interface Props {
  title: string;
  showCompanyInfo?: boolean;
  isNegative?: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  selectBox: {
    minWidth: 260,
  },
  marginLeft8: {
    marginLeft: 8,
  },
  medGray: {
    color: COLORS.medGray,
  },
  appBar: {
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  helpButton: {
    minWidth: 'auto',
  },
  errorIcon: {
    color: COLORS.error,
  },
  negative: {
    backgroundColor: COLORS.white,
  },
}));

const TopBar = ({
  title,
  showCompanyInfo = true,
  isNegative,
}: Props) => {
  const classes = useStyles();
  const [lastSyncString, setLastSyncString] = useState<string>('');
  const history = useHistory();
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const accountInfo = useSelector(accountInfoSelector);
  const lastMessage = useSelector(lastMessageSelector);
  const [isLoading, setIsLoading] = useState(false);

  const {
    lastSync,
    companyId: accountCompanyId,
    companies,
    bookCloseDate,
  } = showCompanyInfo ? accountInfo! : {
    lastSync: new Date(),
    companyId: '',
    companies: [],
    bookCloseDate: '',
  };

  const {
    control,
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      company: companies.find((company) => company.id === accountCompanyId),
    },
  });

  const onSyncData = () => {
    setIsLoading(true);
    reduxDispatch(getAccountSync())
      .finally(() => reduxDispatch(postV2Sync())
        .finally(() => {
          setIsLoading(false);
        }));
  };

  const handleChangeClient = (values: { company: Company }) => {
    const selectedCompanyId = values.company.id;

    if (selectedCompanyId === accountCompanyId) return;

    reduxDispatch(postAccountCompanySwitch({ companyId: selectedCompanyId }))
      .unwrap()
      .then(async ({ token }) => {
        setToken(token);
        onSyncData();
        history.push('/');
      });
  };

  const handleErrorClick = () => {
    reduxDispatch(clearLastDismissedError());
  };

  useInterval(() => {
    setLastSyncString(lastSync ? formatRelative(new Date(lastSync), new Date())
      .replace(' at', ',') : '');
  }, 60000); // delay for a minute

  useEffect(() => {
    setLastSyncString(lastSync ? formatRelative(new Date(lastSync), new Date())
      .replace(' at', ',') : '');
  }, [lastSync]);

  return (
    <>
      <Loader open={isLoading} />
      <StyledAppBar
        className={clsx(classes.appBar, {
          [classes.negative]: isNegative,
        })}
        position="static"
      >
        <TopBarContainer>
          <PageTitle>
            {title}
          </PageTitle>
          {showCompanyInfo && (
            <>
              <TopBarItem>
                <TopBarLabel>
                  Customer
                </TopBarLabel>
                <FactaAutocomplete
                  name="company"
                  control={control}
                  defaultValue={null}
                  onChange={handleSubmit(handleChangeClient)}
                  className={classes.selectBox}
                  options={companies}
                  optionName="companyName"
                  disableClearable
                  additionalActions={[
                    {
                      label: 'Add company',
                      action: () => history.push('/settings/connect?addCompany=true'),
                    },
                  ]}
                />
              </TopBarItem>
              <TopBarItem>
                <TopBarBoldLabel>
                  Last Sync
                </TopBarBoldLabel>
                <TopBarLabel>
                  {lastSyncString}
                </TopBarLabel>
                <StyledResyncButton
                  startIcon={(
                    <CachedIcon />
                  )}
                  onClick={onSyncData}
                  size="small"
                >
                  Sync now
                </StyledResyncButton>
              </TopBarItem>
              {lastMessage && (
                <TopBarItem margin="0 !important">
                  <Tooltip
                    arrow
                    title={lastMessage.message}
                  >
                    <Button
                      className={classes.helpButton}
                      onClick={handleErrorClick}
                    >
                      <ErrorOutlineIcon className={classes.errorIcon} />
                    </Button>
                  </Tooltip>
                </TopBarItem>
              )}
              <Box flexGrow={1} />
              <TopBarItem
                margin="8px 14px 8px 14px !important"
                paddingLeft="14px"
              >
                <LockIcon
                  className={classes.medGray}
                  fontSize="small"
                />
                <TopBarLabel className={classes.marginLeft8}>
                  Last Close Date:
                </TopBarLabel>
                <TopBarBoldLabel>
                  {bookCloseDate ? format(parseISO(bookCloseDate), FULL_DAY_FORMAT) : 'N/A'}
                </TopBarBoldLabel>
              </TopBarItem>
              <TopBarItem
                margin="0 14px 0 auto !important"
                padding="0 0 0 14px"
                borderLeft={`1px solid ${COLORS.lightGray2}`}
              >
                <Tooltip
                  arrow
                  title="Help Center"
                >
                  <Button
                    className={classes.helpButton}
                    href="https://help.facta.io"
                    target="_blank"
                  >
                    <HelpOutlineIcon
                      className={classes.medGray}
                      fontSize="small"
                    />
                  </Button>
                </Tooltip>
              </TopBarItem>
            </>
          )}
        </TopBarContainer>
      </StyledAppBar>
    </>
  );
};

export default TopBar;
