import {
  useEffect,
  useRef,
} from 'react';
// @ts-ignore
import markerSDK from '@marker.io/browser';
import { useSelector } from 'react-redux';
import { accountInfoSelector } from '../../store/selectors/account';

interface Props {
  destination: string;
}

export const MarkerIo = ({ destination }: Props) => {
  const accountInfo = useSelector(accountInfoSelector);
  const widget = useRef<any>();

  const loadMarkerIo = async () => {
    widget.current = await markerSDK.loadWidget({
      destination,
      customData: {},
    });
  };

  useEffect(() => {
    if (widget?.current) {
      widget.current.setCustomData({
        company: accountInfo?.companyName,
        companyId: accountInfo?.companyId,
      });
    }
  }, [accountInfo, widget]);

  useEffect(() => {
    loadMarkerIo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
