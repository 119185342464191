import clsx from 'clsx';
import {
  Box,
  TableCell,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useTableStyles } from '../Subledger.styled';
import { AmortizationSource } from '../../../interfaces/types';
import { getAccountNameFromList } from '../common';
import { accountInfoSelector } from '../../../store/selectors/account';

interface Props {
  amortizationSource: AmortizationSource;
  hasChildren?: boolean;
  lastChild?: boolean;
  highlighted?: boolean;
  showCursor?: boolean;
  isCellSticky?: string;
}

const ExpenseAccountCell = ({
  amortizationSource,
  hasChildren,
  lastChild,
  highlighted,
  showCursor,
  isCellSticky,
}: Props) => {
  const tableClasses = useTableStyles();
  const account = useSelector(accountInfoSelector)!;
  const { accountIncomes } = account;
  return (
    <TableCell
      className={clsx(tableClasses.customerCell, {
        [tableClasses.child]: amortizationSource.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.bold]: highlighted,
        [tableClasses.lastChild]: lastChild,
        [tableClasses.cursorPointer]: showCursor,
      })}
      is-cell-sticky={isCellSticky}
    >
      {
        hasChildren
        && (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Typography className={tableClasses.fontSize13}>Multiple</Typography>
            <ArrowDropDownIcon />
          </Box>
        )
      }
      {
        !hasChildren
        && getAccountNameFromList(amortizationSource?.amortizationSchedule?.destinationId!, accountIncomes)
      }
    </TableCell>
  );
};

export default React.memo(ExpenseAccountCell);
