import React from 'react';
import { useHistory } from 'react-router';
import { format } from 'date-fns';
import {
  Link,
  makeStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import ErrorIcon from '@material-ui/icons/Error';
import { OutOfPeriodData } from '../../../../interfaces/types';
import { MONTH_LONG_FORMAT } from '../../../../util/constants';
import COLORS from '../../../../theme/colors';

const useStyles = makeStyles({
  link: {
    color: COLORS.error,
    fontWeight: 700,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
});

interface Props {
  outOfPeriodData: OutOfPeriodData;
}

export const OutOfPeriodWarning = ({ outOfPeriodData }: Props) => {
  const history = useHistory();
  const classes = useStyles();
  const {
    outOfPeriodTransactions,
    outOfPeriodDate,
    outOfPeriodMessage,
    outOfPeriodNavLink,
  } = outOfPeriodData;

  const handleClick = (url?: string) => {
    if (url) {
      history.push(url);
    }
  };

  return (
    <Alert
      elevation={0}
      severity="error"
      icon={<ErrorIcon />}
      variant="outlined"
      style={{ margin: '20px 20px 10px', maxWidth: 320 }}
    >
      <span>
        {outOfPeriodTransactions > 0 ? `${outOfPeriodTransactions} ` : ''}
        {outOfPeriodMessage}
      </span>
      {outOfPeriodDate && (
        <span>
          identified in
          {' '}
          <Link
            className={classes.link}
            onClick={() => handleClick(outOfPeriodNavLink)}
          >
            {format(outOfPeriodDate, MONTH_LONG_FORMAT)}
          </Link>
        </span>
      )}
    </Alert>
  );
};
