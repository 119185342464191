import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { LocalizationProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import FullStory from 'react-fullstory';
import './util/sentry';
import theme from './theme/theme';
import Notifier from './components/Notifier';
import AppRouter from './pages/AppRouter';
import NavBarContext from './context/navbarContext';
import RefreshDataContext from './context/dataRefreshContext';
import SessionExpiredDialog from './components/DialogBox/SessionExpiredDialog';
import { featureSwitch } from './util/featureSwitch';
import { MarkerIo } from './components/MarkerIo';

const App = () => (
  <RefreshDataContext>
    <NavBarContext open={localStorage.getItem('navBarOpen') !== 'false'}>
      <LocalizationProvider dateAdapter={DateFnsUtils}>
        <ThemeProvider theme={theme}>
          {featureSwitch.fullStory && <FullStory org={process.env.REACT_APP_FULLSTORY_ORG!} />}
          <CssBaseline />
          <Notifier />
          <SessionExpiredDialog />
          <AppRouter />
          {featureSwitch.markerIO && <MarkerIo destination={process.env.REACT_APP_MARKERIO_DESTINATION!} />}
        </ThemeProvider>
      </LocalizationProvider>
    </NavBarContext>
  </RefreshDataContext>

);

export default App;
