import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  Box,
  createStyles,
} from '@material-ui/core';
import {
  useSelector,
} from 'react-redux';
import AccountBalanceCard from '../../../AccountBalanceCard';
import { V2Subledger } from '../../../../interfaces/subledgers';
import { accountGLBalanceSelector } from '../../../../store/selectors/v2Accounts';
import COLORS from '../../../../theme/colors';
import { calculateSubledgerDetails } from '../../../../util/pages/FixedAssets/calculateSubledgerDetails';
import {
  fixedAssetsListSelector,
  fixedAssetsListWithoutQBOSelector,
} from '../../../../store/selectors/v2Subledgers';
import { AccountDetails } from '../../../AccountDetails';

const useStyles = makeStyles(() => createStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '32px',
  },
  accountDetails: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    paddingRight: 32,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
  },
  accountName: {
    marginLeft: 16,
    fontWeight: 500,
    lineHeight: '26px',
  },
  accumulationAccount: {
    marginTop: '9px',
    fontSize: '12px',
    lineHeight: '14px',
    color: COLORS.grayNeutral,
  },
}));

interface Props {
  subledgerDetails: V2Subledger;
  showScheduled?: boolean;
  showIngestedTransactions?: boolean;
  selectedDate?: string;
}

const GLBalancesInfo = ({
  subledgerDetails,
  showScheduled,
  showIngestedTransactions = true,
  selectedDate,
}: Props) => {
  const accountGLBalance = useSelector(accountGLBalanceSelector);
  const fixedAssetsList = useSelector(showIngestedTransactions
    ? fixedAssetsListSelector
    : fixedAssetsListWithoutQBOSelector);
  const classes = useStyles();
  const currentFactaBalance = showScheduled
    ? calculateSubledgerDetails({ fixedAssets: fixedAssetsList, asOfDate: selectedDate }).assetsScheduledValue
    : calculateSubledgerDetails({ fixedAssets: fixedAssetsList }).currentFactaBalance;
  const difference = accountGLBalance - currentFactaBalance;

  return (
    <Box className={classes.wrapper}>
      <AccountDetails
        accumulationAccountId={subledgerDetails.accumulationAccount}
        subledgerAccountId={subledgerDetails.accountId}
      />
      <AccountBalanceCard
        withType={false}
        headerText="Current GL"
        tooltipText="GL balance as of Facta Start Date"
        amount={accountGLBalance || 0}
      />
      <AccountBalanceCard
        withType={false}
        headerText="Current Facta"
        tooltipText="Facta subledger balance as of Facta Start Date"
        amount={currentFactaBalance || 0}
        isAmountHighlighted
      />
      <AccountBalanceCard
        withType
        headerText="Difference"
        tooltipText="Difference between GL balance and Facta subledger balance as of Facta Start Date"
        amount={Math.abs(difference) === 0 ? 0 : difference}
        isAmountHighlighted
      />
    </Box>
  );
};

export default GLBalancesInfo;
