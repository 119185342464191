import React, {
  useEffect,
  useState,
} from 'react';
import find from 'lodash.find';
import {
  Box,
  styled,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
  compareAsc,
  isBefore,
  parseISO,
} from 'date-fns';
import { Account } from '../../../../interfaces/accounts';
import { DashboardItem } from '../DashboardItem';
import {
  NestedSubledgerDetails,
  V2Subledger,
} from '../../../../interfaces/subledgers';
import COLORS from '../../../../theme/colors';
import AccountBalanceCard from '../../../../components/AccountBalanceCard';
import Alert from '../../../../components/Alert';
import { AppThunkDispatch } from '../../../../store/store';
import { getAccountBalance } from '../../../../store/slices/v2Accounts';
import { calculateSubledgerDetails } from '../../../../util/pages/FixedAssets/calculateSubledgerDetails';
import { precisionRound } from '../../../../util/math';
import { parseToIncludeCurrentDay } from '../../../../util/timezone';
import { ClosedTag } from '../../../../components/Subledger/FixedAssets/ClosedTag';
import { CheckFactaIcon } from '../../../../components/Icons';

const GroupName = styled(Typography)({
  fontSize: 20,
  lineHeight: '32px',
  fontWeight: 'bold',
});

const GroupContainer = styled(Box)({
  backgroundColor: COLORS.white,
  padding: '24px 0 24px 24px',
  marginTop: '32px',
  boxShadow: COLORS.containerBoxShadow,
});

const GroupDetails = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginTop: '32px',
});

const GroupDetailsHeader = styled(Typography)({
  display: 'flex',
  alignItems: 'flex-start',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '14px',
  color: COLORS.grayNeutral,
  height: 20,
});

const GroupDetailsValue = styled(Typography)({
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 600,
});

interface Props {
  accumulationAccount: Account;
  nestedSubledgers: Array<V2Subledger>;
  accounts: Array<Account>;
  selectedDate: string;
  setSelectedDate: (date: string) => void;
}

export const DashboardGroup = ({
  accumulationAccount,
  nestedSubledgers,
  accounts,
  selectedDate,
  setSelectedDate,
}: Props) => {
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const [accountBalance, setAccountBalance] = useState(accumulationAccount.balance);
  const [isLoadingBalance, setIsLoadingBalance] = useState(false);
  const [subledgerAccountBalances, setSubledgerAccountBalances] = useState<any>({});
  const allSubledgersPriorDepreciation = nestedSubledgers
    .reduce((sum, subledger) => (
      sum + calculateSubledgerDetails({
        fixedAssets: subledger.fixedAssets,
        asOfDate: selectedDate,
        omitCalcOnDisposal: true,
      }).asOfDatePostedAccumulatedDepreciation), 0);

  const numberOfVisibleSubledgers = nestedSubledgers
    .filter((subledger) => find(accounts, { id: subledger.accountId })?.visible)
    .length;

  useEffect(() => {
    setIsLoadingBalance(true);
    reduxDispatch(getAccountBalance({
      accountId: accumulationAccount.id,
      date: parseToIncludeCurrentDay(selectedDate),
    }))
      .unwrap()
      .then((result) => {
        setAccountBalance(result.balance);
      })
      .finally(() => {
        setIsLoadingBalance(false);
      });
  }, [accumulationAccount.id, selectedDate, reduxDispatch]);

  const differenceGLFacta = precisionRound(-accountBalance - allSubledgersPriorDepreciation);
  const withWarning = differenceGLFacta !== 0;

  const nestedSubledgersDetails: Array<NestedSubledgerDetails> = nestedSubledgers.map((sub) => {
    const {
      allAssetsScheduled,
      assetsScheduledValue,
      assetsUnpostedValue,
      currentPeriodPostedValue,
      currentPeriodUnpostedAssetsLength,
    } = calculateSubledgerDetails({ fixedAssets: sub.fixedAssets, asOfDate: selectedDate });

    const isOutOfPeriod = sub.currentPeriodEnd !== sub.latestPeriodEnd
      && !isBefore(parseISO(selectedDate), parseISO(sub.currentPeriodEnd));

    const shouldSchedule = precisionRound(subledgerAccountBalances[sub.id] - assetsScheduledValue) !== 0;
    const shouldPost = !shouldSchedule && (currentPeriodUnpostedAssetsLength > 0 || assetsUnpostedValue);
    const isSubledgerDisabled = compareAsc(parseISO(selectedDate), parseISO(sub.currentPeriodEnd)) > 0;
    const isClosed = (allAssetsScheduled && assetsUnpostedValue === 0 && !shouldPost && !shouldSchedule);

    return {
      id: sub.id,
      shouldSchedule,
      shouldPost,
      isSubledgerDisabled,
      isClosed,
      isOutOfPeriod,
      currentPeriodPostedValue,
      assetsScheduledValue,
      assetsUnpostedValue,
    };
  });

  const areAllSubledgersClosed = nestedSubledgersDetails.every((detail) => detail.isClosed === true);

  const isGroupClosed = areAllSubledgersClosed && differenceGLFacta === 0;

  useEffect(() => {
    setIsLoadingBalance(true);
    const balances: any = {};
    Promise.all(
      nestedSubledgers.map((sub) => reduxDispatch(getAccountBalance({
        accountId: sub.accountId,
        date: parseToIncludeCurrentDay(selectedDate),
      }))
        .unwrap()
        .then((result) => {
          const { id } = sub;
          balances[id] = result.balance;
        })),
    ).finally(() => {
      setIsLoadingBalance(false);
      setSubledgerAccountBalances(balances);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  return numberOfVisibleSubledgers > 0
    ? (
      <GroupContainer>
        <GroupName>{accumulationAccount.name}</GroupName>
        <Box
          display="flex"
          flexWrap="wrap"
        >
          {nestedSubledgers.map((subledgerDetails) => {
            const subledgerAccount = find(accounts, { id: subledgerDetails.accountId });

            return subledgerAccount && (
              <DashboardItem
                key={subledgerDetails.accountId}
                subledgerDetails={subledgerDetails}
                subledgerAccount={subledgerAccount}
                accumulationAccount={accumulationAccount}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                additionalSubledgerDetails={find(nestedSubledgersDetails, { id: subledgerDetails.id })!}
                accountBalance={subledgerAccountBalances[subledgerDetails.id]}
                isLoadingBalance={isLoadingBalance}
              />
            );
          })}
        </Box>
        {withWarning && !isLoadingBalance && (
          <Alert
            elevation={0}
            severity="error"
            variant="outlined"
            style={{ margin: '24px 24px 0 0' }}
          >
            <span>
              <strong>Attention: </strong>
              {'Your Facta accumulated depreciation balance doesn\'t reconcile with your general ledger.'}
            </span>
          </Alert>
        )}
        <GroupDetails>
          <Box marginRight="32px">
            <GroupDetailsHeader>ACCUMULATED DEPRECIATION ACCOUNT</GroupDetailsHeader>
            <GroupDetailsValue>
              {accumulationAccount.displayName}
            </GroupDetailsValue>
          </Box>
          <AccountBalanceCard
            withType={false}
            headerText="GL"
            isLoading={isLoadingBalance}
            amount={Math.abs(accountBalance)}
          />
          <AccountBalanceCard
            withType={false}
            headerText="Facta"
            isLoading={isLoadingBalance}
            amount={allSubledgersPriorDepreciation}
          />
          <AccountBalanceCard
            withType={false}
            headerText="Difference"
            isLoading={isLoadingBalance}
            amount={Math.abs(differenceGLFacta) === 0 ? 0 : differenceGLFacta}
          />
          {isGroupClosed && (
            <ClosedTag>
              <span><CheckFactaIcon /></span>
            </ClosedTag>
          )}
        </GroupDetails>
      </GroupContainer>
    )
    : null;
};
