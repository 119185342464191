import React from 'react';
import PrepareJE from './PrepareJE';
import Layout from '../../../components/Layout';
import { Account } from '../../../interfaces/types';

interface Props {
  scheduleDate: string;
  accountBalance: Account;
}

const PrepareJEContainer = ({
  scheduleDate,
  accountBalance,
}: Props) => (
  <Layout title="REVIEW SCHEDULE">
    <PrepareJE
      scheduleDate={scheduleDate}
      accountBalance={accountBalance}
    />
  </Layout>
);

export default PrepareJEContainer;
