import React, {
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  subDays,
  format,
  startOfMonth,
} from 'date-fns';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import { diff } from 'deep-object-diff';
import cloneDeep from 'lodash.clonedeep';
import roundTo from 'round-to';
import {
  BULK_EDIT,
  BULK_REMOVE_ASSETS,
  DAY_SHORT_FORMAT,
  FACTA_SOURCE,
  INITIALED,
  SUBLEDGER_AMORTIZATION_SCHEDULE_CHANGE,
} from '../../../util/constants';
import Header from './Header';
import DialogBox from '../../../components/DialogBox';
import reducer from '../../../components/Subledger/reducer';
import ErrorPage from '../../../components/ErrorPage';
import CreateSubledgerTable from '../../../components/Subledger/CreateSubledgerTable';
import {
  getFactaBalance,
  initializedSubledger,
  isCreateSubledgerFormValid,
  updateAssetsForSaving,
} from '../../../components/Subledger/common';
import {
  Account,
  AmortizationSource,
  Subledger,
  ProductCategory,
} from '../../../interfaces/types';
import isEmpty from '../../../util/isEmpty';
import { getAccountSync } from '../../../store/slices/account';
import { AppThunkDispatch } from '../../../store/store';
import { accountLastFetchSelector } from '../../../store/selectors/account';
import {
  getSubledgerDetails,
  clearSubledger,
  getBalanceData,
  patchSubledgerAmortizationSources,
} from '../../../store/slices/subledger';
import {
  isFetchingSubledger,
  isFetchingBalanceData,
  isUpdatingSubledger,
  fetchingSubledgerError,
  fetchingBalanceDataError,
} from '../../../store/selectors/subledger';
import { Loader } from '../../../components/Loader';

interface Params {
  id: string;
}

const CreateSubledger = () => {
  const { id: subledgerId } = useParams<Params>();
  const history = useHistory();
  const reduxDispatch: AppThunkDispatch = useDispatch();

  const lastFetch = useSelector(accountLastFetchSelector);
  const subledgerError = useSelector(fetchingSubledgerError);
  const accountBalanceError = useSelector(fetchingBalanceDataError);
  const subledgerLoading = useSelector(isFetchingSubledger);
  const subledgerBalanceLoading = useSelector(isFetchingBalanceData);
  const isUpdating = useSelector(isUpdatingSubledger);

  const [openSubledgerDialog, setOpenSubledgerDialog] = useState<boolean>(false);
  const [openDirtyCheckDialog, setOpenDirtyCheckDialog] = useState<boolean>(false);
  const [openFinalizeSubledgerAlert, setFinalizeSubledgerAlert] = useState<boolean>(false);
  const [formSubmit, setFormSubmit] = useState<boolean>();
  const [existingSubledger, setExistingSubledger] = useState<Subledger>();
  const [accountBalance, setAccountBalance] = useState<Account | null>();
  const [isBulkEditApplied, setIsBulkEditApplied] = useState<boolean>(false);

  // @ts-ignore
  const [state, dispatch] = useReducer(reducer, {
    selectedRow: '',
    subledger: {},
    hoverRow: '',
    isSplitAssetsSumCorrect: true,
  });
  const {
    subledger,
    selectedRow,
    hoverRow,
    isSplitAssetsSumCorrect,
  } = state;

  // Will get subleger balance by last day of the month of previous month
  const lastDayOfSelectedDate = subledger?.factaStartDate
    ? format(subDays(startOfMonth(subledger.factaStartDate), 1), DAY_SHORT_FORMAT)
    : '';

  const getData = useCallback(() => {
    if (!subledgerLoading && !subledgerError) {
      reduxDispatch(getSubledgerDetails({ subledgerId }))
        .unwrap()
        .then((result) => {
          const openingBalance = getFactaBalance(result?.amortizationSources);

          dispatch({
            type: INITIALED,
            payload: {
              subledger: {
                ...result,
                openingBalance,
              },
            },
          });
          setExistingSubledger(initializedSubledger({ subledger: result }));
        });
    }
  }, [reduxDispatch, subledgerError, subledgerId, subledgerLoading]);

  const getAccountBalance = useCallback(() => {
    reduxDispatch(getBalanceData({
      subledgerId,
      lastDayOfSelectedDate,
    }))
      .unwrap()
      .then((result) => {
        setAccountBalance(result);
      });
  }, [reduxDispatch, subledgerId, lastDayOfSelectedDate]);

  useEffect(() => {
    if (lastDayOfSelectedDate) {
      getAccountBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastDayOfSelectedDate]);

  useEffect(() => {
    getData();

    return () => {
      reduxDispatch(clearSubledger());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastFetch]);

  useEffect(() => {
    if (isBulkEditApplied) {
      onFinalizedSubledger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBulkEditApplied]);

  if (!subledger || !accountBalance || isEmpty(subledger)) {
    return <Loader open />;
  }

  if (subledgerError || accountBalanceError) {
    return <ErrorPage />;
  }

  const onSubledgerAmortizationScheduleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch({
      type: SUBLEDGER_AMORTIZATION_SCHEDULE_CHANGE,
      payload: { value: event.target.value },
    });
  };

  const callFinalizedSubledger = async () => {
    setFinalizeSubledgerAlert(false);
    if (!isEmpty(diff(existingSubledger?.amortizationSources!, subledger?.amortizationSources))) {
      const amortizationSources = cloneDeep(subledger?.amortizationSources)
        ?.map((prepaidAsset: AmortizationSource) => ({
          ...prepaidAsset,
          sourceCreationDate: startOfMonth(subledger?.factaStartDate!),
          origin: FACTA_SOURCE,
        }));
      amortizationSources?.filter((prepaidAsset: AmortizationSource) => !prepaidAsset.parentId)
        .forEach((prepaidAsset: AmortizationSource, index: number) => {
          // eslint-disable-next-line no-param-reassign
          prepaidAsset.sourceId = `Open ${index + 1}`;
        });
      updateAssetsForSaving(amortizationSources);
      reduxDispatch(patchSubledgerAmortizationSources({
        subledgerId,
        amortizationSources,
        productCategory: subledger.productCategory,
      }))
        .then(() => {
          reduxDispatch(getAccountSync())
            .then(() => {
              history.push(subledger?.productCategory === ProductCategory.DeferredRevenue ? '/revsync' : '/');
            });
        });
    } else {
      history.push(subledger?.productCategory === ProductCategory.DeferredRevenue ? '/revsync' : '/');
    }
  };

  const showDirtyCheckDialog = () => {
    if (!isEmpty(diff(existingSubledger?.amortizationSources!, subledger?.amortizationSources))) {
      setOpenDirtyCheckDialog(true);
    } else {
      navigateBack();
    }
  };

  const navigateBack = () => {
    history.push(subledger?.productCategory === ProductCategory.DeferredRevenue ? '/revsync' : '/');
  };

  const onFinalizedSubledger = () => {
    const difference = roundTo(Number(accountBalance?.glBalance), 2) - roundTo(Number(subledger?.openingBalance), 2);
    if (difference === 0) {
      setFormSubmit(true);
      const valid = isCreateSubledgerFormValid(subledger, dispatch);
      if (valid) {
        setFinalizeSubledgerAlert(true);
      }
    } else {
      setOpenSubledgerDialog(true);
    }
  };

  const closeDialog = () => {
    setOpenSubledgerDialog(false);
    setOpenDirtyCheckDialog(false);
    setFinalizeSubledgerAlert(false);
  };

  const onBulkEditApply = (internalIds: Array<string>, changedProperties: any) => {
    dispatch({
      type: BULK_EDIT,
      payload: {
        internalIds,
        changedProperties,
      },
    });
  };

  const onBulkRemoveAssets = (internalIds: Array<string>) => {
    dispatch({
      type: BULK_REMOVE_ASSETS,
      payload: {
        internalIds,
      },
    });
  };

  const onBulkEditSchedule = (internalIds: Array<string>, changedProperties: any) => {
    onBulkEditApply(internalIds, changedProperties);
    setIsBulkEditApplied(true);
  };

  return (
    <>
      <Loader open={subledgerLoading || subledgerBalanceLoading || isUpdating} />
      <DialogBox
        openDialog={openSubledgerDialog}
        closeDialog={closeDialog}
        dialogContext="The difference between your Facta subledger and your General Ledger balance must be $0.00 to
            finalize subledger."
        dialogTitle="Hint"
        dismissContext="Dismiss"
      />
      <DialogBox
        openDialog={openDirtyCheckDialog}
        closeDialog={closeDialog}
        dialogContext={'Are you sure you want to navigate away from this page?'
        + ' \n If you press "Yes" now, ALL your changes will be lost!'}
        dialogTitle="Alert"
        dismissContext="Cancel"
        actions={[{
          title: 'YES',
          event: navigateBack,
        }]}
      />
      <DialogBox
        openDialog={openFinalizeSubledgerAlert}
        closeDialog={closeDialog}
        dialogContext={'You are about to edit a historical period. \n Do you want to proceed?'}
        dialogTitle="Alert"
        dismissContext="Cancel"
        actions={[{
          title: 'YES',
          event: callFinalizedSubledger,
        }]}
      />
      <Header
        subledger={subledger}
        onAmortizationScheduleTypeChange={onSubledgerAmortizationScheduleChange}
        accountBalance={accountBalance}
        showDirtyCheckDialog={showDirtyCheckDialog}
        onFinalizedSubledger={onFinalizedSubledger}
        isFinalizeSubledgerEnabled={isSplitAssetsSumCorrect}
      />
      <CreateSubledgerTable
        subledger={subledger}
        selectedRow={selectedRow}
        hoverRow={hoverRow}
        dispatch={dispatch}
        formSubmit={formSubmit}
        isSplitAssetsSumCorrect={isSplitAssetsSumCorrect}
        onBulkEditApply={onBulkEditApply}
        onBulkEditSchedule={onBulkEditSchedule}
        onBulkRemoveAssets={onBulkRemoveAssets}
      />
    </>
  );
};

export default React.memo(CreateSubledger);
