import { FullStoryAPI } from 'react-fullstory';
import { AccountInfo } from '../interfaces/types';
import { featureSwitch } from './featureSwitch';

export const updateFullStoryGlobals = (data: AccountInfo) => {
  if (featureSwitch.fullStory) {
    FullStoryAPI('identify', data.user.email);
    FullStoryAPI('setUserVars', {
      displayName: `${data.user.firstName} ${data.user.lastName}`,
      email: data.user.email,
      company: data.companyName,
    });
  }
};
