import { TagDetails } from './tags';
import {
  Nullable,
  SchedulingMethod,
  SchedulingMethodOption,
} from './common';
import { Contact } from './contacts';
import { AssetType } from './fixedAssetTypes';
import {
  SourceType,
  Vendor,
} from './types';

export interface FixedAssetScheduleItem {
  effective: string;
  amount: number;
  posted: boolean;
  periodStart: string;
  periodEnd: string;
}

export interface Transaction {
  accountId: string;
  transactionType: SourceType;
  amount: string;
  contactId: string;
  description: string;
  externalId: string;
  id: string;
  postingDate: string;
}

export interface Disposal {
  id: string;
  disposalDate: string;
  gainOrLossAccountId: string;
  writtenOffAmount: number;
  tags: Array<TagDetails | Partial<TagDetails>>;
}

export interface FixedAsset {
  id: string;
  transaction: Transaction;
  subledgerId: string;
  finalized: boolean;
  vendorContactId: Nullable<string>;
  fixedAssetTypeId: Nullable<string>;
  number: string;
  description: string;
  schedulingMethod: SchedulingMethod;
  originalValue: number;
  priorDepreciation: number;
  inServiceDate: string;
  fullyDepreciatedDate: string;
  depreciationAccountId: Nullable<string>;
  gainOrLossAccountId: Nullable<string>;
  tags: Array<TagDetails | Partial<TagDetails>>;
  schedule?: Array<FixedAssetScheduleItem>;
  periodDepreciationAmount?: number; // TODO Remove when BE done. See slices/v2Subledgers.ts: updateFixedAsset()
  isValid: boolean;
  disposal?: Disposal;
}

export interface ReviewScreenFixedAsset extends FixedAsset {
  throughDepreciation: number;
  remainingDepreciation: number;
}

export interface BasicFixedAssetForm {
  description: string;
  number: string;
  vendorContact?: Contact;
  fixedAssetType?: AssetType;
  originalValue: number;
  priorDepreciation: number;
  inServiceDate?: Date;
  fullyDepreciatedDate?: Date;
  schedulingMethod?: SchedulingMethodOption;
}

export interface FixedAssetForm extends BasicFixedAssetForm {
  glDate: string;
  id: string;
  transaction: Transaction;
  finalized: boolean;
  depreciationAccountId: Nullable<string>;
  gainOrLossAccountId: Nullable<string>;
  tags: Array<TagDetails | Partial<TagDetails>>;
  remainingLife: {
    difference: number;
    label: string;
  };
  nbv: number;
  periodDepreciationAmount: number;
  remainingDepreciation: number;
  throughDepreciation: number;
  isAssetEditable?: boolean;
  isValid: boolean;
}

export enum FixedAssetsColumn {
  NUMBER = 'FA#',
  JE_LINE = 'JE Line',
  DESCRIPTION = 'Description',
  VENDOR = 'Vendor',
  ASSET_TYPE = 'Asset Type',
  ORIGINAL_AMOUNT = 'Original Amount',
  ACCUMULATED_DEPRECIATION = 'Accumulated Depreciation',
  ACCOUNT = 'Account',
  FACTA_DEPRECIATION_START_DATE = 'Facta Depreciation Start Date',
  FACTA_DEPRECIATION_END_DATE = 'Facta Depreciation End Date',
  SCHEDULE = 'Schedule',
  CLASS = 'Class',
  LOCATION = 'Location',
  DR = 'DR',
  CR = 'CR',
}

export enum AssetTypeColumnName {
  ASSET_TYPE_NAME = 'Asset Type Name',
  ASSET_TYPE_DESCRIPTION = 'Asset Type Description',
  DEPRECIATION_ACCOUNT = 'Depreciation Account',
  GAIN_LOSS_ACCOUNT = 'Gain/Loss Account',
  CLASS = 'Class',
  LOCATION = 'Location',
  USEFUL_LIFE = 'Useful Life (months)',
}

export type FixedAssetsImportObject = {
  [key in FixedAssetsColumn]: string;
};

export interface SaveMethodProps {
  assetId: string;
  data: FixedAssetForm;
  shouldSchedule?: boolean;
  updateSilently?: boolean;
  dontUpdateState: boolean;
}

export interface FixedAssetBulkEditForm extends FixedAssetForm {
  vendorContactId: Vendor;
  fixedAssetTypeId: AssetType;
}
