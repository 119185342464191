import Box from '@material-ui/core/Box';
import React from 'react';
import {
  CardLabel,
  CurrencyLabel,
} from '../Dashboard.styled';
import currencyFormatter from '../../../util/currencyFormatter';

interface Props {
  loading?: boolean;
  glBalance: number;
}

const GLBalance = ({ loading = false, glBalance }: Props) => (
  <Box
    display="flex"
    flexDirection="row"
    justifyContent="space-between"
    alignItems="center"
    width="100%"
    fontSize="14px"
    borderBottom="1px dashed #CFD3DA "
    paddingBottom="8px"
  >
    <CardLabel>
      GL
    </CardLabel>
    <CurrencyLabel>
      { loading && 'Loading...'}
      {!loading && currencyFormatter.format(glBalance)}
    </CurrencyLabel>
  </Box>
);

export default React.memo(GLBalance);
