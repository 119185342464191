import { QUICKBOOK_ERRORS } from './constants';

interface ErrorMapItem {
  code?: string;
  regex: RegExp;
  customErrorMessage: string;
  shouldPrefixProductCategory?: boolean;
}

export const ERRORS_MAP: Array<ErrorMapItem> = [
  {
    code: QUICKBOOK_ERRORS.INVALID_REFERENCE_ID,
    regex: /Account assigned to this transaction has been deleted/,
    customErrorMessage: 'Account assigned to this transaction does not exist anymore. '
      + 'Please go to QuickBooks and restore it.',
    shouldPrefixProductCategory: true,
  },
  {
    code: QUICKBOOK_ERRORS.INVALID_REFERENCE_ID,
    regex: /Customer assigned to this transaction has been deleted/,
    customErrorMessage: 'Customer assigned to this transaction does not exist anymore. '
      + 'Please go to QuickBooks and restore it.',
  },
  {
    code: QUICKBOOK_ERRORS.INVALID_REFERENCE_ID,
    regex: /Vendor assigned to this transaction has been deleted/,
    customErrorMessage: 'Vendor assigned to this transaction does not exist anymore. '
      + 'Please go to QuickBooks and restore it.',
  },
  {
    code: QUICKBOOK_ERRORS.INVALID_REFERENCE_ID,
    regex: /Class assigned to this transaction has been deleted/,
    customErrorMessage: 'Class assigned to this transaction does not exist anymore. '
      + 'Please go to QuickBooks and restore it.',
  },
  {
    regex: /journal content hash doesn't match/,
    customErrorMessage: 'Unreviewed changes have been detected for this journal entry. '
      + 'Please review depreciation before posting.',
  },
];

export enum ProductCategoryAccountMap {
  PREPAID_EXPENSE = 'Expense',
  DEFERRED_REVENUE = 'Revenue',
  FIXED_ASSET = 'Fixed asset',
}
