import React from 'react';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';

import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/styles/makeStyles';

interface Action {
  title: string;
  event: any;
}

interface Props {
  openDialog: any;
  closeDialog: any;
  actions?: Array<Action>;
  dialogTitle: string;
  dialogContext: string | React.ReactNode;
  dismissContext: string;
  showCancel?: boolean;
  children?: React.ReactNode;
}
const useStyles = makeStyles(() => createStyles({
  displayNewLine: {
    whiteSpace: 'pre-line',
  },
}));
const DialogBox = ({
  openDialog,
  closeDialog,
  actions,
  dialogTitle,
  dialogContext,
  dismissContext,
  showCancel = true,
  children,
}: Props) => {
  const classes = useStyles();
  return (
    <Dialog
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={closeDialog}
    >
      {children || (
        <>
          <DialogTitle disableTypography>{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.displayNewLine}>
              {dialogContext}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {showCancel && (
              <Button
                variant="outlined"
                onClick={closeDialog}
              >
                {dismissContext}
              </Button>
            )}
            {actions?.map((action) => (
              <Button
                key={action.title}
                variant="contained"
                color="primary"
                autoFocus
                onClick={action.event}
              >
                {action.title}
              </Button>
            ))}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
export default DialogBox;
