import React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import Profile from './Profile';
import Layout from '../../components/Layout';
import About from './About';
import Connect from './Connect';
import ManageUsers from './ManageUser';
import { isCompanyAvailableSelector } from '../../store/selectors/account';
import { NavigationTabs } from '../../components/NavigationTabs';

const Settings = () => {
  const { url } = useRouteMatch();
  const isCompanyAvailable = useSelector(isCompanyAvailableSelector);

  const settingsRoutes = [
    {
      label: 'Profile',
      path: `${url}/profile`,
      component: <Profile />,
      isDisabled: !isCompanyAvailable,
      exact: true,
    },
    {
      label: 'User Management',
      path: `${url}/manage-user`,
      component: <ManageUsers />,
      isDisabled: !isCompanyAvailable,
      exact: true,
    },
    {
      label: 'Connections',
      path: `${url}/connect`,
      component: <Connect />,
      isDisabled: !isCompanyAvailable,
      exact: true,
    },
    {
      label: 'About Facta',
      path: `${url}/about`,
      component: <About />,
      isDisabled: !isCompanyAvailable,
      exact: true,
    },
  ];

  return (
    <Layout
      title="Settings"
      showCompanyInfo={isCompanyAvailable}
    >
      <NavigationTabs
        routes={settingsRoutes}
        baseRedirect={{ fromUrl: url, pathUrl: `${url}/profile` }}
      />
    </Layout>
  );
};

export default Settings;
