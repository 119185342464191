import Cookies from 'universal-cookie';
import axios from 'axios';

import { AUTH_TOKEN_KEY } from './constants';

export const setToken = (token: string) => {
  localStorage.setItem(AUTH_TOKEN_KEY, token);
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const getToken = (): string | null => localStorage.getItem(AUTH_TOKEN_KEY);

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};

export const deleteToken = () => localStorage.removeItem(AUTH_TOKEN_KEY);

export const updateTokenFromCookie = () => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  if (token) {
    setToken(token);
    cookies.remove('token', {
      path: '/',
      domain: `.${window.location.hostname}`,
    });
  }

  return token;
};

export const getErrorFromCookie = () => {
  const cookies = new Cookies();
  const requestError = cookies.get('error');
  cookies.remove('error', {
    path: '/',
    domain: `.${window.location.hostname}`,
  });
  return requestError;
};

export const isExpired = (jwtToken: any) => {
  if (!jwtToken) {
    return true;
  }
  const ts = new Date().getTime();
  return (jwtToken.exp * 1000) <= ts;
};

export const handleAuthError = () => {
  deleteToken();
  window.location.href = '/login';
};
