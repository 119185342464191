import { TableCell } from '@material-ui/core';
import clsx from 'clsx';
import {
  addMonths,
  differenceInCalendarMonths,
  format,
  isAfter,
  isBefore,
  isSameMonth,
  lastDayOfMonth,
  parse,
  startOfMonth,
} from 'date-fns';
import React from 'react';
import {
  getAssetSumForMonth,
  getChildren,
} from './common';
import currencyFormatter from '../../util/currencyFormatter';
import {
  AmortizationSource,
  AmortizationScheduleDetails,
  LegacySchedulingMethod,
} from '../../interfaces/types';
import { useTableStyles } from './Subledger.styled';
import COLORS from '../../theme/colors';
import InputBox from '../InputBox';
import { DAY_SHORT_FORMAT } from '../../util/constants';

interface Props {
  amortizationSource: AmortizationSource;
  amortizationSources: Array<AmortizationSource>;
  hasChildren: boolean;
  startDate: Date;
  endDate: Date;
  scheduleDate: string;
  disabled?: boolean;
  onInputBoxChange: (lastDay: string, asset: AmortizationSource, schedule?: AmortizationScheduleDetails) =>
  (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PrepareJERowAmortizationSchedules = ({
  amortizationSource,
  amortizationSources,
  hasChildren,
  scheduleDate,
  startDate,
  endDate,
  onInputBoxChange,
  disabled = false,
}: Props) => {
  const tableClasses = useTableStyles();
  const children = getChildren(amortizationSource.internalId, amortizationSources);
  const scheduledOnDate = parse(scheduleDate, DAY_SHORT_FORMAT, new Date());

  if (startDate && endDate) {
    const diffInMonths = differenceInCalendarMonths(lastDayOfMonth(endDate!), startOfMonth(startDate!)) + 1;
    if (diffInMonths > 0) {
      const childrenFromParent = getChildren(amortizationSource.parentId, amortizationSources);
      return (
        <>
          {
            Array.from(Array(diffInMonths)
              .keys())
              .map((index: number) => {
                const currentDate = lastDayOfMonth(addMonths(startDate!, index));
                const lastDay = format(currentDate, 'L/d/yy');
                const sum = hasChildren ? getAssetSumForMonth(lastDay, children) : 0;
                const schedule = amortizationSource?.amortizationSchedule
                  ?.amortizationScheduleDetails
                  ?.find(
                    (item: AmortizationScheduleDetails) => format(item.scheduleDate, DAY_SHORT_FORMAT) === lastDay,
                  );
                return (
                  <TableCell
                    key={`month${index}`}
                    align="right"
                    className={clsx(tableClasses.amortizationRowCell, tableClasses.amortizationCell, {
                      [tableClasses.amortizationManualCell]: !disabled
                      && !hasChildren
                      && (amortizationSource
                        .amortizationSchedule?.amortizationScheduleType === LegacySchedulingMethod.MANUAL
                        && !isBefore(currentDate, scheduledOnDate)),
                      [tableClasses.cellBackground]: index % 2 === 0,
                      [tableClasses.selectedCellBackground]: lastDay === scheduleDate,
                      [tableClasses.amortizationSplitCell]: hasChildren || (amortizationSource.parentId
                        && childrenFromParent?.[childrenFromParent?.length - 1]
                          ?.internalId !== amortizationSource.internalId),
                    })}
                    style={{
                      borderLeft: index === 0 ? `1px solid ${COLORS.lightGray2}` : 'none',
                    }}
                  >
                    {
                      !disabled && !hasChildren
                      && amortizationSource
                        .amortizationSchedule?.amortizationScheduleType === LegacySchedulingMethod.MANUAL
                      && (isAfter(currentDate, scheduledOnDate) || isSameMonth(currentDate, scheduledOnDate)) && (
                        <InputBox
                          error={!disabled && lastDay === scheduleDate
                          && (Number.isNaN(Number(schedule?.amount))
                            || schedule?.amount === '' || schedule?.error)}
                          inputProps={{ style: { textAlign: 'right' } }}
                          onChange={onInputBoxChange(lastDay, amortizationSource, schedule)}
                          value={!Number.isNaN(Number(schedule?.amount))
                          || schedule?.amount === '-' ? schedule?.amount : 'M'}
                          disabled={disabled}
                        />
                      )
                    }
                    {
                      !hasChildren
                      && (amortizationSource
                        .amortizationSchedule?.amortizationScheduleType !== LegacySchedulingMethod.MANUAL
                        || isBefore(currentDate, scheduledOnDate) || disabled) && (
                        schedule?.amount
                          ? currencyFormatter.format(Number(schedule?.amount)) : '-'
                      )
                    }
                    {
                      hasChildren && (
                        sum
                          ? currencyFormatter.format(sum) : '-'
                      )
                    }
                  </TableCell>
                );
              })
          }
        </>
      );
    }
  }
  return null;
};

export default React.memo(PrepareJERowAmortizationSchedules);
