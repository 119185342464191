import React from 'react';
import {
  ProductCategory,
  Subledger,
} from '../../../interfaces/types';
import Flyover from '../../Flyover';
import VerticalScheduleContent from './VerticalScheduleContent';

interface Props {
  internalId: null | string;
  sourceId?: string;
  subledger: Subledger;
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<{ isOpen: boolean; internalId: null | string }>>;
}

const VerticalSchedule = ({
  internalId,
  sourceId,
  isOpen,
  setOpen,
  subledger,
}: Props) => {
  const flyoverTitle = subledger.productCategory === ProductCategory.PrepaidExpense
    ? 'Prepaid expense amortization schedule'
    : 'Revenue recognition schedule';

  return (
    <Flyover
      open={isOpen}
      title={flyoverTitle}
      variant="fullscreen"
      onClose={() => setOpen((state) => ({ ...state, isOpen: false }))}
    >
      <VerticalScheduleContent
        subledger={subledger}
        selectedAmortizationSourceId={internalId}
        sourceId={sourceId}
      />
    </Flyover>
  );
};

export default React.memo(VerticalSchedule);
