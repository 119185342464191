import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  createStyles,
  TableCell,
} from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import makeStyles from '@material-ui/styles/makeStyles';
import clsx from 'clsx';
import Papa from 'papaparse';
import fileDownload from 'js-file-download';
import {
  endOfMonth,
  format,
  lastDayOfMonth,
  startOfMonth,
  subDays,
} from 'date-fns';
import roundTo from 'round-to';
import { useLocation } from 'react-router';
import JournalEntryHeader from './JournalEntryHeader';
import COLORS from '../../theme/colors';
import JournalEntryFilter from './JournalEntryFilter';
import ErrorPage from '../../components/ErrorPage';
import {
  AccountClass,
  ErrorPageType,
  JournalEntryLineItem,
  ReportType,
  Subledger,
  Vendor,
  Account,
  JournalEntryFilterDates,
  LegacyJournalEntry,
  Product,
  Customer,
  ProductCategory,
  ITableItemType,
  SortOrder,
  ITableType,
} from '../../interfaces/types';
import {
  CREDIT,
  FULL_DAY_FORMAT,
  DAY_SHORT_FORMAT,
  DEBIT,
} from '../../util/constants';
import { fromUTC } from '../../util/timezone';
import {
  getAccountName,
  getAccountNameFromList,
  getClassName,
  getCustomerName,
  getProductName,
  getVendorName,
} from '../../components/Subledger/common';
import getFullName from '../../util/user';
import currencyFormatter from '../../util/currencyFormatter';
import NoRecords from '../../components/Dashboard/NoRecords';
import Flyover from '../../components/Flyover';
import TableComponent from '../../components/Table';
import { EmptyState } from '../../components/Table/EmptyState';
import getSubledgerStartEndDate from '../../util/getSubledgerStartEndDate';
import { deleteSubledger } from '../../util/subledger';
import isEmpty from '../../util/isEmpty';
import { getAccountInfo } from '../../store/slices/account';
import { AppThunkDispatch } from '../../store/store';
import { accountInfoSelector } from '../../store/selectors/account';
import {
  getJournalEntryCSV,
  getSubledgerWithJournalEntries,
  setFilterDates,
} from '../../store/slices/je';
import {
  fetchingSubledgerWithJeError,
  isFetchingJournalEntryCSV,
  isFetchingSubledgerWithJe,
  journalEntryFilterDatesSelector,
  subledgerWithJeSelector,
} from '../../store/selectors/je';
import { Loader } from '../../components/Loader';

const useStyles = makeStyles(() => createStyles({
  root: {
    '& td': {
      color: COLORS.deepGray,
    },
  },
  fontSize13: {
    fontSize: 13,
  },
  exportIcon: {
    width: 13,
    height: 13,
    marginLeft: 8,
  },
  editIcon: {
    height: 12,
    width: 12,
    marginLeft: 5,
  },
  colorBlue: {
    color: `${COLORS.skyBlue} !important`,
    textTransform: 'none',
  },
  noOffset: {
    minHeight: 'auto',
    margin: 0,
    padding: 0,
  },
  width5: {
    width: '5%',
  },
  positionRelative: {
    position: 'relative',
  },
}));

const downloadJournalEntrySummary = (data: Subledger) => {
  const journalEntries = data?.journalEntries.map((journalEntry: any) => ({
    ...journalEntry,
    status: 'Posted',
    amount: roundTo(journalEntry.amount, 2),
    txnDate: format(fromUTC(journalEntry.txnDate), FULL_DAY_FORMAT),
    glAccount: getAccountName(data?.account),
    user: getFullName(journalEntry.user),
  }));
  const csvData = Papa.unparse({
    data: journalEntries,
    fields: ['txnDate', 'jeNumber', 'jeNumber', 'glAccount', 'description', 'user', 'amount', 'status'],
  }, {
    header: false,
  });
  const downloadData = `${[
    'JE Date',
    'Facta JE #',
    'GL JE #',
    'GL Account',
    'JE Description',
    'User',
    'Amount',
    'Status',
  ].join(',')}\n${csvData}`;
  fileDownload(downloadData, 'Journal Entries Summary.csv');
};

const downloadJournalEntryDetail = (data: Subledger, vendors: Array<Vendor>,
  accountClasses: Array<AccountClass>, accountIncomes: Array<Account>,
  products: Array<Product>, customers: Array<Customer>) => {
  const journalEntries: any = [];
  data?.journalEntries?.forEach((journalEntry: any) => {
    journalEntry?.journalEntryLineItems?.forEach((lineItem: JournalEntryLineItem, index: number) => {
      journalEntries.push({
        jeNumber: journalEntry.jeNumber,
        txnDate: format(fromUTC(journalEntry.txnDate), FULL_DAY_FORMAT),
        amount: roundTo(journalEntry.amount, 2),
        glAccount: getAccountName(data?.account),
        user: getFullName(journalEntry.user),
        description: journalEntry.description,
        lineNumber: index + 1,
        lineDescription: lineItem.description,
        // @ts-ignore
        vendor: getVendorName(lineItem?.vendorId, vendors),
        // @ts-ignore
        customer: getCustomerName(lineItem?.customerId, customers),
        // @ts-ignore
        product: getProductName(lineItem?.productId, products),
        expenseAccount: getAccountNameFromList(lineItem?.accountId, accountIncomes),
        class: getClassName(lineItem?.classId!, accountClasses),
        dr: lineItem?.postingType === DEBIT ? lineItem.amount : '',
        cr: lineItem?.postingType === CREDIT ? lineItem.amount : '',
      });
    });
  });
  const fields = data?.productCategory === ProductCategory.PrepaidExpense
    ? ['txnDate', 'jeNumber', 'jeNumber', 'glAccount', 'description', 'lineNumber',
      'lineDescription', 'vendor', 'expenseAccount', 'class', 'user', 'amount', 'dr', 'cr']
    : ['txnDate', 'jeNumber', 'jeNumber', 'glAccount', 'description', 'lineNumber',
      'lineDescription', 'customer', 'product', 'expenseAccount', 'class', 'user', 'amount', 'dr', 'cr'];
  const csvData = Papa.unparse({
    data: journalEntries,
    fields,
  }, {
    header: false,
  });
  const columns = data?.productCategory === ProductCategory.PrepaidExpense
    ? ['JE Date', 'Facta JE #', 'GL JE #', 'GL Account', 'JE Description', 'Line Number',
      'Line Description', 'Vendor', 'GL Expense Account', 'Class', 'User', 'Amount', 'DR', 'CR']
    : ['JE Date', 'Facta JE #', 'GL JE #', 'GL Account', 'JE Description', 'Line Number',
      'Line Description', 'Customer', 'Product/Service', 'GL Revenue Account', 'Class', 'User', 'Amount', 'DR', 'CR'];
  const downloadData = `${columns.join(',')}\n${csvData}`;
  fileDownload(downloadData, 'Journal Entries Detail.csv');
};

const JournalEntryListView = () => {
  const classes = useStyles();
  const history = useHistory();
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const useQuery = () => new URLSearchParams(useLocation().search);

  const account = useSelector(accountInfoSelector);
  const subledger = useSelector(subledgerWithJeSelector) || null;
  const filterDates = useSelector(journalEntryFilterDatesSelector);
  const loading = useSelector(isFetchingSubledgerWithJe);
  const error = useSelector(fetchingSubledgerWithJeError);
  const csvLoading = useSelector(isFetchingJournalEntryCSV);

  const [filterVisibility, setFilterVisibility] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [accountId, setAccountId] = useState<string>(useQuery().get('accountId') ?? '');

  const {
    vendors,
    accountBalances,
    accountClasses,
    accountIncomes,
    products,
    customers,
  } = account!;

  const {
    startDate,
    endDate,
  } = getSubledgerStartEndDate(subledger);

  const previousMonth = subledger?.subledgerAmortizationLogs?.length
    ? endOfMonth(subDays(startOfMonth(endDate), 1)) : endDate;

  const getData = useCallback(async () => {
    if (!loading && !error && (!accountId || filterApplied || accountId !== subledger?.accountId)) {
      let filter = '';
      if (filterDates && filterApplied) {
        filter = `&start=${format(filterDates?.startDate!, 'yyyy-LL-dd')}`
          + `&end=${format(filterDates?.endDate!, 'yyyy-LL-dd')}`;
      }
      reduxDispatch(getSubledgerWithJournalEntries({
        accountId,
        filter,
      }));
    }
  }, [accountId, error, filterApplied, filterDates, loading, reduxDispatch, subledger]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, filterDates]);

  useEffect(() => {
    reduxDispatch(getAccountInfo());
  }, [reduxDispatch]);

  if (error) {
    return (
      <ErrorPage variant={ErrorPageType.Error} />
    );
  }

  if (loading) {
    return <Loader open />;
  }

  if (isEmpty(subledger) || !subledger) {
    return <NoRecords />;
  }

  const onDateChange = (journalEntryFilterDates: JournalEntryFilterDates | null) => {
    setAccountId(subledger?.account?.id!);
    setFilterVisibility(false);
    reduxDispatch(setFilterDates(journalEntryFilterDates));
    setFilterApplied(
      (journalEntryFilterDates?.startDate !== startDate || journalEntryFilterDates?.endDate !== previousMonth),
    );
  };

  const onAccountChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    reduxDispatch(setFilterDates(null));
    setFilterApplied(false);
    history.replace(`/journal-entries/?accountId=${event.target.value}`);
    setAccountId(event.target.value as string);
  };

  const onCSVExport = async (type: ReportType) => {
    if (isEmpty(subledger) || !subledger?.account?.id) {
      return;
    }
    if (type === ReportType.Detail) {
      reduxDispatch(getJournalEntryCSV({
        accountId: subledger.account.id,
        productCategory: subledger.productCategory,
      }))
        .unwrap()
        .then((result) => {
          downloadJournalEntryDetail(result, vendors, accountClasses, accountIncomes, products, customers);
        });
    } else if (subledger?.journalEntries && subledger?.journalEntries.length) {
      downloadJournalEntrySummary(subledger);
    }
  };

  const navigateToViewJournalEntry = (id: string) => () => {
    history.push(`/journal-entries/${id}`);
  };

  const navigateToHistoricalEditingPage = (txtDate: string) => () => {
    deleteSubledger();
    const date = format(lastDayOfMonth(fromUTC(txtDate)), DAY_SHORT_FORMAT);
    history.push(`/historical/subledgers/schedule/${subledger?.id}/?scheduleDate=${date}`);
  };

  const defaultSort = {
    key: 'txnDate',
    order: SortOrder.DESC,
  };

  const colProps = [
    {
      colName: 'FACTA JE #',
      varKey: 'jeNumber',
      colType: ITableItemType.TEXT,
      child: (value: LegacyJournalEntry) => (
        <TableCell key="factaJENumber">
          {value.jeNumber}
        </TableCell>
      ),
    },
    {
      colName: 'GL JE #',
      varKey: 'jeNumber',
      colType: ITableItemType.TEXT,
      child: (value: LegacyJournalEntry) => (
        <TableCell key="JENumber">
          {value.jeNumber}
        </TableCell>
      ),
    },
    {
      colName: 'GL Account',
      varKey: '',
      colType: ITableItemType.TEXT,
      child: () => (
        <TableCell key="GLAccount">
          {getAccountName(subledger?.account!)}
        </TableCell>
      ),
    },
    {
      colName: 'JE Date',
      varKey: 'txnDate',
      colType: ITableItemType.DATE,
      child: (value: LegacyJournalEntry) => (
        <TableCell key="txnDate">
          {format(fromUTC(value.txnDate as string), FULL_DAY_FORMAT)}
        </TableCell>
      ),
    },
    {
      colName: 'JE Description',
      varKey: 'description',
      colType: ITableItemType.TEXT,
      child: (value: LegacyJournalEntry) => (
        <TableCell key="description">
          {value.description}
        </TableCell>
      ),
    },
    {
      colName: 'User',
      varKey: 'user',
      colType: ITableItemType.USERNAME,
      child: (value: LegacyJournalEntry) => (
        <TableCell key="user">
          {getFullName(value?.user)}
        </TableCell>
      ),
    },
    {
      colName: 'Updated at',
      varKey: 'updatedAd',
      colType: ITableItemType.DATE,
      child: (value: LegacyJournalEntry) => (
        <TableCell key="updatedAt">
          {format(fromUTC(value.updatedAt as string), FULL_DAY_FORMAT)}
        </TableCell>
      ),
    },
    {
      colName: 'Amount',
      varKey: 'amount',
      colType: ITableItemType.NUMMARY,
      child: (value: LegacyJournalEntry) => (
        <TableCell key="amount">
          {currencyFormatter.format(value.amount)}
        </TableCell>
      ),
    },
    {
      colName: 'Status',
      varKey: 'status',
      colType: ITableItemType.TEXT,
      child: (value: LegacyJournalEntry) => (
        <TableCell key="status">
          {value.status ? value?.status : 'Posted'}
        </TableCell>
      ),
    },
    {
      colName: '',
      varKey: 'id',
      colType: ITableItemType.TEXT,
      child: (value: LegacyJournalEntry) => (
        <TableCell
          className={clsx(classes.colorBlue, classes.width5)}
          key="id"
        >
          <Button
            onClick={navigateToViewJournalEntry(value?.id)}
            color="primary"
            className={classes.colorBlue}
          >
            View
          </Button>
        </TableCell>
      ),
    },
    {
      colName: '',
      varKey: 'edit',
      colType: ITableItemType.NUMMARY,
      child: (value: LegacyJournalEntry) => (
        <TableCell
          className={clsx(classes.colorBlue, classes.width5)}
          key="edit"
        >
          <Button
            color="primary"
            className={classes.colorBlue}
            onClick={navigateToHistoricalEditingPage(value.txnDate as string)}
          >
            Edit
            <EditOutlinedIcon className={clsx(classes.colorBlue, classes.editIcon)} />
          </Button>
        </TableCell>
      ),
    },
  ];

  return (
    <Box
      padding={2}
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      flexGrow={1}
    >
      <Flyover
        title="FILTER"
        variant="drawer"
        open={filterVisibility}
        onClose={() => setFilterVisibility(false)}
      >
        <JournalEntryFilter
          setDates={onDateChange}
          filterDates={filterDates}
          startDate={startDate}
          endDate={previousMonth}
        />
      </Flyover>
      <Loader open={csvLoading} />
      <JournalEntryHeader
        downloadCSV={onCSVExport}
        toggleFilter={setFilterVisibility}
        subledger={subledger!}
        accounts={accountBalances}
        onAccountChange={onAccountChange}
        filterApplied={filterApplied}
      />
      {subledger.journalEntries ? (
        <TableComponent
          colProps={colProps}
          tableData={subledger.journalEntries}
          defaultSort={defaultSort}
          contentType={ITableType.settings}
          topBarActions={[]}
        />
      ) : <EmptyState />}
    </Box>
  );
};

export default JournalEntryListView;
