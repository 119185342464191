import React from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  Button,
  createStyles,
  Tooltip,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useHistory } from 'react-router';
import {
  HeaderLabel,
  CurrencyLabel,
  HeaderTop,
  HeaderCurrencyDetails,
  AccountStyled,
} from '../../../components/common.styled';
import COLORS from '../../../theme/colors';
import currencyFormatter from '../../../util/currencyFormatter';
import {
  Account,
  ProductCategory,
  Subledger,
} from '../../../interfaces/types';
import isEmpty from '../../../util/isEmpty';
import SubledgerMonthIndicator from '../../../components/Subledger/SubledgerMonthIndicator';
import SubledgerAccountName from '../../../components/Subledger/SubledgerAccountName';
import {
  Breadcrumbs,
  BreadcrumbsItem,
} from '../../../components/BreadcrumbsStepper';

const useStyles = makeStyles(() => createStyles({
  selectBox: {
    width: 400,
  },
  periodBox: {
    width: 100,
    marginRight: 20,
  },
  subledgerBalance: {
    color: COLORS.violet,
  },
  fullWidth: {
    width: '100%',
  },
  whiteFont: {
    color: COLORS.white,
  },
  navigation: {
    paddingLeft: 38,
    paddingTop: 30,
  },
}));

interface Props {
  subledger?: Subledger | null;
  scheduleDate: string;
  postJEEnabled: boolean;
  onPostJE: () => void;
  accountBalance: Account;
}

const ScheduleHeader = ({ subledger, scheduleDate, postJEEnabled, onPostJE, accountBalance }: Props) => {
  const classes = useStyles();
  const history = useHistory();

  if (!subledger || isEmpty(subledger)) {
    return null;
  }

  const navigateToSchedulePrepaid = () => {
    history.push(`/subledgers/schedule/${subledger.id}/?scheduleDate=${scheduleDate}`);
  };

  const difference = accountBalance?.glBalance - subledger?.openingBalance;

  return (
    <>
      <SubledgerMonthIndicator
        title="Current Period:"
        scheduleDate={scheduleDate}
      />
      <Box className={classes.navigation}>
        <Breadcrumbs>
          <BreadcrumbsItem
            step={1}
            checked
            action={navigateToSchedulePrepaid}
          >
            Schedule
            {' '}
            {subledger?.productCategory === ProductCategory.DeferredRevenue ? 'Revenue' : 'Prepaid'}
          </BreadcrumbsItem>
          <BreadcrumbsItem
            step={2}
            active
          >
            Review schedule
          </BreadcrumbsItem>
          <BreadcrumbsItem
            step={3}
            action={postJEEnabled ? onPostJE : undefined}
          >
            Prepare JE
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Box>
      <HeaderTop>
        <AccountStyled>
          <SubledgerAccountName account={subledger?.account} />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            marginTop="10px"
          />
        </AccountStyled>
        <Box display="flex">
          <HeaderCurrencyDetails>
            <HeaderLabel>
              Current GL Balance:
            </HeaderLabel>
            <CurrencyLabel>
              {currencyFormatter.format(accountBalance?.glBalance)}
            </CurrencyLabel>
          </HeaderCurrencyDetails>
          <HeaderCurrencyDetails>
            <HeaderLabel>
              Current Facta Balance:
            </HeaderLabel>
            <CurrencyLabel className={classes.subledgerBalance}>
              {currencyFormatter.format(subledger.openingBalance)}
            </CurrencyLabel>
          </HeaderCurrencyDetails>
          <HeaderCurrencyDetails>
            <HeaderLabel>
              Difference
            </HeaderLabel>
            <CurrencyLabel>
              {currencyFormatter.format(difference)}
            </CurrencyLabel>
          </HeaderCurrencyDetails>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          marginLeft="auto"
        >
          <Button
            variant="outlined"
            color="primary"
            startIcon={<ChevronLeftIcon />}
            onClick={navigateToSchedulePrepaid}
          >
            {subledger.productCategory === ProductCategory.DeferredRevenue ? 'SCHEDULE REVENUE' : 'SCHEDULE PREPAID' }
          </Button>
          {!postJEEnabled && (
            <Tooltip
              title="Please ensure all Manual Prepaids have a $0.00 or greater value."
              arrow
              placement="top"
            >
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<ChevronRightIcon />}
                  onClick={onPostJE}
                  disabled
                >
                  PREPARE JE
                </Button>
              </Box>
            </Tooltip>
          )}
          {postJEEnabled && (
            <Box>
              <Button
                variant="contained"
                color="primary"
                endIcon={<ChevronRightIcon />}
                onClick={onPostJE}
                disabled={!postJEEnabled}
              >
                PREPARE JE
              </Button>
            </Box>
          )}
        </Box>
      </HeaderTop>
    </>
  );
};

export default ScheduleHeader;
