import React from 'react';
import Table from '@material-ui/core/Table';
import { ProductCategory } from '../../interfaces/types';
import JournalEntryViewPrepaidRevenueTable from './JournalEntryViewPrepaidRevenueTable';
import JournalEntryViewRevSyncTable from './JournalEntryViewRevSyncTable';
import { ScrollableTableContainer } from '../ScrollableTable';

interface Props {
  DRAmount: number;
  CRAmount: number;
  data: any;
}

const JournalEntryViewTable = ({ data, DRAmount, CRAmount }: Props) => (
  <>
    <ScrollableTableContainer>
      <Table
        size="small"
        id="journalEntryTable"
        stickyHeader
        aria-label="Journal Entry List"
      >
        {data?.subledger?.productCategory === ProductCategory.PrepaidExpense && (
          <JournalEntryViewPrepaidRevenueTable
            data={data}
            DRAmount={DRAmount}
            CRAmount={CRAmount}
          />
        )}
        {data?.subledger?.productCategory === ProductCategory.DeferredRevenue && (
          <JournalEntryViewRevSyncTable
            data={data}
            DRAmount={DRAmount}
            CRAmount={CRAmount}
          />
        )}
      </Table>
    </ScrollableTableContainer>
  </>
);

export default JournalEntryViewTable;
