import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import find from 'lodash.find';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  format,
  formatISO,
  isSameMonth,
  parseISO,
  startOfMonth,
} from 'date-fns';
import {
  Box,
  Button,
} from '@material-ui/core';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import TableComponent from '../../../Table';
import {
  FilterMatchingType,
  ITableItemType,
  ITableType,
  ProductCategory,
  SortOrder,
} from '../../../../interfaces/types';
import { useFilters } from '../../../../hooks/useFilters';
import {
  getFilteredData,
  getFilterValueByFilterName,
} from '../../../../util/filtering';
import { TextSearchFilter } from '../../../Table/Filters/TextSearch';
import { AmountFilter } from '../../../Table/Filters/Amount';
import { MultipickerFilter } from '../../../Table/Filters/Multipicker';
import { DatepickerFilter } from '../../../Table/Filters/Datepicker/index';
import {
  AUTOSAVE_DELAY,
  DEFAULT_PAGE_SIZE,
  FULL_DATE_FORMAT,
  HIGHLIGHT_RESET,
  MONTH_SHORT_FORMAT,
} from '../../../../util/constants';
import { AppThunkDispatch } from '../../../../store/store';
import currencyFormatter from '../../../../util/currencyFormatter';
import { StyledTableCell } from '../../../Table/StyledTableCell';
import {
  finalizeFixedAsset,
  updateFixedAsset,
  updateManualStepForFixedAsset,
  createManualStepForFixedAsset,
  bulkUpdateFixedAssets,
  bulkFinalizeFixedAssets,
} from '../../../../store/slices/v2Subledgers';
import {
  FixedAsset,
  FixedAssetForm,
  SaveMethodProps,
} from '../../../../interfaces/fixedAssets';
import {
  fixedAssetsListSelectorWithoutFactaUnfinalized,
  subledgerTouchedSelector,
} from '../../../../store/selectors/v2Subledgers';
import { vendorsListSelector } from '../../../../store/selectors/v2contacts';
import { assetTypesSelector } from '../../../../store/selectors/assetTypes';
import FactaAutocomplete from '../../../Inputs/FactaAutocomplete';
import { NumberInput } from '../../../Inputs/NumberInput';
import FactaDatePicker from '../../../DatePicker';
import { SaveAndScheduleCell } from './SaveAndScheduleCell';
import {
  SchedulerTab,
  SchedulingMethod,
} from '../../../../interfaces/common';
import { useDebouncedEffect } from '../../../../hooks/useDebouncedEffect';
import EditFixedAsset from '../../../Flyovers/EditFixedAsset';
import WriteOffFixedAsset from '../../../Flyovers/WriteOffFixedAsset';
import { V2Subledger } from '../../../../interfaces/subledgers';
import { FormInput } from '../../../Inputs/FormInput';
import { StyledLink } from '../../../StyledLink';
import {
  generateQBOLink,
  schedulingMethodOptions,
} from '../../../../util/helpers';
import { FactaCheckbox } from '../../../Checkbox';
import { BulkEdit } from '../../../BulkEdit';
import { Contact } from '../../../../interfaces/contacts';
import {
  AssetType,
  AssetTypeForm,
} from '../../../../interfaces/fixedAssetTypes';
import { LoadingBox } from '../../../LoadingBox';
import { LineClamp } from '../../../Table/LineClamp';

import AddEditAssetType from '../../../Flyovers/AddEditAssetType';
import { DEFAULT_ASSET_TYPE } from '../../../../util/pages/FixedAssets/assetTypes';
import { IdleTableCell } from '../../../Table/IdleTableCell';
import { precisionRound } from '../../../../util/math';

interface Props {
  selectedDate: string;
  subledgerDetails: V2Subledger;
  isPeriodLocked: boolean;
  page: number;
  setPage: (page: number) => void;
  tab: SchedulerTab;
  setTab: (tab: SchedulerTab) => void;
  isCurrentPeriod?: boolean;
}

export const FixedAssetsSchedulerTable = ({
  selectedDate,
  subledgerDetails,
  isPeriodLocked,
  page,
  setPage,
  tab,
  setTab,
  isCurrentPeriod,
}: Props) => {
  const [selectedRow, setSelectedRow] = useState<string | undefined>(undefined);
  const [updatingId, setUpdatingId] = useState('');
  const [autosaveData, setAutosaveData] = useState<{
    assetId?: string;
    data?: FixedAssetForm;
  }>({ assetId: undefined, data: undefined });
  const [editFlyover, setEditFlyover] = useState<{
    isOpen: boolean;
    asset?: FixedAsset;
  }>({
    isOpen: false,
    asset: undefined,
  });
  const [writeOffFlyover, setWriteOffFlyover] = useState<{
    isOpen: boolean;
    asset?: FixedAsset;
  }>({
    isOpen: false,
    asset: undefined,
  });
  const [assetTypeFlyover, setAssetTypeFlyover] = useState<{
    isOpen: boolean;
    assetType: AssetTypeForm;
  }>({
    isOpen: false,
    assetType: DEFAULT_ASSET_TYPE,
  });
  const [highlighted, setHighlighted] = useState('');
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const addedRowRef = useRef<HTMLTableRowElement>(null);
  const [selectedSources, setSelectedSources] = useState<string[]>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [selectedByTabbing, setSelectedByTabbing] = useState(false);

  const vendors = useSelector(vendorsListSelector);
  const fixedAssetTypes = useSelector(assetTypesSelector);
  const allFixedAssets = useSelector(fixedAssetsListSelectorWithoutFactaUnfinalized);
  const touched = useSelector(subledgerTouchedSelector);

  const adjustedFixedAssets = useMemo(() => allFixedAssets.map((asset) => ({
    ...asset,
    nbv: precisionRound(asset.originalValue - asset.priorDepreciation),
  })), [allFixedAssets]);

  const {
    id: subledgerId,
    startDate: factaStartDate,
  } = subledgerDetails;

  const yupSchema = () => yupResolver(yup.object().shape({
    fixedAssetType: yup.object({
      name: yup.string().required(),
    }).required(),
    originalValue: yup.number(),
    schedulingMethod: yup.object({
      value: yup.string().required(),
    }).required(),
    inServiceDate: yup.date().required()
      .min(parseISO(factaStartDate),
        `Amortization start date must be later or equal ${format(parseISO(factaStartDate), FULL_DATE_FORMAT)}`),
  }));

  const {
    control,
    errors,
    handleSubmit,
    formState,
    setValue,
    getValues,
    reset,
  } = useForm({
    mode: 'onBlur',
    resolver: yupSchema(),
    shouldUnregister: true,
  });

  const initialFilters = useMemo(() => ([
    {
      name: 'glDate',
      sourcePath: ['transaction', 'postingDate'],
      matching: FilterMatchingType.DATE_RANGE,
    },
    {
      name: 'externalId',
      sourcePath: ['transaction', 'externalId'],
      matching: FilterMatchingType.STRING_INCLUDES,
    },
    {
      name: 'number',
      matching: FilterMatchingType.STRING_INCLUDES,
    },
    {
      name: 'description',
      matching: FilterMatchingType.STRING_INCLUDES,
    },
    {
      name: 'vendorContactId',
      matching: FilterMatchingType.ARRAY_INCLUDES_PROPERTY,
      propertyPath: ['id'],
    },
    {
      name: 'fixedAssetTypeId',
      matching: FilterMatchingType.ARRAY_INCLUDES_PROPERTY,
      propertyPath: ['id'],
    },
    {
      name: 'inServiceDate',
      matching: FilterMatchingType.DATE_RANGE,
    },
    {
      name: 'schedulingMethod',
      matching: FilterMatchingType.ARRAY_INCLUDES_PROPERTY,
      propertyPath: ['value'],
    },
    {
      name: 'originalValue',
      matching: FilterMatchingType.NUMBER_COMPARE,
    },
    {
      name: 'nbv',
      matching: FilterMatchingType.NUMBER_COMPARE,
    },
  ]), []);

  const unscheduledFixedAssets = adjustedFixedAssets.filter((asset) => !asset.finalized);
  const scheduledFixedAssets = adjustedFixedAssets.filter((asset) => asset.finalized && !asset.disposal);
  const editableScheduledFixedAssets = scheduledFixedAssets.filter((asset) => isSameMonth(
    parseISO(asset?.transaction?.postingDate || subledgerDetails.startDate), parseISO(selectedDate),
  ));

  const tabbedFixedAssets = tab === SchedulerTab.UNSCHEDULED
    ? unscheduledFixedAssets
    : scheduledFixedAssets;

  const {
    filters,
    setFilter,
    clearAllFilters,
  } = useFilters(initialFilters);

  const {
    filteredData,
    numberOfFiltersApplied,
  } = useMemo(
    () => getFilteredData(tabbedFixedAssets, filters),
    [filters, tabbedFixedAssets],
  );

  const filteredFixedAssets = filteredData as unknown as FixedAsset[];

  const fixedAssets: Array<Partial<FixedAssetForm>> = filteredFixedAssets.map((asset) => ({
    id: asset.id,
    glDate: asset.transaction?.postingDate || factaStartDate,
    transaction: asset.transaction,
    description: asset.description || '',
    number: asset.number || '',
    vendorContact: find(vendors, { id: asset.vendorContactId }) as Contact || null,
    fixedAssetType: find(fixedAssetTypes, { id: asset.fixedAssetTypeId }) as AssetType || null,
    originalValue: asset.originalValue,
    priorDepreciation: asset.priorDepreciation,
    inServiceDate: asset.inServiceDate
      ? parseISO(asset.inServiceDate)
      : undefined,
    fullyDepreciatedDate: asset.fullyDepreciatedDate
      ? parseISO(asset.fullyDepreciatedDate)
      : undefined,
    schedulingMethod: find(schedulingMethodOptions, { value: asset.schedulingMethod }),
    depreciationAccountId: asset.depreciationAccountId,
    gainOrLossAccountId: asset.gainOrLossAccountId,
    tags: asset.tags,
    finalized: asset.finalized,
    nbv: asset.originalValue - asset.priorDepreciation,
    periodDepreciationAmount: asset.periodDepreciationAmount
      || asset.schedule?.find((sch) => sch.periodEnd === selectedDate)?.amount
      || 0,
    isAssetEditable: !isPeriodLocked && (isSameMonth(
      parseISO(asset?.transaction?.postingDate || subledgerDetails.startDate), parseISO(selectedDate),
    )),
    isValid: asset.isValid,
  }));

  useEffect(() => {
    setPage(1);
  }, [filters, allFixedAssets, setPage]);

  const handleCheckboxChange = (id: string) => {
    selectedSources.includes(id)
      ? setSelectedSources(selectedSources.filter((source) => source !== id))
      : setSelectedSources([...selectedSources, id]);
  };

  const handleSelectAll = () => {
    const tabSelectedAllAssets = tab === SchedulerTab.UNSCHEDULED
      ? unscheduledFixedAssets.map((asset) => asset.id)
      : editableScheduledFixedAssets.map((asset) => asset.id);

    isAllSelected
      ? setSelectedSources([])
      : setSelectedSources(tabSelectedAllAssets);

    setIsAllSelected(!isAllSelected);
  };

  const handleBulkEditClose = () => {
    setSelectedSources([]);
    setIsAllSelected(false);
  };

  const handleBulkEditApply = async (ids: Array<string>, changedProperties: Partial<FixedAssetForm>) => {
    const payload: Array<Partial<FixedAsset>> = ids.map((id) => ({
      id,
      ...Object.fromEntries(Object.entries(changedProperties).map(([key, value]: any) => {
        switch (key) {
          case 'vendorContact':
            return ['vendorContactId', value.id];
          case 'fixedAssetType':
            return ['fixedAssetTypeId', value.id];
          case 'schedulingMethod':
            return ['schedulingMethod', value.value];
          case 'inServiceDate':
          case 'fullyDepreciatedDate':
            return [key, formatISO(value, { representation: 'date' })];
          default:
            return [key, value];
        }
      })),
      ...(changedProperties.fixedAssetType ? {
        depreciationAccountId: changedProperties.fixedAssetType.depreciationAccountId,
        gainOrLossAccountId: changedProperties.fixedAssetType.gainOrLossAccountId,
      } : {}),
    }));

    await reduxDispatch(bulkUpdateFixedAssets({
      fixedAssets: payload,
      effective: formatISO(startOfMonth(parseISO(selectedDate)), { representation: 'date' }),
    }));
  };

  const onBulkEditSchedule = (fixedAssetsIds: Array<string>, changedProperties: any) => {
    if (changedProperties) {
      handleBulkEditApply(fixedAssetsIds, changedProperties)
        .then(() => reduxDispatch(bulkFinalizeFixedAssets({ fixedAssetsIds }))
          .unwrap()
          .then(() => handleBulkEditClose()));
    } else {
      reduxDispatch(bulkFinalizeFixedAssets({ fixedAssetsIds }))
        .unwrap()
        .then(() => handleBulkEditClose());
    }
  };

  const colProps = [
    {
      colName: 'Bulk Edit',
      varKey: 'bulkEdit',
      colType: ITableItemType.BOOLEAN,
      sortable: false,
      sticky: true,
      customHeader: (
        <FactaCheckbox
          checked={isAllSelected}
          onChange={handleSelectAll}
          disabled={false}
        />
      ),
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="bulk"
          is-cell-sticky="true"
        >
          <FactaCheckbox
            checked={selectedSources.includes(value.id)}
            onChange={() => handleCheckboxChange(value.id)}
            disabled={!value.isAssetEditable || false}
            onKeyDown={(ev) => {
              if (ev.key === 'Tab' && !ev.shiftKey && selectedRow !== value.id) {
                ev.preventDefault();
                setSelectedByTabbing(true);
                setSelectedRow(value.id);
              }
            }}
          />
        </StyledTableCell>
      ),
    },
    {
      colName: 'GL Date',
      customHeader: (
        <DatepickerFilter
          _key={subledgerId}
          label="GL Date"
          value={getFilterValueByFilterName(filters, 'glDate')}
          onChange={(filter) => setFilter('glDate', filter)}
        />
      ),
      varKey: 'glDate',
      colType: ITableItemType.DATE,
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="glDate"
          minWidth="150"
        >
          {updatingId === value.id && (
            <LoadingBox
              transparent
              size={20}
            />
          )}
          {value.glDate && format(parseISO(value.glDate), FULL_DATE_FORMAT)}
        </StyledTableCell>
      ),
    },
    {
      colName: 'ID',
      customHeader: (
        <TextSearchFilter
          _key={subledgerId}
          label="JE&nbsp;ID"
          value={getFilterValueByFilterName(filters, 'externalId')}
          onChange={(filter) => setFilter('externalId', filter)}
          onInput={(filter) => setFilter('externalId', filter)}
        />
      ),
      varKey: 'externalId',
      colType: ITableItemType.SORTKEY,
      sortKey: ['externalId'],
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="externalId"
        >
          {value.transaction
            ? (
              <StyledLink
                target="_blank"
                to={{ pathname: generateQBOLink(value.transaction.transactionType, value.transaction.externalId) }}
              >
                {value.transaction.externalId.split(':').shift()}
              </StyledLink>
            )
            : (
              <StyledLink to={`/fixed-assets/edit/${subledgerId}/#${value.id}`}>
                Open balance
              </StyledLink>
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: 'FA#',
      customHeader: (
        <TextSearchFilter
          _key={subledgerId}
          label="FA#"
          value={getFilterValueByFilterName(filters, 'number')}
          onChange={(filter) => setFilter('number', filter)}
          onInput={(filter) => setFilter('number', filter)}
        />
      ),
      varKey: 'number',
      colType: ITableItemType.TEXT,
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="number"
          minWidth="120"
        >
          {updatingId === value.id && (
            <LoadingBox
              transparent
              size={20}
            />
          )}
          {selectedRow === value.id
            ? (
              <FormInput
                control={control}
                name="number"
                defaultValue={value.number}
                error={!!errors?.number}
                onBlur={() => handleOnBlur(value.id)}
                autoFocus={selectedRow === value.id && selectedByTabbing}
                placeholder="#FA"
              />
            )
            : (
              <IdleTableCell
                placeholderText="#FA"
                value={value.number}
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: 'Description',
      customHeader: (
        <TextSearchFilter
          _key={subledgerId}
          label="Description"
          value={getFilterValueByFilterName(filters, 'description')}
          onChange={(filter) => setFilter('description', filter)}
          onInput={(filter) => setFilter('description', filter)}
        />
      ),
      varKey: 'description',
      colType: ITableItemType.TEXT,
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="description"
          minWidth="200"
        >
          <LineClamp text={value.description} />
        </StyledTableCell>
      ),
    },
    {
      colName: 'Vendor',
      customHeader: (
        <MultipickerFilter
          _key={subledgerId}
          label="Vendor"
          value={getFilterValueByFilterName(filters, 'vendorContactId')}
          options={vendors || []}
          onChange={(filter) => setFilter('vendorContactId', filter)}
        />
      ),
      varKey: 'vendorContact',
      colType: ITableItemType.SORTKEY,
      sortKey: ['name'],
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="vendorContact"
          minWidth="200"
        >
          {value.vendorContact?.name || ''}
        </StyledTableCell>
      ),
    },
    {
      colName: 'Asset Type',
      customHeader: (
        <MultipickerFilter
          _key={subledgerId}
          label="Asset Type"
          value={getFilterValueByFilterName(filters, 'fixedAssetTypeId')}
          options={fixedAssetTypes || []}
          onChange={(filter) => setFilter('fixedAssetTypeId', filter)}
        />
      ),
      varKey: 'fixedAssetType',
      colType: ITableItemType.SORTKEY,
      sortKey: ['name'],
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="fixedAssetType"
          minWidth="200"
        >
          {updatingId === value.id && (
            <LoadingBox
              transparent
              size={20}
            />
          )}
          {selectedRow === value.id && isSameMonth(
            parseISO(value?.transaction?.postingDate || subledgerDetails.startDate), parseISO(selectedDate),
          )
            ? (
              <FactaAutocomplete
                control={control}
                options={fixedAssetTypes || []}
                disableInactiveOptions
                optionName="name"
                name="fixedAssetType"
                defaultValue={value.fixedAssetType}
                error={errors?.fixedAssetType}
                onBlur={() => handleOnBlur(value.id)}
                additionalActions={[
                  {
                    label: 'Add New Asset Type',
                    action: () => setAssetTypeFlyover({ isOpen: true, assetType: DEFAULT_ASSET_TYPE }),
                  },
                ]}
                placeholder="Select Asset Type"
              />
            )
            : (
              <IdleTableCell
                value={value.fixedAssetType?.name || ''}
                placeholderText="Select Asset Type"
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: 'In-Service Date',
      customHeader: (
        <DatepickerFilter
          _key={subledgerId}
          label="In-Service Date"
          value={getFilterValueByFilterName(filters, 'inServiceDate')}
          onChange={(filter) => setFilter('inServiceDate', filter)}
        />
      ),
      varKey: 'inServiceDate',
      colType: ITableItemType.DATE,
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="inServiceDate"
          minWidth="200"
        >
          {updatingId === value.id && (
            <LoadingBox
              transparent
              size={20}
            />
          )}
          {selectedRow === value.id && isSameMonth(
            parseISO(value?.transaction?.postingDate || subledgerDetails.startDate), parseISO(selectedDate),
          )
            ? (
              <Controller
                name="inServiceDate"
                control={control}
                defaultValue={value.inServiceDate ? new Date(value.inServiceDate) : null}
                render={({ onChange, onBlur, value: localValue }) => (
                  <FactaDatePicker
                    selectedDate={localValue}
                    error={!!errors?.inServiceDate}
                    onDateChange={onChange}
                    openToDate={value.inServiceDate
                      ? new Date(value.inServiceDate)
                      : parseISO(selectedDate)}
                    isNullable
                    onBlur={() => {
                      onBlur();
                      handleOnBlur(value.id);
                    }}
                  />
                )}
              />
            )
            : (
              <IdleTableCell
                value={(value.inServiceDate && format(value.inServiceDate, FULL_DATE_FORMAT)) || ''}
                placeholderText="mm/dd/yyyy"
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: 'Frequency',
      varKey: 'schedulingMethod',
      customHeader: (
        <MultipickerFilter
          _key={subledgerId}
          label="Frequency"
          value={getFilterValueByFilterName(filters, 'schedulingMethod')}
          options={schedulingMethodOptions || []}
          onChange={(filter) => setFilter('schedulingMethod', filter)}
        />
      ),
      colType: ITableItemType.SORTKEY,
      sortKey: ['label'],
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="schedulingMethod"
          minWidth="150"
        >
          {updatingId === value.id && (
            <LoadingBox
              transparent
              size={20}
            />
          )}
          {selectedRow === value.id
            ? (
              <FactaAutocomplete
                control={control}
                options={schedulingMethodOptions}
                optionName="label"
                name="schedulingMethod"
                defaultValue={value.schedulingMethod}
                error={errors?.schedulingMethod}
                onBlur={() => handleOnBlur(value.id)}
                disableClearable
                placeholder="Select Frequency"
              />
            )
            : (
              <IdleTableCell
                value={value.schedulingMethod?.label || ''}
                placeholderText="Select Frequency"
              />
            )}
        </StyledTableCell>
      ),
    },
    ...(tab === SchedulerTab.UNSCHEDULED ? [{
      colName: 'Original Amount',
      customHeader: (
        <AmountFilter
          _key={subledgerId}
          label="Original Amount"
          value={getFilterValueByFilterName(filters, 'originalValue')}
          onChange={(filter) => setFilter('originalValue', filter)}
        />
      ),
      varKey: 'originalValue',
      colType: ITableItemType.NUMMARY,
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="originalValue"
          minWidth="190"
          alignRight
        >
          {currencyFormatter.format(value.originalValue)}
        </StyledTableCell>
      ),
    }] : []),
    ...(tab === SchedulerTab.SCHEDULED ? [{
      colName: 'Net Book Value',
      customHeader: (
        <AmountFilter
          _key={subledgerId}
          label="Net Book Value"
          value={getFilterValueByFilterName(filters, 'nbv')}
          onChange={(filter) => setFilter('nbv', filter)}
        />
      ),
      varKey: 'nbv',
      colType: ITableItemType.NUMMARY,
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="nbv"
          minWidth="190"
          alignRight
        >
          {currencyFormatter.format(value.nbv)}
        </StyledTableCell>
      ),
    }] : []),
    {
      colName: `${format(parseISO(selectedDate), MONTH_SHORT_FORMAT)} Depreciation Amount`,
      customHeader: (
        <Box
          display="flex"
          flexDirection="column"
        >
          <span>{format(parseISO(selectedDate), MONTH_SHORT_FORMAT)}</span>
          <span>Depreciation Amount</span>
        </Box>
      ),
      alignRight: true,
      varKey: 'periodDepreciationAmount',
      colType: ITableItemType.NUMMARY,
      headerStyles: { textAlign: 'right' as const },
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="periodDepreciationAmount"
          minWidth="210"
          alignRight
        >
          {updatingId === value.id && (
            <LoadingBox
              transparent
              size={20}
            />
          )}
          {selectedRow === value.id && value.schedulingMethod?.value === SchedulingMethod.MANUAL
            ? (
              <NumberInput
                control={control}
                name="periodDepreciationAmount"
                defaultValue={value.periodDepreciationAmount}
                error={errors?.periodDepreciationAmount}
                onBlur={() => handleOnBlur(value.id)}
              />
            )
            : currencyFormatter.format(value.periodDepreciationAmount)}
        </StyledTableCell>
      ),
    },
    {
      colName: '',
      varKey: 'saveSchedule',
      colType: ITableItemType.ACTION,
      child: (value: FixedAssetForm) => (
        <SaveAndScheduleCell
          key="saveSchedule"
          handleSubmit={handleSubmit}
          onSave={handleSave}
          onSchedule={handleSchedule}
          onEditFixedAsset={handleEditFixedAsset}
          onWriteOffFixedAsset={handleWriteOffFixedAsset}
          isPeriodLocked={isPeriodLocked}
          asset={value}
          selectedRow={selectedRow}
          isAssetEditable={value.isAssetEditable}
          isCurrentPeriod={isCurrentPeriod}
        />
      ),
    },
  ];

  const defaultSort = {
    key: 'transaction',
    order: SortOrder.DESC,
  };

  const handleRowSelect = (ev: React.MouseEvent<HTMLTableRowElement, MouseEvent>, id: string) => {
    const isAssetEditable = find(fixedAssets, { id })?.isAssetEditable || isCurrentPeriod;

    if (!isAssetEditable) return;

    if (id !== selectedRow) {
      handleInstantSave(selectedRow);
      setSelectedRow(id);
    } else {
      setSelectedRow(id);
    }
  };
  const handleOnBlur = (assetId: string) => {
    setAutosaveData({ assetId, data: getValues() as FixedAssetForm });
  };

  const handleInstantSave = (assetId?: string) => {
    const { isDirty } = formState;
    setAutosaveData({ assetId: undefined, data: undefined });
    if (assetId && isDirty) {
      setUpdatingId(assetId);
      handleSave({
        assetId,
        data: getValues() as FixedAssetForm,
        shouldSchedule: false,
        updateSilently: true,
        dontUpdateState: false,
      });
    }
  };

  const handleClickAway = () => {
    if (selectedRow && !assetTypeFlyover.isOpen) {
      handleInstantSave(selectedRow);
      setSelectedRow(undefined);
    }
  };

  useDebouncedEffect(() => {
    if (autosaveData.assetId && autosaveData.data) {
      handleSave({
        assetId: autosaveData.assetId,
        data: autosaveData.data,
        shouldSchedule: false,
        updateSilently: true,
        dontUpdateState: true,
      });
    }
  }, [autosaveData], AUTOSAVE_DELAY);

  const handleSave = ({
    assetId,
    data,
    shouldSchedule,
    updateSilently,
    dontUpdateState,
  }: SaveMethodProps) => {
    const parsedData = {
      assetId,
      fixedAsset: {
        number: data.number,
        description: data.description,
        fixedAssetTypeId: data.fixedAssetType?.id || null,
        originalValue: data.originalValue,
        inServiceDate: data.inServiceDate && formatISO(data.inServiceDate, { representation: 'date' }),
        fullyDepreciatedDate: data
          .fullyDepreciatedDate && formatISO(data.fullyDepreciatedDate, { representation: 'date' }),
        schedulingMethod: data.schedulingMethod?.value,
        depreciationAccountId: data.fixedAssetType?.depreciationAccountId || null,
        gainOrLossAccountId: data.fixedAssetType?.gainOrLossAccountId || null,
        tags: data.fixedAssetType?.tags || [],
      },
      updateSilently,
      dontUpdateState,
      effective: formatISO(startOfMonth(parseISO(selectedDate)), { representation: 'date' }),
    };

    reduxDispatch(updateFixedAsset(parsedData))
      .unwrap()
      .then((result) => {
        if (!updateSilently) setSelectedRow(undefined);
        if (shouldSchedule) {
          handleSchedule(assetId, data.periodDepreciationAmount);
        }

        if (data.schedulingMethod?.value === SchedulingMethod.MANUAL && tab === SchedulerTab.SCHEDULED) {
          const createOrUpdateMethod = result.schedule
            ? updateManualStepForFixedAsset
            : createManualStepForFixedAsset;

          reduxDispatch(createOrUpdateMethod({
            assetId,
            fixedAssetManualStep: {
              periodStart: formatISO(startOfMonth(parseISO(selectedDate)), { representation: 'date' }),
              periodEnd: selectedDate,
              amount: data.periodDepreciationAmount,
            },
            updateSilently: true,
          }));
        }
      })
      .finally(() => {
        setUpdatingId('');
      });
  };

  const handleSchedule = (assetId: string, periodDepreciationAmount?: number) => {
    reduxDispatch(finalizeFixedAsset({ assetId }))
      .then(() => {
        if (periodDepreciationAmount) {
          reduxDispatch(createManualStepForFixedAsset({
            assetId,
            fixedAssetManualStep: {
              periodStart: formatISO(startOfMonth(parseISO(selectedDate)), { representation: 'date' }),
              periodEnd: selectedDate,
              amount: periodDepreciationAmount,
            },
            updateSilently: true,
          }));
        }

        if (unscheduledFixedAssets.length === 1) {
          setTab(SchedulerTab.SCHEDULED);
        }
      });
  };

  const handleEditFixedAsset = (assetId: string) => {
    setEditFlyover({
      isOpen: true,
      asset: find(allFixedAssets, { id: assetId }),
    });
  };

  const handleWriteOffFixedAsset = (assetId: string) => {
    setWriteOffFlyover({
      isOpen: true,
      asset: find(allFixedAssets, { id: assetId }),
    });
  };

  const handleSetPage = (p: number) => {
    setPage(p);
    setSelectedRow(undefined);
  };

  useEffect(() => {
    if (highlighted) {
      setTimeout(() => setHighlighted(''), HIGHLIGHT_RESET);
    }
  }, [highlighted]);

  useEffect(() => {
    setSelectedSources([]);
  }, [tab]);

  useEffect(() => {
    if (selectedSources.length > 1 && selectedRow) {
      setSelectedRow('');
    }

    const isAllUncheduledSelected = selectedSources.length === unscheduledFixedAssets.length
      && tab === SchedulerTab.UNSCHEDULED && unscheduledFixedAssets.length > 0;

    const isAllScheduledSelected = selectedSources.length === editableScheduledFixedAssets.length
      && tab === SchedulerTab.SCHEDULED && editableScheduledFixedAssets.length > 0;

    isAllUncheduledSelected || isAllScheduledSelected
      ? setIsAllSelected(true)
      : setIsAllSelected(false);
  }, [selectedSources, selectedRow, unscheduledFixedAssets.length, editableScheduledFixedAssets.length, tab]);

  useEffect(() => {
    reset();
    setSelectedByTabbing(false);
  }, [selectedRow, reset]);

  const emptyStateDescription = numberOfFiltersApplied
    ? (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        No results for selected filters
        <Button
          color="primary"
          variant="contained"
          onClick={clearAllFilters}
          style={{ marginTop: 10 }}
        >
          Clear all filters
        </Button>
      </Box>
    )
    : (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        Nothing to see here...
        <Button
          color="primary"
          variant="contained"
          onClick={() => setTab(tab === SchedulerTab.SCHEDULED
            ? SchedulerTab.UNSCHEDULED
            : SchedulerTab.SCHEDULED)}
          style={{ marginTop: 10 }}
        >
          {tab === SchedulerTab.SCHEDULED && 'See unscheduled assets'}
          {tab === SchedulerTab.UNSCHEDULED && 'See scheduled assets'}
        </Button>
      </Box>
    );

  return (
    <>
      {fixedAssetTypes && (
        <TableComponent
          colProps={colProps}
          tableData={fixedAssets}
          defaultSort={defaultSort}
          contentType={ITableType.fixedAssetsAmortizationSources}
          topBarActions={[]}
          emptyStateDescription={emptyStateDescription}
          onRowClick={handleRowSelect}
          onClickOutsideSelectedRow={handleClickAway}
          selectedId={selectedRow}
          selectedIds={selectedSources}
          addedRowRef={addedRowRef}
          highlightedId={highlighted}
          page={page}
          setPage={handleSetPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          touched={!isCurrentPeriod ? touched : []}
        >
          <BulkEdit
            productCategory={ProductCategory.FixedAsset}
            onClose={handleBulkEditClose}
            onBulkEditApply={handleBulkEditApply}
            {...(tab === SchedulerTab.UNSCHEDULED && { onBulkEditSchedule })}
            selectedSources={selectedSources}
            isOpen={selectedSources.length > 1}
            isFullyEditable={false}
            isAllSelected={isAllSelected}
          />
        </TableComponent>
      )}
      <EditFixedAsset
        asset={editFlyover.asset}
        isOpen={editFlyover.isOpen}
        onClose={(id) => {
          setEditFlyover({ isOpen: false, asset: undefined });
          if (id) setHighlighted(id);
        }}
        selectedDate={selectedDate}
        isSchedulerView
      />
      <WriteOffFixedAsset
        asset={writeOffFlyover.asset}
        isOpen={writeOffFlyover.isOpen}
        onClose={(id) => {
          setWriteOffFlyover({ isOpen: false, asset: undefined });
          if (id) setHighlighted(id);
        }}
        selectedDate={selectedDate}
      />
      <AddEditAssetType
        assetType={assetTypeFlyover.assetType}
        isOpen={assetTypeFlyover.isOpen}
        customSaveLabel="Save & Apply"
        onClose={(assetType) => {
          setAssetTypeFlyover({ isOpen: false, assetType: assetTypeFlyover.assetType });
          if (assetType?.id) setValue('fixedAssetType', assetType);
        }}
      />
    </>
  );
};
