import React from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { useTableStyles } from '../Subledger/Subledger.styled';

interface IMenuItem {
  action: () => void;
  title: string;
  hidden?: boolean;
  disabled?: boolean;
}

interface Props {
  menuItems: Array<IMenuItem>;
  children: React.ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
}

const ContextMenu = ({
  menuItems,
  children,
  onOpen,
  onClose,
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    if (onOpen) {
      onOpen();
    }
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
    if (onClose) {
      onClose();
    }
  };

  const tableClasses = useTableStyles();

  return (
    <>
      <IconButton
        className={tableClasses.actionCellButtonPadding}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {children}
      </IconButton>
      {anchorEl && (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={handleClose}
        >
          {menuItems.map((menuItem) => !menuItem.hidden && (
            <MenuItem
              key={menuItem.title}
              disabled={menuItem.disabled}
              onClick={menuItem.action}
            >
              {menuItem.title}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

export default ContextMenu;
