import React, {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  addMonths,
  format,
  formatISO,
  isBefore,
  isEqual,
  lastDayOfMonth,
  parseISO,
} from 'date-fns';
import { flatten } from 'ramda';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import {
  Box,
  Button,
  FormControlLabel,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import FactaAutocomplete from '../../Inputs/FactaAutocomplete';
import { InputLabel } from '../../InputLabel';
import MonthPicker from '../../MonthPicker';
import { FactaCheckbox } from '../../Checkbox';
import {
  defRevenueFinalizedAccountSubledgerListSelector,
  prepaidAccountFinalizedSubledgerListSelector,
} from '../../../store/selectors/account';
import { AppThunkDispatch } from '../../../store/store';
import {
  clearReports,
  getWaterfallLegacyReport,
} from '../../../store/slices/reports';
import {
  isFetchingReport,
  waterfallLegacyReportSelector,
} from '../../../store/selectors/reports';
import COLORS from '../../../theme/colors';
import { ProductTypes } from '../../../interfaces/common';
import currencyFormatter from '../../../util/currencyFormatter';
import {
  Account,
  NotifierType,
} from '../../../interfaces/types';
import {
  relevantDateInfo,
  saveFile,
} from '../../../util/helpers';
import { openSnackbar } from '../../Notifier';
import { StyledTableCell } from '../../Table/StyledTableCell';
import { EmptyState } from '../../Table/EmptyState';
import {
  CalendarIcon,
  CopyIcon,
  DownloadExportIcon,
  InfoIcon,
} from '../../Icons';
import {
  FULL_DATE_FORMAT,
  MONTH_SHORT_FORMAT,
} from '../../../util/constants';
import {
  clearSubledger,
  getSubledgerDetails,
} from '../../../store/slices/subledger';
import {
  isFetchingSubledger,
  subledgerStartEndDateSelector,
} from '../../../store/selectors/subledger';
import {
  ScrollableBoxContainer,
  ScrollableTableContainer,
} from '../../ScrollableTable';
import { Loader } from '../../Loader';
import { StyledCalendarInput } from '../../MonthPicker/index.styled';
import { useReportsStyles } from '../reports.styled';
import { BackButton } from '../../BackButton';

const yupSchema = () => yupResolver(yup.object().shape({
  subledgerAccount: yup.object({
    id: yup.string().required(),
    name: yup.string().required(),
  }).required(),
  requiredEndDate: yup.boolean(),
  asOfDate: yup.date().required(),
  endDate: yup.date().nullable()
    .default(null)
    .when('requiredEndDate', {
      is: true,
      then: yup.date()
        .when(['asOfDate', 'endDate'], {
          is: (asOfDate, endDate) => isEqual(lastDayOfMonth(asOfDate), lastDayOfMonth(endDate)),
          then: yup.date().required(),
          otherwise: yup.date().min(yup.ref('asOfDate')),
        })
        .required()
        .typeError(),
      otherwise: yup.date().nullable(),
    }),
}));

interface Props {
  category: ProductTypes;
}

interface GenerateReportData {
  subledgerAccount: Account;
  asOfDate: Date;
  endDate?: Date;
}

export const WaterfallLegacy = ({
  category,
}: Props) => {
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const classes = useReportsStyles();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const isPrepaids = category === ProductTypes.PREPAID;
  const accountBalances = useSelector(isPrepaids
    ? prepaidAccountFinalizedSubledgerListSelector
    : defRevenueFinalizedAccountSubledgerListSelector);
  const isFetching = useSelector(isFetchingReport);
  const isFetchingSubledgerDetails = useSelector(isFetchingSubledger);
  const report = useSelector(waterfallLegacyReportSelector);
  const startEndDates = useSelector(subledgerStartEndDateSelector);

  useEffect(() => () => {
    reduxDispatch(clearReports());
    reduxDispatch(clearSubledger());
  }, [reduxDispatch]);

  const {
    control,
    getValues,
    trigger,
    formState,
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: yupSchema(),
    shouldUnregister: true,
  });

  const handleGenerateReport: SubmitHandler<GenerateReportData> = (data) => {
    const parsedData = {
      subledgerId: data.subledgerAccount.subledger.id,
      startDate: formatISO(lastDayOfMonth(data.asOfDate), { representation: 'date' }),
      ...(data.endDate && { endDate: formatISO(lastDayOfMonth(data.endDate), { representation: 'date' }) }),
    };
    reduxDispatch(getWaterfallLegacyReport(parsedData));
    setIsFormDirty(false);
  };

  const handleCSVTSVData = (data: GenerateReportData, type: string) => {
    const parsedData = {
      subledgerId: data.subledgerAccount.subledger.id,
      startDate: formatISO(lastDayOfMonth(data.asOfDate), { representation: 'date' }),
      ...(data.endDate && { endDate: formatISO(lastDayOfMonth(data.endDate), { representation: 'date' }) }),
      ...(type && { accept: type }),
    };
    reduxDispatch(getWaterfallLegacyReport(parsedData))
      .unwrap()
      .then(({ result, filename }) => {
        if (type === 'text/csv') {
          saveFile(result as string, filename || 'report', type);
        }
        if (type === 'text/tsv') {
          navigator.clipboard.writeText(result as string);
          openSnackbar({ message: 'Copied to clipboard' }, NotifierType.Success);
        }
      });
  };

  const handleAccountChange = () => {
    const accountData = getValues('subledgerAccount');
    reduxDispatch(clearReports());
    if (accountData) {
      reset({ subledgerAccount: accountData, asOfDate: null, endDate: null, requiredEndDate: false });
      reduxDispatch(getSubledgerDetails({ subledgerId: accountData.subledger.id }))
        .unwrap()
        .then((result) => {
          const { endDate } = relevantDateInfo(result.subledgerAmortizationLogs);
          setValue('asOfDate', new Date(endDate!));
        })
        .finally(() => trigger());
    } else {
      reset({ subledgerAccount: null, asOfDate: null, endDate: null, requiredEndDate: false });
    }
  };

  const scheduleTableHeaders = report?.waterfallScheduleSums?.map((head, index) => ({
    label: head.date,
    sum: currencyFormatter.format(head.amount || 0),
    className: clsx(classes.schedule, {
      [classes.leftSeparator]: index === 0,
      [classes.odd]: index % 2,
    }),
    id: uuidv4(),
  })) || [];

  const reportTableHeaders: Array<{
    label: string;
    sum?: string;
    className?: string;
    minWidth?: number;
    id?: string;
  }> = [
    {
      label: 'JE Date',
    },
    {
      label: 'Transaction No.',
    },
    {
      label: 'Description',
      minWidth: 185,
    },
    {
      label: 'Account',
      minWidth: 185,
    },
    {
      label: isPrepaids ? 'Vendor' : 'Customer',
      minWidth: 160,
    },
    {
      label: 'Expense Account',
      minWidth: 185,
    },
    {
      label: 'Class',
    },
    {
      label: 'Amortization Start Date',
    },
    {
      label: 'Amortization End Date',
    },
    {
      label: 'Amortization Schedule',
      sum: 'Total:',
    },
    {
      label: 'Original Amount',
      sum: report.startingBalanceSum ? currencyFormatter.format(report.startingBalanceSum) : 'N/A',
    },
    {
      label: 'Expense To Date',
      sum: report.amortizedToDateSum ? currencyFormatter.format(report.amortizedToDateSum) : 'N/A',
    },
    {
      label: 'Remaining Balance',
      sum: report.remainingBalanceSum ? currencyFormatter.format(report.remainingBalanceSum) : 'N/A',
    },
    ...flatten(scheduleTableHeaders),
  ];

  const renderTableBody = () => (
    <>
      {report.rows.map((reportRow) => (
        <TableRow key={reportRow.id}>
          <StyledTableCell>
            {reportRow.postingDate && format(parseISO(reportRow.postingDate), FULL_DATE_FORMAT)}
          </StyledTableCell>
          <StyledTableCell>{reportRow.journalNumber}</StyledTableCell>
          <StyledTableCell>{reportRow.description}</StyledTableCell>
          <StyledTableCell>{reportRow.account}</StyledTableCell>
          <StyledTableCell>{isPrepaids ? reportRow.vendorName : reportRow.customerName}</StyledTableCell>
          <StyledTableCell>{reportRow.destinationAccount}</StyledTableCell>
          <StyledTableCell>{reportRow.className}</StyledTableCell>
          <StyledTableCell>
            {reportRow.amortizationStartDate && format(parseISO(reportRow.amortizationStartDate), FULL_DATE_FORMAT)}
          </StyledTableCell>
          <StyledTableCell>
            {reportRow.amortizationEndDate && format(parseISO(reportRow.amortizationEndDate), FULL_DATE_FORMAT)}
          </StyledTableCell>
          <StyledTableCell>{reportRow.amortizationScheduleType}</StyledTableCell>
          <StyledTableCell>{currencyFormatter.format(reportRow.startingBalance)}</StyledTableCell>
          <StyledTableCell>{currencyFormatter.format(reportRow.amortizedToDate)}</StyledTableCell>
          <StyledTableCell>{currencyFormatter.format(reportRow.remainingBalance)}</StyledTableCell>
          {reportRow.waterfallSchedule.map((sch, index) => (
            <StyledTableCell
              key={`${reportRow.id}_${sch.date}`}
              className={clsx(classes.schedule, {
                [classes.leftSeparator]: index === 0,
                [classes.odd]: index % 2,
              })}
            >
              {currencyFormatter.format(sch.amount)}
            </StyledTableCell>
          ))}
        </TableRow>
      ))}
    </>
  );

  return (
    <>
      <Loader open={isFetching || isFetchingSubledgerDetails} />
      <Box>
        <BackButton
          label="Back to report list"
          path="/reports/standard-reports"
        />
      </Box>
      <>
        <Typography className={classes.formHeader}>
          {isPrepaids
            ? 'Prepaid Expense Schedule'
            : 'Deferred Revenue Schedule'}
        </Typography>
        <form className={classes.form}>
          <Box className={classes.formCell}>
            <InputLabel
              label="Account name:"
              isHorizontal
            />
            <FactaAutocomplete
              className={classes.accountCell}
              options={accountBalances || []}
              control={control}
              optionName="displayName"
              name="subledgerAccount"
              defaultValue={null}
              onChange={() => {
                setIsFormDirty(true);
                handleAccountChange();
              }}
            />
          </Box>
          <Box className={classes.formCell}>
            <InputLabel
              label="As of date:"
              isHorizontal
              disabled={!getValues('subledgerAccount')}
              tooltipText="As of date will always be the last day of the month."
              centered
            />
            <Controller
              name="asOfDate"
              control={control}
              defaultValue={null}
              render={({ onChange, value }) => (
                <MonthPicker
                  selectedDate={value}
                  includeDates={startEndDates.datesInterval}
                  onDateChange={(date) => {
                    setIsFormDirty(true);
                    onChange(date);
                    if (getValues('endDate') && isBefore(
                      lastDayOfMonth(getValues('endDate')),
                      lastDayOfMonth(getValues('asOfDate')),
                    )) {
                      setValue('endDate', getValues('asOfDate'));
                    }
                    trigger();
                  }}
                  disabled={!getValues('subledgerAccount')}
                  customInput={(
                    <StyledCalendarInput>
                      <Typography>{value && format(value, MONTH_SHORT_FORMAT)}</Typography>
                      <CalendarIcon />
                    </StyledCalendarInput>
                  )}
                />
              )}
            />
          </Box>
          <Box className={classes.formCell}>
            <InputLabel
              label="End date:"
              isHorizontal
              disabled={!getValues('subledgerAccount') || !getValues('requiredEndDate')}
              tooltipText={`End date will always be the last day of the month 
                and designates the final month of the waterfall view.`}
              centered
            />
            <Controller
              name="endDate"
              control={control}
              defaultValue={null}
              render={({ onChange, value }) => (
                <MonthPicker
                  selectedDate={value}
                  minDate={getValues('asOfDate') || new Date(startEndDates.startDate!) || undefined}
                  onDateChange={(date) => {
                    setIsFormDirty(true);
                    onChange(date);
                    trigger();
                  }}
                  disabled={!getValues('subledgerAccount') || !getValues('requiredEndDate')}
                  customInput={(
                    <StyledCalendarInput>
                      <Typography>{value && format(value, MONTH_SHORT_FORMAT)}</Typography>
                      <CalendarIcon />
                    </StyledCalendarInput>
                  )}
                />
              )}
            />
          </Box>
          <Box className={classes.checkboxWrapper}>
            <Controller
              name="requiredEndDate"
              control={control}
              defaultValue={false}
              render={({ onChange, value }) => (
                <FormControlLabel
                  control={(
                    <FactaCheckbox
                      checked={value}
                      disabled={!getValues('subledgerAccount')}
                      onChange={(e) => {
                        setIsFormDirty(true);
                        onChange(e.target.checked);
                        setValue(
                          'endDate',
                          e.target.checked
                            ? addMonths(new Date(startEndDates.endDate!), 11)
                            : null,
                        );
                        trigger();
                      }}
                    />
                  )}
                  label="Add an end date"
                />
              )}
            />
          </Box>
          <Button
            style={{ marginTop: '24px' }}
            variant="contained"
            color="primary"
            disabled={!formState.isValid || !isFormDirty}
            onClick={handleSubmit(handleGenerateReport)}
          >
            Generate report
          </Button>
        </form>
      </>
      <Typography className={classes.formHeader}>Report</Typography>
      <Box className={classes.actionHeader}>
        <Box
          marginRight="auto"
          marginLeft={2}
          display="flex"
          alignItems="center"
          color={COLORS.skyBlue}
        >
          {!!report?.rows?.length && (
            <>
              <InfoIcon className={classes.infoIcon} />
              <span>
                This report contains
                <strong>{` ${report.rows.length || 0} row${report.rows.length > 1 ? 's' : ''}.`}</strong>
              </span>
            </>
          )}
        </Box>
        <Button
          className={classes.actionButtons}
          disabled={!report?.rows?.length}
          color="primary"
          onClick={() => handleCSVTSVData(
            getValues() as GenerateReportData,
            'text/csv',
          )}
          endIcon={(<DownloadExportIcon />)}
        >
          Export to CSV
        </Button>
        <Button
          className={classes.actionButtons}
          disabled={!report?.rows?.length}
          color="primary"
          onClick={() => handleCSVTSVData(
            getValues() as GenerateReportData,
            'text/tsv',
          )}
          endIcon={(<CopyIcon />)}
        >
          Copy to clipboard
        </Button>
      </Box>
      {report?.rows?.length > 0 && (
        <Box className={classes.reportContainer}>
          <ScrollableBoxContainer style={{ padding: 0, margin: 0 }}>
            <ScrollableTableContainer style={{ width: '100%' }}>
              <Table
                size="small"
                stickyHeader
                aria-label="Reports table"
              >
                <TableHead>
                  <TableRow>
                    {reportTableHeaders.map(({
                      label,
                      className,
                      minWidth,
                      id,
                      sum,
                    }) => (
                      <StyledTableCell
                        key={id || label}
                        className={clsx(className, {
                          [classes.sumRowEmpty]: !sum,
                          [classes.sumRowFilled]: !!sum,
                        })}
                        minWidth={minWidth ? `${minWidth}` : '100'}
                      >
                        <Box>{sum}</Box>
                      </StyledTableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    {reportTableHeaders.map(({
                      label,
                      className,
                      minWidth,
                      id,
                    }) => (
                      <StyledTableCell
                        key={id || label}
                        className={className}
                        minWidth={minWidth ? `${minWidth}` : '100'}
                      >
                        {label}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderTableBody()}
                </TableBody>
              </Table>
            </ScrollableTableContainer>
          </ScrollableBoxContainer>
        </Box>
      )}
      {(!report?.rows?.length && formState.isSubmitted && !isFetching) && (<EmptyState />)}
    </>
  );
};
