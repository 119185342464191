import React, {
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import {
  useHistory,
  useParams,
  useLocation,
} from 'react-router-dom';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  endOfMonth,
  format,
  isWithinInterval,
  parse,
  startOfMonth,
} from 'date-fns';
import debounce from 'lodash.debounce';
import { useRefreshDataProvider } from '../../../context/dataRefreshContext';
import {
  AmortizationSource,
  BulkEditorType,
  NotifierType,
  Subledger,
} from '../../../interfaces/types';
import {
  ADD_SPLIT,
  ADD_SPLIT_ASSET,
  BULK_EDIT,
  DAY_SHORT_FORMAT,
  FINALIZED_SUBLEDGER_STATUS,
  HISTORICAL_UPDATE_ASSET,
  HISTORICAL_UPDATE_ASSETS,
  INITIALED,
  INITIALED_WITH_LOCAL_STORAGE,
  INPUT_FOCUS,
  SELECT_ROW,
  UNSCHEDULED_DATE_CHANGE,
  UNSCHEDULED_INPUT_CHANGE,
  UNSCHEDULED_REMOVE_ASSET,
  UNSCHEDULED_SELECT_CHANGE,
} from '../../../util/constants';
import {
  checkSchedulerAmortizationSourceDirtyCheck,
  getChildren,
  monthChange,
  schedulerExportToCSV,
  updateHistoricalSubledgerData,
} from '../../../components/Subledger/common';
import ScheduleHeader from './ScheduleHeader';
import reducer from '../../../components/Subledger/reducer';
import ErrorPage from '../../../components/ErrorPage';
import DialogBox from '../../../components/DialogBox';
import {
  deleteSubledger,
  getSubledger,
} from '../../../util/subledger';
import isEmpty from '../../../util/isEmpty';
import HistoricalSchedulerTable from '../../../components/Subledger/HistoricalSchedulerTable';
import { AppThunkDispatch } from '../../../store/store';
import {
  fetchingSubledgerError,
  isFetchingSubledger,
} from '../../../store/selectors/subledger';
import {
  accountInfoSelector,
  accountLastFetchSelector,
} from '../../../store/selectors/account';
import { getSubledgerDetailsByScheduleDate } from '../../../store/slices/subledger';
import { openSnackbar } from '../../../components/Notifier';
import { Loader } from '../../../components/Loader';

interface Props {
  scheduleDate: string;
  initSubledger?: Subledger;
}

interface Params {
  id: string;
}

const Scheduler = ({
  scheduleDate,
  initSubledger,
}: Props) => {
  const { id: subledgerId } = useParams<Params>();
  const location = useLocation();
  const history = useHistory();
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const { refreshDate } = useRefreshDataProvider();
  const useQuery = () => new URLSearchParams(location.search);
  const fromPrepareJE = !!useQuery()
    .get('fromPrepareJE');
  const parsedScheduledDate = parse(scheduleDate, DAY_SHORT_FORMAT, new Date());

  const account = useSelector(accountInfoSelector)!;
  const lastFetch = useSelector(accountLastFetchSelector);
  const subledgerLoading = useSelector(isFetchingSubledger);
  const subledgerError = useSelector(fetchingSubledgerError);

  const [openSubledgerDialog, setOpenSubledgerDialog] = useState<boolean>(false);
  const [openConfirmationDialog, setConfirmationDialog] = useState<boolean>(false);
  const [refreshLocal, setRefreshLocal] = useState<Date>(refreshDate);
  const [serviceData, setServiceData] = useState<Subledger>();
  const [navigationDate, setNavigationDate] = useState(new Date());

  const {
    accountClasses,
    vendors,
    accountIncomes,
    customers,
    products,
  } = account;

  // @ts-ignore
  const [state, dispatch] = useReducer(reducer, {
    subledger: {},
    selectedRow: '',
  });

  const [selectedSources, setSelectedSources] = useState<string[]>([]);
  const [bulkEditorType, setBulkEditorType] = useState(BulkEditorType.NONE);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [isBulkEditApplied, setIsBulkEditApplied] = useState<boolean>(false);

  const getData = useCallback(async () => {
    if (!fromPrepareJE || refreshLocal !== refreshDate) {
      setRefreshLocal(refreshDate);
      deleteSubledger();
      if (!subledgerLoading && !subledgerError) {
        reduxDispatch(getSubledgerDetailsByScheduleDate({
          subledgerId,
          scheduleDate,
          requiredAmortizationToDate: true,
        }))
          .unwrap()
          .then((result) => {
            setServiceData(result);
            if (result.status !== FINALIZED_SUBLEDGER_STATUS) {
              history.push(`/subledgers/scheduler/${subledgerId}`);
            }
            dispatch({
              type: INITIALED,
              payload: {
                subledger: result,
                scheduleDate,
                historicalUpdatedAssets: [],
              },
            });
          });
      }
    } else {
      const {
        subledger: localSubledger,
        historicalUpdatedAssets: localHistoricalPrepaidAssets,
      } = getSubledger();
      dispatch({
        type: INITIALED_WITH_LOCAL_STORAGE,
        payload: {
          subledger: localSubledger,
          historicalUpdatedAssets: localHistoricalPrepaidAssets,
          scheduleDate,
        },
      });

      if (fromPrepareJE) {
        const pathWithoutFromPrepareJe = window.location.search.replace('&fromPrepareJE=true', '');
        history.push(pathWithoutFromPrepareJe);
      }
    }
  }, [
    fromPrepareJE,
    history,
    reduxDispatch,
    refreshDate,
    refreshLocal,
    scheduleDate,
    subledgerError,
    subledgerId,
    subledgerLoading,
  ]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastFetch, scheduleDate, refreshDate]);

  const {
    subledger,
    selectedRow,
    historicalUpdatedAssets,
  } = state;

  useEffect(() => {
    if (selectedSources.length > 1 && selectedRow) {
      dispatch({
        type: SELECT_ROW,
        payload: { selectedRow: '' },
      });
    }

    if (!bulkEditorType && selectedSources.length === 1) {
      const type = !subledger?.amortizationSources
        .find((source: AmortizationSource) => source.internalId === selectedSources[0]).status
        ? BulkEditorType.UNSCHEDULED : BulkEditorType.SCHEDULED;

      setBulkEditorType(type);
    }

    if (bulkEditorType) {
      const isScheduled = bulkEditorType === BulkEditorType.SCHEDULED;
      const amortizationSourcesLength = subledger
        .amortizationSources?.filter((source: AmortizationSource) => getChildren(
          source.internalId,
          subledger?.amortizationSources,
        ).length === 0
        && !source.parentId && (isScheduled ? source.status : !source.status)
        && isWithinInterval(source.sourceCreationDate!, {
          start: startOfMonth(parsedScheduledDate),
          end: endOfMonth(parsedScheduledDate),
        }))?.length;

      (selectedSources.length === amortizationSourcesLength && amortizationSourcesLength > 0)
        ? setIsAllSelected(true)
        : setIsAllSelected(false);

      selectedSources.length === 0 && setBulkEditorType(BulkEditorType.NONE);
    }
  }, [bulkEditorType, selectedSources, selectedRow, dispatch, subledger.amortizationSources, parsedScheduledDate]);

  useEffect(() => {
    if (isBulkEditApplied) {
      const amortizationSources = subledger.amortizationSources
        .filter((source: AmortizationSource) => selectedSources.includes(source.internalId));

      const isAmortizationSourcesInvalid = amortizationSources
        .some((source: AmortizationSource) => !checkSchedulerAmortizationSourceDirtyCheck(source, subledger));

      if (!isAmortizationSourcesInvalid) {
        dispatch({
          type: HISTORICAL_UPDATE_ASSETS,
          payload: { assets: amortizationSources, internalIds: selectedSources },
        });
      } else {
        openSnackbar({ message: 'Unable to proceed with schedule, incorrect data.' }, NotifierType.Error);
      }

      onEditClose();
      setIsBulkEditApplied(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBulkEditApplied]);

  if (subledgerLoading && isEmpty(subledger)) {
    return <Loader open />;
  }

  if (subledgerError) {
    return <ErrorPage />;
  }

  const onMonthChange = (previousDateType: Date) => {
    setNavigationDate(previousDateType);
    if (historicalUpdatedAssets?.length > 0) {
      setOpenSubledgerDialog(true);
    } else {
      navigateToDates(previousDateType);
    }
  };

  const switchDates = () => {
    setOpenSubledgerDialog(false);
    deleteSubledger();
    navigateToDates(navigationDate);
  };

  const navigateToDates = (previousDateType: Date) => {
    const {
      equalDates,
      scheduleDateToNavigate,
    } = monthChange(previousDateType, subledger);
    const date = format(scheduleDateToNavigate, DAY_SHORT_FORMAT);
    if (equalDates) {
      history.push(`/subledgers/schedule/${subledger.id}/?scheduleDate=${date}`);
    } else {
      deleteSubledger();
      history.push(`/historical/subledgers/schedule/${subledger.id}/?scheduleDate=${date}`);
    }
  };

  const onRowSelect = (internalId: string) => () => {
    if (selectedSources.length > 1) {
      return;
    }

    dispatch({
      type: SELECT_ROW,
      payload: { selectedRow: internalId },
    });
  };

  const resetSelected = debounce(onRowSelect(''), 200);

  const onSave = (existingAsset: AmortizationSource) => async () => {
    dispatch({
      type: HISTORICAL_UPDATE_ASSET,
      payload: { internalId: existingAsset.internalId },
    });
    resetSelected();
  };

  const addSplit = (internalId: string) => () => {
    dispatch({
      type: ADD_SPLIT,
      payload: { internalId },
    });
  };

  const addSplitAsset = (internalId: string) => () => {
    dispatch({
      type: ADD_SPLIT_ASSET,
      payload: { internalId },
    });
  };

  const removeAsset = (internalId: string) => () => {
    dispatch({
      type: UNSCHEDULED_REMOVE_ASSET,
      payload: { internalId },
    });
  };

  const onDateChange = (propertyName: string, internalId: string, value: Date | null) => {
    dispatch({
      type: UNSCHEDULED_DATE_CHANGE,
      payload: {
        internalId,
        propertyName,
        value,
        scheduleDate,
      },
    });
  };

  const onAutoCompleteChange = (propertyName: string, internalId: string) => (value: string) => {
    dispatch({
      type: UNSCHEDULED_SELECT_CHANGE,
      payload: {
        internalId,
        propertyName,
        value,
        scheduleDate,
      },
    });
  };

  const onInputBoxChange = (
    propertyName: string,
    internalId: string,
  ) => (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: UNSCHEDULED_INPUT_CHANGE,
      payload: {
        internalId,
        propertyName,
        value: event.target.value,
        scheduleDate,
      },
    });
  };

  const onInputBoxFocus = (
    propertyName: string,
    internalId: string,
  ) => (event: React.FocusEvent<HTMLInputElement>) => {
    dispatch({
      type: INPUT_FOCUS,
      payload: {
        propertyName,
        internalId,
        target: event.target,
        type: event.type,
      },
    });
  };

  const isSaveEnabled = (asset: AmortizationSource) => (
    checkSchedulerAmortizationSourceDirtyCheck(asset, subledger, initSubledger)
  );

  const navigateToPrepareJE = () => {
    updateHistoricalSubledgerData(historicalUpdatedAssets, subledger, serviceData);
    history.push(`/historical/subledgers/schedule/${subledger.id}/prepare-je/?scheduleDate=${scheduleDate}`);
  };

  // @ts-ignore
  const onCSVExport = () => {
    schedulerExportToCSV({
      subledger,
      vendors,
      accountClasses,
      accountIncomes,
      account,
      scheduleDate,
      customers,
      products,
    });
  };

  const closeDialog = () => {
    setOpenSubledgerDialog(false);
    setConfirmationDialog(false);
  };

  const onCancel = () => {
    if (historicalUpdatedAssets?.length > 0) {
      setConfirmationDialog(true);
    } else {
      navigateBack();
    }
  };

  const navigateBack = () => {
    deleteSubledger();
    setConfirmationDialog(false);
    history.push(`/journal-entries/?account_id=${subledger?.account?.id}`);
  };

  const onBulkEditApply = (internalIds: Array<string>, changedProperties: any) => {
    dispatch({
      type: BULK_EDIT,
      payload: {
        internalIds,
        changedProperties,
        scheduleDate,
      },
    });
  };

  const onBulkEditSchedule = (internalIds: Array<string>, changedProperties: any) => {
    onBulkEditApply(internalIds, changedProperties);
    setIsBulkEditApplied(true);
  };

  const onCheckboxChange = (internalId: string) => {
    selectedSources.includes(internalId)
      ? setSelectedSources(selectedSources.filter((source) => source !== internalId))
      : setSelectedSources([...selectedSources, internalId]);
  };

  const clearSelected = () => {
    setSelectedSources([]);
    setBulkEditorType(BulkEditorType.NONE);
  };

  const onSelectAll = (assetsType: BulkEditorType) => {
    const isScheduled = assetsType === BulkEditorType.SCHEDULED;
    const handleSelectAll = (type: BulkEditorType) => {
      setSelectedSources(subledger.amortizationSources
        .filter((source: AmortizationSource) => getChildren(
          source.internalId,
          subledger?.amortizationSources,
        ).length === 0
          && !source.parentId && (isScheduled ? source.status : !source.status)
          && isWithinInterval(source.sourceCreationDate!, {
            start: startOfMonth(parsedScheduledDate),
            end: endOfMonth(parsedScheduledDate),
          }))
        .map((source: AmortizationSource) => source.internalId));
      setBulkEditorType(type);
      setIsAllSelected(true);
    };

    if (isAllSelected && assetsType === bulkEditorType) {
      clearSelected();
    } else {
      handleSelectAll(assetsType);
    }
  };

  const onEditClose = () => {
    clearSelected();
    setIsAllSelected(false);
  };

  const isElementSelected = (internalId: string) => selectedSources.includes(internalId);
  const isAllSelectedByType = (type: BulkEditorType) => isAllSelected && bulkEditorType === type;

  // @ts-ignore
  return (
    <>
      <DialogBox
        openDialog={openSubledgerDialog}
        closeDialog={closeDialog}
        dialogContext={'Are you sure you want to navigate away from this page?'
        + ' \n If you press "Yes" now, ALL your changes will be lost!'}
        dialogTitle="Alert"
        dismissContext="Cancel"
        actions={[{
          title: 'YES',
          event: switchDates,
        }]}
      />
      <DialogBox
        openDialog={openConfirmationDialog}
        closeDialog={closeDialog}
        dialogContext={'Are you sure you want to navigate away from this page?'
        + ' \n If you press "Yes" now, ALL your changes will be lost!'}
        dialogTitle="Alert"
        dismissContext="Cancel"
        actions={[{
          title: 'YES',
          event: navigateBack,
        }]}
      />
      <Loader open={subledgerLoading} />
      <ScheduleHeader
        subledger={subledger}
        scheduleDate={scheduleDate}
        downloadCSV={onCSVExport}
        navigateToPrepareJE={navigateToPrepareJE}
        onCancel={onCancel}
        onMonthChange={onMonthChange}
      />
      <HistoricalSchedulerTable
        subledger={subledger}
        selectedRow={selectedRow}
        scheduleDate={scheduleDate}
        onSave={onSave}
        onRowSelect={onRowSelect}
        isSaveEnabled={isSaveEnabled}
        onInputBoxChange={onInputBoxChange}
        onInputBoxFocus={onInputBoxFocus}
        removeAsset={removeAsset}
        onDateChange={onDateChange}
        onAutoCompleteChange={onAutoCompleteChange}
        addSplitAsset={addSplitAsset}
        addSplit={addSplit}
        onBulkEditApply={onBulkEditApply}
        onEditClose={onEditClose}
        isElementSelected={isElementSelected}
        onCheckboxChange={onCheckboxChange}
        isAllSelected={isAllSelectedByType}
        onSelectAll={onSelectAll}
        selectedSources={selectedSources}
        onBulkEditSchedule={onBulkEditSchedule}
        bulkEditorType={bulkEditorType}
      />
    </>
  );
};

export default Scheduler;
