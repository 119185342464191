import {
  ButtonBase,
  styled as muiStyled,
} from '@material-ui/core';
import styled from 'styled-components';
import COLORS from '../../theme/colors';
import { CalendarIcon } from '../Icons';

export const StyledCalendarIcon = muiStyled(CalendarIcon)({
  width: 13,
  height: 14,
});

interface Props {
  withError?: boolean;
}

export const StyledCalendarInput = styled(ButtonBase).withConfig({
  shouldForwardProp: (prop) => !['withError'].includes(prop),
})<Props>`
  display: flex;
  justify-content: space-between;
  min-width: 130px;
  height: 32px;
  padding: 4px 12px;
  border-radius: 4px;
  border: 1px solid ${({ withError }) => (withError ? COLORS.error : COLORS.border)};

  & p {
    font-size: 12px;
    line-height: 14px;
  };

  &.Mui-disabled {
    background-color: ${COLORS.lightGrayBackground};
    color: ${COLORS.disabledInput};
  };
`;
