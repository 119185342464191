import clsx from 'clsx';
import {
  format,
  isBefore,
  isSameDay,
  max,
} from 'date-fns';
import { TableCell } from '@material-ui/core';
import React from 'react';
import { FULL_DAY_FORMAT } from '../../../util/constants';
import { useTableStyles } from '../Subledger.styled';
import {
  AmortizationSource,
  LegacySchedulingMethod,
} from '../../../interfaces/types';
import FactaDatePicker from '../../DatePicker';

interface Props {
  amortizationSource: AmortizationSource;
  hasChildren?: boolean;
  lastChild?: boolean;
  highlighted?: boolean;
  showCursor?: boolean;
  isRowSelected?: boolean;
  onDateChange?: (propertyName: string, internalId: string, value: Date | null) => void;
  isCellSticky?: string;
}

const AmortizationEndDateCell = ({
  amortizationSource,
  hasChildren,
  lastChild,
  highlighted,
  showCursor,
  isRowSelected,
  onDateChange,
  isCellSticky,
}: Props) => {
  const tableClasses = useTableStyles();
  const amortizationMinEndDate = amortizationSource?.amortizationSchedule?.amortizationStartDate
    ? max([amortizationSource.sourceCreationDate!, amortizationSource.amortizationSchedule.amortizationStartDate])
    : amortizationSource?.sourceCreationDate!;
  return (
    <TableCell
      className={clsx(tableClasses.datePickerCell, {
        [tableClasses.child]: amortizationSource.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.bold]: highlighted,
        [tableClasses.lastChild]: lastChild,
        [tableClasses.cursorPointer]: showCursor,
      })}
      is-cell-sticky={isCellSticky}
    >
      {
        hasChildren && 'Multiple'
      }
      {
        isRowSelected && !hasChildren && onDateChange && (
          <FactaDatePicker
            disabled={amortizationSource
              .amortizationSchedule.amortizationScheduleType === LegacySchedulingMethod.MANUAL}
            error={amortizationSource.amortizationSchedule.amortizationScheduleType !== LegacySchedulingMethod.MANUAL
            && amortizationMinEndDate
            && amortizationSource.amortizationSchedule.amortizationEndDate
            && isBefore(amortizationSource.amortizationSchedule.amortizationEndDate, amortizationMinEndDate)
            && !isSameDay(amortizationSource.amortizationSchedule?.amortizationEndDate!, amortizationMinEndDate)}
            minDate={amortizationMinEndDate}
            selectedDate={amortizationSource.amortizationSchedule.amortizationEndDate}
            onDateChange={(newDate) => onDateChange('amortizationEndDate', amortizationSource.internalId, newDate)}
          />
        )
      }
      {
        amortizationSource.amortizationSchedule.amortizationScheduleType === LegacySchedulingMethod.MANUAL
        && !isRowSelected && !hasChildren && 'M'
      }
      {
        amortizationSource.amortizationSchedule.amortizationScheduleType !== LegacySchedulingMethod.MANUAL
        && !isRowSelected && !hasChildren && amortizationSource.amortizationSchedule.amortizationEndDate
        && format(amortizationSource.amortizationSchedule.amortizationEndDate, FULL_DAY_FORMAT)
      }
    </TableCell>
  );
};

export default React.memo(AmortizationEndDateCell);
