import React from 'react';
import {
  useRouteMatch,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Layout from '../../components/Layout';
import { CreateSubledger } from './CreateSubledger';
import { EditSubledger } from './EditSubledger';
import { Dashboard } from './Dashboard';
import { FixedAssetsPrepareJE } from './PrepareJE';
import { FixedAssetsScheduler } from './Scheduler';
import { FixedAssetsSchedulerReview } from './SchedulerReview';

export const FixedAssets = () => {
  const { url } = useRouteMatch();

  const fixedAssetsRoutes = [
    {
      title: 'Fixed Assets',
      path: `${url}`,
      component: <Dashboard />,
      isNegativeLayout: true,
    },
    {
      title: 'Scheduler',
      path: `${url}/scheduler/:subledgerId/:subledgerDate?`,
      component: <FixedAssetsScheduler />,
    },
    {
      title: 'Review schedule',
      path: `${url}/scheduler/:subledgerId/:subledgerDate?/review`,
      component: <FixedAssetsSchedulerReview />,
    },
    {
      title: 'Prepare JE',
      path: `${url}/scheduler/:subledgerId/:subledgerDate?/prepare-je`,
      component: <FixedAssetsPrepareJE />,
    },
    {
      title: 'Create Subledger',
      path: `${url}/create/:subledgerId`,
      component: <CreateSubledger />,
    },
    {
      title: 'Edit Subledger',
      path: `${url}/edit/:subledgerId`,
      component: <EditSubledger />,
    },
  ];

  return (
    <Switch>
      {fixedAssetsRoutes.map(({ title, path, component, isNegativeLayout }) => (
        <Route
          key={title}
          path={path}
          exact
        >
          <Layout
            key={title}
            isNegative={isNegativeLayout}
            title={title}
          >
            {component}
          </Layout>
        </Route>
      ))}
      <Redirect
        to={url}
      />
    </Switch>
  );
};
