import clsx from 'clsx';
import { TableCell } from '@material-ui/core';
import React from 'react';
import { useTableStyles } from '../Subledger.styled';
import { AmortizationSource } from '../../../interfaces/types';
import InputBox from '../../InputBox';

interface Props {
  amortizationSource: AmortizationSource;
  hasChildren?: boolean;
  formSubmit?: boolean;
  onInputBoxChange: (propertyName: string, internalId: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  isCellSticky?: string;
}

const DescriptionEditableCell = ({
  amortizationSource,
  hasChildren,
  onInputBoxChange,
  formSubmit,
  isCellSticky,
}: Props) => {
  const tableClasses = useTableStyles();
  return (
    <TableCell
      className={clsx(tableClasses.descriptionCell, {
        [tableClasses.child]: amortizationSource.parentId,
        [tableClasses.parent]: hasChildren,
      })}
      is-cell-sticky={isCellSticky}
    >
      <InputBox
        error={!amortizationSource.description && formSubmit && !amortizationSource.parentId}
        placeholder=""
        value={amortizationSource.description}
        className={tableClasses.descriptionCellBox}
        onChange={onInputBoxChange('description', amortizationSource.internalId)}
      />
    </TableCell>
  );
};

export default React.memo(DescriptionEditableCell);
