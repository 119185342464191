import {
  makeStyles,
  createStyles,
} from '@material-ui/styles';
import COLORS from '../../../theme/colors';

export const importWizardStyles = makeStyles(() => createStyles({
  actionBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  fileButtons: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltipInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    color: COLORS.lightGray,
    marginRight: '8px',
    marginLeft: '32px',
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 500,
  },
  input: {
    marginRight: '26px',
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 500,
  },
  divider: {
    height: '100%',
    width: '1px',
    backgroundColor: COLORS.disabled,
    margin: '0 32px 0 36px',
  },
  errorTab: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    marginRight: '12px',
  },
}));
