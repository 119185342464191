import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  TableCell,
} from '@material-ui/core';
import { QUICKBOOK_SOURCE } from '../../../util/constants';
import RefreshData from '../../RefreshData';
import { useTableStyles } from '../Subledger.styled';
import { AmortizationSource } from '../../../interfaces/types';
import ContextMenu from '../../ContextMenu';
import { MoreHorizIcon } from '../../Icons';

interface Props {
  amortizationSource: AmortizationSource;
  hasChildren?: boolean;
  lastChild?: boolean;
  removeAsset: (internalId: string) => () => void;
  addSplit: (internalId: string) => () => void;
  isSaveEnabled: (asset: AmortizationSource) => boolean;
  onSave: (asset: AmortizationSource) => () => Promise<void>;
  saveTitle?: string;
  subledgerId: string;
  onShowDetails: (internalId: string, sourceId: string) => void;
  isRevSync?: boolean;
}

const SaveRefreshActionCell = ({
  amortizationSource,
  hasChildren,
  lastChild,
  removeAsset,
  addSplit,
  isSaveEnabled,
  onSave,
  saveTitle,
  subledgerId,
  onShowDetails,
  isRevSync,
}: Props) => {
  const tableClasses = useTableStyles();
  const sourceId = amortizationSource.parentId ? '' : amortizationSource.sourceId;
  return (
    <TableCell
      className={clsx(tableClasses.actionCellWithSave, {
        [tableClasses.child]: amortizationSource.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <ContextMenu
          menuItems={[
            {
              title: `View ${isRevSync ? 'revenue recognition' : 'amortization'} schedule`,
              disabled: hasChildren || amortizationSource.startingBalance <= 0,
              hidden: !onShowDetails,
              action: () => onShowDetails(amortizationSource.internalId, sourceId),
            },
            {
              title: 'Split asset',
              action: addSplit(amortizationSource.internalId),
              hidden: !!amortizationSource.parentId,
              disabled: hasChildren || amortizationSource.startingBalance <= 0,
            },
            {
              hidden: !amortizationSource.parentId,
              title: 'Remove asset',
              action: removeAsset(amortizationSource.internalId),
            },
          ]}
        >
          <MoreHorizIcon className={tableClasses.fontSize13} />
        </ContextMenu>
        <Button
          className={saveTitle === 'Save' ? tableClasses.saveButton : tableClasses.scheduledMinWidth}
          variant="contained"
          color="primary"
          size="small"
          disabled={!isSaveEnabled(amortizationSource)}
          onClick={onSave(amortizationSource)}
        >
          {saveTitle}
        </Button>
        {amortizationSource?.origin === QUICKBOOK_SOURCE && (
          <RefreshData
            subledgerId={subledgerId}
            sourceId={sourceId}
          />
        )}
      </Box>
    </TableCell>
  );
};

export default React.memo(SaveRefreshActionCell);
