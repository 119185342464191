import React from 'react';
import clsx from 'clsx';
import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { createStyles } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import CheckIcon from '@material-ui/icons/Check';
import COLORS from '../../theme/colors';

const useStyles = makeStyles(() => createStyles({
  breadcrumbItem: {
    font: 'inherit',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: 32,
    position: 'relative',
    width: 250,
    border: 0,
    background: 'transparent',
    textAlign: 'left',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 7,
      left: 0,
      display: 'block',
      width: 16,
      height: 16,
      borderRadius: '50%',
      border: `1px solid ${COLORS.skyBlue}`,
      backgroundColor: COLORS.white,
    },
    '&:not(:last-of-type):after': {
      content: '""',
      position: 'absolute',
      top: 15,
      left: 32,
      right: 16,
      height: 2,
      backgroundColor: COLORS.chipGray,
      display: 'block',
    },
    '&:last-of-type': {
      width: 'auto',
    },
  },
  violet: {
    '&:before': {
      borderColor: COLORS.violet,
    },
  },
  activeBlue: {
    fontWeight: 700,
    '&:before': {
      backgroundColor: COLORS.skyBlue,
    },
  },
  activeViolet: {
    color: COLORS.black,
    fontWeight: 700,
    '&:before': {
      backgroundColor: COLORS.violet,
    },
  },
  checkedBlue: {
    color: COLORS.lightGray,
    '&:before': {
      backgroundColor: COLORS.skyBlue,
    },
  },
  checkedViolet: {
    color: COLORS.lightGray,
    '&:before': {
      backgroundColor: COLORS.violet,
    },
  },
  checkIcon: {
    color: COLORS.white,
    position: 'absolute',
    fontSize: 12,
    top: 10,
    left: 2,
  },
  step: {
    fontWeight: 700,
    fontSize: 8,
    lineHeight: '16px',
  },
  breadcrumbItemContent: {
    backgroundColor: COLORS.white,
    zIndex: 1,
    paddingRight: 16,
    height: 30,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  hasAction: {
    cursor: 'pointer',
    '&:hover': {
      color: COLORS.skyBlue,
    },
  },
}));

interface Props {
  active?: boolean;
  checked?: boolean;
  children: React.ReactNode;
  violet?: boolean;
  step?: number | string;
  action?: () => void;
}

export const Breadcrumbs = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  margin: '10px 0',
});

export const BreadcrumbsItem = ({
  active,
  checked,
  children,
  violet,
  step,
  action,
}: Props) => {
  const classes = useStyles();

  return (
    <button
      className={clsx(classes.breadcrumbItem, {
        [classes.activeBlue]: active && !violet,
        [classes.activeViolet]: active && violet,
        [classes.violet]: violet,
        [classes.checkedBlue]: checked && !violet,
        [classes.checkedViolet]: checked && violet,
        [classes.hasAction]: !!action,
      })}
      onClick={action}
      type="button"
    >
      <div className={classes.breadcrumbItemContent}>
        {checked && <CheckIcon className={classes.checkIcon} />}
        {step && (
          <div className={classes.step}>
            {`STEP ${step}`}
          </div>
        )}
        {children}
      </div>
    </button>
  );
};
