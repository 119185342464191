import React, {
  useCallback,
  useEffect,
  useRef,
} from 'react';
import {
  Modal,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useNavBarContext } from '../../context/navbarContext';
import COLORS from '../../theme/colors';
import { LLAMA_ANIMATION_AUTO_CLOSE_TIME } from '../../util/constants';

const useStyles = makeStyles({
  meditatingLlama: {
    width: 'calc(100vw/2)',
    height: 'calc(100vh/2)',
    position: 'absolute',
    left: 'calc(100vw-50%)',
    top: 0,
    animation: '$slideDown 3s',
    animationDirection: 'normal',
    animationFillMode: 'forwards',
  },
  mountain: {
    height: 'calc(100vh - 10%)',
    width: 'calc(100vw - 10%)',
    position: 'absolute',
    bottom: 0,
    left: 0,
    animation: '$slideUp 3s',
    animationDirection: 'normal',
    animationFillMode: 'forwards',
  },
  '@keyframes slideDown': {
    '0%': {
      top: -500,
    },
    '100%': {
      top: 0,
    },
  },
  '@keyframes slideUp': {
    '0%': {
      bottom: -500,
    },
    '100%': {
      bottom: 0,
    },
  },
  paper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.white,
    opacity: '0.9',
    zIndex: 0,
    border: 'none',
  },
});

interface Props {
  open: boolean;
  onClose: () => void;
}

const AssetsSettled = ({
  open,
  onClose,
}: Props) => {
  const classes = useStyles();
  const { navBarOpen } = useNavBarContext();
  const closeTimer = useRef<any>(null);

  const handleClose = useCallback(() => {
    onClose();
    clearTimeout(closeTimer.current);
  }, [onClose]);

  useEffect(() => {
    if (open) {
      closeTimer.current = setTimeout(() => handleClose(), LLAMA_ANIMATION_AUTO_CLOSE_TIME);
    }
  }, [handleClose, open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{ border: 'none', zIndex: 99999999999 }}
      onClick={() => handleClose()}
    >
      <div
        className={classes.paper}
        style={{ left: navBarOpen ? 152 : 52 }}
      >
        {open && (
          <>
            <Box
              justifyContent="center"
              display="flex"
              flexDirection="column"
              alignItems="center"
              width="100%"
              height="100%"
              position="absolute"
            >
              <img
                alt="mediating llama"
                src="/meditatingLlama.svg"
                className={classes.meditatingLlama}
              />
            </Box>
            <Box
              justifyContent="flex-end"
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
              width="100%"
              height="100%"
              position="absolute"
              style={{ bottom: 0 }}
            >
              <img
                alt="mountain"
                src="/mountain.svg"
                className={classes.mountain}
              />
            </Box>
          </>
        )}
      </div>
    </Modal>
  );
};

export default AssetsSettled;
