import React, {
  useEffect,
  useState,
} from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  ProductCategory,
  Subledger,
} from '../../interfaces/types';
import NoData from '../NoData';
import Accounts from './Accounts';
import { initializedSubledger } from '../Subledger/common';
import ErrorPage from '../ErrorPage';
import { useRefreshDataProvider } from '../../context/dataRefreshContext';
import { accountLastFetchSelector } from '../../store/selectors/account';
import { AppThunkDispatch } from '../../store/store';
import {
  clearDashboardData,
  getBalancesByCategory,
  getSubledgersByCategory,
} from '../../store/slices/dashboard';
import {
  dashboardBalancesByCategorySelector,
  dashboardSubledgersByCategorySelector,
  fetchingDashboardError,
} from '../../store/selectors/dashboard';
import { Loader } from '../Loader';

interface Props {
  category: ProductCategory;
}

const Subledgers = ({ category }: Props) => {
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const lastFetch = useSelector(accountLastFetchSelector);
  const accountBalances = useSelector(dashboardBalancesByCategorySelector);
  const subledgers = useSelector(dashboardSubledgersByCategorySelector);
  const error = useSelector(fetchingDashboardError);

  const { refreshDate } = useRefreshDataProvider();

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      reduxDispatch(getSubledgersByCategory({ category })),
      reduxDispatch(getBalancesByCategory({ category })),
    ])
      .finally(() => setIsLoading(false));

    return () => {
      reduxDispatch(clearDashboardData());
    };
  }, [reduxDispatch, category, lastFetch, refreshDate]);

  if (error) {
    return <ErrorPage />;
  }

  const filteredSubledgers = subledgers
    ?.map((subledger: Subledger) => initializedSubledger({ subledger }));

  return (
    <>
      <Loader open={isLoading} />
      {!filteredSubledgers?.length && !isLoading && (
        <NoData />
      )}
      {filteredSubledgers?.length > 0 && (
        <Accounts
          category={category}
          subledgers={filteredSubledgers}
          accountBalances={accountBalances}
        />
      )}
    </>
  );
};

export default React.memo(Subledgers);
