import React, {
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  createStyles,
  makeStyles,
  Tab,
  Tabs,
} from '@material-ui/core';
import { SchedulerTab } from '../../../../interfaces/common';
import { V2Subledger } from '../../../../interfaces/subledgers';
import { Autosaved } from '../../../Autosaved';
import { Loader } from '../../../Loader';
import COLORS from '../../../../theme/colors';
import {
  fixedAssetsListSelectorWithoutFactaUnfinalized,
  isFetchingFixedAssets,
  isUpdatingSilentlyFixedAssets,
  updatedAtFixedAssets,
} from '../../../../store/selectors/v2Subledgers';
import { FixedAssetsSchedulerTable } from '../SchedulerTable';
import { WriteOffsTable } from '../WriteOffsTable';
import { FixedAssetsSchedulerReviewTable } from '../SchedulerReviewTable';
import { featureSwitch } from '../../../../util/featureSwitch';

const useStyles = makeStyles(() => createStyles({
  tabs: {
    borderBottom: `1px solid ${COLORS.disabled}`,
    padding: '0 32px',
  },
}));

interface Props {
  selectedDate: string;
  subledgerDetails: V2Subledger;
  isPeriodLocked?: boolean;
  isReview?: boolean;
  isCurrentPeriod: boolean;
}

export const SchedulerContainer = ({
  selectedDate,
  subledgerDetails,
  isPeriodLocked = true,
  isReview,
  isCurrentPeriod,
}: Props) => {
  const classes = useStyles();
  const [tab, setTab] = useState(SchedulerTab.SCHEDULED);
  const [page, setPage] = useState(1);

  const allFixedAssets = useSelector(fixedAssetsListSelectorWithoutFactaUnfinalized);
  const isFetching = useSelector(isFetchingFixedAssets);
  const isUpdatingSilently = useSelector(isUpdatingSilentlyFixedAssets);
  const updatedAt = useSelector(updatedAtFixedAssets);

  const unscheduledFixedAssets = allFixedAssets.filter((asset) => !asset.finalized);
  const scheduledFixedAssets = allFixedAssets.filter((asset) => asset.finalized && !asset.disposal);
  const writeOffs = allFixedAssets.filter((asset) => asset.finalized && asset.disposal);

  const handleChange = (ev: React.ChangeEvent<{}>, newValue: SchedulerTab) => {
    setTab(newValue);
    setPage(1);
  };

  useEffect(() => {
    if (unscheduledFixedAssets.length > 0) {
      setTab(SchedulerTab.UNSCHEDULED);
      setPage(1);
    }
  }, [unscheduledFixedAssets.length]);

  useEffect(() => {
    if (writeOffs.length === 0) {
      setTab(SchedulerTab.SCHEDULED);
      setPage(1);
    }
  }, [writeOffs.length]);

  const renderAssetsTable = () => (isReview
    ? (
      <FixedAssetsSchedulerReviewTable
        selectedDate={selectedDate}
        subledgerDetails={subledgerDetails}
        page={page}
        setPage={setPage}
      />
    )
    : (
      <FixedAssetsSchedulerTable
        subledgerDetails={subledgerDetails}
        selectedDate={selectedDate}
        isPeriodLocked={isPeriodLocked}
        isCurrentPeriod={isCurrentPeriod}
        page={page}
        setPage={setPage}
        tab={tab}
        setTab={setTab}
      />
    ));

  return (
    <Box
      padding={2}
      width="100%"
      display="flex"
      flexDirection="column"
      flexGrow={1}
    >
      <Loader open={isFetching} />
      <Autosaved
        isSaving={isUpdatingSilently}
        lastSaved={updatedAt}
      />
      <Tabs
        value={tab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        scrollButtons="off"
        className={classes.tabs}
      >
        {!isReview && (
          <Tab
            label={`Unscheduled (${unscheduledFixedAssets.length || 0})`}
            value={SchedulerTab.UNSCHEDULED}
            disableRipple
          />
        )}
        <Tab
          label={`${isReview ? 'Depreciation Schedule' : 'Scheduled'} (${scheduledFixedAssets.length || 0})`}
          value={SchedulerTab.SCHEDULED}
          disableRipple
        />
        {(featureSwitch.disposals && writeOffs.length > 0) && (
          <Tab
            label={`Write-offs (${writeOffs.length || 0})`}
            value={SchedulerTab.WRITEOFFS}
            disableRipple
          />
        )}
      </Tabs>
      {tab === SchedulerTab.WRITEOFFS && featureSwitch.disposals
        ? (
          <WriteOffsTable
            subledgerDetails={subledgerDetails}
            page={page}
            setPage={setPage}
            selectedDate={selectedDate}
            isCurrentPeriod={isCurrentPeriod}
          />
        )
        : renderAssetsTable()}
    </Box>
  );
};
