import styled from 'styled-components';
import { Link } from 'react-router-dom';
import COLORS from '../../theme/colors';

export const StyledLink = styled(Link)`
  color: ${COLORS.skyBlue};
  font-weight: 700;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
