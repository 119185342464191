import React, {
  useEffect,
} from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import {
  Box,
  Button,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import find from 'lodash.find';
import {
  formatISO,
  lastDayOfMonth,
  parseISO,
} from 'date-fns';
import FactaAutocomplete from '../../Inputs/FactaAutocomplete';
import Flyover from '../../Flyover';
import { FormInput } from '../../Inputs/FormInput/index';
import { AppThunkDispatch } from '../../../store/store';
import { InputLabel } from '../../InputLabel';
import { TagCategories } from '../../../interfaces/tags';
import { accountsListSelector } from '../../../store/selectors/v2Accounts';
import {
  tagsClassListSelector,
  tagsLocationListSelector,
} from '../../../store/selectors/tags';
import { FixedAsset } from '../../../interfaces/fixedAssets';
import { writeOffFixedAsset } from '../../../store/slices/v2Subledgers';
import { getAllTags } from '../../../store/slices/v2Tags';
import FactaDatePicker from '../../DatePicker';
import { ErrorAnnotate } from '../../Inputs/ErrorAnnotate';
import currencyFormatter from '../../../util/currencyFormatter';
import {
  isWritingOffFixedAsset,
  v2SubledgerSelector,
} from '../../../store/selectors/v2Subledgers';
import {
  scheduleAsOfdate,
  scheduleUnpostedSumReduce,
} from '../../../util/pages/FixedAssets/calculateSubledgerDetails';
import { precisionRound } from '../../../util/math';

const useStyles = makeStyles(() => createStyles({
  inputBox: {
    margin: '12px 8px',
    '& p + p': {
      margin: '8px 12px',
    },
  },
  buttonBox: {
    margin: '12px 2px',
  },
}));

interface Props {
  asset?: FixedAsset;
  isOpen: boolean;
  onClose: (id?: string) => void;
  selectedDate: string;
}

const WriteOffFixedAsset = ({
  asset,
  isOpen,
  onClose,
  selectedDate,
}: Props) => {
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const styles = useStyles();
  const accounts = useSelector(accountsListSelector);
  const accountClasses = useSelector(tagsClassListSelector);
  const accountLocations = useSelector(tagsLocationListSelector);
  const subledger = useSelector(v2SubledgerSelector);
  const isWritingOff = useSelector(isWritingOffFixedAsset)!;
  const fixedAssetAccount = accounts.find((acc) => acc.id === subledger?.accountId)?.displayName || '';
  const depreciationAccount = accounts.find((acc) => acc.id === asset?.depreciationAccountId)?.displayName || '';

  const priorDepreciation = asset?.priorDepreciation || 0;
  const accumulatedDepreciationAmount = precisionRound((scheduleAsOfdate(
    selectedDate,
    asset?.inServiceDate || selectedDate,
    asset?.schedule || [],
  )
    .reduce(scheduleUnpostedSumReduce, 0) || 0) + priorDepreciation);

  const yupSchema = () => yupResolver(yup.object().shape({
    disposalDate: yup.date().nullable()
      .min(new Date(asset?.inServiceDate || 0),
        'Disposal date must be later or equal start date'),
    gainOrLossAccount: yup.object({
      id: yup.string().required(),
      name: yup.string().required(),
    }).required('This field is required')
      .nullable(),
  }));

  const {
    handleSubmit,
    control,
    errors,
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: yupSchema(),
    shouldUnregister: true,
  });

  const parseDataToBeSaved = (data: any) => ({
    disposalDate: formatISO(data.disposalDate, { representation: 'date' }),
    gainOrLossAccountId: data.gainOrLossAccount.id,
    tags: [
      ...(data.class?.id ? [{
        category: TagCategories.CLASS,
        id: data.class?.id,
      }] : []),
      ...(data.location?.id ? [{
        category: TagCategories.LOCATION,
        id: data.location?.id,
      }] : []),
    ],
  });

  const handleSave = (data: any) => {
    if (!asset) return;

    const parsedData = parseDataToBeSaved(data);

    reduxDispatch(writeOffFixedAsset({
      assetId: asset.id,
      ...parsedData,
    }))
      .unwrap()
      .then(() => {
        onClose(asset.id);
      });
  };

  useEffect(() => {
    const updatedAsset = ({
      gainOrLossAccount: find(accounts, { id: asset?.gainOrLossAccountId }),
      class: find(
        accountClasses,
        { id: asset?.tags.find((tag) => tag.category === TagCategories.CLASS)?.id },
      ),
      location: find(
        accountLocations,
        { id: asset?.tags.find((tag) => tag.category === TagCategories.LOCATION)?.id },
      ),
    });

    reset(updatedAsset);
  }, [accountClasses, accountLocations, accounts, asset, reset]);

  useEffect(() => {
    reduxDispatch(getAllTags());
  }, [reduxDispatch]);

  return accounts && (
    <Flyover
      open={isOpen}
      title="Write-off asset"
      variant="drawer"
      onClose={onClose}
    >
      <Box overflow="auto">
        <form style={{ display: 'flex', flexDirection: 'column' }}>
          <Box className={styles.inputBox}>
            <InputLabel label="Asset Description" />
            <FormInput
              control={control}
              name="description"
              defaultValue={asset?.description || ''}
              error={!!errors?.description}
              disabled
            />
          </Box>
          <Box className={styles.inputBox}>
            <InputLabel label="Disposal Date" />
            <Controller
              name="disposalDate"
              control={control}
              defaultValue={lastDayOfMonth(parseISO(selectedDate))}
              render={({ onChange, value: localValue }) => (
                <FactaDatePicker
                  selectedDate={localValue}
                  onDateChange={onChange}
                  error={!!errors?.disposalDate}
                  disabled
                />
              )}
            />
            <ErrorAnnotate error={errors?.fullyDepreciatedDate} />
          </Box>
          <Box className={styles.inputBox}>
            <InputLabel label="Fixed Asset Account" />
            <p>{fixedAssetAccount}</p>
          </Box>
          <Box className={styles.inputBox}>
            <InputLabel label="Accumulated Depreciation Account" />
            <p>{depreciationAccount}</p>
          </Box>
          <Box className={styles.inputBox}>
            <InputLabel label="Accumulated Depreciation Amount" />
            <p>{currencyFormatter.format(accumulatedDepreciationAmount)}</p>
          </Box>
          <Box className={styles.inputBox}>
            <InputLabel label="Gain/loss account" />
            <FactaAutocomplete
              control={control}
              name="gainOrLossAccount"
              options={accounts}
              disableInactiveOptions
              optionName="name"
              defaultValue={null}
              error={!!errors?.gainOrLossAccount}
              disabled={isWritingOff}
              placeholder="Select Account"
            />
            <ErrorAnnotate error={errors.gainOrLossAccount} />
          </Box>
          <Box className={styles.inputBox}>
            <InputLabel label="Class" />
            <FactaAutocomplete
              control={control}
              name="class"
              options={accountClasses}
              disableInactiveOptions
              optionName="name"
              defaultValue={null}
              error={!!errors?.class}
              disabled={isWritingOff}
              placeholder="Select Class"
            />
          </Box>
          <Box className={styles.inputBox}>
            <InputLabel label="Location" />
            <FactaAutocomplete
              control={control}
              name="location"
              options={accountLocations}
              disableInactiveOptions
              optionName="name"
              defaultValue={null}
              error={!!errors?.location}
              disabled={isWritingOff}
              placeholder="Select Location"
            />
          </Box>
          <Box className={styles.buttonBox}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => onClose()}
              disabled={isWritingOff}
            >
              Cancel
              {isWritingOff && (
                <CircularProgress
                  size={20}
                  style={{ position: 'absolute' }}
                />
              )}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit((data) => handleSave(data))}
              disabled={isWritingOff}
            >
              Dispose
              {isWritingOff && (
                <CircularProgress
                  size={20}
                  style={{ position: 'absolute' }}
                />
              )}
            </Button>
          </Box>
        </form>
      </Box>
    </Flyover>
  );
};

export default React.memo(WriteOffFixedAsset);
