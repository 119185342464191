import React, { useState } from 'react';
import {
  Box,
  makeStyles,
  Button,
  IconButton,
  Collapse,
} from '@material-ui/core';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import {
  BulkEditorType,
  ProductCategory,
} from '../../interfaces/types';
import { PrepaidAssetsForm } from './PrepaidAssetsForm';
import { DeferredRevenueForm } from './DeferredRevenueForm';
import { FixedAssetsForm } from './FixedAssetsForm';
import { removeNullValuesFromObject } from '../../util/helpers';
import COLORS from '../../theme/colors';

const useStyles = makeStyles((theme) => ({
  collapseContainer: {
    width: '100%',
  },
  bulkWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    height: '112px',
    padding: '24px',
    borderTop: `1px solid ${COLORS.disabled}`,
    overflowX: 'auto',
  },
  bulkWrapperExpanded: {
    height: '184px',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  expandButton: {
    minWidth: '250px',
  },
  expandText: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
  },
  actionButtonsWrapper: {
    display: 'flex',
    flexDirection: 'row-reverse',
    minWidth: '260px',
    marginRight: '60px',
    alignItems: 'center',
  },
  actionButtonsWrapperExpanded: {
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  actionButton: {
    '& + &': {
      marginLeft: '10px',
    },
  },
}));

interface Props {
  productCategory: ProductCategory;
  onClose: () => void;
  onBulkEditApply: (internalIds: string[], changedProperties: any) => void;
  onBulkEditSchedule?: (internalIds: string[], changedProperties: any) => void;
  selectedSources: string[];
  isOpen: boolean;
  isFullyEditable: boolean;
  scheduleDate?: string;
  bulkEditorType?: BulkEditorType;
  isAllSelected?: boolean;
}

export const BulkEdit = ({
  productCategory,
  onClose,
  onBulkEditApply,
  onBulkEditSchedule,
  selectedSources,
  isOpen,
  isFullyEditable,
  scheduleDate,
  bulkEditorType,
  isAllSelected,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [submitSource, setSubmitSource] = useState<string>('');
  const classes = useStyles();

  const onApply = (properties: any) => {
    onBulkEditApply(selectedSources, properties);
    onClose();
  };

  const onSubmit = (data: any) => {
    const changedProperties = removeNullValuesFromObject(data);
    submitSource === 'apply'
      ? onApply(changedProperties)
      : onBulkEditSchedule && onBulkEditSchedule(selectedSources, changedProperties);
  };

  return (
    <Collapse
      className={classes.collapseContainer}
      in={isOpen}
    >
      <Box
        className={clsx(classes.bulkWrapper, {
          [classes.bulkWrapperExpanded]: isExpanded,
        })}
      >
        <Box className={classes.expandButton}>
          <Button
            onClick={() => setIsExpanded(!isExpanded)}
            aria-expanded={isExpanded}
            aria-label="show more"
          >
            <ExpandMoreIcon
              className={clsx(classes.expand, {
                [classes.expandOpen]: isExpanded,
              })}
            />
            <span>
              Edit multiple (
              {selectedSources.length}
              {isAllSelected && ' - All records'}
              )
            </span>
          </Button>
        </Box>
        {productCategory === ProductCategory.PrepaidExpense && (
          <PrepaidAssetsForm
            onSubmit={onSubmit}
            isOpen={isOpen}
            isFullyEditable={isFullyEditable}
            scheduleDate={scheduleDate || ''}
          />
        )}
        {productCategory === ProductCategory.DeferredRevenue && (
          <DeferredRevenueForm
            isOpen={isOpen}
            onSubmit={onSubmit}
            isFullyEditable={isFullyEditable}
            scheduleDate={scheduleDate || ''}
          />
        )}
        {productCategory === ProductCategory.FixedAsset && (
          <FixedAssetsForm
            isOpen={isOpen}
            onSubmit={onSubmit}
            isFullyEditable={isFullyEditable}
            scheduleDate={scheduleDate || ''}
          />
        )}
        <Box
          className={clsx(classes.actionButtonsWrapper, {
            [classes.actionButtonsWrapperExpanded]: isExpanded,
          })}
        >
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Box>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              form="hook-form"
              name="apply"
              onClick={() => setSubmitSource('apply')}
              className={classes.actionButton}
              size="small"
            >
              Apply
            </Button>
            {bulkEditorType !== BulkEditorType.SCHEDULED && onBulkEditSchedule && (
              <Button
                variant="contained"
                color="primary"
                type="submit"
                form="hook-form"
                name="schedule"
                onClick={() => setSubmitSource('schedule')}
                className={classes.actionButton}
                size="small"
              >
                Schedule
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Collapse>
  );
};
