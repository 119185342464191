import { makeStyles } from '@material-ui/core';
import COLORS from '../../theme/colors';

export const useReportsStyles = makeStyles(() => ({
  backIcon: {
    marginRight: '40px',
  },
  formHeader: {
    marginTop: '40px',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 600,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  formCell: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '24px',
    marginRight: '40px',
  },
  accountCell: {
    minWidth: '224px',
  },
  dateCells: {
    width: '130px',
  },
  checkboxWrapper: {
    marginTop: '24px',
    marginRight: '30px',
    '& .MuiFormControlLabel-root': {
      margin: 0,
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '12px',
      lineHeight: '14px',
      fontWeight: 500,
      marginLeft: '19px',
    },
  },
  actionHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '24px',
    padding: '13px',
    border: `1px solid ${COLORS.border}`,
  },
  reportContainer: {
    display: 'flex',
    flexGrow: 1,
    border: `1px solid ${COLORS.border}`,
    padding: '24px 34px',
    borderWidth: '0 1px 1px 1px',
    overflow: 'auto',
  },
  actionButtons: {
    margin: '0 10px',
  },
  infoIcon: {
    color: COLORS.skyBlue,
    marginRight: 8,
  },
  leftSeparator: {
    background: 'linear-gradient(90deg, #E6E6E6 0%, #FFFFFF 10px, #FFFFFF 100%)',
    paddingLeft: '16px !important',
  },
  odd: {
    background: `${COLORS.lightGray3} !important`,
  },
  schedule: {
    textAlign: 'center',
  },
  sumRowEmpty: {
    fontWeight: 600,
    color: `${COLORS.black} !important`,
    borderBottom: 'none !important',
  },
  sumRowFilled: {
    fontWeight: 600,
    color: `${COLORS.black} !important`,
    borderBottom: `solid 1px ${COLORS.disabled} !important`,
  },
}));
