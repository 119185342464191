import React from 'react';
import { AmortizationSource } from '../../interfaces/types';
import { getChildren } from './common';
import PostJERevSyncRow from './PostJEReSyncRow';

interface Props {
  amortizationSources: Array<AmortizationSource>;
  scheduleDate: string;
}

const PostJEPrepaidExpenseRows = ({
  amortizationSources,
  scheduleDate,
}: Props) => (
  <>
    {
        amortizationSources?.filter((asset: AmortizationSource) => {
          const children = getChildren(asset?.id, amortizationSources!);
          return children?.length === 0;
        })
          .map((asset: AmortizationSource, index: number) => (
            <PostJERevSyncRow
              key={asset?.internalId}
              amortizationSource={asset}
              index={index}
              amortizationSources={amortizationSources}
              scheduleDate={scheduleDate}
            />
          ))
    }
  </>
);

export default React.memo(PostJEPrepaidExpenseRows);
