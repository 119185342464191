import React from 'react';
import styled from 'styled-components';
import COLORS from '../../../theme/colors';

export const StyledTextParagraph = styled.p`
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  &[data-hidden] {
    position: absolute;
    display: flex;
    content: '';
    top: 0;
    left: 0;
    align-items: center;
    padding: 8px;
    background-color: ${COLORS.lightGrayBackground};
    width: 100%;
    min-height: 100%;
    opacity: 0;
    overflow: hidden;
    transition: opacity 200ms ease-out;

    &:hover {
      opacity: 1;
      transition: opacity 200ms ease-in 500ms;
      overflow: auto;
      z-index: 1;
    }
  }
`;

export const LineClamp = ({ text }: { text: string }) => (
  <>
    <StyledTextParagraph>{text}</StyledTextParagraph>
    <StyledTextParagraph data-hidden>{text}</StyledTextParagraph>
  </>
);
