import React, { useState } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  format,
  parseISO,
} from 'date-fns';
import RefreshIcon from '../Icons/RefreshIcon';
import {
  NotifierType,
  QuickBookError,
} from '../../interfaces/types';
import { openSnackbar } from '../Notifier';
import { useRefreshDataProvider } from '../../context/dataRefreshContext';
import DialogBox from '../DialogBox';
import { QUICKBOOK_ERRORS } from '../../util/constants';
import mapErrorMessage from '../../util/errorMessage';
import { putSubledgerRefresh } from '../../store/slices/subledger';
import { AppThunkDispatch } from '../../store/store';
import { accountInfoSelector } from '../../store/selectors/account';
import { isUpdatingSubledger } from '../../store/selectors/subledger';
import { Loader } from '../Loader';

type Props = {
  subledgerId: string;
  sourceId: string;
  dialogContent?: string;
  alertDialog?: boolean;
};

const RefreshData = ({ subledgerId, sourceId, alertDialog, dialogContent }: Props) => {
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const { setRefreshDate } = useRefreshDataProvider();

  const account = useSelector(accountInfoSelector);
  const isUpdating = useSelector(isUpdatingSubledger);

  const [openRefreshConfirmationDialog, setRefreshConfirmationDialog] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState(false);

  const onRefresh = async () => {
    if (alertDialog) {
      setRefreshConfirmationDialog(true);
    } else {
      await callRefreshService();
    }
  };

  const callRefreshService = async () => {
    reduxDispatch(putSubledgerRefresh({
      subledgerId,
      sourceId,
    }))
      .unwrap()
      .then(() => {
        setRefreshDate(new Date());
      })
      .catch((error) => {
        let snackbarMessages: QuickBookError[] = [];
        error.response?.data?.errors && error.response.data.errors.forEach((err: QuickBookError) => {
          if (QUICKBOOK_ERRORS.BOOK_CLOSE_DATES?.includes(err.code)) {
            setOpenDialog(true);
          } else {
            snackbarMessages = [err, ...snackbarMessages];
          }
        });
        if (snackbarMessages.length > 0) {
          const message = snackbarMessages.map((err) => mapErrorMessage(err)).join(', ');
          openSnackbar({ message }, NotifierType.Error);
        }
      });
  };

  const closeDialog = () => {
    setOpenDialog(false);
    setRefreshConfirmationDialog(false);
  };

  return (
    <>
      {
        account?.bookCloseDate && (
          <DialogBox
            openDialog={openDialog}
            closeDialog={closeDialog}
            dialogContext={`QuickBooks is locked as of ${format(parseISO(account?.bookCloseDate), 'LLLL dd, yyyy')}.`
            + ' \n You cannot edit a locked period. \n Please unlock QuickBooks in order to post changes.'}
            dialogTitle="Alert"
            dismissContext="Dismiss"
          />
        )
      }
      {alertDialog
      && (
        <DialogBox
          openDialog={openRefreshConfirmationDialog}
          closeDialog={closeDialog}
          dialogContext={dialogContent!}
          dialogTitle="Alert"
          dismissContext="Cancel"
          actions={[{ title: 'YES', event: callRefreshService }]}
        />
      )}
      <Loader open={isUpdating} />
      <RefreshIcon onClick={onRefresh} />
    </>
  );
};

export default RefreshData;
