import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  IconButton,
  makeStyles,
  Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import {
  compareAsc,
  format,
  parseISO,
} from 'date-fns';
import { NotifierType } from '../../interfaces/types';
import {
  lastDismissedErrorSelector,
  lastMessageSelector,
} from '../../store/selectors/notices';
import {
  getAllNotices,
  setLastDismissedError,
} from '../../store/slices/notices';
import { AppThunkDispatch } from '../../store/store';
import COLORS from '../../theme/colors';
import { Message } from '../../interfaces/notices';
import {
  DATE_DESCRIPTIVE_FORMAT,
  ERROR_NOTICES_INTERVAL,
} from '../../util/constants';

const useStyles = makeStyles({
  snackbar: {
    zIndex: 1399,
    bottom: '50px',
  },
  alert: {
    flexDirection: 'row',
    '& .MuiAlert-message': {
      display: 'block',
      color: COLORS.black,
      fontSize: '16px',
      lineHeight: '26px',
      '& span': {
        margin: '0 12px',
        color: COLORS.medGray,
        fontSize: '12px',
        lineHeight: '26px',
      },
    },
  },
  closeButton: {
    color: COLORS.error,
    padding: '6px',
  },
});

export const ErrorNotices = () => {
  const classes = useStyles();
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const lastMessage = useSelector(lastMessageSelector);
  const lastDismissedError = useSelector(lastDismissedErrorSelector);
  const [globalError, setGlobalError] = useState<Message | null>(null);
  const noticesInterval = useRef<any>(null);

  const handleClose = () => {
    reduxDispatch(setLastDismissedError(globalError?.lastUpdated));
    setGlobalError(null);
  };

  useEffect(() => {
    if (lastMessage && compareAsc(parseISO(lastMessage.lastUpdated), parseISO(lastDismissedError))) {
      setGlobalError(lastMessage);
    } else {
      setGlobalError(null);
    }
  }, [lastDismissedError, lastMessage]);

  useEffect(() => {
    reduxDispatch(getAllNotices());
    noticesInterval.current = setInterval(() => {
      reduxDispatch(getAllNotices());
    }, ERROR_NOTICES_INTERVAL);

    return () => clearInterval(noticesInterval.current);
  }, [reduxDispatch]);

  return globalError
    ? (
      <Snackbar
        className={classes.snackbar}
        open={!!globalError}
      >
        <Alert
          elevation={4}
          className={classes.alert}
          severity={NotifierType.Error}
          variant="outlined"
          icon={false}
          action={(
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className={classes.closeButton}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )}
        >
          {globalError?.message}
          <span>
            Last updated:
            {' '}
            {format(parseISO(globalError!.lastUpdated), DATE_DESCRIPTIVE_FORMAT)}
          </span>
        </Alert>
      </Snackbar>
    )
    : null;
};
