/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { request } from '../../util/request';
import { NotifierType } from '../../interfaces/types';
import { openSnackbar } from '../../components/Notifier';
import { AssetType } from '../../interfaces/fixedAssetTypes';

const PREFIX = 'assetTypes';

const GET_ASSET_TYPES = `${PREFIX}/getAssetTypes`;
const POST_ASSET_TYPE = `${PREFIX}/postAssetType`;
const POST_BULK_CREATE_ASSET_TYPE = `${PREFIX}/postBulkCreateAssetType`;
const PATCH_ASSET_TYPE = `${PREFIX}/patchAssetType`;

interface State {
  isFetching: boolean;
  isSaving: boolean;
  assetTypes: Array<AssetType>;
  error?: any;
}

const initialState: State = {
  isSaving: false,
  isFetching: false,
  assetTypes: [],
};

interface GetAssetTypesResponse {
  fixedAssetTypes: Array<AssetType>;
}

export const getAssetTypes = createAsyncThunk<GetAssetTypesResponse>(
  GET_ASSET_TYPES,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request().get<GetAssetTypesResponse>('/v2/fixed_asset_types');

      return data;
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const postAssetType = createAsyncThunk<AssetType, {
  assetType: AssetType;
}>(
  POST_ASSET_TYPE,
  async ({ assetType }, { rejectWithValue }) => {
    try {
      delete assetType.class;
      delete assetType.location;
      const { data } = await request().post<AssetType>('/v2/fixed_asset_types', assetType);

      return data;
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const patchAssetType = createAsyncThunk<AssetType, {
  assetType: Partial<AssetType>;
}>(
  PATCH_ASSET_TYPE,
  async ({ assetType }, { rejectWithValue }) => {
    try {
      delete assetType.class;
      delete assetType.location;
      const { data } = await request().patch<AssetType>(`/v2/fixed_asset_types/${assetType.id}`, assetType);

      return data;
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const postBulkCreateAssetType = createAsyncThunk<GetAssetTypesResponse, {
  assetTypes: Array<Partial<AssetType>>;
}>(
  POST_BULK_CREATE_ASSET_TYPE,
  async ({ assetTypes }, { rejectWithValue }) => {
    try {
      const { data } = await request().post<GetAssetTypesResponse>('/v2/fixed_asset_types/bulk', assetTypes);

      return data;
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const assetTypesSlice = createSlice({
  initialState,
  name: PREFIX,
  reducers: {
    clearAssetTypes: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAssetTypes.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getAssetTypes.fulfilled, (state, { payload }) => {
      state.assetTypes = payload.fixedAssetTypes;
      state.isFetching = false;
    });
    builder.addCase(getAssetTypes.rejected, (state) => {
      state.isFetching = false;
    });

    builder.addCase(patchAssetType.pending, (state) => {
      state.isSaving = true;
    });
    builder.addCase(patchAssetType.fulfilled, (state, { payload }) => {
      state.assetTypes = state.assetTypes?.map((assetType) => {
        if (assetType.id === payload.id) {
          return {
            ...assetType,
            ...payload,
          };
        }
        return { ...assetType };
      });
      state.isSaving = false;
    });
    builder.addCase(patchAssetType.rejected, (state) => {
      state.isSaving = false;
    });

    builder.addCase(postAssetType.pending, (state) => {
      state.isSaving = true;
    });
    builder.addCase(postAssetType.fulfilled, (state, { payload }) => {
      state.assetTypes = [
        ...state.assetTypes!,
        payload,
      ];
      state.isSaving = false;
    });
    builder.addCase(postAssetType.rejected, (state) => {
      state.isSaving = false;
    });

    builder.addCase(postBulkCreateAssetType.pending, (state) => {
      state.isSaving = true;
    });
    builder.addCase(postBulkCreateAssetType.fulfilled, (state, { payload }) => {
      state.assetTypes = [
        ...state.assetTypes!,
        ...payload.fixedAssetTypes,
      ];
      state.isSaving = false;
    });

    builder.addCase(postBulkCreateAssetType.rejected, (state) => {
      state.isSaving = false;
    });
  },
});

export const {
  clearAssetTypes,
} = assetTypesSlice.actions;

export const assetTypesReducer = assetTypesSlice.reducer;
