import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

export const SchedulerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  height: '100%',
  marginTop: '10px',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export const SchedulerBoxItem = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
});
