import React, {
  useEffect,
  useState,
} from 'react';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FixedAssetsCreateEditTable } from '../../../components/Subledger/FixedAssets/CreateEditTable';
import { AssetDetailsForm } from '../../../components/Subledger/FixedAssets/AssetDetailsForm';
import { AppThunkDispatch } from '../../../store/store';
import {
  clearAllV2Subledgers,
  getV2SubledgerDetails,
  postV2FinalizeSubledger,
} from '../../../store/slices/v2Subledgers';
import GLBalancesInfo from '../../../components/Subledger/FixedAssets/GLBalancesInfo';
import { v2SubledgerSelector } from '../../../store/selectors/v2Subledgers';
import {
  getAccountBalance,
  getAllAccounts,
} from '../../../store/slices/v2Accounts';
import { getAssetTypes } from '../../../store/slices/assetTypes';
import { getAllContacts } from '../../../store/slices/v2Contacts';
import { BottomActions } from '../../../components/Subledger/FixedAssets/BottomActions';
import { Loader } from '../../../components/Loader';
import { accountGLBalanceSelector } from '../../../store/selectors/v2Accounts';
import { calculateSubledgerDetails } from '../../../util/pages/FixedAssets/calculateSubledgerDetails';
import { precisionRound } from '../../../util/math';
import { lastV2SyncSelector } from '../../../store/selectors/v2Sync';

export const CreateSubledger = () => {
  const { subledgerId } = useParams<{ subledgerId: string }>();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isEditingTable, setIsEditingTable] = useState(false);
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const lastFetch = useSelector(lastV2SyncSelector);
  const accountGLBalance = useSelector(accountGLBalanceSelector);
  const subledgerDetails = useSelector(v2SubledgerSelector);

  const {
    currentFactaBalance: sumOfAllAssets,
    allAssetsValid,
  } = calculateSubledgerDetails({ fixedAssets: subledgerDetails?.fixedAssets?.filter((asset) => !asset.transaction) });

  const isFinalizeDisabled = (precisionRound(accountGLBalance - sumOfAllAssets) !== 0)
    || isFormDirty
    || !allAssetsValid
    || isEditingTable;

  const handleFinalize = () => {
    setIsLoading(true);
    reduxDispatch(postV2FinalizeSubledger({ subledgerId }))
      .unwrap()
      .then(() => history.push('/fixed-assets'))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      reduxDispatch(getV2SubledgerDetails({ subledgerId })),
      reduxDispatch(getAllAccounts({})),
      reduxDispatch(getAllContacts()),
      reduxDispatch(getAssetTypes()),
    ])
      .finally(() => setIsLoading(false));

    return () => {
      reduxDispatch(clearAllV2Subledgers());
    };
  }, [subledgerId, reduxDispatch, lastFetch]);

  useEffect(() => {
    if (subledgerDetails) {
      reduxDispatch(getAccountBalance({
        accountId: subledgerDetails.accountId,
        date: subledgerDetails.startDate,
      }));
    }
  }, [subledgerDetails, reduxDispatch]);

  return (
    <>
      <Loader open={isLoading} />
      {subledgerDetails && (
        <>
          <Box
            padding={4}
            paddingBottom={0}
          >
            <AssetDetailsForm
              subledgerDetails={subledgerDetails}
              setIsFormDirty={setIsFormDirty}
            />
            <GLBalancesInfo
              subledgerDetails={subledgerDetails}
              showIngestedTransactions={false}
            />
          </Box>
          <FixedAssetsCreateEditTable
            subledgerId={subledgerId}
            subledgerDetails={subledgerDetails}
            disabled={isFormDirty}
            setIsEditingTable={setIsEditingTable}
          />
          <BottomActions
            menuItems={[
              {
                label: 'Finalize Subledger',
                action: handleFinalize,
                disabled: isFinalizeDisabled,
              },
            ]}
          />
        </>
      )}
      {(!isLoading && !subledgerDetails) && (
        <Box padding={4}>
          <Alert severity="error">No subledger with given ID.</Alert>
        </Box>
      )}
    </>
  );
};
