import fileDownload from 'js-file-download';
import {
  eachMonthOfInterval,
  parseISO,
} from 'date-fns';
import {
  ProductCategory,
  LegacySchedulingMethod,
  SubledgerAmortizationLog,
  SubledgerColumns,
} from '../interfaces/types';
import {
  AssetTypeColumnName,
  FixedAssetsColumn,
} from '../interfaces/fixedAssets';
import { V2Subledger } from '../interfaces/subledgers';
import { SchedulingMethod } from '../interfaces/common';

export const capitalizeFirstLetter = (
  variable: string,
) => {
  if (variable) {
    return variable.charAt(0).toUpperCase() + variable.slice(1).toLowerCase();
  }

  return '';
};

export const generateColumnNames = (category: ProductCategory, isClassEnabled?: boolean) => {
  switch (category) {
    case ProductCategory.PrepaidExpense:
      return [
        SubledgerColumns.DESCRIPTION,
        SubledgerColumns.VENDOR,
        SubledgerColumns.EXPENSES_ACCOUNT,
        ...(isClassEnabled ? [SubledgerColumns.CLASS] : []),
        SubledgerColumns.AMORTIZATION_START_DATE,
        SubledgerColumns.AMORTIZATION_END_DATE,
        SubledgerColumns.AMORT_SCHEDULE,
        SubledgerColumns.FACTA_STARTING_BALANCE,
      ];
    case ProductCategory.DeferredRevenue:
      return [
        SubledgerColumns.DESCRIPTION,
        SubledgerColumns.CUSTOMER,
        SubledgerColumns.PRODUCT,
        SubledgerColumns.REVENUE_ACCOUNT,
        ...(isClassEnabled ? [SubledgerColumns.CLASS] : []),
        SubledgerColumns.RECOGNITION_START_DATE,
        SubledgerColumns.RECOGNITION_END_DATE,
        SubledgerColumns.RECOG_SCHEDULE,
        SubledgerColumns.FACTA_STARTING_BALANCE,
      ];
    default:
      return [];
  }
};

export const generateFixedAssetColumnNames = () => ([
  FixedAssetsColumn.NUMBER,
  FixedAssetsColumn.DESCRIPTION,
  FixedAssetsColumn.VENDOR,
  FixedAssetsColumn.ASSET_TYPE,
  FixedAssetsColumn.ORIGINAL_AMOUNT,
  FixedAssetsColumn.ACCUMULATED_DEPRECIATION,
  FixedAssetsColumn.FACTA_DEPRECIATION_START_DATE,
  FixedAssetsColumn.FACTA_DEPRECIATION_END_DATE,
  FixedAssetsColumn.SCHEDULE,
]);

export const generateAsseTypeColumnNames = () => ([
  AssetTypeColumnName.ASSET_TYPE_NAME,
  AssetTypeColumnName.ASSET_TYPE_DESCRIPTION,
  AssetTypeColumnName.DEPRECIATION_ACCOUNT,
  AssetTypeColumnName.GAIN_LOSS_ACCOUNT,
  AssetTypeColumnName.CLASS,
  AssetTypeColumnName.LOCATION,
  AssetTypeColumnName.USEFUL_LIFE,
]);

export const downloadTemplateFile = <T>(columns: Array<T>, filename: string) => {
  fileDownload(columns.join(','), filename);
};

export const legacySchedulingMethodOptions = [
  {
    value: LegacySchedulingMethod.DAILY,
    label: 'Daily',
  },
  {
    value: LegacySchedulingMethod.MONTHLY,
    label: 'Monthly',
  },
  {
    value: LegacySchedulingMethod.MANUAL,
    label: 'Manual',
  },
];

export const schedulingMethodOptions = [
  {
    value: SchedulingMethod.DAILY,
    label: 'Daily',
  },
  {
    value: SchedulingMethod.PERIODIC,
    label: 'Monthly',
  },
  {
    value: SchedulingMethod.MANUAL,
    label: 'Manual',
  },
];

export const getSelectedLegacySchedulingMethodOption = (
  schedulingMethodOption: LegacySchedulingMethod,
) => legacySchedulingMethodOptions.find((option) => option.value === schedulingMethodOption);

export const removeNullValuesFromObject = <T extends object>(obj: T): T => Object
  .entries(obj).reduce((acc, [key, value]) => {
    if ((!value || value?.length === 0) && typeof value !== 'boolean') {
      return acc;
    }

    acc[key] = value;

    return acc;
  }, {} as any);

export const saveFile = async (fileContent: string, filename: string, contentType: string) => {
  const a = document.createElement('a');
  const blob = new Blob([fileContent], { type: contentType });
  const url = window.URL.createObjectURL(blob);

  document.body.appendChild(a);
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const getFileNameFromContentDisposition = (contentDisposition: string) => {
  if (!contentDisposition) return '';
  return contentDisposition.split('"')[1];
};

export const relevantDateInfo = (amortizationLogs: Array<SubledgerAmortizationLog>) => {
  if (amortizationLogs?.length) {
    const availableDates = amortizationLogs.map(({ postingDate }) => postingDate && postingDate);
    const startDate = availableDates.sort((a, b) => new Date(a!).valueOf() - new Date(b!).valueOf())[0];
    const endDate = availableDates.sort((a, b) => new Date(b!).valueOf() - new Date(a!).valueOf())[0];
    const datesInterval = eachMonthOfInterval({ start: new Date(startDate!), end: new Date(endDate!) });

    return {
      datesInterval,
      startDate,
      endDate,
    };
  }

  return {
    datesInterval: [],
    startDate: undefined,
    endDate: undefined,
  };
};

export const fixedAssetsSubledgersStartDate = (subledgers: Array<V2Subledger>) => {
  if (subledgers.length > 0) {
    const availableDates = subledgers.map(({ startDate }) => startDate);
    return parseISO(availableDates.sort((a, b) => parseISO(a!).valueOf() - parseISO(b!).valueOf())[0]);
  }

  return new Date();
};

export const generateQBOLink = (
  transactionType: string,
  transactionId: string,
): string => {
  const cleanTransactionId = transactionId.split(':').shift();

  return `${process.env.REACT_APP_QUICKBOOK_APP_URL}app/${transactionType}?txnId=${cleanTransactionId}`;
};

export const sessionEnabledFeatures = () => localStorage.getItem('features');

export const enableFeature = (feature: string) => {
  if (sessionEnabledFeatures()) {
    const updatedFeatures = new Set([...JSON.parse(sessionEnabledFeatures()!), feature]);

    localStorage.setItem('features', JSON.stringify(Array.from(updatedFeatures)));
  }

  if (!sessionEnabledFeatures()) {
    localStorage.setItem('features', JSON.stringify([feature]));
  }
};

export const disableFeature = (feature: string) => {
  if (sessionEnabledFeatures()) {
    const features: Array<string> = [...JSON.parse(sessionEnabledFeatures()!)].filter((id) => id !== feature);
    features.length === 0
      ? localStorage.removeItem('features')
      : localStorage.setItem('features', JSON.stringify(features));
  }
};
