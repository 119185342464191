import React from 'react';
import Box from '@material-ui/core/Box';
import {
  format,
  lastDayOfMonth,
  parse,
} from 'date-fns';
import {
  ButtonBase,
  createStyles,
  Typography,
} from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import clsx from 'clsx';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MonthPicker from '../MonthPicker';
import {
  DAY_SHORT_FORMAT,
  MONTH_SHORT_FORMAT,
} from '../../util/constants';
import COLORS from '../../theme/colors';

const useStyles = makeStyles(() => createStyles({
  fontSize13: {
    fontSize: 13,
  },
  displayFlex: {
    display: 'flex',
  },
  dropDownIcon: {
    marginTop: -1,
  },
  subledgerNavigator: {
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    borderTopLeftRadius: 0,
    color: COLORS.white,
    padding: '3px 10px',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    marginTop: 0,
    '& p': {
      fontSize: 13,
      '& span': {
        fontWeight: 'bold',
        marginLeft: 5,
      },
    },
  },
}));

interface Props {
  onDateChange: (dateItem: Date) => void;
  periodContext: string;
  scheduleDate: string;
  subledgerInterval: Array<Date>;
  color?: string;
}

const CalendarNavigation = ({ onDateChange, periodContext, scheduleDate, subledgerInterval, color }: Props) => {
  const classes = useStyles();

  const handleMonthChange = (navigationDate: Date | null) => {
    if (navigationDate) {
      onDateChange(lastDayOfMonth(navigationDate));
    }
  };

  return (
    <Box
      className={classes.subledgerNavigator}
      style={{ background: color }}
    >
      <MonthPicker
        selectedDate={parse(scheduleDate, DAY_SHORT_FORMAT, new Date())}
        onDateChange={(date) => handleMonthChange(date)}
        includeDates={subledgerInterval}
        customInput={(
          <ButtonBase>
            <Typography
              className={clsx(classes.fontSize13, classes.displayFlex)}
            >
              {periodContext}
              <span>{format(parse(scheduleDate, DAY_SHORT_FORMAT, new Date()), MONTH_SHORT_FORMAT)}</span>
              <ArrowDropDownIcon className={classes.dropDownIcon} />
            </Typography>
          </ButtonBase>
        )}
      />
    </Box>
  );
};

export default React.memo(CalendarNavigation);
