import {
  endOfMonth,
  format,
  isBefore,
  subMonths,
} from 'date-fns';
import { getFactaSourceBalance } from '../components/Subledger/common';
import getSubledgerStartEndDate from './getSubledgerStartEndDate';
import {
  Account,
  OutOfPeriodData,
  Subledger,
} from '../interfaces/types';
import {
  ASSET_TEMP_SCHEDULED_STATUS,
  DAY_SHORT_FORMAT,
} from './constants';

export const getOutOfPeriodInfoForSubledger = (subledger: Subledger, accountBalances: Account[]): OutOfPeriodData => {
  const selectedAccount = accountBalances?.find((account) => account.id === subledger?.accountId);
  const { endDate } = getSubledgerStartEndDate(subledger);
  let outOfPeriodNavLink;
  let outOfPeriodDate = null;

  if (getFactaSourceBalance(subledger?.amortizationSources) !== selectedAccount?.glBalance) {
    return {
      outOfPeriod: true,
      outOfPeriodDate: null,
      outOfPeriodMessage: 'Out-of-period transaction prior to Facta start date',
      outOfPeriodTransactions: 0,
      outOfPeriodNavLink: `/historical/subledgers/scheduler/${subledger.id}`,
    };
  }

  const outOfScopeAsset = subledger
    ?.amortizationSources
    ?.find((asset) => ((!asset.status || asset?.status === ASSET_TEMP_SCHEDULED_STATUS)
    && isBefore(asset.sourceCreationDate!, subMonths(endDate, 1))
    && !asset?.parentId));

  const outOfPeriodTransactions = subledger
    ?.amortizationSources
    ?.filter((asset) => (!asset.status || asset?.status === ASSET_TEMP_SCHEDULED_STATUS)
      && isBefore(asset.sourceCreationDate!, subMonths(endDate, 1))
      && !asset?.parentId)
    ?.length || 0;

  if (outOfScopeAsset) {
    outOfPeriodDate = outOfScopeAsset?.sourceCreationDate!;
    const formatteDdate = format(endOfMonth(outOfPeriodDate), DAY_SHORT_FORMAT);
    const historicalNavigation = `/historical/subledgers/schedule/${subledger.id}`;
    if (!outOfScopeAsset.status) {
      outOfPeriodNavLink = `${historicalNavigation}?scheduleDate=${formatteDdate}`;
    } else {
      outOfPeriodNavLink = `${historicalNavigation}/post-je?scheduleDate=${formatteDdate}&fromDashboard=true`;
    }
  }

  if (outOfPeriodTransactions > 0) {
    return {
      outOfPeriod: true,
      outOfPeriodDate,
      outOfPeriodMessage: `Out-of-period transaction${outOfPeriodTransactions > 1 ? 's' : ''}`,
      outOfPeriodTransactions,
      outOfPeriodNavLink,
    };
  }

  return {
    outOfPeriod: false,
    outOfPeriodDate: null,
    outOfPeriodMessage: null,
    outOfPeriodTransactions: 0,
  };
};
