import React, {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Popper,
  Paper,
  Box,
  ClickAwayListener,
} from '@material-ui/core';
import clsx from 'clsx';
import { useAutocomplete } from '@material-ui/lab';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CheckIcon from '@material-ui/icons/Check';
import { POPPER_ELEMENTS_Z_INDEX } from '../../../../util/constants';
import { useStyles } from './index.styled';
import { StyledInput } from '../../../Inputs/index.styled';
import { commonFiltersStyles } from '../common/index.styled';

interface IMultipickerFilter {
  _key: React.Key;
  label: string;
  value?: Array<any>;
  options: Array<any>;
  onChange: (filter?: Array<string>) => void;
}

export const MultipickerFilter = ({
  _key,
  label,
  value: selectedValues,
  options,
  onChange,
}: IMultipickerFilter) => {
  const [open, setOpen] = useState(false);
  const [innerValue, setInnerValue] = useState<Array<any> | undefined>(selectedValues || []);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [autocompleteOpen, setAutocompleteOpen] = React.useState(false);
  const styles = useStyles();
  const commonStyles = commonFiltersStyles();

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
    setAnchorEl: setAutocompleteAnchorEl,
  } = useAutocomplete({
    id: 'customized-hook-demo',
    value: innerValue,
    multiple: true,
    open: autocompleteOpen,
    options,
    disableCloseOnSelect: true,
    getOptionLabel: (option: any) => option.displayName || option.className || option.name || option.label,
    onChange: (_, value) => setInnerValue(value),
  });

  const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
    setOpen(!open);
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const applyFilter = () => {
    onChange(innerValue?.length ? innerValue : undefined);
    setOpen(false);
    setAnchorEl(null);
  };

  const clearFilter = () => {
    setOpen(false);
    onChange(undefined);
    setAnchorEl(null);
  };

  const handleCancel = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.key === 'Escape') {
      clearFilter();
    }
  };

  useEffect(() => {
    setInnerValue([]);
  }, [_key]);

  useEffect(() => {
    setInnerValue(selectedValues || []);
  }, [selectedValues]);

  return (
    <>
      <Button
        type="button"
        onClick={handleOpen}
        fullWidth
        variant="outlined"
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        className={clsx(commonStyles.filterButton, {
          [commonStyles.selected]: !!selectedValues?.length,
        })}
      >
        {label}
        {selectedValues && selectedValues?.length > 0 && (
          <span className={commonStyles.filterIndicator}>
            (
            <span>{selectedValues.length}</span>
            )
          </span>
        )}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="top-start"
        style={{
          zIndex: POPPER_ELEMENTS_Z_INDEX,
          minWidth: '200px',
        }}
        transition
      >
        <ClickAwayListener onClickAway={handleCancel}>
          <Paper
            className={commonStyles.paper}
            elevation={3}
          >
            <form
              noValidate
              autoComplete="off"
            >
              <div {...getRootProps()}>
                <div
                  ref={setAutocompleteAnchorEl}
                  className={focused ? 'focused' : ''}
                >
                  <StyledInput
                    {...getInputProps()}
                    className={commonStyles.searchField}
                    fullWidth
                    autoFocus
                    onFocus={() => setAutocompleteOpen(true)}
                    onKeyDown={onKeyDown}
                    placeholder="Search"
                  />
                </div>
                <ul
                  {...getListboxProps()}
                  className={styles.listBox}
                >
                  {groupedOptions.map((option, index) => (
                    <li
                      className={selectedValues?.includes(option) ? 'selected' : ''}
                      key={option.displayName || option.className}
                      {...getOptionProps({ option, index })}
                    >
                      <span>{option.displayName || option.className || option.name || option.label}</span>
                      <CheckIcon fontSize="small" />
                    </li>
                  ))}
                </ul>
              </div>
              <Box className={commonStyles.buttonBox}>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={clearFilter}
                  size="small"
                >
                  Clear
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  onClick={applyFilter}
                  fullWidth
                  variant="contained"
                  size="small"
                  className={commonStyles.applyButton}
                >
                  Apply
                </Button>
              </Box>
            </form>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
