/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import {
  compareAsc,
  parseISO,
} from 'date-fns';
import { request } from '../../util/request';
import { openSnackbar } from '../../components/Notifier';
import mapErrorMessage from '../../util/errorMessage';
import { NotifierType } from '../../interfaces/types';
import { Message } from '../../interfaces/notices';

const PREFIX = 'notices';
const GET_NOTICES = `${PREFIX}/getAllNotices`;

interface State {
  isFetchingNotices: boolean;
  messages: Array<Message>;
  lastMessage: Message | null;
  lastDismissedError: string;
}

const initialState: State = {
  isFetchingNotices: false,
  messages: [],
  lastMessage: null,
  lastDismissedError: '1991-01-01T01:01:01Z',
};

export const getAllNotices = createAsyncThunk<{ messages: Array<Message> }>(
  GET_NOTICES,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request().get<{ messages: Array<Message> }>('/v2/notices');

      return data;
    } catch (error: any) {
      const handledError = mapErrorMessage(error.response?.data || error.message);
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const noticesSlice = createSlice({
  initialState,
  name: PREFIX,
  reducers: {
    clearNotices: () => initialState,
    setLastDismissedError: (state, { payload }) => {
      state.lastDismissedError = payload;
    },
    clearLastDismissedError: (state) => {
      state.lastDismissedError = initialState.lastDismissedError;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllNotices.pending, (state) => {
      state.isFetchingNotices = true;
    });
    builder.addCase(getAllNotices.fulfilled, (state, { payload }) => {
      const messages = payload.messages.sort((a, b) => compareAsc(parseISO(b.lastUpdated), parseISO(a.lastUpdated)));
      const lastMessage = messages.length > 0 ? messages[0] : null;

      state.isFetchingNotices = false;
      state.messages = messages;
      state.lastMessage = lastMessage;
    });
    builder.addCase(getAllNotices.rejected, (state) => {
      state.isFetchingNotices = false;
    });
  },
});

export const {
  clearNotices,
  setLastDismissedError,
  clearLastDismissedError,
} = noticesSlice.actions;

export const noticesReducer = noticesSlice.reducer;
