import React, {
  useMemo,
  useState,
} from 'react';
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import find from 'lodash.find';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Papa from 'papaparse';
import fileDownload from 'js-file-download';
import Flyover from '../../../Flyover';
import {
  ITableItemType,
  ITableType,
  NotifierType,
  SortOrder,
} from '../../../../interfaces/types';
import { openSnackbar } from '../../../Notifier';
import { DeleteIcon } from '../../../Icons';
import COLORS from '../../../../theme/colors';
import {
  downloadTemplateFile,
  generateAsseTypeColumnNames,
} from '../../../../util/helpers';
import { AssetTypeColumnName } from '../../../../interfaces/fixedAssets';
import { assetTypesSelector } from '../../../../store/selectors/assetTypes';
import { AppThunkDispatch } from '../../../../store/store';
import { accountsIncomeListSelector } from '../../../../store/selectors/v2Accounts';
import {
  tagsClassListSelector,
  tagsLocationListSelector,
} from '../../../../store/selectors/tags';
import { importWizardStyles } from '../index.styled';
import { FormInput } from '../../../Inputs/FormInput';
import { StyledTableCell } from '../../../Table/StyledTableCell';
import TableComponent from '../../../Table';
import FactaAutocomplete from '../../../Inputs/FactaAutocomplete';
import { NumberInput } from '../../../Inputs/NumberInput';
import { IdleTableCell } from '../../../Table/IdleTableCell';
import {
  AssetType,
  UsefulLifeUnits,
} from '../../../../interfaces/fixedAssetTypes';
import { TagCategories } from '../../../../interfaces/tags';
import { postBulkCreateAssetType } from '../../../../store/slices/assetTypes';
import { UploadActions } from '../UploadActions';
import { ImportTab } from '../../../../interfaces/common';

interface Props {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AssetTypesImportWizard = ({
  isOpen,
  setOpen,
}: Props) => {
  const [importedData, setImportedData] = useState<Array<any>>([]);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [selectedAssetType, setSelectedAssetType] = useState<string>('');
  const [tab, setTab] = useState(ImportTab.ALL);
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const classes = importWizardStyles();

  const assetTypes = useSelector(assetTypesSelector);
  const incomeAccounts = useSelector(accountsIncomeListSelector);
  const accountClasses = useSelector(tagsClassListSelector);
  const accountLocations = useSelector(tagsLocationListSelector);
  const columnNames = generateAsseTypeColumnNames();
  const assetTypesNames = assetTypes.map((at) => at.name);
  const isImportedDataValid = !importedData.find((item) => Object.keys(item.errors).length);

  const handleClose = () => {
    setOpen(() => false);
    setUploadedFile(null);
    setImportedData([]);
  };

  const duplicatedAssetNames = (assets: Array<any>) => {
    const namesArray = assets.map((item: any) => item.name);

    return namesArray
      .filter((name: string, index: number) => namesArray.indexOf(name) !== index);
  };

  const assignErrorOnDuplicates = (assets: Array<any>) => {
    if (duplicatedAssetNames(assets).length > 0) {
      const validatedAssets = assets.map((asset: any) => {
        if (duplicatedAssetNames(assets).includes(asset.name)) {
          const updatedErrors = Object.assign(asset.errors, { name: true });

          return { ...asset, updatedErrors };
        }

        return asset;
      });

      return validatedAssets;
    }

    return assets;
  };

  const validateAssetType = (asset: any) => {
    const errors = {};

    if (!asset.name || assetTypesNames.includes(asset.name || '')) {
      Object.assign(errors, { name: true });
    }

    if (importedData && duplicatedAssetNames(importedData).includes(asset.name)) {
      Object.assign(errors, { name: true });
    }

    if (asset.depreciationAccount?.value || !asset.depreciationAccount) {
      Object.assign(errors, { depreciationAccount: true });
    }

    if (asset.gainOrLossAccount?.value || !asset.gainOrLossAccount) {
      Object.assign(errors, { gainOrLossAccount: true });
    }

    if (!asset.usefulLifeValue) {
      Object.assign(errors, { usefulLifeValue: true });
    }

    if (asset.class?.value) {
      Object.assign(errors, { class: true });
    }

    if (asset.location?.value) {
      Object.assign(errors, { location: true });
    }

    return { ...asset, errors };
  };

  const yupSchema = () => yupResolver(yup.object().shape({
    name: yup.string().required('This field is required')
      .test(
        'isUnique',
        'Asset Type name must be unique',
        (val) => !([
          ...assetTypesNames,
          ...importedData.filter((asset) => asset.id !== selectedAssetType).map((at) => at.name),
        ].includes(val || '')),
      ),
    description: yup.string(),
    depreciationAccount: yup.object({
      id: yup.string().required(),
      name: yup.string().required(),
    })
      .nullable()
      .required('This field is required'),
    gainOrLossAccount: yup.object({
      id: yup.string().required(),
      name: yup.string().required(),
    })
      .nullable()
      .required('This field is required'),
    usefulLifeValue: yup.number().required(),
  }));

  const {
    control,
    errors,
    getValues,
    reset,
    trigger,
  } = useForm({
    mode: 'onChange',
    resolver: yupSchema(),
    shouldUnregister: false,
  });

  const onFileUploaded = (data: any, fileInfo: any) => {
    if (!data.length) {
      openSnackbar({ message: 'There is no data in this CSV file, please select different one' }, NotifierType.Error);
      return;
    }

    if (Object.entries(data[0]).length !== columnNames.length) {
      openSnackbar(
        { message: 'Missing columns. Please be sure to use the provided CSV import template' },
        NotifierType.Error,
      );
      return;
    }

    if (JSON.stringify(Object.keys(data[0])) !== JSON.stringify(columnNames)) {
      openSnackbar(
        { message: 'Missing or incorrect column names. Please be sure to use the provided CSV import template' },
        NotifierType.Error,
      );
      return;
    }

    const importedAssetTypes = data?.map((item: any) => {
      const assetType: any = {
        id: uuidv4(),
        name: item[AssetTypeColumnName.ASSET_TYPE_NAME] ?? '',
        description: item[AssetTypeColumnName.ASSET_TYPE_DESCRIPTION] ?? '',
        depreciationAccount: null,
        gainOrLossAccount: null,
        usefulLifeValue: 0,
        class: null,
        location: null,
      };

      if (item[AssetTypeColumnName.DEPRECIATION_ACCOUNT]) {
        assetType.depreciationAccount = find(
          incomeAccounts, { name: item[AssetTypeColumnName.DEPRECIATION_ACCOUNT] },
        ) || {
          value: item[AssetTypeColumnName.DEPRECIATION_ACCOUNT],
        };
      }

      if (item[AssetTypeColumnName.GAIN_LOSS_ACCOUNT]) {
        assetType.gainOrLossAccount = find(
          incomeAccounts, { name: item[AssetTypeColumnName.GAIN_LOSS_ACCOUNT] },
        ) || {
          value: item[AssetTypeColumnName.GAIN_LOSS_ACCOUNT],
        };
      }

      if (item[AssetTypeColumnName.USEFUL_LIFE]) {
        assetType.usefulLifeValue = Number(item[AssetTypeColumnName.USEFUL_LIFE]
          .replace(/[a-z\s,$]/ig, ''));
      }

      if (item[AssetTypeColumnName.CLASS]) {
        assetType.class = find(
          accountClasses, { name: item[AssetTypeColumnName.CLASS] },
        ) || {
          value: item[AssetTypeColumnName.CLASS],
        };
      }

      if (item[AssetTypeColumnName.LOCATION]) {
        assetType.location = find(
          accountLocations, { name: item[AssetTypeColumnName.LOCATION] },
        ) || {
          value: item[AssetTypeColumnName.LOCATION],
        };
      }

      return (validateAssetType(assetType));
    });

    setUploadedFile(fileInfo);

    setImportedData(assignErrorOnDuplicates(importedAssetTypes));

    if (importedAssetTypes.filter((assetType: any) => Object.keys(assetType.errors).length).length) {
      setTab(ImportTab.ERRORS);
    }
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: ImportTab) => {
    setTab(newValue);
  };

  const handleTemplateDownload = () => {
    downloadTemplateFile(columnNames, 'Facta_asset_type_template.csv');
  };

  const handleDownloadFixedData = () => {
    const fixedData = importedData.map((assetType) => ({
      name: assetType.name,
      description: assetType.description,
      depreciationAccount: assetType.depreciationAccount.name,
      gainOrLossAccount: assetType.gainOrLossAccount.name,
      usefulLifeValue: assetType.usefulLifeValue,
      class: assetType.class?.name,
      location: assetType.location?.name,
    }));

    const csvData = Papa.unparse({
      data: fixedData,
      fields: Object.keys(fixedData[0]),
    }, {
      header: false,
    });
    const downloadData = `${[...columnNames].join(',')}\r\n${csvData}`;
    fileDownload(downloadData, 'updatedData.csv');
  };

  const assetTypesColumnInfo = [
    {
      columnName: AssetTypeColumnName.ASSET_TYPE_NAME,
      fieldName: 'name',
      messages: ['Invalid Asset Type Name', 'Asset Type Name must be unique'],
    },
    {
      columnName: AssetTypeColumnName.ASSET_TYPE_DESCRIPTION,
      fieldName: 'description',
      messages: [],
    },
    {
      columnName: AssetTypeColumnName.DEPRECIATION_ACCOUNT,
      fieldName: 'depreciationAccount',
      messages: ['Invalid Depreciation Account'],
    },
    {
      columnName: AssetTypeColumnName.GAIN_LOSS_ACCOUNT,
      fieldName: 'gainOrLossAccount',
      messages: ['Invalid Gain/Loss Account'],
    },
    {
      columnName: AssetTypeColumnName.USEFUL_LIFE,
      fieldName: 'usefulLifeValue',
      messages: ['Invalid Useful Life'],
    },
    {
      columnName: AssetTypeColumnName.LOCATION,
      fieldName: 'location',
      messages: ['Invalid Location'],
    },
    {
      columnName: AssetTypeColumnName.CLASS,
      fieldName: 'class',
      messages: ['Invalid Class'],
    },
  ];

  const checkColumnErrors = (colName: AssetTypeColumnName) => {
    const searchedItem = assetTypesColumnInfo.find((item) => item.columnName === colName);

    if (searchedItem) {
      return importedData.filter((asset: any) => Object
        .prototype.hasOwnProperty.call(asset.errors, searchedItem.fieldName)).length > 0;
    }

    return false;
  };

  const getColumnMessages = (colName: AssetTypeColumnName) => assetTypesColumnInfo
    .find((item) => item.columnName === colName)?.messages;

  const renderCustomHeader = (colName: AssetTypeColumnName) => (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexGrow={1}
    >
      <p>{colName}</p>
      {checkColumnErrors(colName) && (
        <Tooltip
          title={(
            <p className={classes.tooltipInfo}>
              {getColumnMessages(colName)?.map((message) => (
                <span key={message}>{message}</span>
              ))}
            </p>
          )}
          arrow
          placement="top"
        >
          <ErrorIcon
            style={{ color: COLORS.error, fontSize: '20px' }}
          />
        </Tooltip>
      )}
    </Box>
  );

  const handleDeleteRow = (internalId: string, event: React.MouseEvent) => {
    event.stopPropagation();
    const updatedImportedData = importedData.filter((asset) => asset.id !== internalId);
    setImportedData(updatedImportedData);
  };

  const colProps = [
    {
      colName: AssetTypeColumnName.ASSET_TYPE_NAME,
      customHeader: renderCustomHeader(AssetTypeColumnName.ASSET_TYPE_NAME),
      varKey: 'name',
      colType: ITableItemType.TEXT,
      child: (value: any) => (
        <StyledTableCell
          key="name"
          minWidth="160"
        >
          {selectedAssetType === value.id
            ? (
              <FormInput
                control={control}
                defaultValue={value.name}
                name="name"
                error={!!errors.name}
                placeholder="Asset Type Name"
              />
            )
            : (
              <IdleTableCell
                value={value.name}
                placeholderText="Asset Type Name"
                withError={!!value.errors.name}
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: AssetTypeColumnName.ASSET_TYPE_DESCRIPTION,
      customHeader: renderCustomHeader(AssetTypeColumnName.ASSET_TYPE_DESCRIPTION),
      varKey: 'description',
      colType: ITableItemType.TEXT,
      child: (value: any) => (
        <StyledTableCell
          key="description"
          minWidth="160"
        >
          {selectedAssetType === value.id
            ? (
              <FormInput
                control={control}
                defaultValue={value.description}
                name="description"
                error={!!errors.description}
                placeholder="Description"
              />
            )
            : (
              <IdleTableCell
                value={value.description}
                placeholderText="Description"
                withError={!!value.errors.description}
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: AssetTypeColumnName.DEPRECIATION_ACCOUNT,
      customHeader: (renderCustomHeader(AssetTypeColumnName.DEPRECIATION_ACCOUNT)),
      varKey: 'depreciationAccount',
      colType: ITableItemType.SORTKEY,
      sortKey: ['name'],
      child: (value: any) => (
        <StyledTableCell
          key="depreciationAccount"
          minWidth="200"
        >
          {selectedAssetType === value.id
            ? (
              <FactaAutocomplete
                control={control}
                options={incomeAccounts || []}
                disableInactiveOptions
                optionName="name"
                name="depreciationAccount"
                defaultValue={value.depreciationAccount}
                {...(value?.depreciationAccount?.value
                  && { defaultInputValue: value.depreciationAccount.value })
                }
                error={errors?.depreciationAccount}
                placeholder="Select Account"
              />
            )
            : (
              <IdleTableCell
                value={value.depreciationAccount?.name || value?.depreciationAccount?.value || ''}
                placeholderText="Select Account"
                withError={!!value.errors.depreciationAccount}
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: AssetTypeColumnName.GAIN_LOSS_ACCOUNT,
      customHeader: (renderCustomHeader(AssetTypeColumnName.GAIN_LOSS_ACCOUNT)),
      varKey: 'gainOrLossAccount',
      colType: ITableItemType.SORTKEY,
      sortKey: ['name'],
      child: (value: any) => (
        <StyledTableCell
          key="gainOrLossAccount"
          minWidth="200"
        >
          {selectedAssetType === value.id
            ? (
              <FactaAutocomplete
                control={control}
                options={incomeAccounts || []}
                disableInactiveOptions
                optionName="name"
                name="gainOrLossAccount"
                defaultValue={value.gainOrLossAccount}
                {...(value?.gainOrLossAccount?.value
                  && { defaultInputValue: value.gainOrLossAccount.value })
                }
                error={errors?.gainOrLossAccount}
                placeholder="Select Account"
              />
            )
            : (
              <IdleTableCell
                value={value.gainOrLossAccount?.name || value?.gainOrLossAccount?.value || ''}
                placeholderText="Select Account"
                withError={!!value.errors.gainOrLossAccount}
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: AssetTypeColumnName.CLASS,
      customHeader: (renderCustomHeader(AssetTypeColumnName.CLASS)),
      varKey: 'class',
      colType: ITableItemType.SORTKEY,
      sortKey: ['name'],
      child: (value: any) => (
        <StyledTableCell
          key="class"
          minWidth="160px"
        >
          {selectedAssetType === value.id
            ? (
              <FactaAutocomplete
                control={control}
                options={accountClasses || []}
                disableInactiveOptions
                optionName="name"
                name="class"
                defaultValue={value.class}
                {...(value?.class?.value
                  && { defaultInputValue: value.class.value })
                }
                error={errors?.class}
                placeholder="Select Class"
              />
            )
            : (
              <IdleTableCell
                value={value.class?.name || value?.class?.value || ''}
                placeholderText="Select Class"
                withError={!!value.errors.class}
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: AssetTypeColumnName.LOCATION,
      customHeader: (renderCustomHeader(AssetTypeColumnName.LOCATION)),
      varKey: 'location',
      colType: ITableItemType.SORTKEY,
      sortKey: ['name'],
      child: (value: any) => (
        <StyledTableCell
          key="location"
          minWidth="160px"
        >
          {selectedAssetType === value.id
            ? (
              <FactaAutocomplete
                control={control}
                options={accountLocations || []}
                disableInactiveOptions
                optionName="name"
                name="location"
                defaultValue={value.location}
                {...(value?.location?.value
                  && { defaultInputValue: value.location.value })
                }
                error={errors?.location}
                placeholder="Select Location"
              />
            )
            : (
              <IdleTableCell
                value={value.location?.name || value?.location?.value || ''}
                placeholderText="Select Location"
                withError={!!value.errors.location}
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: AssetTypeColumnName.USEFUL_LIFE,
      customHeader: (renderCustomHeader(AssetTypeColumnName.USEFUL_LIFE)),
      varKey: 'usefulLifeValue',
      colType: ITableItemType.NUMMARY,
      child: (value: any) => (
        <StyledTableCell
          key="usefulLifeValue"
          minWidth="120"
          alignRight
        >
          {selectedAssetType === value.id
            ? (
              <NumberInput
                control={control}
                name="usefulLifeValue"
                defaultValue={value.usefulLifeValue}
                error={!!errors?.usefulLifeValue}
                suffix=" months"
                decimalScale={0}
              />
            )
            : (
              <IdleTableCell
                value={`${value.usefulLifeValue} months`}
                placeholderText="Useful Life"
                withError={!!value.errors.usefulLifeValue}
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: '',
      varKey: 'delete',
      colType: ITableItemType.ACTION,
      child: (value: any) => (
        <StyledTableCell key="delete">
          <IconButton
            onClick={(e) => handleDeleteRow(value.id, e)}
          >
            <DeleteIcon />
          </IconButton>
        </StyledTableCell>
      ),
    },
  ];

  const handleClickAway = () => {
    if (selectedAssetType && !Object.keys(errors).length) {
      const updatedData = importedData.map((asset) => {
        if (asset.id === selectedAssetType) {
          return { id: selectedAssetType, ...getValues(), errors: {} };
        }

        return asset;
      });
      setImportedData(updatedData);
      setSelectedAssetType('');
    }
  };

  const handleRowSelect = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, id: string) => {
    event.stopPropagation();
    if (selectedAssetType === id) {
      return;
    }

    if (selectedAssetType && selectedAssetType !== id) {
      const updatedData = importedData.map((asset) => {
        if (asset.id === selectedAssetType) {
          return { id: selectedAssetType, ...(validateAssetType(getValues())) };
        }

        return asset;
      });
      setImportedData(assignErrorOnDuplicates(updatedData));
    }

    setSelectedAssetType(id);
    reset(importedData.find((asset) => asset.id === id));
    setTimeout(() => trigger(), 0);
  };

  const defaultSort = {
    key: 'name',
    order: SortOrder.DESC,
  };

  const onSubmit = () => {
    const parsedAssetTypes: Array<Partial<AssetType>> = importedData.map((data) => ({
      id: undefined,
      description: data.description,
      name: data.name,
      visible: true,
      depreciationAccountId: data.depreciationAccount.id,
      gainOrLossAccountId: data.gainOrLossAccount.id,
      usefulLifeValue: data.usefulLifeValue,
      usefulLifeUnits: UsefulLifeUnits.MONTHS,
      tags: [
        ...(data.class?.id ? [{
          category: TagCategories.CLASS,
          id: data.class.id,
        }] : []),
        ...(data.location?.id ? [{
          category: TagCategories.LOCATION,
          id: data.location.id,
        }] : []),
      ],
    }));

    reduxDispatch(postBulkCreateAssetType({ assetTypes: parsedAssetTypes }))
      .unwrap()
      .then(() => handleClose());
  };

  const filteredData = useMemo(
    () => (tab === ImportTab.ERRORS && !isImportedDataValid
      ? importedData.filter((asset) => Object.keys(asset.errors).length)
      : importedData),
    [importedData, isImportedDataValid, tab],
  );

  return (
    <Flyover
      open={isOpen}
      title="Asset type import"
      variant="cover"
      onClose={handleClose}
    >
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        flexGrow={1}
        overflow="auto"
      >
        <UploadActions
          handleTemplateDownload={handleTemplateDownload}
          onFileUploaded={onFileUploaded}
          onSubmit={onSubmit}
          handleDownloadFixedData={handleDownloadFixedData}
          isImportedDataValid={isImportedDataValid}
          uploadedFile={uploadedFile}
        />
        {importedData.length > 0 && (
          <>
            <Tabs
              value={tab}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              scrollButtons="off"
            >
              <Tab
                label={`All (${importedData.length})`}
                value={ImportTab.ALL}
                disableRipple
              />
              <Tab
                label={(
                  <Box display="flex">
                    <Typography className={classes.errorTab}>
                      With errors (
                      {importedData.filter((asset) => Object.keys(asset.errors).length)?.length || 0}
                      )
                    </Typography>
                    {!isImportedDataValid && <ErrorIcon style={{ color: COLORS.error, fontSize: '20px' }} />}
                  </Box>
                )}
                value={ImportTab.ERRORS}
                disableRipple
                disabled={isImportedDataValid}
              />
            </Tabs>
            <form
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                overflow: 'auto',
              }}
            >
              <TableComponent
                colProps={colProps}
                tableData={filteredData}
                defaultSort={defaultSort}
                contentType={ITableType.fixedAssetsAmortizationSources}
                onRowClick={handleRowSelect}
                onClickOutsideSelectedRow={handleClickAway}
                selectedId={selectedAssetType}
                topBarActions={[]}
              />
            </form>
          </>
        )}
      </Box>
    </Flyover>
  );
};
