import styled from 'styled-components';

export const DevInfo = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  background-color: purple;
  color: white;
  padding: 3px 4px 4px 8px;
  border-radius: 0 0 0 10px;
`;
