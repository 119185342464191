import React, {
  useEffect,
  useCallback,
} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Typography,
} from '@material-ui/core';
import find from 'lodash.find';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  format,
  formatISO,
  parseISO,
} from 'date-fns';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Controller,
  useForm,
  SubmitHandler,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { InputLabel } from '../../../InputLabel';
import FactaAutocomplete from '../../../Inputs/FactaAutocomplete';
import { AppThunkDispatch } from '../../../../store/store';
import COLORS from '../../../../theme/colors';
import { patchV2Subledger } from '../../../../store/slices/v2Subledgers';
import { isUpdatingV2Subledger } from '../../../../store/selectors/v2Subledgers';
import { SchedulingMethodOption } from '../../../../interfaces/common';
import {
  accountsAccumulationListSelector,
  accountsListSelector,
} from '../../../../store/selectors/v2Accounts';
import { Account } from '../../../../interfaces/accounts';
import { V2Subledger } from '../../../../interfaces/subledgers';
import { MONTH_SHORT_FORMAT } from '../../../../util/constants';
import { CalendarIcon } from '../../../Icons';
import MonthPicker from '../../../MonthPicker';
import { StyledCalendarInput } from '../../../MonthPicker/index.styled';
import { schedulingMethodOptions } from '../../../../util/helpers';

const yupSchema = () => yupResolver(yup.object().shape({
  accumulationAccount: yup.object({
    id: yup.string().required(),
    name: yup.string().required(),
  }).required(),
  factaStartDate: yup.date().required(),
  amortizationScheduleType: yup.object({
    value: yup.string().required(),
    label: yup.string().required(),
  }).required(),
}));

const useStyles = makeStyles(() => createStyles({
  accountDetails: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    marginRight: 40,
    paddingRight: 40,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    borderRight: `1px solid ${COLORS.disabled}`,
    textTransform: 'uppercase',
  },
  accountName: {
    marginLeft: 16,
    fontWeight: 500,
    lineHeight: '26px',
  },
  form: {
    position: 'relative',
    display: 'flex',
    '& > *': {
      marginRight: '24px',
    },
  },
  applyButton: {
    alignSelf: 'flex-end',
  },
}));

interface Props {
  subledgerDetails: V2Subledger;
  setIsFormDirty?: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
}

interface ApplyFormData {
  factaStartDate: Date;
  amortizationScheduleType: SchedulingMethodOption;
  accumulationAccount: Account;
}

export const AssetDetailsForm = ({
  subledgerDetails,
  setIsFormDirty,
  disabled,
}: Props) => {
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const accounts = useSelector(accountsListSelector);
  const accumulationAccounts = useSelector(accountsAccumulationListSelector);
  const isFetching = useSelector(isUpdatingV2Subledger);
  const selectedDate = parseISO(subledgerDetails.startDate);
  const classes = useStyles();
  const {
    handleSubmit,
    errors,
    control,
    formState,
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: yupSchema(),
    shouldUnregister: true,
  });

  const handleApply: SubmitHandler<ApplyFormData> = (data) => {
    const parsedData = {
      startDate: formatISO(data.factaStartDate, { representation: 'date' }),
      defaultSchedulingMethod: data.amortizationScheduleType.value,
      accumulationAccount: data.accumulationAccount.id,
    };
    reduxDispatch(patchV2Subledger({
      subledgerId: subledgerDetails.id,
      params: parsedData,
    }));
  };

  const handleReset = useCallback(() => {
    reset({
      accumulationAccount: find(accounts, { id: subledgerDetails.accumulationAccount }),
      factaStartDate: parseISO(subledgerDetails.startDate),
      amortizationScheduleType: find(schedulingMethodOptions, { value: subledgerDetails.defaultSchedulingMethod }),
    });
  }, [accounts, reset, subledgerDetails]);

  useEffect(() => {
    handleReset();
  }, [accounts, handleReset]);

  useEffect(() => {
    if (setIsFormDirty) {
      setIsFormDirty(formState.isDirty);
    }
  }, [formState.isDirty, setIsFormDirty]);

  return (
    <form className={classes.form}>
      <Box>
        <InputLabel
          label="Accumulated Depreciation Mapping"
          keepHeight
          centered
        />
        <FactaAutocomplete
          control={control}
          name="accumulationAccount"
          options={accumulationAccounts}
          disableInactiveOptions
          optionName="displayName"
          defaultValue={null}
          error={!!errors?.accumulationAccount}
          disabled={disabled}
        />
      </Box>
      <Box>
        <InputLabel
          label="Facta Start Date"
          tooltipText="Facta Start Date will always be the first day of the month."
          centered
        />
        <Controller
          name="factaStartDate"
          control={control}
          defaultValue={selectedDate}
          render={({ onChange, value: localValue }) => (
            <MonthPicker
              selectedDate={localValue}
              onDateChange={onChange}
              disabled={disabled}
              customInput={(
                <StyledCalendarInput withError={!!errors?.factaStartDate}>
                  <Typography>{localValue && format(localValue, MONTH_SHORT_FORMAT)}</Typography>
                  <CalendarIcon />
                </StyledCalendarInput>
              )}
            />
          )}
        />
      </Box>
      <Box minWidth={140}>
        <InputLabel
          label="Schedule"
          keepHeight
          centered
        />
        <FactaAutocomplete
          control={control}
          name="amortizationScheduleType"
          options={schedulingMethodOptions}
          optionName="label"
          defaultValue={null}
          error={!!errors?.amortizationScheduleType}
          disabled={disabled}
        />
      </Box>
      {!disabled && (
        <Box
          display="flex"
          alignItems="center"
        >
          <Button
            disabled={!formState.isDirty || !formState.isValid || isFetching}
            className={classes.applyButton}
            color="primary"
            variant="contained"
            onClick={handleSubmit(handleApply)}
          >
            Apply
            {isFetching && (
              <CircularProgress
                size={20}
                style={{ position: 'absolute' }}
              />
            )}
          </Button>
          <Button
            disabled={!formState.isDirty || !formState.isValid || isFetching}
            className={classes.applyButton}
            color="primary"
            variant="outlined"
            onClick={handleReset}
          >
            Cancel
            {isFetching && (
              <CircularProgress
                size={20}
                style={{ position: 'absolute' }}
              />
            )}
          </Button>
        </Box>
      )}
    </form>
  );
};
