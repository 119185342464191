import {
  addHours,
  endOfMonth,
  isValid,
  isWithinInterval,
  parse,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import roundTo from 'round-to';
import cloneDeep from 'lodash.clonedeep';
import {
  AmortizationSource,
  LegacySchedulingMethod,
  Subledger,
} from '../../interfaces/types';
import {
  calculateAsset,
  calculateAssetForUnScheduledAssets,
  calculateScheduleAsset,
  defaultAsset,
  defaultAssetFromLocalStorage,
  getAssetSum,
  getChildren,
  getRemainingBalanceForSplitAssets,
  initializedSubledger,
  initializedSubledgerFromLocalStorage,
  initialPrepaidAssetForPrepareJE,
} from './common';
import {
  ADD_ASSET,
  ADD_SPLIT,
  ADD_SPLIT_ASSET,
  ASSET_TEMP_SCHEDULED_STATUS,
  CALCULATE_ASSET,
  DATE_CHANGE,
  DIGIT_LOWER_LIMIT,
  DIGIT_UPPER_LIMIT,
  HISTORICAL_MANUAL_ASSET,
  HISTORICAL_UPDATE_ASSET,
  HISTORICAL_UPDATE_ASSETS,
  HOVER_ROW,
  IMPORT_CSV,
  INITIALED,
  INITIALED_FOR_PREPARE_JE,
  INITIALED_WITH_LOCAL_STORAGE,
  INITIALED_WITHOUT_MODIFICATION,
  INPUT_CHANGE,
  INPUT_FOCUS,
  PREPARE_JE_UPDATE_ASSETS,
  REMOVE_ASSET,
  SELECT_CHANGE,
  SELECT_ROW,
  SUBLEDGER_AMORTIZATION_SCHEDULE_CHANGE,
  SUBLEDGER_START_DATE_CHANGE,
  UNSCHEDULED_DATE_CHANGE,
  UNSCHEDULED_INPUT_CHANGE,
  UNSCHEDULED_REMOVE_ASSET,
  UNSCHEDULED_SELECT_CHANGE,
  UPDATE_ASSET,
  BULK_EDIT,
  UPDATE_ASSETS,
  ASSET_SCHEDULED_STATUS,
  DAY_SHORT_FORMAT,
  BULK_REMOVE_ASSETS,
} from '../../util/constants';

interface Payload {
  internalId: string;
  propertyName: string;
  value: any;
  subledger: Subledger;
  selectedRow: string;
  hoverRow: string;
  scheduleDate: string;
  assets: Array<AmortizationSource>;
  balance: number;
  historicalUpdatedAssets: Array<AmortizationSource>;
  target: HTMLInputElement;
  type: string;
  internalIds: Array<string>;
  changedProperties: any;
}

export interface Action {
  type: string;
  payload: Payload;
}

interface State {
  subledger: Subledger;
  initSubledger: Subledger;
  selectedRow: string;
  hoverRow: string;
  scheduleDate: string;
  historicalUpdatedAssets: Array<AmortizationSource>;
  isSplitAssetsSumCorrect: boolean;
}

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case BULK_EDIT: {
      const { internalIds, changedProperties, scheduleDate } = action.payload;

      const newAssets = [...state?.subledger?.amortizationSources].map((source) => {
        if (internalIds.includes(source.internalId)) {
          const isAmortizationScheduled = source.status === ASSET_SCHEDULED_STATUS;
          const hasSubledgerStatus = !!state.subledger.status;
          const parsedScheduledDate = parse(scheduleDate, DAY_SHORT_FORMAT, new Date());
          const isLimited = scheduleDate ? !isWithinInterval(source.sourceCreationDate!, {
            start: startOfMonth(parsedScheduledDate),
            end: endOfMonth(parsedScheduledDate),
          }) : false;

          const amortizationSchedule = {
            ...source.amortizationSchedule,
          };
          const newValues = (Object.entries(changedProperties).map(([key, value]: [string, any]) => {
            if (isAmortizationScheduled && hasSubledgerStatus && isLimited) {
              switch (key) {
                case 'destinationId': {
                  return { amortizationSchedule: Object.assign(amortizationSchedule, { [key]: value.id }) };
                }
                case 'class': {
                  return { amortizationSchedule: Object.assign(amortizationSchedule, { classId: value.id }) };
                }
              }
            } else {
              switch (key) {
                case 'description': {
                  return { [key]: value };
                }
                case 'vendor': {
                  return { vendorId: value.id };
                }
                case 'customerId': {
                  return { customerId: value.id };
                }
                case 'productId': {
                  return { productId: value.id };
                }
                case 'destinationId': {
                  return { amortizationSchedule: Object.assign(amortizationSchedule, { [key]: value.id }) };
                }
                case 'class': {
                  return { amortizationSchedule: Object.assign(amortizationSchedule, { classId: value.id }) };
                }
                case 'amortizationScheduleType': {
                  return {
                    amortizationSchedule: Object
                      .assign(amortizationSchedule, {
                        [key]: value.value,
                        // @ts-ignore
                        ...(value.value === LegacySchedulingMethod.MANUAL ?? {
                          amortizationEndDate: null,
                          amortizationStartDate: null,
                        }),
                      }),
                  };
                }
                case 'amortizationStartDate': {
                  if (!isValid(value)) {
                    return {};
                  }
                  return { amortizationSchedule: Object.assign(amortizationSchedule, { [key]: value }) };
                }
                case 'amortizationEndDate': {
                  if (!isValid(value)) {
                    return {};
                  }
                  return { amortizationSchedule: Object.assign(amortizationSchedule, { [key]: value }) };
                }
              }
            }
            return null;
          }));

          return {
            ...source,
            ...(Object.assign({}, ...newValues)),
          };
        }
        return source;
      });

      if (Object.keys(changedProperties).find((key) => key === 'amortizationEndDate'
        || 'amortizationScheduleType'
        || 'amortizationStartDate')) {
        return {
          ...state,
          subledger: {
            ...state.subledger,
            amortizationSources: newAssets.map((asset) => calculateScheduleAsset(state.subledger, asset, newAssets)),
          },
        };
      }

      return {
        ...state,
        subledger: {
          ...state.subledger,
          amortizationSources: newAssets,
        },
      };
    }
    case INITIALED_WITHOUT_MODIFICATION:
    {
      const { subledger } = action.payload;
      return {
        ...state,
        subledger,
        initSubledger: cloneDeep(subledger),
      };
    }
    case INITIALED:
    {
      const subledger = initializedSubledger(action.payload);
      return {
        subledger,
        initSubledger: cloneDeep(subledger),
        historicalUpdatedAssets: action.payload.historicalUpdatedAssets,
        isSplitAssetsSumCorrect: true,
      };
    }
    case INITIALED_FOR_PREPARE_JE:
    {
      const { scheduleDate } = action.payload;
      const subledger = initializedSubledger(action.payload);
      const serviceAssets = initialPrepaidAssetForPrepareJE(subledger, scheduleDate);

      // @ts-ignore
      return {
        subledger: {
          ...subledger,
          amortizationSources: serviceAssets,
        },
      };
    }
    case INITIALED_WITH_LOCAL_STORAGE:
    {
      const {
        subledger: localSubledger,
        historicalUpdatedAssets: localHistoricalPrepaidAssets,
        scheduleDate,
      } = action.payload;
      const historicalUpdatedAssets = localHistoricalPrepaidAssets?.map(defaultAssetFromLocalStorage) ?? [];
      // @ts-ignore
      const subledger = initializedSubledgerFromLocalStorage({ subledger: localSubledger });
      const serviceAssets = initialPrepaidAssetForPrepareJE(subledger, scheduleDate);

      // @ts-ignore
      return {
        subledger: {
          ...subledger,
          amortizationSources: serviceAssets,
        },
        historicalUpdatedAssets,
      };
    }
    case PREPARE_JE_UPDATE_ASSETS:
    {
      const { assets } = action.payload;
      return {
        ...state,
        subledger: {
          ...state.subledger,
          amortizationSources: assets,
        },
      };
    }
    case SELECT_ROW:
    {
      if (state.selectedRow === action.payload.selectedRow) {
        return state;
      }
      return {
        ...state,
        selectedRow: action.payload.selectedRow,
      };
    }
    case HOVER_ROW:
    {
      if (state.hoverRow === action.payload.hoverRow) {
        return state;
      }
      return {
        ...state,
        hoverRow: action.payload.hoverRow,
      };
    }
    case SUBLEDGER_START_DATE_CHANGE:
    {
      const { value }: Payload = action.payload;
      return {
        ...state,
        subledger: {
          ...state?.subledger,
          factaStartDate: startOfMonth(value!),
        },
      };
    }
    case SUBLEDGER_AMORTIZATION_SCHEDULE_CHANGE:
    {
      const { value }: Payload = action.payload;
      return {
        ...state,
        subledger: {
          ...state?.subledger,
          account: {
            ...state.subledger?.account,
            scheduleType: value,
          },
        },
      };
    }
    case INPUT_CHANGE:
    {
      const { internalId, propertyName, value }: Payload = action.payload;
      const newAssets = [...state?.subledger?.amortizationSources];
      const index = newAssets?.findIndex((asset: AmortizationSource) => asset.internalId === internalId);
      if (propertyName === 'startingBalance') {
        if (Number.isNaN(Number(value)) || Number(value) >= DIGIT_UPPER_LIMIT || Number(value) <= DIGIT_LOWER_LIMIT) {
          return state;
        }
        // @ts-ignore
        newAssets[index][propertyName] = value;
        newAssets[index] = calculateScheduleAsset(state?.subledger, newAssets[index]);

        const {
          isParentAsset,
          remainingBalance,
        } = getRemainingBalanceForSplitAssets(newAssets, internalId);

        const isSplitAssetsSumCorrect = isParentAsset ? state.isSplitAssetsSumCorrect : remainingBalance === 0;

        return {
          ...state,
          subledger: {
            ...state.subledger,
            openingBalance: getAssetSum(state?.subledger, newAssets),
            amortizationSources: newAssets,
          },
          isSplitAssetsSumCorrect,
        };
      }

      // @ts-ignore
      newAssets[index][propertyName] = value;
      return {
        ...state,
        subledger: {
          ...state.subledger,
          amortizationSources: newAssets,
        },
      };
    }
    case INPUT_FOCUS:
    {
      const { propertyName, internalId, target, type }: Payload = action.payload;
      if (propertyName === 'startingBalance') {
        const newAssets = [...state?.subledger?.amortizationSources];
        const index = newAssets?.findIndex((asset: AmortizationSource) => asset.internalId === internalId);

        const {
          isParentAsset,
          currentAssetValue,
          remainingBalance,
        } = getRemainingBalanceForSplitAssets(newAssets, internalId);

        const isSplitAssetsSumCorrect = isParentAsset ? state.isSplitAssetsSumCorrect : remainingBalance === 0;

        if (!Number(currentAssetValue) && remainingBalance > 0) {
          if (type === 'focus') setTimeout(() => target.select(), 0);
          // @ts-ignore
          newAssets[index][propertyName] = remainingBalance;
          newAssets[index] = calculateScheduleAsset(state?.subledger, newAssets[index]);

          return {
            ...state,
            isSplitAssetsSumCorrect: true,
            subledger: {
              ...state.subledger,
              amortizationSources: newAssets
                .map((asset: AmortizationSource) => (asset.internalId === internalId
                  ? { ...asset, startingBalance: remainingBalance }
                  : asset)),
            },
          };
        }
        return {
          ...state,
          isSplitAssetsSumCorrect,
        };
      }

      return state;
    }
    case UNSCHEDULED_INPUT_CHANGE:
    {
      const { internalId, propertyName, value }: Payload = action.payload;
      const newAssets = [...state?.subledger?.amortizationSources];
      const index = newAssets?.findIndex((asset: AmortizationSource) => asset.internalId === internalId);
      if (propertyName === 'startingBalance') {
        if (Number.isNaN(Number(value)) || Number(value) >= DIGIT_UPPER_LIMIT || Number(value) <= DIGIT_LOWER_LIMIT) {
          return state;
        }
        newAssets[index][propertyName] = value;
        calculateAssetForUnScheduledAssets(newAssets, index, state?.subledger);
        return {
          ...state,
          subledger: {
            ...state.subledger,
            amortizationSources: newAssets,
          },
        };
      }

      // @ts-ignore
      newAssets[index][propertyName] = value;
      return {
        ...state,
        subledger: {
          ...state.subledger,
          amortizationSources: newAssets,
        },
      };
    }
    case SELECT_CHANGE:
    {
      const { internalId, propertyName, value }: Payload = action.payload;
      const newAssets = [...state?.subledger.amortizationSources] as Array<AmortizationSource>;
      const index = newAssets?.findIndex((asset: AmortizationSource) => asset.internalId === internalId);
      if (propertyName === 'amortizationScheduleType') {
        newAssets[index].amortizationSchedule.amortizationScheduleDetails = null;
        if (newAssets[index]?.amortizationSchedule?.[propertyName] === LegacySchedulingMethod.MANUAL
          && value !== LegacySchedulingMethod.MANUAL) {
          newAssets[index].amortizationSchedule.amortizationEndDate = new Date();
          newAssets[index].amortizationSchedule.amortizationStartDate = state?.subledger?.factaStartDate;
        }
        // @ts-ignore
        newAssets[index].amortizationSchedule[propertyName] = value;
        if (newAssets[index]?.amortizationSchedule?.[propertyName] !== LegacySchedulingMethod.MANUAL) {
          newAssets[index] = calculateScheduleAsset(state?.subledger, newAssets[index]);
        } else {
          newAssets[index].amortizationSchedule.amortizationEndDate = null;
          newAssets[index].amortizationSchedule.amortizationStartDate = null;
          newAssets[index].amortizationSchedule.amortizationScheduleDetails = null;
        }
      } else if (['classId', 'destinationId'].includes(propertyName)) {
        // @ts-ignore
        newAssets[index].amortizationSchedule[propertyName] = value;
      } else {
        // @ts-ignore
        newAssets[index][propertyName] = value;
      }
      return {
        ...state,
        subledger: {
          ...state.subledger,
          amortizationSources: newAssets,
        },
      };
    }
    case DATE_CHANGE:
    {
      const { internalId, propertyName, value }: Payload = action.payload;
      if (!isValid(value)) {
        return state;
      }
      const newAssets = [...state?.subledger.amortizationSources] as Array<AmortizationSource>;
      const index = state?.subledger.amortizationSources
        ?.findIndex((asset: AmortizationSource) => asset.internalId === internalId);
      // @ts-ignore
      newAssets[index].amortizationSchedule[propertyName] = value ?? new Date();
      newAssets[index] = calculateScheduleAsset(state?.subledger, newAssets[index]);
      return {
        ...state,
        subledger: {
          ...state.subledger,
          amortizationSources: newAssets,
        },
      };
    }
    case UNSCHEDULED_DATE_CHANGE:
    {
      const { internalId, propertyName, value }: Payload = action.payload;
      if (!isValid(value)) {
        return state;
      }
      const newAssets = [...state?.subledger.amortizationSources];
      const index = state?.subledger.amortizationSources
        ?.findIndex((asset: AmortizationSource) => asset.internalId === internalId);
      // @ts-ignore
      newAssets[index].amortizationSchedule[propertyName] = value ?? new Date();
      calculateAssetForUnScheduledAssets(newAssets, index, state?.subledger);
      return {
        ...state,
        subledger: {
          ...state.subledger,
          amortizationSources: newAssets,
        },
      };
    }
    case UNSCHEDULED_SELECT_CHANGE:
    {
      const { internalId, propertyName, value }: Payload = action.payload;
      const newAssets = [...state?.subledger.amortizationSources];
      const index = newAssets?.findIndex((asset: AmortizationSource) => asset.internalId === internalId);
      if (propertyName === 'amortizationScheduleType') {
        newAssets[index].amortizationSchedule.amortizationScheduleDetails = null;
        if (newAssets[index].amortizationSchedule[propertyName] === LegacySchedulingMethod.MANUAL
          && value !== LegacySchedulingMethod.MANUAL) {
          newAssets[index].amortizationSchedule.amortizationEndDate = new Date();
          newAssets[index].amortizationSchedule.amortizationStartDate = state?.subledger?.factaStartDate;
        } else if (newAssets[index]?.amortizationSchedule?.[propertyName] !== LegacySchedulingMethod.MANUAL
          && value === LegacySchedulingMethod.MANUAL) {
          newAssets[index].amortizationSchedule.amortizationScheduleDetails = null;
        }
        // @ts-ignore
        newAssets[index].amortizationSchedule[propertyName] = value;
        calculateAssetForUnScheduledAssets(newAssets, index, state?.subledger);
      } else if (['classId', 'destinationId'].includes(propertyName)) {
        // @ts-ignore
        newAssets[index].amortizationSchedule[propertyName] = value;
      } else {
        // TODO need to add a list here
        // @ts-ignore
        newAssets[index][propertyName] = value;
      }
      return {
        ...state,
        subledger: {
          ...state.subledger,
          amortizationSources: newAssets,
        },
      };
    }
    case HISTORICAL_UPDATE_ASSETS:
    {
      const { assets, internalIds }: Payload = action.payload;

      const updatedAssets = assets.map((asset: AmortizationSource) => (asset?.status
        ? asset
        : {
          ...asset,
          status: ASSET_TEMP_SCHEDULED_STATUS,
        }));
      const notUpdatedAmortizationSources = state.subledger.amortizationSources
        .filter((source: AmortizationSource) => !internalIds.includes(source.internalId));

      const historicalUpdatedAssets = state?.historicalUpdatedAssets
        ?.filter((asset: AmortizationSource) => !internalIds.includes(asset.internalId));

      return {
        ...state,
        subledger: {
          ...state.subledger,
          amortizationSources: [...updatedAssets, ...notUpdatedAmortizationSources],
        },
        historicalUpdatedAssets: [...historicalUpdatedAssets, ...updatedAssets],
      };
    }
    case HISTORICAL_UPDATE_ASSET:
    {
      const { internalId }: Payload = action.payload;
      const newAssets = calculateAsset(state?.subledger, internalId);
      const parentAsset = newAssets
        ?.find((asset: AmortizationSource) => asset.internalId === internalId);
      if (!parentAsset) {
        return state;
      }
      if (!parentAsset.status) {
        parentAsset.status = ASSET_TEMP_SCHEDULED_STATUS;
      }
      const historicalUpdatedAssets = state?.historicalUpdatedAssets
        ?.filter((asset: AmortizationSource) => !(asset.internalId === internalId || asset.parentId === internalId));

      // update historical assets
      historicalUpdatedAssets.push(cloneDeep(parentAsset));
      const children = getChildren(internalId, newAssets);
      children?.forEach((child) => {
        if (!child.status) {
          // eslint-disable-next-line no-param-reassign
          child.status = ASSET_TEMP_SCHEDULED_STATUS;
        }
        historicalUpdatedAssets.push(cloneDeep(child));
      });

      return {
        ...state,
        subledger: {
          ...state.subledger,
          amortizationSources: newAssets,
        },
        historicalUpdatedAssets,
      };
    }
    case HISTORICAL_MANUAL_ASSET:
    {
      const { internalId }: Payload = action.payload;
      const manualAsset = state?.subledger?.amortizationSources
        ?.find((asset: AmortizationSource) => asset.internalId === internalId);
      if (!manualAsset) {
        return state;
      }
      let historicalUpdatedAssets = [];
      if (manualAsset?.parentId) {
        const parentAsset = state?.subledger?.amortizationSources
          ?.find((asset: AmortizationSource) => asset.internalId === manualAsset?.parentId);
        if (!parentAsset) {
          return state;
        }
        historicalUpdatedAssets = state?.historicalUpdatedAssets
          ?.filter((asset: AmortizationSource) => !(asset.internalId === parentAsset.internalId
              || asset.parentId === parentAsset.internalId));

        // update historical assets
        historicalUpdatedAssets.push(cloneDeep(parentAsset));
        const children = getChildren(parentAsset.internalId, state?.subledger?.amortizationSources);
        children?.forEach((child) => historicalUpdatedAssets.push(cloneDeep(child)));
      } else {
        historicalUpdatedAssets = state?.historicalUpdatedAssets
          ?.filter((asset: AmortizationSource) => !(asset.internalId === internalId));

        // update historical assets
        historicalUpdatedAssets.push(cloneDeep(manualAsset));
      }
      return {
        ...state,
        historicalUpdatedAssets,
      };
    }
    case CALCULATE_ASSET:
    {
      const { internalId }: Payload = action.payload;
      const newAssets = calculateAsset(state?.subledger, internalId);
      return {
        ...state,
        subledger: {
          ...state.subledger,
          amortizationSources: newAssets,
        },
      };
    }
    case UPDATE_ASSETS:
    {
      const { assets, balance, internalIds }: Payload = action.payload;

      const updatedAssets = assets.map((source: AmortizationSource) => defaultAsset(
        source,
        source.amortizationSchedule?.amortizationScheduleType,
        state?.subledger?.factaStartDate,
      ));
      const notUpdatedAmortizationSources = state.subledger.amortizationSources
        .filter((source: AmortizationSource) => !internalIds.includes(source.internalId));

      const changedNotUpdatedAmortizationSources = state?.initSubledger?.amortizationSources
        .filter((source: AmortizationSource) => !internalIds.includes(source.internalId));

      return {
        ...state,
        subledger: {
          ...state.subledger,
          openingBalance: balance,
          amortizationSources: [...updatedAssets, ...notUpdatedAmortizationSources],
        },
        initSubledger: {
          ...state.initSubledger,
          openingBalance: balance,
          amortizationSources: [...updatedAssets, ...changedNotUpdatedAmortizationSources],
        },
      };
    }
    case UPDATE_ASSET:
    {
      const { assets, balance, internalId }: Payload = action.payload;

      // old child assets
      const stateSubledger = state?.subledger;
      const initSubledger = state?.initSubledger;
      const oldAmortizationSources = stateSubledger?.amortizationSources?.filter((a) => a.parentId !== internalId);
      const oldInitSubledgerAmortizationSources = initSubledger?.amortizationSources
        ?.filter((a) => a.parentId !== internalId);

      // get parent asset index
      const index = oldAmortizationSources?.findIndex((a: AmortizationSource) => a.internalId === internalId);

      // get parent asset from service
      let amortizationSource = assets?.find((a) => !a.parentId);
      if (!amortizationSource) {
        return state;
      }
      // @ts-ignore
      amortizationSource = defaultAsset(
        amortizationSource,
        action?.payload?.subledger?.account?.scheduleType,
        state?.subledger?.factaStartDate,
      );

      const newChildAssets = assets
        ?.filter((a) => a.parentId === amortizationSource?.id)
        ?.map((a) => defaultAsset(
          a,
          action?.payload?.subledger?.account?.scheduleType,
          state?.subledger?.factaStartDate,
        )) ?? [];

      oldAmortizationSources[index] = amortizationSource;
      // update init subledger for dirty check
      oldInitSubledgerAmortizationSources[index] = cloneDeep(amortizationSource);

      const updatedAmortizationSources = [...oldAmortizationSources, ...newChildAssets];
      const updatedInitSubledgerAmortizationSources = [
        ...oldInitSubledgerAmortizationSources, ...cloneDeep(newChildAssets),
      ];
      const openingBalance = balance;
      return {
        ...state,
        subledger: {
          ...state.subledger,
          openingBalance,
          amortizationSources: updatedAmortizationSources,
        },
        initSubledger: {
          ...state.initSubledger,
          openingBalance,
          amortizationSources: updatedInitSubledgerAmortizationSources,
        },
      };
    }
    case ADD_ASSET:
    {
      const internalId = uuidv4();
      const newAssets = [...state?.subledger?.amortizationSources, calculateScheduleAsset(state?.subledger, {
        internalId,
        sourceCreationDate: startOfDay(state?.subledger?.factaStartDate ?? new Date()),
        amortizationSchedule: {
          amortizationEndDate: endOfMonth(state?.subledger?.factaStartDate ?? new Date()),
          amortizationStartDate: state?.subledger?.factaStartDate,
          amortizationScheduleType: state?.subledger?.account?.scheduleType,
        },
        startingBalance: 0,
      } as AmortizationSource)];
      return {
        ...state,
        selectedRow: internalId,
        subledger: {
          ...state.subledger,
          amortizationSources: newAssets,
        },
      };
    }
    case BULK_REMOVE_ASSETS: {
      const { internalIds }: Payload = action.payload;
      const oldAssets = [...state?.subledger?.amortizationSources];
      const updatedAssets = oldAssets.filter((asset: AmortizationSource) => !(internalIds.includes(asset.internalId)));

      return {
        ...state,
        subledger: {
          ...state.subledger,
          openingBalance: getAssetSum(state?.subledger, updatedAssets),
          amortizationSources: updatedAssets,
        },
      };
    }
    case REMOVE_ASSET:
    {
      const { internalId }: Payload = action.payload;
      const oldAssets = [...state?.subledger?.amortizationSources];
      const index = oldAssets?.findIndex((asset: AmortizationSource) => asset.internalId === internalId);
      const internalIds = [internalId];
      const amortizationSource = state?.subledger?.amortizationSources
        ?.find((asset: AmortizationSource) => asset.internalId === internalId);
      // @ts-ignore
      const sourceCreationDate = amortizationSource.sourceCreationDate
        ?? startOfDay(state?.subledger?.factaStartDate ?? new Date());

      if (oldAssets[index].parentId) {
        const children = getChildren(oldAssets[index].parentId, oldAssets);
        const parentAssetIndex = oldAssets
          ?.findIndex((asset: AmortizationSource) => asset.internalId === oldAssets[index].parentId);
        if (children?.length === 2) {
          internalIds.push(children?.[0]?.internalId);
          internalIds.push(children?.[1]?.internalId);
          oldAssets[parentAssetIndex].amortizationSchedule.amortizationStartDate = state?.subledger?.factaStartDate;
          oldAssets[parentAssetIndex].amortizationSchedule.amortizationEndDate = endOfMonth(sourceCreationDate);
          oldAssets[parentAssetIndex] = calculateScheduleAsset(state?.subledger, oldAssets[parentAssetIndex]);
        }
      }
      const updatedAssets = oldAssets?.filter((asset: AmortizationSource) => !(internalIds.includes(asset.internalId)
        || internalIds.includes(asset.parentId)));

      const {
        isParentAsset,
        remainingBalance,
      } = getRemainingBalanceForSplitAssets(oldAssets, internalId, updatedAssets);

      const isSplitAssetsSumCorrect = isParentAsset ? state.isSplitAssetsSumCorrect : remainingBalance === 0;

      return {
        ...state,
        isSplitAssetsSumCorrect,
        subledger: {
          ...state.subledger,
          openingBalance: getAssetSum(state?.subledger, updatedAssets),
          amortizationSources: updatedAssets,
        },
      };
    }
    case UNSCHEDULED_REMOVE_ASSET:
    {
      const { internalId }: Payload = action.payload;
      const oldAssets = [...state?.subledger?.amortizationSources];
      const index = oldAssets?.findIndex((asset: AmortizationSource) => asset.internalId === internalId);
      const internalIds = [internalId];
      const amortizationSource = state?.subledger?.amortizationSources
        ?.find((asset: AmortizationSource) => asset.internalId === internalId);
      // @ts-ignore
      const sourceCreationDate = amortizationSource.sourceCreationDate
        ?? startOfDay(state?.subledger?.factaStartDate ?? new Date());
      if (oldAssets[index].parentId) {
        const children = getChildren(oldAssets[index].parentId, oldAssets);
        const parentAssetIndex = oldAssets
          ?.findIndex((asset: AmortizationSource) => asset.internalId === oldAssets[index].parentId);
        if (children?.length === 2) {
          internalIds.push(children?.[0]?.internalId);
          internalIds.push(children?.[1]?.internalId);
          oldAssets[parentAssetIndex].amortizationSchedule.amortizationStartDate = state?.subledger?.factaStartDate;
          oldAssets[parentAssetIndex].amortizationSchedule.amortizationEndDate = endOfMonth(sourceCreationDate);
        }
      }

      const updatedAssets = oldAssets?.filter((asset: AmortizationSource) => !(internalIds.includes(asset.internalId)));
      return {
        ...state,
        subledger: {
          ...state.subledger,
          amortizationSources: [...updatedAssets],
        },
      };
    }
    case ADD_SPLIT:
    {
      const { internalId }: Payload = action.payload;
      const amortizationSource = state?.subledger?.amortizationSources
        ?.find((asset: AmortizationSource) => asset.internalId === internalId);
      if (!amortizationSource) {
        return state;
      }

      const sourceCreationDate = amortizationSource.sourceCreationDate ?? startOfDay(state?.subledger?.factaStartDate
        ?? new Date());
      const splitAmount = roundTo(amortizationSource?.startingBalance
        ? Number(amortizationSource?.startingBalance / 2) : 0, 2);
      const amortizationStartDate = addHours(startOfDay(amortizationSource.amortizationSchedule.amortizationStartDate
        ?? sourceCreationDate!), 12);
      const amortizationEndDate = addHours(startOfDay(amortizationSource.amortizationSchedule.amortizationEndDate!
        ?? sourceCreationDate!), 12);
      const amortizationScheduleType = amortizationSource.amortizationSchedule.amortizationScheduleType!;
      amortizationSource.amortizationSchedule.amortizationScheduleDetails = null;
      const newAssets = [
        ...state?.subledger?.amortizationSources,
        calculateScheduleAsset(state?.subledger, {
          internalId: uuidv4(),
          sourceCreationDate,
          amortizationSchedule: {
            amortizationEndDate,
            amortizationStartDate,
            amortizationScheduleType,
            destinationId: amortizationSource.amortizationSchedule.destinationId,
            classId: amortizationSource.amortizationSchedule.classId,
          },
          parentId: internalId,
          startingBalance: splitAmount,
          description: amortizationSource.description,
          status: amortizationSource.status,
        } as AmortizationSource),
        calculateScheduleAsset(state?.subledger, {
          internalId: uuidv4(),
          sourceCreationDate,
          amortizationSchedule: {
            amortizationEndDate,
            amortizationStartDate,
            amortizationScheduleType,
            destinationId: amortizationSource.amortizationSchedule.destinationId,
            classId: amortizationSource.amortizationSchedule.classId,
          },
          parentId: internalId,
          startingBalance: roundTo(amortizationSource?.startingBalance
            ? amortizationSource?.startingBalance - splitAmount : 0, 2),
          description: amortizationSource.description,
          status: amortizationSource.status,
        } as AmortizationSource),
      ];
      amortizationSource.amortizationSchedule.amortizationStartDate = null;
      amortizationSource.amortizationSchedule.amortizationEndDate = null;
      amortizationSource.amortizationSchedule.amortizationScheduleType = state?.subledger?.account?.scheduleType!;
      return {
        ...state,
        subledger: {
          ...state.subledger,
          amortizationSources: newAssets,
        },
      };
    }
    case ADD_SPLIT_ASSET:
    {
      const { internalId }: Payload = action.payload;
      const amortizationSource = state?.subledger?.amortizationSources
        ?.find((asset: AmortizationSource) => asset.internalId === internalId);

      const lastChildAmortizationSource = [...state.subledger.amortizationSources]
        ?.reverse().find((asset: AmortizationSource) => asset.parentId === internalId);

      if (!amortizationSource || !lastChildAmortizationSource) {
        return state;
      }

      amortizationSource.amortizationSchedule.amortizationScheduleDetails = null;
      amortizationSource.amortizationSchedule.amortizationStartDate = null;
      amortizationSource.amortizationSchedule.amortizationEndDate = null;
      amortizationSource.amortizationSchedule.amortizationScheduleType = state?.subledger?.account?.scheduleType!;
      const sourceCreationDate = amortizationSource.sourceCreationDate
        ?? startOfDay(state?.subledger?.factaStartDate ?? new Date());
      const newSplitAssetInternalId = uuidv4();
      const newSplitAsset = calculateScheduleAsset(state?.subledger, {
        internalId: newSplitAssetInternalId,
        sourceCreationDate,
        amortizationSchedule: {
          amortizationEndDate: lastChildAmortizationSource.amortizationSchedule.amortizationEndDate,
          amortizationStartDate: lastChildAmortizationSource.amortizationSchedule.amortizationStartDate,
          amortizationScheduleType: lastChildAmortizationSource.amortizationSchedule.amortizationScheduleType,
          destinationId: lastChildAmortizationSource.amortizationSchedule.destinationId,
          classId: lastChildAmortizationSource.amortizationSchedule.classId,
        },
        parentId: internalId,
        startingBalance: 0,
        description: lastChildAmortizationSource.description,
        status: amortizationSource.status,
      } as AmortizationSource);

      const { remainingBalance } = getRemainingBalanceForSplitAssets([
        ...state?.subledger?.amortizationSources,
        newSplitAsset,
      ], newSplitAssetInternalId);

      if (remainingBalance) {
        newSplitAsset.startingBalance = remainingBalance;
      }

      const newAssets = [...state?.subledger?.amortizationSources, newSplitAsset] as Array<AmortizationSource>;

      return {
        ...state,
        subledger: {
          ...state.subledger,
          amortizationSources: newAssets,
        },
        isSplitAssetsSumCorrect: true,
      };
    }
    case IMPORT_CSV:
    {
      const { assets }: Payload = action.payload;
      const allAssets = [...state?.subledger?.amortizationSources, ...assets];
      const updatedAssets = assets
        ?.map((item: AmortizationSource) => calculateScheduleAsset(state?.subledger, item, allAssets));
      const modifiedAllAssets = [...state?.subledger?.amortizationSources, ...updatedAssets];
      return {
        ...state,
        subledger: {
          ...state?.subledger,
          amortizationSources: [...modifiedAllAssets],
          openingBalance: getAssetSum(state?.subledger, modifiedAllAssets),
        },
      };
    }
    default:
      throw new Error();
  }
};

export default reducer;
