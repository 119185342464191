import {
  ERRORS_MAP,
  ProductCategoryAccountMap,
} from './errorsMap';
import {
  ProductCategory,
  QuickBookError,
} from '../interfaces/types';

const mapErrorMessage = (error: QuickBookError | string, productCategory?: ProductCategory) => {
  const mappedError = typeof error === 'object'
    ? ERRORS_MAP.filter(({ code, regex }) => code === error?.code && regex.test(error.detail))?.[0]
    : ERRORS_MAP.filter(({ regex }) => regex.test(error))?.[0];

  const mappedErrorMessage = mappedError?.shouldPrefixProductCategory && productCategory
    ? `${ProductCategoryAccountMap[productCategory]} ${mappedError.customErrorMessage}`
    : mappedError?.customErrorMessage;

  // eslint-disable-next-line no-console
  console.log(typeof error === 'object' ? error?.detail : error);

  return mappedErrorMessage || (error as QuickBookError).detail || error as string;
};

export default mapErrorMessage;
