import React from 'react';
import Subledgers from './Subledgers';
import Layout from '../Layout';
import { ProductCategory } from '../../interfaces/types';

interface Props {
  category: ProductCategory;
}

const Dashboard = ({ category }: Props) => <Subledgers category={category} />;

const DashboardContainer = ({ category }: Props) => (
  <Layout title="Dashboard">
    <Dashboard category={category} />
  </Layout>
);

export default DashboardContainer;
