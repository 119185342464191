import React, {
  useEffect,
  useState,
} from 'react';
import {
  Box,
  makeStyles,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { formatDistanceToNow } from 'date-fns';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    width: '100%',
    height: '36px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: '#b9b9b9',
    '& svg': {
      margin: '8px 11px',
    },
    '& strong': {
      marginLeft: '5px',
    },
  },
}));
interface Props {
  isSaving: boolean;
  lastSaved?: Date;
}

export const Autosaved = ({
  isSaving,
  lastSaved,
}: Props) => {
  const [autosaved, setAutosaved] = useState<string | undefined>(undefined);
  const classes = useStyles();

  const formatDate = (date: Date) => formatDistanceToNow(date, {
    addSuffix: true,
  });

  useEffect(() => {
    lastSaved && setAutosaved(formatDate(lastSaved));
    const handler = setInterval(() => lastSaved && setAutosaved(formatDate(lastSaved)), 60000);
    return () => clearInterval(handler);
  }, [lastSaved]);

  return (
    <Box className={classes.container}>
      {isSaving && (
        <>
          <span>Autosaving...</span>
          <SaveIcon />
        </>
      )}
      {!isSaving && lastSaved && (
        <>
          <span>
            Autosaved:
            <strong>
              {autosaved}
            </strong>
          </span>
          <CheckCircleOutlineIcon />
        </>
      )}
    </Box>
  );
};
