/* eslint-disable no-param-reassign */
import axios from 'axios';
import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit';
import {
  CompanyUser,
  NotifierType,
  User,
} from '../../interfaces/types';
import { openSnackbar } from '../../components/Notifier';
import mapErrorMessage from '../../util/errorMessage';

const PREFIX = 'settings';
const GET_ME = `${PREFIX}/getMe`;
const PUT_ME = `${PREFIX}/putMe`;
const GET_COMPANY_USERS = `${PREFIX}/getCompanyUsers`;
const POST_INVITE_COMPANY_USER = `${PREFIX}/postInviteCompanyUser`;
const DELETE_COMPANY_USER = `${PREFIX}/deleteCompanyUser`;

interface State {
  isFetchingCompanyUsers: boolean;
  isFetchingMe: boolean;
  me?: User;
  companyUsers: Array<CompanyUser>;
  error?: any;
}

const initialState: State = {
  isFetchingMe: false,
  isFetchingCompanyUsers: false,
  companyUsers: [],
};

export const getMe = createAsyncThunk<User>(
  GET_ME,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<User>('/user/me');

      return data;
    } catch (error) {
      const handledError = mapErrorMessage(error.response?.data || error.message);
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const putMe = createAsyncThunk<User, Partial<User>>(
  PUT_ME,
  async (user, { rejectWithValue }) => {
    try {
      const { data } = await axios.put<User>('/user/me', user);

      return data;
    } catch (error) {
      const handledError = mapErrorMessage(error.response?.data || error.message);
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const getCompanyUsers = createAsyncThunk<Array<CompanyUser>, {
  accountCompanyId: string;
}>(
  GET_COMPANY_USERS,
  async ({ accountCompanyId }, { rejectWithValue }) => {
    try {
      const { data } = await axios
        // .get<Array<CompanyUser>>(`/user/company-users/${accountCompanyId}`);
        .post<Array<CompanyUser>>('/user/company-users/get', { companyId: accountCompanyId });

      return data;
    } catch (error) {
      const handledError = mapErrorMessage(error.response?.data || error.message);
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const postInviteCompanyUser = createAsyncThunk<null, {
  accountCompanyId: string;
  email: string;
}>(
  POST_INVITE_COMPANY_USER,
  async ({ accountCompanyId, email }, { rejectWithValue }) => {
    try {
      await axios.post('/user/invite', { companyId: accountCompanyId, email });

      return null;
    } catch (error) {
      const handledError = mapErrorMessage(error.response?.data || error.message);
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const deleteCompanyUsers = createAsyncThunk<null, {
  companyUserId: string;
}>(
  DELETE_COMPANY_USER,
  async ({ companyUserId }, { rejectWithValue }) => {
    try {
      await axios.delete(`user/${companyUserId}`);

      return null;
    } catch (error) {
      const handledError = mapErrorMessage(error.response?.data || error.message);
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const settingsSlice = createSlice({
  initialState,
  name: PREFIX,
  reducers: {
    clearSettingsData: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getCompanyUsers.pending, (state) => {
      state.isFetchingCompanyUsers = true;
    });
    builder.addCase(getCompanyUsers.fulfilled, (state, { payload }) => {
      state.companyUsers = payload;
      state.isFetchingCompanyUsers = false;
    });
    builder.addCase(getCompanyUsers.rejected, (state, { payload }) => {
      state.isFetchingCompanyUsers = false;
      state.error = payload;
    });

    builder.addMatcher(isAnyOf(getMe.pending, putMe.pending), (state) => {
      state.isFetchingMe = true;
    });
    builder.addMatcher(isAnyOf(getMe.fulfilled, putMe.fulfilled), (state, { payload }) => {
      state.me = payload;
      state.isFetchingMe = false;
    });
    builder.addMatcher(isAnyOf(getMe.rejected, putMe.rejected), (state, { payload }) => {
      state.isFetchingMe = false;
      state.error = payload;
    });
  },
});

export const {
  clearSettingsData,
} = settingsSlice.actions;

export const settingsReducer = settingsSlice.reducer;
