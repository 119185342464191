import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import COLORS from '../../theme/colors';

interface IInputError {
  errorMessage: string;
}

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: 500,
    fontSize: 10,
    lineHeight: '16px',
    letterSpacing: '0.02em',
    margin: '0 10px 4px',
    color: COLORS.error,
  },
}));

export const InputError = ({
  errorMessage,
}: IInputError) => {
  const classes = useStyles();

  return (
    <Typography className={classes.label}>
      {errorMessage}
    </Typography>
  );
};
