import { createSelector } from '@reduxjs/toolkit';
import {
  compareAsc,
  parseISO,
} from 'date-fns';
import {
  filterSubledgersNestedAssets,
  filterUnfinalizedFactaAssets,
} from '../../util/middleware';
import { RootState } from '../store';

const v2SubledgersSelector = (state: RootState) => state.v2Subledgers;

export const subledgersListSelector = createSelector(
  v2SubledgersSelector,
  (v2Subledgers) => v2Subledgers.subledgers.subledgers,
);

export const subledgersFinalizedListSelector = createSelector(
  v2SubledgersSelector,
  (v2Subledgers) => filterSubledgersNestedAssets(
    v2Subledgers.subledgers.subledgers.filter(({ finalized }) => finalized),
  )
  ,
);

export const currentPeriodSelector = createSelector(
  v2SubledgersSelector,
  (v2Subledgers) => v2Subledgers.subledgers.currentPeriodEnd,
);

export const latestPeriodSelector = createSelector(
  v2SubledgersSelector,
  (v2Subledgers) => v2Subledgers.subledgers.subledgers
    .filter(({ finalized }) => finalized)
    .map((sub) => sub.latestPeriodEnd)
    .sort((a, b) => compareAsc(parseISO(b), parseISO(a)))
    .at(0),
);

export const isFetchingSubledgers = createSelector(
  v2SubledgersSelector,
  (v2Subledgers) => v2Subledgers.isFetchingSubledgers,
);

export const v2SubledgerSelector = createSelector(
  v2SubledgersSelector,
  (v2Subledgers) => v2Subledgers.subledger?.subledger,
);

export const v2SubledgerSelectorFilteredUnfinalized = createSelector(
  v2SubledgersSelector,
  (v2Subledgers) => (v2Subledgers.subledger?.subledger
    && filterUnfinalizedFactaAssets(v2Subledgers.subledger?.subledger)
  ),
);

export const isFetchingV2Subledger = createSelector(
  v2SubledgersSelector,
  (v2Subledgers) => v2Subledgers.isFetchingSubledger,
);

export const isUpdatingV2Subledger = createSelector(
  v2SubledgersSelector,
  (v2Subledgers) => v2Subledgers.isUpdatingSubledger,
);

export const fixedAssetsListSelector = createSelector(
  v2SubledgersSelector,
  (v2Subledgers) => v2Subledgers.subledger?.subledger.fixedAssets || [],
);

export const fixedAssetsListSelectorWithoutFactaUnfinalized = createSelector(
  v2SubledgersSelector,
  (v2Subledgers) => filterUnfinalizedFactaAssets(v2Subledgers.subledger?.subledger!).fixedAssets || [],
);

export const fixedAssetsListWithoutQBOSelector = createSelector(
  v2SubledgersSelector,
  (v2Subledgers) => v2Subledgers
    .subledger?.subledger.fixedAssets?.filter((asset) => !asset.transaction) || [],
);

export const isFetchingFixedAssets = createSelector(
  v2SubledgersSelector,
  (v2Subledgers) => v2Subledgers.isFetchingFixedAssets,
);

export const isUpdatingSilentlyFixedAssets = createSelector(
  v2SubledgersSelector,
  (v2Subledgers) => v2Subledgers.isUpdatingSilently,
);

export const updatedAtFixedAssets = createSelector(
  v2SubledgersSelector,
  (v2Subledgers) => v2Subledgers.updatedAt,
);

export const isWritingOffFixedAsset = createSelector(
  v2SubledgersSelector,
  (v2Subledgers) => v2Subledgers.isWritingOffFixedAsset,
);

export const subledgerTouchedSelector = createSelector(
  v2SubledgersSelector,
  (v2Subledgers) => v2Subledgers.touched,
);
