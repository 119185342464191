import { createSelector } from '@reduxjs/toolkit';
import { TagCategories } from '../../interfaces/tags';
import { RootState } from '../store';

const tagsSelector = (state: RootState) => state.tags;

export const tagsListSelector = createSelector(
  tagsSelector,
  (tags) => tags.tags,
);

export const tagsClassListSelector = createSelector(
  tagsSelector,
  (tags) => tags.tags
    .filter((tag) => tag.category === TagCategories.CLASS),
);

export const tagsLocationListSelector = createSelector(
  tagsSelector,
  (tags) => tags.tags
    .filter((tag) => tag.category === TagCategories.LOCATION),
);

export const isFetchingTags = createSelector(
  tagsSelector,
  (tags) => tags.isFetchingTags,
);
