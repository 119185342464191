export type Nullable<T> = T | null;

export enum ProductTypes {
  FIXED_ASSETS = 'fixed_assets',
  PREPAID = 'prepaid',
  DEF_REV = 'defrev',
}

export enum SchedulingMethod {
  PERIODIC = 'periodic',
  DAILY = 'daily',
  MANUAL = 'manual',
}

export interface SchedulingMethodOption {
  value: SchedulingMethod;
  label: string;
}

export enum SchedulerTab {
  UNSCHEDULED = 'unscheduled',
  SCHEDULED = 'scheduled',
  WRITEOFFS = 'writeOffs',
}

export enum ImportTab {
  ALL = 'all',
  ERRORS = 'errors',
}
