/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ReportsIcon = (props: any) => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 7C6.5 6.17157 7.17157 5.5 8 5.5H16C16.8284 5.5 17.5 6.17157 17.5 7V9.83682C18.6825 10.3985 19.5 11.6038 19.5 13C19.5 13.7862 19.2408 14.5118 18.8032 15.0961L21.3536 17.6464L20.6464 18.3536L18.0961 15.8032C17.9108 15.942 17.7112 16.0629 17.5 16.1632V17C17.5 17.8284 16.8284 18.5 16 18.5H8C7.17157 18.5 6.5 17.8284 6.5 17V7ZM16.5 16.4646C16.3367 16.4879 16.1698 16.5 16 16.5C14.2368 16.5 12.7781 15.1961 12.5354 13.5H9V12.5H12.5354C12.5859 12.1469 12.6891 11.8109 12.8368 11.5H9V10.5H13.5505C14.1818 9.88141 15.0463 9.5 16 9.5C16.1698 9.5 16.3367 9.51209 16.5 9.53544V7C16.5 6.72386 16.2761 6.5 16 6.5H8C7.72386 6.5 7.5 6.72386 7.5 7V17C7.5 17.2761 7.72386 17.5 8 17.5H16C16.2761 17.5 16.5 17.2761 16.5 17V16.4646ZM13.9998 11.5H14V11.4998C14.4561 10.8927 15.1822 10.5 16 10.5C17.3807 10.5 18.5 11.6193 18.5 13C18.5 14.3807 17.3807 15.5 16 15.5C14.6193 15.5 13.5 14.3807 13.5 13C13.5 12.4372 13.686 11.9178 13.9998 11.5ZM9 7.5H15V8.5H9V7.5ZM9 14.5H11V15.5H9V14.5Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default ReportsIcon;
