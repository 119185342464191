import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { relevantDateInfo } from '../../util/helpers';

const subledgerState = (state: RootState) => state.subledger;

export const isFetchingSubledger = createSelector(
  subledgerState,
  (subledger) => subledger.isFetchingSubledger,
);

export const isFetchingBalanceData = createSelector(
  subledgerState,
  (subledger) => subledger.isFetchingBalanceData,
);

export const isUpdatingSubledger = createSelector(
  subledgerState,
  (subledger) => subledger.isUpdating,
);

export const fetchingSubledgerError = createSelector(
  subledgerState,
  (subledger) => subledger.errorFetchingSubledger,
);

export const fetchingBalanceDataError = createSelector(
  subledgerState,
  (subledger) => subledger.errorFetchingBalanceData,
);

// Probably to be moved to JE slice
export const isFetchingJournalEntry = createSelector(
  subledgerState,
  (subledger) => subledger.isFetchingJournalEntry,
);

export const fetchingJournalEntryError = createSelector(
  subledgerState,
  (subledger) => subledger.errorFetchingJournalEntry,
);

export const subledgerStartEndDateSelector = createSelector(
  subledgerState,
  (subledger) => relevantDateInfo(subledger.subledger?.subledgerAmortizationLogs),
);
