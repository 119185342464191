import React from 'react';
import { getChildren } from './common';
import PrepareJERow from './PrepareJEPrepareExpenseRow';
import {
  AmortizationScheduleDetails,
  AmortizationSource,
  Subledger,
} from '../../interfaces/types';

interface Props {
  amortizationSource: AmortizationSource;
  subledger: Subledger;
  scheduleDate: string;
  startDate: Date;
  endDate: Date;
  collapses: Array<string>;
  onInputBoxChange: (lastDay: string, asset: AmortizationSource, schedule?: AmortizationScheduleDetails) =>
  (event: React.ChangeEvent<HTMLInputElement>) => void;
  toggleSplit: (internalId: string) => () => void;
  isCollapsedView?: boolean;
}

const PrepareJEPrepareExpenseTableRow = ({
  amortizationSource,
  subledger,
  scheduleDate,
  startDate,
  endDate,
  collapses,
  onInputBoxChange,
  toggleSplit,
  isCollapsedView,
}: Props) => {
  const children = getChildren(amortizationSource.internalId, subledger?.amortizationSources);
  const parent = (
    <PrepareJERow
      amortizationSource={amortizationSource}
      amortizationSources={subledger?.amortizationSources}
      scheduleDate={scheduleDate}
      endDate={endDate!}
      startDate={startDate}
      hasChildren={!!children?.length}
      lastChild={false}
      collapses={collapses}
      onInputBoxChange={onInputBoxChange}
      toggleSplit={toggleSplit}
      isCollapsedView={isCollapsedView}
    />
  );
  if (!children?.length || collapses.includes(amortizationSource.internalId)) {
    return parent;
  }
  return (
    <>
      {parent}
      {
        children
          ?.map((child: AmortizationSource, childIndex: number) => (
            <PrepareJERow
              key={child.id}
              amortizationSource={child}
              amortizationSources={subledger?.amortizationSources}
              scheduleDate={scheduleDate}
              endDate={endDate!}
              startDate={startDate}
              hasChildren={false}
              lastChild={children?.length - 1 === childIndex}
              collapses={collapses}
              onInputBoxChange={onInputBoxChange}
              toggleSplit={toggleSplit}
              isCollapsedView={isCollapsedView}
            />
          ))
      }
    </>
  );
};

export default React.memo(PrepareJEPrepareExpenseTableRow);
