/* eslint-disable react/jsx-props-no-spreading,max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const AccountIcon = (props: any) => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.72852 12.7448H13.0921V13.8378H8.72852V12.7448Z"
      fill="inherit"
    />
    <path
      d="M8.72852 10.0113H15.274V11.1043H8.72852V10.0113Z"
      fill="inherit"
    />
    <path
      d="M8.72852 15.4766H11.4558V16.5696H8.72852V15.4766Z"
      fill="inherit"
    />
    <path
      d="M16.9091 5.63952H15.2727V5.09301C15.2727 4.80313 15.1578 4.52511 14.9532 4.32014C14.7486 4.11516 14.4711 4 14.1818 4H9.81818C9.52885 4 9.25138 4.11516 9.04679 4.32014C8.84221 4.52511 8.72727 4.80313 8.72727 5.09301V5.63952H7.09091C6.80158 5.63952 6.5241 5.75467 6.31952 5.95965C6.11493 6.16463 6 6.44264 6 6.73253V18.2091C6 18.499 6.11493 18.777 6.31952 18.982C6.5241 19.187 6.80158 19.3021 7.09091 19.3021H16.9091C17.1984 19.3021 17.4759 19.187 17.6805 18.982C17.8851 18.777 18 18.499 18 18.2091V6.73253C18 6.44264 17.8851 6.16463 17.6805 5.95965C17.4759 5.75467 17.1984 5.63952 16.9091 5.63952ZM9.81818 5.09301H14.1818V7.27903H9.81818V5.09301ZM16.9091 18.2091H7.09091V6.73253H8.72727V8.37204H15.2727V6.73253H16.9091V18.2091Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default AccountIcon;
