import React, {
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import { Box } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import {
  endOfMonth,
  isWithinInterval,
  parse,
  startOfMonth,
} from 'date-fns';
import {
  AmortizationSource,
  AmortizationScheduleDetails,
  ProductCategory,
} from '../../../interfaces/types';
import {
  DAY_SHORT_FORMAT,
  HISTORICAL_MANUAL_ASSET,
  INITIALED_WITH_LOCAL_STORAGE,
} from '../../../util/constants';
import { getPrepareJEStartEndDate } from '../../../components/Subledger/common';
import ScheduleHeader from './ScheduleHeader';
import reducer from '../../../components/Subledger/reducer';
import {
  deleteSubledger,
  getSubledger,
  setSubledger,
} from '../../../util/subledger';
import {
  isPostJEEnabled,
  prepareJEInputChange,
} from '../../../components/Subledger/prepreJECommon';
import isEmpty from '../../../util/isEmpty';
import DialogBox from '../../../components/DialogBox';
import PrepareJEPrepaidExpenseTableHeader from '../../../components/Subledger/PrepareJEPrepaidExpenseTableHeader';
import PrepareJERevSyncTableHeader from '../../../components/Subledger/PrepareJERevSyncTableHeader';
import PrepareJEPrepareExpenseTableRow from '../../../components/Subledger/PrepareJEPrepareExpenseTableRow';
import PrepareJERevSyncTableRow from '../../../components/Subledger/PrepareJERevSyncTableRow';
import useStickyColumns from '../../../hooks/useStickyColumns';
import useCollapsedView from '../../../hooks/useCollapsedView';
import {
  ScrollableBoxContainer,
  ScrollableTableContainer,
} from '../../../components/ScrollableTable';
import { Loader } from '../../../components/Loader';

interface Props {
  scheduleDate: string;
}

const PrepareJE = ({ scheduleDate }: Props) => {
  const history = useHistory();
  const [collapses, setCollapses] = useState<Array<string>>([]);
  const [openSubledgerDialog, setOpenSubledgerDialog] = useState<boolean>(false);
  const tableRef: React.MutableRefObject<HTMLTableElement | null> = useRef(null);
  const [tableRefState, setTableRefState] = useState(false);

  // @ts-ignore
  const [state, dispatch] = useReducer(reducer, {
    subledger: {},
    selectedRow: '',
  });

  const [
    isCollapsedView,
    isCollapsedViewHovered,
    ToggleExpandedView,
  ] = useCollapsedView(false, 'expanded view');

  useStickyColumns(tableRef, [tableRefState, isCollapsedView, state.subledger]);

  useEffect(() => {
    const {
      subledger: localSubledger,
      historicalUpdatedAssets: localHistoricalPrepaidAssets,
    } = getSubledger();
    dispatch({
      type: INITIALED_WITH_LOCAL_STORAGE,
      payload: {
        subledger: localSubledger,
        historicalUpdatedAssets: localHistoricalPrepaidAssets,
        scheduleDate,
      },
    });
  }, [scheduleDate]);

  if (isEmpty(state.subledger)) {
    return <Loader open />;
  }

  const {
    subledger,
    historicalUpdatedAssets,
  } = state;

  const {
    startDate,
    endDate,
  } = getPrepareJEStartEndDate(subledger, subledger?.amortizationSources, scheduleDate);

  const toggleSplit = (internalId: string) => () => {
    const exists = collapses.includes(internalId);
    let updateCollapse: Array<string> = [];
    if (exists) {
      updateCollapse = collapses?.filter((item: string) => item !== internalId);
    } else {
      updateCollapse.push(internalId);
    }
    setCollapses(updateCollapse);
  };

  const activePrepaidAssets = subledger?.amortizationSources
    ?.filter((subledgerAmortizationSource: AmortizationSource) => isWithinInterval(
      subledgerAmortizationSource.sourceCreationDate!, {
        start: startOfMonth(parse(scheduleDate, DAY_SHORT_FORMAT, new Date())),
        end: endOfMonth(parse(scheduleDate, DAY_SHORT_FORMAT, new Date())),
      },
    ));
  const postJEEnabled = isPostJEEnabled(activePrepaidAssets, scheduleDate);

  const onInputBoxChange = (lastDay: string, amortizationSource: AmortizationSource,
    schedule?: AmortizationScheduleDetails) => (event: React.ChangeEvent<HTMLInputElement>) => {
    prepareJEInputChange({
      number: event.target.value,
      amortizationSource,
      schedule,
      lastDay,
      amortizationSources: subledger?.amortizationSources,
      dispatch,
    });
    dispatch({
      type: HISTORICAL_MANUAL_ASSET,
      payload: { internalId: amortizationSource.internalId },
    });
  };

  const renderRow = (amortizationSource: AmortizationSource) => {
    if (subledger?.productCategory === ProductCategory.PrepaidExpense) {
      return (
        <PrepareJEPrepareExpenseTableRow
          startDate={startDate}
          endDate={endDate}
          scheduleDate={scheduleDate}
          amortizationSource={amortizationSource}
          subledger={subledger}
          onInputBoxChange={onInputBoxChange}
          collapses={collapses}
          toggleSplit={toggleSplit}
          isCollapsedView={isCollapsedView}
        />
      );
    }
    if (subledger?.productCategory === ProductCategory.DeferredRevenue) {
      return (
        <PrepareJERevSyncTableRow
          startDate={startDate}
          endDate={endDate}
          scheduleDate={scheduleDate}
          amortizationSource={amortizationSource}
          subledger={subledger}
          onInputBoxChange={onInputBoxChange}
          collapses={collapses}
          toggleSplit={toggleSplit}
          isCollapsedView={isCollapsedView}
        />
      );
    }
    return null;
  };

  const navigateToSchedulePrepaid = () => {
    setSubledger({
      subledger,
      historicalUpdatedAssets,
    });
    history.push(`/historical/subledgers/schedule/${subledger.id}/?scheduleDate=${scheduleDate}&fromPrepareJE=true`);
  };

  const onPostJE = async () => {
    setSubledger({
      subledger,
      historicalUpdatedAssets,
    });
    history.push(`/historical/subledgers/schedule/${subledger.id}/post-je?scheduleDate=${scheduleDate}`);
  };

  const closeDialog = () => {
    setOpenSubledgerDialog(false);
  };

  const onCancel = () => {
    if (historicalUpdatedAssets?.length > 0) {
      setOpenSubledgerDialog(true);
    } else {
      navigateBack();
    }
  };

  const navigateBack = () => {
    deleteSubledger();
    setOpenSubledgerDialog(false);
    history.push(`/journal-entries/?account_id=${subledger?.account?.id}`);
  };

  return (
    <>
      <DialogBox
        openDialog={openSubledgerDialog}
        closeDialog={closeDialog}
        dialogContext={'Are you sure you want to navigate away from this page?'
        + ' \n If you press "Yes" now, ALL your changes will be lost!'}
        dialogTitle="Alert"
        dismissContext="Cancel"
        actions={[{
          title: 'YES',
          event: navigateBack,
        }]}
      />
      <ScheduleHeader
        subledger={subledger}
        scheduleDate={scheduleDate}
        postJEEnabled={postJEEnabled}
        onPostJE={onPostJE}
        onCancel={onCancel}
        navigateToSchedulePrepaid={navigateToSchedulePrepaid}
      />
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="flex-end"
        padding="0 16px"
      >
        <ToggleExpandedView />
      </Box>
      <ScrollableBoxContainer>
        <ScrollableTableContainer>
          <Table
            ref={(el) => {
              setTableRefState(true);
              tableRef.current = el;
            }}
            size="small"
            stickyHeader
            aria-label="Prepare JE Edit"
          >
            {subledger.productCategory === ProductCategory.PrepaidExpense && (
              <PrepareJEPrepaidExpenseTableHeader
                startDate={startDate}
                endDate={endDate!}
                scheduleDate={scheduleDate}
                amortizationSources={subledger?.amortizationSources}
                isCollapsedView={isCollapsedView}
                isCollapsedViewHovered={isCollapsedViewHovered}
              />
            )}
            {subledger.productCategory === ProductCategory.DeferredRevenue && (
              <PrepareJERevSyncTableHeader
                startDate={startDate}
                endDate={endDate!}
                scheduleDate={scheduleDate}
                amortizationSources={subledger?.amortizationSources}
                isCollapsedView={isCollapsedView}
                isCollapsedViewHovered={isCollapsedViewHovered}
              />
            )}
            <TableBody>
              {
                subledger?.amortizationSources
                  ?.filter((asset: AmortizationSource) => !asset.parentId)
                  .map(renderRow)
              }
            </TableBody>
          </Table>
        </ScrollableTableContainer>
      </ScrollableBoxContainer>
    </>
  );
};

export default PrepareJE;
