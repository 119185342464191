import { AccountClassification } from '../interfaces/types';

export const AUTH_TOKEN_KEY = 'AUTH_TOKEN_KEY';
export const SUBLEDGER_STORAGE_KEY = 'SUBLEDGER_STORAGE_KEY';
export const PREPAID_EXPENSES = 'PrepaidExpenses';
export const DEFERRED_REVENUE_ACCOUNTS = [
  'OtherCurrentLiabilities',
  'OtherLongTermLiabilities',
  'DeferredRevenue',
];
export const FIXED_ASSETS = 'Fixed Asset';
export const FIXED_ASSETS_ACCUMULATED_ACCOUNTS = [
  'AccumulatedAmortization',
  'AccumulatedDepletion',
  'AccumulatedDepreciation',
];

export const DEFAULT_PAGE_SIZE = 100;
export const DEFAULT_PAGINATION_OPTIONS = [20, 50, 100, 200];

export const ASSET_CELL = 80;
export const DESCRIPTION_CELL = 330;
export const CUSTOMER_CELL = 300;
export const VIRTUALISED_CELL = 300;
export const PRODUCT_CELL = 180;
export const EXPENSE_ACCOUNT_CELL = 235;
export const CLASSES_CELL = 127;
export const REMAINING_BALANCE = 100;
export const DATEPICKER_CELL = 140;
export const SCHEDULE_TYPE_CELL = 130;
export const AMORTIZAION_ROW_CELL = 100;
export const CURRENCY_CELL = 100;
export const REVSYNC_HEADER_CELL_100 = 100;
export const REVSYNC_HEADER_CELL_80 = 80;
export const ACTION_CELL = 120;
export const ACTION_CELL_WITH_SAVE = 170;
export const PREPAID_AMOUNT = 200;
export const ASSET_AMOUNT = 150;
export const FINALIZED_SUBLEDGER_STATUS = 'FINALIZED';
export const ASSET_SCHEDULED_STATUS = 'SCHEDULED';
export const ASSET_TEMP_SCHEDULED_STATUS = 'TEMP_SCHEDULED';
export const AMORTIZATION_POSTED = 'POSTED';

export const FACTA_SOURCE = 'FACTA';
export const QUICKBOOK_SOURCE = 'QUICKBOOK';

export const DAY_FORMAT = 'd';
export const DAY_MONTH_FORMAT = 'L/d';
export const DAY_SHORT_FORMAT = 'L/d/yy';
export const FULL_DAY_FORMAT = 'LL/dd/yyyy';
export const CSV_IMPORT_DAY_FORMAT = 'L/d/yyyy';
export const MONTH_SHORT_FORMAT = 'MMM yyyy';
export const MONTH_LONG_FORMAT = 'MMMM yyyy';
export const FULL_DATE_FORMAT = 'MM/dd/yyyy';
export const MONTH_SHOW_FORMAT = 'MMM';
export const YEAR_FORMAT = 'yyyy';
export const MONTH_YEAR_FORMAT = 'MM/yyyy';
export const DATE_DESCRIPTIVE_FORMAT = 'LL/dd/yyyy \'at\' HH:mm';

export const VISITED_TIME = 100;
export const LLAMA_ANIMATION_AUTO_CLOSE_TIME = 5000;

export const DIGIT_UPPER_LIMIT = 100000000000;
export const DIGIT_LOWER_LIMIT = -100000000000;

export const DEBIT = 'Debit';
export const CREDIT = 'Credit';
export const INPUT_CHANGE = 'INPUT_CHANGE';
export const INPUT_FOCUS = 'INPUT_FOCUS';
export const SELECT_CHANGE = 'SELECT_CHANGE';
export const DATE_CHANGE = 'DATE_CHANGE';
export const UNSCHEDULED_DATE_CHANGE = 'UNSCHEDULED_DATE_CHANGE';
export const UNSCHEDULED_SELECT_CHANGE = 'UNSCHEDULED_SELECT_CHANGE';
export const UNSCHEDULED_INPUT_CHANGE = 'UNSCHEDULED_INPUT_CHANGE';
export const PREPARE_JE_UPDATE_ASSETS = 'PREPARE_JE_UPDATE_ASSETS';
export const ADD_ASSET = 'ADD_ASSET';
export const REMOVE_ASSET = 'REMOVE_ASSET';
export const UNSCHEDULED_REMOVE_ASSET = 'UNSCHEDULED_REMOVE_ASSET';
export const ADD_SPLIT = 'ADD_SPLIT';
export const ADD_SPLIT_ASSET = 'ADD_SPLIT_ASSET';
export const IMPORT_CSV = 'IMPORT_CSV';
export const SUBLEDGER_AMORTIZATION_SCHEDULE_CHANGE = 'SUBLEDGER_AMORTIZATION_SCHEDULE_CHANGE';
export const SUBLEDGER_START_DATE_CHANGE = 'SUBLEDGER_START_DATE_CHANGE';
export const INITIALED = 'INITIALED';
export const INITIALED_WITHOUT_MODIFICATION = 'INITIALED_WITHOUT_MODIFICATION';
export const INITIALED_WITH_LOCAL_STORAGE = 'INITIALED_WITH_LOCAL_STORAGE';
export const INITIALED_FOR_PREPARE_JE = 'INITIALED_FOR_PREPARE_JE';
export const SELECT_ROW = 'SELECT_ROW';
export const HOVER_ROW = 'HOVER_ROW';
export const CALCULATE_ASSET = 'CALCULATE_ASSET';
export const HISTORICAL_UPDATE_ASSET = 'HISTORICAL_UPDATE_ASSET';
export const HISTORICAL_UPDATE_ASSETS = 'HISTORICAL_UPDATE_ASSETS';
export const HISTORICAL_MANUAL_ASSET = 'HISTORICAL_MANUAL_ASSET';
export const UPDATE_ASSET = 'UPDATE_ASSET';
export const BULK_EDIT = 'BULK_EDIT';
export const BULK_REMOVE_ASSETS = 'BULK_REMOVE_ASSETS';
export const UPDATE_ASSETS = 'UPDATE_ASSETS';

export const QUICKBOOK_ERRORS = {
  INVALID_REFERENCE_ID: '2500',
  BOOK_CLOSE_DATES: ['6200', '6210'],
};

export const MIN_SYSTEM_DATE = '1970-01-01';
export const STICKY_COLUMN_Z_INDEX = 200;
export const STICKY_COLUMN_HEADER_Z_INDEX = 201;
export const POPPER_ELEMENTS_Z_INDEX = 1301;
export const FREEZE_PANEL_Z_INDEX = 100;
export const TABLE_HEADER_Z_INDEX = 2;

export const NAVBAR_OPEN_WIDTH = 162;
export const NAVBAR_CLOSED_WIDTH = 52;

// environments
export const PRODUCTION = 'prod';
export const UAT = 'uat';
export const DEMO = 'demo';
export const DEXTER = 'dexter';
export const VEGETA = 'vegeta';

// virtualized auto complete
export const VIRTUALIZED_AUTO_COMPLETE_ITEM_SIZE = 60;
export const VIRTUALIZED_AUTO_COMPLETE_VIEW_PORT_ITEM_COUNT = 6;

export const STATUS_DISCONNECTED = 'disconnected';

export const PENDING_USER_STATUS = 'PENDING';

export const negativeBalanceAccounts = [
  AccountClassification.Equity,
  AccountClassification.Liability,
  AccountClassification.Revenue,
];

export const AUTOSAVE_DELAY = 2000; // miliseconds
export const NUMBER_OF_VISIBLE_PERIODS = 12;
export const SIDEBAR_EXPAND_DELAY = 300; // miliseconds
export const HIGHLIGHT_RESET = 5000; // miliseconds
export const ERROR_NOTICES_INTERVAL = 120000; // 2 minutes in miliseconds
