import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Box,
  Button,
  makeStyles,
  styled,
  Typography,
} from '@material-ui/core';
import {
  format,
  parseISO,
} from 'date-fns';
import { Link } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { Account } from '../../../../interfaces/accounts';
import COLORS from '../../../../theme/colors';
import currencyFormatter from '../../../../util/currencyFormatter';
import {
  NestedSubledgerDetails,
  V2Subledger,
} from '../../../../interfaces/subledgers';
import { isFetchingSubledgers } from '../../../../store/selectors/v2Subledgers';
import { LoadingBox } from '../../../../components/LoadingBox';
import { CheckFactaIcon } from '../../../../components/Icons';
import { MONTH_LONG_FORMAT } from '../../../../util/constants';
import { ClosedTag } from '../../../../components/Subledger/FixedAssets/ClosedTag';

const useStyles = makeStyles({
  disabledDashboardItem: {
    backgroundColor: '#e4e4e4',
    pointerEvents: 'none',
    filter: 'blur(1.5px)',
    opacity: 0.5,
  },
  amountLink: {
    color: COLORS.skyBlue,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  amountLinkClosed: {
    fontSize: '12px',
  },
  diagnoseLink: {
    color: COLORS.error,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  textLink: {
    padding: '0',
    margin: 0,
    fontSize: 'inherit',
    lineHeight: 'inherit',
    minHeight: 'auto',
    verticalAlign: 'inherit',
    '&:hover, &:active': {
      backgroundColor: 'inherit',
      textDecoration: 'underline',
    },
  },
});

const StyledDashboardItem = styled(Box)({
  border: `solid 1px ${COLORS.disabled}`,
  borderRadius: '4px',
  margin: '24px 24px 0 0',
  width: '380px',
  '& > div': {
    padding: 24,
  },
});

const AccountName = styled(Typography)({
  fontSize: 16,
  lineHeight: '24px',
  fontWeight: 600,
});

const AccountSubName = styled(Typography)({
  fontSize: 14,
  lineHeight: '24px',
});

const BalanceRow = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  lineHeight: '26px',
});

const GeneralBalanceRow = styled(BalanceRow)({
  '& span:first-of-type': {
    fontSize: 16,
    lineHeight: '26px',
    color: COLORS.lightGray,
  },
  '& span:last-of-type': {
    fontSize: 12,
    lineHeight: '26px',
  },
});

const ScheduleBalanceRow = styled(BalanceRow)({
  '& > span:last-of-type': {
    fontSize: 16,
    fontWeight: 600,
    color: COLORS.skyBlue,
    lineHeight: '26px',
  },
});

const StyledButton = styled(Button)({
  margin: '0 0 8px 0',
});

const CheckedBalanceInfo = styled(Box)({
  display: 'flex',
  '& span': {
    marginRight: 13,
  },
  '& svg': {
    color: COLORS.successGreen,
  },
});

const DisabledStateContainer = styled(Box)({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  inset: '6px',
  zIndex: 1,
});

const DisabledStateContent = styled(Box)({
  background: COLORS.white,
  padding: '12px 24px',
  borderRadius: '4px',
  fontSize: '14px',
  lineHeight: '22px',
  textAlign: 'center',
  pointerEvents: 'all',
  boxShadow: COLORS.inputBoxShadow,
});

interface Props {
  subledgerDetails: V2Subledger;
  subledgerAccount: Account;
  accumulationAccount: Account;
  selectedDate: string;
  setSelectedDate: (date: string) => void;
  additionalSubledgerDetails: NestedSubledgerDetails;
  accountBalance: number;
  isLoadingBalance: boolean;
}

export const DashboardItem = ({
  subledgerDetails,
  subledgerAccount,
  accumulationAccount,
  selectedDate,
  setSelectedDate,
  additionalSubledgerDetails,
  accountBalance,
  isLoadingBalance,
}: Props) => {
  const history = useHistory();
  const isLoadingSubledgers = useSelector(isFetchingSubledgers);
  const classes = useStyles();

  const schedulerUrl = `/fixed-assets/scheduler/${subledgerDetails.id}/${selectedDate}`;

  const {
    shouldSchedule,
    shouldPost,
    isSubledgerDisabled,
    isClosed,
    isOutOfPeriod,
    currentPeriodPostedValue,
    assetsScheduledValue,
    assetsUnpostedValue,
  } = additionalSubledgerDetails;

  return (
    <StyledDashboardItem
      hidden={!subledgerAccount.visible}
      position="relative"
    >
      {(isLoadingBalance || isLoadingSubledgers) && (
        <LoadingBox />
      )}
      {isSubledgerDisabled && (
        <DisabledStateContainer>
          <DisabledStateContent>
            You have unposted transactions in a
            {' '}
            <Button
              color="primary"
              variant="text"
              size="large"
              onClick={() => setSelectedDate(subledgerDetails.currentPeriodEnd)}
              className={classes.textLink}
              disableRipple
            >
              previous period
            </Button>
            {' '}
            for this account.
          </DisabledStateContent>
        </DisabledStateContainer>
      )}
      <Box className={clsx({ [classes.disabledDashboardItem]: isSubledgerDisabled })}>
        <Box
          display="flex"
          justifyContent="space-between"
        >
          <Box>
            <AccountName>{subledgerAccount.displayName}</AccountName>
            <AccountSubName>{accumulationAccount.displayName}</AccountSubName>
          </Box>
          <Box>
            {isClosed && (
              <ClosedTag>
                <span>Closed</span>
                <span><CheckFactaIcon /></span>
              </ClosedTag>
            )}
          </Box>
        </Box>
        {isOutOfPeriod && !isSubledgerDisabled && (
          <Alert
            elevation={0}
            severity="error"
            variant="outlined"
            style={{ margin: '16px 0' }}
          >
            <span>
              Out of period transaction in
              {' '}
              <Link
                to={`/fixed-assets/scheduler/${subledgerDetails.id}/${subledgerDetails.currentPeriodEnd}`}
                className={classes.diagnoseLink}
              >
                {format(parseISO(subledgerDetails.currentPeriodEnd), MONTH_LONG_FORMAT)}
              </Link>
            </span>
          </Alert>
        )}
        <Box margin="16px 0">
          <GeneralBalanceRow>
            <span>GL</span>
            <span>{currencyFormatter.format(accountBalance)}</span>
          </GeneralBalanceRow>
          <GeneralBalanceRow>
            <span>FACTA</span>
            <span>{currencyFormatter.format(assetsScheduledValue)}</span>
          </GeneralBalanceRow>
        </Box>
        <ScheduleBalanceRow>
          {shouldSchedule
            ? (
              <StyledButton
                variant="contained"
                color="secondary"
                onClick={() => history.push(schedulerUrl)}
              >
                Schedule Fixed Assets
              </StyledButton>
            )
            : (
              <CheckedBalanceInfo>
                <span>Fixed Assets Scheduled</span>
                <CheckFactaIcon />
              </CheckedBalanceInfo>
            )}
          <span>
            <Link
              className={clsx(classes.amountLink, {
                [classes.amountLinkClosed]: isClosed,
              })}
              to={schedulerUrl}
            >
              {currencyFormatter.format(accountBalance - assetsScheduledValue)}
            </Link>
          </span>
        </ScheduleBalanceRow>
        <BalanceRow>
          {shouldPost
            ? (
              <StyledButton
                variant="contained"
                color="secondary"
                onClick={() => history.push(
                  `/fixed-assets/scheduler/${subledgerDetails.id}/${selectedDate}/prepare-je`,
                )}
              >
                Post Depreciation
              </StyledButton>
            )
            : (
              <CheckedBalanceInfo>
                <span>Depreciation Unposted</span>
                {!shouldSchedule && <CheckFactaIcon />}
              </CheckedBalanceInfo>
            )}
          <span>
            {currencyFormatter.format(assetsUnpostedValue)}
          </span>
        </BalanceRow>
        <BalanceRow>
          <CheckedBalanceInfo>
            <span>Depreciation Posted</span>
            {(!shouldSchedule && !shouldPost) && <CheckFactaIcon />}
          </CheckedBalanceInfo>
          <span>
            {currencyFormatter.format(currentPeriodPostedValue)}
          </span>
        </BalanceRow>
      </Box>
    </StyledDashboardItem>
  );
};
