import {
  Box,
  TableCell,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React from 'react';
import { useSelector } from 'react-redux';
import { getAccountNameFromList } from '../common';
import { useTableStyles } from '../Subledger.styled';
import { AmortizationSource } from '../../../interfaces/types';
import { accountInfoSelector } from '../../../store/selectors/account';

interface Props {
  amortizationSource: AmortizationSource;
  hasChildren?: boolean;
  lastChild?: boolean;
  collapses: Array<string>;
  toggleSplit: (id: string) => () => void;
  isCellSticky?: string;
}

const ExpenseCellWithCollapseOption = ({
  amortizationSource,
  hasChildren,
  lastChild,
  collapses,
  toggleSplit,
  isCellSticky,
}: Props) => {
  const tableClasses = useTableStyles();
  const account = useSelector(accountInfoSelector)!;
  const { accountIncomes } = account;
  return (
    <TableCell
      className={clsx(tableClasses.expenseAccountCell, tableClasses.cursorPointer, {
        [tableClasses.child]: amortizationSource.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
      })}
      onClick={toggleSplit(amortizationSource.parentId ?? amortizationSource.internalId)}
      is-cell-sticky={isCellSticky}
    >
      {
        hasChildren
        && (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Typography className={tableClasses.fontSize13}>Multiple</Typography>
            {
              collapses.includes(amortizationSource.internalId) && (
                <ArrowRightIcon />
              )
            }
            {
              !collapses.includes(amortizationSource.internalId) && (
                <ArrowDropDownIcon />
              )
            }
          </Box>
        )
      }
      {
        !hasChildren
        && getAccountNameFromList(amortizationSource?.amortizationSchedule?.destinationId!, accountIncomes)
      }
    </TableCell>
  );
};

export default React.memo(ExpenseCellWithCollapseOption);
