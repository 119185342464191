import React, {
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { path } from 'ramda';
import Table from '@material-ui/core/Table';
import {
  Box,
  Button,
  ClickAwayListener,
  createStyles,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  Account,
  ITableItemType,
  ITableType,
  SortOrder,
} from '../../interfaces/types';
import SortNoneIcon from '../Icons/SortNonecon';
import SortUpIcon from '../Icons/SortUpIcon';
import SortDownIcon from '../Icons/SortDownIcon';
import {
  ScrollableBoxContainer,
  ScrollableTableContainer,
} from '../ScrollableTable';
import { getGLBalance } from '../../util/pages/ChartOfAccounts/getGLBalance';
import { EmptyState } from './EmptyState/index';
import useStickyColumns from '../../hooks/useStickyColumns';
import { STICKY_COLUMN_HEADER_Z_INDEX } from '../../util/constants';
import COLORS from '../../theme/colors';
import { Pagination } from '../Pagination';

export interface ITableItem {
  [key: string]: any;
}

export interface IColProps {
  colName: string;
  varKey: string;
  colType: ITableItemType;
  sortKey?: Array<string | number>;
  child?: any;
  customHeader?: React.ReactNode;
  headerStyles?: React.CSSProperties;
  headerClassName?: string;
  sticky?: boolean;
  sortable?: boolean;
  alignRight?: boolean;
}

export interface ITableData {
  data: Array<ITableItem>;
}

interface IActionData {
  label: string;
  icon: React.ReactNode;
  isData: boolean;
  action: any;
  pushRight?: boolean;
  iconPosition?: string;
  isDisabled?: boolean;
  isInversed?: boolean;
}

interface ITable {
  colProps: Array<IColProps>;
  tableData: Array<ITableItem>;
  defaultSort: SortProps;
  contentType: ITableType;
  topBarActions: Array<IActionData>;
  highlightedId?: string;
  selectedId?: string;
  selectedIds?: string[];
  emptyStateDescription?: string | React.ReactNode;
  onRowClick?: (ev: React.MouseEvent<HTMLTableRowElement, MouseEvent>, id: string) => void;
  onClickOutsideSelectedRow?: (event: React.MouseEvent<Document, MouseEvent>) => void;
  addedRowRef?: React.RefObject<HTMLTableRowElement>;
  preselectedAssetId?: string;
  preselectedRowRef?: React.RefObject<HTMLTableRowElement>;
  disabled?: boolean;
  isFixed?: boolean;
  summaryRow?: () => JSX.Element;
  page?: number;
  setPage?: any;
  pageSize?: number;
  setPageSize?: any;
  children?: JSX.Element;
  touched?: Array<string>;
}

type SortProps = {
  key: string;
  order: SortOrder;
};

const useStyles = makeStyles(() => createStyles({
  container: {
    top: 0,
    width: '100%',
    bottom: 0,
    position: 'absolute',
    scrollBehavior: 'smooth',
    overflow: 'auto',
  },
  classTableFixed: {
    tableLayout: 'fixed',
  },
  opacity4: {
    color: COLORS.grayNeutral,
  },
  topBarButton: {
    margin: '0 10px',
    '&:first-child': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  exportIcon: {
    width: 13,
    height: 13,
    marginLeft: 8,
    marginRight: 32,
  },
  tableSortWrapper: {
    display: 'flex',
    '&:hover .MuiTableSortLabel-root svg': {
      opacity: '0.5',
    },
  },
  tableSortWrapperRight: {
    justifyContent: 'flex-end',
  },
  tableSortLabel: {
    display: 'flex',
  },
  tableSortLabelSelected: {
    '& svg': {
      opacity: 0.5,
    },
  },
  '@keyframes blink': {
    '0%, 100%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.2,
    },
  },
  highlighted: {
    animationName: '$blink',
    animationDuration: '400ms',
    animationTimingFunction: 'linear',
    animationIterationCount: 4,

    '& td': {
      fontWeight: 700,
    },
  },
  touched: {
    position: 'relative',
    background: `${COLORS.warningBackground} !important`,

    '& td:first-child': {
      borderLeft: `4px solid ${COLORS.warning}`,
      paddingLeft: '4px',
    },
  },
  selected: {
    background: `${COLORS.selectedAssetRow} !important`,
  },
  tableHeader: {
    position: 'sticky',
    zIndex: STICKY_COLUMN_HEADER_Z_INDEX,
  },
  pushRight: {
    marginLeft: 'auto',
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.5,
  },
  isButtonInversed: {
    color: COLORS.buttonBlack,
  },
}));

const TableComponent = ({
  colProps,
  defaultSort,
  tableData,
  contentType,
  topBarActions,
  highlightedId,
  selectedId,
  selectedIds,
  emptyStateDescription,
  onRowClick,
  onClickOutsideSelectedRow,
  addedRowRef,
  preselectedAssetId,
  preselectedRowRef,
  disabled,
  isFixed,
  summaryRow,
  page,
  setPage,
  pageSize,
  setPageSize,
  children,
  touched,
}: ITable) => {
  const classes = useStyles();
  const tableRef: React.MutableRefObject<HTMLTableElement | null> = useRef(null);
  const [tableRefState, setTableRefState] = useState(false);
  const [sortProps, setSortProps] = React.useState<SortProps>(defaultSort);
  const isPaginationEnabled = page && setPage && pageSize && setPageSize;

  const sortItems = useMemo(() => (order: SortOrder, varKey: string, data: Array<ITableItem>) => {
    const sortColumnProps = colProps.find((element) => element.varKey === varKey);
    const localCompareOptions = ['en', { numeric: true }];
    const sortedData = [...data].sort((a, b) => {
      const item = order === SortOrder.DESC ? a : b;
      const nextItem = order === SortOrder.DESC ? b : a;
      const sortByVisited = contentType === ITableType.accounts ? new Date(a.visited).valueOf()
        - new Date(b.visited).valueOf() : null;

      switch (sortColumnProps?.colType) {
        case ITableItemType.TEXT:
          if (typeof item[sortColumnProps.varKey] === 'string') {
            return sortByVisited || item[sortColumnProps.varKey]
              .localeCompare(nextItem[sortColumnProps.varKey], ...localCompareOptions);
          }
          return sortByVisited || item[sortColumnProps.varKey] - nextItem[sortColumnProps.varKey];
        case ITableItemType.NUMMARY:
          return sortByVisited || item[sortColumnProps.varKey] - nextItem[sortColumnProps.varKey];
        case ITableItemType.BOOLEAN:
        case ITableItemType.DATE: {
          const prevDate = new Date(item[sortColumnProps.varKey])?.valueOf() || 0;
          const nextDate = new Date(nextItem[sortColumnProps.varKey])?.valueOf() || 0;
          return sortByVisited || prevDate - nextDate;
        }
        case ITableItemType.ACTION:
          return sortByVisited
            || (item[sortColumnProps.varKey]?.id || '')
              .localeCompare(nextItem[sortColumnProps.varKey]?.id || '', ...localCompareOptions);
        case ITableItemType.GL_BALANCE:
          return sortByVisited
            || getGLBalance(item as Account) - getGLBalance(nextItem as Account);
        case ITableItemType.USERNAME:
          return (item[sortColumnProps.varKey]?.lastName || '')
            .localeCompare(nextItem[sortColumnProps.varKey]?.lastName || '');
        case ITableItemType.USEREMAIL:
          return (item[sortColumnProps.varKey]?.email || '')
            .localeCompare(nextItem[sortColumnProps.varKey]?.email || '');
        case ITableItemType.SORTKEY:
          if (!sortColumnProps.sortKey) return null;
          return ((path(sortColumnProps.sortKey, item[sortColumnProps.varKey])) as string || '')
            .localeCompare((path(sortColumnProps.sortKey, nextItem[sortColumnProps.varKey])) as string || '');
        case ITableItemType.SORTKEY_NUMMARY:
          if (!sortColumnProps.sortKey) return -1;
          return (
            (path(sortColumnProps.sortKey, item[sortColumnProps.varKey]) as number || 0)
            - (path(sortColumnProps.sortKey, nextItem[sortColumnProps.varKey]) as number || 0)
          );
        default:
          return null;
      }
    });
    return sortedData;
  }, [colProps, contentType]);

  const tableDataSorted = useMemo(() => sortItems(
    sortProps.order || defaultSort.order,
    sortProps.key || defaultSort.key,
    tableData,
  ), [sortProps.key, sortProps.order, tableData, defaultSort, sortItems]);

  const tableDataPaginated = isPaginationEnabled
    ? tableDataSorted
      .slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize)
    : tableDataSorted;

  useStickyColumns(tableRef, [tableRefState, tableDataPaginated, colProps]);

  const handleSortClick = (varKey: string) => {
    if (sortProps?.key === varKey) {
      const thirdOrder = varKey === defaultSort.key ? SortOrder.ASC : defaultSort.order;
      setSortProps({
        key: sortProps?.order === SortOrder.DESC ? defaultSort.key : varKey,
        order: sortProps?.order === SortOrder.ASC ? SortOrder.DESC : thirdOrder,
      });
    } else {
      setSortProps({ key: varKey, order: SortOrder.ASC });
    }
  };

  const renderSortIcon = () => {
    if (sortProps?.order === SortOrder.DESC) {
      return SortUpIcon;
    }
    return SortDownIcon;
  };

  const renderTableRow = (tableItem: ITableItem, index: number) => {
    const tableRow = () => (
      <TableRow
        key={tableItem.id}
        ref={preselectedAssetId === tableItem.id
          ? preselectedRowRef
          : addedRowRef}
        hover
        className={clsx({
          [classes.opacity4]: tableItem.active === false || tableItem.visible === false,
          [classes.highlighted]: highlightedId === tableItem.id,
          [classes.touched]: touched?.includes(tableItem.id),
          [classes.selected]: (selectedId === tableItem.id)
            || (selectedIds && selectedIds.includes(tableItem.id)),
          [classes.disabled]: disabled,
        })}
        onClick={(ev) => onRowClick && onRowClick(ev, tableItem.id)}
      >
        {colProps.map((column) => column.child(tableItem, index))}
      </TableRow>
    );

    if (onClickOutsideSelectedRow && selectedId === tableItem.id) {
      return (
        <ClickAwayListener
          key={tableItem.id}
          onClickAway={onClickOutsideSelectedRow}
        >
          {tableRow()}
        </ClickAwayListener>
      );
    }
    return tableRow();
  };

  return (
    <>
      {topBarActions.length > 0 && (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          margin="20px 0"
          className={clsx({
            [classes.disabled]: disabled,
          })}
        >
          {topBarActions?.map(({
            label,
            icon,
            isData,
            action,
            pushRight,
            iconPosition,
            isDisabled,
            isInversed,
          }) => (
            <Button
              key={label}
              color="primary"
              onClick={() => (isData ? action(tableDataSorted) : action())}
              endIcon={(!iconPosition || iconPosition === 'right') && icon}
              startIcon={(iconPosition === 'left') && icon}
              className={clsx(classes.topBarButton, {
                [classes.pushRight]: pushRight,
                [classes.isButtonInversed]: isInversed,
              })}
              disabled={isDisabled}
              size="large"
            >
              {label}
            </Button>
          ))}
        </Box>
      )}
      <ScrollableBoxContainer style={{ padding: 0, margin: 0 }}>
        <ScrollableTableContainer style={{ width: '100%' }}>
          <Table
            size="small"
            stickyHeader
            ref={(el) => {
              setTableRefState(true);
              tableRef.current = el;
            }}
            className={clsx({
              [classes.classTableFixed]: isFixed,
            })}
          >
            <TableHead className={classes.tableHeader}>
              <TableRow hover={false}>
                {colProps.map(({
                  colName,
                  varKey,
                  customHeader,
                  headerStyles,
                  headerClassName,
                  sticky,
                  sortable = true,
                  alignRight,
                }) => (
                  <TableCell
                    key={`${varKey}_${colName}`}
                    is-cell-sticky={sticky ? 'true' : 'false'}
                    className={headerClassName}
                    style={headerStyles}
                  >
                    {(colName && varKey) ? (
                      <Box
                        className={clsx(classes.tableSortWrapper, {
                          [classes.tableSortWrapperRight]: alignRight,
                        })}
                      >
                        {customHeader && customHeader}
                        {(!sortable && !customHeader) && colName}
                        {sortable && (
                          <TableSortLabel
                            className={clsx(classes.tableSortLabel, {
                              [classes.tableSortLabelSelected]: varKey === sortProps?.key,
                            })}
                            onClick={() => handleSortClick(varKey)}
                            IconComponent={varKey === sortProps?.key ? renderSortIcon() : SortNoneIcon}
                          >
                            {customHeader ? '' : colName}
                          </TableSortLabel>
                        )}
                      </Box>
                    ) : colName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableDataPaginated?.length > 0
                && tableDataPaginated?.map((tableItem, index) => renderTableRow(tableItem, index))}
              {summaryRow && summaryRow()}
            </TableBody>
          </Table>
          {!tableDataSorted.length && (
            <EmptyState description={emptyStateDescription} />
          )}
        </ScrollableTableContainer>
      </ScrollableBoxContainer>
      {children}
      {isPaginationEnabled && (
        <Pagination
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          length={tableData.length}
          removeBottomMargin
        />
      )}
    </>
  );
};

export default TableComponent;
