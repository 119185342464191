import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import Table from '@material-ui/core/Table';
import {
  Box,
  Button,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import {
  ADD_ASSET,
  ADD_SPLIT,
  ADD_SPLIT_ASSET,
  DATE_CHANGE,
  FREEZE_PANEL_Z_INDEX,
  INPUT_CHANGE,
  INPUT_FOCUS,
  DEFAULT_PAGE_SIZE,
  REMOVE_ASSET,
  SELECT_CHANGE,
  SELECT_ROW,
} from '../../util/constants';
import {
  AmortizationSource,
  ProductCategory,
  Subledger,
} from '../../interfaces/types';
import CreateSubledgerPrepaidExpenses from './CreateSubledgerPrepaidExpenses';
import CreateSubledgerRevSyncTranscations from './CreateSubledgerRevSyncTranscations';
import useStickyColumns from '../../hooks/useStickyColumns';
import useCollapsedView from '../../hooks/useCollapsedView';
import {
  ScrollableBoxContainer,
  ScrollableTableContainer,
} from '../ScrollableTable';
import VerticalSchedule from '../Flyovers/VerticalSchedule';
import { BulkEdit } from '../BulkEdit';
import { Pagination } from '../Pagination';
import { getChildren } from './common';
import { featureSwitch } from '../../util/featureSwitch';
import DialogBox from '../DialogBox';
import { DeleteIcon } from '../Icons';

interface Props {
  dispatch: any;
  subledger: Subledger;
  selectedRow: string;
  hoverRow: string;
  formSubmit?: boolean;
  isSplitAssetsSumCorrect: boolean;
  onBulkEditApply: (internalIds: string[], changedProperties: any) => void;
  onBulkEditSchedule: (internalIds: string[], changedProperties: any) => void;
  onBulkRemoveAssets?: (internalIds: string[]) => void;
}

const useStyles = makeStyles(() => createStyles({
  table: {
    minWidth: 1560,
    marginBottom: 0,
    zIndex: FREEZE_PANEL_Z_INDEX,
  },
}));

const CreateSubledgerTable = ({
  dispatch,
  subledger,
  selectedRow,
  hoverRow,
  formSubmit,
  isSplitAssetsSumCorrect,
  onBulkEditApply,
  onBulkEditSchedule,
  onBulkRemoveAssets,
}: Props) => {
  const classes = useStyles();
  const scrollRef = useRef(null);
  const tableRef: React.MutableRefObject<HTMLTableElement | null> = useRef(null);
  const [flyover, setFlyover] = useState<{
    isOpen: boolean;
    internalId: null | string;
    sourceId?: string;
  }>({
    isOpen: false,
    internalId: null,
    sourceId: undefined,
  });
  const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false);

  const [
    isCollapsedView,
    isCollapsedViewHovered,
    ToggleExpandedView,
  ] = useCollapsedView(false, 'expanded view');

  const [selectedSources, setSelectedSources] = useState<string[]>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const updateStickyColumns = useStickyColumns(tableRef, [
    selectedRow,
    isCollapsedView,
    subledger,
    page,
    pageSize,
  ]);

  const onCheckboxChange = (internalId: string) => {
    selectedSources.includes(internalId)
      ? setSelectedSources(selectedSources.filter((source) => source !== internalId))
      : setSelectedSources([...selectedSources, internalId]);
  };

  const onSelectAll = () => {
    isAllSelected
      ? setSelectedSources([])
      : setSelectedSources(subledger.amortizationSources
        .filter((source) => getChildren(source.internalId, subledger?.amortizationSources).length === 0
          && !source.parentId)
        .map((source) => source.internalId));

    setIsAllSelected(!isAllSelected);
  };

  const onEditClose = () => {
    setSelectedSources([]);
    setIsAllSelected(false);
  };

  const isElementSelected = (internalId: string) => selectedSources.includes(internalId);

  const addAsset = () => {
    dispatch({ type: ADD_ASSET });
    if (scrollRef.current) {
      // @ts-ignore
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
    setPage(Math.ceil((subledger.amortizationSources.length + 1) / pageSize));
  };

  const addSplit = (internalId: string) => () => {
    updateStickyColumns(true);
    dispatch({ type: ADD_SPLIT, payload: { internalId } });
  };

  const addSplitAsset = (internalId: string) => () => {
    updateStickyColumns(true);
    dispatch({ type: ADD_SPLIT_ASSET, payload: { internalId } });
  };

  const removeAsset = (internalId: string) => () => {
    updateStickyColumns(true);
    dispatch({ type: REMOVE_ASSET, payload: { internalId } });
  };

  const handleClickDeleteSelectedAssets = () => {
    setIsDeleteWarningOpen(true);
  };

  const handleDeleteSelectedAssets = () => {
    setIsDeleteWarningOpen(false);
    onBulkRemoveAssets && onBulkRemoveAssets(selectedSources);
    setSelectedSources([]);
  };

  const onInputBoxChange = (propertyName: string, internalId: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch({ type: INPUT_CHANGE, payload: { internalId, propertyName, value: event.target.value } });
  };

  const onInputBoxFocus = (propertyName: string, internalId: string) => (
    event: React.FocusEvent<HTMLInputElement>,
  ) => {
    dispatch({
      type: INPUT_FOCUS,
      payload: {
        internalId,
        propertyName,
        target: event.target,
        type: event.type,
      },
    });
  };

  const onAutoCompleteChange = (propertyName: string, internalId: string) => (value: string) => {
    dispatch({ type: SELECT_CHANGE, payload: { internalId, propertyName, value } });
  };

  const onDateChange = (propertyName: string, internalId: string, value: Date | null) => {
    dispatch({ type: DATE_CHANGE, payload: { internalId, propertyName, value } });
  };

  const onShowDetails = (internalId: string, sourceId?: string) => {
    setFlyover({ isOpen: true, internalId, sourceId });
  };

  const onRowClick = (asset: AmortizationSource) => {
    if (selectedSources.length > 1) {
      return;
    }
    if (selectedRow === (asset.parentId || asset.internalId)) {
      return;
    }
    dispatch({
      type: SELECT_ROW,
      payload: { selectedRow: asset.parentId ?? asset.internalId },
    });
  };

  useEffect(() => {
    if (selectedSources.length > 1 && selectedRow) {
      dispatch({
        type: SELECT_ROW,
        payload: { selectedRow: '' },
      });
    }

    selectedSources.length === subledger.amortizationSources
      ?.filter((source) => getChildren(source.internalId, subledger
        ?.amortizationSources).length === 0 && !source.parentId).length
      ? setIsAllSelected(true)
      : setIsAllSelected(false);
  }, [selectedSources, selectedRow, dispatch, subledger.amortizationSources]);

  const showBulkEdit = featureSwitch.bulkEdit;

  return (
    <>
      <DialogBox
        openDialog={isDeleteWarningOpen}
        closeDialog={() => setIsDeleteWarningOpen(false)}
        dialogContext={`Are you sure you want to delete selected ${selectedSources.length > 1
          ? `${selectedSources.length} assets`
          : 'asset'}?`}
        dialogTitle="Alert"
        dismissContext="Cancel"
        actions={[{
          title: 'Delete',
          event: handleDeleteSelectedAssets,
        }]}
      />
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="flex-end"
        padding="0 16px"
      >
        <Button
          color="primary"
          startIcon={(<AddCircleRoundedIcon />)}
          onClick={addAsset}
        >
          Add
          {' '}
          {subledger?.productCategory === ProductCategory.DeferredRevenue ? 'Transaction' : 'Asset'}
        </Button>
        {!!selectedSources.length && (
          <Button
            onClick={handleClickDeleteSelectedAssets}
            color="primary"
            startIcon={<DeleteIcon />}
          >
            Delete selected (
            {selectedSources.length}
            )
          </Button>
        )}
        <Box marginLeft="auto" />
        <ToggleExpandedView />
      </Box>
      <ScrollableBoxContainer>
        <ScrollableTableContainer
          scrollRef={scrollRef}
          removeBottomPadding
        >
          <Table
            ref={tableRef}
            size="small"
            stickyHeader
            aria-label="create subledger table"
            className={classes.table}
          >
            {subledger?.productCategory === ProductCategory.PrepaidExpense && (
              <CreateSubledgerPrepaidExpenses
                addSplit={addSplit}
                addSplitAsset={addSplitAsset}
                formSubmit={formSubmit}
                hoverRow={hoverRow}
                onAutoCompleteChange={onAutoCompleteChange}
                onDateChange={onDateChange}
                onInputBoxChange={onInputBoxChange}
                onInputBoxFocus={onInputBoxFocus}
                onSelectChange={onAutoCompleteChange}
                removeAsset={removeAsset}
                selectedRow={selectedRow}
                subledger={subledger}
                isCollapsedView={isCollapsedView}
                isCollapsedViewHovered={isCollapsedViewHovered}
                isSplitAssetsSumCorrect={isSplitAssetsSumCorrect}
                onShowDetails={onShowDetails}
                onCheckboxChange={onCheckboxChange}
                checkIsElementSelected={isElementSelected}
                onSelectAll={onSelectAll}
                isAllSelected={isAllSelected}
                onRowClick={onRowClick}
                showBulkEdit={showBulkEdit}
                page={page - 1}
                pageSize={pageSize}
              />
            )}
            {subledger?.productCategory === ProductCategory.DeferredRevenue && (
              <CreateSubledgerRevSyncTranscations
                addSplit={addSplit}
                addSplitAsset={addSplitAsset}
                formSubmit={formSubmit}
                hoverRow={hoverRow}
                onAutoCompleteChange={onAutoCompleteChange}
                onDateChange={onDateChange}
                onInputBoxChange={onInputBoxChange}
                onInputBoxFocus={onInputBoxFocus}
                onSelectChange={onAutoCompleteChange}
                removeAsset={removeAsset}
                selectedRow={selectedRow}
                subledger={subledger}
                isCollapsedView={isCollapsedView}
                isCollapsedViewHovered={isCollapsedViewHovered}
                isSplitAssetsSumCorrect={isSplitAssetsSumCorrect}
                onShowDetails={onShowDetails}
                onCheckboxChange={onCheckboxChange}
                checkIsElementSelected={isElementSelected}
                onSelectAll={onSelectAll}
                isAllSelected={isAllSelected}
                onRowClick={onRowClick}
                showBulkEdit={showBulkEdit}
                page={page - 1}
                pageSize={pageSize}
              />
            )}
          </Table>
        </ScrollableTableContainer>
      </ScrollableBoxContainer>
      {showBulkEdit && (
        <BulkEdit
          productCategory={subledger?.productCategory}
          onClose={onEditClose}
          onBulkEditApply={onBulkEditApply}
          onBulkEditSchedule={onBulkEditSchedule}
          selectedSources={selectedSources}
          isOpen={selectedSources.length > 1}
          scheduleDate={subledger.factaStartDate?.toDateString()}
          isFullyEditable
          isAllSelected={isAllSelected}
        />
      )}
      <Pagination
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        length={subledger.amortizationSources.filter((source) => !source.parentId).length || 0}
        scrollRef={scrollRef}
        hidePaginationOptions
      />
      <VerticalSchedule
        isOpen={flyover.isOpen}
        setOpen={setFlyover}
        internalId={flyover.internalId}
        sourceId={flyover.sourceId}
        subledger={subledger}
      />
    </>
  );
};

export default CreateSubledgerTable;
