/* eslint-disable react/jsx-props-no-spreading,max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PrepaidIcon = (props: any) => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.5374 8.84778L15.1522 3.46263C14.5353 2.84575 13.5316 2.84582 12.9148 3.46263L6.15741 10.22H4.5821C3.70973 10.22 3 10.9298 3 11.8021V19.4179C3 20.2903 3.70973 21 4.5821 21H16.6982C17.5705 21 18.2803 20.2903 18.2803 19.4179V13.3423L20.5374 11.0852C21.1542 10.4683 21.1542 9.46466 20.5374 8.84778ZM13.6606 4.20843C13.7634 4.10563 13.8985 4.0542 14.0335 4.0542C14.1686 4.0542 14.3036 4.10563 14.4064 4.20843L17.1812 6.98326L13.9445 10.22H7.64898L13.6606 4.20843ZM17.2255 19.4179C17.2255 19.7087 16.989 19.9453 16.6982 19.9453H4.5821C4.29131 19.9453 4.05473 19.7087 4.05473 19.4179V11.8021C4.05473 11.5113 4.29131 11.2748 4.5821 11.2748H16.6982C16.989 11.2748 17.2255 11.5113 17.2255 11.8021V19.4179ZM16.9128 10.235C16.8425 10.2254 16.771 10.22 16.6982 10.22H15.4361L17.927 7.72903L18.6729 8.47487L16.9128 10.235ZM19.7916 10.3394L18.2803 11.8507V11.8021C18.2803 11.4013 18.1301 11.035 17.8835 10.7559L19.4187 9.22067L19.7916 9.59355C19.9972 9.79919 19.9972 10.1338 19.7916 10.3394Z"
      fill="inherit"
    />
    <path
      d="M5.10938 17.2939H7.07185V18.3486H5.10938V17.2939Z"
      fill="inherit"
    />
    <path
      d="M8.14258 17.2939H10.105V18.3486H8.14258V17.2939Z"
      fill="inherit"
    />
    <path
      d="M11.1758 17.2939H13.1383V18.3486H11.1758V17.2939Z"
      fill="inherit"
    />
    <path
      d="M14.208 17.2939H16.1705V18.3486H14.208V17.2939Z"
      fill="inherit"
    />
    <path
      d="M8.6697 12.8708H5.10938V16.035H8.6697V12.8708ZM7.61496 14.9802H6.16411V13.9255H7.61496V14.9802Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default PrepaidIcon;
