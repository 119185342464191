import React from 'react';
import {
  TableCell,
  TableRow,
} from '@material-ui/core';
import clsx from 'clsx';
import {
  AccountInfo,
  AmortizationSource,
} from '../../interfaces/types';
import { useTableStyles } from './Subledger.styled';
import currencyFormatter from '../../util/currencyFormatter';
import CreateSubledgerAmortizationSchedules from './CreateSubledgerAmortizationSchedules';
import AmortizationReadOnlyTableCell from './AmortizationReadOnlyDateTableCell';
import DescriptionReadOnlyCell from './TableCells/DescriptionReadOnlyCell';
import CustomerCell from './TableCells/CustomerCell';
import ProductCell from './TableCells/ProductCell';
import AccountClassCell from './TableCells/AccountClassCell';
import AmortizationScheduledTypeCell from './TableCells/AmoritizationScheduledTypeCell';
import ExpenseAccountCell from './TableCells/ExpenseAccountCell';
import { FactaCheckbox } from '../Checkbox';

interface Props {
  selectedRow: string;
  amortizationSource: AmortizationSource;
  hoverRow: string;
  onClick: () => void;
  hasChildren?: boolean;
  index: number;
  account: AccountInfo;
  startDate: Date;
  endDate: Date | null;
  amortizationSources: Array<AmortizationSource>;
  lastChild?: boolean;
  isCollapsedView: boolean;
  onCheckboxChange: (internalId: string) => void;
  isSelectedForBulkEdit: boolean;
  showBulkEdit: boolean;
}

const CreateSubledgerRevSyncReadOnlyRow = ({
  selectedRow,
  amortizationSource,
  lastChild,
  hasChildren,
  account,
  hoverRow,
  index,
  onClick,
  startDate,
  endDate,
  amortizationSources,
  isCollapsedView,
  onCheckboxChange,
  isSelectedForBulkEdit,
  showBulkEdit,
}: Props) => {
  const tableClasses = useTableStyles();
  return (
    <TableRow
      hover={false}
      selected={
        isSelectedForBulkEdit
        || selectedRow === amortizationSource.internalId
        || (hoverRow === amortizationSource.internalId
          || amortizationSource.parentId === selectedRow)
      }
      onClick={onClick}
      classes={{
        selected: tableClasses.selected,
      }}
    >
      {showBulkEdit && (
        <TableCell
          className={clsx({
            [tableClasses.child]: amortizationSource.parentId,
            [tableClasses.parent]: hasChildren,
          })}
          is-cell-sticky="true"
        >
          {!amortizationSource?.parentId ? (
            <FactaCheckbox
              checked={isSelectedForBulkEdit}
              disabled={!!hasChildren}
              onChange={() => onCheckboxChange(amortizationSource.internalId)}
            />
          ) : ''}
        </TableCell>
      )}
      <TableCell
        className={clsx(tableClasses.assetCell, {
          [tableClasses.child]: amortizationSource.parentId,
          [tableClasses.parent]: hasChildren,
          [tableClasses.lastChild]: lastChild,
        })}
        is-cell-sticky="true"
      >
        {amortizationSource.parentId ? '' : `FINV${index + 1}`}
      </TableCell>
      <DescriptionReadOnlyCell
        hasChildren={hasChildren}
        lastChild={lastChild}
        amortizationSource={amortizationSource}
        isCellSticky="true"
      />
      {!isCollapsedView && (
        <>
          <CustomerCell
            hasChildren={hasChildren}
            lastChild={lastChild}
            amortizationSource={amortizationSource}
            isCellSticky="true"
          />
          <ProductCell
            hasChildren={hasChildren}
            lastChild={lastChild}
            amortizationSource={amortizationSource}
            isCellSticky="true"
          />
          <ExpenseAccountCell
            hasChildren={hasChildren}
            lastChild={lastChild}
            amortizationSource={amortizationSource}
            isCellSticky="true"
          />
          {
            (account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) && (
              <AccountClassCell
                hasChildren={hasChildren}
                lastChild={lastChild}
                amortizationSource={amortizationSource}
                isCellSticky="true"
              />
            )
          }
        </>
      )}
      <AmortizationReadOnlyTableCell
        amortizationDate={amortizationSource?.amortizationSchedule?.amortizationStartDate}
        amortizationScheduleType={amortizationSource?.amortizationSchedule?.amortizationScheduleType}
        parentId={amortizationSource.parentId}
        hasChildren={hasChildren}
        lastChild={lastChild}
        isCellSticky="true"
      />
      <AmortizationReadOnlyTableCell
        amortizationDate={amortizationSource.amortizationSchedule?.amortizationEndDate}
        amortizationScheduleType={amortizationSource.amortizationSchedule.amortizationScheduleType}
        parentId={amortizationSource.parentId}
        hasChildren={hasChildren}
        lastChild={lastChild}
        isCellSticky="true"
      />
      {!isCollapsedView && (
        <AmortizationScheduledTypeCell
          hasChildren={hasChildren}
          lastChild={lastChild}
          amortizationSource={amortizationSource}
          isCellSticky="true"
        />
      )}
      <TableCell
        className={clsx(tableClasses.currencyCell, {
          [tableClasses.child]: amortizationSource.parentId,
          [tableClasses.parent]: hasChildren,
          [tableClasses.lastChild]: lastChild,
        })}
        is-cell-sticky="true"
      >
        {currencyFormatter.format(amortizationSource?.startingBalance ?? 0)}
      </TableCell>
      <TableCell
        className={clsx({
          [tableClasses.child]: amortizationSource.parentId,
          [tableClasses.parent]: hasChildren,
          [tableClasses.lastChild]: lastChild,
        })}
        is-cell-sticky="true"
      />
      <CreateSubledgerAmortizationSchedules
        startDate={startDate}
        endDate={endDate}
        asset={amortizationSource}
        amortizationSources={amortizationSources}
        lastChild={lastChild}
      />
    </TableRow>
  );
};

export default React.memo(CreateSubledgerRevSyncReadOnlyRow);
