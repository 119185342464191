import { Button } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import COLORS from '../../theme/colors';

export const CardLabel = styled(Typography)({
  fontSize: 14,
  color: COLORS.lightGray,
  fontWeight: 500,
});
export const ActionButton = styled(Button)({
  margin: 0,
});
export const CurrencyLabel = styled(Typography)({
  margin: 0,
  fontSize: 16,
  color: COLORS.medGray,
});
export const CompletedTasksLabel = styled(Typography)({
  margin: 0,
  fontSize: 11,
  fontWeight: 500,
  color: COLORS.lightGray,
  lineHeight: 2,
});
