import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router';
import COLORS from '../../theme/colors';
import {
  WelcomeMessage,
  StyledButton,
} from '../common.styled';

interface Props {
  isNegativeLayout?: boolean;
}

const NoData = ({ isNegativeLayout }: Props) => {
  const history = useHistory();
  const navigateToCharts = () => history.push('/account');
  return (
    <Box
      bgcolor={isNegativeLayout ? COLORS.white : COLORS.lighterBlue}
      height={172}
      display="flex"
      flexDirection="row"
      width="95%"
      alignSelf="center"
      borderRadius="4px"
      marginTop={6}
      p={0}
      boxShadow={isNegativeLayout ? COLORS.containerBoxShadow : undefined}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
      >
        <img
          alt="half-llama"
          src="/half-llama.svg"
        />
      </Box>
      <Box
        display="flex"
        maxWidth="650px"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        marginLeft="-50px"
        zIndex={1}
      >
        <WelcomeMessage>Welcome to Facta!</WelcomeMessage>
        <Typography>
          Your dashboard is currently empty because no subledgers have been created. Create your first
          subledger by clicking this magical button.
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        marginLeft="-175px"
        marginTop="75px"
      >
        <img
          alt="get started arrow"
          src="/get-started-arrow.svg"
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        ml="4px"
      >
        <StyledButton
          variant="contained"
          color="primary"
          onClick={navigateToCharts}
        >
          GET STARTED
        </StyledButton>
      </Box>
    </Box>
  );
};

export default React.memo(NoData);
