import React from 'react';
import {
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import TemplateIcon from '../../../Icons/TemplateIcon';
import CSVImportInput from '../CSVImportInput';
import { importWizardStyles } from '../index.styled';
import { NotifierType } from '../../../../interfaces/types';
import { openSnackbar } from '../../../Notifier';
import { DownloadIcon } from '../../../Icons';

interface Props {
  handleTemplateDownload: () => void;
  onFileUploaded: (data: any, fileInfo: any) => void;
  onSubmit: () => void;
  handleDownloadFixedData: () => void;
  isImportedDataValid: boolean;
  uploadedFile: File | null;
}

export const UploadActions = ({
  handleTemplateDownload,
  handleDownloadFixedData,
  onFileUploaded,
  onSubmit,
  isImportedDataValid,
  uploadedFile,
}: Props) => {
  const classes = importWizardStyles();

  const onCSVError = (err: Error) => {
    openSnackbar({ message: err.message }, NotifierType.Error);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      marginBottom="40px"
    >
      <Box
        display="flex"
        alignItems="center"
        height="100%"
      >
        <Button
          color="primary"
          endIcon={(<TemplateIcon />)}
          onClick={handleTemplateDownload}
          size="large"
        >
          CSV Template
        </Button>
        <Box className={classes.divider} />
        <CSVImportInput
          onFileUploaded={onFileUploaded}
          onError={onCSVError}
        />
        <Button
          onClick={onSubmit}
          disabled={!isImportedDataValid}
          variant="contained"
          color="primary"
        >
          {'Let\'s Go'}
        </Button>
        <Typography className={classes.label}>
          File:
        </Typography>
        {uploadedFile && (
          <Typography className={classes.input}>
            {uploadedFile.name}
          </Typography>
        )}
      </Box>
      {isImportedDataValid && uploadedFile && (
        <Button
          color="secondary"
          endIcon={(<DownloadIcon />)}
          onClick={handleDownloadFixedData}
          size="large"
        >
          Download Fixed Data
        </Button>
      )}
    </Box>
  );
};
