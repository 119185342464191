/* eslint-disable react/jsx-props-no-spreading,max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const VisibilityIcon = (props: any) => (
  <SvgIcon
    fontSize="small"
    width="16"
    height="11"
    viewBox="0 0 16 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 3.27273C8.57865 3.27273 9.13361 3.5026 9.54278 3.91177C9.95195 4.32094 10.1818 4.87589 10.1818 5.45455C10.1818 6.0332 9.95195 6.58815 9.54278 6.99732C9.13361 7.4065 8.57865 7.63636 8 7.63636C7.42135 7.63636 6.86639 7.4065 6.45722 6.99732C6.04805 6.58815 5.81818 6.0332 5.81818 5.45455C5.81818 4.87589 6.04805 4.32094 6.45722 3.91177C6.86639 3.5026 7.42135 3.27273 8 3.27273ZM8 0C11.6364 0 14.7418 2.26182 16 5.45455C14.7418 8.64727 11.6364 10.9091 8 10.9091C4.36364 10.9091 1.25818 8.64727 0 5.45455C1.25818 2.26182 4.36364 0 8 0ZM1.58545 5.45455C2.17328 6.65477 3.08604 7.666 4.21998 8.37328C5.35393 9.08055 6.66356 9.45551 8 9.45551C9.33644 9.45551 10.6461 9.08055 11.78 8.37328C12.914 7.666 13.8267 6.65477 14.4145 5.45455C13.8267 4.25432 12.914 3.24309 11.78 2.53582C10.6461 1.82854 9.33644 1.45359 8 1.45359C6.66356 1.45359 5.35393 1.82854 4.21998 2.53582C3.08604 3.24309 2.17328 4.25432 1.58545 5.45455Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default VisibilityIcon;
