import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const dashboardSelector = (state: RootState) => state.dashboard;

export const isFetchingDashboardBalances = createSelector(
  dashboardSelector,
  (dashboard) => dashboard.isFetchingAccountBalances,
);

export const isFetchingBalancesByCategory = createSelector(
  dashboardSelector,
  (dashboard) => dashboard.isFetchingBalancesByCategory,
);

export const isFetchingSubledgersByCategory = createSelector(
  dashboardSelector,
  (dashboard) => dashboard.isFetchingSubledgersByCategory,
);

export const isDeletingJournalEntry = createSelector(
  dashboardSelector,
  (dashboard) => dashboard.isDeletingJournalEntry,
);

export const fetchingDashboardError = createSelector(
  dashboardSelector,
  (dashboard) => dashboard.error,
);

export const dashboardBalancesSelector = createSelector(
  dashboardSelector,
  (dashboard) => dashboard.accountBalances,
);

export const dashboardBalancesByCategorySelector = createSelector(
  dashboardSelector,
  (dashboard) => dashboard.balancesByCategory,
);

export const dashboardSubledgersByCategorySelector = createSelector(
  dashboardSelector,
  (dashboard) => dashboard.subledgersByCategory,
);
