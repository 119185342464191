/* eslint-disable no-param-reassign */
import axios from 'axios';
import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import {
  Account,
  NotifierType,
  ProductCategory,
  Subledger,
} from '../../interfaces/types';
import { openSnackbar } from '../../components/Notifier';
import mapErrorMessage from '../../util/errorMessage';

const PREFIX = 'dashboard';
const GET_DASHBOARD_BALANCES = `${PREFIX}/getDashboardBalances`;
const GET_SUBLEDGERS_BY_CATEGORY = `${PREFIX}/getSubledgersByCategory`;
const GET_BALANCES_BY_CATEGORY = `${PREFIX}/getBalancesByCategory`;
const DELETE_JOURNAL_ENTRY = `${PREFIX}/deleteJournalEntry`;

interface State {
  isFetchingAccountBalances: boolean;
  isFetchingSubledgersByCategory: boolean;
  isFetchingBalancesByCategory: boolean;
  isDeletingJournalEntry: boolean;
  accountBalances: Array<Account>;
  subledgersByCategory: Array<Subledger>;
  balancesByCategory: Array<Account>;
  error?: any;
}

const initialState: State = {
  isFetchingAccountBalances: false,
  isFetchingSubledgersByCategory: false,
  isFetchingBalancesByCategory: false,
  isDeletingJournalEntry: false,
  accountBalances: [],
  balancesByCategory: [],
  subledgersByCategory: [],
};

export const getDashboardBalances = createAsyncThunk<Array<Account>, {
  selectedDateFormatted: unknown;
  ids: unknown;
}>(
  GET_DASHBOARD_BALANCES,
  async ({ selectedDateFormatted, ids }, { rejectWithValue }) => {
    try {
      const { data } = await axios
        .get<Array<Account>>(`/account/balance?selectedDate=${selectedDateFormatted}&ids=${ids}`);

      return data;
    } catch (error) {
      const handledError = mapErrorMessage(error.response?.data || error.message);
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const getBalancesByCategory = createAsyncThunk<Array<Account>, {
  category: ProductCategory;
}>(
  GET_BALANCES_BY_CATEGORY,
  async ({ category }, { rejectWithValue }) => {
    try {
      const { data } = await axios
        .get<Array<Account>>(`/account/subledgers/balance?category=${category}`);

      return data;
    } catch (error) {
      const handledError = mapErrorMessage(error.response?.data || error.message);
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const getSubledgersByCategory = createAsyncThunk<Array<Subledger>, {
  category: ProductCategory;
}>(
  GET_SUBLEDGERS_BY_CATEGORY,
  async ({ category }, { rejectWithValue }) => {
    try {
      const { data } = await axios
        .get<Array<Subledger>>(`/subledgers?category=${category}`);

      return data;
    } catch (error) {
      const handledError = mapErrorMessage(error.response?.data || error.message);
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const deleteJournalEntry = createAsyncThunk<null, {
  subledger: Subledger;
  journalEntryId: string;
}>(
  DELETE_JOURNAL_ENTRY,
  async ({ subledger, journalEntryId }, { rejectWithValue }) => {
    try {
      await axios.delete(`/subledgers/${subledger.id}/journal-entry/${journalEntryId}`);

      return null;
    } catch (error) {
      const handledError = mapErrorMessage(error.response?.data || error.message, subledger.productCategory);
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const dashboardSlice = createSlice({
  initialState,
  name: PREFIX,
  reducers: {
    clearDashboardData: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getDashboardBalances.pending, (state) => {
      state.isFetchingAccountBalances = true;
    });
    builder.addCase(getDashboardBalances.fulfilled, (state, { payload }) => {
      state.accountBalances = payload || [];
      state.isFetchingAccountBalances = false;
    });
    builder.addCase(getDashboardBalances.rejected, (state, { payload }) => {
      state.isFetchingAccountBalances = false;
      state.error = payload;
    });

    builder.addCase(getBalancesByCategory.pending, (state) => {
      state.isFetchingBalancesByCategory = true;
    });
    builder.addCase(getBalancesByCategory.fulfilled, (state, { payload }) => {
      state.balancesByCategory = payload || [];
      state.isFetchingBalancesByCategory = false;
    });
    builder.addCase(getBalancesByCategory.rejected, (state, { payload }) => {
      state.isFetchingBalancesByCategory = false;
      state.error = payload;
    });

    builder.addCase(getSubledgersByCategory.pending, (state) => {
      state.isFetchingSubledgersByCategory = true;
    });
    builder.addCase(getSubledgersByCategory.fulfilled, (state, { payload }) => {
      state.subledgersByCategory = payload || [];
      state.isFetchingSubledgersByCategory = false;
    });
    builder.addCase(getSubledgersByCategory.rejected, (state, { payload }) => {
      state.isFetchingSubledgersByCategory = false;
      state.error = payload;
    });

    builder.addCase(deleteJournalEntry.pending, (state) => {
      state.isDeletingJournalEntry = true;
    });
    builder.addCase(deleteJournalEntry.fulfilled, (state) => {
      state.isDeletingJournalEntry = false;
    });
    builder.addCase(deleteJournalEntry.rejected, (state, { payload }) => {
      state.isDeletingJournalEntry = false;
      state.error = payload;
    });
  },
});

export const {
  clearDashboardData,
} = dashboardSlice.actions;

export const dashboardReducer = dashboardSlice.reducer;
