import {
  FixedAsset,
  FixedAssetScheduleItem,
} from '../../../interfaces/fixedAssets';
import { NUMBER_OF_VISIBLE_PERIODS } from '../../constants';
import { precisionRound } from '../../math';

interface Props {
  allFixedAssets: Array<FixedAsset>;
  selectedDate: string;
}

export const reduceScheduleToSum = (dates: Array<string>) => (acc: number, curr: FixedAssetScheduleItem) => (
  precisionRound(acc + (dates.includes(curr.effective) ? curr.amount : 0))
);

export const getScheduleTableProperties = ({ allFixedAssets, selectedDate }: Props) => {
  const distinctDates = allFixedAssets
    .filter((asset) => asset.schedule) // Filter to get only assets with schedules
    .map((asset) => asset.schedule!) // Map to get only schedules
    .reduce((acc, curr) => acc.concat(curr), []) // Concat all schedules to flat array
    .map((sch) => sch.effective) // Map to get only dates
    // Filter out duplicates and all dates before selected date
    .filter((date, i, ar) => ar.indexOf(date) === i && date >= selectedDate)
    .sort((a, b) => ((a > b ? 1 : -1))); // Sort dates ascending

  const distinctDatesVisiblePeriods = distinctDates.slice(0, NUMBER_OF_VISIBLE_PERIODS);
  const distinctDatesFuturePeriods = distinctDates.slice(NUMBER_OF_VISIBLE_PERIODS);

  const reduceFixedAssetsToSum = (acc: number, curr: FixedAsset) => precisionRound(
    acc + (curr?.schedule ? curr.schedule.reduce(reduceScheduleToSum(distinctDatesFuturePeriods), 0) : 0),
  );

  const futureSum = allFixedAssets.reduce(reduceFixedAssetsToSum, 0);

  return {
    distinctDatesVisiblePeriods,
    distinctDatesFuturePeriods,
    futureSum,
  };
};
