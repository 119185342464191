import React from 'react';
import JournalEntryListView from './JournalEntryListView';
import Layout from '../../components/Layout';

const JournalEntryContainer = () => (
  <Layout title="Journal Entries">
    <JournalEntryListView />
  </Layout>
);

export default JournalEntryContainer;
