import React, {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  Button,
  createStyles,
  TextField,
  Tooltip,
} from '@material-ui/core';
import {
  format,
  lastDayOfMonth,
  parse,
  parseISO,
  startOfMonth,
} from 'date-fns';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {
  HeaderCurrencyDetails,
  HeaderLabel,
  HeaderTop,
  AccountStyled,
} from '../../../components/common.styled';
import COLORS from '../../../theme/colors';
import {
  NotifierType,
  ProductCategory,
  Subledger,
  QuickBookError,
} from '../../../interfaces/types';
import {
  DAY_SHORT_FORMAT,
  QUICKBOOK_ERRORS,
} from '../../../util/constants';
import FactaDatePicker from '../../../components/DatePicker';
import InputBox from '../../../components/InputBox';
import { openSnackbar } from '../../../components/Notifier';
import isEmpty from '../../../util/isEmpty';
import { toUTC } from '../../../util/timezone';
import mapErrorMessage from '../../../util/errorMessage';
import DialogBox from '../../../components/DialogBox';
import SubledgerAccountName from '../../../components/Subledger/SubledgerAccountName';
import { accountInfoSelector } from '../../../store/selectors/account';
import {
  Breadcrumbs,
  BreadcrumbsItem,
} from '../../../components/BreadcrumbsStepper';
import { AppThunkDispatch } from '../../../store/store';
import { getAccountJeNumber } from '../../../store/slices/account';
import { postJournalEntry } from '../../../store/slices/subledger';
import { isUpdatingSubledger } from '../../../store/selectors/subledger';
import { Loader } from '../../../components/Loader';
import AssetsSettled from '../../../components/Dashboard/AssetsSettled';

const useStyles = makeStyles(() => createStyles({
  selectBox: {
    width: 400,
  },
  periodBox: {
    width: 100,
    marginRight: 20,
  },
  subledgerBalance: {
    color: COLORS.violet,
  },
  datepicker: {
    background: COLORS.white,
    width: 120,
    maxWidth: 120,
    '& input': {
      background: COLORS.white,
    },
  },
  fullWidth: {
    width: '100%',
  },
  whiteFont: {
    color: COLORS.white,
  },
  currentPeriod: {
    background: COLORS.deepGray,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    borderTopLeftRadius: 0,
    color: COLORS.white,
    padding: '3px 10px',
    position: 'absolute',
    left: '50%',
    '& p': {
      fontSize: 13,
      margin: 2,
      '& span': {
        fontWeight: 'bold',
        marginLeft: 5,
      },
    },
  },
  jeNumber: {
    '& input': {
      paddingTop: 7.5,
      paddingBottom: 7.5,
    },
  },
  jeDescription: {
    background: COLORS.white,
    width: 400,
    margin: 0,
    '& p': {
      position: 'absolute',
      top: -25,
      right: 0,
    },
    '& fieldset': {
      border: 0,
    },
    '& .MuiInputBase-root': {
      padding: '4px 0',
      border: `1px solid ${COLORS.border}`,
      '& .MuiInputBase-input': {
        padding: '4px 12px',
      },
      '&.Mui-focused': {
        border: `1px solid ${COLORS.violet}`,
      },
    },
  },
  margin0: {
    margin: 0,
  },
  fontBold: {
    fontWeight: 'bold',
  },
  navigation: {
    paddingLeft: 38,
    paddingTop: 30,
  },
}));

interface Props {
  subledger?: Subledger | null;
  scheduleDate: string;
  currentMonth: string;
  rowsCount: number;
  totalPrepaidAccount: number;
}

interface Params {
  id: string;
}

const PostJEHeader = ({
  subledger,
  currentMonth,
  scheduleDate,
  rowsCount,
  totalPrepaidAccount,
}: Props) => {
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const history = useHistory();
  const { id: subledgerId } = useParams<Params>();
  const classes = useStyles();
  const CHARACTER_LIMIT = 140;

  const account = useSelector(accountInfoSelector);
  const loading = useSelector(isUpdatingSubledger);

  const [values, setValues] = useState('');
  const [jeNumber, setJeNumber] = useState('');
  const [selectedDate, handleDateChange] = useState(new Date(scheduleDate));
  const [openDialog, setOpenDialog] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const isAccountClosed = totalPrepaidAccount === 0 || rowsCount === 0;

  useEffect(() => {
    const description = subledger?.productCategory === ProductCategory.DeferredRevenue
      ? 'revenue recognition' : 'prepaid expense amortization';
    setValues(`${currentMonth} ${description}`);
  }, [currentMonth, subledger]);

  useEffect(() => {
    reduxDispatch(getAccountJeNumber())
      .unwrap()
      .then((incJeNumber) => incJeNumber && setJeNumber(incJeNumber));
  }, [reduxDispatch]);

  if (!subledger || isEmpty(subledger)) {
    return null;
  }

  const postJE = async () => {
    reduxDispatch(postJournalEntry({
      subledgerID: subledgerId,
      scheduleDate,
      jeNumber,
      description: values,
      postingDate: toUTC(selectedDate),
      productCategory: subledger?.productCategory,
    }))
      .unwrap()
      .then(() => setIsSuccessModalOpen(true))
      .catch((error) => {
        let snackbarMessages: QuickBookError[] = [];
        error.response?.data?.errors && error.response.data.errors.forEach((err: QuickBookError) => {
          if (QUICKBOOK_ERRORS.BOOK_CLOSE_DATES?.includes(err.code)) {
            setOpenDialog(true);
          } else {
            snackbarMessages = [err, ...snackbarMessages];
          }
        });
        if (snackbarMessages.length > 0) {
          const message = snackbarMessages.map((err) => mapErrorMessage(err, subledger.productCategory)).join(', ');
          openSnackbar({ message }, NotifierType.Error);
        }
      });
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const navigateToPrepareJE = async () => {
    history.push(`/subledgers/schedule/${subledger.id}/prepare-je/?scheduleDate=${scheduleDate}`);
  };

  const navigateToSchedulePrepaid = () => {
    history.push(`/subledgers/schedule/${subledger.id}/?scheduleDate=${scheduleDate}`);
  };

  const changeDate = (date: Date | ((prevState: Date) => Date) | null) => {
    // @ts-ignore
    handleDateChange(date);
  };

  const currentAccountClosingDate = parse(scheduleDate, DAY_SHORT_FORMAT, new Date());

  const dialogMessage = account?.bookCloseDate
    ? `QuickBooks is locked as of ${format(parseISO(account?.bookCloseDate!), 'LLLL dd, yyyy')}.`
    + ' \n You cannot edit a locked period. \n Please unlock QuickBooks in order to post changes.' : '';

  const handleSuccessModalClose = () => {
    setIsSuccessModalOpen(false);
    history.push(subledger?.productCategory === ProductCategory.DeferredRevenue ? '/revsync' : '/');
  };

  return (
    <>
      <Loader open={loading} />
      {
        account?.bookCloseDate && (
          <DialogBox
            openDialog={openDialog}
            closeDialog={closeDialog}
            dialogContext={dialogMessage}
            dialogTitle="Alert"
            dismissContext="Dismiss"
          />
        )
      }
      <div className={classes.currentPeriod}>
        <p>
          Current Period:
          <span>{format(currentAccountClosingDate, 'MMM 20yy')}</span>
        </p>
      </div>
      <Box className={classes.navigation}>
        <Breadcrumbs>
          <BreadcrumbsItem
            step={1}
            checked
            action={navigateToSchedulePrepaid}
          >
            Schedule
            {' '}
            {subledger?.productCategory === ProductCategory.DeferredRevenue ? 'Revenue' : 'Prepaid'}
          </BreadcrumbsItem>
          <BreadcrumbsItem
            step={2}
            checked
            action={navigateToPrepareJE}
          >
            Review schedule
          </BreadcrumbsItem>
          <BreadcrumbsItem
            step={3}
            active
          >
            Prepare JE
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Box>
      <HeaderTop
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="flex-start"
        alignItems="flex-start"
        width="100%"
      >
        <AccountStyled>
          <SubledgerAccountName account={subledger?.account} />
        </AccountStyled>
        <Box display="flex">
          <HeaderCurrencyDetails>
            <HeaderLabel>
              JE Number:
            </HeaderLabel>
            <InputBox
              fullWidth
              onChange={(e) => setJeNumber(e.target.value)}
              value={jeNumber}
            />
          </HeaderCurrencyDetails>
          <HeaderCurrencyDetails>
            <HeaderLabel>
              Posting Date
            </HeaderLabel>
            <FactaDatePicker
              selectedDate={selectedDate}
              onDateChange={(date) => changeDate(date)}
              className={classes.datepicker}
              minDate={startOfMonth(new Date(scheduleDate))}
              maxDate={lastDayOfMonth(new Date(scheduleDate))}
            />
          </HeaderCurrencyDetails>
        </Box>
        <HeaderCurrencyDetails>
          <HeaderLabel>
            JE Description
          </HeaderLabel>
          <TextField
            inputProps={{
              maxLength: CHARACTER_LIMIT,
            }}
            value={values}
            helperText={`${values.length}/${CHARACTER_LIMIT}`}
            margin="normal"
            variant="outlined"
            className={classes.jeDescription}
            onChange={(e) => setValues(e.target.value)}
          />
        </HeaderCurrencyDetails>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          paddingLeft="19px"
          marginLeft="auto"
          marginTop="18px"
        >
          <Button
            variant="outlined"
            color="primary"
            startIcon={<ChevronLeftIcon />}
            onClick={navigateToPrepareJE}
          >
            REVIEW SCHEDULE
          </Button>
          <Tooltip
            title={!jeNumber ? 'Please add the JE Number before you post your journal entry' : ''}
            arrow
            placement="top"
          >
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={postJE}
                disabled={!jeNumber}
              >
                {!isAccountClosed ? 'POST JE' : 'CLOSE'}
              </Button>
            </Box>
          </Tooltip>
        </Box>
      </HeaderTop>
      <Box position="relative">
        <AssetsSettled
          open={isSuccessModalOpen}
          onClose={handleSuccessModalClose}
        />
      </Box>
    </>
  );
};

export default PostJEHeader;
