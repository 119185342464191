import React from 'react';
import clsx from 'clsx';
import {
  Tooltip,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { StyledInputLabelRequired } from '../FormControl/FormControl.styled';
import COLORS from '../../theme/colors';
import { InfoIcon } from '../Icons';

interface IInputLabel {
  label: string;
  tooltipText?: string;
  isHorizontal?: boolean;
  required?: boolean;
  disabled?: boolean;
  centered?: boolean;
  keepHeight?: boolean;
}

const useStyles = makeStyles(() => ({
  label: {
    display: 'flex',
    alignItems: 'flex-start',
    fontWeight: 500,
    fontSize: 10,
    lineHeight: '16px',
    letterSpacing: '0.02em',
    margin: '0 10px 4px 0',
    color: COLORS.grayNeutral,
  },
  centered: {
    alignItems: 'center',
  },
  keepHeight: {
    minHeight: '20px',
  },
  horizontal: {
    fontSize: '12px',
    lineHeight: '14px',
    margin: '0 16px 0 0',
    flexDirection: 'row-reverse',
  },
  tooltip: {
    margin: '0 10px',
    minHeight: 'auto',
    padding: 0,
  },
  disabled: {
    color: COLORS.textDisabled,
  },
}));

export const InputLabel = ({
  label,
  isHorizontal,
  required,
  disabled,
  tooltipText,
  centered,
  keepHeight,
}: IInputLabel) => {
  const classes = useStyles();

  return (
    <Typography
      className={clsx(classes.label, {
        [classes.horizontal]: isHorizontal,
        [classes.disabled]: disabled,
        [classes.centered]: centered,
        [classes.keepHeight]: keepHeight,
      })}
    >
      <span>
        {label}
        {required && (<StyledInputLabelRequired>*</StyledInputLabelRequired>)}
      </span>
      {tooltipText && (
        <Tooltip
          title={tooltipText}
          arrow
          placement="top"
        >
          <IconButton className={classes.tooltip}>
            <InfoIcon style={{ color: COLORS.skyBlue }} />
          </IconButton>
        </Tooltip>
      )}
    </Typography>
  );
};
