import React from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Typography,
} from '@material-ui/core';
import find from 'lodash.find';
import { useSelector } from 'react-redux';
import COLORS from '../../theme/colors';
import { accountsListSelector } from '../../store/selectors/v2Accounts';

interface Props {
  accumulationAccountId: string;
  subledgerAccountId: string;
}

const useStyles = makeStyles(() => createStyles({
  accountDetails: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    paddingRight: 32,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
  },
  accountName: {
    marginLeft: 16,
    fontWeight: 500,
    lineHeight: '26px',
  },
  accumulationAccount: {
    marginTop: '9px',
    fontSize: '12px',
    lineHeight: '14px',
    color: COLORS.grayNeutral,
    paddingRight: 32,
  },
}));

export const AccountDetails = ({
  accumulationAccountId,
  subledgerAccountId,
}: Props) => {
  const accounts = useSelector(accountsListSelector);
  const account = find(accounts, { id: subledgerAccountId });
  const accumulationAccount = find(accounts, { id: accumulationAccountId });
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
    >
      <Typography className={classes.accountDetails}>
        <span>Account</span>
        <span className={classes.accountName}>
          {account?.displayName}
        </span>
      </Typography>
      <Typography className={classes.accumulationAccount}>
        {accumulationAccount?.displayName}
      </Typography>
    </Box>
  );
};
