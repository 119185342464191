import {
  addDays,
  differenceInSeconds,
  formatDuration,
  intervalToDuration,
  parseISO,
} from 'date-fns';

export const calculateRemainingLife = (start: string, end: string) => {
  if (!start || !end) {
    return {
      difference: 0,
      label: '',
    };
  }

  const distance = intervalToDuration({
    start: parseISO(start),
    end: addDays(parseISO(end), 1),
  });

  const difference = differenceInSeconds(parseISO(end), parseISO(start));

  return {
    difference,
    label: formatDuration(distance, { format: ['years', 'months', 'weeks', 'days'] }),
  };
};
