import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import { makeStyles } from '@material-ui/core/styles';
import {
  AmortizationSource,
  FilterMatchingType,
  Subledger,
} from '../../interfaces/types';
import getScheduleDate from '../../util/getScheduleDate';
import UnScheduleAssets from '../Subledger/UnScheduledAssets';
import { useTableStyles } from '../Subledger/Subledger.styled';
import { accountInfoSelector } from '../../store/selectors/account';
import { TextSearchFilter } from '../Table/Filters/TextSearch';
import { MultipickerFilter } from '../Table/Filters/Multipicker';
import { AmountFilter } from '../Table/Filters/Amount';
import { ClearAllButton } from '../Table/Filters/ClearAllButton';
import { EmptyState } from '../Table/EmptyState';
import { useFilters } from '../../hooks/useFilters';
import {
  getFilteredData,
  getFilterValueByFilterName,
} from '../../util/filtering';
import { DatepickerFilter } from '../Table/Filters/Datepicker';

const useStyles = makeStyles({
  tableStyle: {
    position: 'relative',
    width: '100%',

    '& .MuiTableCell-head': {
      verticalAlign: 'middle',
    },
  },
});

interface Props {
  subledger: Subledger;
  dispatch: any;
  selectedRow: string;
  showFilters?: boolean;
}

const SubledgerDetailsPrepaidExpense = ({
  subledger,
  selectedRow,
  dispatch,
  showFilters,
}: Props) => {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const account = useSelector(accountInfoSelector)!;
  const scheduleDate = getScheduleDate(subledger);

  const initialFilters = useMemo(() => ([
    {
      name: 'description',
      matching: FilterMatchingType.STRING_INCLUDES,
    },
    {
      name: 'vendorId',
      matching: FilterMatchingType.ARRAY_INCLUDES_PROPERTY,
      propertyPath: ['id'],
    },
    {
      name: 'amortizationSchedule.destinationId',
      matching: FilterMatchingType.ARRAY_INCLUDES_PROPERTY,
      propertyPath: ['destinationId'],
    },
    {
      name: 'amortizationSchedule.classId',
      matching: FilterMatchingType.ARRAY_INCLUDES_PROPERTY,
      propertyPath: ['classId'],
    },
    {
      name: 'startingBalance',
      matching: FilterMatchingType.NUMBER_COMPARE,
    },
    {
      name: 'sourceCreationDate',
      matching: FilterMatchingType.DATE_RANGE,
    },
  ]),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [subledger]);

  const {
    filters,
    setFilter,
    clearAllFilters,
  } = useFilters(initialFilters);

  const unScheduleAssets = subledger?.amortizationSources?.filter((asset: AmortizationSource) => !asset.status);

  const {
    filteredData: filteredAmortizationSources,
    numberOfFiltersApplied,
  } = useMemo(
    () => getFilteredData(unScheduleAssets, filters),
    [filters, unScheduleAssets],
  );

  return (
    <Box width="100%">
      <Table
        size="small"
        stickyHeader
        className={classes.tableStyle}
      >
        <TableHead>
          <TableRow hover={false}>
            <TableCell className={tableClasses.assetCell}>
              {showFilters
                ? (
                  <DatepickerFilter
                    _key={subledger.id}
                    label="JE Date"
                    value={getFilterValueByFilterName(filters, 'sourceCreationDate')}
                    onChange={(filter) => setFilter('sourceCreationDate', filter)}
                  />
                )
                : 'JE Date'}
            </TableCell>
            <TableCell className={tableClasses.assetCell}>
              GL JE ID
            </TableCell>
            <TableCell className={tableClasses.descriptionCell}>
              {showFilters
                ? (
                  <TextSearchFilter
                    _key={subledger.id}
                    label="Description"
                    value={getFilterValueByFilterName(filters, 'description')}
                    onChange={(filter) => setFilter('description', filter)}
                    onInput={(filter) => setFilter('description', filter)}
                  />
                )
                : 'Description'}
            </TableCell>
            <TableCell className={tableClasses.customerCell}>
              {showFilters
                ? (
                  <MultipickerFilter
                    _key={subledger.id}
                    label="Vendor"
                    value={getFilterValueByFilterName(filters, 'vendorId')}
                    options={account.vendors}
                    onChange={(filter) => setFilter('vendorId', filter)}
                  />
                )
                : 'Vendor'}
            </TableCell>
            <TableCell className={tableClasses.expenseAccountCell}>
              {showFilters
                ? (
                  <MultipickerFilter
                    _key={subledger.id}
                    label="Expense Account"
                    value={getFilterValueByFilterName(filters, 'amortizationSchedule.destinationId')}
                    options={account.accountIncomes}
                    onChange={(filter) => setFilter('amortizationSchedule.destinationId', filter)}
                  />
                )
                : 'Expense Account'}
            </TableCell>
            {
              (account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) && (
                <TableCell className={tableClasses.classesCell}>
                  {showFilters
                    ? (
                      <MultipickerFilter
                        _key={subledger.id}
                        label="Class"
                        value={getFilterValueByFilterName(filters, 'amortizationSchedule.classId')}
                        options={account.accountClasses}
                        onChange={(filter) => setFilter('amortizationSchedule.classId', filter)}
                      />
                    )
                    : 'Class'}
                </TableCell>
              )
            }
            <TableCell className={tableClasses.datePickerCell}>
              Amortization Start Date
            </TableCell>
            <TableCell className={tableClasses.datePickerCell}>
              Amortization End Date
            </TableCell>
            <TableCell className={tableClasses.scheduleTypeCell}>
              Amort. Schedule
            </TableCell>
            <TableCell className={tableClasses.currencyCell}>
              {showFilters
                ? (
                  <AmountFilter
                    _key={subledger.id}
                    label="Amount"
                    value={getFilterValueByFilterName(filters, 'startingBalance')}
                    onChange={(filter) => setFilter('startingBalance', filter)}
                  />
                )
                : 'Amount'}
            </TableCell>
            <TableCell>
              {showFilters && (
                <ClearAllButton
                  disabled={!numberOfFiltersApplied}
                  onClick={clearAllFilters}
                />
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <UnScheduleAssets
            selectedRow={selectedRow}
            subledger={subledger}
            dispatch={dispatch}
            scheduleDate={scheduleDate!}
            unScheduledAssets={filteredAmortizationSources}
          />
        </TableBody>
      </Table>
      {filteredAmortizationSources.length === 0 && (
        <EmptyState
          description={numberOfFiltersApplied
            ? 'There are no search results for selected filters...'
            : 'Nothing to see here'}
        />
      )}
    </Box>
  );
};

export default SubledgerDetailsPrepaidExpense;
