import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash.find';
import {
  format,
  formatISO,
  isSameMonth,
  parseISO,
  subMonths,
} from 'date-fns';
import {
  Box,
  Button,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import TableComponent from '../../../Table';
import {
  FilterMatchingType,
  ITableItemType,
  ITableType,
  SortOrder,
} from '../../../../interfaces/types';
import { useFilters } from '../../../../hooks/useFilters';
import {
  getFilteredData,
  getFilterValueByFilterName,
} from '../../../../util/filtering';
import { TextSearchFilter } from '../../../Table/Filters/TextSearch';
import { AmountFilter } from '../../../Table/Filters/Amount';
import { MultipickerFilter } from '../../../Table/Filters/Multipicker';
import { DatepickerFilter } from '../../../Table/Filters/Datepicker/index';
import {
  DEFAULT_PAGE_SIZE,
  FULL_DATE_FORMAT,
  MONTH_YEAR_FORMAT,
} from '../../../../util/constants';
import currencyFormatter from '../../../../util/currencyFormatter';
import { StyledTableCell } from '../../../Table/StyledTableCell';
import { fixedAssetsListSelector } from '../../../../store/selectors/v2Subledgers';
import { vendorsListSelector } from '../../../../store/selectors/v2contacts';
import { assetTypesSelector } from '../../../../store/selectors/assetTypes';
import {
  FixedAsset,
  FixedAssetForm,
  ReviewScreenFixedAsset,
} from '../../../../interfaces/fixedAssets';
import COLORS from '../../../../theme/colors';
import {
  getScheduleTableProperties,
  reduceScheduleToSum,
} from '../../../../util/pages/FixedAssets/getScheduleTableProperties';
import { StyledLink } from '../../../StyledLink';
import {
  generateQBOLink,
  schedulingMethodOptions,
} from '../../../../util/helpers';
import { V2Subledger } from '../../../../interfaces/subledgers';
import { Contact } from '../../../../interfaces/contacts';
import { AssetType } from '../../../../interfaces/fixedAssetTypes';
import { LineClamp } from '../../../Table/LineClamp';
import { precisionRound } from '../../../../util/math';
import {
  scheduleAsOfdate,
  scheduleUnpostedSumReduce,
} from '../../../../util/pages/FixedAssets/calculateSubledgerDetails';

const useStyles = makeStyles(() => ({
  scheduleHeader: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  scheduleHeaderAmount: {
    padding: '6px',
    borderBottom: `solid 1px ${COLORS.disabled}`,
  },
  scheduleHeaderDate: {
    padding: '6px',
    textAlign: 'center',
  },
  scheduleCell: {
    padding: '0 !important',
    textAlign: 'center',
    minWidth: 90,

    '&:nth-child(2n)': {
      background: COLORS.lightGray3,
    },
  },
  scheduleCellFirst: {
    background: 'linear-gradient(90deg, #E6E6E6 0%, #FFFFFF 10px, #FFFFFF 100%)',
  },
}));

interface Props {
  subledgerDetails: V2Subledger;
  selectedDate: string;
  page: number;
  setPage: (page: number) => void;
}

export const FixedAssetsSchedulerReviewTable = ({
  subledgerDetails,
  selectedDate,
  page,
  setPage,
}: Props) => {
  const classes = useStyles();
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const vendors = useSelector(vendorsListSelector);
  const fixedAssetTypes = useSelector(assetTypesSelector);
  const allFixedAssets = useSelector(fixedAssetsListSelector);

  const getAdjustedFixedAssetsData = (data: Array<FixedAsset>) => {
    const onlyScheduledAssets = data.filter((asset) => asset.finalized && !asset.disposal);
    const isFirstPeriod = isSameMonth(parseISO(selectedDate), parseISO(subledgerDetails.startDate));

    const scheduledFixedAssets = onlyScheduledAssets.map((asset) => {
      const throughDepreciation = isFirstPeriod
        ? asset.priorDepreciation
        : precisionRound((scheduleAsOfdate(
          formatISO(subMonths(parseISO(selectedDate), 1)),
          subledgerDetails.startDate,
          asset.schedule!,
        ).reduce(scheduleUnpostedSumReduce, 0) || 0) + asset.priorDepreciation);

      return ({
        ...asset,
        throughDepreciation,
        remainingDepreciation: precisionRound(asset.originalValue - throughDepreciation),
      });
    });

    return scheduledFixedAssets;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const scheduledFixedAssets = useMemo(() => getAdjustedFixedAssetsData(allFixedAssets), [allFixedAssets]);

  const {
    id: subledgerId,
    startDate: factaStartDate,
  } = subledgerDetails;

  const initialFilters = useMemo(() => ([
    {
      name: 'glDate',
      sourcePath: ['transaction', 'postingDate'],
      matching: FilterMatchingType.DATE_RANGE,
    },
    {
      name: 'externalId',
      sourcePath: ['transaction', 'externalId'],
      matching: FilterMatchingType.STRING_INCLUDES,
    },
    {
      name: 'description',
      matching: FilterMatchingType.STRING_INCLUDES,
    },
    {
      name: 'vendorContactId',
      matching: FilterMatchingType.ARRAY_INCLUDES_PROPERTY,
      propertyPath: ['id'],
    },
    {
      name: 'fixedAssetTypeId',
      matching: FilterMatchingType.ARRAY_INCLUDES_PROPERTY,
      propertyPath: ['id'],
    },
    {
      name: 'inServiceDate',
      matching: FilterMatchingType.DATE_RANGE,
    },
    {
      name: 'schedulingMethod',
      matching: FilterMatchingType.ARRAY_INCLUDES_PROPERTY,
      propertyPath: ['value'],
    },
    {
      name: 'originalValue',
      matching: FilterMatchingType.NUMBER_COMPARE,
    },
    {
      name: 'throughDepreciation',
      matching: FilterMatchingType.NUMBER_COMPARE,
    },
    {
      name: 'remainingDepreciation',
      matching: FilterMatchingType.NUMBER_COMPARE,
    },
  ]), []);

  const {
    filters,
    setFilter,
    clearAllFilters,
  } = useFilters(initialFilters);

  const {
    filteredData,
    numberOfFiltersApplied,
  } = useMemo(
    () => getFilteredData(scheduledFixedAssets, filters),
    [filters, scheduledFixedAssets],
  );

  const filteredFixedAssets = filteredData as unknown as ReviewScreenFixedAsset[];

  const fixedAssets: Array<Partial<FixedAssetForm>> = filteredFixedAssets.map((asset) => ({
    glDate: asset.transaction?.postingDate || factaStartDate,
    id: asset.id,
    transaction: asset.transaction,
    description: asset.description || '',
    vendorContact: find(vendors, { id: asset.vendorContactId }) as Contact,
    fixedAssetType: find(fixedAssetTypes, { id: asset.fixedAssetTypeId }) as AssetType,
    inServiceDate: asset.inServiceDate
      ? parseISO(asset.inServiceDate)
      : undefined,
    schedulingMethod: find(schedulingMethodOptions, { value: asset.schedulingMethod }),
    finalized: asset.finalized,
    originalValue: asset.originalValue,
    throughDepreciation: asset.throughDepreciation,
    remainingDepreciation: asset.remainingDepreciation,
    schedule: asset.schedule,
  }));

  useEffect(() => {
    setPage(1);
  }, [filters, allFixedAssets, setPage]);

  const {
    distinctDatesVisiblePeriods,
    distinctDatesFuturePeriods,
    futureSum,
  } = useMemo(
    () => getScheduleTableProperties({ allFixedAssets, selectedDate }),
    [allFixedAssets, selectedDate],
  );

  const distinctDatesToIterate = [...distinctDatesVisiblePeriods, 'future'];

  const schedulerColProps = distinctDatesToIterate.map((columnDate, index) => {
    const isFutureColumn = columnDate === 'future';
    const sum = isFutureColumn
      ? futureSum
      : allFixedAssets
        .reduce((acc, curr) => precisionRound(
          acc + (curr.schedule
            ?.find((sch) => sch.effective === columnDate)
            ?.amount || 0),
        ), 0);

    return ({
      colName: columnDate,
      customHeader: (
        <Box className={classes.scheduleHeader}>
          <Box className={classes.scheduleHeaderAmount}>
            <strong>{currencyFormatter.format(sum)}</strong>
          </Box>
          <Box className={classes.scheduleHeaderDate}>
            {isFutureColumn
              ? 'Future'
              : format(parseISO(columnDate), MONTH_YEAR_FORMAT)}
          </Box>
        </Box>
      ),
      headerClassName: clsx(classes.scheduleCell, {
        [classes.scheduleCellFirst]: index === 0,
      }),
      varKey: `schedule${columnDate}`,
      colType: ITableItemType.TEXT,
      sortable: false,
      child: (value: FixedAsset) => {
        const amount = isFutureColumn
          ? value.schedule?.reduce(reduceScheduleToSum(distinctDatesFuturePeriods), 0)
          : value?.schedule?.find((sch) => sch.effective === columnDate)?.amount;

        return (
          <StyledTableCell
            key={columnDate}
            className={clsx(classes.scheduleCell, {
              [classes.scheduleCellFirst]: index === 0,
            })}
          >
            {amount
              ? currencyFormatter.format(amount)
              : '-'}
          </StyledTableCell>
        );
      },
    });
  });

  const colProps = [
    {
      colName: 'GL Date',
      customHeader: (
        <DatepickerFilter
          _key={subledgerId}
          label="GL Date"
          value={getFilterValueByFilterName(filters, 'glDate')}
          onChange={(filter) => setFilter('glDate', filter)}
        />
      ),
      varKey: 'glDate',
      colType: ITableItemType.DATE,
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="glDate"
          minWidth="150"
        >
          {value.glDate && format(parseISO(value.glDate), FULL_DATE_FORMAT)}
        </StyledTableCell>
      ),
    },
    {
      colName: 'ID',
      customHeader: (
        <TextSearchFilter
          _key={subledgerId}
          label="JE&nbsp;ID"
          value={getFilterValueByFilterName(filters, 'externalId')}
          onChange={(filter) => setFilter('externalId', filter)}
          onInput={(filter) => setFilter('externalId', filter)}
        />
      ),
      varKey: 'externalId',
      colType: ITableItemType.SORTKEY,
      sortKey: ['externalId'],
      child: (value: FixedAssetForm) => (
        <StyledTableCell key="externalId">
          {value.transaction
            ? (
              <StyledLink
                target="_blank"
                to={{ pathname: generateQBOLink(value.transaction.transactionType, value.transaction.externalId) }}
              >
                {value.transaction.externalId.split(':').shift()}
              </StyledLink>
            )
            : (
              <StyledLink to={`/fixed-assets/edit/${subledgerId}/#${value.id}`}>
                Open balance
              </StyledLink>
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: 'Description',
      customHeader: (
        <TextSearchFilter
          _key={subledgerId}
          label="Description"
          value={getFilterValueByFilterName(filters, 'description')}
          onChange={(filter) => setFilter('description', filter)}
          onInput={(filter) => setFilter('description', filter)}
        />
      ),
      varKey: 'description',
      colType: ITableItemType.TEXT,
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="description"
          minWidth="200"
        >
          <LineClamp text={value.description} />
        </StyledTableCell>
      ),
    },
    {
      colName: 'Vendor',
      customHeader: (
        <MultipickerFilter
          _key={subledgerId}
          label="Vendor"
          value={getFilterValueByFilterName(filters, 'vendorContactId')}
          options={vendors || []}
          onChange={(filter) => setFilter('vendorContactId', filter)}
        />
      ),
      varKey: 'vendorContact',
      colType: ITableItemType.SORTKEY,
      sortKey: ['name'],
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="vendorContact"
          minWidth="200"
        >
          {value.vendorContact?.name || ''}
        </StyledTableCell>
      ),
    },
    {
      colName: 'Asset Type',
      customHeader: (
        <MultipickerFilter
          _key={subledgerId}
          label="Asset Type"
          value={getFilterValueByFilterName(filters, 'fixedAssetTypeId')}
          options={fixedAssetTypes || []}
          onChange={(filter) => setFilter('fixedAssetTypeId', filter)}
        />
      ),
      varKey: 'fixedAssetType',
      colType: ITableItemType.SORTKEY,
      sortKey: ['name'],
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="fixedAssetType"
          minWidth="200"
        >
          {value.fixedAssetType?.name || ''}
        </StyledTableCell>
      ),
    },
    {
      colName: 'In-Service Date',
      customHeader: (
        <DatepickerFilter
          _key={subledgerId}
          label="In-Service Date"
          value={getFilterValueByFilterName(filters, 'inServiceDate')}
          onChange={(filter) => setFilter('inServiceDate', filter)}
        />
      ),
      varKey: 'inServiceDate',
      colType: ITableItemType.DATE,
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="inServiceDate"
          minWidth="200"
        >
          {value.inServiceDate && format(value.inServiceDate, FULL_DATE_FORMAT)}
        </StyledTableCell>
      ),
    },
    {
      colName: 'Original Amount',
      customHeader: (
        <AmountFilter
          _key={subledgerId}
          label="Original Amount"
          value={getFilterValueByFilterName(filters, 'originalValue')}
          onChange={(filter) => setFilter('originalValue', filter)}
        />
      ),
      varKey: 'originalValue',
      colType: ITableItemType.NUMMARY,
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="originalValue"
          minWidth="190"
          alignRight
        >
          {currencyFormatter.format(value.originalValue)}
        </StyledTableCell>
      ),
    },
    {
      colName: `Depreciation Through ${format(subMonths(parseISO(selectedDate), 1), MONTH_YEAR_FORMAT)}`,
      customHeader: (
        <AmountFilter
          _key={subledgerId}
          label={`Depreciation Through ${format(subMonths(parseISO(selectedDate), 1), MONTH_YEAR_FORMAT)}`}
          value={getFilterValueByFilterName(filters, 'throughDepreciation')}
          onChange={(filter) => setFilter('throughDepreciation', filter)}
        />
      ),
      varKey: 'throughDepreciation',
      colType: ITableItemType.NUMMARY,
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="throughDepreciation"
          minWidth="220"
          alignRight
        >
          {currencyFormatter.format(value.throughDepreciation)}
        </StyledTableCell>
      ),
    },
    {
      colName: 'Remaining Depreciation',
      customHeader: (
        <AmountFilter
          _key={subledgerId}
          label="Remaining Depreciation"
          value={getFilterValueByFilterName(filters, 'remainingDepreciation')}
          onChange={(filter) => setFilter('remainingDepreciation', filter)}
        />
      ),
      varKey: 'remainingDepreciation',
      colType: ITableItemType.NUMMARY,
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="remainingDepreciation"
          minWidth="190"
          alignRight
        >
          {currencyFormatter.format(value.remainingDepreciation)}
        </StyledTableCell>
      ),
    },
    ...schedulerColProps,
  ];

  const defaultSort = {
    key: 'transaction',
    order: SortOrder.DESC,
  };

  const handleSetPage = (p: number) => {
    setPage(p);
  };

  const emptyStateDescription = numberOfFiltersApplied
    ? (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        No results for selected filters
        <Button
          color="primary"
          variant="contained"
          onClick={clearAllFilters}
          style={{ marginTop: 10 }}
        >
          Clear all filters
        </Button>
      </Box>
    )
    : (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        Nothing to see here...
      </Box>
    );

  return (
    <>
      {fixedAssetTypes && (
        <TableComponent
          colProps={colProps}
          tableData={fixedAssets}
          defaultSort={defaultSort}
          contentType={ITableType.fixedAssetsAmortizationSources}
          topBarActions={[]}
          emptyStateDescription={emptyStateDescription}
          page={page}
          setPage={handleSetPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </>
  );
};
