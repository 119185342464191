import React, {
  useEffect,
  useState,
} from 'react';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { Alert } from '@material-ui/lab';
import { Box } from '@material-ui/core';
import {
  isEqual,
  isValid,
  lastDayOfMonth,
  parseISO,
} from 'date-fns';
import { AppThunkDispatch } from '../../../store/store';
import {
  clearAllV2Subledgers,
  getV2SubledgerDetails,
} from '../../../store/slices/v2Subledgers';
import {
  getAccountBalance,
  getAllAccounts,
} from '../../../store/slices/v2Accounts';
import { getAllContacts } from '../../../store/slices/v2Contacts';
import { getAssetTypes } from '../../../store/slices/assetTypes';
import { v2SubledgerSelectorFilteredUnfinalized } from '../../../store/selectors/v2Subledgers';
import GLBalancesInfo from '../../../components/Subledger/FixedAssets/GLBalancesInfo';
import { PeriodSelector } from '../../../components/Subledger/FixedAssets/PeriodSelector';
import {
  Breadcrumbs,
  BreadcrumbsItem,
} from '../../../components/BreadcrumbsStepper';
import { BottomActions } from '../../../components/Subledger/FixedAssets/BottomActions';
import { Loader } from '../../../components/Loader';
import { InformationBanner } from '../../../components/InformationBanner';
import { NotifierType } from '../../../interfaces/types';
import { calculateSubledgerDetails } from '../../../util/pages/FixedAssets/calculateSubledgerDetails';
import { SchedulerContainer } from '../../../components/Subledger/FixedAssets/SchedulerContainer';
import { parseToIncludeCurrentDay } from '../../../util/timezone';
import { lastV2SyncSelector } from '../../../store/selectors/v2Sync';

export const FixedAssetsSchedulerReview = () => {
  const history = useHistory();
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const { subledgerId, subledgerDate } = useParams<{ subledgerId: string; subledgerDate: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const lastFetch = useSelector(lastV2SyncSelector);
  const subledgerDetails = useSelector(v2SubledgerSelectorFilteredUnfinalized);

  const { allAssetsScheduled } = calculateSubledgerDetails({
    fixedAssets: subledgerDetails?.fixedAssets,
    asOfDate: subledgerDate,
  });
  const isDateValid = subledgerDate && isValid(parseISO(subledgerDate));
  const isCurrentPeriod = isEqual(
    lastDayOfMonth(parseISO(subledgerDetails?.currentPeriodEnd!)),
    lastDayOfMonth(parseISO(subledgerDate)),
  );

  useEffect(() => {
    if (isDateValid) {
      setIsLoading(true);
      Promise.all([
        reduxDispatch(getV2SubledgerDetails({
          subledgerId,
          date: subledgerDate,
        })),
        reduxDispatch(getAllAccounts({})),
        reduxDispatch(getAllContacts()),
        reduxDispatch(getAssetTypes()),
      ])
        .finally(() => setIsLoading(false));
    }

    return () => {
      reduxDispatch(clearAllV2Subledgers());
    };
  }, [isDateValid, subledgerId, subledgerDate, reduxDispatch, lastFetch]);

  useEffect(() => {
    if (subledgerDetails) {
      reduxDispatch(getAccountBalance({
        accountId: subledgerDetails.accountId,
        date: parseToIncludeCurrentDay(subledgerDate),
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subledgerDetails, reduxDispatch]);

  if (!isDateValid) {
    return (
      <Box padding={4}>
        <Alert severity="error">No date or wrong date provided.</Alert>
      </Box>
    );
  }

  const handleGoBack = () => {
    history.push(`/fixed-assets/scheduler/${subledgerId}/${subledgerDate}`);
  };

  const handleGoForward = () => {
    history.push(`/fixed-assets/scheduler/${subledgerId}/${subledgerDate}/prepare-je`);
  };

  return (
    <>
      <Loader open={isLoading} />
      {subledgerDetails && (
        <InformationBanner
          isOpenProp={!allAssetsScheduled}
          informationType={NotifierType.Warning}
          icon={false}
        >
          <span>
            <strong>Warning: </strong>
            There was a change in your general ledger resulting in new unscheduled transactions.
          </span>
        </InformationBanner>
      )}
      <Box padding={4}>
        <PeriodSelector
          selectedDate={parseISO(subledgerDate)}
          currentPeriodDate={parseISO(subledgerDate)}
          handleChange={() => {}}
          isDisabled
        />
        <Breadcrumbs style={{ marginTop: 32 }}>
          <BreadcrumbsItem
            step={1}
            checked
            action={handleGoBack}
          >
            Schedule Fixed Assets
          </BreadcrumbsItem>
          <BreadcrumbsItem
            step={2}
            active
          >
            Review Depreciation
          </BreadcrumbsItem>
          <BreadcrumbsItem
            step={3}
            action={allAssetsScheduled ? handleGoForward : undefined}
          >
            Prepare JE
          </BreadcrumbsItem>
        </Breadcrumbs>
        {!isLoading && subledgerDetails && (
          <GLBalancesInfo
            subledgerDetails={subledgerDetails}
            showScheduled
            selectedDate={subledgerDate}
          />
        )}
      </Box>
      {!isLoading && subledgerDetails && (
        <>
          <SchedulerContainer
            selectedDate={subledgerDate}
            subledgerDetails={subledgerDetails}
            isReview
            isCurrentPeriod={isCurrentPeriod}
          />
          <BottomActions
            menuItems={[
              {
                label: 'Schedule',
                variant: 'outlined',
                action: handleGoBack,
              },
              {
                label: 'Prepare JE',
                action: handleGoForward,
                disabled: !allAssetsScheduled,
              },
            ]}
          />
        </>
      )}
    </>
  );
};
