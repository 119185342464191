import React, {
  useEffect,
  useState,
} from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import { Box } from '@material-ui/core';
import find from 'lodash.find';
import Papa from 'papaparse';
import fileDownload from 'js-file-download';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import { AppThunkDispatch } from '../../../store/store';
import { assetTypesSelector } from '../../../store/selectors/assetTypes';
import TableComponent from '../../../components/Table';
import {
  ITableItemType,
  ITableType,
  SortOrder,
} from '../../../interfaces/types';
import { getAssetTypes } from '../../../store/slices/assetTypes';
import AddEditAssetType from '../../../components/Flyovers/AddEditAssetType';
import {
  AssetType,
  AssetTypeForm,
  UsefulLifeUnits,
} from '../../../interfaces/fixedAssetTypes';
import {
  TagCategories,
  TagDetails,
} from '../../../interfaces/tags';
import { accountsListSelector } from '../../../store/selectors/v2Accounts';
import {
  tagsClassListSelector,
  tagsLocationListSelector,
} from '../../../store/selectors/tags';
import { getAllTags } from '../../../store/slices/v2Tags';
import { getAllAccounts } from '../../../store/slices/v2Accounts';
import { lastV2SyncSelector } from '../../../store/selectors/v2Sync';
import {
  DEFAULT_PAGE_SIZE,
  HIGHLIGHT_RESET,
} from '../../../util/constants';
import { Loader } from '../../../components/Loader';
import { StyledTableCell } from '../../../components/Table/StyledTableCell';
import {
  DownloadExportIcon,
  ImportCSVIcon,
} from '../../../components/Icons';
import { LineClamp } from '../../../components/Table/LineClamp';
import { DEFAULT_ASSET_TYPE } from '../../../util/pages/FixedAssets/assetTypes';
import { AseetTypesActions } from './AssetTypesActions';
import { AssetTypesImportWizard } from '../../../components/Flyovers/CSVImportWizard/AssetTypesImportWizard';
import COLORS from '../../../theme/colors';

export const AssetTypes = () => {
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [isLoading, setIsLoading] = useState(true);
  const [highlighted, setHighlighted] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [flyover, setFlyover] = useState<{
    isOpen: boolean;
    assetType: AssetTypeForm;
  }>({
    isOpen: false,
    assetType: DEFAULT_ASSET_TYPE,
  });
  const [importFlyover, setImportFlyover] = useState(false);
  const lastFetch = useSelector(lastV2SyncSelector);
  const accounts = useSelector(accountsListSelector);
  const accountClasses = useSelector(tagsClassListSelector);
  const accountLocations = useSelector(tagsLocationListSelector);
  const assetTypes = useSelector(assetTypesSelector);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      reduxDispatch(getAssetTypes()),
      reduxDispatch(getAllTags()),
      reduxDispatch(getAllAccounts({})),
    ]).finally(() => {
      setIsLoading(false);
    });
  }, [reduxDispatch, lastFetch]);

  const defaultSort = {
    key: 'number',
    order: SortOrder.DESC,
  };

  const onDownload = (sortedTableValues: any[]) => {
    if (sortedTableValues?.length) {
      const assetTypesForCSV = sortedTableValues.map((assetType: any) => ({
        ...assetType,
        depreciationAccount: assetType.depreciationAccount.name || '',
        gainOrLossAccount: assetType.gainOrLossAccount.name || '',
        class: find(
          accountClasses,
          { id: assetType.tags?.find((tag: TagDetails) => tag?.category === TagCategories.CLASS)?.id },
        )?.name || '',
        location: find(
          accountLocations,
          { id: assetType.tags?.find((tag: TagDetails) => tag?.category === TagCategories.LOCATION)?.id },
        )?.name || '',
        usefulLife: `${assetType.usefulLifeValue} ${assetType.usefulLifeUnits.type}`,
      }));
      const csvData = Papa.unparse({
        data: assetTypesForCSV,
        fields: [
          'number',
          'name',
          'description',
          'depreciationAccount',
          'gainOrLossAccount',
          'class',
          'location',
          'usefulLife',
        ],
      }, {
        header: false,
      });
      const downloadData = `${[
        'Asset Type ID',
        'Asset Type Name',
        'Asset Type Description',
        'Depreciation Account',
        'Gain/Loss Account',
        'Class',
        'Location',
        'Useful Life',
      ].join(',')}\n${csvData}`;
      fileDownload(downloadData, 'assetTypes.csv');
    }
  };

  const colProps = [
    {
      colName: 'Asset Type ID',
      varKey: 'number',
      colType: ITableItemType.TEXT,
      child: (value: AssetType) => (
        <StyledTableCell
          key="number"
          minWidth="100"
        >
          {value.number}
        </StyledTableCell>
      ),
    },
    {
      colName: 'Asset Type Name',
      varKey: 'name',
      colType: ITableItemType.TEXT,
      child: (value: AssetTypeForm) => (
        <StyledTableCell
          key="name"
          style={{ whiteSpace: 'nowrap' }}
          minWidth="128"
        >
          {value.name}
        </StyledTableCell>
      ),
    },
    {
      colName: 'Asset Type Description',
      varKey: 'description',
      colType: ITableItemType.TEXT,
      child: (value: AssetTypeForm) => (
        <StyledTableCell key="description">
          <LineClamp text={value.description} />
        </StyledTableCell>
      ),
    },
    {
      colName: 'Depreciation Account',
      varKey: 'depreciationAccount',
      colType: ITableItemType.SORTKEY,
      sortKey: ['name'],
      child: (value: AssetTypeForm) => (
        <StyledTableCell key="depreciationAccount">
          <LineClamp text={value.depreciationAccount?.name || ''} />
        </StyledTableCell>
      ),
    },
    {
      colName: 'Gain/Loss Account',
      varKey: 'gainOrLossAccount',
      colType: ITableItemType.SORTKEY,
      sortKey: ['name'],
      child: (value: AssetTypeForm) => (
        <StyledTableCell key="gainOrLossAccount">
          <LineClamp text={value.gainOrLossAccount?.name || ''} />
        </StyledTableCell>
      ),
    },
    {
      colName: 'Class',
      varKey: 'class',
      colType: ITableItemType.SORTKEY,
      sortKey: ['name'],
      child: (value: AssetTypeForm) => (
        <StyledTableCell key="class">
          <LineClamp text={value.class?.name || ''} />
        </StyledTableCell>
      ),
    },
    {
      colName: 'Location',
      varKey: 'location',
      colType: ITableItemType.SORTKEY,
      sortKey: ['name'],
      child: (value: AssetTypeForm) => (
        <StyledTableCell key="location">
          <LineClamp text={value.location?.name || ''} />
        </StyledTableCell>
      ),
    },
    {
      colName: 'Useful Life',
      varKey: 'usefulLifeValueMonths',
      colType: ITableItemType.NUMMARY,
      child: (value: AssetTypeForm) => (
        <StyledTableCell
          key="usefulLife"
          setWidth="96"
        >
          {`${value.usefulLifeValue} ${value.usefulLifeUnits.type}`}
        </StyledTableCell>
      ),
    },
    {
      colName: 'Actions',
      varKey: 'actions',
      colType: ITableItemType.ACTION,
      alignRight: true,
      child: (value: AssetTypeForm) => (
        <StyledTableCell
          key="actions"
          width={80}
          alignRight
        >
          <AseetTypesActions
            assetType={value}
            setSelectedId={setSelectedId}
            setFlyover={setFlyover}
          />
        </StyledTableCell>
      ),
    },
  ];

  const parsedAssetTypes: Array<AssetTypeForm> = assetTypes.map((assetType) => ({
    id: assetType.id,
    number: assetType.number,
    visible: assetType.visible,
    name: assetType.name,
    description: assetType.description,
    depreciationAccount: find(accounts, { id: assetType.depreciationAccountId }),
    gainOrLossAccount: find(accounts, { id: assetType.gainOrLossAccountId }),
    usefulLifeUnits: { type: assetType.usefulLifeUnits },
    usefulLifeValue: assetType.usefulLifeValue,
    usefulLifeValueMonths: assetType.usefulLifeUnits === UsefulLifeUnits.MONTHS
      ? assetType.usefulLifeValue
      : assetType.usefulLifeValue * 12,
    class: find(accountClasses, { id: assetType.tags.find((tag) => tag.category === TagCategories.CLASS)?.id }),
    location: find(accountLocations, { id: assetType.tags.find((tag) => tag.category === TagCategories.LOCATION)?.id }),
    tags: assetType.tags,
  }));

  const handleAddAssetType = () => {
    setHighlighted('');
    setFlyover({
      isOpen: true,
      assetType: DEFAULT_ASSET_TYPE,
    });
  };

  const handleClose = (assetType?: AssetType) => {
    setFlyover({
      isOpen: false,
      assetType: flyover.assetType,
    });
    if (assetType?.id) {
      setHighlighted(assetType.id);
      setTimeout(() => setHighlighted(''), HIGHLIGHT_RESET);
    }
  };

  const handleSaveAndNew = (id?: string) => {
    id && setHighlighted(id);
  };

  const handleClickImportCSV = () => setImportFlyover(true);

  const topBarActions = [
    {
      label: 'Add Asset Type',
      icon: <AddCircleRoundedIcon
        color="primary"
        fontSize="small"
      />,
      iconPosition: 'left',
      isData: true,
      action: handleAddAssetType,
    },
    {
      label: 'Import CSV',
      icon: <ImportCSVIcon
        color="primary"
        fill={COLORS.skyBlue}
        fontSize="small"
      />,
      isData: true,
      action: handleClickImportCSV,
      pushRight: true,
    },
    {
      label: 'Export',
      icon: <DownloadExportIcon />,
      isData: true,
      action: onDownload,
    },
  ];

  return (
    <Box
      padding={2}
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      flexGrow={1}
    >
      <Loader open={isLoading} />
      {!isLoading && (
        <TableComponent
          colProps={colProps}
          tableData={parsedAssetTypes}
          defaultSort={defaultSort}
          contentType={ITableType.assetTypes}
          topBarActions={topBarActions}
          highlightedId={highlighted}
          selectedId={selectedId}
          emptyStateDescription="No results"
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
      <AddEditAssetType
        key={flyover.assetType.id}
        assetType={flyover.assetType}
        isOpen={flyover.isOpen}
        onClose={handleClose}
        onSaveAndNew={handleSaveAndNew}
      />
      <AssetTypesImportWizard
        isOpen={importFlyover}
        setOpen={setImportFlyover}
      />
    </Box>
  );
};
