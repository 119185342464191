import styled, { css } from 'styled-components';
import COLORS from '../../../theme/colors';

interface Props {
  minWidth?: string;
  alignRight?: boolean;
  withError?: boolean;
  setWidth?: string;
  verticalAlign?: string;
}
export const StyledTableCell = styled.td.withConfig({
  shouldForwardProp: (prop) => !['minWidth', 'alignRight', 'withError', 'setWidth', 'verticalAlign'].includes(prop),
})<Props>`
  position: relative;
  font-size: 12px;
  vertical-align: ${({ verticalAlign }) => (verticalAlign || 'middle')};
  color: ${({ withError }) => (withError ? COLORS.error : 'inherit')};
  border-bottom: 1px dashed ${COLORS.lightGray2};
  line-height: 16px;
  height: 46px;
  padding: 8px;
  ${({ minWidth }) => (minWidth && css`
    min-width: ${minWidth}px;
  `)}
  ${({ alignRight }) => (alignRight && css`
    text-align: right;
    padding: 8px 28px 8px 8px;
  `)}
  ${({ withError }) => (withError && css`
    background-color: ${COLORS.errorBackground};
  `)}

  ${({ setWidth }) => (setWidth && css`
    width: ${setWidth}px;
  `)}

  &:hover {
    & > [data-hidden] {
      opacity: 1;
    }
  }

  thead & {
    color: ${COLORS.lightGray};
    border-bottom: 1px solid ${COLORS.lightGray};
    vertical-align: middle;
  }

  .MuiTable-stickyHeader thead & {
    top: 0;
    left: 0;
    z-index: 2;
    position: sticky;
    background-color: ${COLORS.white}
  }
`;
