/* eslint-disable react/jsx-props-no-spreading,max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import {
  createStyles,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  refreshButton: {
    marginLeft: 10,
    cursor: 'pointer',
    fontSize: 10,
  },
}));

const RefreshIcon = (props: any) => {
  const classes = useStyles();
  return (
    <SvgIcon
      width="9"
      height="8"
      viewBox="0 0 9 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.refreshButton}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.938 5.5627C2.2335 6.04705 2.66025 6.43771 3.16876 6.68937C3.67727 6.94103 4.2467 7.04338 4.81102 6.98455C5.37534 6.92573 5.91141 6.70813 6.35708 6.357C6.80275 6.00586 7.13975 5.53559 7.329 5.0007H8.374C8.18225 5.74187 7.78191 6.41247 7.22048 6.93294C6.65905 7.45341 5.9601 7.80191 5.20656 7.93707C4.45302 8.07224 3.67653 7.98841 2.9692 7.69553C2.26187 7.40265 1.65341 6.91301 1.216 6.2847L0 7.5007V4.5007H3L1.938 5.5627ZM7.0625 2.4382C6.76685 1.95379 6.33994 1.56309 5.83128 1.31143C5.32262 1.05977 4.75304 0.957443 4.18859 1.01632C3.62414 1.0752 3.08795 1.29287 2.64217 1.64409C2.1964 1.99531 1.85931 2.4657 1.67 3.0007H0.625C0.81668 2.25926 1.21705 1.58836 1.7786 1.06764C2.34014 0.546916 3.03929 0.198225 3.79307 0.062944C4.54685 -0.0723366 5.32362 0.0114733 6.0312 0.304428C6.73877 0.597383 7.34745 1.08718 7.785 1.7157L9 0.500704V3.5007H6L7.0625 2.4382Z"
        fill="#11ADF8"
      />
    </SvgIcon>
  );
};

export default RefreshIcon;
