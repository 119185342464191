import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Collapse,
  IconButton,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { NotifierType } from '../../interfaces/types';

interface Props {
  isOpenProp?: boolean;
  informationType: NotifierType;
  icon: boolean;
  children: React.ReactNode;
}

const useStyles = makeStyles({
  wrapper: {
    width: '100%',
  },
  alertClass: {
    margin: 32,
    flexDirection: 'row',
  },
});

export const InformationBanner = ({
  informationType,
  children,
  isOpenProp,
  icon,
}: Props) => {
  const [isOpen, setOpen] = useState(isOpenProp);
  const classes = useStyles();

  return (
    <Collapse
      className={classes.wrapper}
      in={isOpen}
    >
      <Alert
        severity={informationType}
        variant="outlined"
        icon={icon}
        className={classes.alertClass}
        action={(
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        )}
      >
        {children}
      </Alert>
    </Collapse>
  );
};
