import React from 'react';
import {
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core';
import find from 'lodash.find';
import COLORS from '../../../../theme/colors';
import { LegacySchedulingMethod } from '../../../../interfaces/types';
import { SchedulingMethod } from '../../../../interfaces/common';
import {
  schedulingMethodOptions,
  legacySchedulingMethodOptions,
} from '../../../../util/helpers';

const useStyles = makeStyles(() => ({
  wrapperBox: {
    display: 'flex',
    position: 'relative',
    fontSize: '13px',
    marginLeft: '15px',
    paddingLeft: '15px',
    '&::before': {
      content: "''",
      position: 'absolute',
      left: '0',
      width: '1px',
      height: '100%',
      backgroundColor: COLORS.lightGray2,
    },
  },
  property: {
    paddingRight: '8px',
    '&:not(:first-child)': {
      paddingLeft: '20px',
    },
  },
  value: {
    fontWeight: 'bold',
  },
}));

interface Props {
  accountName: string;
  factaStartDate: string;
  amortizationScheduleType: LegacySchedulingMethod | SchedulingMethod;
}

const TopBarSubledgerInfo = ({
  accountName,
  factaStartDate,
  amortizationScheduleType,
}: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapperBox}>
      <Typography className={classes.property}>ACCOUNT</Typography>
      <Typography className={classes.value}>{accountName}</Typography>
      <Typography className={classes.property}>Facta Start Date</Typography>
      <Typography className={classes.value}>{factaStartDate}</Typography>
      <Typography className={classes.property}>Amortization Schedule</Typography>
      <Typography className={classes.value}>
        {find([...schedulingMethodOptions, ...legacySchedulingMethodOptions], { value: amortizationScheduleType })
          ?.label}
      </Typography>
    </Box>
  );
};

export default TopBarSubledgerInfo;
