import React from 'react';
import PrepareJE from './PrepareJE';
import Layout from '../../../components/Layout';

interface Props {
  scheduleDate: string;
}

const PrepareJEContainer = ({
  scheduleDate,
}: Props) => (
  <Layout title="REVIEW SCHEDULE">
    <PrepareJE
      scheduleDate={scheduleDate}
    />
  </Layout>
);

export default PrepareJEContainer;
