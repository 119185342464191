import React, {
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import { format } from 'date-fns';
import roundTo from 'round-to';
import cloneDeep from 'lodash.clonedeep';
import { useRefreshDataProvider } from '../../../context/dataRefreshContext';
import {
  Account,
  AmortizationSource,
  BulkEditorType,
  NotifierType,
} from '../../../interfaces/types';
import {
  ADD_SPLIT,
  ADD_SPLIT_ASSET,
  ASSET_SCHEDULED_STATUS,
  BULK_EDIT,
  DAY_SHORT_FORMAT,
  FINALIZED_SUBLEDGER_STATUS,
  INITIALED,
  INPUT_FOCUS,
  SELECT_CHANGE,
  SELECT_ROW,
  UNSCHEDULED_DATE_CHANGE,
  UNSCHEDULED_INPUT_CHANGE,
  UNSCHEDULED_REMOVE_ASSET,
  UNSCHEDULED_SELECT_CHANGE,
  UPDATE_ASSET,
  UPDATE_ASSETS,
} from '../../../util/constants';
import {
  checkSchedulerAmortizationSourceDirtyCheck,
  getChildren,
  getPrepaidAssetsForSave,
  monthChange,
  schedulerExportToCSV,
  updateAssetsForSaving,
} from '../../../components/Subledger/common';
import ScheduleHeader from './ScheduleHeader';
import reducer from '../../../components/Subledger/reducer';
import ErrorPage from '../../../components/ErrorPage';
import { openSnackbar } from '../../../components/Notifier';
import { deleteSubledger } from '../../../util/subledger';
import PrepaidSchedulerTable from '../../../components/Subledger/PrepaidSchedulerTable';
import { AppThunkDispatch } from '../../../store/store';
import {
  getSubledgerDetailsByScheduleDate,
  patchSchedulerAmortizationSources,
  patchUnscheduledAmortizationSources,
} from '../../../store/slices/subledger';
import {
  accountInfoSelector,
  accountLastFetchSelector,
} from '../../../store/selectors/account';
import {
  fetchingSubledgerError,
  isFetchingSubledger,
  isUpdatingSubledger,
} from '../../../store/selectors/subledger';
import { Loader } from '../../../components/Loader';

interface Props {
  scheduleDate: string;
  accountBalance: Account;
}

interface Params {
  id: string;
}

const Scheduler = ({
  scheduleDate,
  accountBalance,
}: Props) => {
  const { id: subledgerId } = useParams<Params>();
  const history = useHistory();
  const { refreshDate } = useRefreshDataProvider();
  const reduxDispatch: AppThunkDispatch = useDispatch();

  const account = useSelector(accountInfoSelector)!;
  const subledgerLoading = useSelector(isFetchingSubledger);
  const subledgerError = useSelector(fetchingSubledgerError);
  const isUpdating = useSelector(isUpdatingSubledger);
  const lastFetch = useSelector(accountLastFetchSelector);

  const {
    accountClasses,
    vendors,
    accountIncomes,
    customers,
    products,
  } = account!;

  // @ts-ignore
  const [state, dispatch] = useReducer(reducer, {
    subledger: {},
    selectedRow: '',
    initSubledger: {},
  });

  const [selectedSources, setSelectedSources] = useState<string[]>([]);
  const [bulkEditorType, setBulkEditorType] = useState(BulkEditorType.NONE);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [isBulkEditApplied, setIsBulkEditApplied] = useState<boolean>(false);

  const getData = useCallback(async () => {
    if (!subledgerLoading && !subledgerError) {
      reduxDispatch(getSubledgerDetailsByScheduleDate({
        subledgerId,
        scheduleDate,
        requiredAmortizationToDate: true,
      }))
        .unwrap()
        .then((result) => {
          if (result.status !== FINALIZED_SUBLEDGER_STATUS) {
            history.push(`/subledgers/scheduler/${subledgerId}`);
          }
          dispatch({
            type: INITIALED,
            payload: {
              subledger: result,
              scheduleDate,
            },
          });
        });
    }
  }, [
    history,
    reduxDispatch,
    scheduleDate,
    subledgerError,
    subledgerId,
    subledgerLoading,
  ]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastFetch, refreshDate]);

  const navigateToPrepareJE = () => {
    history.push(`/subledgers/schedule/${subledger.id}/prepare-je/?scheduleDate=${scheduleDate}`);
  };

  const {
    subledger,
    selectedRow,
    initSubledger,
  } = state;

  useEffect(() => {
    if (selectedSources.length > 1 && selectedRow) {
      dispatch({
        type: SELECT_ROW,
        payload: { selectedRow: '' },
      });
    }

    if (!bulkEditorType && selectedSources.length === 1) {
      const type = !subledger?.amortizationSources
        .find((source: AmortizationSource) => source.internalId === selectedSources[0]).status
        ? BulkEditorType.UNSCHEDULED : BulkEditorType.SCHEDULED;

      setBulkEditorType(type);
    }

    if (bulkEditorType) {
      const isScheduled = bulkEditorType === BulkEditorType.SCHEDULED;
      const amortizationSourcesLength = subledger
        .amortizationSources?.filter((source: AmortizationSource) => getChildren(
          source.internalId,
          subledger?.amortizationSources,
        ).length === 0
        && !source.parentId && (isScheduled ? source.status : !source.status))?.length;

      (selectedSources.length === amortizationSourcesLength && amortizationSourcesLength > 0)
        ? setIsAllSelected(true)
        : setIsAllSelected(false);

      selectedSources.length === 0 && setBulkEditorType(BulkEditorType.NONE);
    }
  }, [bulkEditorType, selectedSources, selectedRow, dispatch, subledger.amortizationSources]);

  useEffect(() => {
    if (isBulkEditApplied) {
      const amortizationSources = cloneDeep(subledger.amortizationSources
        .filter((source: AmortizationSource) => selectedSources.includes(source.internalId)));

      const isAmortizationSourcesInvalid = amortizationSources
        .some((source: AmortizationSource) => !checkSchedulerAmortizationSourceDirtyCheck(source, subledger));

      if (!isAmortizationSourcesInvalid) {
        updateAssetsForSaving(amortizationSources);
        reduxDispatch(patchUnscheduledAmortizationSources({
          subledgerId,
          amortizationSources,
          productCategory: subledger.productCategory,
        }))
          .unwrap()
          .then((result) => {
            dispatch({
              type: UPDATE_ASSETS,
              payload: {
                assets: result?.amortizationSources,
                // @ts-ignore
                balance: roundTo(result?.balance, 2),
                internalIds: selectedSources,
              },
            });
          });
      } else {
        openSnackbar({ message: 'Unable to proceed with schedule, incorrect data.' }, NotifierType.Error);
      }

      onEditClose();
      setIsBulkEditApplied(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBulkEditApplied]);

  if (subledgerLoading && !subledger) {
    return <Loader open />;
  }

  if (subledgerError) {
    return <ErrorPage />;
  }

  const onSave = (existingAsset: AmortizationSource) => async () => {
    const {
      amortizationSource,
      amortizationSources,
    } = await getPrepaidAssetsForSave(existingAsset, dispatch, scheduleDate, subledger);
    if (!amortizationSource) {
      return;
    }
    if (amortizationSource.status === ASSET_SCHEDULED_STATUS) {
      reduxDispatch(patchSchedulerAmortizationSources({
        subledgerId,
        amortizationSources,
        productCategory: subledger.productCategory,
      }))
        .unwrap()
        .then((result) => {
          dispatch({
            type: UPDATE_ASSET,
            payload: {
              assets: result,
              balance: subledger.openingBalance,
              internalId: amortizationSource.internalId,
            },
          });
        });
    } else {
      reduxDispatch(patchUnscheduledAmortizationSources({
        subledgerId,
        amortizationSources,
        productCategory: subledger.productCategory,
      }))
        .unwrap()
        .then((result) => {
          dispatch({
            type: UPDATE_ASSET,
            payload: {
              assets: result?.amortizationSources,
              // @ts-ignore
              balance: roundTo(result?.balance, 2),
              internalId: amortizationSource.internalId,
            },
          });
        });
    }
  };

  const addSplit = (internalId: string) => () => {
    dispatch({
      type: ADD_SPLIT,
      payload: { internalId },
    });
  };

  const addSplitAsset = (internalId: string) => () => {
    dispatch({
      type: ADD_SPLIT_ASSET,
      payload: { internalId },
    });
  };

  const removeAsset = (internalId: string) => () => {
    dispatch({
      type: UNSCHEDULED_REMOVE_ASSET,
      payload: { internalId },
    });
  };

  const onDateChange = (propertyName: string, internalId: string, value: Date | null) => {
    dispatch({
      type: UNSCHEDULED_DATE_CHANGE,
      payload: {
        internalId,
        propertyName,
        value,
        scheduleDate,
      },
    });
  };

  const onAutoCompleteChange = (propertyName: string, internalId: string) => (value: string) => {
    dispatch({
      type: UNSCHEDULED_SELECT_CHANGE,
      payload: {
        internalId,
        propertyName,
        value,
        scheduleDate,
      },
    });
  };

  const onInputBoxChange = (
    propertyName: string,
    internalId: string,
  ) => (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: UNSCHEDULED_INPUT_CHANGE,
      payload: {
        internalId,
        propertyName,
        value: event.target.value,
        scheduleDate,
      },
    });
  };

  const onInputBoxFocus = (
    propertyName: string,
    internalId: string,
  ) => (event: React.FocusEvent<HTMLInputElement>) => {
    dispatch({
      type: INPUT_FOCUS,
      payload: {
        propertyName,
        internalId,
        target: event.target,
        type: event.type,
      },
    });
  };

  const onRowSelect = (internalId: string) => () => {
    if (selectedSources.length > 1) {
      return;
    }

    dispatch({
      type: SELECT_ROW,
      payload: { selectedRow: internalId },
    });
  };

  const isSaveEnabled = (asset: AmortizationSource) => (
    checkSchedulerAmortizationSourceDirtyCheck(asset, subledger, initSubledger)
  );

  const onSelectAutoCompleteChange = (propertyName: string, internalId: string) => (value: string) => {
    dispatch({
      type: SELECT_CHANGE,
      payload: {
        internalId,
        propertyName,
        value,
      },
    });
  };

  const onCSVExport = () => {
    schedulerExportToCSV({
      subledger,
      vendors,
      accountClasses,
      accountIncomes,
      account,
      scheduleDate,
      customers,
      products,
    });
  };

  const onMonthChange = (previousDateType: Date) => {
    const {
      equalDates,
      scheduleDateToNavigate,
    } = monthChange(previousDateType, subledger);
    const date = format(scheduleDateToNavigate, DAY_SHORT_FORMAT);
    if (!equalDates) {
      deleteSubledger();
      history.push(`/historical/subledgers/schedule/${subledger.id}/?scheduleDate=${date}`);
    } else {
      history.push(`/subledgers/schedule/${subledger.id}/?scheduleDate=${date}`);
    }
  };

  const onBulkEditApply = (internalIds: Array<string>, changedProperties: any) => {
    dispatch({
      type: BULK_EDIT,
      payload: {
        internalIds,
        changedProperties,
        scheduleDate,
      },
    });
  };

  const onBulkEditSchedule = (internalIds: Array<string>, changedProperties: any) => {
    onBulkEditApply(internalIds, changedProperties);
    setIsBulkEditApplied(true);
  };

  const onCheckboxChange = (internalId: string) => {
    selectedSources.includes(internalId)
      ? setSelectedSources(selectedSources.filter((source) => source !== internalId))
      : setSelectedSources([...selectedSources, internalId]);
  };

  const clearSelected = () => {
    setSelectedSources([]);
    setBulkEditorType(BulkEditorType.NONE);
  };

  const onSelectAll = (assetsType: BulkEditorType) => {
    const isScheduled = assetsType === BulkEditorType.SCHEDULED;
    const handleSelectAll = (type: BulkEditorType) => {
      setSelectedSources(subledger.amortizationSources
        .filter((source: AmortizationSource) => getChildren(
          source.internalId,
          subledger?.amortizationSources,
        ).length === 0
          && !source.parentId && (isScheduled ? source.status : !source.status))
        .map((source: AmortizationSource) => source.internalId));
      setBulkEditorType(type);
      setIsAllSelected(true);
    };

    if (isAllSelected && assetsType === bulkEditorType) {
      clearSelected();
    } else {
      handleSelectAll(assetsType);
    }
  };

  const onEditClose = () => {
    clearSelected();
    setIsAllSelected(false);
  };

  const isElementSelected = (internalId: string) => selectedSources.includes(internalId);
  const isAllSelectedByType = (type: BulkEditorType) => isAllSelected && bulkEditorType === type;

  return (
    <>
      <Loader open={subledgerLoading || isUpdating} />
      <ScheduleHeader
        subledger={subledger}
        scheduleDate={scheduleDate}
        accountBalance={accountBalance}
        downloadCSV={onCSVExport}
        navigateToPrepareJE={navigateToPrepareJE}
        onMonthChange={onMonthChange}
      />
      <PrepaidSchedulerTable
        subledger={subledger}
        selectedRow={selectedRow}
        scheduleDate={scheduleDate}
        onSave={onSave}
        onRowSelect={onRowSelect}
        isSaveEnabled={isSaveEnabled}
        onSelectAutoCompleteChange={onSelectAutoCompleteChange}
        onInputBoxChange={onInputBoxChange}
        onInputBoxFocus={onInputBoxFocus}
        removeAsset={removeAsset}
        onDateChange={onDateChange}
        onAutoCompleteChange={onAutoCompleteChange}
        addSplitAsset={addSplitAsset}
        addSplit={addSplit}
        onBulkEditApply={onBulkEditApply}
        onBulkEditSchedule={onBulkEditSchedule}
        onEditClose={onEditClose}
        isElementSelected={isElementSelected}
        onCheckboxChange={onCheckboxChange}
        isAllSelected={isAllSelectedByType}
        bulkEditorType={bulkEditorType}
        onSelectAll={onSelectAll}
        selectedSources={selectedSources}
      />
    </>
  );
};

export default Scheduler;
