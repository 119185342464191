/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import DialogBox from './index';
import { deleteToken } from '../../util/auth';

let openSnackbarFn: Function;

interface State {
  open: boolean;
}

class SessionExpiredDialog extends React.Component<{}, State> {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    open: false,
  };

  componentDidMount() {
    openSnackbarFn = this.openSnackbar;
  }

  handleSnackbarClose = () => {
    this.setState({
      open: false,
    });
  };

  openSnackbar = () => {
    this.setState({ open: true });
  };

  onClose = () => {
    deleteToken();
    window.location.href = '/';
  };

  render() {
    const { open } = this.state;

    return (
      <DialogBox
        openDialog={open}
        closeDialog={this.onClose}
        dialogContext="Your session is expired"
        dialogTitle="Alert"
        dismissContext="Login Again"
      />
    );
  }
}

export const openSessionExpiredDialog = () => {
  openSnackbarFn();
};

export default SessionExpiredDialog;
