import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import {
  AppBar,
  Typography,
} from '@material-ui/core';

import Button from '@material-ui/core/Button';
import COLORS from '../../theme/colors';

export const StyledAppBar = styled(AppBar)({
  border: 'none',
  boxShadow: 'none',
  backgroundColor: COLORS.lightGray3,
});

export const TopBarContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  bgcolor: `${COLORS.lightGray3}`,
  width: '100%',
  padding: '20px 24px',
});

export const TopBarItem = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  margin: '0 14px',
  minHeight: '18px',
});

export const PageTitle = styled(Typography)({
  fontSize: 16,
  textTransform: 'uppercase',
  color: COLORS.lightGray,
  fontWeight: 600,
  margin: '0 14px',
});

export const TopBarLabel = styled(Typography)({
  fontSize: 12,
  marginRight: 9,
  color: COLORS.medGray,
  textTransform: 'capitalize',
});

export const TopBarBoldLabel = styled(TopBarLabel)({
  fontWeight: 600,
});

export const StyledResyncButton = styled(Button)({
  border: `1px solid ${COLORS.medGray}`,
  color: COLORS.medGray,
  marginLeft: 8,
  paddingLeft: 12,
  paddingRight: 12,
  whiteSpace: 'nowrap',
});
