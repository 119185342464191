import React from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useTableStyles } from './Subledger.styled';
import { accountInfoSelector } from '../../store/selectors/account';
import { REVSYNC_HEADER_CELL_100 } from '../../util/constants';
import { FactaCheckbox } from '../Checkbox';

interface Props {
  showBulkEdit: boolean;
  onSelectAll: () => void;
  isAllSelected: boolean;
  amortizationSourcesLength: number;
}

const PrepaidSchedulerRevSyncTableHeader = ({
  showBulkEdit,
  onSelectAll,
  isAllSelected,
  amortizationSourcesLength,
}: Props) => {
  const account = useSelector(accountInfoSelector)!;
  const tableClasses = useTableStyles();
  return (
    <TableHead>
      <TableRow
        hover={false}
      >
        {showBulkEdit && (
          <TableCell is-cell-sticky="true">
            <FactaCheckbox
              checked={isAllSelected}
              disabled={amortizationSourcesLength <= 0}
              onChange={() => onSelectAll()}
            />
          </TableCell>
        )}
        <TableCell className={tableClasses.assetCell}>
          JE Date
        </TableCell>
        <TableCell className={tableClasses.assetCell}>
          Trans. #
        </TableCell>
        <TableCell className={tableClasses.descriptionCell}>
          Description
        </TableCell>
        <TableCell className={tableClasses.customerCell}>
          Customer
        </TableCell>
        <TableCell className={tableClasses.customerCell}>
          <Box maxWidth={REVSYNC_HEADER_CELL_100}>
            Product/Service
          </Box>
        </TableCell>
        <TableCell className={tableClasses.expenseAccountCell}>
          <Box maxWidth={REVSYNC_HEADER_CELL_100}>
            Revenue Account
          </Box>
        </TableCell>

        {
          (account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) && (
            <TableCell className={tableClasses.classesCell}>
              Class
            </TableCell>
          )
        }
        <TableCell className={tableClasses.datePickerCell}>
          Recognition Start Date
        </TableCell>
        <TableCell className={tableClasses.datePickerCell}>
          Recognition End Date
        </TableCell>
        <TableCell className={tableClasses.scheduleTypeCell}>
          Recog. Schedule
        </TableCell>
        <TableCell className={tableClasses.currencyCell}>
          Amount
        </TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default React.memo(PrepaidSchedulerRevSyncTableHeader);
