import React from 'react';
import clsx from 'clsx';
import {
  Box,
  createStyles,
  makeStyles,
  Typography,
} from '@material-ui/core';
import COLORS from '../../theme/colors';
import currencyFormatter from '../../util/currencyFormatter';
import { TooltipHeader } from '../TooltipHeader';
import { LoadingBox } from '../LoadingBox';

interface Props {
  headerText: string;
  tooltipText?: string;
  amount: number;
  withType: boolean;
  isAmountHighlighted?: boolean;
  isLoading?: boolean;
}

const useStyles = makeStyles(() => createStyles({
  wrapper: {
    position: 'relative',
    padding: '0 32px',
    borderLeft: `1px solid ${COLORS.disabled}`,
  },
  mainContent: {
    padding: '8px 0',
  },
  withType: {
    padding: '8px 16px',
    backgroundColor: COLORS.lightGrayBackground,
    borderRadius: '4px',
  },
  summaryValue: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 600,
  },
  highlighted: {
    color: COLORS.violet,
  },
}));

const AccountBalanceCard = ({
  withType,
  headerText,
  isAmountHighlighted,
  amount,
  tooltipText,
  isLoading,
}: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      {isLoading && <LoadingBox transparent />}
      <Box
        className={clsx(classes.mainContent, {
          [classes.withType]: withType,
        })}
      >
        <TooltipHeader
          headerText={headerText}
          tooltipText={tooltipText}
        />
        <Typography
          className={clsx(classes.summaryValue, {
            [classes.highlighted]: isAmountHighlighted,
          })}
        >
          {currencyFormatter.format(amount)}
        </Typography>
      </Box>
    </Box>
  );
};

export default AccountBalanceCard;
