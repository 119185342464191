import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ArrowRight = (props: any) => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ fontSize: '24px' }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 7L15 12L10 17V7Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default ArrowRight;
