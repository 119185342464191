import { createSelector } from '@reduxjs/toolkit';
import { ContactTypes } from '../../interfaces/contacts';
import { RootState } from '../store';

const contactsSelector = (state: RootState) => state.v2Contacts;

export const contactsListSelector = createSelector(
  contactsSelector,
  (contacts) => contacts.contacts,
);

export const vendorsListSelector = createSelector(
  contactsSelector,
  (contacts) => contacts.contacts.filter((contact) => contact.contactType === ContactTypes.VENDOR),
);

export const customersListSelector = createSelector(
  contactsSelector,
  (contacts) => contacts.contacts.filter((contact) => contact.contactType === ContactTypes.CUSTOMER),
);

export const isFetchingContacts = createSelector(
  contactsSelector,
  (contacts) => contacts.isFetchingContacts,
);
