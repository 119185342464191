import {
  Box,
  Button,
} from '@material-ui/core';
import clsx from 'clsx';
import { format } from 'date-fns';
import React from 'react';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { ClassNameMap } from '@material-ui/styles';
import { getAccountName } from '../../../components/Subledger/common';
import { StyledTableCell } from '../../../components/Table/StyledTableCell';
import {
  Filter,
  FilterValue,
  ITableItemType,
  LegacyJournalEntry,
  SortOrder,
} from '../../../interfaces/types';
import { FULL_DAY_FORMAT } from '../../../util/constants';
import currencyFormatter from '../../../util/currencyFormatter';
import { fromUTC } from '../../../util/timezone';
import getFullName from '../../../util/user';
import { DatepickerFilter } from '../../../components/Table/Filters/Datepicker';
import { getFilterValueByFilterName } from '../../../util/filtering';
import { TextSearchFilter } from '../../../components/Table/Filters/TextSearch';
import { AmountFilter } from '../../../components/Table/Filters/Amount';

export const v1DefaultSort = {
  key: 'txnDate',
  order: SortOrder.DESC,
};

interface GetV1ColProps {
  accountName: string;
  classes: ClassNameMap<any>;
  onView: (id: string) => void;
  onEdit: (txtDate: string) => void;
  filters: Array<Filter>;
  setFilter: (filterName: string, filterValue: FilterValue) => void;
  clearFilters: () => void;
  filtersApplied: boolean;
}

export const getV1ColProps = ({
  accountName,
  classes,
  onView,
  onEdit,
  filters,
  setFilter,
  clearFilters,
  filtersApplied,
}: GetV1ColProps) => [
  {
    colName: 'FACTA JE #',
    varKey: 'jeNumber',
    customHeader: (
      <TextSearchFilter
        _key={accountName}
        label="FACTA JE #"
        value={getFilterValueByFilterName(filters, 'jeNumber')}
        onChange={(filter) => setFilter('jeNumber', filter)}
        onInput={(filter) => setFilter('jeNumber', filter)}
      />
    ),
    colType: ITableItemType.TEXT,
    child: (value: LegacyJournalEntry) => (
      <StyledTableCell key="factaJENumber">
        {value.jeNumber}
      </StyledTableCell>
    ),
  },
  {
    colName: 'GL JE #',
    varKey: 'jeNumber',
    colType: ITableItemType.TEXT,
    child: (value: LegacyJournalEntry) => (
      <StyledTableCell key="JENumber">
        {value.jeNumber}
      </StyledTableCell>
    ),
  },
  {
    colName: 'GL Account',
    varKey: '',
    colType: ITableItemType.TEXT,
    child: (value: LegacyJournalEntry) => (
      <StyledTableCell key="GLAccount">
        {getAccountName(value.subledger?.account!)}
      </StyledTableCell>
    ),
  },
  {
    colName: 'JE Date',
    varKey: 'txnDate',
    customHeader: (
      <DatepickerFilter
        _key={accountName}
        label="JE Date"
        value={getFilterValueByFilterName(filters, 'txnDate')}
        onChange={(filter) => setFilter('txnDate', filter)}
      />
    ),
    colType: ITableItemType.DATE,
    child: (value: LegacyJournalEntry) => (
      <StyledTableCell key="txnDate">
        {format(fromUTC(value.txnDate as string), FULL_DAY_FORMAT)}
      </StyledTableCell>
    ),
  },
  {
    colName: 'JE Description',
    varKey: 'description',
    customHeader: (
      <TextSearchFilter
        _key={accountName}
        label="JE Description"
        value={getFilterValueByFilterName(filters, 'description')}
        onChange={(filter) => setFilter('description', filter)}
        onInput={(filter) => setFilter('description', filter)}
      />
    ),
    colType: ITableItemType.TEXT,
    child: (value: LegacyJournalEntry) => (
      <StyledTableCell key="description">
        {value.description}
      </StyledTableCell>
    ),
  },
  {
    colName: 'User',
    varKey: 'user',
    colType: ITableItemType.USERNAME,
    child: (value: LegacyJournalEntry) => (
      <StyledTableCell key="user">
        {getFullName(value?.user)}
      </StyledTableCell>
    ),
  },
  {
    colName: 'Updated at',
    varKey: 'updatedAt',
    colType: ITableItemType.DATE,
    child: (value: LegacyJournalEntry) => (
      <StyledTableCell key="updatedAt">
        {format(fromUTC(value.updatedAt as string), FULL_DAY_FORMAT)}
      </StyledTableCell>
    ),
  },
  {
    colName: 'Amount',
    varKey: 'amount',
    customHeader: (
      <AmountFilter
        _key={accountName}
        label="Amount"
        value={getFilterValueByFilterName(filters, 'amount')}
        onChange={(filter) => setFilter('amount', filter)}
      />
    ),
    colType: ITableItemType.NUMMARY,
    child: (value: LegacyJournalEntry) => (
      <StyledTableCell key="amount">
        {currencyFormatter.format(value.amount)}
      </StyledTableCell>
    ),
  },
  {
    colName: 'Status',
    varKey: 'status',
    colType: ITableItemType.TEXT,
    child: (value: LegacyJournalEntry) => (
      <StyledTableCell key="status">
        {value.status ? value?.status : 'Posted'}
      </StyledTableCell>
    ),
  },
  {
    colName: 'edit',
    varKey: 'edit',
    customHeader: (
      <Button
        color="primary"
        variant="outlined"
        fullWidth
        onClick={clearFilters}
        disabled={!filtersApplied}
      >
        Clear filters
      </Button>
    ),
    sortable: false,
    colType: ITableItemType.TEXT,
    child: (value: LegacyJournalEntry) => (
      <StyledTableCell
        className={clsx(classes.colorBlue, classes.width5)}
        key="id"
      >
        <Box display="flex">
          <Button
            onClick={() => onView(value?.id)}
            color="primary"
          >
            View
          </Button>
          <Button
            color="primary"
            onClick={() => onEdit(value.txnDate as string)}
          >
            Edit
            <EditOutlinedIcon className={clsx(classes.colorBlue, classes.editIcon)} />
          </Button>
        </Box>
      </StyledTableCell>
    ),
  },
];
