import React, {
  useRef,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {
  createStyles,
  Divider,
  styled,
  Theme,
} from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
  AccountIcon,
  BalanceIcon,
  RevSyncIcon,
  PrepaidIcon,
  ReportsIcon,
  SettingsIcon,
} from '../Icons';
import COLORS from '../../theme/colors';
import { deleteToken } from '../../util/auth';
import { useNavBarContext } from '../../context/navbarContext';
import {
  NAVBAR_OPEN_WIDTH,
  NAVBAR_CLOSED_WIDTH,
  SIDEBAR_EXPAND_DELAY,
} from '../../util/constants';
import { featureSwitch } from '../../util/featureSwitch';
import { isCompanyAvailableSelector } from '../../store/selectors/account';
import FixedAssetsIcon from '../Icons/FixedAssetsIcon';
import { NavigationItem } from './NavigationItem';
import { DevInfo } from './DevInfo';
import { postV2Logout } from '../../store/slices/v2Sync';
import { AppThunkDispatch } from '../../store/store';

const useStyles = makeStyles((theme: Theme) => createStyles({
  navBarIcon: {
    fontSize: 15,
    opacity: 0.3,
  },
  drawer: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1,
  },
  drawerOpen: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    width: NAVBAR_OPEN_WIDTH,
  },
  drawerClose: {
    backgroundColor: COLORS.black,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: NAVBAR_CLOSED_WIDTH,
  },
  collapseButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    minWidth: 'auto',
    color: COLORS.white,
  },
  menuContainer: {
    marginBottom: 'auto',
    overflowX: 'hidden',
    paddingBottom: '16px',
  },
}));

export const StyledDivider = styled(Divider)({
  backgroundColor: COLORS.white,
  opacity: 0.2,
  width: '94%',
  marginLeft: 5,
  marginRight: 5,
});

const Sidebar = () => {
  const { navBarOpen, setNavBarOpen } = useNavBarContext();
  const [navBarTextVisible, setNavBarTextVisible] = useState(navBarOpen);
  const [navBarHovered, setNavBarHovered] = useState(false);
  const timer1 = useRef<any>(null);
  const timer2 = useRef<any>(null);
  const classes = useStyles();
  const history = useHistory();
  const isCompanyAvailable = useSelector(isCompanyAvailableSelector);
  const reduxDispatch: AppThunkDispatch = useDispatch();

  const onLogout = () => {
    reduxDispatch(postV2Logout())
      .unwrap()
      .then(() => {
        deleteToken();
        history.push('/login');
      });
  };

  const handleSidebarEnter = () => {
    timer1.current = setTimeout(() => setNavBarTextVisible(true), SIDEBAR_EXPAND_DELAY + 100);
    timer2.current = setTimeout(() => setNavBarHovered(true), SIDEBAR_EXPAND_DELAY);
  };

  const handleSidebarLeave = () => {
    clearInterval(timer1.current);
    clearInterval(timer2.current);
    setNavBarTextVisible(false);
    setNavBarHovered(false);
  };

  const sidebarTopItems = [
    {
      Icon: PrepaidIcon,
      label: 'PrepaidSync',
      tooltip: 'Prepaid Expenses',
      path: '/',
      exact: true,
      disabled: !isCompanyAvailable,
    },
    {
      Icon: RevSyncIcon,
      label: 'RevSync',
      tooltip: 'Deferred Revenue',
      path: '/revsync',
      exact: false,
      disabled: !isCompanyAvailable,
    },
    {
      Icon: FixedAssetsIcon,
      label: 'Fixed Assets',
      tooltip: 'Fixed Assets',
      path: '/fixed-assets',
      exact: false,
      disabled: !isCompanyAvailable,
      hidden: !featureSwitch.fixedAssets,
    },
    {
      Icon: AccountIcon,
      label: 'Chart of Accounts',
      tooltip: 'Chart of Accounts',
      path: '/account',
      exact: false,
      disabled: !isCompanyAvailable,
    },
    {
      Icon: BalanceIcon,
      label: 'Journal Entries',
      tooltip: 'Journal Entries',
      path: '/journal-entries',
      exact: false,
      disabled: !isCompanyAvailable,
    },
    {
      Icon: ReportsIcon,
      label: 'Reports',
      tooltip: 'Reports',
      path: '/reports',
      exact: false,
      disabled: !isCompanyAvailable,
      hidden: !featureSwitch.reports,
    },
  ];

  const sidebarBottomItems = [
    {
      label: 'Settings',
      path: '/settings',
      exact: false,
      Icon: SettingsIcon,
      disabled: false,
      subItems: [
        {
          label: 'Open Settings',
          path: '/settings/profile',
        },
        {
          label: 'Logout',
          path: isCompanyAvailable ? '/settings/profile' : '/settings/connect',
          action: onLogout,
        },
      ],
    },
  ];

  return (
    <Box
      height="100%"
      bgcolor={COLORS.black}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: navBarOpen || navBarHovered,
        [classes.drawerClose]: !navBarOpen && !navBarHovered,
      })}
      onMouseEnter={handleSidebarEnter}
      onMouseLeave={handleSidebarLeave}
    >
      <Box
        flexDirection="column"
        display="flex"
        alignItems="space-between"
        justifyContent="flex-start"
        height="100%"
        m={0}
        p={0}
        overflow="hidden"
      >
        <Box
          display="flex"
          height={74}
          padding={1}
        >
          {process.env.REACT_APP_ENV === 'development' && (
            <DevInfo>
              {process.env.REACT_APP_API_URL}
            </DevInfo>
          )}
          <ButtonBase>
            <img
              src="/full_logo.png"
              alt="logo"
              height={40}
            />
          </ButtonBase>
        </Box>
        <StyledDivider />
        <Box className={classes.menuContainer}>
          <NavigationItem
            sidebarItem={{
              Icon: navBarOpen ? ChevronLeft : ChevronRight,
              label: navBarOpen ? 'Unpin' : 'Pin',
              path: '/expandCollapse',
              exact: false,
              disabled: !isCompanyAvailable,
              action: () => {
                setNavBarOpen(!navBarOpen);
                setNavBarHovered(false);
                setNavBarTextVisible(false);
              },
              customStyle: { color: COLORS.buttonBlack, marginTop: '8px' },
            }}
            navBarOpen={navBarOpen}
            navBarTextVisible={navBarTextVisible}
          />
          {sidebarTopItems.map((sidebarItem) => !sidebarItem.hidden && (
            <NavigationItem
              key={sidebarItem.label}
              sidebarItem={sidebarItem}
              navBarOpen={navBarOpen}
              navBarTextVisible={navBarTextVisible}
            />
          ))}
        </Box>
        <StyledDivider />
        <Box
          flexDirection="column"
          display="flex"
          alignItems="center"
          justifyContent="center"
          pb={2}
        >
          {sidebarBottomItems.map((sidebarItem) => (
            <NavigationItem
              key={sidebarItem.label}
              sidebarItem={sidebarItem}
              navBarOpen={navBarOpen}
              navBarTextVisible={navBarTextVisible}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
