import get from 'lodash.get';
import {
  endOfDay,
  startOfDay,
} from 'date-fns';
import { path } from 'ramda';
import {
  AmountFilterValue,
  DateRangeFilterValue,
  Filter,
  FilterMatchingType,
} from '../interfaces/types';

export const getFilterValueByFilterName = (filters: Array<Filter>, filterName: string) => filters
  .find((filter) => filter.name === filterName)?.value! as any;

const testAmountFilter = ({
  compareMethod,
  value1,
  value2,
  multiplicator = 1,
}: AmountFilterValue,
filteredProperty: any) => {
  const val1 = Number(value1) * multiplicator;
  const val2 = Number(value2) * multiplicator;

  switch (compareMethod) {
    case 'gt':
      return value1 && filteredProperty > val1;
    case 'gte':
      return value1 && filteredProperty >= val1;
    case 'lt':
      return value1 && filteredProperty < val1;
    case 'lte':
      return value1 && filteredProperty <= val1;
    case 'eq':
      return value1 && filteredProperty === val1;
    case 'neq':
      return value1 && filteredProperty !== val1;
    case 'in':
      return value1 && value2 && filteredProperty >= val1 && filteredProperty <= val2;
    case 'nin':
      return value1 && value2 && (filteredProperty <= val1 || filteredProperty >= val2);
    default:
      return true;
  }
};

export const getFilteredData = (
  data: Array<any>,
  filters: Array<Filter>,
) => {
  const numberOfFiltersApplied = filters.reduce((acc: number, cur: Filter) => (cur.value ? acc + 1 : acc), 0);

  if (!numberOfFiltersApplied) {
    return {
      filteredData: data,
      numberOfFiltersApplied,
    };
  }

  const filteredData = data.filter((source) => {
    const matches: Array<boolean> = [];

    filters.forEach((filter) => {
      const filteredProperty = get(source, filter.sourcePath || filter.name);

      switch (filter.matching) {
        case FilterMatchingType.STRING_INCLUDES: {
          if (!filteredProperty) {
            matches.push(true);
            break;
          }
          if (!filter.value) {
            matches.push(true);
            break;
          }
          if ((filter.value && filteredProperty.toLowerCase().indexOf((filter.value as string).toLowerCase()) !== -1)) {
            matches.push(true);
          } else {
            matches.push(false);
          }
          break;
        }

        case FilterMatchingType.ARRAY_INCLUDES_PROPERTY: {
          const { propertyPath } = filter;

          if (!filter.value || (filter.value as Array<any>).length === 0) {
            matches.push(true);
            break;
          }
          if (!filteredProperty) {
            matches.push(false);
            break;
          }
          if ((filter.value as Array<any>)
            .filter((val) => filteredProperty === path(propertyPath!, val)).length) {
            matches.push(true);
          } else {
            matches.push(false);
          }
          break;
        }

        case FilterMatchingType.NUMBER_COMPARE: {
          if (!filter.value || (filter.value as Array<any>).length === 0) {
            matches.push(true);
            break;
          }
          if (!filteredProperty) {
            matches.push(false);
            break;
          }
          if (testAmountFilter(filter.value as AmountFilterValue, filteredProperty)) {
            matches.push(true);
          } else {
            matches.push(false);
          }
          break;
        }

        case FilterMatchingType.DATE_RANGE: {
          if (!filter.value) {
            matches.push(true);
            break;
          }
          if (!filteredProperty) {
            matches.push(false);
            break;
          }

          const filteredPropertyTimestamp = startOfDay(new Date(filteredProperty)).getTime();
          const filterValue = (filter.value as DateRangeFilterValue);

          if (filteredPropertyTimestamp >= startOfDay(filterValue.startDate as Date).getTime()
              && filteredPropertyTimestamp <= endOfDay(filterValue.endDate as Date).getTime()) {
            matches.push(true);
          } else {
            matches.push(false);
          }
          break;
        }

        case FilterMatchingType.LIFE_COMPARE: {
          if (!filter.value || (filter.value as Array<any>).length === 0) {
            matches.push(true);
            break;
          }
          if (!filteredProperty) {
            matches.push(false);
            break;
          }
          if (testAmountFilter(filter.value as AmountFilterValue, filteredProperty.difference)) {
            matches.push(true);
          } else {
            matches.push(false);
          }
          break;
        }
      }
    });

    /**
     * matches: <Array<boolean>> holds a set of matching information for a ceratin asset.
     * This set of information consists of bool values saying if filter criteria have been met.
     * If any of filters returns `false` this certain asset is not being returned.
     */
    return !matches.includes(false);
  });

  return {
    filteredData,
    numberOfFiltersApplied,
  };
};
