import React from 'react';
import { useHistory } from 'react-router';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button } from '@material-ui/core';

interface Props {
  label?: string;
  path?: string;
}

export const BackButton = ({ path, label }: Props) => {
  const history = useHistory();

  return (
    <Button
      onClick={() => (path ? history.push(path) : history.goBack())}
      color="primary"
      variant="outlined"
      size="small"
      startIcon={<ArrowBackIcon />}
      style={{ margin: 0 }}
    >
      {label || 'Back'}
    </Button>
  );
};
