import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import debounce from 'lodash.debounce';
import clsx from 'clsx';
import {
  Button,
  Popper,
  Paper,
  Box,
  ClickAwayListener,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { POPPER_ELEMENTS_Z_INDEX } from '../../../../util/constants';
import { StyledInput } from '../../../Inputs/index.styled';
import { commonFiltersStyles } from '../common/index.styled';

interface ITextSearchFilter {
  _key: React.Key;
  label: string;
  value?: string;
  onChange: (filter?: string) => void;
  onInput?: (filter?: string) => void;
}

export const TextSearchFilter = ({
  _key,
  label,
  value,
  onChange,
  onInput,
}: ITextSearchFilter) => {
  const [open, setOpen] = useState(false);
  const [innerValue, setInnerValue] = useState(value);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const styles = commonFiltersStyles();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnInput = useCallback(debounce(onInput || (() => {}), 200), []);

  const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
    setOpen(!open);
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInnerValue(e.currentTarget.value);
    debouncedOnInput(e.currentTarget.value);
  };

  const applyFilter = () => {
    onChange(innerValue);
    setOpen(false);
    setAnchorEl(null);
  };

  const clearFilter = () => {
    onChange(undefined);
    setOpen(false);
    setAnchorEl(null);
  };

  const handleCancel = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === 'Escape') {
      clearFilter();
    }
  };

  useEffect(() => {
    setInnerValue(undefined);
  }, [_key]);

  useEffect(() => {
    setInnerValue(value || undefined);
  }, [value]);

  return (
    <>
      <Button
        type="button"
        onClick={handleOpen}
        fullWidth
        variant="outlined"
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        className={clsx(styles.filterButton, {
          [styles.selected]: !!value,
        })}
      >
        {label}
        {value && <span className={styles.filterDot} />}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="top-start"
        style={{
          zIndex: POPPER_ELEMENTS_Z_INDEX,
          minWidth: '250px',
        }}
        transition
      >
        <ClickAwayListener onClickAway={handleCancel}>
          <Paper
            className={styles.paper}
            elevation={3}
          >
            <form
              noValidate
              autoComplete="off"
            >
              <StyledInput
                value={innerValue || ''}
                onChange={handleChange}
                onKeyDown={onKeyDown}
                className={styles.searchField}
                autoFocus
                placeholder="Search"
              />
              <Box className={styles.buttonBox}>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={clearFilter}
                  size="small"
                >
                  Clear
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  onClick={applyFilter}
                  fullWidth
                  variant="contained"
                  size="small"
                  className={styles.applyButton}
                >
                  Apply
                </Button>
              </Box>
            </form>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
