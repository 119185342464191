/* eslint-disable no-param-reassign */
import axios from 'axios';
import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit';
import {
  NotifierType,
  Subledger,
  Account,
  AmortizationSource,
  ProductCategory,
  LegacyJournalEntry,
} from '../../interfaces/types';
import { openSnackbar } from '../../components/Notifier';
import { FACTA_SOURCE } from '../../util/constants';
import mapErrorMessage from '../../util/errorMessage';
import { sortAmortizationLogs } from '../../util/middleware';

const PREFIX = 'subledger';
const GET_SUBLEDGER_DETAILS = `${PREFIX}/getSubledgerDetails`;
const GET_SUBLEDGER_DETAILS_BY_SCHEDULE_DATE = `${PREFIX}/getSubledgerDetailsByScheduleDate`;
const GET_BALANCE_DATA = `${PREFIX}/getBalanceData`;
const PATCH_SUBLEDGER_AMORTIZATION_SOURCES = `${PREFIX}/patchSubledgerAmortizationSources`;
const PATCH_AMORTIZATION_SOURCES = `${PREFIX}/patchAmortizationSources`;
const PUT_SUBLEDGER = `${PREFIX}/putSubledger`;
const PATCH_UNSCHEDULED_AMORTIZATION_SOURCES = `${PREFIX}/patchUnscheduledAmortizationSources`;
const PATCH_SCHEDULER_AMORTIZATION_SOURCES = `${PREFIX}/patchSchedulerAmortizationSources`;
const PATCH_MANUAL_AMORTIZATION_SOURCES = `${PREFIX}/patchManualAmortizationSources`;
const POST_JOURNAL_ENTRY = `${PREFIX}/postJournalEntry`;
const PUT_SUBLEDGER_REFRESH = `${PREFIX}/putSubledgerRefresh`;

const POST_CREATE_SUBLEDGER = `${PREFIX}/postCreateSubledger`;

// TODO: Probably to be moved to JE slice
const GET_JOURNAL_ENTRY_DATA = `${PREFIX}/getJournalEntryData`;

interface State {
  isFetchingSubledger: boolean;
  isFetchingBalanceData: boolean;
  isUpdating: boolean;
  subledger?: Subledger;
  errorFetchingSubledger?: any;
  errorFetchingBalanceData?: any;
  errorUpdating?: any;
  isFetchingJournalEntry: boolean;
  errorFetchingJournalEntry?: any;
}

const initialState: State = {
  isFetchingSubledger: false,
  isFetchingBalanceData: false,
  isUpdating: false,
  isFetchingJournalEntry: false,
};

export const postCreateSubledger = createAsyncThunk<Subledger, {
  accountId: string;
}>(
  POST_CREATE_SUBLEDGER,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<Subledger>('/subledgers', params);

      return sortAmortizationLogs(data);
    } catch (error: any) {
      const handledError = mapErrorMessage(error.response?.data || error.message);
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const getSubledgerDetails = createAsyncThunk<Subledger, {
  subledgerId: string;
}>(
  GET_SUBLEDGER_DETAILS,
  async ({ subledgerId }, { rejectWithValue }) => {
    try {
      const { data } = await axios
        .get<Subledger>(`/subledgers/${subledgerId}/details?source=${FACTA_SOURCE}&ignorePrepaidAssetsSorting=true`);

      return sortAmortizationLogs(data);
    } catch (error: any) {
      const handledError = mapErrorMessage(error.response?.data || error.message);
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const getSubledgerDetailsByScheduleDate = createAsyncThunk<Subledger, {
  subledgerId: string;
  scheduleDate: string;
  requiredAmortizationToDate?: boolean;
}>(
  GET_SUBLEDGER_DETAILS_BY_SCHEDULE_DATE,
  async ({
    subledgerId,
    scheduleDate,
    requiredAmortizationToDate,
  }, { rejectWithValue }) => {
    try {
      const url = `/subledgers/${subledgerId}/details?scheduleDate=${scheduleDate}`
      + `${requiredAmortizationToDate ? '&requiredAmortizationToDate=true' : ''}`;
      const { data } = await axios.get<Subledger>(url);

      return sortAmortizationLogs(data);
    } catch (error: any) {
      const handledError = mapErrorMessage(error.response?.data || error.message);
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const getBalanceData = createAsyncThunk<Account, {
  subledgerId: string;
  lastDayOfSelectedDate: string;
}>(
  GET_BALANCE_DATA,
  async ({ subledgerId, lastDayOfSelectedDate }, { rejectWithValue }) => {
    try {
      const { data } = await axios
        .get<Account>(`/subledgers/${subledgerId}/account/balance?selectedDate=${lastDayOfSelectedDate}`);

      return data;
    } catch (error: any) {
      const handledError = mapErrorMessage(error.response?.data || error.message);
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const patchSubledgerAmortizationSources = createAsyncThunk<null, {
  subledgerId: string;
  amortizationSources: Array<AmortizationSource>;
  productCategory: ProductCategory;
}>(
  PATCH_SUBLEDGER_AMORTIZATION_SOURCES,
  async ({ subledgerId, amortizationSources, productCategory }, { rejectWithValue }) => {
    try {
      await axios.patch(`/subledgers/${subledgerId}/historical/subledger/amortization-sources`, amortizationSources);

      return null;
    } catch (error: any) {
      const handledError = mapErrorMessage(error.response?.data || error.message, productCategory);
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const patchAmortizationSources = createAsyncThunk<null, {
  subledgerId: string;
  amortizationSources: Array<AmortizationSource>;
  productCategory: ProductCategory;
}>(
  PATCH_AMORTIZATION_SOURCES,
  async ({
    subledgerId,
    amortizationSources,
    productCategory,
  }, { rejectWithValue }) => {
    try {
      await axios.patch(`/subledgers/${subledgerId}/historical/amortization-sources`, amortizationSources);

      return null;
    } catch (error: any) {
      const handledError = mapErrorMessage(error.response?.data || error.message, productCategory);
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(error);
    }
  },
);

export const patchManualAmortizationSources = createAsyncThunk<null, {
  subledgerId: string;
  amortizationSources: Array<AmortizationSource>;
  productCategory: ProductCategory;
}>(
  PATCH_MANUAL_AMORTIZATION_SOURCES,
  async ({ subledgerId, amortizationSources, productCategory }, { rejectWithValue }) => {
    try {
      await axios.patch(`/subledgers/${subledgerId}/manual/amortization-sources`, amortizationSources);

      return null;
    } catch (error: any) {
      const handledError = mapErrorMessage(error.response?.data || error.message, productCategory);
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const putSubledger = createAsyncThunk<Subledger, {
  subledgerId: string;
  updatedSubledger: Subledger;
}>(
  PUT_SUBLEDGER,
  async ({ subledgerId, updatedSubledger }, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(`/subledgers/${subledgerId}`, updatedSubledger);

      return sortAmortizationLogs(data);
    } catch (error: any) {
      const handledError = mapErrorMessage(error.response?.data || error.message, updatedSubledger.productCategory);
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const patchUnscheduledAmortizationSources = createAsyncThunk<Subledger, {
  subledgerId?: string;
  amortizationSources?: Array<AmortizationSource>;
  productCategory: ProductCategory;
}>(
  PATCH_UNSCHEDULED_AMORTIZATION_SOURCES,
  async ({ subledgerId, amortizationSources, productCategory }, { rejectWithValue }) => {
    try {
      const { data } = await axios
        .patch<Subledger>(`/subledgers/${subledgerId}/unscheduled/amortization-sources`, amortizationSources);

      return sortAmortizationLogs(data);
    } catch (error: any) {
      const handledError = mapErrorMessage(error.response?.data || error.message, productCategory);
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const getJournalEntryData = createAsyncThunk<LegacyJournalEntry, {
  subledgerId: string;
  scheduleDate: string;
}>(
  GET_JOURNAL_ENTRY_DATA,
  async ({ subledgerId, scheduleDate }, { rejectWithValue }) => {
    try {
      const { data } = await axios
        .get<LegacyJournalEntry>(`/journal-entries/${subledgerId}/subledger?scheduleDate=${scheduleDate}`);

      return data;
    } catch (error: any) {
      const handledError = mapErrorMessage(error.response?.data || error.message);
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const patchSchedulerAmortizationSources = createAsyncThunk<Subledger, {
  subledgerId?: string;
  amortizationSources?: Array<AmortizationSource>;
  productCategory: ProductCategory;
}>(
  PATCH_SCHEDULER_AMORTIZATION_SOURCES,
  async ({ subledgerId, amortizationSources, productCategory }, { rejectWithValue }) => {
    try {
      const { data } = await axios
        .patch<Subledger>(`/subledgers/${subledgerId}/scheduler/amortization-sources`, amortizationSources);

      return sortAmortizationLogs(data);
    } catch (error: any) {
      const handledError = mapErrorMessage(error.response?.data || error.message, productCategory);
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const putSubledgerRefresh = createAsyncThunk<null, {
  subledgerId: string;
  sourceId: string;
}>(
  PUT_SUBLEDGER_REFRESH,
  async ({ subledgerId, sourceId }, { rejectWithValue }) => {
    try {
      await axios.put(`/subledgers/${subledgerId}/refresh/${sourceId}`);

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const postJournalEntry = createAsyncThunk<null, {
  subledgerID: string;
  scheduleDate: string;
  jeNumber: string;
  description: string;
  postingDate: string;
  productCategory?: ProductCategory;
}>(
  POST_JOURNAL_ENTRY,
  async (params, { rejectWithValue }) => {
    try {
      await axios.post('/subledgers/journal-entry', params);

      return null;
    } catch (error: any) {
      const handledError = mapErrorMessage(error.response?.data || error.message, params.productCategory);
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(error);
    }
  },
);

export const subledgerSlice = createSlice({
  initialState,
  name: PREFIX,
  reducers: {
    clearSubledger: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getBalanceData.pending, (state) => {
      state.isFetchingBalanceData = true;
      state.errorFetchingBalanceData = undefined;
    });
    builder.addCase(getBalanceData.fulfilled, (state) => {
      state.isFetchingBalanceData = false;
    });
    builder.addCase(getBalanceData.rejected, (state, { payload }) => {
      state.isFetchingBalanceData = false;
      state.errorFetchingBalanceData = payload;
    });

    builder.addCase(getJournalEntryData.pending, (state) => {
      state.isFetchingJournalEntry = true;
      state.errorFetchingJournalEntry = undefined;
    });
    builder.addCase(getJournalEntryData.fulfilled, (state) => {
      state.isFetchingJournalEntry = false;
    });
    builder.addCase(getJournalEntryData.rejected, (state, { payload }) => {
      state.isFetchingJournalEntry = false;
      state.errorFetchingJournalEntry = payload;
    });

    builder.addCase(postCreateSubledger.pending, (state) => {
      state.isFetchingSubledger = true;
      state.errorFetchingSubledger = undefined;
    });
    builder.addCase(postCreateSubledger.fulfilled, (state) => {
      state.isFetchingSubledger = false;
    });
    builder.addCase(postCreateSubledger.rejected, (state, { payload }) => {
      state.isFetchingSubledger = false;
      state.errorFetchingSubledger = payload;
    });

    builder.addMatcher(isAnyOf(
      getSubledgerDetails.pending,
      getSubledgerDetailsByScheduleDate.pending,
    ), (state) => {
      state.isFetchingSubledger = true;
      state.errorFetchingSubledger = undefined;
    });

    builder.addMatcher(isAnyOf(
      getSubledgerDetails.fulfilled,
      getSubledgerDetailsByScheduleDate.fulfilled,
    ), (state, { payload }) => {
      state.subledger = payload;
      state.isFetchingSubledger = false;
    });

    builder.addMatcher(isAnyOf(
      getSubledgerDetails.rejected,
      getSubledgerDetailsByScheduleDate.rejected,
    ), (state, { payload }) => {
      state.isFetchingSubledger = false;
      state.errorFetchingSubledger = payload;
    });

    builder.addMatcher(isAnyOf(
      patchSubledgerAmortizationSources.pending,
      patchAmortizationSources.pending,
      putSubledger.pending,
      patchUnscheduledAmortizationSources.pending,
      patchSchedulerAmortizationSources.pending,
      putSubledgerRefresh.pending,
      postJournalEntry.pending,
    ), (state) => {
      state.isUpdating = true;
      state.errorUpdating = undefined;
    });

    builder.addMatcher(isAnyOf(
      patchSubledgerAmortizationSources.fulfilled,
      patchAmortizationSources.fulfilled,
      putSubledger.fulfilled,
      patchUnscheduledAmortizationSources.fulfilled,
      patchSchedulerAmortizationSources.fulfilled,
      putSubledgerRefresh.fulfilled,
      postJournalEntry.fulfilled,
    ), (state) => {
      state.isUpdating = false;
    });

    builder.addMatcher(isAnyOf(
      patchSubledgerAmortizationSources.rejected,
      patchAmortizationSources.rejected,
      putSubledger.rejected,
      patchUnscheduledAmortizationSources.rejected,
      patchSchedulerAmortizationSources.rejected,
      putSubledgerRefresh.rejected,
      postJournalEntry.rejected,
    ), (state, { payload }) => {
      state.isUpdating = false;
      state.errorUpdating = payload;
    });
  },
});

export const {
  clearSubledger,
} = subledgerSlice.actions;

export const subledgerReducer = subledgerSlice.reducer;
