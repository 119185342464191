/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit';
import { request } from '../../util/request';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../interfaces/types';
import { V2Subledger } from '../../interfaces/subledgers';
import { ProductTypes } from '../../interfaces/common';
import { FixedAsset } from '../../interfaces/fixedAssets';
import { TagDetails } from '../../interfaces/tags';
import { handleAuthError } from '../../util/auth';

const PREFIX = 'v2Subledgers';
const POST_V2_CREATE_SUBLEDGER = `${PREFIX}/postV2CreateSubledger`;
const PATCH_V2_SUBLEDGER = `${PREFIX}/patchV2Subledger`;
const GET_V2_SUBLEDGER = `${PREFIX}/getV2Subledger`;
const GET_V2_SUBLEDGERS = `${PREFIX}/getV2Subledgers`;
const POST_V2_FINALIZE_SUBLEDGER = `${PREFIX}/getV2FinalizeSubledger`;

const CREATE_FIXED_ASSET = `${PREFIX}/createFixedAsset`;
const UPDATE_FIXED_ASSET = `${PREFIX}/updateFixedAsset`;
const BULK_UPDATE_FIXED_ASSETS = `${PREFIX}/bulkUpdateFixedAssets`;
const BULK_FINALIZE_FIXED_ASSETS = `${PREFIX}/bulkFinalizeFixedAssets`;
const FINALIZE_FIXED_ASSET = `${PREFIX}/finalizeFixedAsset`;
const REMOVE_FIXED_ASSETS = `${PREFIX}/removeFixedAssets`;
const BULK_CREATE_FIXED_ASSETS = `${PREFIX}/bulkCreateFixedAssets`;
const UPDATE_MANUAL_STEP_FOR_FIXED_ASSET = `${PREFIX}/updateManualStep`;
const CREATE_MANUAL_STEP_FOR_FIXED_ASSET = `${PREFIX}/createManualStep`;
const WRITE_OFF_FIXED_ASSET = `${PREFIX}/writeOffFixedAsset`;
const DELETE_WRITE_OFF_FIXED_ASSET = `${PREFIX}/deleteWriteOffFixedAsset`;

interface GetSubledgersResponse {
  subledgers: Array<V2Subledger>;
  effective?: string;
  currentPeriodEnd: string;
}

interface GetSubledgerDetailsResponse {
  subledger: V2Subledger;
  effective?: string;
}

interface State {
  isFetchingSubledgers: boolean;
  isFetchingSubledger: boolean;
  isUpdatingSubledger: boolean;
  isFetchingFixedAssets: boolean;
  isWritingOffFixedAsset: boolean;
  subledger?: GetSubledgerDetailsResponse;
  subledgers: GetSubledgersResponse;
  isUpdatingSilently: boolean;
  updatedAt?: Date;
  touched: Array<string>;
}

const initialState: State = {
  isFetchingSubledgers: false,
  isFetchingSubledger: false,
  isUpdatingSubledger: false,
  isFetchingFixedAssets: false,
  isWritingOffFixedAsset: false,
  subledgers: {
    subledgers: [],
    currentPeriodEnd: '',
  },
  isUpdatingSilently: false,
  touched: [],
};

export const postV2CreateSubledger = createAsyncThunk<V2Subledger, {
  subledgerData: Partial <V2Subledger>;
}>(
  POST_V2_CREATE_SUBLEDGER,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await request().post<V2Subledger>('v2/subledgers', { ...params.subledgerData });

      return data;
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';

      if (handledError === 'invalidated authorization token') {
        handleAuthError();
      }

      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const patchV2Subledger = createAsyncThunk<V2Subledger, {
  subledgerId: string;
  params: Partial<V2Subledger>;
}>(
  PATCH_V2_SUBLEDGER,
  async ({ subledgerId, params }, { rejectWithValue }) => {
    try {
      const { data } = await request().patch<V2Subledger>(`v2/subledgers/${subledgerId}`, { ...params });

      return data;
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';

      if (handledError === 'invalidated authorization token') {
        handleAuthError();
      }

      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const getV2SubledgerDetails = createAsyncThunk<GetSubledgerDetailsResponse, {
  subledgerId: string;
  date?: string;
}>(
  GET_V2_SUBLEDGER,
  async ({ subledgerId, date }, { rejectWithValue }) => {
    try {
      const { data } = await request()
        .get<GetSubledgerDetailsResponse>(`v2/subledgers/${subledgerId}`, { params: { date } });

      return data;
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';

      if (handledError === 'invalidated authorization token') {
        handleAuthError();
      }

      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const getV2Subledgers = createAsyncThunk<GetSubledgersResponse, {
  productType?: ProductTypes;
  date?: string;
}>(
  GET_V2_SUBLEDGERS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await request().get<GetSubledgersResponse>('v2/subledgers', { params });

      return data;
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';

      if (handledError === 'invalidated authorization token') {
        handleAuthError();
      }

      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const postV2FinalizeSubledger = createAsyncThunk<V2Subledger, {
  subledgerId: string;
}>(
  POST_V2_FINALIZE_SUBLEDGER,
  async ({ subledgerId }, { rejectWithValue }) => {
    try {
      const { data } = await request().post<V2Subledger>(`v2/subledgers/${subledgerId}/finalize`);

      return data;
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';

      if (handledError === 'invalidated authorization token') {
        handleAuthError();
      }

      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const createFixedAsset = createAsyncThunk<FixedAsset, {
  subledgerId: string;
}>(
  CREATE_FIXED_ASSET,
  async ({ subledgerId }, { rejectWithValue }) => {
    try {
      const { data } = await request()
        .post<FixedAsset>('/v2/fixed_assets', { subledgerId });

      return data;
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';

      if (handledError === 'invalidated authorization token') {
        handleAuthError();
      }

      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const bulkCreateFixedAssets = createAsyncThunk<{ fixedAssets: Array<FixedAsset> }, {
  fixedAssets: Array<Partial<FixedAsset>>;
  subledgerId: string;
}>(
  BULK_CREATE_FIXED_ASSETS,
  async ({ subledgerId, fixedAssets }, { rejectWithValue }) => {
    try {
      const { data } = await request()
        .post<{ fixedAssets: Array<FixedAsset> }>('/v2/fixed_assets/bulk', { subledgerId, fixedAssets });

      return data;
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';

      if (handledError === 'invalidated authorization token') {
        handleAuthError();
      }

      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const updateFixedAsset = createAsyncThunk<FixedAsset, {
  assetId: string;
  effective?: string;
  updateSilently?: boolean;
  dontUpdateState?: boolean;
  fixedAsset: Partial<FixedAsset>;
}>(
  UPDATE_FIXED_ASSET,
  async ({ assetId, effective, fixedAsset }, { rejectWithValue }) => {
    try {
      const { data } = await request()
        .patch<FixedAsset>(`/v2/fixed_assets/${assetId}`, fixedAsset, { params: { effective } });

      /**
       * TODO: Move it to backend when possible
       * Below we should return only `data` but currently additional `periodDepreciationAmount` field
       * is pushed to redux store to imitate autosaving feature which is not yet done on backend.
       */
      return {
        ...data,
        periodDepreciationAmount: fixedAsset.periodDepreciationAmount,
      };
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';

      if (handledError === 'invalidated authorization token') {
        handleAuthError();
      }

      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const bulkUpdateFixedAssets = createAsyncThunk<{ fixedAssets: Array<FixedAsset> }, {
  fixedAssets: Array<Partial<FixedAsset>>;
  effective?: string;
}>(
  BULK_UPDATE_FIXED_ASSETS,
  async ({ fixedAssets, effective }, { rejectWithValue }) => {
    try {
      const { data } = await request()
        .patch<{ fixedAssets: Array<FixedAsset> }>('/v2/fixed_assets/bulk', { fixedAssets }, { params: { effective } });

      return data;
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';

      if (handledError === 'invalidated authorization token') {
        handleAuthError();
      }

      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const bulkFinalizeFixedAssets = createAsyncThunk<{ fixedAssets: Array<FixedAsset> }, {
  fixedAssetsIds: Array<string>;
}>(
  BULK_FINALIZE_FIXED_ASSETS,
  async ({ fixedAssetsIds }, { rejectWithValue }) => {
    try {
      const { data } = await request()
        .post<{ fixedAssets: Array<FixedAsset> }>('/v2/fixed_assets/bulk/finalize', { asset_ids: fixedAssetsIds });

      return data;
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';

      if (handledError === 'invalidated authorization token') {
        handleAuthError();
      }

      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const removeFixedAssets = createAsyncThunk<null, {
  fixedAssetIds: Array<string>;
}>(
  REMOVE_FIXED_ASSETS,
  async ({ fixedAssetIds }, { rejectWithValue }) => {
    try {
      await request()
        .post<FixedAsset>('/v2/fixed_assets/remove', { fixedAssetIds });

      return null;
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';

      if (handledError === 'invalidated authorization token') {
        handleAuthError();
      }

      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const finalizeFixedAsset = createAsyncThunk<FixedAsset, {
  assetId: string;
}>(
  FINALIZE_FIXED_ASSET,
  async ({ assetId }, { rejectWithValue }) => {
    try {
      const { data } = await request()
        .post<FixedAsset>(`/v2/fixed_assets/${assetId}/finalize`);

      return data;
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';

      if (handledError === 'invalidated authorization token') {
        handleAuthError();
      }

      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const createManualStepForFixedAsset = createAsyncThunk<FixedAsset, {
  assetId: string;
  updateSilently?: boolean;
  fixedAssetManualStep: {
    periodStart: string;
    periodEnd: string;
    amount: number;
  };
}>(
  CREATE_MANUAL_STEP_FOR_FIXED_ASSET,
  async ({ assetId, fixedAssetManualStep }, { rejectWithValue }) => {
    try {
      const { data } = await request()
        .post<FixedAsset>(`/v2/fixed_assets/${assetId}/steps`, fixedAssetManualStep);

      return data;
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';

      if (handledError === 'invalidated authorization token') {
        handleAuthError();
      }

      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const updateManualStepForFixedAsset = createAsyncThunk<FixedAsset, {
  assetId: string;
  updateSilently?: boolean;
  fixedAssetManualStep: {
    periodStart: string;
    periodEnd: string;
    amount: number;
  };
}>(
  UPDATE_MANUAL_STEP_FOR_FIXED_ASSET,
  async ({ assetId, fixedAssetManualStep }, { rejectWithValue }) => {
    try {
      const { data } = await request()
        .patch<FixedAsset>(`/v2/fixed_assets/${assetId}/steps`, fixedAssetManualStep);

      return data;
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';

      if (handledError === 'invalidated authorization token') {
        handleAuthError();
      }

      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const writeOffFixedAsset = createAsyncThunk<FixedAsset, {
  assetId: string;
  disposalDate: string;
  gainOrLossAccountId: string;
  tags: Array<Partial<TagDetails>>;
}>(
  WRITE_OFF_FIXED_ASSET,
  async ({ assetId, ...restParams }, { rejectWithValue }) => {
    try {
      const { data } = await request()
        .post<FixedAsset>(`/v2/fixed_assets/${assetId}/write_off`, restParams);

      return data;
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';

      if (handledError === 'invalidated authorization token') {
        handleAuthError();
      }

      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const deleteWriteOffFixedAsset = createAsyncThunk<FixedAsset, {
  subledgerId: string;
  assetId: string;
  date: string;
}>(
  DELETE_WRITE_OFF_FIXED_ASSET,
  async ({ subledgerId, assetId, date }, { rejectWithValue }) => {
    try {
      const { data } = await request()
        .post<FixedAsset>(`/v2/subledgers/${subledgerId}/revert_write_off/${date}`, { assetId });

      return data;
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';

      if (handledError === 'invalidated authorization token') {
        handleAuthError();
      }

      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const v2SubledgersSlice = createSlice({
  initialState,
  name: PREFIX,
  reducers: {
    clearAllV2Subledgers: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(postV2CreateSubledger.pending, (state) => {
      state.isFetchingSubledger = true;
    });
    builder.addCase(postV2CreateSubledger.fulfilled, (state, { payload }) => {
      state.isFetchingSubledger = false;
      state.subledger = {
        ...state.subledger,
        subledger: payload,
      };
    });
    builder.addCase(postV2CreateSubledger.rejected, (state) => {
      state.isFetchingSubledger = false;
    });

    builder.addCase(getV2SubledgerDetails.pending, (state) => {
      state.isFetchingSubledger = true;
    });
    builder.addCase(getV2SubledgerDetails.fulfilled, (state, { payload }) => {
      state.isFetchingSubledger = false;
      state.subledger = payload;
    });
    builder.addCase(getV2SubledgerDetails.rejected, (state) => {
      state.isFetchingSubledger = false;
    });

    builder.addCase(patchV2Subledger.pending, (state) => {
      state.isUpdatingSubledger = true;
    });
    builder.addCase(patchV2Subledger.fulfilled, (state, { payload }) => {
      state.isUpdatingSubledger = false;
      state.subledger = {
        ...state.subledger,
        subledger: payload,
      };
      state.subledgers.subledgers = state.subledgers.subledgers
        .map((subledger) => (subledger.id === payload.id ? payload : subledger));
    });
    builder.addCase(patchV2Subledger.rejected, (state) => {
      state.isUpdatingSubledger = false;
    });

    builder.addCase(getV2Subledgers.pending, (state) => {
      state.isFetchingSubledgers = true;
    });
    builder.addCase(getV2Subledgers.fulfilled, (state, { payload }) => {
      state.isFetchingSubledgers = false;
      state.subledgers = payload;
    });
    builder.addCase(getV2Subledgers.rejected, (state) => {
      state.isFetchingSubledgers = false;
    });

    builder.addCase(postV2FinalizeSubledger.pending, (state) => {
      state.isFetchingSubledger = true;
    });
    builder.addCase(postV2FinalizeSubledger.fulfilled, (state) => {
      state.isFetchingSubledger = false;
    });
    builder.addCase(postV2FinalizeSubledger.rejected, (state) => {
      state.isFetchingSubledger = false;
    });

    builder.addCase(createFixedAsset.pending, (state) => {
      state.isFetchingFixedAssets = true;
    });
    builder.addCase(createFixedAsset.fulfilled, (state, { payload: returnedAsset }) => {
      state.isFetchingFixedAssets = false;
      state.subledger!.subledger.fixedAssets = [
        ...state.subledger!.subledger.fixedAssets || [],
        returnedAsset,
      ];
    });
    builder.addCase(createFixedAsset.rejected, (state) => {
      state.isFetchingFixedAssets = false;
    });

    builder.addCase(updateFixedAsset.pending, (state, { meta }) => {
      const { updateSilently } = meta.arg;
      state.isUpdatingSilently = !!updateSilently;
      state.isFetchingFixedAssets = !updateSilently;
    });
    builder.addCase(updateFixedAsset.fulfilled, (state, {
      payload: returnedAsset,
      meta: {
        arg: { dontUpdateState },
      },
    }) => {
      state.isUpdatingSilently = false;
      state.isFetchingFixedAssets = false;
      if (!dontUpdateState) {
        state.subledger!.subledger.fixedAssets = state.subledger!.subledger.fixedAssets!
          .map((asset) => (asset.id === returnedAsset.id ? returnedAsset : asset));
      }
      state.updatedAt = new Date();
      state.touched = state.touched.includes(returnedAsset.id)
        ? state.touched
        : [...state.touched, returnedAsset.id];
    });
    builder.addCase(updateFixedAsset.rejected, (state) => {
      state.isUpdatingSilently = false;
      state.isFetchingFixedAssets = false;
    });

    builder.addCase(bulkUpdateFixedAssets.pending, (state) => {
      state.isFetchingFixedAssets = true;
    });
    builder.addCase(bulkUpdateFixedAssets.fulfilled, (state, { payload }) => {
      const arrayOfIds = payload.fixedAssets.map((returnedAsset) => returnedAsset.id);
      state.isFetchingFixedAssets = false;
      state.subledger!.subledger.fixedAssets = [
        ...state.subledger!.subledger.fixedAssets!
          .map((asset) => (payload.fixedAssets.find((rAsset) => rAsset.id === asset.id) || asset)),
      ];
      state.updatedAt = new Date();
      state.touched = arrayOfIds.every((id) => state.touched.includes(id))
        ? state.touched
        : [...state.touched, ...arrayOfIds];
    });
    builder.addCase(bulkUpdateFixedAssets.rejected, (state) => {
      state.isFetchingFixedAssets = false;
    });

    builder.addCase(bulkFinalizeFixedAssets.pending, (state) => {
      state.isFetchingFixedAssets = true;
    });
    builder.addCase(bulkFinalizeFixedAssets.fulfilled, (state, { payload }) => {
      const arrayOfIds = payload.fixedAssets.map((returnedAsset) => returnedAsset.id);
      state.isFetchingFixedAssets = false;
      state.subledger!.subledger.fixedAssets = [
        ...state.subledger!.subledger.fixedAssets!
          .map((asset) => (payload.fixedAssets.find((rAsset) => rAsset.id === asset.id) || asset)),
      ];
      state.updatedAt = new Date();
      state.touched = arrayOfIds.every((id) => state.touched.includes(id))
        ? state.touched
        : [...state.touched, ...arrayOfIds];
    });
    builder.addCase(bulkFinalizeFixedAssets.rejected, (state) => {
      state.isFetchingFixedAssets = false;
    });

    builder.addCase(removeFixedAssets.pending, (state) => {
      state.isFetchingFixedAssets = true;
    });
    builder.addCase(removeFixedAssets.fulfilled, (state, { meta }) => {
      const { fixedAssetIds } = meta.arg;
      state.isFetchingFixedAssets = false;
      state.subledger!.subledger.fixedAssets = state.subledger!.subledger.fixedAssets!
        .filter((asset) => !fixedAssetIds.includes(asset.id));
    });
    builder.addCase(removeFixedAssets.rejected, (state) => {
      state.isFetchingFixedAssets = false;
    });

    builder.addCase(finalizeFixedAsset.pending, (state) => {
      state.isFetchingFixedAssets = true;
    });
    builder.addCase(finalizeFixedAsset.fulfilled, (state, { payload: returnedAsset }) => {
      state.isFetchingFixedAssets = false;
      state.subledger!.subledger.fixedAssets = state.subledger!.subledger.fixedAssets!
        .map((asset) => (asset.id === returnedAsset.id ? returnedAsset : asset));
    });
    builder.addCase(finalizeFixedAsset.rejected, (state) => {
      state.isFetchingFixedAssets = false;
    });

    builder.addCase(bulkCreateFixedAssets.pending, (state) => {
      state.isFetchingFixedAssets = true;
    });
    builder.addCase(bulkCreateFixedAssets.fulfilled, (state, { payload }) => {
      state.isFetchingFixedAssets = false;
      state.subledger!.subledger.fixedAssets = [
        ...state.subledger!.subledger.fixedAssets || [],
        ...payload.fixedAssets,
      ];
    });
    builder.addCase(bulkCreateFixedAssets.rejected, (state) => {
      state.isFetchingFixedAssets = false;
    });

    builder.addCase(writeOffFixedAsset.pending, (state) => {
      state.isWritingOffFixedAsset = true;
    });
    builder.addCase(writeOffFixedAsset.fulfilled, (state, { payload: returnedAsset }) => {
      state.isWritingOffFixedAsset = false;
      state.subledger!.subledger.fixedAssets = state.subledger!.subledger.fixedAssets!
        .map((asset) => (asset.id === returnedAsset.id ? returnedAsset : asset));
    });
    builder.addCase(writeOffFixedAsset.rejected, (state) => {
      state.isWritingOffFixedAsset = false;
    });

    builder.addCase(deleteWriteOffFixedAsset.pending, (state) => {
      state.isWritingOffFixedAsset = true;
    });
    builder.addCase(deleteWriteOffFixedAsset.fulfilled, (state, { payload: returnedAsset }) => {
      state.isWritingOffFixedAsset = false;
      state.subledger!.subledger.fixedAssets = state.subledger!.subledger.fixedAssets!
        .map((asset) => (asset.id === returnedAsset.id ? returnedAsset : asset));
    });
    builder.addCase(deleteWriteOffFixedAsset.rejected, (state) => {
      state.isWritingOffFixedAsset = false;
    });

    builder.addMatcher(isAnyOf(
      createManualStepForFixedAsset.pending,
      updateManualStepForFixedAsset.pending,
    ), (state, { meta }) => {
      const { updateSilently } = meta.arg;
      state.isUpdatingSilently = !!updateSilently;
      state.isFetchingFixedAssets = !updateSilently;
    });

    builder.addMatcher(isAnyOf(
      createManualStepForFixedAsset.fulfilled,
      updateManualStepForFixedAsset.fulfilled,
    ), (state, { payload: returnedAsset }) => {
      state.isUpdatingSilently = false;
      state.isFetchingFixedAssets = false;
      state.subledger!.subledger.fixedAssets = state.subledger!.subledger.fixedAssets!
        .map((asset) => (asset.id === returnedAsset.id ? returnedAsset : asset));
      state.updatedAt = new Date();
    });

    builder.addMatcher(isAnyOf(
      createManualStepForFixedAsset.rejected,
      updateManualStepForFixedAsset.rejected,
    ), (state) => {
      state.isUpdatingSilently = false;
      state.isFetchingFixedAssets = false;
    });
  },
});

export const {
  clearAllV2Subledgers,
} = v2SubledgersSlice.actions;

export const v2SubledgersReducer = v2SubledgersSlice.reducer;
