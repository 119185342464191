import React, {
  useEffect,
} from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Typography,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import find from 'lodash.find';
import {
  addDays,
  addMonths,
  differenceInCalendarMonths,
  format,
  formatISO,
  isSameMonth,
  parseISO,
  startOfMonth,
} from 'date-fns';
import { Link } from 'react-router-dom';
import FactaAutocomplete from '../../Inputs/FactaAutocomplete';
import Flyover from '../../Flyover';
import { FormInput } from '../../Inputs/FormInput/index';
import { AppThunkDispatch } from '../../../store/store';
import { InputLabel } from '../../InputLabel';
import { assetTypesSelector } from '../../../store/selectors/assetTypes';
import { TagCategories } from '../../../interfaces/tags';
import { accountsIncomeListSelector } from '../../../store/selectors/v2Accounts';
import {
  tagsClassListSelector,
  tagsLocationListSelector,
} from '../../../store/selectors/tags';
import { FixedAsset } from '../../../interfaces/fixedAssets';
import { updateFixedAsset } from '../../../store/slices/v2Subledgers';
import { getAllTags } from '../../../store/slices/v2Tags';
import { isUpdatingSilentlyFixedAssets } from '../../../store/selectors/v2Subledgers';
import FactaDatePicker from '../../DatePicker';
import { AssetType } from '../../../interfaces/fixedAssetTypes';
import { ErrorAnnotate } from '../../Inputs/ErrorAnnotate';
import { NumberInput } from '../../Inputs/NumberInput';
import { MONTH_LONG_FORMAT } from '../../../util/constants';

const useStyles = makeStyles(() => createStyles({
  inputBox: {
    margin: '12px 8px',
  },
  buttonBox: {
    margin: '12px 2px',
  },
  informationContainer: {
    fontSize: '12px',
    lineHeight: '14px',
    margin: '20px 8px',
    '& span': {
      display: 'block',
      marginTop: '20px',
    },
  },
}));

interface Props {
  asset?: FixedAsset;
  isOpen: boolean;
  onClose: (id?: string) => void;
  selectedDate: string;
  isSchedulerView?: boolean;
}

const EditFixedAsset = ({
  asset,
  isOpen,
  onClose,
  selectedDate,
  isSchedulerView,
}: Props) => {
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const styles = useStyles();
  const incomeAccounts = useSelector(accountsIncomeListSelector);
  const accountClasses = useSelector(tagsClassListSelector);
  const accountLocations = useSelector(tagsLocationListSelector);
  const assetTypes = useSelector(assetTypesSelector);
  const isSaving = useSelector(isUpdatingSilentlyFixedAssets)!;

  const usefulLife = asset
    ? differenceInCalendarMonths(
      parseISO(asset?.fullyDepreciatedDate!),
      addDays(parseISO(asset?.inServiceDate!), -1),
    )
    : 0;

  const alreadyPostedMonths = asset?.schedule?.filter((sch) => sch.posted).length || 0;
  const isIngestionPeriod = asset?.inServiceDate || asset?.transaction?.postingDate ? isSameMonth(
    parseISO(asset?.transaction?.postingDate || asset?.inServiceDate), parseISO(selectedDate),
  ) : false;

  const schedulerFlyoverTitle = isIngestionPeriod ? 'Override Asset Type' : 'Update Schedule';

  const yupSchema = () => yupResolver(yup.object().shape({
    depreciationAccount: yup.object({
      id: yup.string().required(),
      name: yup.string().required(),
    }).required(),
    gainOrLossAccount: yup.object({
      id: yup.string().required(),
      name: yup.string().required(),
    }).required(),
    usefulLifeValue: yup.number()
      .required('This field is required')
      .moreThan(alreadyPostedMonths - 1, 'Value cannot be less than the number of already depreciated periods.'),
    fullyDepreciatedDate: yup.date().nullable()
      .min(parseISO(asset?.inServiceDate || selectedDate),
        'Depreciation end date must be later or equal start date'),
  }));

  const {
    handleSubmit,
    control,
    errors,
    reset,
    setValue,
    getValues,
  } = useForm({
    mode: 'onChange',
    resolver: yupSchema(),
    shouldUnregister: true,
  });

  const assetType = find(assetTypes, { id: asset?.fixedAssetTypeId }) as AssetType;

  const parseDataToBeSaved = (data: any) => ({
    depreciationAccountId: data.depreciationAccount.id,
    gainOrLossAccountId: data.gainOrLossAccount.id,
    fullyDepreciatedDate: formatISO(data.fullyDepreciatedDate, { representation: 'date' }),
    tags: [
      ...(data.class?.id ? [{
        category: TagCategories.CLASS,
        id: data.class?.id,
      }] : []),
      ...(data.location?.id ? [{
        category: TagCategories.LOCATION,
        id: data.location?.id,
      }] : []),
    ],
  });

  const handleSave = (data: any) => {
    if (!asset) return;

    const parsedData = parseDataToBeSaved(data);

    reduxDispatch(updateFixedAsset({
      assetId: asset.id,
      fixedAsset: parsedData,
      updateSilently: true,
      effective: formatISO(startOfMonth(parseISO(selectedDate)), { representation: 'date' }),
    }))
      .unwrap()
      .then(() => {
        onClose(asset.id);
      });
  };

  useEffect(() => {
    const updatedAsset = ({
      depreciationAccount: find(incomeAccounts, { id: asset?.depreciationAccountId }),
      gainOrLossAccount: find(incomeAccounts, { id: asset?.gainOrLossAccountId }),
      class: find(
        accountClasses,
        { id: asset?.tags.find((tag) => tag.category === TagCategories.CLASS)?.id },
      ),
      location: find(
        accountLocations,
        { id: asset?.tags.find((tag) => tag.category === TagCategories.LOCATION)?.id },
      ),
    });

    reset(updatedAsset);
  }, [accountClasses, accountLocations, incomeAccounts, asset, reset]);

  useEffect(() => {
    reduxDispatch(getAllTags());
  }, [reduxDispatch]);

  return incomeAccounts && (
    <Flyover
      open={isOpen}
      title={isSchedulerView ? schedulerFlyoverTitle : 'Edit asset'}
      variant="drawer"
      onClose={onClose}
    >
      <Box overflow="auto">
        {(isSchedulerView && !isIngestionPeriod) && (
          <Typography className={styles.informationContainer}>
            <span>
              In-Service Date, Asset Type, and Frequency can only be changed in the&nbsp;
              <Link to={`/fixed-assets/scheduler/${asset?.subledgerId}/${asset?.inServiceDate}`}>
                {asset?.inServiceDate && format(parseISO(asset?.inServiceDate), MONTH_LONG_FORMAT)}
              </Link>
              &nbsp;period.
            </span>
            <span>
              All below changes will impact this asset for current and following periods.
              All previous periods will remain unchanged.
            </span>
          </Typography>
        )}
        <form style={{ display: 'flex', flexDirection: 'column' }}>
          <Box className={styles.inputBox}>
            <InputLabel label="Asset Type Name" />
            <FormInput
              control={control}
              name="name"
              defaultValue={assetType?.name || ''}
              error={!!errors?.name}
              disabled
            />
          </Box>
          <Box className={styles.inputBox}>
            <InputLabel label="Asset Description" />
            <FormInput
              control={control}
              name="description"
              defaultValue={asset?.description || ''}
              error={!!errors?.description}
              multiline
              disabled
              placeholder="Description"
            />
          </Box>
          <Box className={styles.inputBox}>
            <InputLabel label="Depreciation Account" />
            <FactaAutocomplete
              control={control}
              name="depreciationAccount"
              options={incomeAccounts}
              disableInactiveOptions
              optionName="name"
              defaultValue={null}
              error={!!errors?.depreciationAccount}
              disabled={isSaving}
              placeholder="Select Account"
            />
          </Box>
          <Box className={styles.inputBox}>
            <InputLabel label="Gain/Loss Account" />
            <FactaAutocomplete
              control={control}
              name="gainOrLossAccount"
              options={incomeAccounts}
              disableInactiveOptions
              optionName="name"
              defaultValue={null}
              error={!!errors?.gainOrLossAccount}
              disabled={isSaving}
              placeholder="Select Account"
            />
          </Box>
          <Box className={styles.inputBox}>
            <InputLabel label="Useful Life" />
            <Box
              display="flex"
              alignItems="center"
            >
              <Box
                width="115px"
                marginRight="16px"
              >
                <NumberInput
                  control={control}
                  name="usefulLifeValue"
                  defaultValue={usefulLife}
                  error={!!errors?.usefulLifeValue}
                  disabled={isSaving}
                  onUpdate={() => {
                    setValue(
                      'fullyDepreciatedDate',
                      addDays(addMonths(parseISO(asset?.inServiceDate || ''), getValues().usefulLifeValue), -1),
                    );
                  }}
                  prefix=""
                  decimalScale={0}
                />
              </Box>
              <Box width="130px">
                months
              </Box>
            </Box>
            <ErrorAnnotate error={errors?.usefulLifeValue} />
          </Box>
          <Box className={styles.inputBox}>
            <InputLabel label="Useful Life End Date" />
            <Controller
              name="fullyDepreciatedDate"
              control={control}
              defaultValue={asset?.fullyDepreciatedDate ? parseISO(asset.fullyDepreciatedDate) : null}
              render={({ onChange, value: localValue }) => (
                <FactaDatePicker
                  selectedDate={localValue}
                  onDateChange={onChange}
                  error={!!errors?.fullyDepreciatedDate}
                  isNullable
                  disabled
                />
              )}
            />
            <ErrorAnnotate error={errors?.fullyDepreciatedDate} />
          </Box>
          <Box className={styles.inputBox}>
            <InputLabel label="Class" />
            <FactaAutocomplete
              control={control}
              name="class"
              options={accountClasses}
              disableInactiveOptions
              optionName="name"
              defaultValue={null}
              error={!!errors?.class}
              disabled={isSaving}
              placeholder="Select Class"
            />
          </Box>
          <Box className={styles.inputBox}>
            <InputLabel label="Location" />
            <FactaAutocomplete
              control={control}
              name="location"
              options={accountLocations}
              disableInactiveOptions
              optionName="name"
              defaultValue={null}
              error={!!errors?.location}
              disabled={isSaving}
              placeholder="Select Location"
            />
          </Box>
          <Box className={styles.buttonBox}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit((data) => handleSave(data))}
              disabled={isSaving}
            >
              Save & Close
              {isSaving && (
                <CircularProgress
                  size={20}
                  style={{ position: 'absolute' }}
                />
              )}
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => onClose()}
              disabled={isSaving}
            >
              Cancel
              {isSaving && (
                <CircularProgress
                  size={20}
                  style={{ position: 'absolute' }}
                />
              )}
            </Button>

          </Box>
        </form>
      </Box>
    </Flyover>
  );
};

export default React.memo(EditFixedAsset);
