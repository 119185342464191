import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import DatePicker from 'react-datepicker';
import {
  Box,
  Button,
  Popper,
  Paper,
  ClickAwayListener,
} from '@material-ui/core';
import clsx from 'clsx';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { isAfter } from 'date-fns';
import { POPPER_ELEMENTS_Z_INDEX } from '../../../../util/constants';
import { commonFiltersStyles } from '../common/index.styled';
import { useStyles } from '../../../DatePicker/index.styled';
import { DateRangeFilterValue } from '../../../../interfaces/types';
import StyledCalendarInput from './StyledCalendarInput';

interface IDatepickerFilter {
  _key: React.Key;
  label: string;
  value?: DateRangeFilterValue;
  onChange: (filter: DateRangeFilterValue | undefined) => void;
}

export const DatepickerFilter = ({
  _key,
  label,
  value,
  onChange,
}: IDatepickerFilter) => {
  const [open, setOpen] = useState(false);
  const [innerValue, setInnerValue] = useState<DateRangeFilterValue | undefined>(value);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const endDateInputRef = useRef<DatePicker>(null);
  const commonStyles = commonFiltersStyles();
  const styles = useStyles();

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(!open);
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleChange = (date: Date | [Date, Date] | null, key: 'startDate' | 'endDate') => {
    setInnerValue((prevInnerValue) => {
      const newInnerValue = {
        ...prevInnerValue,
        [key]: date,
      };

      const updatedInnerValue = key === 'startDate'
        && (!innerValue?.endDate || (date && isAfter(date as Date, innerValue?.startDate as Date)))
        ? { ...newInnerValue, endDate: date }
        : newInnerValue;

      return updatedInnerValue as DateRangeFilterValue;
    });
  };

  const handleSelect = () => {
    if (endDateInputRef?.current) {
      endDateInputRef.current.setOpen(true);
    }
  };

  const applyFilter = () => {
    onChange(innerValue);
    setOpen(false);
    setAnchorEl(null);
  };

  const clearFilter = () => {
    onChange(undefined);
    setInnerValue(undefined);
    setOpen(false);
    setAnchorEl(null);
  };

  const handleCancel = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  useEffect(() => {
    setInnerValue(undefined);
  }, [_key]);

  return (
    <>
      <Button
        type="button"
        onClick={handleOpen}
        fullWidth
        variant="outlined"
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        className={clsx(commonStyles.filterButton, {
          [commonStyles.selected]: !!value,
        })}
      >
        {label}
        {value && <span className={commonStyles.filterDot} />}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="top-start"
        style={{
          zIndex: POPPER_ELEMENTS_Z_INDEX,
          minWidth: '250px',
        }}
        transition
      >
        <ClickAwayListener onClickAway={handleCancel}>
          <Paper
            className={commonStyles.paper}
            elevation={3}
          >
            <Box className={commonStyles.inputsBox}>
              <Box
                display="flex"
                flexDirection="column"
              >
                <span>From</span>
                <DatePicker
                  key={`${_key}1`}
                  className={styles.datepicker}
                  calendarClassName={styles.datepickerCalendar}
                  showPopperArrow={false}
                  customInput={<StyledCalendarInput className={commonStyles.searchField} />}
                  selected={innerValue?.startDate || new Date()}
                  onChange={(date) => handleChange(date, 'startDate')}
                  onSelect={handleSelect}
                  selectsStart
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
              >
                <span>To</span>
                <DatePicker
                  key={`${_key}2`}
                  ref={endDateInputRef}
                  className={styles.datepicker}
                  calendarClassName={styles.datepickerCalendar}
                  showPopperArrow={false}
                  customInput={<StyledCalendarInput className={commonStyles.searchField} />}
                  selected={innerValue?.endDate || innerValue?.startDate || new Date()}
                  onChange={(date) => handleChange(date, 'endDate')}
                  selectsEnd
                  minDate={innerValue?.startDate}
                  popperPlacement="top-end"
                />
              </Box>
            </Box>
            <Box
              marginTop="16px !important"
              className={commonStyles.buttonBox}
            >
              <Button
                type="button"
                variant="outlined"
                onClick={clearFilter}
                size="small"
              >
                Clear
              </Button>
              <Button
                type="submit"
                color="primary"
                onClick={applyFilter}
                fullWidth
                variant="contained"
                size="small"
                className={commonStyles.applyButton}
              >
                Apply
              </Button>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
