import React, {
  useEffect,
  useState,
} from 'react';
import find from 'lodash.find';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useParams,
} from 'react-router';
import {
  Box,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { PrepareJETable } from '../../../components/Subledger/FixedAssets/PrepareJETable';
import { v2JournalEntriesSelector } from '../../../store/selectors/v2JournalEntries';
import { AppThunkDispatch } from '../../../store/store';
import { getV2JournalEntries } from '../../../store/slices/v2JournalEntries';
import { getV2SubledgerDetails } from '../../../store/slices/v2Subledgers';
import { v2SubledgerSelector } from '../../../store/selectors/v2Subledgers';
import { AccountDetails } from '../../../components/AccountDetails';
import { InputLabel } from '../../../components/InputLabel';
import COLORS from '../../../theme/colors';
import { getAllAccounts } from '../../../store/slices/v2Accounts';
import { Loader } from '../../../components/Loader';
import { BackButton } from '../../../components/BackButton';

const useStyles = makeStyles(() => createStyles({
  formContent: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '20px 0 32px',
  },
  wrapper: {
    padding: '0 32px',
    borderLeft: `1px solid ${COLORS.disabled}`,
  },
}));

export const JEEntry = () => {
  const classes = useStyles();
  const {
    subledgerId,
    jeId,
  } = useParams<{ subledgerId: string; jeId: string }>();
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const subledgerDetails = useSelector(v2SubledgerSelector);
  const journalEntries = useSelector(v2JournalEntriesSelector);
  const journalEntry = find(journalEntries, { id: jeId });

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      reduxDispatch(getV2SubledgerDetails({ subledgerId })),
      reduxDispatch(getV2JournalEntries({ subledgerId })),
      reduxDispatch(getAllAccounts({})),
    ])
      .finally(() => setIsLoading(false));
  }, [reduxDispatch, subledgerId]);

  return (
    <Box padding={4}>
      <Loader open={isLoading} />
      <BackButton
        label="Back to All Journal Entries"
        path={`journal-entries/${subledgerDetails?.accountId}`}
      />
      <Box className={classes.formContent}>
        {!isLoading && subledgerDetails && (
          <AccountDetails
            accumulationAccountId={subledgerDetails.accumulationAccount}
            subledgerAccountId={subledgerDetails.accountId}
          />
        )}
        <Box className={classes.wrapper}>
          <InputLabel
            label="JE Number"
            tooltipText="This number is synced to your general ledger to identify your journal entry."
            centered
          />
          {journalEntry?.number}
        </Box>
        <Box className={classes.wrapper}>
          <InputLabel
            label="Posting Date"
            tooltipText="Posting date will always be the last day of the posting period."
            centered
          />
          {journalEntry?.postingDate}
        </Box>
        <Box
          minWidth={320}
          className={classes.wrapper}
        >
          <InputLabel
            label="JE Description"
            centered
            keepHeight
          />
          {journalEntry?.description}
        </Box>
      </Box>
      {journalEntry && <PrepareJETable tableData={journalEntry.lines} />}
    </Box>
  );
};
