import React, { ReactNode } from 'react';
import {
  Box,
  createStyles,
  Theme,
} from '@material-ui/core';
import clsx from 'clsx';
import makeStyles from '@material-ui/styles/makeStyles';
import Sidebar from '../Sidebar';
import TopBar from '../TopBar';
import { useNavBarContext } from '../../context/navbarContext';
import {
  NAVBAR_OPEN_WIDTH,
  NAVBAR_CLOSED_WIDTH,
} from '../../util/constants';
import COLORS from '../../theme/colors';

interface Props {
  children?: ReactNode;
  title: string;
  showCompanyInfo?: boolean;
  isNegative?: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  contentBox: {
    transition: theme.transitions.create('margin-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  negative: {
    backgroundColor: COLORS.lightGrayBackground,
  },
}));

const Layout = ({
  children,
  title,
  showCompanyInfo,
  isNegative,
}: Props) => {
  const classes = useStyles();
  const { navBarOpen } = useNavBarContext();

  return (
    <Box
      display="flex"
      width="100%"
      flexDirection="row"
    >
      <Sidebar />
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        marginLeft={`${navBarOpen ? NAVBAR_OPEN_WIDTH : NAVBAR_CLOSED_WIDTH}px`}
        className={classes.contentBox}
        height="100%"
        overflow="auto"
      >
        <TopBar
          title={title}
          showCompanyInfo={showCompanyInfo}
          isNegative={isNegative}
        />
        <Box
          display="flex"
          flexGrow={1}
          width="100%"
          flexDirection="column"
          alignItems="flex-start"
          overflow="auto"
          flexWrap="no-wrap"
          className={clsx({ [classes.negative]: isNegative })}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
