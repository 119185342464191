/* eslint-disable no-param-reassign */
import axios from 'axios';
import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import {
  endOfMonth,
  startOfMonth,
  subDays,
} from 'date-fns';
import {
  LegacyJournalEntry,
  JournalEntryFilterDates,
  NotifierType,
  ProductCategory,
  Subledger,
} from '../../interfaces/types';
import { openSnackbar } from '../../components/Notifier';
import mapErrorMessage from '../../util/errorMessage';
import { initializedSubledger } from '../../components/Subledger/common';
import getSubledgerStartEndDate from '../../util/getSubledgerStartEndDate';
import { sortAmortizationLogs } from '../../util/middleware';

const PREFIX = 'je';
const GET_SUBLEDGER_WITH_JOURNAL_ENTRIES = `${PREFIX}/getSubledgerWithJournalEntries`;
const GET_JOURNAL_ENTRY_CSV = `${PREFIX}/getJournalEntryCsv`;
const GET_JOURNAL_ENTRY_BY_ID = `${PREFIX}/getJournalEntryById`;

interface State {
  isFetching: boolean;
  isFetchingCSV: boolean;
  subledger?: Subledger;
  filterDates: JournalEntryFilterDates | null;
  journalEntry?: LegacyJournalEntry;
  error?: any;
}

const initialState: State = {
  isFetching: false,
  isFetchingCSV: false,
  filterDates: null,
};

export const getSubledgerWithJournalEntries = createAsyncThunk<{
  subledger: Subledger;
  filterDates: JournalEntryFilterDates;
}, {
  accountId: string;
  filter?: string;
}>(
  GET_SUBLEDGER_WITH_JOURNAL_ENTRIES,
  async ({ accountId, filter }, { rejectWithValue }) => {
    try {
      const { data } = await axios
        .get<Subledger>(`/journal-entries?account_id=${accountId}${filter || ''}`);

      const subledger = initializedSubledger({ subledger: data });

      const {
        startDate: subledgerStartDate,
        endDate: subledgerEndDate,
      } = getSubledgerStartEndDate(subledger);

      const subledgerLastClosingMonth = subledger.subledgerAmortizationLogs?.length
        ? endOfMonth(subDays(startOfMonth(subledgerEndDate), 1)) : subledgerEndDate;

      const journalEntryFilterDates: JournalEntryFilterDates = {
        startDate: subledgerStartDate,
        endDate: subledgerLastClosingMonth,
      };

      return {
        subledger: sortAmortizationLogs(subledger),
        filterDates: journalEntryFilterDates,
      };
    } catch (error: any) {
      const handledError = mapErrorMessage(error.response?.data || error.message);
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const getJournalEntryCSV = createAsyncThunk<Subledger, {
  accountId: string;
  productCategory: ProductCategory;
}>(
  GET_JOURNAL_ENTRY_CSV,
  async ({ accountId, productCategory }, { rejectWithValue }) => {
    try {
      const { data } = await axios
        .get<Subledger>(`/journal-entries?account_id=${accountId}&type=detail`);

      return sortAmortizationLogs(data);
    } catch (error: any) {
      const handledError = mapErrorMessage(error.response?.data || error.message, productCategory);
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const getJournalEntryById = createAsyncThunk<LegacyJournalEntry, {
  jeID: string;
}>(
  GET_JOURNAL_ENTRY_BY_ID,
  async ({ jeID }, { rejectWithValue }) => {
    try {
      const { data } = await axios
        .get<LegacyJournalEntry>(`/journal-entries/${jeID}`);

      return data;
    } catch (error: any) {
      const handledError = mapErrorMessage(error.response?.data || error.message);
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const jeSlice = createSlice({
  initialState,
  name: PREFIX,
  reducers: {
    clearJeData: () => initialState,
    setFilterDates: (state, { payload }: { payload: JournalEntryFilterDates | null }) => {
      state.filterDates = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSubledgerWithJournalEntries.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getSubledgerWithJournalEntries.fulfilled, (state, { payload }) => {
      state.subledger = payload.subledger;
      state.isFetching = false;
      if (!state.filterDates) {
        state.filterDates = payload.filterDates;
      }
    });
    builder.addCase(getSubledgerWithJournalEntries.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    });

    builder.addCase(getJournalEntryCSV.pending, (state) => {
      state.isFetchingCSV = true;
    });
    builder.addCase(getJournalEntryCSV.fulfilled, (state) => {
      state.isFetchingCSV = false;
    });
    builder.addCase(getJournalEntryCSV.rejected, (state) => {
      state.isFetchingCSV = false;
    });

    builder.addCase(getJournalEntryById.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getJournalEntryById.fulfilled, (state, { payload }) => {
      state.journalEntry = payload;
      state.isFetching = false;
    });
    builder.addCase(getJournalEntryById.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    });
  },
});

export const {
  clearJeData,
  setFilterDates,
} = jeSlice.actions;

export const jeReducer = jeSlice.reducer;
