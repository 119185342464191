import {
  Route,
  Switch,
} from 'react-router-dom';
import React from 'react';
import Account from './Account';
import Scheduler from './Subledger';
import HistoricalEditing from './HistoricalEditing';
import JournalEntryListing from './JournalEntryListing';
import JournalEntryView from './JournalEntryView';
import PrepaidSync from './PrepaidSync';
import RevSync from './RevSync';
import Layout from '../components/Layout';
import ErrorPage from '../components/ErrorPage';
import { ErrorPageType } from '../interfaces/types';
import Settings from './Settings';
import { FixedAssets } from './FixedAssets';
import { Reports } from './Reports';
import { featureSwitch } from '../util/featureSwitch';
import { JEs } from './JEs';

const ProtectedRoutes = () => (
  <Switch>
    <Route
      path="/account"
      component={Account}
    />
    {featureSwitch.reports && (
      <Route
        path="/reports"
        component={Reports}
      />
    )}
    <Route
      path="/settings"
      component={Settings}
    />
    <Route
      path="/subledgers"
      component={Scheduler}
    />
    <Route
      path="/historical"
      component={HistoricalEditing}
    />
    {!featureSwitch.fixedAssets && (
      <Route
        path="/journal-entries/:id"
        component={JournalEntryView}
        exact
      />
    )}
    <Route
      path="/journal-entries"
      component={featureSwitch.fixedAssets ? JEs : JournalEntryListing}
    />
    <Route
      path="/journal-entries-legacy"
      component={JournalEntryListing}
      exact
    />
    <Route
      path="/journal-entries-legacy/:id"
      component={JournalEntryView}
      exact
    />
    <Route
      path="/"
      component={PrepaidSync}
      exact
    />
    <Route
      path="/revsync"
      component={RevSync}
      exact
    />
    {featureSwitch.fixedAssets && (
      <Route
        path="/fixed-assets"
        component={FixedAssets}
      />
    )}
    <Route path="*">
      <Layout title="404">
        <ErrorPage variant={ErrorPageType.NotFound} />
      </Layout>
    </Route>
  </Switch>
);

export default ProtectedRoutes;
