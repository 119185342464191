import {
  addDays,
  endOfMonth,
  lastDayOfMonth,
  parse,
  startOfMonth,
} from 'date-fns';
import { Subledger } from '../interfaces/types';
import getScheduleDate from './getScheduleDate';
import { DAY_SHORT_FORMAT } from './constants';

const getSubledgerStartEndDate = (subledger: Subledger | null) => {
  if (!subledger) {
    return {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(addDays(endOfMonth(new Date()), 1)),
    };
  }
  const scheduleDate = getScheduleDate(subledger);
  const factaStartDate = startOfMonth(subledger?.factaStartDate!);
  return {
    startDate: factaStartDate,
    endDate: lastDayOfMonth(parse(scheduleDate!, DAY_SHORT_FORMAT, new Date())),
  };
};

export default getSubledgerStartEndDate;
