import React from 'react';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import { RollforwardFixedAssets } from '../../../components/Reports/RollforwardFixedAssets';
import { WaterfallLegacy } from '../../../components/Reports/WaterfallLegacy';
import { WaterfallFixedAssets } from '../../../components/Reports/WaterfallFixedAssets';
import { ProductTypes } from '../../../interfaces/common';

interface Params {
  section?: string;
}

export const ReportPage = () => {
  const { section } = useParams<Params>();
  const history = useHistory();

  const renderReport = () => {
    switch (section) {
      case 'prepaid-expense-schedule':
        return <WaterfallLegacy category={ProductTypes.PREPAID} />;
      case 'deferred-revenue-schedule':
        return <WaterfallLegacy category={ProductTypes.DEF_REV} />;
      case 'fixed-asset-depreciation-schedule':
        return <WaterfallFixedAssets />;
      case 'fixed-asset-roll-forward':
        return <RollforwardFixedAssets />;
      default: history.push('/reports/standard-reports');
    }
    return null;
  };

  return (
    <>
      {renderReport()}
    </>
  );
};
