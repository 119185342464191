import {
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTableStyles } from './Subledger.styled';
import { accountInfoSelector } from '../../store/selectors/account';

const PostJEPrepaidExpenseTableHeader = () => {
  const tableClasses = useTableStyles();
  const account = useSelector(accountInfoSelector)!;

  return (
    <TableHead>
      <TableRow
        hover={false}
      >
        <TableCell className={tableClasses.assetCell}>
          JE LINE
        </TableCell>
        <TableCell className={tableClasses.assetCell}>
          Trans. #
        </TableCell>
        <TableCell className={tableClasses.descriptionCell}>
          Description
        </TableCell>
        <TableCell className={tableClasses.customerCell}>
          Vendor
        </TableCell>
        <TableCell className={tableClasses.expenseAccountCell}>
          Expense Account
        </TableCell>
        {
          (account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) && (
            <TableCell className={tableClasses.classesCell}>
              Class
            </TableCell>
          )
        }
        <TableCell className={tableClasses.prepaidAssetAmount}>
          DR
        </TableCell>
        <TableCell className={clsx(tableClasses.prepaidAssetAmount, tableClasses.lastCell)}>
          CR
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default React.memo(PostJEPrepaidExpenseTableHeader);
