import { Account } from './accounts';
import { TagDetails } from './tags';

export interface AssetType {
  id?: string;
  number: string;
  visible: boolean;
  name: string;
  description: string;
  depreciationAccountId: string;
  gainOrLossAccountId: string;
  usefulLifeUnits: UsefulLifeUnits;
  usefulLifeValue: number;
  tags: Array<Partial<TagDetails>>;
  class?: Partial<TagDetails>;
  location?: Partial<TagDetails>;
}

export interface AssetTypeForm {
  id?: string;
  number: string;
  visible: boolean;
  name: string;
  description: string;
  depreciationAccount?: Account;
  gainOrLossAccount?: Account;
  usefulLifeUnits: { type: UsefulLifeUnits };
  usefulLifeValue: number;
  usefulLifeValueMonths: number;
  tags: Array<Partial<TagDetails>>;
  class?: Partial<TagDetails>;
  location?: Partial<TagDetails>;
}

export enum UsefulLifeUnits {
  MONTHS = 'months',
  YEARS = 'years',
}
