import React from 'react';
import { TagType } from '../../../interfaces/types';
import { ReportsCard } from '../../../components/Reports/ReportsCard';
import { featureSwitch } from '../../../util/featureSwitch';

export const StandardReports = () => {
  const standardPrepaidReports = [
    {
      title: 'Prepaid Expense Schedule',
      subtitle: 'Detailed subledger with amortization schedule.',
      url: '/reports/standard-reports/prepaid-expense-schedule',
    },
    {
      title: 'Prepaids Roll Forward',
      subtitle: `Schedule showing the beginning balance, additions, disposals, transfers,
        and ending balance for an account between two dates.`,
      url: '',
      isDisabled: true,
    },
  ];

  const standardDeferredRevenuesReports = [
    {
      title: 'Deferred Revenue Schedule',
      subtitle: 'Detailed subledger with amortization schedule.',
      url: '/reports/standard-reports/deferred-revenue-schedule',
    },
    {
      title: 'Deferred Revenues Roll Forward',
      subtitle: `Schedule showing the beginning balance, additions, disposals, transfers,
        and ending balance for an account between two dates.`,
      url: '',
      isDisabled: true,
    },
  ];

  const standardFixedAssetsReports = [
    {
      title: 'Fixed Asset Depreciation Schedule',
      subtitle: 'Detailed subledger with depreciation schedule.',
      url: '/reports/standard-reports/fixed-asset-depreciation-schedule',
      isDisabled: !featureSwitch.reportsFixedAssets,
    },
    {
      title: 'Fixed Asset Roll Forward',
      subtitle: `Schedule showing the beginning balance, additions, disposals, transfers,
        and ending balance for an account between two dates.`,
      url: '/reports/standard-reports/fixed-asset-roll-forward',
      isDisabled: !featureSwitch.reportsFixedAssets,
    },
  ];

  return (
    <>
      <ReportsCard
        reportsDetails={standardPrepaidReports}
        reportName="Prepaids"
      />
      <ReportsCard
        reportsDetails={standardDeferredRevenuesReports}
        reportName="Deferred Revenues"
      />
      <ReportsCard
        reportsDetails={standardFixedAssetsReports}
        reportName="Fixed Assets"
        tagType={featureSwitch.reportsFixedAssets ? TagType.New : TagType.ComingSoon}
      />
    </>
  );
};
