import {
  Action,
  combineReducers,
  configureStore,
  getDefaultMiddleware,
  ThunkAction,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import { accountReducer } from './slices/account';
import { dashboardReducer } from './slices/dashboard';
import { subledgerReducer } from './slices/subledger';
import { settingsReducer } from './slices/settings';
import { jeReducer } from './slices/je';
import { assetTypesReducer } from './slices/assetTypes';
import { companyReducer } from './slices/company';
import { accountsReducer } from './slices/v2Accounts';
import { contactsReducer } from './slices/v2Contacts';
import { tagsReducer } from './slices/v2Tags';
import { itemsReducer } from './slices/items';
import { v2SubledgersReducer } from './slices/v2Subledgers';
import { reportsReducer } from './slices/reports';
import { v2SyncReducer } from './slices/v2Sync';
import { v2JournalEntriesReducer } from './slices/v2JournalEntries';
import { noticesReducer } from './slices/notices';

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

const rootReducer = combineReducers({
  account: accountReducer,
  dashboard: dashboardReducer,
  subledger: subledgerReducer,
  settings: settingsReducer,
  je: jeReducer,
  assetTypes: assetTypesReducer,
  company: companyReducer,
  v2Accounts: accountsReducer,
  v2Contacts: contactsReducer,
  tags: tagsReducer,
  items: itemsReducer,
  v2Subledgers: v2SubledgersReducer,
  reports: reportsReducer,
  v2Sync: v2SyncReducer,
  v2JournalEntries: v2JournalEntriesReducer,
  notices: noticesReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: customizedMiddleware,
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;
export type AppThunk<R = void> = ThunkAction<R, RootState, null, Action<string>>;
export type AppThunkDispatch = ThunkDispatch<RootState, null, Action<string>>;

export default store;
