import {
  isValid,
  parse,
} from 'date-fns';
import {
  CSV_IMPORT_DAY_FORMAT,
  DAY_SHORT_FORMAT,
} from './constants';

const getCSVImportDate = (dateString: string) => {
  const date = parse(dateString, DAY_SHORT_FORMAT, new Date());
  if (isValid(date)) {
    return date;
  }
  return parse(dateString, CSV_IMPORT_DAY_FORMAT, new Date());
};

export default getCSVImportDate;
