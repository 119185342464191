import React from 'react';
import { Box } from '@material-ui/core';
import styled from 'styled-components';
import { FixedAsset } from '../../../../../interfaces/fixedAssets';
import ContextMenu from '../../../../ContextMenu';
import { StyledTableCell } from '../../../../Table/StyledTableCell';
import { MoreHorizIcon } from '../../../../Icons';

interface Props {
  onDelete: (assetId: string) => void;
  value: FixedAsset;
}

const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
});

export const SaveCell = ({
  onDelete,
  value,
}: Props) => {
  const menuItems = [
    {
      title: 'Remove Asset',
      action: () => onDelete(value.id),
    },
  ];

  return (
    <StyledTableCell>
      <Container>
        <ContextMenu menuItems={menuItems}>
          <MoreHorizIcon style={{ fontSize: 13 }} />
        </ContextMenu>
      </Container>
    </StyledTableCell>
  );
};
