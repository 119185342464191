/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { request } from '../../util/request';
import { openSnackbar } from '../../components/Notifier';
import {
  Account,
  AccountsFilters,
} from '../../interfaces/accounts';
import { NotifierType } from '../../interfaces/types';
import { handleAuthError } from '../../util/auth';

const PREFIX = 'v2Accounts';
const GET_ALL_ACCOUNTS = `${PREFIX}/getAllAccounts`;
const GET_ACCOUNT_BALANCE = `${PREFIX}/getAccountBalance`;
const PATCH_ACCOUNT = `${PREFIX}/patchAccount`;

interface State {
  isFetchingAccounts: boolean;
  isFetchingBalance: boolean;
  isUpdatingAccount: boolean;
  accounts: Array<Account>;
  accountBalance: number;
}

const initialState: State = {
  isFetchingAccounts: false,
  isFetchingBalance: false,
  isUpdatingAccount: false,
  accounts: [],
  accountBalance: 0,
};

export const getAllAccounts = createAsyncThunk<{ accounts: Array<Account> }, {
  filter?: AccountsFilters;
}>(
  GET_ALL_ACCOUNTS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await request()
        .get<{ accounts: Array<Account> }>('/v2/accounts', { params });

      return data;
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';

      if (handledError === 'invalidated authorization token') {
        handleAuthError();
      }

      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const patchAccount = createAsyncThunk<Account, {
  accountId: string;
  account: Partial<Account>;
}>(
  PATCH_ACCOUNT,
  async ({ accountId, account }, { rejectWithValue }) => {
    try {
      const { data } = await request()
        .patch<Account>(`/v2/accounts/${accountId}`, account);

      return data;
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';

      if (handledError === 'invalidated authorization token') {
        handleAuthError();
      }

      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const getAccountBalance = createAsyncThunk<{ balance: number }, {
  accountId: string;
  date?: string;
}>(
  GET_ACCOUNT_BALANCE,
  async ({ accountId, date }, { rejectWithValue }) => {
    try {
      const { data } = await request()
        .get<{ balance: number }>(`/v2/accounts/${accountId}/balance`, { params: { date } });

      return data;
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';

      if (handledError === 'invalidated authorization token') {
        handleAuthError();
      }

      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const accountsSlice = createSlice({
  initialState,
  name: PREFIX,
  reducers: {
    clearAccounts: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAccounts.pending, (state) => {
      state.isFetchingAccounts = true;
    });
    builder.addCase(getAllAccounts.fulfilled, (state, { payload }) => {
      state.isFetchingAccounts = false;
      state.accounts = [...payload.accounts];
    });
    builder.addCase(getAllAccounts.rejected, (state) => {
      state.isFetchingAccounts = false;
    });

    builder.addCase(patchAccount.pending, (state) => {
      state.isUpdatingAccount = true;
    });
    builder.addCase(patchAccount.fulfilled, (state, { payload }) => {
      state.isUpdatingAccount = false;
      state.accounts = state.accounts.map((acc) => (acc.id === payload.id ? payload : acc));
    });
    builder.addCase(patchAccount.rejected, (state) => {
      state.isUpdatingAccount = false;
    });

    builder.addCase(getAccountBalance.pending, (state) => {
      state.isFetchingBalance = true;
    });
    builder.addCase(getAccountBalance.fulfilled, (state, { payload }) => {
      state.isFetchingBalance = false;
      state.accountBalance = payload.balance;
    });
    builder.addCase(getAccountBalance.rejected, (state) => {
      state.isFetchingBalance = false;
      state.accountBalance = 0;
    });
  },
});

export const {
  clearAccounts,
} = accountsSlice.actions;

export const accountsReducer = accountsSlice.reducer;
