import {
  Box,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTableStyles } from './Subledger.styled';
import { accountInfoSelector } from '../../store/selectors/account';
import { Subledger } from '../../interfaces/types';
import CreateSubledgerAmortizationTableHeader from './CreateSubledgerAmortizationTableHeader';
import {
  REVSYNC_HEADER_CELL_100,
  REVSYNC_HEADER_CELL_80,
} from '../../util/constants';
import { FactaCheckbox } from '../Checkbox';

interface Props {
  subledger: Subledger;
  isCollapsedView: boolean;
  isCollapsedViewHovered: boolean;
  onSelectAll: () => void;
  isAllSelected: boolean;
  showBulkEdit: boolean;
}

const CreateSubledgerRevSyncTableHeader = ({
  subledger,
  isCollapsedView,
  isCollapsedViewHovered,
  onSelectAll,
  isAllSelected,
  showBulkEdit,
}: Props) => {
  const tableClasses = useTableStyles();
  const account = useSelector(accountInfoSelector)!;

  return (
    <TableHead className={tableClasses.tableHeader}>
      <TableRow
        hover={false}
      >
        {showBulkEdit && (
          <TableCell is-cell-sticky="true">
            <FactaCheckbox
              checked={isAllSelected}
              disabled={subledger.amortizationSources.length <= 0}
              onChange={() => onSelectAll()}
            />
          </TableCell>
        )}
        <TableCell
          className={tableClasses.assetCell}
          is-cell-sticky="true"
        >
          Trans. #
        </TableCell>
        <TableCell
          className={tableClasses.descriptionCell}
          is-cell-sticky="true"
        >
          Description
        </TableCell>
        {!isCollapsedView && (
          <>
            <TableCell
              className={clsx(tableClasses.customerCell, {
                [tableClasses.hover]: isCollapsedViewHovered,
              })}
              is-cell-sticky="true"
            >
              Customer
            </TableCell>
            <TableCell
              className={clsx(tableClasses.productCell, {
                [tableClasses.hover]: isCollapsedViewHovered,
              })}
              is-cell-sticky="true"
            >
              <Box maxWidth={REVSYNC_HEADER_CELL_100}>
                Product/ Service
              </Box>
            </TableCell>
            <TableCell
              className={clsx(tableClasses.expenseAccountCell, {
                [tableClasses.hover]: isCollapsedViewHovered,
              })}
              is-cell-sticky="true"
            >
              <Box maxWidth={REVSYNC_HEADER_CELL_100}>
                Revenue Account
              </Box>
            </TableCell>
            {
              (account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) && (
                <TableCell
                  className={clsx(tableClasses.classesCell, {
                    [tableClasses.hover]: isCollapsedViewHovered,
                  })}
                  is-cell-sticky="true"
                >
                  Class
                </TableCell>
              )
            }
          </>
        )}
        <TableCell
          className={tableClasses.datePickerCell}
          is-cell-sticky="true"
        >
          <Box
            maxWidth={REVSYNC_HEADER_CELL_80}
            textAlign="left"
          >
            Recognition Start Date
          </Box>
        </TableCell>
        <TableCell
          className={tableClasses.datePickerCell}
          is-cell-sticky="true"
        >
          <Box
            maxWidth={REVSYNC_HEADER_CELL_80}
            textAlign="left"
          >
            Recognition End Date
          </Box>
        </TableCell>
        {!isCollapsedView && (
          <TableCell
            className={clsx(tableClasses.scheduleTypeCell, {
              [tableClasses.hover]: isCollapsedViewHovered,
            })}
            is-cell-sticky="true"
          >
            Recog. Schedule
          </TableCell>
        )}
        <TableCell
          className={tableClasses.currencyCell}
          is-cell-sticky="true"
        >
          Facta Starting Balance
        </TableCell>
        <TableCell is-cell-sticky="true" />
        <CreateSubledgerAmortizationTableHeader
          subledger={subledger}
        />
      </TableRow>
    </TableHead>
  );
};

export default React.memo(CreateSubledgerRevSyncTableHeader);
