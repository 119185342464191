import {
  useEffect,
  useState,
} from 'react';
import find from 'lodash.find';
import { Account } from '../interfaces/types';
import { Account as V2Account } from '../interfaces/accounts';
import { V2Subledger } from '../interfaces/subledgers';
import { getGLBalance } from '../components/Subledger/common';
import {
  legacySchedulingMethodOptions,
  schedulingMethodOptions,
} from '../util/helpers';
import {
  DEFERRED_REVENUE_ACCOUNTS,
  FINALIZED_SUBLEDGER_STATUS,
} from '../util/constants';
import { featureSwitch } from '../util/featureSwitch';

interface Props {
  v1Accounts: Account[] | undefined;
  v2Accounts: V2Account[];
  v2Subledgers: V2Subledger[] | undefined;
}

export interface CommonSubledger {
  id: string;
  accountId: string;
  number: string;
  accountName: string;
  factaType: string;
  glType: string;
  glDetailType: string;
  defaultFrequency: string;
  glBalance: number;
  finalized: boolean;
  active: boolean; // Is it active in QBO
  visible: boolean; // Is it visible in Facta
  apiVersion: number;
}

export const useAllSubledgers = ({
  v1Accounts,
  v2Accounts,
  v2Subledgers,
}: Props): CommonSubledger[] => {
  const [allSubledgers, setAllSubledgers] = useState<Array<any>>([]);

  const getLegacyProductType = (account: Account) => {
    const isDeferred = DEFERRED_REVENUE_ACCOUNTS.includes(account.accountDetail);
    return isDeferred ? 'RevSync' : 'PrepaidSync';
  };

  const getProductType = (subledger: V2Subledger) => {
    switch (subledger.product) {
      case 'fixed_assets':
        return 'Fixed Assets';
    }
    return 'unknown';
  };

  useEffect(() => {
    if (v1Accounts && v2Accounts && v2Subledgers) {
      const v1CommonSubledgers: CommonSubledger[] = v1Accounts
        .filter((account) => account.subledger.id)
        .map((account) => ({
          id: account.subledger.id,
          accountId: account.id,
          number: account.accNum || '',
          accountName: account.name,
          factaType: getLegacyProductType(account),
          glType: account.accountType,
          glDetailType: account.accountDetail,
          defaultFrequency: find(legacySchedulingMethodOptions, { value: account.scheduleType })?.label!,
          glBalance: getGLBalance(account),
          finalized: !!account.subledger?.subledgerAmortizationLogs?.length
            || account.subledger.status === FINALIZED_SUBLEDGER_STATUS,
          active: account.active,
          visible: account.enable,
          apiVersion: 1,
        }));

      const v2CommonSubledgers: CommonSubledger[] = v2Subledgers
        .map((v2Subledger) => {
          const v2Account = find(v2Accounts, { id: v2Subledger.accountId });

          return {
            id: v2Subledger.id,
            accountId: v2Account?.id!,
            number: v2Account?.number || '',
            accountName: v2Account?.name!,
            factaType: getProductType(v2Subledger),
            glType: v2Account?.type!,
            glDetailType: v2Account?.detail!,
            defaultFrequency: find(schedulingMethodOptions, { value: v2Subledger.defaultSchedulingMethod })?.label!,
            glBalance: v2Account?.balance!,
            finalized: !!v2Subledger.finalized,
            active: !!v2Account?.active,
            visible: !!v2Account?.visible,
            apiVersion: 2,
          };
        });

      if (featureSwitch.fixedAssets) {
        setAllSubledgers([...v1CommonSubledgers, ...v2CommonSubledgers]);
      } else {
        setAllSubledgers(v1CommonSubledgers);
      }
    }
  }, [v1Accounts, v2Accounts, v2Subledgers]);

  return allSubledgers;
};
