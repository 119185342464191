import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { MoreHorizIcon } from '../../../../components/Icons';
import ContextMenu from '../../../../components/ContextMenu';
import { AppThunkDispatch } from '../../../../store/store';
import { LoadingBox } from '../../../../components/LoadingBox';
import { patchAssetType } from '../../../../store/slices/assetTypes';
import { AssetTypeForm } from '../../../../interfaces/fixedAssetTypes';

interface Props {
  assetType: AssetTypeForm;
  setSelectedId: any;
  setFlyover: any;
}

export const AseetTypesActions = ({
  assetType,
  setSelectedId,
  setFlyover,
}: Props) => {
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const [isUpdating, setIsUpdating] = useState(false);

  const handleToggleVisibility = () => {
    setIsUpdating(true);
    reduxDispatch(patchAssetType({
      assetType: {
        id: assetType.id,
        visible: !assetType.visible,
      },
    }))
      .finally(() => setIsUpdating(false));
  };

  const menuItems = [
    {
      title: 'Edit',
      disabled: !assetType.visible,
      action: () => setFlyover({ assetType, isOpen: true }),
    },
    {
      title: assetType.visible ? 'Hide' : 'Show',
      action: handleToggleVisibility,
    },
  ];

  return (
    <>
      {isUpdating && (
        <LoadingBox transparent />
      )}
      <ContextMenu
        menuItems={menuItems}
        onOpen={() => setSelectedId(assetType.id)}
        onClose={() => setSelectedId(undefined)}
      >
        <MoreHorizIcon style={{ margin: 0 }} />
      </ContextMenu>
    </>
  );
};
