import React, {
  useEffect,
  useState,
} from 'react';
import {
  Route,
  useParams,
} from 'react-router-dom';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useLocation } from 'react-router';
import {
  format,
  lastDayOfMonth,
} from 'date-fns';
import Schedule from './Scheduler';
import PrepareJE from './PrepareJE';
import JournalEntry from './PostJE';
import { DAY_SHORT_FORMAT } from '../../util/constants';
import Layout from '../../components/Layout';
import ErrorPage from '../../components/ErrorPage';
import { useRefreshDataProvider } from '../../context/dataRefreshContext';
import { AppThunkDispatch } from '../../store/store';
import { accountLastFetchSelector } from '../../store/selectors/account';
import { getBalanceData } from '../../store/slices/subledger';
import {
  isFetchingBalanceData,
  fetchingBalanceDataError,
} from '../../store/selectors/subledger';
import { Account } from '../../interfaces/types';
import { Loader } from '../../components/Loader';
import { getAccountInfo } from '../../store/slices/account';

interface Params {
  id: string;
}

const SchedulerWizard = () => {
  const { id: subledgerId } = useParams<Params>();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const { refreshDate } = useRefreshDataProvider();
  const scheduleDate = useQuery()
    .get('scheduleDate') ?? format(lastDayOfMonth(new Date()), DAY_SHORT_FORMAT);

  const lastFetch = useSelector(accountLastFetchSelector);
  const loading = useSelector(isFetchingBalanceData);
  const error = useSelector(fetchingBalanceDataError);

  const [accountBalance, setAccountBalance] = useState<Account | null>();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    reduxDispatch(getAccountInfo())
      .then(() => reduxDispatch(getBalanceData({
        subledgerId,
        lastDayOfSelectedDate: scheduleDate,
      }))
        .unwrap()
        .then((result) => {
          setAccountBalance(result);
        }))
      .finally(() => setIsInitialized(true));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxDispatch]);

  useEffect(() => {
    if (isInitialized) {
      reduxDispatch(getBalanceData({
        subledgerId,
        lastDayOfSelectedDate: scheduleDate,
      }))
        .unwrap()
        .then((result) => {
          setAccountBalance(result);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxDispatch, scheduleDate, subledgerId, refreshDate, lastFetch]);

  if (!isInitialized || (loading && !accountBalance)) {
    return (
      <Layout title="loading...   ">
        <Loader open />
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout title="Error">
        <ErrorPage />
      </Layout>
    );
  }
  return (
    <>
      <Route
        path="/subledgers/schedule/:id"
        exact
      >
        <Schedule
          accountBalance={accountBalance!}
          scheduleDate={scheduleDate}
        />
      </Route>
      <Route
        path="/subledgers/schedule/:id/prepare-je"
        exact
      >
        <PrepareJE
          accountBalance={accountBalance!}
          scheduleDate={scheduleDate}
        />
      </Route>
      <Route
        path="/subledgers/schedule/:id/journal-entry"
        exact
      >
        <JournalEntry
          scheduleDate={scheduleDate}
        />
      </Route>
    </>
  );
};

export default SchedulerWizard;
