/* eslint-disable react/jsx-props-no-spreading,max-len,max-len,max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const BalanceIcon = (props: any) => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.2407 15.0004L10.3993 14.8258V13.9644L7.80372 8.47409H6.59554L4 13.9644V14.8679L4.1408 15.0211C4.52806 15.442 4.99819 15.778 5.52154 16.0078C6.04489 16.2376 6.61009 16.3563 7.1815 16.3562H7.19572C7.7688 16.3543 8.33522 16.233 8.85903 16.0001C9.38285 15.7672 9.85262 15.4277 10.2386 15.0032L10.2414 14.9997L10.2407 15.0004ZM9.3326 14.4062C8.76525 14.9721 7.99698 15.2893 7.19643 15.2882H7.16514C6.38168 15.2893 5.62847 14.9853 5.06453 14.4404L5.06524 14.4411V14.2053L7.19856 9.69314L9.33189 14.2053L9.3326 14.4062Z"
      fill="inherit"
    />
    <path
      d="M17.4033 8.47409H16.1952L13.5996 13.9644V14.8679L13.7404 15.0211C14.1277 15.442 14.5978 15.778 15.1211 16.0078C15.6445 16.2376 16.2097 16.3563 16.7811 16.3562H16.7953C17.3684 16.3543 17.9348 16.233 18.4586 16.0001C18.9825 15.7672 19.4522 15.4277 19.8382 15.0032L19.841 14.9997L19.9996 14.8251V13.9637L17.4033 8.47409ZM18.9322 14.4062C18.3649 14.9721 17.5966 15.2893 16.796 15.2882H16.7648C15.9813 15.2893 15.2281 14.9853 14.6641 14.4404L14.6648 14.4411V14.2053L16.7982 9.69314L18.9315 14.2053L18.9322 14.4062Z"
      fill="inherit"
    />
    <path
      d="M12.5327 8.66284C12.8269 8.57354 13.0947 8.4134 13.3129 8.19642C13.5311 7.97945 13.6929 7.71224 13.7842 7.41815L13.7878 7.40461H17.3326V6.33589H13.7878C13.551 5.55716 12.8399 5 11.9993 5C11.1588 5 10.4477 5.55716 10.2144 6.32236L10.2109 6.33589H6.66602V7.40461H10.2109C10.3 7.69937 10.4599 7.96777 10.6764 8.18636C10.893 8.40495 11.1597 8.56708 11.4532 8.65857L11.4667 8.66213V17.5574H8.00005V18.6261H16V17.5574H12.5334L12.5327 8.66284ZM11.1993 6.87096C11.1993 6.71243 11.2462 6.55747 11.3342 6.42565C11.4221 6.29384 11.547 6.19111 11.6932 6.13044C11.8394 6.06977 12.0002 6.0539 12.1554 6.08483C12.3106 6.11576 12.4531 6.19209 12.565 6.30419C12.6769 6.41629 12.7531 6.55911 12.784 6.71459C12.8148 6.87007 12.799 7.03124 12.7384 7.1777C12.6779 7.32416 12.5753 7.44934 12.4438 7.53742C12.3122 7.62549 12.1576 7.6725 11.9993 7.6725C11.7872 7.6725 11.5837 7.58805 11.4336 7.43773C11.2836 7.28742 11.1993 7.08354 11.1993 6.87096Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default BalanceIcon;
