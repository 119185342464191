import React, { useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  Button,
  createStyles,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import COLORS from '../../theme/colors';
import FactaDatePicker from '../../components/DatePicker';
import { JournalEntryFilterDates } from '../../interfaces/types';
import { HeaderLabel } from '../../components/common.styled';

const useStyles = makeStyles(() => createStyles({
  datepicker: {
    background: COLORS.white,
    borderRadius: 4,
    width: 140,
    maxWidth: 150,
    '& input': {
      background: COLORS.white,
      maxWidth: '90px',
    },
  },
  marginSpan: {
    marginLeft: 10,
    marginRight: 10,
  },
  colorBlue: {
    color: `${COLORS.skyBlue} !important`,
    textTransform: 'none',
  },
  viewDatesButton: {
    marginLeft: 0,
    paddingLeft: 0,
    fontSize: 12,
    marginTop: 10,
  },
  applyButton: {
    position: 'absolute',
    bottom: '3%',
  },
}));

interface Props {
  filterDates: JournalEntryFilterDates | null;
  setDates: (dates: JournalEntryFilterDates | null) => void;
  startDate: Date;
  endDate: Date;
}

const JournalEntryFilter = ({ setDates, filterDates, startDate, endDate }: Props) => {
  const [start, setStartDate] = useState<Date | null>(filterDates?.startDate ?? startDate);
  const [end, setEndDate] = useState<Date | null>(filterDates?.endDate ?? endDate);
  const classes = useStyles();

  const changeStartDate = (date: Date | null) => {
    setStartDate(date);
  };
  const changeEndDate = (date: Date | null) => {
    setEndDate(date);
  };

  return (
    <>
      <HeaderLabel>
        PERIOD
      </HeaderLabel>
      <Box
        display="flex"
        justifyContent="center"
        width="100%"
        flexDirection="row"
        alignItems="center"
        marginTop="5px"
      >
        <FactaDatePicker
          selectedDate={start}
          onDateChange={changeStartDate}
          className={classes.datepicker}
          minDate={startDate}
        />
        <span className={classes.marginSpan}> to </span>
        <FactaDatePicker
          selectedDate={end}
          onDateChange={changeEndDate}
          className={classes.datepicker}
          minDate={startDate}
          placement="bottom-end"
        />
      </Box>
      <Box>
        <Button
          color="primary"
          className={clsx(classes.colorBlue, classes.viewDatesButton)}
          onClick={() => setDates({ startDate, endDate })}
        >
          View All Dates
        </Button>
      </Box>
      <Button
        variant="contained"
        color="primary"
        className={classes.applyButton}
        onClick={() => {
          setDates({ startDate: start!, endDate: end! });
        }}
      >
        APPLY
      </Button>
    </>
  );
};
export default JournalEntryFilter;
