import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import COLORS from '../../../../theme/colors';

const StyledBottomNavigation = styled.div`
  padding: 16px 90px 16px 16px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  border-top: 1px solid ${COLORS.disabled};
`;

interface MenuItem {
  label: string;
  action: any;
  disabled?: boolean;
  variant?: 'text' | 'contained' | 'outlined';
}

interface Props {
  menuItems: Array<MenuItem>;
}

export const BottomActions = ({ menuItems }: Props) => (
  <StyledBottomNavigation>
    {menuItems.map(({ label, action, disabled, variant }) => (
      <Button
        key={label}
        variant={variant || 'contained'}
        color="primary"
        onClick={action}
        disabled={disabled}
      >
        {label}
      </Button>
    ))}
  </StyledBottomNavigation>
);
