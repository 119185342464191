import React, {
  useState,
  memo,
  useMemo,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import {
  format,
  isBefore,
  isValid,
  startOfMonth,
} from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Box,
  Tooltip,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { useSelector } from 'react-redux';
import fileDownload from 'js-file-download';
import Papa from 'papaparse';
import { getAccountName } from '../../../Subledger/common';
import { NumberInput } from '../../../Inputs/NumberInput';
import FactaDatePicker from '../../../DatePicker';
import FactaAutocomplete from '../../../Inputs/FactaAutocomplete';
import {
  Account,
  AccountClass,
  AmortizationSource,
  CSVImportObject,
  Customer,
  ITableItemType,
  ITableType,
  NotifierType,
  Product,
  ProductCategory,
  LegacySchedulingMethod,
  SortOrder,
  SubledgerColumns,
  Vendor,
} from '../../../../interfaces/types';
import { openSnackbar } from '../../../Notifier';
import {
  DIGIT_LOWER_LIMIT,
  DIGIT_UPPER_LIMIT,
  FULL_DATE_FORMAT,
  FULL_DAY_FORMAT,
} from '../../../../util/constants';
import getCSVImportDate from '../../../../util/csv';
import COLORS from '../../../../theme/colors';
import {
  downloadTemplateFile,
  generateColumnNames,
  getSelectedLegacySchedulingMethodOption,
  legacySchedulingMethodOptions,
} from '../../../../util/helpers';
import { FormInput } from '../../../Inputs/FormInput';
import { accountInfoSelector } from '../../../../store/selectors/account';
import currencyFormatter from '../../../../util/currencyFormatter';
import { DeleteIcon } from '../../../Icons';
import { importWizardStyles } from '../index.styled';
import { StyledTableCell } from '../../../Table/StyledTableCell';
import TableComponent from '../../../Table';
import { ImportTab } from '../../../../interfaces/common';
import { UploadActions } from '../UploadActions';
import { IdleTableCell } from '../../../Table/IdleTableCell';

interface Props {
  productCategory: ProductCategory;
  factaStartDate: string;
  isClassTrackingEnabled: boolean;
  onClose: (e?: React.MouseEvent) => void;
  onImportCSV: (data: Array<AmortizationSource>) => void;
}

const ImportCSVContent = ({
  factaStartDate,
  productCategory,
  isClassTrackingEnabled,
  onClose,
  onImportCSV,
}: Props) => {
  const [importedData, setImportedData] = useState<Array<any>>([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState<string>('');
  const [tab, setTab] = useState(ImportTab.ALL);
  const account = useSelector(accountInfoSelector)!;
  const {
    vendors,
    accountClasses,
    accountIncomes,
    customers,
    products,
  } = account;
  const isImportedDataValid = !importedData.find((item) => Object.keys(item.errors).length);
  const isDefRev = productCategory === ProductCategory.DeferredRevenue;

  const classes = importWizardStyles();

  const baseSchema = yup.object().shape({
    description: yup.string().required(),
    destinationAccount: yup.object({
      id: yup.string().required(),
      displayName: yup.string().required(),
    }).required(),
    ...(isClassTrackingEnabled && {
      accountClass: yup.object({
        id: yup.string().required(),
        className: yup.string().required(),
      }).nullable(),
    }),
    amortizationScheduleType: yup.object({
      value: yup.string().required(),
      label: yup.string().required(),
    }).required(),
    amortizationStartDate: yup.date().nullable()
      .default(null)
      .when('amortizationScheduleType', {
        is: (amortizationScheduleType) => amortizationScheduleType?.value !== LegacySchedulingMethod.MANUAL,
        then: yup.date()
          .min(startOfMonth(new Date(factaStartDate)))
          .required()
          .typeError(),
        otherwise: yup.date().nullable(),
      }),
    amortizationEndDate: yup.date().nullable()
      .default(null)
      .when('amortizationScheduleType', {
        is: (amortizationScheduleType) => amortizationScheduleType?.value !== LegacySchedulingMethod.MANUAL,
        then: yup.date()
          .min(yup.ref('amortizationStartDate'))
          .min(startOfMonth(new Date(factaStartDate)))
          .required()
          .typeError(),
        otherwise: yup.date().nullable(),
      }),
    startingBalance: yup.number()
      .required()
      .min(DIGIT_LOWER_LIMIT)
      .max(DIGIT_UPPER_LIMIT),
  });

  const deferredSchema = baseSchema.shape({
    product: yup.object({
      id: yup.string().required(),
      name: yup.string().required(),
    }).nullable(),
    customer: yup.object({
      id: yup.string().required(),
      displayName: yup.string().required(),
    }).nullable(),
  });

  const prepaidSchema = baseSchema.shape({
    vendor: yup.object({
      id: yup.string().required(),
      displayName: yup.string().required(),
    }).nullable(),
  });

  const usedSchema = () => {
    switch (productCategory) {
      case ProductCategory.DeferredRevenue: {
        return (yupResolver(deferredSchema));
      }
      case ProductCategory.PrepaidExpense: {
        return (
          yupResolver(prepaidSchema));
      }
      default:
        return yupResolver(
          yup.object(),
        );
    }
  };

  const validateAsset = (asset: any) => {
    const errors = {};
    const isManualScheduleType = asset.amortizationScheduleType?.value === LegacySchedulingMethod.MANUAL;
    const isInvalidStartDate = asset.amortizationStartDate
      && isBefore(asset.amortizationStartDate, startOfMonth(new Date(factaStartDate)));
    const isInvalidEndDate = asset.amortizationEndDate
      && (
        isBefore(asset.amortizationEndDate, asset.amortizationStartDate)
        || isBefore(asset.amortizationEndDate, startOfMonth(new Date(factaStartDate)))
      );

    if (!asset.description) {
      Object.assign(errors, { description: true });
    }

    if (asset.destinationAccount?.value || !asset.destinationAccount) {
      Object.assign(errors, { destinationAccount: true });
    }

    if (isClassTrackingEnabled && asset.accountClass?.value) {
      Object.assign(errors, { accountClass: true });
    }

    if (asset.amortizationScheduleType?.importValue || !asset.amortizationScheduleType) {
      Object.assign(errors, { amortizationScheduleType: true });
    }

    if ((!asset.amortizationStartDate && !isManualScheduleType) || isInvalidStartDate) {
      Object.assign(errors, { amortizationStartDate: true });
    }

    if ((!asset.amortizationEndDate && !isManualScheduleType) || isInvalidEndDate) {
      Object.assign(errors, { amortizationEndDate: true });
    }

    if (asset.customer?.value) {
      Object.assign(errors, { customer: true });
    }

    if (asset.vendor?.value) {
      Object.assign(errors, { vendor: true });
    }

    if (asset.product?.value) {
      Object.assign(errors, { product: true });
    }

    return { ...asset, errors };
  };

  const {
    control,
    errors,
    getValues,
    setValue,
    trigger,
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: usedSchema(),
    shouldUnregister: false,
  });

  const columnNames = generateColumnNames(productCategory, isClassTrackingEnabled);

  const handleRowSelect = (ev: React.MouseEvent<HTMLTableRowElement, MouseEvent>, id: string) => {
    ev.stopPropagation();
    if (selectedAsset === id) {
      return;
    }

    if (selectedAsset && selectedAsset !== id) {
      const updatedData = importedData.map((asset) => {
        if (asset.id === selectedAsset) {
          return { id: selectedAsset, ...(validateAsset(getValues())) };
        }

        return asset;
      });
      setImportedData(updatedData);
    }

    setSelectedAsset(id);
    reset(importedData.find((asset) => asset.id === id));
    setTimeout(() => trigger(), 0);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: ImportTab) => {
    setTab(newValue);
  };

  const onFileUploaded = (data: Array<CSVImportObject>, fileInfo: any) => {
    if (!data.length) {
      openSnackbar({ message: 'There is no data in this CSV file, please select different one' }, NotifierType.Error);
      return;
    }

    if (Object.entries(data[0]).length !== columnNames.length) {
      openSnackbar(
        { message: 'Missing columns. Please be sure to use the provided CSV import template' },
        NotifierType.Error,
      );
      return;
    }

    if (JSON.stringify(Object.keys(data[0])) !== JSON.stringify(columnNames)) {
      openSnackbar(
        { message: 'Missing or incorrect column names. Please be sure to use the provided CSV import template' },
        NotifierType.Error,
      );
      return;
    }

    const amortizationSources = data?.map((item: CSVImportObject) => {
      const asset: any = {
        id: uuidv4(),
        description: item[SubledgerColumns.DESCRIPTION] ?? '',
        ...(isDefRev ? { customer: null } : { vendor: null }),
        product: null,
        accountClass: null,
        startingBalance: 0,
        amortizationScheduleType: null,
        amortizationStartDate: null,
        amortizationEndDate: null,
        destinationAccount: null,
        errors: {},
      };
      if (item[SubledgerColumns.FACTA_STARTING_BALANCE]) {
        asset.startingBalance = Number(item[SubledgerColumns.FACTA_STARTING_BALANCE]
          .replace(/[a-z\s,$]/ig, ''));
      }
      if (item[SubledgerColumns.AMORTIZATION_START_DATE] || item[SubledgerColumns.RECOGNITION_START_DATE]) {
        const amortizationStartDate = item[SubledgerColumns.AMORTIZATION_START_DATE]
          || item[SubledgerColumns.RECOGNITION_START_DATE];
        asset.amortizationStartDate = getCSVImportDate(amortizationStartDate);
        if (!isValid(asset?.amortizationStartDate)) {
          asset.amortizationStartDate = null;
        }
      }
      if (item[SubledgerColumns.AMORTIZATION_END_DATE] || item[SubledgerColumns.RECOGNITION_END_DATE]) {
        const amortizationEndDate = item[SubledgerColumns.AMORTIZATION_END_DATE]
          || item[SubledgerColumns.RECOGNITION_END_DATE];
        asset.amortizationEndDate = getCSVImportDate(amortizationEndDate);
        if (!isValid(asset.amortizationEndDate)) {
          asset.amortizationEndDate = null;
        }
      }
      if (item[SubledgerColumns.RECOG_SCHEDULE] || item[SubledgerColumns.AMORT_SCHEDULE]) {
        const importedScheduleType = item[SubledgerColumns.RECOG_SCHEDULE] || item[SubledgerColumns.AMORT_SCHEDULE];
        asset.amortizationScheduleType = getSelectedLegacySchedulingMethodOption(
          importedScheduleType.toUpperCase() as LegacySchedulingMethod,
        ) || {
          importValue: importedScheduleType,
        };
        if (asset.amortizationScheduleType?.value === LegacySchedulingMethod.MANUAL) {
          asset.amortizationEndDate = null;
          asset.amortizationStartDate = null;
        }
      }
      if (item[SubledgerColumns.EXPENSES_ACCOUNT] || item[SubledgerColumns.REVENUE_ACCOUNT]) {
        const expenseAccountId = item[SubledgerColumns.EXPENSES_ACCOUNT]
          || item[SubledgerColumns.REVENUE_ACCOUNT];
        asset.destinationAccount = accountIncomes?.find(
          (income: Account) => getAccountName(income)
            ?.toLowerCase() === expenseAccountId.toLowerCase(),
        ) || {
          value: item[SubledgerColumns.EXPENSES_ACCOUNT] || item[SubledgerColumns.REVENUE_ACCOUNT],
        };
      }
      if (item[SubledgerColumns.CLASS] && isClassTrackingEnabled) {
        asset.accountClass = accountClasses
          ?.find((accClass: AccountClass) => (
            accClass.className?.toLowerCase() === item[SubledgerColumns.CLASS]?.toLowerCase())) || {
          value: item[SubledgerColumns.CLASS],
        };
      }
      if (item[SubledgerColumns.VENDOR]) {
        asset.vendor = vendors?.find((ven: Vendor) => (
          ven.displayName.toLowerCase() === item[SubledgerColumns.VENDOR].toLowerCase())) || {
          value: item[SubledgerColumns.VENDOR],
        };
      }
      if (item[SubledgerColumns.CUSTOMER]) {
        asset.customer = customers?.find(
          (cust: Customer) => (
            cust.displayName.toLowerCase() === item[SubledgerColumns.CUSTOMER].toLowerCase()),
        ) || {
          value: item[SubledgerColumns.CUSTOMER],
        };
      }
      if (item[SubledgerColumns.PRODUCT]) {
        asset.product = products?.find(
          (pro: Product) => pro.name.toLowerCase() === item[SubledgerColumns.PRODUCT].toLowerCase(),
        ) || {
          value: item[SubledgerColumns.PRODUCT],
        };
      }
      return validateAsset(asset);
    });

    setUploadedFile(fileInfo);
    setImportedData(amortizationSources);

    if (amortizationSources.filter((asset) => Object.keys(asset.errors).length).length) {
      setTab(ImportTab.ERRORS);
    }
  };

  const onSubmit = () => {
    let amortizationSources: any = [];
    switch (productCategory) {
      case ProductCategory.DeferredRevenue: {
        amortizationSources = importedData.map((item: any) => ({
          internalId: item.id,
          description: item.description,
          customerId: item.customer?.id || '',
          productId: item.product?.id || '',
          startingBalance: item.startingBalance,
          amortizationSchedule: {
            amortizationScheduleType: item.amortizationScheduleType.value,
            amortizationStartDate: item.amortizationStartDate,
            amortizationEndDate: item.amortizationEndDate,
            destinationId: item.destinationAccount.id,
            classId: item.accountClass?.id || '',
          },
        }));
        break;
      }
      case ProductCategory.PrepaidExpense: {
        amortizationSources = importedData.map((item: any) => ({
          internalId: item.id,
          description: item.description,
          vendorId: item.vendor?.id || '',
          startingBalance: item.startingBalance,
          amortizationSchedule: {
            amortizationScheduleType: item.amortizationScheduleType.value,
            amortizationStartDate: item.amortizationStartDate,
            amortizationEndDate: item.amortizationEndDate,
            destinationId: item.destinationAccount.id,
            classId: item.accountClass?.id || '',
          },
        }));
        break;
      }
      default: break;
    }

    onImportCSV(amortizationSources as Array<AmortizationSource>);
    onClose();
  };

  const subledgerColumnInfo = [
    {
      columnName: SubledgerColumns.DESCRIPTION,
      fieldName: 'description',
      messages: ['Required'],
    },
    {
      columnName: SubledgerColumns.CUSTOMER,
      fieldName: 'customer',
      messages: ['Customer does not exist. Please select a valid customer'],
    },
    {
      columnName: SubledgerColumns.VENDOR,
      fieldName: 'vendor',
      messages: ['Vendor does not exist. Please select a valid vendor'],
    },
    {
      columnName: SubledgerColumns.PRODUCT,
      fieldName: 'product',
      messages: ['Product does not exist. Please select a valid product/service'],
    },
    {
      columnName: SubledgerColumns.EXPENSES_ACCOUNT,
      fieldName: 'destinationAccount',
      messages: ['Account does not exist. Please select a valid account', 'This field is required'],
    },
    {
      columnName: SubledgerColumns.REVENUE_ACCOUNT,
      fieldName: 'destinationAccount',
      messages: ['Account does not exist. Please select a valid account', 'This field is required'],
    },
    {
      columnName: SubledgerColumns.CLASS,
      fieldName: 'accountClass',
      messages: ['Account class does not exist. Please select a valid account class'],
    },
    {
      columnName: SubledgerColumns.FACTA_STARTING_BALANCE,
      fieldName: 'startingBalance',
      messages: ['Please provide a valid number without formatting'],
    },
    {
      columnName: SubledgerColumns.EXPENSES_ACCOUNT,
      fieldName: 'destinationAccount',
      messages: ['Account does not exist. Please select a valid account', 'This field is required'],
    },
    {
      columnName: SubledgerColumns.REVENUE_ACCOUNT,
      fieldName: 'destinationAccount',
      messages: ['Account does not exist. Please select a valid account'],
    },
    {
      columnName: SubledgerColumns.AMORTIZATION_START_DATE,
      fieldName: 'amortizationStartDate',
      messages: [
        `Amortization start date should be on or after ${format(new Date(factaStartDate), FULL_DAY_FORMAT)}`,
        'Format: MM/DD/YYYY',
        'This field is required',
      ],
    },
    {
      columnName: SubledgerColumns.AMORTIZATION_END_DATE,
      fieldName: 'amortizationEndDate',
      messages: [
        'Recognition end date should be on or after Amortization Start Date',
        'Format: MM/DD/YYYY',
        'This field is required',
      ],
    },
    {
      columnName: SubledgerColumns.RECOGNITION_START_DATE,
      fieldName: 'amortizationStartDate',
      messages: [
        `Recognition start date should be on or after ${format(new Date(factaStartDate), FULL_DAY_FORMAT)}`,
        'Format: MM/DD/YYYY',
        'This field is required',
      ],
    },
    {
      columnName: SubledgerColumns.RECOGNITION_END_DATE,
      fieldName: 'amortizationEndDate',
      messages: [
        'Recognition end date should be on or after Recognition Start Date',
        'Format: MM/DD/YYYY',
        'This field is required',
      ],
    },
    {
      columnName: SubledgerColumns.AMORT_SCHEDULE,
      fieldName: 'amortizationScheduleType',
      messages: ['Schedule type does not exist. Please select a valid schedule type', 'This field is required'],
    },
    {
      columnName: SubledgerColumns.RECOG_SCHEDULE,
      fieldName: 'amortizationScheduleType',
      messages: ['Schedule type does not exist. Please select a valid schedule type', 'This field is required'],
    },
  ];

  const onFixedDataDownload = () => {
    const formData = importedData;
    let exportData: any = [];
    switch (productCategory) {
      case ProductCategory.DeferredRevenue: {
        exportData = formData.map((item: any) => ({
          description: item.description,
          customer: item.customer?.displayName || '',
          product: item.product?.name || '',
          destinationAccount: item.destinationAccount.name,
          ...(isClassTrackingEnabled ? item.accountClass?.className : []),
          amortizationStartDate: format(item.amortizationStartDate, FULL_DATE_FORMAT),
          amortizationEndDate: format(item.amortizationEndDate, FULL_DATE_FORMAT),
          amortizationScheduleType: item.amortizationScheduleType.value,
          startingBalance: item.startingBalance,
        }));
        break;
      }
      case ProductCategory.PrepaidExpense: {
        exportData = formData.map((item: any) => ({
          description: item.description,
          vendor: item.vendor?.displayName || '',
          destinationAccount: item.destinationAccount.name,
          ...(isClassTrackingEnabled ? item.accountClass?.className : []),
          amortizationStartDate: format(item.amortizationStartDate, FULL_DATE_FORMAT),
          amortizationEndDate: format(item.amortizationEndDate, FULL_DATE_FORMAT),
          amortizationScheduleType: item.amortizationScheduleType.value,
          startingBalance: item.startingBalance,
        }));
        break;
      }
      default: break;
    }
    const csvData = Papa.unparse({
      data: exportData,
      fields: Object.keys(exportData[0]),
    }, {
      header: false,
    });
    const downloadData = `${[...columnNames].join(',')}\r\n${csvData}`;
    fileDownload(downloadData, 'updatedData.csv');
  };

  const checkColumnErrors = (colName: SubledgerColumns) => {
    const searchedItem = subledgerColumnInfo.find((item) => item.columnName === colName);

    if (searchedItem) {
      return importedData.filter((asset: any) => Object
        .prototype.hasOwnProperty.call(asset.errors, searchedItem.fieldName)).length > 0;
    }

    return false;
  };

  const handleDeleteRow = (id: string, event: React.MouseEvent) => {
    event.stopPropagation();
    const updatedImportedData = importedData.filter((asset) => asset.id !== id);
    setImportedData(updatedImportedData);
  };

  const getColumnMessages = (colName: SubledgerColumns) => subledgerColumnInfo
    .find((item) => item.columnName === colName)?.messages;

  const onScheduleTypeChange = () => {
    if (getValues('amortizationScheduleType')?.value === LegacySchedulingMethod.MANUAL) {
      setValue('amortizationStartDate', null);
      setValue('amortizationEndDate', null);
    }
    trigger();
  };

  const handleClickAway = () => {
    if (selectedAsset && !Object.keys(errors).length) {
      const updatedData = importedData.map((asset) => {
        if (asset.id === selectedAsset) {
          return { id: selectedAsset, ...getValues(), errors: {} };
        }

        return asset;
      });
      setImportedData(updatedData);
      setSelectedAsset('');
    }
  };

  const renderCustomHeader = (colName: SubledgerColumns) => (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexGrow={1}
    >
      <p>{colName}</p>
      {checkColumnErrors(colName) && (
        <Tooltip
          title={(
            <p className={classes.tooltipInfo}>
              {getColumnMessages(colName)?.map((message) => (
                <span key={message}>{message}</span>
              ))}
            </p>
          )}
          arrow
          placement="top"
        >
          <ErrorIcon
            style={{ color: COLORS.error, fontSize: '20px' }}
          />
        </Tooltip>
      )}
    </Box>
  );

  const colProps = [
    {
      colName: SubledgerColumns.DESCRIPTION,
      customHeader: renderCustomHeader(SubledgerColumns.DESCRIPTION),
      varKey: 'description',
      colType: ITableItemType.TEXT,
      child: (value: any) => (
        <StyledTableCell
          key="description"
          minWidth="200"
        >
          {value.id === selectedAsset
            ? (
              <FormInput
                name="description"
                control={control}
                defaultValue={`${value?.description}`}
                error={!!errors?.description}
                placeholder="Provide Description"
              />
            )
            : (
              <IdleTableCell
                value={value.description}
                placeholderText="Provide Description"
                withError={!!value.errors.description}
              />
            )}
        </StyledTableCell>
      ),
    },
    ...(isDefRev
      ? [{
        colName: SubledgerColumns.CUSTOMER,
        customHeader: renderCustomHeader(SubledgerColumns.CUSTOMER),
        varKey: 'customer',
        colType: ITableItemType.SORTKEY,
        sortKey: ['displayName'],
        child: (value: any) => (
          <StyledTableCell
            key="customer"
            minWidth="200"
          >
            {value.id === selectedAsset
              ? (
                <FactaAutocomplete
                  control={control}
                  name="customer"
                  options={customers}
                  disableInactiveOptions
                  optionName="displayName"
                  defaultValue={value?.customer}
                  {...(value?.customer?.value && { defaultInputValue: value.customer.value })}
                  error={!!errors?.customer}
                  placeholder="Select Customer"
                />
              )
              : (
                <IdleTableCell
                  value={value?.customer?.displayName || value?.customer?.value || ''}
                  placeholderText="Select Customer"
                  withError={!!value.errors.customer}
                />
              )}
          </StyledTableCell>
        ),
      }]
      : [{
        colName: SubledgerColumns.VENDOR,
        customHeader: renderCustomHeader(SubledgerColumns.VENDOR),
        varKey: 'vendor',
        colType: ITableItemType.SORTKEY,
        sortKey: ['displayName'],
        child: (value: any) => (
          <StyledTableCell
            key="vendor"
            minWidth="200"
          >
            {value.id === selectedAsset
              ? (
                <FactaAutocomplete
                  control={control}
                  name="vendor"
                  options={vendors}
                  disableInactiveOptions
                  optionName="displayName"
                  defaultValue={value?.vendor}
                  {...(value?.vendor?.value && { defaultInputValue: value.vendor.value })}
                  error={!!errors?.vendor}
                  placeholder="Select Vendor"
                />
              )
              : (
                <IdleTableCell
                  value={value?.vendor?.displayName || value?.vendor?.value || ''}
                  placeholderText="Select Vendor"
                  withError={!!value.errors.vendor}
                />
              )}
          </StyledTableCell>
        ),
      }]),
    ...(isDefRev ? [{
      colName: SubledgerColumns.PRODUCT,
      customHeader: renderCustomHeader(SubledgerColumns.PRODUCT),
      varKey: 'product',
      colType: ITableItemType.SORTKEY,
      sortKey: ['name'],
      child: (value: any) => (
        <StyledTableCell
          key="product"
          minWidth="200"
        >
          {value.id === selectedAsset
            ? (
              <FactaAutocomplete
                control={control}
                name="product"
                options={products}
                disableInactiveOptions
                optionName="name"
                defaultValue={value?.product}
                {...(value?.product?.value && { defaultInputValue: value.product.value })}
                error={!!errors?.product}
                placeholder="Select Product"
              />
            )
            : (
              <IdleTableCell
                value={value.product?.name || value?.product?.value || ''}
                placeholderText="Select Product"
                withError={!!value.errors.product}
              />
            )}
        </StyledTableCell>
      ),
    }] : []),
    {
      colName: isDefRev
        ? SubledgerColumns.REVENUE_ACCOUNT
        : SubledgerColumns.EXPENSES_ACCOUNT,
      customHeader: renderCustomHeader(isDefRev
        ? SubledgerColumns.REVENUE_ACCOUNT
        : SubledgerColumns.EXPENSES_ACCOUNT),
      varKey: 'destinationAccount',
      colType: ITableItemType.SORTKEY,
      sortKey: ['displayName'],
      child: (value: any) => (
        <StyledTableCell
          key="destinationAccount"
          minWidth="200"
        >
          {value.id === selectedAsset
            ? (
              <FactaAutocomplete
                control={control}
                name="destinationAccount"
                options={accountIncomes}
                disableInactiveOptions
                optionName="displayName"
                defaultValue={value?.destinationAccount}
                {...(value?.destinationAccount?.value
                  && { defaultInputValue: value.destinationAccount.value })}
                error={!!errors?.destinationAccount}
                placeholder="Select Destination Account"
              />
            )
            : (
              <IdleTableCell
                value={value.destinationAccount?.displayName || value?.destinationAccount?.value || ''}
                placeholderText="Select Destination Account"
                withError={!!value.errors.destinationAccount}
              />
            )}
        </StyledTableCell>
      ),
    },
    ...(isClassTrackingEnabled ? [{
      colName: SubledgerColumns.CLASS,
      customHeader: renderCustomHeader(SubledgerColumns.CLASS),
      varKey: 'className',
      colType: ITableItemType.SORTKEY,
      sortKey: ['className'],
      child: (value: any) => (
        <StyledTableCell
          key="className"
          minWidth="200"
        >
          {value.id === selectedAsset
            ? (
              <FactaAutocomplete
                control={control}
                name="accountClass"
                options={accountClasses}
                disableInactiveOptions
                optionName="className"
                defaultValue={value?.accountClass}
                {...(value?.accountClass?.value && { defaultInputValue: value.accountClass.value })}
                error={!!errors?.accountClass}
                placeholder="Select Class"
              />
            )
            : (
              <IdleTableCell
                value={value.accountClass?.className || value?.accountClass?.value || ''}
                placeholderText="Select Class"
                withError={!!value.errors.accountClass}
              />
            )}
        </StyledTableCell>
      ),
    }] : []),
    {
      colName: isDefRev
        ? SubledgerColumns.RECOGNITION_START_DATE
        : SubledgerColumns.AMORTIZATION_START_DATE,
      customHeader: renderCustomHeader(isDefRev
        ? SubledgerColumns.RECOGNITION_START_DATE
        : SubledgerColumns.AMORTIZATION_START_DATE),
      varKey: 'amortizationStartDate',
      colType: ITableItemType.DATE,
      child: (value: any) => (
        <StyledTableCell
          key="amortizationStartDate"
          minWidth="200"
        >
          {value.id === selectedAsset
            ? (
              <Controller
                name="amortizationStartDate"
                control={control}
                defaultValue={value?.amortizationStartDate}
                render={({ onChange, value: localValue }) => (
                  <FactaDatePicker
                    selectedDate={localValue}
                    error={!!errors?.amortizationStartDate}
                    disabled={getValues('amortizationScheduleType')?.value === LegacySchedulingMethod.MANUAL}
                    onDateChange={(date) => {
                      onChange(date);
                      trigger('amortizationEndDate');
                    }}
                    disablePortal
                    isNullable
                  />
                )}
              />
            )
            : (
              <IdleTableCell
                value={(value.amortizationStartDate && format(value.amortizationStartDate, FULL_DATE_FORMAT)) || ''}
                placeholderText="MM/DD/YYYY"
                withError={!!value.errors.amortizationStartDate}
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: isDefRev
        ? SubledgerColumns.RECOGNITION_END_DATE
        : SubledgerColumns.AMORTIZATION_END_DATE,
      customHeader: renderCustomHeader(isDefRev
        ? SubledgerColumns.RECOGNITION_END_DATE
        : SubledgerColumns.AMORTIZATION_END_DATE),
      varKey: 'amortizationEndDate',
      colType: ITableItemType.DATE,
      child: (value: any) => (
        <StyledTableCell
          key="amortizationEndDate"
          minWidth="200"
        >
          {value.id === selectedAsset
            ? (
              <Controller
                name="amortizationEndDate"
                control={control}
                defaultValue={value?.amortizationEndDate}
                render={({ onChange, value: localValue }) => (
                  <FactaDatePicker
                    selectedDate={localValue}
                    error={!!errors?.amortizationEndDate}
                    disabled={getValues('amortizationScheduleType')?.value === LegacySchedulingMethod.MANUAL}
                    onDateChange={(date) => {
                      onChange(date);
                      trigger('amortizationStartDate');
                    }}
                    disablePortal
                    isNullable
                  />
                )}
              />
            )
            : (
              <IdleTableCell
                value={(value.amortizationEndDate && format(value.amortizationEndDate, FULL_DATE_FORMAT)) || ''}
                placeholderText="MM/DD/YYYY"
                withError={!!value.errors.amortizationEndDate}
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: isDefRev
        ? SubledgerColumns.RECOG_SCHEDULE
        : SubledgerColumns.AMORT_SCHEDULE,
      customHeader: renderCustomHeader(isDefRev
        ? SubledgerColumns.RECOG_SCHEDULE
        : SubledgerColumns.AMORT_SCHEDULE),
      varKey: 'amortizationScheduleType',
      colType: ITableItemType.SORTKEY,
      sortKey: ['label'],
      child: (value: any) => (
        <StyledTableCell
          key="amortizationScheduleType"
          minWidth="200"
        >
          {value.id === selectedAsset
            ? (
              <FactaAutocomplete
                control={control}
                name="amortizationScheduleType"
                options={legacySchedulingMethodOptions}
                optionName="label"
                defaultValue={value?.amortizationScheduleType}
                {...(value?.amortizationScheduleType?.importValue
                  && { defaultInputValue: value.amortizationScheduleType.importValue })
                }
                error={!!errors?.amortizationScheduleType}
                onChange={onScheduleTypeChange}
                placeholder="Select Frequency"
              />
            )
            : (
              <IdleTableCell
                value={value.amortizationScheduleType?.label || value?.amortizationScheduleType?.importValue || ''}
                placeholderText="Select Frequency"
                withError={!!value.errors.amortizationScheduleType}
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: SubledgerColumns.FACTA_STARTING_BALANCE,
      customHeader: renderCustomHeader(SubledgerColumns.FACTA_STARTING_BALANCE),
      varKey: 'startingBalance',
      colType: ITableItemType.NUMMARY,
      child: (value: any) => (
        <StyledTableCell
          key="startingBalance"
          minWidth="200"
        >
          {value.id === selectedAsset
            ? (
              <NumberInput
                name="startingBalance"
                defaultValue={value?.startingBalance}
                control={control}
                error={!!errors?.startingBalance}
              />
            )
            : (
              <IdleTableCell
                value={currencyFormatter.format(value?.startingBalance)}
                placeholderText="$0,00"
                withError={!!value.errors.startingBalnace}
              />
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: '',
      varKey: 'delete',
      colType: ITableItemType.ACTION,
      child: (value: any) => (
        <StyledTableCell key="delete">
          <IconButton
            onClick={(e) => handleDeleteRow(value.id, e)}
          >
            <DeleteIcon />
          </IconButton>
        </StyledTableCell>
      ),
    },
  ];

  const defaultSort = {
    key: 'description',
    order: SortOrder.DESC,
  };

  const filteredData = useMemo(
    () => (tab === ImportTab.ERRORS && !isImportedDataValid
      ? importedData.filter((asset) => Object.keys(asset.errors).length)
      : importedData),
    [importedData, isImportedDataValid, tab],
  );

  const onDownloadCSVTemplate = () => {
    downloadTemplateFile(generateColumnNames(
      productCategory, isClassTrackingEnabled,
    ), 'Facta_asset_template.csv');
  };

  return (
    <>
      <UploadActions
        handleTemplateDownload={onDownloadCSVTemplate}
        onFileUploaded={onFileUploaded}
        onSubmit={onSubmit}
        handleDownloadFixedData={onFixedDataDownload}
        isImportedDataValid={isImportedDataValid}
        uploadedFile={uploadedFile}
      />
      {importedData.length > 0 && (
        <>
          <Tabs
            value={tab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            scrollButtons="off"
          >
            <Tab
              label={`All (${importedData.length})`}
              value={ImportTab.ALL}
              disableRipple
            />
            <Tab
              label={(
                <Box display="flex">
                  <Typography className={classes.errorTab}>
                    With errors (
                    {importedData.filter((asset) => Object.keys(asset.errors).length)?.length || 0}
                    )
                  </Typography>
                  {!isImportedDataValid && <ErrorIcon style={{ color: COLORS.error, fontSize: '20px' }} />}
                </Box>
              )}
              value={ImportTab.ERRORS}
              disableRipple
              disabled={isImportedDataValid}
            />
          </Tabs>
          <form
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              overflow: 'auto',
            }}
          >
            <TableComponent
              colProps={colProps}
              tableData={filteredData}
              defaultSort={defaultSort}
              contentType={ITableType.fixedAssetsAmortizationSources}
              onRowClick={handleRowSelect}
              selectedId={selectedAsset}
              topBarActions={[]}
              onClickOutsideSelectedRow={handleClickAway}
            />
          </form>
        </>
      )}
    </>
  );
};

export default memo(ImportCSVContent);
