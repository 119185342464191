import React, { useEffect } from 'react';
import {
  useForm,
  Controller,
} from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { parseISO } from 'date-fns';
import FactaAutocomplete from '../../Inputs/FactaAutocomplete';
import FactaDatePicker from '../../DatePicker';
import { FormInput } from '../../Inputs/FormInput';
import { InputError } from '../../InputError';
import { schedulingMethodOptions } from '../../../util/helpers';
import { InputLabel } from '../../InputLabel';
import { commonBulkEditFormStyles } from '../index.styled';
import { vendorsListSelector } from '../../../store/selectors/v2contacts';
import { assetTypesSelector } from '../../../store/selectors/assetTypes';
import {
  SchedulingMethod,
  SchedulingMethodOption,
} from '../../../interfaces/common';
import { NumberInput } from '../../Inputs/NumberInput';

export const yupSchema = () => yupResolver(
  yup.object().shape({
    fullyDepreciatedDate: yup.date().nullable()
      .when('inServiceDate', {
        is: (val) => val,
        then: yup.date().min(yup.ref('inServiceDate'),
          'Depreciation end date must be after depreciation start date'),
      }),
  }),
);

interface Props {
  onSubmit: (data: any) => void;
  isOpen: boolean;
  isFullyEditable: boolean;
  scheduleDate: string;
}

export const FixedAssetsForm = ({
  onSubmit,
  isOpen,
  isFullyEditable,
  scheduleDate,
}: Props) => {
  const classes = commonBulkEditFormStyles();
  const vendors = useSelector(vendorsListSelector);
  const assetTypes = useSelector(assetTypesSelector);

  const {
    control,
    errors,
    handleSubmit,
    setValue,
    watch,
    reset,
    trigger,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      number: '',
      description: '',
      vendorContact: null,
      fixedAssetType: null,
      originalValue: 0,
      priorDepreciation: 0,
      inServiceDate: null,
      fullyDepreciatedDate: null,
      schedulingMethod: null as unknown as SchedulingMethodOption,
    },
    resolver: yupSchema(),
  });

  const isManualSchedulingMethod = watch('schedulingMethod')?.value === SchedulingMethod.MANUAL;

  useEffect(() => {
    if (isManualSchedulingMethod) {
      setValue('inServiceDate', null);
      setValue('fullyDepreciatedDate', null);
    }
  }, [isManualSchedulingMethod, setValue]);

  useEffect(() => {
    if (isOpen) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Box className={classes.wrapper}>
      <form
        id="hook-form"
        onSubmit={handleSubmit(onSubmit)}
        className={classes.form}
      >
        <Box>
          <InputLabel label="FA#" />
          <FormInput
            control={control}
            defaultValue=""
            name="number"
            error={!!errors.number}
          />
        </Box>
        {isFullyEditable && (
          <>
            <Box>
              <InputLabel label="Description" />
              <FormInput
                control={control}
                defaultValue=""
                name="description"
                error={!!errors.description}
              />
            </Box>
            <Box>
              <InputLabel label="Vendor" />
              <FactaAutocomplete
                options={vendors}
                disableInactiveOptions
                control={control}
                optionName="name"
                name="vendorContact"
                defaultValue={null}
              />
            </Box>
          </>
        )}
        <Box>
          <InputLabel label="Asset Type " />
          <FactaAutocomplete
            options={assetTypes}
            disableInactiveOptions
            control={control}
            optionName="name"
            name="fixedAssetType"
            defaultValue={null}
          />
        </Box>
        {isFullyEditable && (
          <>
            <Box>
              <InputLabel label="Original Amount " />
              <NumberInput
                control={control}
                name="originalValue"
                defaultValue={0}
              />
            </Box>
            <Box>
              <InputLabel label="Accumulated Depreciation " />
              <NumberInput
                control={control}
                name="priorDepreciation"
                defaultValue={0}
              />
            </Box>
          </>
        )}
        <Box>
          <InputLabel label={isFullyEditable ? 'Depreciation Start Date' : 'In-service Date'} />
          <Controller
            name="inServiceDate"
            control={control}
            defaultValue={null}
            render={({ onChange, value }) => (
              <FactaDatePicker
                selectedDate={value}
                error={!!errors?.inServiceDate}
                disabled={isManualSchedulingMethod}
                onDateChange={(date) => {
                  onChange(date);
                  trigger('fullyDepreciatedDate');
                }}
                openToDate={scheduleDate ? parseISO(scheduleDate) : undefined}
                isNullable
              />
            )}
          />
          {errors?.inServiceDate?.message
            && <InputError errorMessage={errors?.inServiceDate?.message} />}
        </Box>
        {isFullyEditable && (
          <Box>
            <InputLabel label="Depreciation End Date" />
            <Controller
              name="fullyDepreciatedDate"
              control={control}
              defaultValue={null}
              render={({ onChange, value }) => (
                <FactaDatePicker
                  selectedDate={value}
                  error={!!errors?.fullyDepreciatedDate}
                  disabled={isManualSchedulingMethod}
                  onDateChange={(date) => onChange(date)}
                  openToDate={scheduleDate ? parseISO(scheduleDate) : undefined}
                  isNullable
                />
              )}
            />
            {errors?.fullyDepreciatedDate?.message
              && <InputError errorMessage={errors?.fullyDepreciatedDate?.message} />}
          </Box>
        )}
        <Box>
          <InputLabel label="Schedule Type" />
          <FactaAutocomplete
            options={schedulingMethodOptions}
            control={control}
            optionName="label"
            name="schedulingMethod"
            defaultValue={null}
          />
        </Box>
      </form>
    </Box>
  );
};
