import InputBase from '@material-ui/core/InputBase';
import { styled } from '@material-ui/core/styles';
import React from 'react';
import COLORS from '../../theme/colors';

const InputBox = styled(InputBase)({
  background: COLORS.white,
  border: `1px solid ${COLORS.border}`,
  borderRadius: 4,
  boxShadow: 'none',
  color: COLORS.deepGray,
  height: 32,
  padding: '8px',
  fontSize: '4px 12px',
  '&.Mui-error': {
    color: COLORS.error,
    border: `1px solid ${COLORS.error}`,
  },
  '&.Mui-focused': {
    border: `1px solid ${COLORS.violet}`,
    boxShadow: 'none',
  },
});

export default React.memo(InputBox);
