import React, { useState } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import Box from '@material-ui/core/Box';
import {
  Button,
  createStyles,
  MenuItem,
  Snackbar,
} from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import { useHistory } from 'react-router';
import Alert from '@material-ui/lab/Alert';
import roundTo from 'round-to';
import find from 'lodash.find';
import SelectBox from '../../../components/Select';
import COLORS from '../../../theme/colors';
import {
  Account,
  AmortizationSource,
  Subledger,
} from '../../../interfaces/types';
import FactaDatePicker from '../../../components/DatePicker';
import currencyFormatter from '../../../util/currencyFormatter';
import ImportCSVIcon from '../../../components/Icons/ImportCSVIcon';
import {
  HeaderTop,
  HeaderCurrencyDetails,
  CurrencyLabel,
  SchedulerLabel,
} from '../../../components/common.styled';
import DialogBox from '../../../components/DialogBox';
import isEmpty from '../../../util/isEmpty';
import SubledgerAccountName from '../../../components/Subledger/SubledgerAccountName';
import SaveButton from '../../../components/SaveButton';
import { getHeaderScheduleLabel } from '../../../components/Subledger/common';
import { AppThunkDispatch } from '../../../store/store';
import { postCreateSubledger } from '../../../store/slices/subledger';
import {
  fetchingAccountError,
  isFetchingAccountInfo,
  isClassTrackingEnabledSelector,
  accountBalancesSelector,
} from '../../../store/selectors/account';
import CSVImportWizard from '../../../components/Flyovers/CSVImportWizard';
import { Loader } from '../../../components/Loader';

const useStyles = makeStyles(() => createStyles({
  selectBox: {
    width: 400,
  },
  periodBox: {
    width: 100,
    marginRight: 20,
  },
  subledgerBalance: {
    color: COLORS.violet,
  },
  datepicker: {
    background: COLORS.white,
    borderRadius: 4,
    marginRight: 20,
    width: 120,
    maxWidth: 120,
    '& input': {
      background: COLORS.white,
    },
  },
  fullWidth: {
    width: '100%',
  },
  marginLeft8: {
    marginLeft: 8,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  glBalance: {
    maxWidth: 100,
    width: 100,
  },
  factaStartingBalance: {
    maxWidth: 120,
    width: 150,
  },
  sidePadding: {
    paddingLeft: 18,
  },
}));

interface Props {
  subledger?: Subledger | null;
  onFactaStartDateChange: (date: Date | null) => void;
  onAmortizationScheduleTypeChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  accountBalance: Account;
  onSave: () => Promise<void>;
  onFinalizedSubledger: () => Promise<void>;
  navigateBack: () => void;
  onSaveAndExit: () => Promise<void>;
  isFinalizeSubledgerEnabled?: boolean;
  onImportCSV: (data: Array<AmortizationSource>) => void;
}

const Header = ({
  subledger,
  onFactaStartDateChange,
  onAmortizationScheduleTypeChange,
  accountBalance,
  onSave,
  onFinalizedSubledger,
  navigateBack,
  onSaveAndExit,
  isFinalizeSubledgerEnabled,
  onImportCSV,
}: Props) => {
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const [accountId, setaccountId] = useState('');
  const [openSubledgerDialog, setOpenSubledgerDialog] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  const accounts = useSelector(accountBalancesSelector);
  const error = useSelector(fetchingAccountError);
  const loading = useSelector(isFetchingAccountInfo);
  const isClassTrackingEnabled = useSelector(isClassTrackingEnabledSelector)!;

  const [flyover, setFlyover] = useState(false);
  if (!subledger || isEmpty(subledger)) {
    return null;
  }

  const difference = roundTo(Number(accountBalance?.glBalance), 2) - roundTo(Number(subledger?.openingBalance), 2);

  // Subledger creation POST trigger
  const createSubledger = async () => {
    reduxDispatch(postCreateSubledger({
      accountId: accountId!,
    }))
      .unwrap()
      .then((result) => {
        closeDialog();
        history.push(`/subledgers/scheduler/${result.id}`);
        window.location.reload();
      });
  };

  const closeDialog = () => {
    setOpenSubledgerDialog(false);
    setaccountId('');
  };

  const onOpenCSVImport = () => {
    setFlyover(true);
  };

  return (
    <>
      <Snackbar
        open={Boolean(error)}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="error">
          {error?.message}
        </Alert>
      </Snackbar>
      <Loader open={loading} />
      <DialogBox
        openDialog={openSubledgerDialog}
        closeDialog={closeDialog}
        actions={[{ title: 'Create Subledger', event: createSubledger }]}
        dialogContext={'You are about to create a new subledger. \n Do you wish to proceed?'}
        dialogTitle="Alert"
        dismissContext="Cancel"
      />
      <HeaderTop>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          padding="0 14px"
          marginBottom="10px"
        >
          <SubledgerAccountName account={subledger?.account} />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            height="100%"
            margin="10px 0"
          >
            <SchedulerLabel>
              Facta Start Date
            </SchedulerLabel>
            <FactaDatePicker
              selectedDate={subledger.factaStartDate}
              onDateChange={onFactaStartDateChange}
              className={classes.datepicker}
            />
            <SchedulerLabel>
              { getHeaderScheduleLabel(subledger?.productCategory) }
            </SchedulerLabel>
            <SelectBox
              value={subledger?.account?.scheduleType}
              disableUnderline
              className={classes.periodBox}
              onChange={onAmortizationScheduleTypeChange}
            >
              <MenuItem value="DAILY">
                Daily
              </MenuItem>
              <MenuItem value="MONTHLY">
                Monthly
              </MenuItem>
            </SelectBox>
          </Box>
        </Box>
        <Box
          display="flex"
          marginBottom="10px"
        >
          <HeaderCurrencyDetails>
            <SchedulerLabel className={classes.glBalance}>
              GL Balance on start date:
            </SchedulerLabel>
            <CurrencyLabel>
              {currencyFormatter.format(accountBalance?.glBalance)}
            </CurrencyLabel>
          </HeaderCurrencyDetails>
          <HeaderCurrencyDetails>
            <SchedulerLabel className={classes.factaStartingBalance}>
              Facta Opening Subledger balance:
            </SchedulerLabel>
            <CurrencyLabel className={classes.subledgerBalance}>
              {currencyFormatter.format(subledger?.openingBalance)}
            </CurrencyLabel>
          </HeaderCurrencyDetails>
          <HeaderCurrencyDetails>
            <SchedulerLabel className={classes.glBalance}>
              Difference
            </SchedulerLabel>
            <CurrencyLabel>
              {currencyFormatter.format(difference)}
            </CurrencyLabel>
          </HeaderCurrencyDetails>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          marginLeft="auto"
        >
          <Box
            display="flex"
            justifyContent="flex-end"
            width="100%"
          >
            <Button
              onClick={onOpenCSVImport}
              startIcon={(
                <ImportCSVIcon
                  color="primary"
                  fill={COLORS.skyBlue}
                  fontSize="small"
                />
              )}
              color="primary"
            >
              Import CSV
            </Button>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            marginTop="10px"
          >
            <SaveButton
              onSave={onSave}
              onSaveAndExit={onSaveAndExit}
              disabled={!isFinalizeSubledgerEnabled}
            />
            <Button
              onClick={onFinalizedSubledger}
              variant="contained"
              color="primary"
              style={{ paddingTop: 4, paddingBottom: 4 }}
              disabled={!isFinalizeSubledgerEnabled}
            >
              Finalize Subledger
            </Button>
            <Button
              onClick={navigateBack}
              color="primary"
              style={{ color: COLORS.skyBlue }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </HeaderTop>
      <CSVImportWizard
        isOpen={flyover}
        setOpen={setFlyover}
        isClassTrackingEnabled={isClassTrackingEnabled}
        onImportCSV={onImportCSV}
        accountName={find(accounts, { id: subledger.accountId })?.displayName || ''}
        factaStartDate={subledger.factaStartDate?.toISOString()!}
        productCategory={subledger.productCategory}
        scheduleType={subledger.account.scheduleType}
      />
    </>
  );
};

export default Header;
