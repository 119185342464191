import clsx from 'clsx';
import { TableCell } from '@material-ui/core';
import React from 'react';
import { useTableStyles } from '../Subledger.styled';
import { AmortizationSource } from '../../../interfaces/types';
import InputBox from '../../InputBox';
import currencyFormatter from '../../../util/currencyFormatter';

interface Props {
  amortizationSource: AmortizationSource;
  hasChildren?: boolean;
  lastChild?: boolean;
  highlighted?: boolean;
  showCursor?: boolean;
  isRowSelected?: boolean;
  onInputBoxChange?: (propertyName: string, internalId: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  onInputBoxFocus?: (propertyName: string, internalId: string) => (event: React.FocusEvent<HTMLInputElement>) => void;
  totalBalance?: number;
  parentStartingBalance?: number;
  isCellSticky?: string;
}

const StartingBalanceEditableCell = ({
  amortizationSource,
  hasChildren,
  lastChild,
  highlighted,
  showCursor,
  isRowSelected,
  onInputBoxChange,
  onInputBoxFocus,
  totalBalance,
  parentStartingBalance,
  isCellSticky,
}: Props) => {
  const tableClasses = useTableStyles();
  return (
    <TableCell
      className={clsx(tableClasses.currencyCell, {
        [tableClasses.child]: amortizationSource.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.bold]: highlighted,
        [tableClasses.lastChild]: lastChild,
        [tableClasses.cursorPointer]: showCursor,
      })}
      is-cell-sticky={isCellSticky}
    >
      {!isRowSelected && currencyFormatter.format(amortizationSource?.startingBalance!)}
      {isRowSelected && !amortizationSource.parentId && currencyFormatter.format(amortizationSource?.startingBalance!)}
      {isRowSelected && amortizationSource.parentId && onInputBoxChange
      && (
        <InputBox
          fullWidth
          type="number"
          error={Boolean(totalBalance! > parentStartingBalance!)}
          readOnly={hasChildren}
          inputProps={{ style: { textAlign: 'right' } }}
          value={amortizationSource.startingBalance}
          className={tableClasses.inputBox}
          onChange={onInputBoxChange('startingBalance', amortizationSource.internalId)}
          onFocus={onInputBoxFocus && onInputBoxFocus('startingBalance', amortizationSource.internalId)}
          onBlur={onInputBoxFocus && onInputBoxFocus('startingBalance', amortizationSource.internalId)}
        />
      )}
    </TableCell>
  );
};

export default React.memo(StartingBalanceEditableCell);
