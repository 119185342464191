import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Popper,
  Paper,
  Box,
  ClickAwayListener,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import clsx from 'clsx';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { POPPER_ELEMENTS_Z_INDEX } from '../../../../util/constants';
import {
  CompareMethod,
  AmountFilterValue,
} from '../../../../interfaces/types';
import { StyledInput } from '../../../Inputs/index.styled';
import { commonFiltersStyles } from '../common/index.styled';
import { compareMethods } from '../../../../util/compareMethods';

interface IAmountFilter {
  _key: React.Key;
  label: string;
  value?: AmountFilterValue;
  onChange: (filter: AmountFilterValue | undefined) => void;
}

export const RemainingLifeFilter = ({
  _key,
  label,
  value,
  onChange,
}: IAmountFilter) => {
  const defaultValue = useMemo(() => ({
    compareMethod: CompareMethod.GT,
    multiplicator: 86400, // number of seconds representing day
  }), []);

  const [open, setOpen] = useState(false);
  const [innerValue, setInnerValue] = useState<AmountFilterValue>(value || defaultValue);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const styles = commonFiltersStyles();

  const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
    setOpen(!open);
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleChange = (propValue: number | undefined, propName: string) => {
    setInnerValue((previousInnerValue) => ({
      ...previousInnerValue,
      [propName]: propValue,
    }));
  };

  const handleChangeCompareMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
    const localCompareMethod = event.target.value as CompareMethod;

    setInnerValue((previousInnerValue) => ({
      ...previousInnerValue,
      compareMethod: localCompareMethod,
    }));
  };

  const isInnerValueCorrect = () => innerValue.compareMethod && innerValue.value1;

  const applyFilter = () => {
    if (isInnerValueCorrect()) {
      onChange(innerValue);
    }
    setOpen(false);
    setAnchorEl(null);
  };

  const clearFilter = () => {
    onChange(undefined);
    setOpen(false);
    setAnchorEl(null);
  };

  const handleCancel = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      clearFilter();
    }
  };

  useEffect(() => {
    setInnerValue(defaultValue);
  }, [_key, defaultValue]);

  useEffect(() => {
    setInnerValue(value || defaultValue);
  }, [value, defaultValue]);

  return (
    <>
      <Button
        type="button"
        onClick={handleOpen}
        fullWidth
        variant="outlined"
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        className={clsx(styles.filterButton, {
          [styles.selected]: !!value,
        })}
      >
        {label}
        {value && <span className={styles.filterDot} />}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="top-start"
        style={{
          zIndex: POPPER_ELEMENTS_Z_INDEX,
          minWidth: '250px',
        }}
        transition
      >
        <ClickAwayListener onClickAway={handleCancel}>
          <Paper
            className={styles.paper}
            elevation={3}
          >
            <form
              noValidate
              autoComplete="off"
            >
              <RadioGroup
                name="compareMethod"
                value={innerValue?.compareMethod}
                onChange={handleChangeCompareMethod}
                style={{ marginTop: '-5px' }}
              >
                {compareMethods.map((method) => (
                  <FormControlLabel
                    key={method.value}
                    value={method.value}
                    control={(
                      <Radio
                        className={styles.radioButton}
                        color="primary"
                      />
                    )}
                    label={method.name}
                  />
                ))}
              </RadioGroup>
              <Box
                display="flex"
                flexDirection="column"
                padding="10px 0 0"
              >
                <StyledInput
                  onChange={(ev) => handleChange(Number(ev.target.value), 'value1')}
                  value={innerValue?.value1 || ''}
                  onKeyDown={onKeyDown}
                  autoFocus
                  placeholder="days"
                />
                {(['in', 'nin'].includes(innerValue.compareMethod!)) && (
                  <>
                    <Box
                      padding="10px"
                      fontSize="12px"
                    >
                      and
                    </Box>
                    <StyledInput
                      onChange={(ev) => handleChange(Number(ev.target.value), 'value2')}
                      onKeyDown={onKeyDown}
                      value={innerValue?.value2 || ''}
                      placeholder="days"
                    />
                  </>
                )}
              </Box>
              <Box className={styles.buttonBox}>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={clearFilter}
                  size="small"
                >
                  Clear
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  onClick={applyFilter}
                  fullWidth
                  variant="contained"
                  size="small"
                  className={styles.applyButton}
                >
                  Apply
                </Button>
              </Box>
            </form>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
