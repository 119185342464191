import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import find from 'lodash.find';
import ContextMenu from '../../../../components/ContextMenu';
import { CommonSubledger } from '../../../../hooks/useAllSubledgers';
import { AppThunkDispatch } from '../../../../store/store';
import { putAccountBalances } from '../../../../store/slices/account';
import { patchAccount } from '../../../../store/slices/v2Accounts';
import { Account as V1Account } from '../../../../interfaces/types';
import { LoadingBox } from '../../../../components/LoadingBox';
import { MoreHorizIcon } from '../../../../components/Icons';

interface Props {
  commonSubledger: CommonSubledger;
  setSelectedId: (id: string) => void;
  v1Accounts?: V1Account[];
}

export const SubledgersSheetActions = ({
  commonSubledger,
  setSelectedId,
  v1Accounts,
}: Props) => {
  const history = useHistory();
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const [isUpdating, setIsUpdating] = useState(false);

  const handleEditSubledger = () => {
    if (commonSubledger.apiVersion === 1) {
      history.push(commonSubledger.finalized
        ? `/historical/subledgers/scheduler/${commonSubledger.id}`
        : `/subledgers/scheduler/${commonSubledger.id}`);
    } else {
      history.push(commonSubledger.finalized
        ? `/fixed-assets/edit/${commonSubledger.id}`
        : `/fixed-assets/create/${commonSubledger.id}`);
    }
  };

  const handleToggleVisibility = () => {
    setIsUpdating(true);
    if (commonSubledger.apiVersion === 1) {
      const v1Account = find(v1Accounts, { subledger: { id: commonSubledger.id } });
      reduxDispatch(putAccountBalances({
        id: commonSubledger.accountId,
        enable: !commonSubledger.visible,
        scheduleType: v1Account?.scheduleType!,
      }))
        .finally(() => setIsUpdating(false));
    } else {
      reduxDispatch(patchAccount({
        accountId: commonSubledger.accountId,
        account: {
          visible: !commonSubledger.visible!,
        },
      }))
        .finally(() => setIsUpdating(false));
    }
  };

  const menuItems = [
    {
      title: 'Edit',
      action: handleEditSubledger,
      disabled: !commonSubledger.visible,
    },
    {
      title: commonSubledger.visible ? 'Hide' : 'Show',
      action: handleToggleVisibility,
    },
  ];

  return (
    <>
      {isUpdating && (
        <LoadingBox transparent />
      )}
      <ContextMenu
        menuItems={menuItems}
        onOpen={() => setSelectedId(commonSubledger.id)}
        onClose={() => setSelectedId('')}
      >
        <MoreHorizIcon style={{ margin: 0 }} />
      </ContextMenu>
    </>
  );
};
