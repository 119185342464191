/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { request } from '../../util/request';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../interfaces/types';
import { Contact } from '../../interfaces/contacts';
import { handleAuthError } from '../../util/auth';

const PREFIX = 'v2Contacts';
const GET_ALL_CONTACTS = `${PREFIX}/getAllContacts`;

interface State {
  isFetchingContacts: boolean;
  contacts: Array<Contact>;
}

const initialState: State = {
  isFetchingContacts: false,
  contacts: [],
};

export const getAllContacts = createAsyncThunk<{ contacts: Array<Contact> }>(
  GET_ALL_CONTACTS,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request().get<{ contacts: Array<Contact> }>('/v2/contacts');

      return data;
    } catch (error) {
      const handledError = error?.response?.data?.raw || 'Unknown error';

      if (handledError === 'invalidated authorization token') {
        handleAuthError();
      }

      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const contactsSlice = createSlice({
  initialState,
  name: PREFIX,
  reducers: {
    clearContacts: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllContacts.pending, (state) => {
      state.isFetchingContacts = true;
    });
    builder.addCase(getAllContacts.fulfilled, (state, { payload }) => {
      state.isFetchingContacts = false;
      state.contacts = [...payload.contacts];
    });
    builder.addCase(getAllContacts.rejected, (state) => {
      state.isFetchingContacts = false;
    });
  },
});

export const {
  clearContacts,
} = contactsSlice.actions;

export const contactsReducer = contactsSlice.reducer;
