import React from 'react';
import {
  Controller,
  Control,
} from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { StyledNumberInput } from '../index.styled';

interface Props {
  name: string;
  defaultValue: number;
  control: Control;
  error?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  prefix?: string;
  suffix?: string;
  decimalScale?: number;
  onUpdate?: () => void;
}

export const NumberInput = ({
  control,
  name,
  defaultValue,
  error,
  onBlur,
  onUpdate,
  disabled,
  prefix = '$',
  suffix,
  decimalScale = 2,
}: Props) => (
  <Controller
    render={({ onChange, ...props }) => (
      <NumberFormat
        customInput={StyledNumberInput}
        thousandSeparator
        {...(suffix ? { suffix } : { prefix })}
        decimalScale={decimalScale}
        fixedDecimalScale
        onValueChange={(v) => {
          onChange(v.floatValue);
          onUpdate && onUpdate();
        }}
        {...props}
        error={error}
        onBlur={(e) => {
          props.onBlur();
          onBlur && onBlur(e);
        }}
        disabled={disabled}
      />
    )}
    name={name}
    control={control}
    variant="outlined"
    defaultValue={defaultValue}
  />
);
