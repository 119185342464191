import React, {
  useReducer,
  useState,
  useEffect,
} from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  Box,
  Fab,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  compareAsc,
  format,
  isAfter,
  isWithinInterval,
  lastDayOfMonth,
  parse,
} from 'date-fns';
import {
  useHistory,
  useLocation,
} from 'react-router';
import cloneDeep from 'lodash.clonedeep';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SubledgerCard from './SubledgerCard';
import {
  Account,
  ProductCategory,
  Subledger,
} from '../../interfaces/types';
import getSubledgersStartEndDate from '../../util/getSubledgersStartEndDate';
import COLORS from '../../theme/colors';
import {
  DAY_SHORT_FORMAT,
  INITIALED_WITHOUT_MODIFICATION,
} from '../../util/constants';
import SubledgerDetailsPrepaidExpense from './SubledgerDetailsPrepaidExpense';
import ErrorPage from '../ErrorPage';
import reducer from '../Subledger/reducer';
import isEmpty from '../../util/isEmpty';
import SubledgerDetailsRevSync from './SubledgerDetailsRevSync';
import MonthPicker from '../MonthPicker';
import JEMismatchAlert from './Alert/JEMismatchAlert';
import { getAccountName } from '../Subledger/common';
import {
  ScrollableBoxContainer,
  ScrollableTableContainer,
} from '../ScrollableTable';
import { getDashboardBalances } from '../../store/slices/dashboard';
import { AppThunkDispatch } from '../../store/store';
import {
  dashboardBalancesSelector,
  fetchingDashboardError,
  isFetchingDashboardBalances,
} from '../../store/selectors/dashboard';
import { featureSwitch } from '../../util/featureSwitch';

const useStyles = makeStyles({
  root: {
    padding: '16px 16px 0',
    lineHeight: '32px',
    '& span': {
      marginRight: 8,
      color: COLORS.deepGray,
    },
    '& input': {
      paddingLeft: 12,
      background: COLORS.white,
      color: COLORS.medGray,
    },
  },
  backgroundWhite: {
    background: COLORS.white,
  },
  cardRecordsStyles: {
    marginLeft: '1.5%',
    marginTop: 50,
  },
  alert: {
    width: '90%',
    backgroundColor: COLORS.violet15,
    color: COLORS.medGray,
  },
  diagnosisButton: {
    paddingLeft: '4px',
    marginTop: '15px',
    color: COLORS.violet,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  menuWrapper: {
    width: '100%',
    position: 'relative',
    '& .menu-wrapper--inner': {
      display: 'flex',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  menuItemWrapper: {
    '&:focus': {
      outline: 'none',
    },
  },
  arrowClass: {
    color: COLORS.skyBlue,
  },
  fabLeftClass: {
    position: 'absolute',
    left: 10,
    top: '40%',
    zIndex: 1,
    backgroundColor: COLORS.white,
  },
  fabRightClass: {
    position: 'absolute',
    top: '40%',
    right: 10,
    zIndex: 1,
    backgroundColor: COLORS.white,
  },
  arrowDisabledCss: {
    visibility: 'hidden',
  },
});

interface Props {
  subledgers: Array<Subledger>;
  accountBalances: Array<Account>;
  category: ProductCategory;
}

const Accounts = ({
  subledgers,
  accountBalances,
  category,
}: Props) => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const history = useHistory();
  const scheduleDate = useQuery()
    .get('scheduleDate');
  const refDate = scheduleDate ? parse(scheduleDate, DAY_SHORT_FORMAT, new Date()) : null;
  const {
    startDate,
    endDate,
    dashboardSelectedDate,
  } = getSubledgersStartEndDate(subledgers);
  const [selectedDate, setSelectedDate] = useState<Date | null>(refDate ?? dashboardSelectedDate);
  const classes = useStyles();
  const ids = subledgers?.map((subledger) => subledger?.account?.id)
    .join(',');
  const selectedDateFormatted = format(lastDayOfMonth(selectedDate!), DAY_SHORT_FORMAT);
  const accounts = useSelector(dashboardBalancesSelector);
  const loading = useSelector(isFetchingDashboardBalances);
  const error = useSelector(fetchingDashboardError);
  // @ts-ignore
  const [state, dispatch] = useReducer(reducer, {
    subledger: {},
    scheduleDate: selectedDate,
  });

  const [dashboardSubledgers, setDashboardSubledgers] = useState<Array<Subledger>>(cloneDeep(subledgers));

  useEffect(() => {
    if (!isEmpty(state?.subledger) || subledgers) {
      const allSubledgers = cloneDeep(cloneDeep(subledgers));
      setDashboardSubledgers(allSubledgers);
      const subledger = allSubledgers?.find((item) => item.id === state?.subledger?.id);
      dispatch({
        type: INITIALED_WITHOUT_MODIFICATION,
        payload: {
          scheduleDate,
          subledger,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subledgers]);

  const onDateChange = (date: Date | null) => {
    if (date && startDate && endDate
      && isAfter(endDate!, startDate!)
      && isWithinInterval(date, {
        start: startDate!,
        end: endDate!,
      })) {
      setSelectedDate(date);
      if (scheduleDate) {
        if (category === ProductCategory.PrepaidExpense) {
          history.replace('/');
        } else if (category === ProductCategory.DeferredRevenue) {
          history.replace('/revsync');
        }
      }
    }
  };

  const onSubledgerChange = (id: any) => {
    if (!isEmpty(state?.subledger)) {
      if (id === state?.subledger?.id) {
        return;
      }
      const allSubledgers = cloneDeep(dashboardSubledgers);
      const dashboardSubledgerIndex = dashboardSubledgers
        ?.findIndex((item) => item.id === state?.subledger?.id);
      allSubledgers[dashboardSubledgerIndex] = state?.subledger;
      setDashboardSubledgers(allSubledgers);
    }
    const subledger = dashboardSubledgers?.find((item) => item.id === id);
    dispatch({
      type: INITIALED_WITHOUT_MODIFICATION,
      payload: {
        scheduleDate,
        subledger,
      },
    });
  };

  useEffect(() => {
    reduxDispatch(getDashboardBalances({
      selectedDateFormatted,
      ids,
    }));
  }, [reduxDispatch, selectedDateFormatted, ids]);

  if (error) {
    return (<ErrorPage />);
  }

  const cardList = dashboardSubledgers
    ?.filter((subledger: Subledger) => selectedDate && subledger.factaStartDate && (compareAsc(
      lastDayOfMonth(selectedDate),
      lastDayOfMonth(subledger.factaStartDate),
    ) !== -1))
    .map((subledger: Subledger) => (
      <SubledgerCard
        key={subledger.id}
        subledgerAccountStartingBalance={accountBalances
          ?.find((account) => account.id === subledger?.accountId)?.glBalance!}
        loading={loading}
        active={state?.subledger?.id === subledger?.id}
        subledger={state?.subledger?.id === subledger?.id ? state?.subledger : subledger}
        selectedDate={selectedDate}
        account={accounts.find((account: Account) => account.id === subledger?.account?.id)!}
        category={category}
        accountBalances={accountBalances}
      />
    ));

  const ArrowLeft = (
    <Fab className={classes.fabLeftClass}>
      <ArrowBackIcon className={classes.arrowClass} />
    </Fab>
  );
  const ArrowRight = (
    <Fab className={classes.fabRightClass}>
      <ArrowForwardIcon className={classes.arrowClass} />
    </Fab>
  );

  return (
    <>
      <JEMismatchAlert
        subledgers={subledgers}
      />
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        className={classes.root}
      >
        <span>Choose Period</span>
        <MonthPicker
          selectedDate={selectedDate}
          onDateChange={onDateChange}
          maxDate={endDate!}
          minDate={startDate!}
        />
      </Box>
      <Box
        width="100%"
        position="relative"
      >
        <ScrollMenu
          data={cardList}
          arrowLeft={ArrowLeft}
          arrowRight={ArrowRight}
          selected={state?.subledger?.id}
          onSelect={onSubledgerChange}
          alignCenter={false}
          alignOnResize
          wrapperClass={classes.menuWrapper}
          itemClass={classes.menuItemWrapper}
          arrowDisabledClass={classes.arrowDisabledCss}
          hideArrows
          dragging={false}
          inertiaScrolling={false}
          wheel={false}
          hideSingleArrow
          scrollToSelected
        />
      </Box>

      {
        !isEmpty(state?.subledger) && (
          <>
            <Typography
              variant="body1"
              style={{ fontSize: 16, margin: 16 }}
            >
              {getAccountName(state?.subledger?.account)}
            </Typography>
            <ScrollableBoxContainer>
              <ScrollableTableContainer>
                {state?.subledger?.productCategory === ProductCategory.PrepaidExpense
                && (
                  <SubledgerDetailsPrepaidExpense
                    dispatch={dispatch}
                    subledger={state?.subledger}
                    selectedRow={state?.selectedRow}
                    showFilters={featureSwitch.prepaidSyncFiltering}
                  />
                )}
                {state?.subledger?.productCategory === ProductCategory.DeferredRevenue
                && (
                  <SubledgerDetailsRevSync
                    dispatch={dispatch}
                    subledger={state?.subledger}
                    selectedRow={state?.selectedRow}
                  />
                )}
              </ScrollableTableContainer>
            </ScrollableBoxContainer>
          </>
        )
      }
    </>
  );
};
export default React.memo(Accounts);
