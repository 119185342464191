import React, { useEffect } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import Box from '@material-ui/core/Box';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import {
  Button,
  createStyles,
} from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import makeStyles from '@material-ui/styles/makeStyles';
import clsx from 'clsx';
import {
  FormInputBox,
  StyledFormControl,
  StyledFormHelperText,
  StyledInputLabel,
} from '../../components/FormControl';
import {
  StyledInputLabelRequired,
  StyledFormLabel,
} from '../../components/FormControl/FormControl.styled';
import 'react-phone-input-2/lib/style.css';
import COLORS from '../../theme/colors';
import { AppThunkDispatch } from '../../store/store';
import {
  getMe,
  putMe,
} from '../../store/slices/settings';
import {
  isFetchingMe,
  meSelector,
} from '../../store/selectors/settings';
import { Loader } from '../../components/Loader';

const useStyles = makeStyles(() => createStyles({
  inputClass: {
    width: '100% !important',
    background: COLORS.white,
    border: `1px solid ${COLORS.border} !important`,
    borderRadius: 4,
    color: COLORS.deepGray,

    '&.focus-visible': {
      border: `1px solid ${COLORS.violet} !important`,

      '& + .flag-dropdown': {
        border: `1px solid ${COLORS.violet} !important`,
      },
    },
  },
  inputClassError: {
    border: `1px solid ${COLORS.error} !important`,
  },
  buttonClass: {
    border: '1px solid rgba(207, 211, 218, 0.6) !important',
  },
  buttonClassError: {
    border: `1px solid ${COLORS.error} !important`,
  },
  update: {
    marginTop: 20,
    marginLeft: 4,
  },
}));

const Profile = () => {
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const classes = useStyles();

  const user = useSelector(meSelector);
  const infoLoading = useSelector(isFetchingMe);

  const {
    register,
    control,
    handleSubmit,
    errors,
    reset,
    trigger,
  } = useForm({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      mobilePhone: '',
      officePhone: '',
    },
  });

  useEffect(() => {
    reduxDispatch(getMe());
  }, [reduxDispatch]);

  useEffect(() => {
    if (user) {
      reset({ ...user });
      trigger();
    }
  }, [reset, trigger, user]);

  if (infoLoading && !user) {
    return (<Loader open={infoLoading} />);
  }

  const onSignUp = async (data: any) => {
    reduxDispatch(putMe({ ...data }));
  };

  return (
    <>
      <Loader open={infoLoading} />
      <StyledFormLabel>Profile</StyledFormLabel>
      <form
        autoComplete="off"
        onSubmit={handleSubmit(onSignUp)}
        noValidate
      >
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
        >
          <FormInputBox
            title="First Name"
            name="firstName"
            type="input"
            require
            defaultValue={user?.firstName}
            hasError={Boolean(errors?.firstName)}
            helperText={errors?.firstName?.message}
            inputRef={register({
              required: { value: true, message: 'First Name is required' },
            })}
          />
          <FormInputBox
            title="Last Name"
            name="lastName"
            type="input"
            require
            defaultValue={user?.lastName}
            hasError={Boolean(errors?.lastName)}
            helperText={errors?.lastName?.message}
            inputRef={register({
              required: { value: true, message: 'Last Name is required' },
            })}
          />
          <FormInputBox
            title="Email Address"
            name="email"
            type="email"
            require
            hasError={Boolean(errors?.email)}
            defaultValue={user?.email}
            helperText={errors?.email?.message}
            inputRef={register({
              required: { value: true, message: 'Email is required' },
              pattern: {
                value: /[^\\.\\s@:](?:[^\\s@:]*[^\\s@:\\.])?@[^\\.\\s@]+(?:\\.[^\\.\\s@]+)*/,
                message: 'Please enter correct email address.',
              },
            })}
          />
          <StyledFormControl>
            <StyledInputLabel>
              Office Phone
              <StyledInputLabelRequired>*</StyledInputLabelRequired>
            </StyledInputLabel>
            <Controller
              control={control}
              name="officePhone"
              mask="+99 999 - 999 - 9999"
              type="tel"
              maskChar={null}
              defaultValue={user?.officePhone}
              error={errors?.officePhone}
              country="us"
              required
              rules={{ required: { value: true, message: 'Office Phone is required' } }}
              as={(
                <PhoneInput
                  inputClass={clsx(classes.inputClass, {
                    [classes.inputClassError]: errors?.officePhone,
                  })}
                  buttonClass={clsx(classes.buttonClass, {
                    [classes.buttonClassError]: errors?.officePhone,
                  })}
                />
              )}
            />
            {errors?.officePhone && (
              <StyledFormHelperText>{errors?.officePhone?.message}</StyledFormHelperText>
            )}
          </StyledFormControl>
          <StyledFormControl>
            <StyledInputLabel>
              Mobile Phone
            </StyledInputLabel>
            <Controller
              control={control}
              name="mobilePhone"
              mask="+99 999 - 999 - 9999"
              type="tel"
              maskChar={null}
              defaultValue={user?.mobilePhone}
              country="us"
              as={(
                <PhoneInput
                  inputClass={classes.inputClass}
                  buttonClass={classes.buttonClass}
                />
              )}
            />
          </StyledFormControl>
          <Box
            display="flex"
            flexDirection="row"
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.update}
            >
              UPDATE
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default React.memo(Profile);
