import clsx from 'clsx';
import { TableCell } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTableStyles } from '../Subledger.styled';
import { AmortizationSource } from '../../../interfaces/types';
import { getVendorName } from '../common';
import { accountInfoSelector } from '../../../store/selectors/account';

interface Props {
  amortizationSource: AmortizationSource;
  hasChildren?: boolean;
  lastChild?: boolean;
  highlighted?: boolean;
  showCursor?: boolean;
  isCellSticky?: string;
}

const VendorCell = ({
  amortizationSource,
  hasChildren,
  lastChild,
  highlighted,
  showCursor,
  isCellSticky,
}: Props) => {
  const tableClasses = useTableStyles();
  const account = useSelector(accountInfoSelector)!;
  const { vendors } = account;
  return (
    <TableCell
      className={clsx(tableClasses.customerCell, {
        [tableClasses.child]: amortizationSource.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.bold]: highlighted,
        [tableClasses.lastChild]: lastChild,
        [tableClasses.cursorPointer]: showCursor,
      })}
      is-cell-sticky={isCellSticky}
    >
      {
        !amortizationSource.parentId && getVendorName(amortizationSource.vendorId, vendors)
      }
    </TableCell>
  );
};

export default React.memo(VendorCell);
