import { withStyles } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import COLORS from '../../theme/colors';

export const Accordion = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '-10px auto 0 0',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: '-10px auto 20px 0',
    },
    '& svg': {
      width: '20px',
      height: '20px',
      padding: 0,
      margin: 0,
      marginTop: '-2px',
      transform: 'rotate(-90deg)',
      transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.shortest,
      }),
    },
  },
  expanded: {},
}))(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    padding: '0 5px',
    margin: '5px auto',
    textAlign: 'center',
    minHeight: 0,
    '&$expanded': {
      minHeight: 0,
      '& svg': {
        transform: 'rotate(0deg)',
      },
    },
  },
  content: {
    fontSize: '11px',
    color: COLORS.skyBlue,
    margin: 0,
    flexGrow: 0,
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles(() => ({
  root: {
    padding: '10px 0 0 0',
    flexDirection: 'column',
  },
}))(MuiAccordionDetails);
