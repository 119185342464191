/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SortDownIcon = (props: any) => (
  <SvgIcon
    fontSize="small"
    width="8"
    height="11"
    viewBox="0 0 8 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ fontSize: '12px' }}
    {...props}
  >
    <path
      d="M3.92857 11L7.33081 6.28571H0.526329L3.92857 11Z"
      fill="#CFD3DA"
    />
    <path
      d="M3.92861 3.05286e-07L0.526363 4.71428L7.33085 4.71428L3.92861 3.05286e-07Z"
      fill="#252729"
    />
  </SvgIcon>
);

export default SortDownIcon;
