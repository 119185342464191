import {
  addMonths,
  differenceInCalendarMonths,
  format,
  lastDayOfMonth,
  startOfMonth,
} from 'date-fns';
import React from 'react';
import {
  AmortizationSource,
  AmortizationScheduleDetails,
} from '../../interfaces/types';
import {
  getAssetSumForMonth,
  getChildren,
} from './common';
import { DAY_SHORT_FORMAT } from '../../util/constants';
import CreateSubledgerAmortizationCell from './TableCells/CreateSubledgerAmortizationCell';

interface Props {
  startDate: Date;
  endDate: Date | null;
  asset: AmortizationSource;
  lastChild?: boolean;
  amortizationSources: Array<AmortizationSource>;
}

const CreateSubledgerAmortizationSchedules = ({
  startDate,
  endDate,
  asset,
  lastChild,
  amortizationSources,
}: Props) => {
  if (startDate && endDate) {
    const diffInMonths = differenceInCalendarMonths(lastDayOfMonth(endDate!), startOfMonth(startDate!)) + 1;
    if (diffInMonths > 0) {
      const children = getChildren(asset.internalId, amortizationSources);
      const hasChildren = children?.length > 0;
      return (
        <>
          {
            Array.from(Array(diffInMonths).keys()).map((index: number) => {
              const dayOfMonth = lastDayOfMonth(addMonths(startDate!, index));
              const lastDay = format(dayOfMonth, DAY_SHORT_FORMAT);
              const sum = hasChildren ? getAssetSumForMonth(lastDay, children) : 0;
              const schedule = asset?.amortizationSchedule?.amortizationScheduleDetails
                ?.find((item: AmortizationScheduleDetails) => format(
                  item.scheduleDate, DAY_SHORT_FORMAT,
                ) === lastDay);
              return (
                <CreateSubledgerAmortizationCell
                  key={schedule?.id}
                  lastChild={lastChild}
                  hasChildren={hasChildren}
                  asset={asset}
                  sum={sum}
                  index={index}
                  schedule={schedule}
                />
              );
            })
          }
        </>
      );
    }
  }
  return null;
};

export default CreateSubledgerAmortizationSchedules;
