import {
  createStyles,
  makeStyles,
  TableCell,
  TableFooter,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import currencyFormatter from '../../util/currencyFormatter';
import { useTableStyles } from './Subledger.styled';
import { accountInfoSelector } from '../../store/selectors/account';
import COLORS from '../../theme/colors';
import { ProductCategory } from '../../interfaces/types';

interface Props {
  DRAmount: number;
  CRAmount: number;
  productCategory: ProductCategory;
}

const useStyles = makeStyles(() => createStyles({
  totalAmount: {
    color: COLORS.deepGray,
    fontWeight: 'bold',
    fontSize: 13,
  },
  tableFooter: {
    background: COLORS.lightGray3,
  },
}));

const JournalEntryViewTableFooter = ({
  DRAmount,
  CRAmount,
  productCategory,
}: Props) => {
  const tableClasses = useTableStyles();
  const classes = useStyles();
  const account = useSelector(accountInfoSelector)!;

  const getColSpan = () => {
    const incremental = productCategory === ProductCategory.DeferredRevenue ? 1 : 0;
    return ((account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) ? 6 : 5) + incremental;
  };

  return (
    <TableFooter className={classes.tableFooter}>
      <TableCell
        colSpan={getColSpan()}
        align="right"
        className={classes.totalAmount}
      >
        Total
      </TableCell>
      <TableCell className={clsx(tableClasses.prepaidAssetAmount, classes.totalAmount)}>
        {currencyFormatter.format(Math.abs(DRAmount))}
      </TableCell>
      <TableCell className={clsx(tableClasses.prepaidAssetAmount, classes.totalAmount)}>
        {currencyFormatter.format(Math.abs(CRAmount))}
      </TableCell>
    </TableFooter>
  );
};

export default memo(JournalEntryViewTableFooter);
