import React from 'react';
import JournalEntryView from './JournalEntryView';
import Layout from '../../components/Layout';

const JournalEntryContainer = () => (
  <Layout title="Journal Entries">
    <JournalEntryView />
  </Layout>
);

export default JournalEntryContainer;
