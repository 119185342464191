import { styled } from '@material-ui/core/styles';
import {
  Button,
  Typography,
} from '@material-ui/core';
import COLORS from '../../theme/colors';

export const InfoTypography = styled(Typography)({
  fontSize: 12,
  marginRight: 16,
});

export const QuickBooksButton = styled(Button)({
  backgroundColor: COLORS.quickBooksGreen,

  '&:hover, &:focus': {
    boxShadow: `0 0 0 3px ${COLORS.quickBooksGreenShadow}`,
    backgroundColor: COLORS.quickBooksGreen,
  },
});

export const SuccessTypography = styled(Typography)({
  fontSize: 13,
  color: COLORS.deepGray,
});
