import { format } from 'date-fns';
import fileDownload from 'js-file-download';
import Papa from 'papaparse';
import roundTo from 'round-to';
import {
  getAccountName,
  getAccountNameFromList,
  getClassName,
  getCustomerName,
  getProductName,
  getVendorName,
} from '../../../components/Subledger/common';
import {
  Account,
  AccountClass,
  Customer,
  JournalEntryLineItem,
  Product,
  ProductCategory,
  Subledger,
  Vendor,
} from '../../../interfaces/types';
import {
  CREDIT,
  DEBIT,
  FULL_DAY_FORMAT,
} from '../../../util/constants';
import { fromUTC } from '../../../util/timezone';
import getFullName from '../../../util/user';

export const downloadJournalEntrySummary = (data: Subledger) => {
  const journalEntries = data?.journalEntries.map((journalEntry: any) => ({
    ...journalEntry,
    status: 'Posted',
    amount: roundTo(journalEntry.amount, 2),
    txnDate: format(fromUTC(journalEntry.txnDate), FULL_DAY_FORMAT),
    glAccount: getAccountName(data?.account),
    user: getFullName(journalEntry.user),
  }));
  const csvData = Papa.unparse({
    data: journalEntries,
    fields: ['txnDate', 'jeNumber', 'jeNumber', 'glAccount', 'description', 'user', 'amount', 'status'],
  }, {
    header: false,
  });
  const downloadData = `${[
    'JE Date',
    'Facta JE #',
    'GL JE #',
    'GL Account',
    'JE Description',
    'User',
    'Amount',
    'Status',
  ].join(',')}\n${csvData}`;
  fileDownload(downloadData, 'Journal Entries Summary.csv');
};

export const downloadJournalEntryDetail = (data: Subledger, vendors: Array<Vendor>,
  accountClasses: Array<AccountClass>, accountIncomes: Array<Account>,
  products: Array<Product>, customers: Array<Customer>) => {
  const journalEntries: any = [];
  data?.journalEntries?.forEach((journalEntry: any) => {
    journalEntry?.journalEntryLineItems?.forEach((lineItem: JournalEntryLineItem, index: number) => {
      journalEntries.push({
        jeNumber: journalEntry.jeNumber,
        txnDate: format(fromUTC(journalEntry.txnDate), FULL_DAY_FORMAT),
        amount: roundTo(journalEntry.amount, 2),
        glAccount: getAccountName(data?.account),
        user: getFullName(journalEntry.user),
        description: journalEntry.description,
        lineNumber: index + 1,
        lineDescription: lineItem.description,
        // @ts-ignore
        vendor: getVendorName(lineItem?.vendorId, vendors),
        // @ts-ignore
        customer: getCustomerName(lineItem?.customerId, customers),
        // @ts-ignore
        product: getProductName(lineItem?.productId, products),
        expenseAccount: getAccountNameFromList(lineItem?.accountId, accountIncomes),
        class: getClassName(lineItem?.classId!, accountClasses),
        dr: lineItem?.postingType === DEBIT ? lineItem.amount : '',
        cr: lineItem?.postingType === CREDIT ? lineItem.amount : '',
      });
    });
  });
  const fields = data?.productCategory === ProductCategory.PrepaidExpense
    ? ['txnDate', 'jeNumber', 'jeNumber', 'glAccount', 'description', 'lineNumber',
      'lineDescription', 'vendor', 'expenseAccount', 'class', 'user', 'amount', 'dr', 'cr']
    : ['txnDate', 'jeNumber', 'jeNumber', 'glAccount', 'description', 'lineNumber',
      'lineDescription', 'customer', 'product', 'expenseAccount', 'class', 'user', 'amount', 'dr', 'cr'];
  const csvData = Papa.unparse({
    data: journalEntries,
    fields,
  }, {
    header: false,
  });
  const columns = data?.productCategory === ProductCategory.PrepaidExpense
    ? ['JE Date', 'Facta JE #', 'GL JE #', 'GL Account', 'JE Description', 'Line Number',
      'Line Description', 'Vendor', 'GL Expense Account', 'Class', 'User', 'Amount', 'DR', 'CR']
    : ['JE Date', 'Facta JE #', 'GL JE #', 'GL Account', 'JE Description', 'Line Number',
      'Line Description', 'Customer', 'Product/Service', 'GL Revenue Account', 'Class', 'User', 'Amount', 'DR', 'CR'];
  const downloadData = `${columns.join(',')}\r\n${csvData}`;
  fileDownload(downloadData, 'Journal Entries Detail.csv');
};
