import { Route } from 'react-router-dom';
import React from 'react';

import CreateSubledger from './CreateSubledger';
import SchedulerWizard from './SchedulerWizard';

const Scheduler = () => (
  <>
    <Route
      path="/subledgers/scheduler/:id"
      exact
      component={CreateSubledger}
    />
    <Route
      path="/subledgers/schedule/:id"
      component={SchedulerWizard}
    />
  </>
);

export default React.memo(Scheduler);
