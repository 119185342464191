import React, { useState } from 'react';
import {
  Button,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import COLORS from '../theme/colors';

interface Props {
  readonly isCollapsed: boolean;
}

const useStyles = makeStyles<Theme, Props>(() => createStyles({
  showHideButton: {
    fontSize: 10,
    color: ({ isCollapsed }) => (isCollapsed ? COLORS.lightGray : COLORS.skyBlue),
    textTransform: 'none',
    '& svg': {
      fontSize: '16px !important',
    },
  },
}));

const useCollapsedView = (initialState: boolean, name: string): [boolean, boolean, () => JSX.Element] => {
  const [isCollapsed, setIsCollapsed] = useState(initialState);
  const [isHovered, setIsHovered] = useState(false);
  const classes = useStyles({ isCollapsed });

  const ToggleElement = () => (
    <Button
      color="primary"
      className={classes.showHideButton}
      startIcon={isCollapsed ? (
        <VisibilityOffIcon
          color="disabled"
          fontSize="small"
        />
      ) : (
        <VisibilityIcon
          color="primary"
          fontSize="small"
        />
      )}
      onClick={() => {
        setIsCollapsed((prevState) => !prevState);
        setIsHovered(false);
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isCollapsed ? 'Show ' : 'Hide '}
      {name}
    </Button>
  );

  return [isCollapsed, isHovered, ToggleElement];
};

export default useCollapsedView;
