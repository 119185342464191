import React from 'react';
import { useSelector } from 'react-redux';
import TableBody from '@material-ui/core/TableBody';
import {
  AmortizationSource,
  Subledger,
} from '../../interfaces/types';
import {
  getChildren,
  getStartEndDate,
} from './common';
import AddSplitButtonRow from './AddSplitButtonRow';
import CreateSubledgerPrepaidExpenseSelectedRow from './CreateSubledgerPrepaidExpenseSelectedRow';
import CreateSubledgerPrepaidExpenseTableHeader from './CreateSubledgerPrepaidExpenseTableHeader';
import CreateSubledgerPrepaidExpenseReadOnlyRow from './CreateSubledgerPrepaidExpenseReadOnlyRow';
import { accountInfoSelector } from '../../store/selectors/account';

interface Props {
  subledger: Subledger;
  selectedRow: string;
  hoverRow: string;
  formSubmit?: boolean;
  addSplit: (internalId: string) => () => void;
  addSplitAsset: (internalId: string) => () => void;
  removeAsset: (internalId: string) => () => void;
  onInputBoxChange: (propertyName: string, internalId: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  onInputBoxFocus: (propertyName: string, internalId: string) => (event: React.FocusEvent<HTMLInputElement>) => void;
  onSelectChange: (propertyName: string, internalId: string) => (value: string) => void;
  onAutoCompleteChange: (propertyName: string, internalId: string) => (value: string) => void;
  onDateChange: (propertyName: string, internalId: string, value: Date | null) => void;
  onShowDetails: (internalId: string, sourceId: string) => void;
  isCollapsedView: boolean;
  isCollapsedViewHovered: boolean;
  isSplitAssetsSumCorrect: boolean;
  onCheckboxChange: (internalId: string) => void;
  checkIsElementSelected: (internalId: string) => boolean;
  onSelectAll: () => void;
  isAllSelected: boolean;
  onRowClick: (asset: AmortizationSource) => void;
  showBulkEdit: boolean;
  page: number;
  pageSize: number;
}

const CreateSubledgerPrepaidExpenses = ({
  subledger,
  selectedRow,
  hoverRow,
  formSubmit,
  addSplit,
  addSplitAsset,
  removeAsset,
  onInputBoxChange,
  onInputBoxFocus,
  onSelectChange,
  onAutoCompleteChange,
  onDateChange,
  onShowDetails,
  isCollapsedView,
  isCollapsedViewHovered,
  isSplitAssetsSumCorrect,
  onCheckboxChange,
  onSelectAll,
  isAllSelected,
  checkIsElementSelected,
  onRowClick,
  showBulkEdit,
  page,
  pageSize,
}: Props) => {
  const account = useSelector(accountInfoSelector)!;
  const { startDate, endDate } = getStartEndDate(subledger);

  const renderSelectedRow = (asset: AmortizationSource, index: number) => {
    const children = getChildren(asset.internalId, subledger?.amortizationSources);
    const parent = (
      <CreateSubledgerPrepaidExpenseSelectedRow
        key={asset.internalId}
        selectedRow={selectedRow}
        isSelectedForBulkEdit={checkIsElementSelected(asset.internalId)}
        onCheckboxChange={onCheckboxChange}
        amortizationSource={asset}
        hoverRow={hoverRow}
        onRowSelected={() => onRowClick(asset)}
        hasChildren={!!children?.length}
        index={index}
        formSubmit={formSubmit}
        onVendorChange={onAutoCompleteChange('vendorId', asset.internalId)}
        onExpenseAccountChange={onAutoCompleteChange('destinationId', asset.internalId)}
        account={account}
        onClassChange={onAutoCompleteChange('classId', asset.internalId)}
        onDateChange={onDateChange}
        onInputBoxChange={onInputBoxChange}
        onInputBoxFocus={onInputBoxFocus}
        onSelectChange={onAutoCompleteChange}
        onRemoveAsset={removeAsset(asset.internalId)}
        onAddAsset={addSplit(asset.internalId)}
        startDate={startDate}
        endDate={endDate}
        amortizationSources={subledger?.amortizationSources}
        factaStartDate={subledger?.factaStartDate}
        isCollapsedView={isCollapsedView}
        isSplitAssetsSumCorrect={isSplitAssetsSumCorrect}
        onShowDetails={(sourceId: string) => onShowDetails(asset.internalId, sourceId)}
        showBulkEdit={showBulkEdit}
      />
    );
    if (!children?.length) {
      return parent;
    }
    return (
      <>
        {parent}
        {
            children?.map((child: AmortizationSource, childIndex: number) => (
              <CreateSubledgerPrepaidExpenseSelectedRow
                key={child.internalId}
                selectedRow={selectedRow}
                isSelectedForBulkEdit={checkIsElementSelected(asset.internalId)}
                amortizationSource={child}
                hoverRow={hoverRow}
                onRowSelected={() => onRowClick(asset)}
                onCheckboxChange={onCheckboxChange}
                hasChildren={false}
                index={childIndex}
                formSubmit={formSubmit}
                onVendorChange={onAutoCompleteChange('destinationId', asset.internalId)}
                onExpenseAccountChange={onAutoCompleteChange('destinationId', child.internalId)}
                account={account}
                onClassChange={onAutoCompleteChange('classId', child.internalId)}
                onInputBoxChange={onInputBoxChange}
                onInputBoxFocus={onInputBoxFocus}
                onDateChange={onDateChange}
                onSelectChange={onSelectChange}
                onRemoveAsset={removeAsset(child.internalId)}
                onAddAsset={addSplit(asset.internalId)}
                startDate={startDate}
                endDate={endDate}
                amortizationSources={subledger?.amortizationSources}
                lastChild={children?.length - 1 === childIndex}
                factaStartDate={subledger?.factaStartDate}
                isCollapsedView={isCollapsedView}
                isSplitAssetsSumCorrect={isSplitAssetsSumCorrect}
                onShowDetails={(sourceId: string) => onShowDetails(child.internalId, sourceId)}
                showBulkEdit={showBulkEdit}
              />
            ))
        }
        <AddSplitButtonRow
          addSplitAsset={addSplitAsset(asset?.internalId)}
        />
      </>
    );
  };

  const renderRow = (asset: AmortizationSource, index: number) => {
    const children = getChildren(asset.internalId, subledger?.amortizationSources);
    const parent = (
      <CreateSubledgerPrepaidExpenseReadOnlyRow
        key={asset.internalId}
        selectedRow={selectedRow}
        isSelectedForBulkEdit={checkIsElementSelected(asset.internalId)}
        amortizationSource={asset}
        hoverRow={hoverRow}
        onClick={() => onRowClick(asset)}
        onCheckboxChange={onCheckboxChange}
        hasChildren={!!children?.length}
        lastChild={false}
        index={index}
        account={account}
        startDate={startDate}
        endDate={endDate}
        amortizationSources={subledger?.amortizationSources}
        isCollapsedView={isCollapsedView}
        showBulkEdit={showBulkEdit}
      />
    );
    if (!children?.length) {
      return parent;
    }
    return (
      <>
        {parent}
        {
            children
              ?.map((child: AmortizationSource, childIndex: number) => (
                <CreateSubledgerPrepaidExpenseReadOnlyRow
                  key={child.internalId}
                  selectedRow={selectedRow}
                  isSelectedForBulkEdit={checkIsElementSelected(asset.internalId)}
                  amortizationSource={child}
                  hoverRow={hoverRow}
                  onCheckboxChange={onCheckboxChange}
                  onClick={() => onRowClick(asset)}
                  hasChildren={false}
                  lastChild={children?.length - 1 === childIndex}
                  index={index}
                  account={account}
                  startDate={startDate}
                  endDate={endDate}
                  amortizationSources={subledger?.amortizationSources}
                  isCollapsedView={isCollapsedView}
                  showBulkEdit={showBulkEdit}
                />
              ))
        }
      </>
    );
  };

  const renderAsset = () => subledger?.amortizationSources
    ?.filter((asset: AmortizationSource) => !asset.parentId)
    .splice(page * pageSize, pageSize)
    .map((asset: AmortizationSource, index: number) => {
      if (asset.internalId === selectedRow) {
        return renderSelectedRow(asset, page * pageSize + index);
      }
      return renderRow(asset, page * pageSize + index);
    });

  return (
    <>
      <CreateSubledgerPrepaidExpenseTableHeader
        subledger={subledger}
        isCollapsedView={isCollapsedView}
        isCollapsedViewHovered={isCollapsedViewHovered}
        onSelectAll={onSelectAll}
        isAllSelected={isAllSelected}
        showBulkEdit={showBulkEdit}
      />
      <TableBody>
        {renderAsset()}
      </TableBody>
    </>
  );
};

export default React.memo(CreateSubledgerPrepaidExpenses);
