/* eslint-disable react/jsx-props-no-spreading,max-len */
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const RevSyncIcon = (props: any) => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 9.2746L17 5.85606L13.7971 5.1452L13.5728 6.15619L15.2703 6.53295L7.00029 12.7339L7.62154 13.5624L15.9644 7.30685L15.9644 9.2746L17 9.2746ZM15.686 10.6564L15.686 18.855H16.9805V10.6564H15.686ZM12.7908 18.8549V12.2101H14.0853V18.8549H12.7908ZM9.89539 13.8929V18.855H11.1899L11.1899 13.8929H9.89539ZM7 15.3169L7 18.855H8.29448L8.29448 15.3169H7Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default RevSyncIcon;
