import clsx from 'clsx';
import { format } from 'date-fns';
import { TableCell } from '@material-ui/core';
import React from 'react';
import { FULL_DAY_FORMAT } from '../../../util/constants';
import { useTableStyles } from '../Subledger.styled';
import { AmortizationSource } from '../../../interfaces/types';

interface Props {
  amortizationSource: AmortizationSource;
  hasChildren?: boolean;
  lastChild?: boolean;
  highlighted?: boolean;
  showCursor?: boolean;
  isCellSticky?: string;
}

const AmortizationSourceCreationCell = ({
  amortizationSource,
  hasChildren,
  lastChild,
  highlighted,
  showCursor,
  isCellSticky,
}: Props) => {
  const tableClasses = useTableStyles();
  return (
    <TableCell
      className={clsx(tableClasses.assetCell, {
        [tableClasses.child]: amortizationSource.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.bold]: highlighted,
        [tableClasses.lastChild]: lastChild,
        [tableClasses.cursorPointer]: showCursor,
      })}
      is-cell-sticky={isCellSticky}
    >
      {amortizationSource.parentId ? '' : format(amortizationSource?.sourceCreationDate!, FULL_DAY_FORMAT)}
    </TableCell>
  );
};

export default React.memo(AmortizationSourceCreationCell);
