import React from 'react';
import { Checkbox } from '@material-ui/core';

interface Props {
  checked: boolean;
  disabled: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLButtonElement>;
}

export const FactaCheckbox = ({
  checked,
  disabled,
  onChange,
  onKeyDown,
}: Props) => (
  <Checkbox
    checked={checked}
    disabled={disabled}
    onChange={onChange}
    onClick={(event) => event.stopPropagation()}
    onKeyDown={onKeyDown}
  />
);
