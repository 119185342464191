/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { request } from '../../util/request';
import {
  WaterfallLegacyReport,
  RollforwardFixedAssetsReport,
  WaterfallFixedAssetsReport,
} from '../../interfaces/reports';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../interfaces/types';
import { getFileNameFromContentDisposition } from '../../util/helpers';

const PREFIX = 'reports';
const GET_WATERFALL_LEGACY_REPORT = `${PREFIX}/getWaterfallLegacyReport`;
const GET_WATERFALL_FAS_REPORT = `${PREFIX}/getWaterfallFixedAssetsReport`;
const GET_ROLLFORWARD_FAS_REPORT = `${PREFIX}/getRollforwardFixedAssetsReport`;

interface State {
  isFetching: boolean;
  waterfallLegacyReport: WaterfallLegacyReport;
  rollforwardLegacyReport: unknown; // TODO use when implemented
  waterfallFixedAssetsReport: WaterfallFixedAssetsReport;
  rollforwardFixedAssetsReport: RollforwardFixedAssetsReport;
  error?: any;
}

const initialState: State = {
  waterfallLegacyReport: {} as WaterfallLegacyReport,
  rollforwardLegacyReport: null,
  waterfallFixedAssetsReport: {} as WaterfallFixedAssetsReport,
  rollforwardFixedAssetsReport: {} as RollforwardFixedAssetsReport,
  isFetching: false,
};

export const getWaterfallLegacyReport = createAsyncThunk<{
  result: WaterfallLegacyReport | string;
  filename: string;
}, {
  subledgerId: string;
  startDate: string;
  endDate?: string;
  accept?: string;
}>(
  GET_WATERFALL_LEGACY_REPORT,
  async ({ accept, subledgerId, ...params }, { rejectWithValue }) => {
    try {
      const response = await request()
        .get<WaterfallLegacyReport | string>(`/v2/subledgers/${subledgerId}/reports/waterfall`, {
        params: { ...params },
        headers: { Accept: accept ? `${accept}` : 'application/json' },
      });

      const filename = getFileNameFromContentDisposition(response.headers.contentDisposition);

      return {
        result: response.data,
        filename,
      };
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const getWaterfallFixedAssetsReport = createAsyncThunk<{
  result: WaterfallFixedAssetsReport | string;
  filename: string;
}, {
  subledgerId: string;
  startDate: string;
  endDate?: string;
  accept?: string;
}>(
  GET_WATERFALL_FAS_REPORT,
  async ({ accept, subledgerId, ...params }, { rejectWithValue }) => {
    try {
      const response = await request()
        .get<WaterfallFixedAssetsReport | string>(`/v2/subledgers/${subledgerId}/reports/waterfall/fixed_assets`, {
        params: { ...params },
        headers: { Accept: accept ? `${accept}` : 'application/json' },
      });

      const filename = getFileNameFromContentDisposition(response.headers.contentDisposition);

      return {
        result: response.data,
        filename,
      };
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const getRollforwardFixedAssetsReport = createAsyncThunk<{
  result: RollforwardFixedAssetsReport | string;
  filename: string;
}, {
  accountsIds: Array<string>;
  startDate: string;
  endDate?: string;
  accept?: string;
}>(
  GET_ROLLFORWARD_FAS_REPORT,
  async ({ accept, ...params }, { rejectWithValue }) => {
    try {
      const response = await request()
        .post<RollforwardFixedAssetsReport | string>('/v2/subledgers/reports/rollforward', params, {
        headers: { Accept: accept ? `${accept}` : 'application/json' },
      });

      const filename = getFileNameFromContentDisposition(response.headers.contentDisposition);

      return {
        result: response.data,
        filename,
      };
    } catch (error: any) {
      const handledError = error?.response?.data?.raw || 'Unknown error';
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

const enrichEachRowWithUUID = (report: any) => ({
  ...report,
  rows: report.rows.map((row: any) => ({ id: uuidv4(), ...row })),
});

export const reportsSlice = createSlice({
  initialState,
  name: PREFIX,
  reducers: {
    clearReports: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getWaterfallLegacyReport.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getWaterfallLegacyReport.fulfilled, (state, { payload, meta }) => {
      state.isFetching = false;
      if (meta.arg.accept) return;
      state.waterfallLegacyReport = payload.result as WaterfallLegacyReport;
    });
    builder.addCase(getWaterfallLegacyReport.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    });

    builder.addCase(getWaterfallFixedAssetsReport.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getWaterfallFixedAssetsReport.fulfilled, (state, { payload, meta }) => {
      state.isFetching = false;
      if (meta.arg.accept) return;
      state.waterfallFixedAssetsReport = enrichEachRowWithUUID(payload.result);
    });
    builder.addCase(getWaterfallFixedAssetsReport.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    });

    builder.addCase(getRollforwardFixedAssetsReport.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getRollforwardFixedAssetsReport.fulfilled, (state, { payload, meta }) => {
      state.isFetching = false;
      if (meta.arg.accept) return;
      state.rollforwardFixedAssetsReport = enrichEachRowWithUUID(payload.result);
    });
    builder.addCase(getRollforwardFixedAssetsReport.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    });
  },
});

export const {
  clearReports,
} = reportsSlice.actions;

export const reportsReducer = reportsSlice.reducer;
