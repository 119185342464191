import React from 'react';
import { Popper } from '@material-ui/core';
import { POPPER_ELEMENTS_Z_INDEX } from '../../../../util/constants';

const styles = {
  popper: {
    maxWidth: 'fit-content',
    zIndex: POPPER_ELEMENTS_Z_INDEX,
  },
};

const AutocompletePopper = (props: any) => (
  <Popper
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
    style={styles.popper}
    placement="bottom-start"
    defaults={{ eventsEnabled: false }}
  />
);

export default AutocompletePopper;
