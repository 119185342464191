import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import {
  Box,
  TableCell,
  Typography,
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
  getAccountById,
  getAccountNameFromList,
} from './common';
import { useTableStyles } from './Subledger.styled';
import { AmortizationSource } from '../../interfaces/types';
import FactaAutocomplete from '../FactaAutocomplete';
import { accountInfoSelector } from '../../store/selectors/account';

interface Props {
  hasChildren?: boolean;
  lastChild: boolean;
  asset: AmortizationSource;
  collapses?: Array<string>;
  isRowSelected: boolean;
  onSelectChange: (propertyName: string, internalId: string) => (value: string) => void;
  isBoldEnable?: boolean;
  onClick?: () => void;
}

const ExpenseAccountEditableCell = ({
  hasChildren,
  lastChild,
  asset,
  collapses,
  isRowSelected,
  onSelectChange,
  isBoldEnable = false,
  onClick = () => {},
}: Props) => {
  const { accountIncomes } = useSelector(accountInfoSelector)!;
  const tableClasses = useTableStyles();

  return (
    <TableCell
      className={clsx(tableClasses.cursorPointer, tableClasses.expenseAccountCell, {
        [tableClasses.child]: asset.parentId,
        [tableClasses.parent]: hasChildren,
        [tableClasses.lastChild]: lastChild,
        [tableClasses.bold]: isBoldEnable && !asset.status,
      })}
      onClick={onClick}
    >
      {hasChildren
        && (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Typography className={tableClasses.fontSize13}>Multiple</Typography>
            {
              collapses?.includes(asset.internalId) && (
                <ArrowRightIcon />
              )
            }
            {
              !collapses?.includes(asset.internalId) && (
                <ArrowDropDownIcon />
              )
            }
          </Box>
        )}
      {!hasChildren && isRowSelected && (
        <FactaAutocomplete
          className={clsx(tableClasses.expenseAccountSelectBox)}
          defaultValue={asset?.amortizationSchedule?.destinationId!}
          onChange={onSelectChange('destinationId', asset.internalId)}
          options={accountIncomes}
          optionName="displayName"
          selectedItem={
            getAccountById(asset?.amortizationSchedule.destinationId!, accountIncomes) || null
          }
        />
      )}
      {!hasChildren
        && !isRowSelected
        && getAccountNameFromList(asset?.amortizationSchedule?.destinationId!, accountIncomes)}
    </TableCell>
  );
};

export default React.memo(ExpenseAccountEditableCell);
