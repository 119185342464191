import React from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  Backdrop,
  Box,
  Modal,
  Slide,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import COLORS from '../../theme/colors';

const useStyles = makeStyles((theme) => ({
  flyover: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    right: 0,
    backgroundColor: COLORS.background,
    boxShadow: COLORS.flyoverShadow,
    padding: 20,
    outline: 0,
  },
  fullscreen: {
    right: 30,
    left: 30,
    maxHeight: 'calc(100vh - 60px)',
  },
  cover: {
    right: 0,
    left: 0,
    height: '100%',
  },
  drawer: {
    top: 0,
    bottom: 0,
    right: 0,
    minWidth: '320px',
    maxWidth: '415px',
  },
  title: {
    fontSize: 16,
    lineHeight: '24px',
    color: COLORS.lightGray,
    fontWeight: 'normal',
    margin: '0 0 20px 0',
    textTransform: 'uppercase',
  },
  closeIcon: {
    cursor: 'pointer',
    marginRight: 0,
    marginTop: 0,
    marginLeft: 'auto',
    color: COLORS.lightGray2,
    fontSize: 24,
    transition: theme.transitions.create('color', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      color: COLORS.lightGray,
    },
  },
}));

interface Props {
  children: React.ReactNode;
  onClose?: any;
  open: boolean;
  title: string;
  titleAdditionalMarkup?: React.ReactNode;
  variant: 'fullscreen' | 'drawer' | 'cover';
}

const Flyover = ({
  children,
  onClose,
  open,
  title,
  titleAdditionalMarkup,
  variant,
}: Props) => {
  const classes = useStyles({ variant });

  return (
    <Modal
      open={open}
      onClose={onClose}
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      style={{ display: 'flex', alignItems: 'center' }}
      disableEscapeKeyDown
      disableEnforceFocus
    >
      <Slide
        in={open}
        direction="left"
      >
        <div className={clsx(classes.flyover, classes[variant])}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            flexDirection="row"
            borderBottom={`1px solid ${COLORS.lightGray2}`}
            marginBottom="20px"
            {...(variant === 'drawer' && { margin: 1 })}
          >
            <h2 className={classes.title}>
              {title}
            </h2>
            {titleAdditionalMarkup && titleAdditionalMarkup}
            <CloseIcon
              onClick={onClose}
              className={classes.closeIcon}
            />
          </Box>
          {children}
        </div>
      </Slide>
    </Modal>
  );
};

export default Flyover;
