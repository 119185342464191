import React, { useRef } from 'react';
import {
  createStyles,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import {
  AutocompleteBox,
  AutocompleteListBox,
  AutoCompleteTextField,
} from '../Inputs/FactaAutocomplete/index.styled';
import COLORS from '../../theme/colors';
import AutocompletePopper from './PopperComponent';

interface Props {
  error?: boolean;
  className: string;
  defaultValue: string;
  onChange: (value: string) => void;
  options: Array<any>;
  optionName: string;
  valueName?: string;
  disabled?: boolean;
  isVirtualizedList?: boolean;
  selectedItem: any;
}

const useStyles = makeStyles(() => createStyles({
  error: {
    border: `1px solid ${COLORS.violet}`,
  },
}));

const FactaAutocomplete = ({
  className,
  onChange,
  error,
  selectedItem,
  options,
  optionName,
  isVirtualizedList,
  valueName,
}: Props) => {
  const [inputValue, setInputValue] = React.useState('');
  const classes = useStyles();
  const highlighted = useRef<any>();

  const onSelectChange = (_: any, item: any) => {
    onChange(valueName ? item?.[valueName] : item?.id);
  };

  const onInputChange = (_: any, value: string) => {
    setInputValue(value);
    highlighted.current = '';
  };

  return (
    <AutocompleteBox
      onKeyDown={(ev) => {
        if (ev.key === 'Tab' && highlighted.current) {
          onSelectChange(null, highlighted.current);
        }
      }}
      onHighlightChange={(_, option, reason) => {
        if (reason === 'keyboard') highlighted.current = option;
      }}
      autoHighlight={inputValue.length > 0}
      className={clsx(className, { [classes.error]: Boolean(error) })}
      options={options || []}
      {...(isVirtualizedList
        ? { ListboxComponent: AutocompleteListBox as React.ComponentType<React.HTMLAttributes<HTMLElement>> }
        : { PopperComponent: AutocompletePopper })}
      getOptionLabel={(option: any) => option?.[optionName]}
      getOptionSelected={(option: any, value: any) => option?.[optionName] === value?.[optionName]}
      defaultValue={selectedItem}
      onChange={onSelectChange}
      value={selectedItem || null}
      openOnFocus
      onInputChange={onInputChange}
      renderInput={(params) => (
        <AutoCompleteTextField
          {...params}
          hiddenLabel
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
          }}
        />
      )}
    />
  );
};

export default React.memo(FactaAutocomplete);
