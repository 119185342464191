import { InputBase } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import COLORS from '../../theme/colors';

export const StyledInput = styled(InputBase)({
  width: '100%',
  background: COLORS.white,
  border: `1px solid ${COLORS.border}`,
  borderRadius: 4,
  color: COLORS.black,
  height: 32,
  padding: '6px 12px',
  fontSize: 12,
  lineHeight: '14px',
  '&.Mui-error': {
    color: COLORS.error,
    border: `1px solid ${COLORS.error}`,
  },
  '&.Mui-focused': {
    border: `1px solid ${COLORS.violet}`,
  },
  '& input': {
    padding: 0,
  },
  '&.MuiInputBase-multiline': {
    height: 'auto',
  },
});

export const StyledNumberInput = styled(StyledInput)({
  '& input': {
    textAlign: 'right',
  },
});
