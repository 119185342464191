import React from 'react';
import { format } from 'date-fns';
import roundTo from 'round-to';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
} from '@material-ui/core';
import currencyFormatter from '../../../../util/currencyFormatter';
import {
  AmortizationScheduleDetails,
  AmortizationSource,
} from '../../../../interfaces/types';
import { FULL_DATE_FORMAT } from '../../../../util/constants';
import COLORS from '../../../../theme/colors';

const useStyles = makeStyles({
  table: {
    marginBottom: 0,
  },
  zebraStripedColumns: {
    '& td:nth-child(even)': {
      background: COLORS.lightGray3,
    },
  },
});

interface Props {
  selectedSubledger: AmortizationSource;
}

interface AmortizationScheduleExtended extends AmortizationScheduleDetails {
  period: number;
  beginningCumulativeAmortization: number;
  endingCumulativeAmortization: number;
  endingNbv: number;
}

const AmortizationTable = ({ selectedSubledger }: Props) => {
  const classes = useStyles();
  const {
    startingBalance,
    amortizationSchedule: {
      amortizationScheduleDetails,
    },
  } = selectedSubledger;

  if (!amortizationScheduleDetails) return null;

  let currentValue = 0;
  let endingNbv = startingBalance;

  const extendedData: Array<AmortizationScheduleExtended> = [...amortizationScheduleDetails]
    .sort((a, b) => (a.scheduleDate.getTime() - b.scheduleDate.getTime()))
    .map((item, index) => {
      const previousValue = currentValue;
      const period = index + 1;
      currentValue = roundTo(previousValue + Number(item.amount), 2);
      endingNbv = roundTo(endingNbv - Number(item.amount), 2);

      return {
        ...item,
        period,
        beginningCumulativeAmortization: previousValue,
        endingCumulativeAmortization: currentValue,
        endingNbv,
      };
    });

  const renderTableBody = () => extendedData.map(((tableItem: AmortizationScheduleExtended) => (
    <TableRow
      key={tableItem.id}
      className={classes.zebraStripedColumns}
    >
      <TableCell>{tableItem.period}</TableCell>
      <TableCell align="right">{format(tableItem.scheduleDate, FULL_DATE_FORMAT)}</TableCell>
      <TableCell align="right">{currencyFormatter.format(tableItem.beginningCumulativeAmortization)}</TableCell>
      <TableCell align="right">{currencyFormatter.format(Number(tableItem.amount))}</TableCell>
      <TableCell align="right">{currencyFormatter.format(tableItem.endingCumulativeAmortization)}</TableCell>
      <TableCell align="right">{currencyFormatter.format(tableItem.endingNbv)}</TableCell>
      <TableCell>{tableItem?.journalEntry?.jeNumber || ''}</TableCell>
    </TableRow>
  )));

  return (
    <Table
      className={classes.table}
      stickyHeader
    >
      <TableHead>
        <TableRow>
          <TableCell>Period</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Beginning Cumulative Amortization</TableCell>
          <TableCell>Amortized</TableCell>
          <TableCell>Ending Cumulative Amortization</TableCell>
          <TableCell>Ending Prepaid Balance</TableCell>
          <TableCell>Prepaid Amortization JE</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {renderTableBody()}
      </TableBody>
    </Table>
  );
};

export default React.memo(AmortizationTable);
