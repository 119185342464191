import React, {
  useEffect,
  useState,
} from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import find from 'lodash.find';
import FactaAutocomplete from '../../Inputs/FactaAutocomplete';
import Flyover from '../../Flyover';
import { FormInput } from '../../Inputs/FormInput/index';
import {
  patchAssetType,
  postAssetType,
} from '../../../store/slices/assetTypes';
import { AppThunkDispatch } from '../../../store/store';
import { InputLabel } from '../../InputLabel';
import {
  assetTypesSelector,
  isSavingAssetTypes,
} from '../../../store/selectors/assetTypes';
import { TagCategories } from '../../../interfaces/tags';
import {
  AssetType,
  AssetTypeForm,
  UsefulLifeUnits,
} from '../../../interfaces/fixedAssetTypes';
import { accountsIncomeListSelector } from '../../../store/selectors/v2Accounts';
import {
  tagsClassListSelector,
  tagsLocationListSelector,
} from '../../../store/selectors/tags';
import { ErrorAnnotate } from '../../Inputs/ErrorAnnotate';
import { DEFAULT_ASSET_TYPE } from '../../../util/pages/FixedAssets/assetTypes';
import { NumberInput } from '../../Inputs/NumberInput';

const useStyles = makeStyles(() => createStyles({
  inputBox: {
    margin: '12px 8px',
  },
  buttonBox: {
    margin: '12px 2px',
  },
}));

interface Props {
  assetType: AssetTypeForm;
  isOpen: boolean;
  onSaveAndNew?: (id?: string) => void;
  onClose: (result?: AssetType) => void;
  customSaveLabel?: string;
}

const AddEditAssetType = ({
  assetType,
  isOpen,
  onSaveAndNew,
  onClose,
  customSaveLabel,
}: Props) => {
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const styles = useStyles();
  const [isEditMode, setIsEditMode] = useState(!!assetType.id);
  const incomeAccounts = useSelector(accountsIncomeListSelector);
  const assetTypes = useSelector(assetTypesSelector);
  const accountClasses = useSelector(tagsClassListSelector);
  const accountLocations = useSelector(tagsLocationListSelector);
  const isSaving = useSelector(isSavingAssetTypes)!;

  const assetTypesNames = assetTypes.map((at) => at.name);

  const yupSchema = () => yupResolver(yup.object().shape({
    name: yup.string().required('This field is required')
      .test(
        'isUnique',
        'Asset Type Name must be unique',
        (val) => !(!isEditMode && assetTypesNames.includes(val || '')),
      ),
    description: yup.string(),
    depreciationAccount: yup.object({
      id: yup.string().required(),
      name: yup.string().required(),
    })
      .nullable()
      .required('This field is required'),
    gainOrLossAccount: yup.object({
      id: yup.string().required(),
      name: yup.string().required(),
    })
      .nullable()
      .required('This field is required'),
    usefulLifeValue: yup.number().required(),
    usefulLifeUnits: yup.object().required(),
  }));

  const {
    handleSubmit,
    control,
    errors,
    reset,
    setValue,
    getValues,
  } = useForm({
    mode: 'onChange',
    resolver: yupSchema(),
    shouldUnregister: true,
  });

  const parseDataToBeSaved = (data: any) => ({
    ...(!isEditMode && { ...DEFAULT_ASSET_TYPE }),
    ...assetType,
    ...data,
    id: isEditMode ? assetType.id : undefined,
    depreciationAccountId: data.depreciationAccount.id,
    gainOrLossAccountId: data.gainOrLossAccount.id,
    usefulLifeUnits: data.usefulLifeUnits.type,
    tags: [
      ...(data.class?.id ? [{
        category: TagCategories.CLASS,
        id: data.class?.id,
      }] : []),
      ...(data.location?.id ? [{
        category: TagCategories.LOCATION,
        id: data.location?.id,
      }] : []),
    ],
  });

  const handleSave = (data: any, addAnotherAsset?: boolean) => {
    const parsedData = parseDataToBeSaved(data);
    const submitMethod = isEditMode ? patchAssetType : postAssetType;

    reduxDispatch(submitMethod({ assetType: parsedData }))
      .unwrap()
      .then((result) => {
        if (addAnotherAsset && onSaveAndNew) {
          onSaveAndNew(result.id);
          setValue('name', `${getValues().name} Copy`);
          setIsEditMode(false);
        } else {
          onClose(result);
        }
      });
  };

  useEffect(() => {
    const updatedAssetType: AssetTypeForm = ({
      ...assetType,
      class: find(
        accountClasses,
        { id: assetType.tags.find((tag) => tag.category === TagCategories.CLASS)?.id },
      ),
      location: find(
        accountLocations,
        { id: assetType.tags.find((tag) => tag.category === TagCategories.LOCATION)?.id },
      ),
    });

    reset(updatedAssetType);
  }, [incomeAccounts, accountClasses, accountLocations, assetType, reset]);

  return assetType && incomeAccounts && (
    <Flyover
      open={isOpen}
      title={`${isEditMode ? 'Edit' : 'Add'} asset type`}
      variant="drawer"
      onClose={() => onClose()}
    >
      <Box overflow="auto">
        <form style={{ display: 'flex', flexDirection: 'column' }}>
          <Box className={styles.inputBox}>
            <InputLabel
              label="Asset Type Name"
              required
            />
            <FormInput
              control={control}
              name="name"
              defaultValue={assetType.name}
              error={!!errors?.name}
              disabled={isSaving}
              placeholder="Name"
            />
            <ErrorAnnotate error={errors?.name} />
          </Box>
          <Box className={styles.inputBox}>
            <InputLabel label="Asset Type Description" />
            <FormInput
              control={control}
              name="description"
              defaultValue={assetType.description}
              error={!!errors?.description}
              disabled={isSaving}
              multiline
              placeholder="Description"
            />
            <ErrorAnnotate error={errors?.description} />
          </Box>
          <Box className={styles.inputBox}>
            <InputLabel
              label="Depreciation Expense Account"
              required
            />
            <FactaAutocomplete
              control={control}
              name="depreciationAccount"
              options={isEditMode ? incomeAccounts : incomeAccounts.filter((acc) => acc.active && acc.visible)}
              disableInactiveOptions
              disableHiddenOptions
              optionName="displayName"
              defaultValue={null}
              error={!!errors?.depreciationAccount}
              disabled={isSaving}
              placeholder="Select Account"
            />
            <ErrorAnnotate error={errors?.depreciationAccount} />
          </Box>
          <Box className={styles.inputBox}>
            <InputLabel
              label="Gain/Loss Account"
              required
            />
            <FactaAutocomplete
              control={control}
              name="gainOrLossAccount"
              options={isEditMode ? incomeAccounts : incomeAccounts.filter((acc) => acc.active && acc.visible)}
              disableInactiveOptions
              disableHiddenOptions
              optionName="displayName"
              defaultValue={null}
              error={!!errors?.gainOrLossAccount}
              disabled={isSaving}
              placeholder="Select Account"
            />
            <ErrorAnnotate error={errors?.gainOrLossAccount} />
          </Box>
          <Box className={styles.inputBox}>
            <InputLabel label="Class" />
            <FactaAutocomplete
              control={control}
              name="class"
              options={isEditMode ? accountClasses : accountClasses.filter((tag) => tag.active && tag.visible)}
              disableInactiveOptions
              disableHiddenOptions
              optionName="name"
              defaultValue={null}
              error={!!errors?.class}
              disabled={isSaving}
              placeholder="Select Class"
            />
          </Box>
          <Box className={styles.inputBox}>
            <InputLabel label="Location" />
            <FactaAutocomplete
              control={control}
              name="location"
              options={isEditMode ? accountLocations : accountLocations.filter((tag) => tag.active && tag.visible)}
              disableInactiveOptions
              disableHiddenOptions
              optionName="name"
              defaultValue={null}
              error={!!errors?.location}
              placeholder="Select Location"
            />
          </Box>
          <Box className={styles.inputBox}>
            <InputLabel
              label="Useful Life"
              required
            />
            <Box display="flex">
              <Box
                width="84px"
                marginRight="16px"
              >
                <NumberInput
                  control={control}
                  name="usefulLifeValue"
                  defaultValue={assetType.usefulLifeValue}
                  error={!!errors?.usefulLifeValue}
                  disabled={isSaving}
                  prefix=""
                  decimalScale={0}
                />
              </Box>
              <Box width="130px">
                <FactaAutocomplete
                  control={control}
                  name="usefulLifeUnits"
                  options={[{ type: UsefulLifeUnits.MONTHS }, { type: UsefulLifeUnits.YEARS }]}
                  optionName="type"
                  defaultValue={null}
                  error={!!errors?.usefulLifeUnits}
                  disabled={isSaving}
                />
              </Box>
            </Box>
          </Box>
          <Box className={styles.buttonBox}>
            {onSaveAndNew && (
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit((data) => handleSave(data, true))}
                disabled={isSaving}
              >
                Save & New
                {isSaving && (
                  <CircularProgress
                    size={20}
                    style={{ position: 'absolute' }}
                  />
                )}
              </Button>
            )}
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit((data) => handleSave(data, false))}
              disabled={isSaving}
            >
              {customSaveLabel || 'Save & Close'}
              {isSaving && (
                <CircularProgress
                  size={20}
                  style={{ position: 'absolute' }}
                />
              )}
            </Button>
          </Box>
        </form>
      </Box>
    </Flyover>
  );
};

export default React.memo(AddEditAssetType);
