import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  createStyles,
  Box,
} from '@material-ui/core';
import clsx from 'clsx';
import COLORS from '../../../theme/colors';
import { LineClamp } from '../LineClamp';

interface Props {
  withError?: boolean;
  placeholderText: string;
  value?: string;
}

const useStyles = makeStyles(() => createStyles({
  text: {
    color: COLORS.black,
    fontSize: '12px',
    lineHeight: '16px',
    padding: 0,
    margin: 0,
  },
  withError: {
    color: COLORS.error,
  },
  placeholder: {
    color: COLORS.checkboxUnchecked,
  },
}));

export const IdleTableCell = ({
  withError,
  placeholderText,
  value,
}: Props) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.text, {
        [classes.withError]: withError,
        [classes.placeholder]: !value && !withError,
      })}
    >
      <LineClamp
        text={value || placeholderText}
      />
    </Box>
  );
};
