import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import find from 'lodash.find';
import {
  format,
  parseISO,
} from 'date-fns';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import TableComponent from '../../../Table';
import {
  FilterMatchingType,
  ITableItemType,
  ITableType,
  SortOrder,
} from '../../../../interfaces/types';
import { useFilters } from '../../../../hooks/useFilters';
import {
  getFilteredData,
  getFilterValueByFilterName,
} from '../../../../util/filtering';
import { TextSearchFilter } from '../../../Table/Filters/TextSearch';
import { AmountFilter } from '../../../Table/Filters/Amount';
import { MultipickerFilter } from '../../../Table/Filters/Multipicker';
import { DatepickerFilter } from '../../../Table/Filters/Datepicker/index';
import {
  DEFAULT_PAGE_SIZE,
  FULL_DATE_FORMAT,
} from '../../../../util/constants';
import currencyFormatter from '../../../../util/currencyFormatter';
import { StyledTableCell } from '../../../Table/StyledTableCell';
import {
  FixedAsset,
  FixedAssetForm,
} from '../../../../interfaces/fixedAssets';
import {
  fixedAssetsListSelector,
  isWritingOffFixedAsset,
} from '../../../../store/selectors/v2Subledgers';
import { vendorsListSelector } from '../../../../store/selectors/v2contacts';
import { assetTypesSelector } from '../../../../store/selectors/assetTypes';
import { V2Subledger } from '../../../../interfaces/subledgers';
import { StyledLink } from '../../../StyledLink';
import {
  generateQBOLink,
  schedulingMethodOptions,
} from '../../../../util/helpers';
import { Contact } from '../../../../interfaces/contacts';
import { AssetType } from '../../../../interfaces/fixedAssetTypes';
import { LineClamp } from '../../../Table/LineClamp';
import { precisionRound } from '../../../../util/math';
import {
  scheduleAsOfdate,
  scheduleUnpostedSumReduce,
} from '../../../../util/pages/FixedAssets/calculateSubledgerDetails';
import { DeleteIcon } from '../../../Icons';
import { AppThunkDispatch } from '../../../../store/store';
import { deleteWriteOffFixedAsset } from '../../../../store/slices/v2Subledgers';
import DialogBox from '../../../DialogBox';
import { Loader } from '../../../Loader';
import { getAccountBalance } from '../../../../store/slices/v2Accounts';
import { parseToIncludeCurrentDay } from '../../../../util/timezone';

interface Props {
  subledgerDetails: V2Subledger;
  page: number;
  setPage: (page: number) => void;
  selectedDate: string;
  isCurrentPeriod: boolean;
}

interface WriteOffFixedAssetForm extends FixedAssetForm {
  disposalDate: string;
  writtenOffAmount: number;
  accumulatedDepreciationAmount: number;
}

interface WriteOffScreenFixedAsset extends FixedAsset {
  accumulatedDepreciationAmount: number;
}

export const WriteOffsTable = ({
  subledgerDetails,
  page,
  setPage,
  selectedDate,
  isCurrentPeriod,
}: Props) => {
  const [selectedAssetId, setSelectedAssetId] = useState('');
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [deleteWriteOffModal, setDeleteWriteOffModal] = useState(false);
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const isDeletingWritingOff = useSelector(isWritingOffFixedAsset)!;

  const vendors = useSelector(vendorsListSelector);
  const fixedAssetTypes = useSelector(assetTypesSelector);
  const allFixedAssets = useSelector(fixedAssetsListSelector);

  const {
    id: subledgerId,
    startDate: factaStartDate,
  } = subledgerDetails;

  const initialFilters = useMemo(() => ([
    {
      name: 'glDate',
      sourcePath: ['transaction', 'postingDate'],
      matching: FilterMatchingType.DATE_RANGE,
    },
    {
      name: 'externalId',
      sourcePath: ['transaction', 'externalId'],
      matching: FilterMatchingType.STRING_INCLUDES,
    },
    {
      name: 'number',
      matching: FilterMatchingType.STRING_INCLUDES,
    },
    {
      name: 'description',
      matching: FilterMatchingType.STRING_INCLUDES,
    },
    {
      name: 'vendorContactId',
      matching: FilterMatchingType.ARRAY_INCLUDES_PROPERTY,
      propertyPath: ['id'],
    },
    {
      name: 'fixedAssetTypeId',
      matching: FilterMatchingType.ARRAY_INCLUDES_PROPERTY,
      propertyPath: ['id'],
    },
    {
      name: 'disposalDate',
      matching: FilterMatchingType.DATE_RANGE,
      sourcePath: ['disposal', 'disposalDate'],
    },
    {
      name: 'originalValue',
      matching: FilterMatchingType.NUMBER_COMPARE,
    },
    {
      name: 'accumulatedDepreciationAmount',
      matching: FilterMatchingType.NUMBER_COMPARE,
    },
    {
      name: 'writtenOffAmount',
      matching: FilterMatchingType.NUMBER_COMPARE,
      sourcePath: ['disposal', 'writtenOffAmount'],
    },
  ]), []);

  const {
    filters,
    setFilter,
    clearAllFilters,
  } = useFilters(initialFilters);

  const adjustedFixedAssets = useMemo(() => allFixedAssets
    .filter((asset) => asset.finalized && asset.disposal)
    .map((asset) => ({
      ...asset,
      accumulatedDepreciationAmount: precisionRound((scheduleAsOfdate(
        selectedDate,
        asset?.inServiceDate || selectedDate,
        asset?.schedule || [],
      )
        .reduce(scheduleUnpostedSumReduce, 0) || 0) + asset.priorDepreciation),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    })), [allFixedAssets]);

  const {
    filteredData,
    numberOfFiltersApplied,
  } = useMemo(
    () => getFilteredData(adjustedFixedAssets, filters),
    [filters, adjustedFixedAssets],
  );

  const filteredFixedAssets = filteredData as unknown as WriteOffScreenFixedAsset[];

  const fixedAssets: Array<Partial<WriteOffFixedAssetForm>> = filteredFixedAssets.map((asset) => ({
    id: asset.id,
    glDate: asset.transaction?.postingDate || factaStartDate,
    transaction: asset.transaction,
    description: asset.description || '',
    number: asset.number || '',
    vendorContact: find(vendors, { id: asset.vendorContactId }) as Contact,
    fixedAssetType: find(fixedAssetTypes, { id: asset.fixedAssetTypeId }) as AssetType,
    originalValue: asset.originalValue,
    schedulingMethod: find(schedulingMethodOptions, { value: asset.schedulingMethod }),
    finalized: asset.finalized,
    accumulatedDepreciationAmount: asset.accumulatedDepreciationAmount,
    disposalDate: asset.disposal?.disposalDate,
    writtenOffAmount: asset.disposal?.writtenOffAmount,
  }));

  useEffect(() => {
    setPage(1);

    return () => {
      setDeleteWriteOffModal(false);
      setSelectedAssetId('');
    };
  }, [filters, allFixedAssets, setPage]);

  const dispatchDeleteWriteOff = (assetId?: string) => {
    reduxDispatch(deleteWriteOffFixedAsset({
      subledgerId: subledgerDetails.id,
      assetId: assetId || selectedAssetId,
      date: selectedDate,
    }))
      .unwrap()
      .then(() => {
        reduxDispatch(getAccountBalance({
          accountId: subledgerDetails.accountId,
          date: parseToIncludeCurrentDay(selectedDate),
        }));
      });
  };

  const handleDeleteWriteOff = (asset: WriteOffFixedAssetForm, event: React.MouseEvent) => {
    event.stopPropagation();
    setSelectedAssetId(asset.id);

    if (isCurrentPeriod) {
      dispatchDeleteWriteOff(asset.id);
    } else {
      setDeleteWriteOffModal(true);
    }
  };

  const colProps = [
    {
      colName: 'GL Date',
      customHeader: (
        <DatepickerFilter
          _key={subledgerId}
          label="GL Date"
          value={getFilterValueByFilterName(filters, 'glDate')}
          onChange={(filter) => setFilter('glDate', filter)}
        />
      ),
      varKey: 'glDate',
      colType: ITableItemType.DATE,
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="glDate"
          minWidth="150"
        >
          {value.glDate && format(parseISO(value.glDate), FULL_DATE_FORMAT)}
        </StyledTableCell>
      ),
    },
    {
      colName: 'ID',
      customHeader: (
        <TextSearchFilter
          _key={subledgerId}
          label="JE&nbsp;ID"
          value={getFilterValueByFilterName(filters, 'externalId')}
          onChange={(filter) => setFilter('externalId', filter)}
          onInput={(filter) => setFilter('externalId', filter)}
        />
      ),
      varKey: 'externalId',
      colType: ITableItemType.SORTKEY,
      sortKey: ['externalId'],
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="externalId"
        >
          {value.transaction
            ? (
              <StyledLink
                target="_blank"
                to={{ pathname: generateQBOLink(value.transaction.transactionType, value.transaction.externalId) }}
              >
                {value.transaction.externalId.split(':').shift()}
              </StyledLink>
            )
            : (
              <StyledLink to={`/fixed-assets/edit/${subledgerId}/#${value.id}`}>
                Open balance
              </StyledLink>
            )}
        </StyledTableCell>
      ),
    },
    {
      colName: 'FA#',
      customHeader: (
        <TextSearchFilter
          _key={subledgerId}
          label="FA#"
          value={getFilterValueByFilterName(filters, 'number')}
          onChange={(filter) => setFilter('number', filter)}
          onInput={(filter) => setFilter('number', filter)}
        />
      ),
      varKey: 'number',
      colType: ITableItemType.TEXT,
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="number"
          minWidth="120"
        >
          {value.number}
        </StyledTableCell>
      ),
    },
    {
      colName: 'Description',
      customHeader: (
        <TextSearchFilter
          _key={subledgerId}
          label="Description"
          value={getFilterValueByFilterName(filters, 'description')}
          onChange={(filter) => setFilter('description', filter)}
          onInput={(filter) => setFilter('description', filter)}
        />
      ),
      varKey: 'description',
      colType: ITableItemType.TEXT,
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="description"
          minWidth="200"
        >
          <LineClamp text={value.description} />
        </StyledTableCell>
      ),
    },
    {
      colName: 'Vendor',
      customHeader: (
        <MultipickerFilter
          _key={subledgerId}
          label="Vendor"
          value={getFilterValueByFilterName(filters, 'vendorContactId')}
          options={vendors || []}
          onChange={(filter) => setFilter('vendorContactId', filter)}
        />
      ),
      varKey: 'vendorContact',
      colType: ITableItemType.SORTKEY,
      sortKey: ['name'],
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="vendorContact"
          minWidth="200"
        >
          {value.vendorContact?.name || ''}
        </StyledTableCell>
      ),
    },
    {
      colName: 'Asset Type',
      customHeader: (
        <MultipickerFilter
          _key={subledgerId}
          label="Asset Type"
          value={getFilterValueByFilterName(filters, 'fixedAssetTypeId')}
          options={fixedAssetTypes || []}
          onChange={(filter) => setFilter('fixedAssetTypeId', filter)}
        />
      ),
      varKey: 'fixedAssetType',
      colType: ITableItemType.SORTKEY,
      sortKey: ['name'],
      child: (value: FixedAssetForm) => (
        <StyledTableCell
          key="fixedAssetType"
          minWidth="200"
        >
          {value.fixedAssetType?.name || ''}
        </StyledTableCell>
      ),
    },
    {
      colName: 'Disposal Date',
      customHeader: (
        <DatepickerFilter
          _key={subledgerId}
          label="Disposal Date"
          value={getFilterValueByFilterName(filters, 'disposalDate')}
          onChange={(filter) => setFilter('disposalDate', filter)}
        />
      ),
      varKey: 'disposalDate',
      colType: ITableItemType.DATE,
      child: (value: WriteOffFixedAssetForm) => (
        <StyledTableCell
          key="disposalDate"
          minWidth="150"
        >
          {value.disposalDate && format(parseISO(value.disposalDate), FULL_DATE_FORMAT)}
        </StyledTableCell>
      ),
    },
    {
      colName: 'Original amount',
      customHeader: (
        <AmountFilter
          _key={subledgerId}
          label="Original amount"
          value={getFilterValueByFilterName(filters, 'originalValue')}
          onChange={(filter) => setFilter('originalValue', filter)}
        />
      ),
      varKey: 'originalValue',
      colType: ITableItemType.NUMMARY,
      child: (value: WriteOffFixedAssetForm) => (
        <StyledTableCell
          key="originalValue"
          minWidth="190"
          alignRight
        >
          {currencyFormatter.format(value.originalValue)}
        </StyledTableCell>
      ),
    },
    {
      colName: 'Accumulated Depreciation Amount',
      customHeader: (
        <AmountFilter
          _key={subledgerId}
          label="Accumulated Depreciation Amount"
          value={getFilterValueByFilterName(filters, 'accumulatedDepreciationAmount')}
          onChange={(filter) => setFilter('accumulatedDepreciationAmount', filter)}
        />
      ),
      varKey: 'accumulatedDepreciationAmount',
      colType: ITableItemType.NUMMARY,
      child: (value: WriteOffFixedAssetForm) => (
        <StyledTableCell
          key="accumulatedDepreciationAmount"
          minWidth="210"
          alignRight
        >
          {currencyFormatter.format(value.accumulatedDepreciationAmount)}
        </StyledTableCell>
      ),
    },
    {
      colName: 'Gain/Loss Amount',
      customHeader: (
        <AmountFilter
          _key={subledgerId}
          label="Gain/Loss Amount"
          value={getFilterValueByFilterName(filters, 'writtenOffAmount')}
          onChange={(filter) => setFilter('writtenOffAmount', filter)}
        />
      ),
      varKey: 'writtenOffAmount',
      colType: ITableItemType.NUMMARY,
      child: (value: WriteOffFixedAssetForm) => (
        <StyledTableCell
          key="writtenOffAmount"
          minWidth="190"
          alignRight
        >
          {currencyFormatter.format(value.writtenOffAmount || 0)}
        </StyledTableCell>
      ),
    },
    {
      colName: '',
      varKey: 'delete',
      colType: ITableItemType.ACTION,
      child: (value: WriteOffFixedAssetForm) => (
        <StyledTableCell key="delete">
          <IconButton
            disabled={isDeletingWritingOff}
            onClick={(e) => handleDeleteWriteOff(value, e)}
          >
            <DeleteIcon />
            {isDeletingWritingOff && (
              <CircularProgress
                size={20}
                style={{ position: 'absolute' }}
              />
            )}
          </IconButton>
        </StyledTableCell>
      ),
    },
  ];

  const defaultSort = {
    key: 'transaction',
    order: SortOrder.DESC,
  };

  const handleSetPage = (p: number) => {
    setPage(p);
  };

  const emptyStateDescription = numberOfFiltersApplied
    && (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        No results for selected filters
        <Button
          color="primary"
          variant="contained"
          onClick={clearAllFilters}
          style={{ marginTop: 10 }}
        >
          Clear all filters
        </Button>
      </Box>
    );

  return (
    <>
      <DialogBox
        openDialog={deleteWriteOffModal}
        closeDialog={() => setDeleteWriteOffModal(false)}
        dialogTitle="Alert"
        dialogContext={(
          <>
            <span>
              You are about to delete a write-off in a historical period.
              This will automatically repost the journal entry for this
              and any subsequent closed periods for this account.
            </span>
            <Loader open={isDeletingWritingOff} />
          </>
        )}
        dismissContext="Cancel"
        actions={[
          {
            title: 'Delete',
            event: () => dispatchDeleteWriteOff(),
          },
        ]}
      />
      {fixedAssetTypes && (
        <TableComponent
          colProps={colProps}
          tableData={fixedAssets}
          defaultSort={defaultSort}
          contentType={ITableType.fixedAssetsAmortizationSources}
          topBarActions={[]}
          emptyStateDescription={emptyStateDescription}
          page={page}
          setPage={handleSetPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </>
  );
};
