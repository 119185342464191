import React, {
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Button,
  makeStyles,
} from '@material-ui/core';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useHistory } from 'react-router';
import {
  prop,
  uniqBy,
} from 'ramda';
import find from 'lodash.find';
import {
  formatISO,
  parseISO,
} from 'date-fns';
import { compareAsc } from 'date-fns/esm';
import { AppThunkDispatch } from '../../../store/store';
import {
  clearAllV2Subledgers,
  getV2Subledgers,
} from '../../../store/slices/v2Subledgers';
import { ProductTypes } from '../../../interfaces/common';
import {
  currentPeriodSelector,
  latestPeriodSelector,
  subledgersFinalizedListSelector,
} from '../../../store/selectors/v2Subledgers';
import { getAllAccounts } from '../../../store/slices/v2Accounts';
import { accountsListSelector } from '../../../store/selectors/v2Accounts';
import { DashboardGroup } from './DashboardGroup';
import { PeriodSelector } from '../../../components/Subledger/FixedAssets/PeriodSelector';
import { fixedAssetsSubledgersStartDate } from '../../../util/helpers';
import NoData from '../../../components/NoData';
import { Loader } from '../../../components/Loader';
import { lastV2SyncSelector } from '../../../store/selectors/v2Sync';

const useStyles = makeStyles({
  resetButton: {
    marginLeft: 8,
  },
  manageAssetTypesButton: {
    marginLeft: 'auto',
  },
});

export const Dashboard = () => {
  const classes = useStyles();
  const history = useHistory();
  const [initialized, setInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState<string | undefined>();
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const lastFetch = useSelector(lastV2SyncSelector);
  const subledgers = useSelector(subledgersFinalizedListSelector);
  const currentPeriod = useSelector(currentPeriodSelector);
  const latestPeriod = useSelector(latestPeriodSelector);
  const accounts = useSelector(accountsListSelector);

  const subledgersForSelectedDate = subledgers
    .filter((sub) => selectedDate && compareAsc(new Date(sub.startDate), new Date(selectedDate)) === -1);

  const subledgersGroupedByAccumulation = uniqBy(prop('accumulationAccount'), subledgersForSelectedDate)
    .map(({ accumulationAccount }) => ({
      accumulationAccountId: accumulationAccount,
      nestedSubledgers: subledgersForSelectedDate.filter((sub) => sub.accumulationAccount === accumulationAccount),
    }));

  const subledgersMinDate = fixedAssetsSubledgersStartDate(
    subledgers.filter((sub) => find(accounts, { id: sub.accountId })?.visible),
  );

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      reduxDispatch(getV2Subledgers({ productType: ProductTypes.FIXED_ASSETS }))
        .unwrap()
        .then((result) => setSelectedDate(result.currentPeriodEnd)),
      reduxDispatch(getAllAccounts({})),
    ]).finally(() => {
      setIsLoading(false);
      setInitialized(true);
    });

    return () => {
      reduxDispatch(clearAllV2Subledgers());
    };
  }, [reduxDispatch, lastFetch]);

  useEffect(() => {
    if (selectedDate && initialized) {
      reduxDispatch(getV2Subledgers({
        productType: ProductTypes.FIXED_ASSETS,
        date: selectedDate,
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, reduxDispatch]);

  const handleChange = (date: Date) => {
    setSelectedDate(formatISO(date, { representation: 'date' }));
  };

  const areAnySubledgersVisible = subledgersGroupedByAccumulation
    .map((group) => group.nestedSubledgers
      .reduce((acc, sub) => (find(accounts, { id: sub.accountId })?.visible === true ? acc + 1 : acc), 0))
    .reduce((acc, val) => acc + val, 0) > 0;

  return (
    <>
      <Loader open={isLoading} />
      {((!subledgersGroupedByAccumulation.length || !areAnySubledgersVisible) && !isLoading) && (
        <NoData isNegativeLayout />
      )}
      {!!subledgersGroupedByAccumulation.length && areAnySubledgersVisible && !isLoading && (
        <Box
          width="100%"
          padding={4}
        >
          {currentPeriod && selectedDate && (
            <Box
              display="flex"
              alignItems="center"
            >
              <PeriodSelector
                selectedDate={parseISO(selectedDate)}
                currentPeriodDate={parseISO(selectedDate)}
                handleChange={handleChange}
                minDate={subledgersMinDate}
                maxDate={parseISO(latestPeriod!)}
              />
              {selectedDate !== currentPeriod && (
                <Button
                  color="primary"
                  onClick={() => setSelectedDate(currentPeriod)}
                  className={classes.resetButton}
                >
                  Return to earliest open period
                </Button>
              )}
              <Button
                color="primary"
                variant="contained"
                className={classes.manageAssetTypesButton}
                onClick={() => history.push('/account/asset-types')}
              >
                Manage Asset Types
              </Button>
            </Box>
          )}
          {selectedDate && subledgersGroupedByAccumulation
            .map(({ accumulationAccountId, nestedSubledgers }) => {
              const accumulationAccount = find(accounts, { id: accumulationAccountId });

              return accumulationAccount && (
                <DashboardGroup
                  key={accumulationAccountId}
                  accumulationAccount={accumulationAccount}
                  nestedSubledgers={nestedSubledgers}
                  accounts={accounts}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                />
              );
            })}
        </Box>
      )}
    </>
  );
};
