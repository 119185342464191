import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Box,
  Button,
  createStyles,
  IconButton,
  TableCell,
  Tooltip,
  Typography,
} from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import { format } from 'date-fns';
import { Close } from '@material-ui/icons';
import {
  CompanyUser,
  ITableItemType,
  ITableType,
  NotifierType,
  SortOrder,
} from '../../interfaces/types';
import ErrorPage from '../../components/ErrorPage';
import { StyledFormLabel } from '../../components/FormControl/FormControl.styled';
import {
  FULL_DAY_FORMAT,
  PENDING_USER_STATUS,
  TABLE_HEADER_Z_INDEX,
} from '../../util/constants';
import COLORS from '../../theme/colors';
import { openSnackbar } from '../../components/Notifier';
import Flyover from '../../components/Flyover';
import AddUser from './AddUser';
import { fromUTC } from '../../util/timezone';
import EmailIcon from '../../components/Icons/EmailIcon';
import AddUserRow from './AddUserRow';
import TableComponent from '../../components/Table';
import {
  deleteCompanyUsers,
  getCompanyUsers,
  postInviteCompanyUser,
} from '../../store/slices/settings';
import { AppThunkDispatch } from '../../store/store';
import { accountInfoSelector } from '../../store/selectors/account';
import {
  companyUsersSelector,
  isFetchingCompanyUsers,
  fetchingCompanyUsersError,
} from '../../store/selectors/settings';
import { Loader } from '../../components/Loader';

const useStyles = makeStyles(() => createStyles({
  fontSize13: {
    fontSize: 13,
  },
  emailButton: {
    marginLeft: 16, marginRight: 8,
  },
  closeIcon: {
    colors: COLORS.violet,
  },
}));

const ManageUser = () => {
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const classes = useStyles();

  const account = useSelector(accountInfoSelector);
  const users = useSelector(companyUsersSelector);
  const loading = useSelector(isFetchingCompanyUsers);
  const error = useSelector(fetchingCompanyUsersError);

  const { companyId: accountCompanyId } = account!;

  const [modalVisibility, setModalVisibility] = useState(false);

  const onAddUser = async (email: string) => reduxDispatch(postInviteCompanyUser({
    accountCompanyId,
    email,
  }))
    .unwrap()
    .then(() => {
      getData();
      return true;
    })
    .catch(() => false);

  const getData = useCallback(async () => {
    if (!loading && !error) {
      reduxDispatch(getCompanyUsers({ accountCompanyId }));
    }
  }, [accountCompanyId, error, loading, reduxDispatch]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const onRemoveCompanyUsers = (companyUser: CompanyUser) => async () => {
    reduxDispatch(deleteCompanyUsers({
      companyUserId: companyUser.user.id,
    }))
      .unwrap()
      .then(() => {
        getData();
      });
  };

  const onResendInvite = (companyUser: CompanyUser) => async () => {
    if (companyUser.status === PENDING_USER_STATUS) {
      const result = await onAddUser(companyUser?.user?.email);
      if (result) {
        openSnackbar({ message: 'Invite has been sent' }, NotifierType.Success);
      }
    }
  };

  if (error) {
    return <ErrorPage />;
  }

  const colProps = [
    {
      colName: 'User Email',
      varKey: 'user',
      colType: ITableItemType.USEREMAIL,
      child: (value: CompanyUser) => (
        <TableCell key="userEmail">
          {value.user.email}
        </TableCell>
      ),
    },
    {
      colName: 'Name',
      varKey: 'user',
      colType: ITableItemType.USERNAME,
      child: (value: CompanyUser) => (
        <TableCell key="userName">
          {`${value.user.firstName} ${value.user.lastName}`}
        </TableCell>
      ),
    },
    {
      colName: 'Start date',
      varKey: 'createdAt',
      colType: ITableItemType.DATE,
      child: (value: CompanyUser) => (
        <TableCell key="createdAt">
          {value.status === PENDING_USER_STATUS ? '-' : `${format(fromUTC(value.createdAt), FULL_DAY_FORMAT)}`}
        </TableCell>
      ),
    },
    {
      colName: 'Last login',
      varKey: 'updatedAt',
      colType: ITableItemType.DATE,
      child: (value: CompanyUser) => (
        <TableCell key="updatedAt">
          {value.status === PENDING_USER_STATUS ? '-' : `${format(fromUTC(value.updatedAt), FULL_DAY_FORMAT)}`}
        </TableCell>
      ),
    },
    {
      colName: '',
      varKey: 'action',
      colType: ITableItemType.TEXT,
      child: (value: CompanyUser) => (
        <TableCell
          align="left"
          key="actionCell"
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            maxWidth="180px"
          >
            {value.status === PENDING_USER_STATUS ? (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <Typography className={classes.fontSize13}>
                  Invite Pending
                </Typography>
                <Tooltip
                  title="Resend Invite"
                  arrow
                  placement="top"
                >
                  <IconButton
                    size="small"
                    className={classes.emailButton}
                    onClick={onResendInvite(value)}
                  >
                    <EmailIcon size="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title="Cancel Invite"
                  arrow
                  placement="top"
                >
                  <IconButton
                    size="small"
                    className={classes.closeIcon}
                    onClick={onRemoveCompanyUsers(value)}
                  >
                    <Close />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={onRemoveCompanyUsers(value)}
              >
                Remove
              </Button>
            )}
          </Box>
        </TableCell>
      ),
    },
  ];

  const defaultSort = {
    key: 'user[email]',
    order: SortOrder.DESC,
  };

  return (
    <>
      <Flyover
        title="ADD USER"
        variant="drawer"
        open={modalVisibility}
        onClose={() => setModalVisibility(false)}
      >
        <AddUser
          onAddUser={onAddUser}
          onClose={() => setModalVisibility(false)}
        />
      </Flyover>
      <Box width="100%">
        <Loader open={loading} />
        <StyledFormLabel>User Management</StyledFormLabel>
        <Box
          width="100%"
          zIndex={TABLE_HEADER_Z_INDEX}
          bgcolor={COLORS.white}
          mt={3}
        >
          <AddUserRow onClick={() => setModalVisibility(true)} />
        </Box>
        <TableComponent
          colProps={colProps}
          tableData={users}
          defaultSort={defaultSort}
          contentType={ITableType.settings}
          topBarActions={[]}
        />
      </Box>
    </>
  );
};

export default React.memo(ManageUser);
