import React, {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import Box from '@material-ui/core/Box';
import {
  Button,
  TableCell,
} from '@material-ui/core';
import { useLocation } from 'react-router';
import TableComponent from '../../components/Table';
import {
  InfoTypography,
  QuickBooksButton,
} from './Settings.styled';
import { openSnackbar } from '../../components/Notifier';
import {
  Company,
  ITableItemType,
  ITableType,
  NotifierType,
  SortOrder,
} from '../../interfaces/types';
import {
  accountInfoSelector,
  isCompanyAvailableSelector,
} from '../../store/selectors/account';
import { StyledFormLabel } from '../../components/FormControl/FormControl.styled';
import ContextMenu from '../../components/ContextMenu/index';
import DialogBox from '../../components/DialogBox';
import { postCompaniesDisconnectById } from '../../store/slices/company';
import { AppThunkDispatch } from '../../store/store';
import {
  deleteToken,
  getErrorFromCookie,
  updateTokenFromCookie,
} from '../../util/auth';
import { isDisconnectingSelector } from '../../store/selectors/company';
import { Loader } from '../../components/Loader';
import { MoreHorizIcon } from '../../components/Icons';

const Connect = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const accountInfo = useSelector(accountInfoSelector);
  const companies = accountInfo?.companies || [];
  const isCompanyAvailable = useSelector(isCompanyAvailableSelector);
  const isDisconnecting = useSelector(isDisconnectingSelector);
  const success = useQuery()?.get('success');

  const [disconnectingCompany, setDisconnectingCompany] = useState<Company | null>(null);
  const [isAddCompanyOpen, setIsAddCompanyOpen] = useState<boolean>(
    !!useQuery().get('addCompany') && isCompanyAvailable,
  );

  const onConnect = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/v2/auth/qbo/connect`;
  };

  useEffect(() => {
    const checkAccountInfo = async () => {
      if (success) {
        if (success === 'true') {
          updateTokenFromCookie();
          window.location.href = '/';
        } else {
          const requestError = getErrorFromCookie();
          if (requestError) {
            openSnackbar({ message: requestError }, NotifierType.Error);
          }
          deleteToken();
        }
      }
    };
    checkAccountInfo();
  });

  const triggerDisconnect = (company: Company) => {
    setDisconnectingCompany(company);
  };

  const performDisconnect = async (company: Company) => {
    await reduxDispatch(postCompaniesDisconnectById({
      companyId: company.id,
    }));
    setDisconnectingCompany(null);
  };

  const colProps = [
    {
      colName: 'Company Name',
      varKey: 'companyName',
      colType: ITableItemType.TEXT,
      child: (value: Company) => (
        <TableCell key="companyName">
          {value.companyName}
        </TableCell>
      ),
    },
    {
      colName: 'General Ledger',
      varKey: 'generalLedger',
      colType: ITableItemType.TEXT,
      child: () => (
        <TableCell key="generalLedger">
          QuickBooks
        </TableCell>
      ),
    },
    {
      colName: '',
      varKey: 'actions',
      colType: ITableItemType.TEXT,
      child: (value: Company) => (
        <TableCell
          key="actions"
          align="right"
        >
          <ContextMenu
            menuItems={[
              {
                action: () => triggerDisconnect(value),
                title: 'Disconnect company',
              },
            ]}
          >
            <MoreHorizIcon />
          </ContextMenu>
        </TableCell>
      ),
    },
  ];

  const defaultSort = {
    key: 'companyName',
    order: SortOrder.DESC,
  };

  return (
    <Box width="100%">
      <Loader open={isDisconnecting} />
      {disconnectingCompany && (
        <DialogBox
          openDialog
          closeDialog={() => setDisconnectingCompany(null)}
          dialogTitle="Warning!"
          dialogContext={(
            <>
              <Box mt={3}>
                Disconnecting
                {' '}
                <strong>{disconnectingCompany.companyName}</strong>
                {' '}
                will remove the connection for all users.
              </Box>
              <Box mt={3}>
                Do you wish to proceed?
              </Box>
            </>
          )}
          dismissContext="Cancel"
          actions={[
            {
              title: 'Disconnect',
              event: () => performDisconnect(disconnectingCompany),
            },
          ]}
        />
      )}
      <StyledFormLabel>Connect a Company</StyledFormLabel>
      {!isCompanyAvailable && (
        <InfoTypography>
          Your dashboard is currently empty because you are not connected to a general ledger.
        </InfoTypography>
      )}
      <Box
        display="flex"
        alignItems="center"
        marginTop="10px"
        marginBottom="30px"
      >
        {isCompanyAvailable
          ? (
            <>
              <InfoTypography>Add another company:</InfoTypography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setIsAddCompanyOpen(true)}
              >
                Add company
              </Button>
            </>
          )
          : (
            <>
              <InfoTypography>Connect Facta to a QuickBooks online company:</InfoTypography>
              <QuickBooksButton
                variant="contained"
                color="primary"
                type="submit"
                onClick={onConnect}
              >
                Connect to QuickBooks
              </QuickBooksButton>
            </>
          )}
      </Box>
      <StyledFormLabel>Current connections</StyledFormLabel>
      <TableComponent
        colProps={colProps}
        tableData={companies}
        defaultSort={defaultSort}
        contentType={ITableType.settings}
        topBarActions={[]}
        emptyStateDescription={(
          <Box textAlign="center">
            <Box marginBottom="10px">No connected companies</Box>
            <QuickBooksButton
              variant="contained"
              color="primary"
              type="submit"
              onClick={onConnect}
            >
              Connect to QuickBooks
            </QuickBooksButton>
          </Box>
        )}
      />
      <DialogBox
        openDialog={isAddCompanyOpen}
        closeDialog={() => setIsAddCompanyOpen(false)}
        dialogTitle="Add another company:"
        dialogContext={(
          <Box>
            <Button
              variant="outlined"
              onClick={() => setIsAddCompanyOpen(false)}
            >
              Cancel
            </Button>
            <QuickBooksButton
              variant="contained"
              color="primary"
              type="submit"
              onClick={onConnect}
            >
              Connect to QuickBooks
            </QuickBooksButton>
          </Box>
        )}
        dismissContext="cancel"
        showCancel={false}
      />
    </Box>
  );
};

export default React.memo(Connect);
