import {
  formatISO,
  isAfter,
  isEqual,
  isWithinInterval,
  parseISO,
  startOfMonth,
} from 'date-fns';
import {
  FixedAsset,
  FixedAssetScheduleItem,
} from '../../../interfaces/fixedAssets';
import { V2SubledgerDetails } from '../../../interfaces/subledgers';
import { precisionRound } from '../../math';

export const schedulePostedSumReduce = (
  sum: number,
  sch: FixedAssetScheduleItem,
) => (sum + (sch.posted ? sch.amount : 0));

export const scheduleUnpostedSumReduce = (
  sum: number,
  sch: FixedAssetScheduleItem,
) => (sum + (sch.amount));

export const scheduleAsOfdate = (
  asOfDate: string,
  scheduleStartDate: string,
  sch: Array<FixedAssetScheduleItem>,
  shouldCalcDisposalsPosted?: boolean,
) => sch.filter((scheduleItem) => (shouldCalcDisposalsPosted
  ? false
  : isWithinInterval(
    parseISO(scheduleItem.effective), { start: parseISO(scheduleStartDate), end: parseISO(asOfDate) },
  )));

export const calculateSubledgerDetails = ({
  fixedAssets,
  asOfDate,
  omitCalcOnDisposal,
}: {
  fixedAssets?: Array<FixedAsset>;
  asOfDate?: string;
  omitCalcOnDisposal?: boolean;
}): V2SubledgerDetails => {
  if (fixedAssets) {
    const asOfDateFixedAssets = asOfDate ? fixedAssets.filter((asset) => asset.schedule
      && (isAfter(parseISO(asOfDate), parseISO(asset.inServiceDate))
        || isEqual(parseISO(asOfDate), parseISO(asset.inServiceDate)))
      && isWithinInterval(
        parseISO(asset.inServiceDate), { start: parseISO(asset.inServiceDate), end: parseISO(asOfDate) },
      )) : [];

    const currentPeriodUnpostedAssetsLength = (asOfDate
      ? asOfDateFixedAssets
        .filter((asset) => (scheduleAsOfdate(asOfDate, asset.inServiceDate, asset.schedule!)
          .filter((schedule) => !schedule.posted).length)).length
      : 0);

    const calculateAsOfDateDepreciation = ({
      posted,
      isCurrentPeriodOnly,
    }: { posted: boolean; isCurrentPeriodOnly?: boolean }) => (asOfDate
      ? precisionRound(asOfDateFixedAssets
        .reduce((sum, asset) => (sum + scheduleAsOfdate(
          asOfDate,
          isCurrentPeriodOnly
            ? formatISO(startOfMonth(parseISO(asOfDate)), { representation: 'date' })
            : asset.inServiceDate,
          asset.schedule!,
          omitCalcOnDisposal && !!asset.disposal && currentPeriodUnpostedAssetsLength === 0,
        )
          .reduce(posted ? schedulePostedSumReduce : scheduleUnpostedSumReduce, 0)), 0))
      : 0);

    const assetsUnpostedValue = calculateAsOfDateDepreciation({
      posted: false,
      isCurrentPeriodOnly: true,
    }) - calculateAsOfDateDepreciation({
      posted: true,
      isCurrentPeriodOnly: true,
    });

    const currentFactaBalance = precisionRound(fixedAssets
      .reduce((sum, asset) => (sum + (asset.originalValue)), 0));

    const assetsScheduledValue = precisionRound(fixedAssets
      .reduce((sum, asset) => (sum + ((asset.finalized && !(asset.disposal && !assetsUnpostedValue))
        ? asset.originalValue
        : 0)), 0));

    const allAssetsScheduled = !fixedAssets.filter((asset) => !asset.finalized).length;

    const priorAccumulatedDepreciation = precisionRound(fixedAssets
      .reduce((sum, asset) => (sum + asset.priorDepreciation), 0));

    const allAssetsValid = !fixedAssets.filter((asset) => !asset.isValid).length;

    return {
      currentFactaBalance,
      assetsScheduledValue,
      allAssetsScheduled,
      assetsPostedValue: calculateAsOfDateDepreciation({ posted: true }),
      currentPeriodPostedValue: calculateAsOfDateDepreciation({ posted: true, isCurrentPeriodOnly: true }),
      assetsUnpostedValue,
      currentPeriodUnpostedAssetsLength,
      priorAccumulatedDepreciation,
      allAssetsValid,
      asOfDatePostedAccumulatedDepreciation: precisionRound(
        priorAccumulatedDepreciation + calculateAsOfDateDepreciation({ posted: true }),
      ),
    };
  }

  return {
    currentFactaBalance: 0,
    assetsScheduledValue: 0,
    allAssetsScheduled: true,
    assetsPostedValue: 0,
    currentPeriodPostedValue: 0,
    assetsUnpostedValue: 0,
    currentPeriodUnpostedAssetsLength: 0,
    allAssetsValid: true,
    priorAccumulatedDepreciation: 0,
    asOfDatePostedAccumulatedDepreciation: 0,
  };
};
