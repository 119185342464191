import React, {
  useRef,
  useState,
} from 'react';
import {
  Button,
  ButtonGroup,
  createStyles,
  Menu,
  MenuItem,
  Theme,
} from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import clsx from 'clsx';
import COLORS from '../../theme/colors';
import DropDownIcon from '../Icons/DropDownIcon';

interface Props {
  onSave: () => Promise<void>;
  onSaveAndExit: () => Promise<void>;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  saveButton: {
    color: COLORS.white,
    paddingRight: 32,
    paddingTop: 4,
    paddingBottom: 4,
    borderRight: '0px !important',
    '&.Mui-disabled': {
      backgroundColor: COLORS.border,
    },
  },
  saveButtonNoBorder: {
    borderRadius: 0,
    borderTopLeftRadius: 6,
  },
  saveExit: {
    color: COLORS.white,
    width: 10,
    padding: 0,
    margin: 0,
    '&.Mui-disabled': {
      backgroundColor: COLORS.border,
      '& svg path': {
        fill: COLORS.disabledButton,
      },
    },
  },
  menuOptions: {
    fontSize: 10,
    fontWeight: 700,
    borderRadius: 0,
    background: COLORS.skyBlue,
    color: COLORS.white,
    padding: 7,
    marginBottom: 0,
    minHeight: 'auto !important',
  },
  saveExitNoBorder: {
    borderRadius: 0,
    borderTopRightRadius: 6,
  },
  menuList: {
    minWidth: 'auto',
    '& .MuiMenu-list': {
      padding: 0,
      minWidth: 'auto',
      width: '113px',
    },
    '& .MuiPaper-root': {
      borderRadius: 0,
      boxShadow: 'none',
      overflow: 'visible',
    },
    '& .MuiListItem-gutters': {
      paddingRight: 16,
      paddingLeft: 16,
    },
    '& .MuiListItem-button': {
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,
      transition: theme.transitions.create('box-shadow', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.short,
      }),
      '&:hover': {
        background: COLORS.skyBlue,
        boxShadow: `0 0 0 3px ${COLORS.skyBlueShadow}`,
      },
    },
  },
}));

const SaveButton = ({ onSave, onSaveAndExit, disabled }: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const buttonRef = useRef(null);

  const openSaveAndExitMenu = () => {
    setAnchorEl(buttonRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <ButtonGroup
        color="primary"
        aria-label="outlined primary button group"
        variant="contained"
        disableElevation={disabled}
      >
        <Button
          ref={buttonRef}
          onClick={onSave}
          variant="contained"
          color="primary"
          className={clsx(classes.saveButton, {
            [classes.saveButton]: !anchorEl,
            [classes.saveButtonNoBorder]: anchorEl,
          })}
          disabled={disabled}
        >
          Save
        </Button>
        <Button
          onClick={openSaveAndExitMenu}
          variant="contained"
          color="primary"
          className={clsx(classes.saveExit, {
            [classes.saveExit]: !anchorEl,
            [classes.saveExitNoBorder]: anchorEl,
          })}
          disabled={disabled}
        >
          <DropDownIcon />
        </Button>
      </ButtonGroup>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -29,
          horizontal: 0,
        }}
        id="save-exit-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ disablePadding: true }}
        className={classes.menuList}
      >
        <MenuItem
          onClick={onSaveAndExit}
          className={classes.menuOptions}
        >
          Save & Exit
        </MenuItem>
      </Menu>
    </>
  );
};

export default SaveButton;
