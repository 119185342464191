import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useParams,
} from 'react-router';
import {
  Box,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import find from 'lodash.find';
import {
  format,
  lastDayOfMonth,
} from 'date-fns';
import FactaAutocomplete from '../../../components/FactaAutocomplete';
import { InputLabel } from '../../../components/InputLabel';
import { Loader } from '../../../components/Loader';
import { accountsListSelector } from '../../../store/selectors/v2Accounts';
import { getAccountInfo } from '../../../store/slices/account';
import { getAllAccounts } from '../../../store/slices/v2Accounts';
import { getV2Subledgers } from '../../../store/slices/v2Subledgers';
import { AppThunkDispatch } from '../../../store/store';
import { subledgersFinalizedListSelector } from '../../../store/selectors/v2Subledgers';
import {
  clearV2Journals,
  getV2JournalEntries,
} from '../../../store/slices/v2JournalEntries';
import {
  clearJeData,
  getJournalEntryCSV,
  getSubledgerWithJournalEntries,
} from '../../../store/slices/je';
import { accountInfoSelector } from '../../../store/selectors/account';
import {
  DAY_SHORT_FORMAT,
  FINALIZED_SUBLEDGER_STATUS,
} from '../../../util/constants';
import TableComponent from '../../../components/Table';
import { subledgerWithJeSelector } from '../../../store/selectors/je';
import {
  getV1ColProps,
  v1DefaultSort,
} from './v1ColProps';
import {
  getV2ColProps,
  v2DefaultSort,
} from './v2ColProps';
import {
  FilterMatchingType,
  ITableType,
  ReportType,
} from '../../../interfaces/types';
import COLORS from '../../../theme/colors';
import { v2JournalEntriesSelector } from '../../../store/selectors/v2JournalEntries';
import { fromUTC } from '../../../util/timezone';
import { deleteSubledger } from '../../../util/subledger';
import { useFilters } from '../../../hooks/useFilters';
import { getFilteredData } from '../../../util/filtering';
import { DownloadExportIcon } from '../../../components/Icons';
import {
  downloadJournalEntryDetail,
  downloadJournalEntrySummary,
} from './v1CSVDownload';
import { featureSwitch } from '../../../util/featureSwitch';

const useStyles = makeStyles(() => createStyles({
  root: {
    '& td': {
      color: COLORS.deepGray,
    },
  },
  fontSize13: {
    fontSize: 13,
  },
  exportIcon: {
    width: 13,
    height: 13,
    marginLeft: 8,
  },
  editIcon: {
    height: 12,
    width: 12,
    marginLeft: 5,
  },
  colorBlue: {
    color: `${COLORS.skyBlue} !important`,
    textTransform: 'none',
  },
  noOffset: {
    minHeight: 'auto',
    margin: 0,
    padding: 0,
  },
  width5: {
    width: '5%',
  },
  positionRelative: {
    position: 'relative',
  },
  accountSelector: {
    minWidth: 300,
  },
}));

export const JEList = () => {
  const history = useHistory();
  const classes = useStyles();
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const { accountId } = useParams<{ accountId: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const {
    vendors,
    accountBalances,
    accountClasses,
    accountIncomes,
    products,
    customers,
  } = useSelector(accountInfoSelector)!;
  const accounts = useSelector(accountsListSelector);
  const v2Subledgers = useSelector(subledgersFinalizedListSelector);
  const v1Subledger = useSelector(subledgerWithJeSelector);
  const v2JournalEntries = useSelector(v2JournalEntriesSelector);
  const [selectedAccount, setSelectedAccount] = useState(find(accounts, { id: accountId }));

  const initialFilters = useMemo(() => ([
    {
      name: 'postingDate',
      matching: FilterMatchingType.DATE_RANGE,
    },
    {
      name: 'txnDate',
      matching: FilterMatchingType.DATE_RANGE,
    },
    {
      name: 'description',
      matching: FilterMatchingType.STRING_INCLUDES,
    },
    {
      name: 'amount',
      matching: FilterMatchingType.NUMBER_COMPARE,
    },
    {
      name: 'jeNumber',
      matching: FilterMatchingType.STRING_INCLUDES,
    },
    {
      name: 'externalId',
      matching: FilterMatchingType.STRING_INCLUDES,
    },
    {
      name: 'number',
      matching: FilterMatchingType.STRING_INCLUDES,
    },
  ]), []);

  const {
    setFilter,
    filters,
    clearAllFilters,
  } = useFilters(initialFilters);

  const {
    filteredData: filteredJournalEntries,
    numberOfFiltersApplied,
  } = useMemo(
    () => getFilteredData(v2JournalEntries, filters),
    [filters, v2JournalEntries],
  );

  const {
    filteredData: filteredJournalEntriesV1,
    numberOfFiltersApplied: numberOfFiltersAppliedV1,
  } = useMemo(
    () => getFilteredData(v1Subledger?.journalEntries || [], filters),
    [filters, v1Subledger?.journalEntries],
  );

  const eligibleAccounts = accounts
    .filter((acc) => find(accountBalances, { id: acc.legacyId })?.subledger.status === FINALIZED_SUBLEDGER_STATUS
      || find(v2Subledgers, { accountId: acc.id }));

  const handleChangeAccount = useCallback((id: string) => {
    history.push(`/journal-entries/${id || 'empty'}`);
  }, [history]);

  const v1NavigateToViewJournalEntry = (id: string) => {
    history.push(`/journal-entries-legacy/${id}`);
  };

  const v1NavigateToHistoricalEditingPage = (txtDate: string) => {
    deleteSubledger();
    const date = format(lastDayOfMonth(fromUTC(txtDate)), DAY_SHORT_FORMAT);
    history.push(`/historical/subledgers/schedule/${v1Subledger?.id}/?scheduleDate=${date}`);
  };

  const v2ViewJournalEntry = (jeId: string) => {
    const v2Subledger = find(v2Subledgers, { accountId });
    history.push(`/journal-entries/entry/${v2Subledger?.id}/${jeId}`);
  };

  const v2EditJournalEntry = (id: string, date: string) => {
    const v2Subledger = find(v2Subledgers, { accountId });
    history.push(`/fixed-assets/scheduler/${v2Subledger?.id}/${date}`);
  };

  const onCSVExport = async (type: ReportType) => {
    if (!v1Subledger?.account?.id) {
      return;
    }
    if (type === ReportType.Detail) {
      reduxDispatch(getJournalEntryCSV({
        accountId: v1Subledger.account.id,
        productCategory: v1Subledger.productCategory,
      }))
        .unwrap()
        .then((result) => {
          downloadJournalEntryDetail(result, vendors, accountClasses, accountIncomes, products, customers);
        });
    } else if (v1Subledger?.journalEntries && v1Subledger?.journalEntries.length) {
      downloadJournalEntrySummary(v1Subledger);
    }
  };

  const v1ColProps = getV1ColProps({
    classes,
    onView: v1NavigateToViewJournalEntry,
    onEdit: v1NavigateToHistoricalEditingPage,
    accountName: selectedAccount?.displayName || '',
    filters,
    setFilter,
    clearFilters: clearAllFilters,
    filtersApplied: numberOfFiltersAppliedV1 > 0,
  });

  const v2ColProps = getV2ColProps({
    classes,
    onView: v2ViewJournalEntry,
    onEdit: v2EditJournalEntry,
    accountName: selectedAccount?.displayName || '',
    filters,
    setFilter,
    clearFilters: clearAllFilters,
    filtersApplied: numberOfFiltersApplied > 0,
  });

  const v1TopBarActions = [
    {
      label: 'Export detail',
      icon: <DownloadExportIcon />,
      isData: true,
      action: () => onCSVExport(ReportType.Detail),
      pushRight: true,
    },
    {
      label: 'Export summary',
      icon: <DownloadExportIcon />,
      isData: true,
      action: () => onCSVExport(ReportType.Summary),
    },
  ];

  const v2TopBarActions = [
    {
      label: 'Export',
      icon: <DownloadExportIcon />,
      isData: true,
      isDisabled: true,
      action: () => {},
      pushRight: true,
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      ...(featureSwitch.fixedAssets ? [reduxDispatch(getV2Subledgers({}))] : []),
      reduxDispatch(getAccountInfo()),
      reduxDispatch(getAllAccounts({})),
    ])
      .finally(() => setIsLoading(false));

    return () => {
      reduxDispatch(clearV2Journals());
      reduxDispatch(clearJeData());
    };
  }, [reduxDispatch]);

  useEffect(() => {
    clearAllFilters();
    if (!selectedAccount || !v2Subledgers) return;

    reduxDispatch(clearV2Journals());
    reduxDispatch(clearJeData());

    const v2Subledger = find(v2Subledgers, { accountId });

    if (v2Subledger) {
      setIsLoading(true);
      reduxDispatch(getV2JournalEntries({ subledgerId: v2Subledger.id }))
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(true);
      reduxDispatch(getSubledgerWithJournalEntries({
        accountId: selectedAccount.legacyId,
      }))
        .finally(() => setIsLoading(false));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount, reduxDispatch]);

  useEffect(() => {
    const shouldConsiderFixedAssets = featureSwitch.fixedAssets ? v2Subledgers.length : true;

    if (!accountId && eligibleAccounts.length > 0) {
      handleChangeAccount(eligibleAccounts[0].id);
    }
    if (accountId && !selectedAccount && shouldConsiderFixedAssets && eligibleAccounts.length > 0) {
      setSelectedAccount(find(accounts, { id: accountId }));
    }
  }, [accountId, accounts, eligibleAccounts, handleChangeAccount, selectedAccount, v2Subledgers]);

  useEffect(() => {
    setSelectedAccount(find(accounts, { id: accountId }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  return (
    <Box
      width="100%"
      padding={4}
    >
      <Loader open={isLoading} />
      <Box display="flex">
        <InputLabel
          label="Account"
          isHorizontal
          centered
        />
        <FactaAutocomplete
          options={eligibleAccounts}
          optionName="displayName"
          onChange={handleChangeAccount}
          selectedItem={selectedAccount}
          defaultValue=""
          className={classes.accountSelector}
        />
      </Box>
      {selectedAccount?.legacyId === v1Subledger?.accountId && (
        <TableComponent
          colProps={v1ColProps}
          tableData={filteredJournalEntriesV1 || []}
          defaultSort={v1DefaultSort}
          contentType={ITableType.settings}
          topBarActions={v1TopBarActions}
        />
      )}
      {selectedAccount && selectedAccount?.legacyId !== v1Subledger?.accountId && (
        <TableComponent
          colProps={v2ColProps}
          tableData={filteredJournalEntries}
          defaultSort={v2DefaultSort}
          contentType={ITableType.settings}
          topBarActions={v2TopBarActions}
          emptyStateDescription={isLoading && <div>Loading</div>}
        />
      )}
    </Box>
  );
};
