/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment,max-len */
import { SvgIcon } from '@material-ui/core';
import * as React from 'react';

const MoreHorizIcon = (props: any) => (
  <SvgIcon
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default MoreHorizIcon;
