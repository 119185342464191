import {
  Box,
  Button,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import React from 'react';
import COLORS from '../../theme/colors';

interface Props {
  onClick: () => void;
}

const useStyles = makeStyles(() => createStyles({
  arrowIcon: {
    fontSize: 12,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: COLORS.white,
    },
  },
  noPadding: {
    padding: 0,
    height: '20px !important',
  },
  addAsset: {
    height: 'auto',
    justifyContent: 'end',
  },
  assetButton: {
    background: '#fff !important',
    minHeight: '20px !important',
  },
}));

const AddUserRow = ({ onClick }: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.addAsset}>
      <Button
        color="primary"
        className={classes.arrowIcon}
        startIcon={(
          <AddCircleRoundedIcon
            color="primary"
            fontSize="small"
          />
        )}
        onClick={onClick}
      >
        Add User
      </Button>
    </Box>
  );
};

export default React.memo(AddUserRow);
