/* eslint-disable react/jsx-props-no-spreading,max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const FixedAssetsIcon = (props: any) => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3266 8.77377H14.7559V7.68701H18.3266V8.77377Z"
      fill="inherit"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3266 11.0252H14.7559V9.93848H18.3266V11.0252Z"
      fill="inherit"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0384 18.0789H7.53613V16.9922H12.0384V18.0789Z"
      fill="inherit"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.2443 17.4577L9.2443 15.1289L10.3311 15.1289L10.3311 17.4577L9.2443 17.4577Z"
      fill="inherit"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6598 7.74545H4.58676V14.5856H12.6598V7.74545ZM3.5 6.65869V15.6724H13.7466V6.65869H3.5Z"
      fill="inherit"
    />
    <rect
      x="15.998"
      y="15.3036"
      width="1.16438"
      height="1.16438"
      fill="inherit"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6602 6.98854C12.6602 6.08824 13.39 5.3584 14.2903 5.3584H18.8702C19.7705 5.3584 20.5003 6.08824 20.5003 6.98854V17.0114C20.5003 17.9117 19.7705 18.6415 18.8702 18.6415H14.2903C13.39 18.6415 12.6602 17.9117 12.6602 17.0114V6.98854ZM14.2903 6.44516C13.9902 6.44516 13.7469 6.68844 13.7469 6.98854V17.0114C13.7469 17.3115 13.9902 17.5547 14.2903 17.5547H18.8702C19.1703 17.5547 19.4136 17.3115 19.4136 17.0114V6.98854C19.4136 6.68844 19.1703 6.44516 18.8702 6.44516H14.2903Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default FixedAssetsIcon;
