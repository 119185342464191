/* eslint-disable no-param-reassign */
import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { NotifierType } from '../../interfaces/types';
import { openSnackbar } from '../../components/Notifier';
import { getAccountSync } from './account';
import {
  parseJwt,
  setToken,
} from '../../util/auth';
import { setAxiosRequestDefaults } from '../../util/request';

const PREFIX = 'company';
const POST_COMPANIES_DISCONNECTED = `${PREFIX}/postCompaniesDisconnected`;
const POST_COMPANIES_DISCONNECT_BY_ID = `${PREFIX}/postCompaniesDisconnectById`;

interface State {
  isDisconnecting: boolean;
  failedDisconnection?: boolean;
}

const initialState: State = {
  isDisconnecting: false,
};

export const postCompaniesDisconnected = createAsyncThunk<null>(
  POST_COMPANIES_DISCONNECTED,
  async (_, { rejectWithValue }) => {
    try {
      await axios.post('/v2/companies/disconnected');

      return null;
    } catch (error: any) {
      const handledError = error.message;
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

interface DisconnectResponse {
  authToken: string;
}

export const postCompaniesDisconnectById = createAsyncThunk<null, {
  companyId: string;
}>(
  POST_COMPANIES_DISCONNECT_BY_ID,
  async ({ companyId }, { rejectWithValue, dispatch }) => {
    try {
      const request = applyCaseMiddleware(axios.create());
      const { data: { authToken } } = await request.post<DisconnectResponse>(`/v2/companies/${companyId}/disconnect`);

      setToken(authToken);
      setAxiosRequestDefaults(authToken);
      const parseToken = parseJwt(authToken);
      if (parseToken?.company) {
        dispatch(getAccountSync());
      } else {
        window.location.href = '/settings/connect';
      }

      return null;
    } catch (error: any) {
      const handledError = error.message;
      openSnackbar({ message: handledError }, NotifierType.Error);

      return rejectWithValue(handledError);
    }
  },
);

export const companySlice = createSlice({
  initialState,
  name: PREFIX,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postCompaniesDisconnected.pending, (state) => {
      state.isDisconnecting = true;
    });
    builder.addCase(postCompaniesDisconnected.fulfilled, (state) => {
      state.isDisconnecting = false;
      state.failedDisconnection = false;
    });
    builder.addCase(postCompaniesDisconnected.rejected, (state) => {
      state.isDisconnecting = false;
      state.failedDisconnection = true;
    });

    builder.addCase(postCompaniesDisconnectById.pending, (state) => {
      state.isDisconnecting = true;
    });
    builder.addCase(postCompaniesDisconnectById.fulfilled, (state) => {
      state.isDisconnecting = false;
      state.failedDisconnection = false;
    });
    builder.addCase(postCompaniesDisconnectById.rejected, (state) => {
      state.isDisconnecting = false;
      state.failedDisconnection = true;
    });
  },
});

export const companyReducer = companySlice.reducer;
