import React, {
  useEffect,
  useState,
} from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import Papa from 'papaparse';
import fileDownload from 'js-file-download';
import { AppThunkDispatch } from '../../../store/store';
import TableComponent from '../../../components/Table';
import {
  ITableItemType,
  ITableType,
  SortOrder,
} from '../../../interfaces/types';
import {
  isFetchingTags,
  tagsClassListSelector,
  tagsLocationListSelector,
} from '../../../store/selectors/tags';
import {
  clearTags,
  getAllTags,
  patchTag,
} from '../../../store/slices/v2Tags';
import { TagDetails } from '../../../interfaces/tags';
import { DEFAULT_PAGE_SIZE } from '../../../util/constants';
import { Loader } from '../../../components/Loader';
import { DownloadExportIcon } from '../../../components/Icons';
import { StyledTableCell } from '../../../components/Table/StyledTableCell';
import { LoadingBox } from '../../../components/LoadingBox';
import VisibilityIcon from '../../../components/Icons/VisibilityIcon';
import HideVisibilityIcon from '../../../components/Icons/HideVisibilityIcon';
import { lastV2SyncSelector } from '../../../store/selectors/v2Sync';

const useStyles = makeStyles(() => createStyles({
  noOffset: {
    minHeight: 'auto',
    margin: 0,
    padding: 0,
  },
}));

interface Props {
  tagType: 'locations' | 'classes';
}

export const Tags = ({ tagType }: Props) => {
  const reduxDispatch: AppThunkDispatch = useDispatch();
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [isUpdating, setIsUpdating] = useState('');
  const tags = useSelector(tagType === 'locations'
    ? tagsLocationListSelector
    : tagsClassListSelector);
  const isLoading = useSelector(isFetchingTags);
  const lastFetch = useSelector(lastV2SyncSelector);

  const tagName = tagType === 'locations'
    ? 'Location'
    : 'Class';

  useEffect(() => {
    reduxDispatch(getAllTags());

    return () => {
      reduxDispatch(clearTags());
    };
  }, [reduxDispatch, lastFetch]);

  const defaultSort = {
    key: 'name',
    order: SortOrder.DESC,
  };

  const onDownload = (sortedTableValues: any[]) => {
    if (sortedTableValues?.length) {
      const updatedTags = sortedTableValues.map((tag: any) => ({
        ...tag,
        active: tag.active ? 'Active' : 'Inactive',
      }));
      const csvData = Papa.unparse({
        data: updatedTags,
        fields: ['externalId', 'name', 'active'],
      }, {
        header: false,
      });
      const downloadData = `${[`${tagName} ID`, `${tagName} name`, 'Active'].join(',')}\n${csvData}`;
      fileDownload(downloadData, `${tagType}.csv`);
    }
  };

  const handleToggleVisibility = (tag: TagDetails) => {
    setIsUpdating(tag.id);
    reduxDispatch(patchTag({
      tagId: tag.id,
      visible: !tag.visible,
    }))
      .finally(() => setIsUpdating(''));
  };

  const colProps = [
    ...((tagType === 'locations')
      ? [{
        colName: `${tagName} ID`,
        varKey: 'externalId',
        sortable: true,
        colType: ITableItemType.NUMMARY,
        child: (value: TagDetails) => (
          <StyledTableCell width={160}>
            {value.externalId}
          </StyledTableCell>
        ),
      }] : []),
    {
      colName: `${tagName} name`,
      varKey: 'name',
      sortable: true,
      colType: ITableItemType.TEXT,
      child: (value: TagDetails) => (
        <StyledTableCell>
          {value.name}
        </StyledTableCell>
      ),
    },
    {
      colName: 'Visibility',
      varKey: 'visibility',
      sortable: true,
      colType: ITableItemType.BOOLEAN,
      child: (value: TagDetails) => (
        <StyledTableCell
          width={80}
          align="center"
        >
          {isUpdating === value.id && (
            <LoadingBox transparent />
          )}
          <Tooltip
            arrow
            title={value.visible ? 'Hide' : 'Show'}
          >
            <IconButton
              color={value.visible ? 'primary' : 'default'}
              className={classes.noOffset}
              disabled={!value.active}
              onClick={() => handleToggleVisibility(value)}
            >
              {value.visible ? <VisibilityIcon /> : <HideVisibilityIcon />}
            </IconButton>
          </Tooltip>
        </StyledTableCell>
      ),
    },
  ];

  const topBarActions = [
    {
      label: 'Export',
      icon: <DownloadExportIcon />,
      isData: true,
      action: onDownload,
    },
  ];

  return (
    <Box
      padding={2}
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      flexGrow={1}
    >
      <Loader open={isLoading} />
      {!isLoading && (
        <TableComponent
          colProps={colProps}
          tableData={tags}
          defaultSort={defaultSort}
          contentType={ITableType.accounts}
          topBarActions={topBarActions}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </Box>
  );
};
