import DatePicker from 'react-datepicker';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  IconButton,
  Typography,
  Divider,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import clsx from 'clsx';
import { format } from 'date-fns';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import COLORS from '../../theme/colors';
import MonthDisplayBox from './MonthDisplayBox';
import {
  MIN_SYSTEM_DATE,
  MONTH_SHORT_FORMAT,
  YEAR_FORMAT,
} from '../../util/constants';

interface Props {
  selectedDate: Date | null;
  onDateChange: (date: Date | null, keyboardString?: string) => void;
  minDate?: Date;
  maxDate?: Date;
  includeDates?: Date[];
  customInput?: React.ReactNode;
  disabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: theme.zIndex.drawer + 1,
    color: COLORS.black,
    backgroundColor: COLORS.white,
    border: 'none',
    padding: 0,
    lineHeight: '32px',

    '& .react-datepicker__header': {
      backgroundColor: COLORS.white,
      border: 'none',
      paddingLeft: 18,
      paddingRight: 12,
    },

    '& .react-datepicker__month-text': {
      outline: 'none !important',
    },

    '&  .react-datepicker__month--disabled': {
      backgroundColor: `${COLORS.white} !important`,
      color: '#ccc !important',
      '& :hover': {
        backgroundColor: `${COLORS.white} !important`,
        color: '#ccc !important',
      },
    },

    '&  .react-datepicker__month--selected': {
      backgroundColor: COLORS.skyBlue,
      '& :hover': {
        backgroundColor: COLORS.darkBlue,
      },
    },
  },
  disabled: {
    color: COLORS.border,
  },
}));

interface HeaderProps {
  date: Date;
  decreaseYear(): void;
  increaseYear(): void;
  prevYearButtonDisabled: boolean;
  nextYearButtonDisabled: boolean;
}

const MonthPicker = ({
  selectedDate,
  onDateChange,
  minDate = new Date(MIN_SYSTEM_DATE),
  maxDate,
  includeDates,
  customInput,
  disabled,
}: Props) => {
  const classes = useStyles();
  const handleMonthChange = (date: Date) => {
    onDateChange(date);
  };

  const renderHeader = ({
    date,
    decreaseYear,
    increaseYear,
    prevYearButtonDisabled,
    nextYearButtonDisabled,
  }: HeaderProps) => (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <IconButton
        onClick={decreaseYear}
        disabled={prevYearButtonDisabled}
        size="small"
      >
        <ChevronLeftIcon
          fontSize="small"
          className={clsx({
            [classes.disabled]: prevYearButtonDisabled,
          })}
        />
      </IconButton>
      <Box
        display="flex"
        flexGrow="1"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h6">
          {format(date, YEAR_FORMAT)}
        </Typography>
      </Box>
      <IconButton
        onClick={increaseYear}
        disabled={nextYearButtonDisabled}
        size="small"
      >
        <ChevronRightIcon
          fontSize="small"
          className={clsx({
            [classes.disabled]: nextYearButtonDisabled,
          })}
        />
      </IconButton>
      <Divider />
    </Box>
  );

  return (
    <DatePicker
      calendarClassName={clsx('MuiPaper-elevation8', classes.container)}
      disabledKeyboardNavigation
      renderCustomHeader={renderHeader}
      selected={selectedDate}
      onChange={handleMonthChange}
      minDate={minDate}
      maxDate={maxDate}
      showMonthYearPicker
      showPopperArrow={false}
      dateFormat={MONTH_SHORT_FORMAT}
      customInput={customInput || <MonthDisplayBox />}
      portalId="monthpicker-portal"
      popperPlacement="bottom"
      popperModifiers={{
        preventOverflow: {
          enabled: true,
          priority: ['right'],
        },
      }}
      includeDates={includeDates}
      disabled={disabled}
    />
  );
};

export default React.memo(MonthPicker);
