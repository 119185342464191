const primaryColor = '#11ADF8';
const secondaryColor = '#9730C2';

const COLORS = {
  white: '#FFFFFF',
  navy: '#1B243D',
  skyBlue: primaryColor,
  violet: secondaryColor,
  lime: '#71E954',
  deepGray: '#252729',
  medGray: '#515659',
  chipGray: '#e7e9ec',
  black: '#000000',
  lightGray: '#82878C',
  grayNeutral: '#747474',
  lightGrayBackground: '#F7F7F7',
  lightActiveColor: '#3F4143',
  lightGray2: '#CFD3DA',
  lightGray3: '#F4F6F8',
  lightGray5: '#FAFBFB',
  successGreen: '#5ABA50',
  lightBlue: '#60D9FF',
  lighterBlue: '#EAFAFF',
  hotViolet: '#FF5AEF',
  brightViolet: '#BD00FF',
  violet15: '#F0E0F6',
  darkBlue: '#0C74D3',
  hoverBlue: '#0b79ad',
  lightGreen: '#DCFFC1',
  quickBooksGreen: '#2CA01C',
  quickBooksGreenShadow: '#C6F6C1',
  error: '#DA072A',
  errorShadow: '#FFECEF',
  errorBackground: '#FDF3F4',
  border: '#ECECEC',
  skyBlueShadow: '#CFEFFE',
  violetShadow: '#EAD6F3',
  disabled: '#D1D1D1',
  checkboxUnchecked: '#8B8B8B',
  selectedAssetRow: '#F3FBFF',
  textDisabled: '#A2A2A2',
  disabledInput: '#B9B9B9',
  warning: '#F8D011',
  buttonBlack: '#5D5D5D',

  skyBlueDisabled: 'rgba(17, 173, 248, 0.35)',
  skyBlueShaded: '#E7F7FE',

  background: '#FFFFFF',
  lightGray2RBA: 'rgba(207, 211, 218, 0.1)',
  selectedCellBackground: 'rgba(17, 173, 248, 0.05)',
  lightGrayWithOpacity: 'rgba(130,135,140, 0.5)',
  hoverButtonGrey: 'rgba(0, 0, 0, 0.04)',
  menuBorder: 'rgba(255, 255, 255, 0.5)',
  disabledButton: 'rgba(0, 0, 0, 0.26)',
  warningBackground: '#FFFDF3',

  logoGradient: 'linear-gradient(180deg, #71E954 12.26%, #11ADF8 45.36%, #9730C2 87.03%)',
  navyBlackGradient: 'linear-gradient(180deg, #000000 0%, #1B243D 98.5%)',
  navyGradient: 'linear-gradient(180deg, #1B243D 0%, #344E76 98.5%);',
  selectBoxShadow: '0px 1px 3px rgba(30, 41, 67, 0.2);',
  inputBoxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2);',
  focusBoxShadow: `0 0 3px 1px ${primaryColor}`,
  errorBorderShadow: `0 0 3px 1px ${secondaryColor}`,
  flyoverShadow: '-2px 0px 13px rgba(0, 0, 0, 0.15);',
  containerBoxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08)',
};

export default COLORS;
