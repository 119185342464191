import React from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  createStyles,
  Tooltip,
  Button,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { eachMonthOfInterval } from 'date-fns';
import { HeaderTop } from '../../../components/common.styled';
import COLORS from '../../../theme/colors';
import {
  AmortizationSource,
  ProductCategory,
  Subledger,
} from '../../../interfaces/types';
import isEmpty from '../../../util/isEmpty';
import CalendarNavigation from '../../../components/CalendarNavigation';
import getSubledgerStartEndDate from '../../../util/getSubledgerStartEndDate';
import SubledgerAccountName from '../../../components/Subledger/SubledgerAccountName';
import {
  Breadcrumbs,
  BreadcrumbsItem,
} from '../../../components/BreadcrumbsStepper';
import { DownloadExportIcon } from '../../../components/Icons';

const useStyles = makeStyles(() => createStyles({
  selectBox: {
    width: 400,
  },
  exportIcon: {
    width: 13,
    height: 13,
    marginLeft: 8,
  },
  fontSize13: {
    fontSize: 13,
  },
  navigation: {
    paddingLeft: 38,
    paddingTop: 30,
  },
  displayBlock: {
    display: 'block',
  },
}));

interface Props {
  subledger?: Subledger | null;
  scheduleDate: string;
  downloadCSV: any;
  navigateToPrepareJE: () => void;
  onCancel: () => void;
  onMonthChange: (dateItem: Date) => void;
}

const ScheduleHeader = ({
  subledger,
  scheduleDate,
  downloadCSV,
  onCancel,
  navigateToPrepareJE,
  onMonthChange,
}: Props) => {
  const classes = useStyles();
  if (!subledger || isEmpty(subledger)) {
    return null;
  }

  const {
    startDate,
    endDate,
  } = getSubledgerStartEndDate(subledger);

  const subledgerInterval = eachMonthOfInterval({
    start: startDate,
    end: endDate,
  });

  const isPrepareJEEnable = !subledger?.amortizationSources?.some((asset: AmortizationSource) => !asset?.status);

  const onDateChange = (dateItem: Date) => {
    onMonthChange(dateItem);
  };

  const toolTipContent = 'Please ensure that all '
    + `${subledger?.productCategory === ProductCategory.PrepaidExpense ? 'prepaids' : 'revenues'} are scheduled.`;

  // @ts-ignore
  return (
    <Box
      position="relative"
      width="100%"
    >
      <CalendarNavigation
        onDateChange={onDateChange}
        scheduleDate={scheduleDate}
        subledgerInterval={subledgerInterval}
        periodContext="Previous Period:"
        color={COLORS.violet}
      />
      <Box className={classes.navigation}>
        <Breadcrumbs>
          <BreadcrumbsItem
            step={1}
            active
            violet
          >
            Schedule
            {' '}
            {subledger?.productCategory === ProductCategory.DeferredRevenue ? 'Revenue' : 'Prepaid'}
          </BreadcrumbsItem>
          <BreadcrumbsItem
            step={2}
            violet
            action={isPrepareJEEnable ? navigateToPrepareJE : undefined}
          >
            Review schedule
          </BreadcrumbsItem>
          <BreadcrumbsItem
            violet
            step={3}
          >
            Prepare JE
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Box>
      <HeaderTop
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          height="100%"
          padding="0 14px"
        >
          <SubledgerAccountName account={subledger?.account} />
        </Box>
        {
          isPrepareJEEnable && (
            <Box marginLeft="auto">
              <Button
                variant="contained"
                color="primary"
                endIcon={<ChevronRightIcon />}
                onClick={navigateToPrepareJE}
              >
                REVIEW SCHEDULE
              </Button>
            </Box>
          )
        }
        {
          !isPrepareJEEnable && (
            <Tooltip
              title={toolTipContent}
              arrow
              placement="top"
            >
              <Box marginLeft="auto">
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<ChevronRightIcon />}
                  onClick={navigateToPrepareJE}
                  disabled={!isPrepareJEEnable}
                >
                  PREPARE JE
                </Button>
              </Box>
            </Tooltip>
          )
        }
        <Button
          style={{ color: COLORS.skyBlue }}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </HeaderTop>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        position="absolute"
        top="30px"
        right="38px"
      >
        <Button
          onClick={downloadCSV}
          color="primary"
          endIcon={(<DownloadExportIcon />)}
        >
          Export
        </Button>
      </Box>
    </Box>
  );
};

export default ScheduleHeader;
