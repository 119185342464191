import {
  TableCell,
  TableRow,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  getChildren,
  getTotalBalanceAndAmortizationBalance,
} from './common';
import currencyFormatter from '../../util/currencyFormatter';
import {
  AmortizationSource,
  AmortizationScheduleDetails,
} from '../../interfaces/types';
import { useTableStyles } from './Subledger.styled';
import { accountInfoSelector } from '../../store/selectors/account';
import PrepareJERowAmortizationSchedules from './PrepareJERowAmortizationSchedules';
import AmortizationReadOnlyTableCell from './AmortizationReadOnlyDateTableCell';
import AmortizationSourceCreationCell from './TableCells/AmortizationSourceCreationCell';
import DescriptionReadOnlyCell from './TableCells/DescriptionReadOnlyCell';
import VendorCell from './TableCells/VendorCell';
import ExpenseCellWithCollapseOption from './TableCells/ExpenseCellWithCollapseOption';
import AccountClassCell from './TableCells/AccountClassCell';

interface Props {
  amortizationSource: AmortizationSource;
  amortizationSources: Array<AmortizationSource>;
  hasChildren: boolean;
  collapses: Array<string>;
  lastChild?: boolean;
  toggleSplit: (id: string) => () => void;
  startDate: Date;
  endDate: Date;
  scheduleDate: string;
  disabled?: boolean;
  onInputBoxChange: (
    lastDay: string,
    asset: AmortizationSource,
    schedule?: AmortizationScheduleDetails
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  isCollapsedView?: boolean;
}

const PrepareJEPrepareExpenseRow = ({
  amortizationSource,
  amortizationSources,
  hasChildren,
  lastChild,
  toggleSplit,
  collapses,
  scheduleDate,
  startDate,
  endDate,
  onInputBoxChange,
  disabled = false,
  isCollapsedView,
}: Props) => {
  const tableClasses = useTableStyles();
  const account = useSelector(accountInfoSelector)!;
  const children = getChildren(amortizationSource.internalId, amortizationSources);
  const {
    totalBalance,
    amortizationToDateBalance,
  } = hasChildren ? getTotalBalanceAndAmortizationBalance(children) : {
    totalBalance: 0,
    amortizationToDateBalance: 0,
  };

  return (
    <TableRow
      hover={false}
      className={clsx({
        [tableClasses.disabledRow]: disabled,
      })}
      classes={{
        selected: tableClasses.selected,
      }}
    >
      <AmortizationSourceCreationCell
        hasChildren={hasChildren}
        lastChild={lastChild}
        amortizationSource={amortizationSource}
        isCellSticky="true"
      />
      <DescriptionReadOnlyCell
        hasChildren={hasChildren}
        lastChild={lastChild}
        amortizationSource={amortizationSource}
        isCellSticky="true"
      />
      {!isCollapsedView && (
        <>
          <VendorCell
            hasChildren={hasChildren}
            lastChild={lastChild}
            amortizationSource={amortizationSource}
            isCellSticky="true"
          />
          <ExpenseCellWithCollapseOption
            hasChildren={hasChildren}
            lastChild={lastChild}
            amortizationSource={amortizationSource}
            collapses={collapses}
            toggleSplit={toggleSplit}
            isCellSticky="true"
          />
          {
            (account?.classTrackingPerTxnLine || account?.classTrackingPerTxn) && (
              <AccountClassCell
                hasChildren={hasChildren}
                lastChild={lastChild}
                amortizationSource={amortizationSource}
                isCellSticky="true"
              />
            )
          }
        </>
      )}
      <AmortizationReadOnlyTableCell
        amortizationDate={amortizationSource.amortizationSchedule?.amortizationStartDate}
        parentId={amortizationSource.parentId}
        amortizationScheduleType={amortizationSource.amortizationSchedule.amortizationScheduleType}
        hasChildren={hasChildren}
        lastChild={lastChild}
        isCellSticky="true"
      />
      <AmortizationReadOnlyTableCell
        amortizationDate={amortizationSource.amortizationSchedule?.amortizationEndDate}
        parentId={amortizationSource.parentId}
        amortizationScheduleType={amortizationSource.amortizationSchedule.amortizationScheduleType}
        hasChildren={hasChildren}
        lastChild={lastChild}
        isCellSticky="true"
      />
      <TableCell
        className={clsx(tableClasses.currencyCell, {
          [tableClasses.child]: amortizationSource.parentId,
          [tableClasses.parent]: hasChildren,
          [tableClasses.lastChild]: lastChild,
        })}
        is-cell-sticky="true"
      >
        {hasChildren && currencyFormatter.format(totalBalance - amortizationToDateBalance)}
        {!hasChildren
        && currencyFormatter.format(amortizationSource?.startingBalance - (
          amortizationSource?.amortizationToDateBalance ? amortizationSource?.amortizationToDateBalance : 0))}
      </TableCell>
      <PrepareJERowAmortizationSchedules
        amortizationSource={amortizationSource}
        amortizationSources={amortizationSources}
        scheduleDate={scheduleDate}
        endDate={endDate!}
        startDate={startDate}
        hasChildren={!!children?.length}
        onInputBoxChange={onInputBoxChange}
      />
    </TableRow>
  );
};

export default React.memo(PrepareJEPrepareExpenseRow);
