import React from 'react';
import {
  useRouteMatch,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Layout from '../../components/Layout';
import { JEEntry } from './JEEntry';
import { JEList } from './JEList';

export const JEs = () => {
  const { url } = useRouteMatch();

  const jeRoutes = [
    {
      title: 'Journal Entries',
      path: `${url}`,
      component: <JEList />,
    },
    {
      title: 'Journal Entries',
      path: `${url}/:accountId`,
      component: <JEList />,
    },
    {
      title: 'Journal Entry',
      path: `${url}/entry/:subledgerId/:jeId`,
      component: <JEEntry />,
    },
  ];

  return (
    <Switch>
      {jeRoutes.map(({ title, path, component }) => (
        <Route
          key={title}
          path={path}
          exact
        >
          <Layout
            key={title}
            title={title}
          >
            {component}
          </Layout>
        </Route>
      ))}
      <Redirect to={url} />
    </Switch>
  );
};
