import React from 'react';
import styled, { css } from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import COLORS from '../../theme/colors';

interface Props {
  transparent?: boolean;
  size?: number;
}

const StyledLoadingBox = styled.div<Props>`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: ${COLORS.lightGray5};
  ${({ transparent }) => (transparent && css`
    opacity: 0.5;
  `)}
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border-radius: 4;
  top: 0;
  left: 0;
`;

export const LoadingBox = ({ transparent, size }: Props) => (
  <StyledLoadingBox transparent={transparent}>
    <CircularProgress size={size} />
  </StyledLoadingBox>
);
