import React from 'react';
import CreateSubledger from './CreateSubledger';
import Layout from '../../../components/Layout';

const SchedulerContainer = () => (
  <Layout title="Create Subledger">
    <CreateSubledger />
  </Layout>
);

export default SchedulerContainer;
